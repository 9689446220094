import React from 'react'
import PhoneNumberButton from '../phoneNumberButton/PhoneNumberButton'

interface IProps {
  extraType?: 'phone' | 'vhf'
  id?: string
  label: React.ReactNode
  value: string | number | undefined
}

const BasicInfoItem = ({ extraType, id, label, value }: IProps) => {
  if (!value) {
    return null
  }

  return (
    <div className="info-item" id={`basic-info-item-${id}`}>
      <span className="info-tag">{label}</span>
      <span className={`info-span ${extraType}`}>
        {extraType === 'phone' ? <PhoneNumberButton number={value} /> : value}
      </span>
    </div>
  )
}

export default BasicInfoItem
