import React, { useEffect, useState } from 'react'
import { I18n } from 'react-redux-i18n'

import ShipSelector from '../../components/shipSelector/ShipSelector'
import LocalePicker from '../../components/localePicker/LocalePicker'
import TeqplayApiService from '../../services/TeqplayAPIService/TeqplayApiService'

import { toast } from 'react-toastify'
import { ILocationStatus } from '../../@types/types'
import { usePrevious } from '../../hooks/usePrevious'
import { useAnalytics } from '../../services/AnalyticsWrapper/AnalyticsWrapper'
import { ILoggedInUser, IShipInfo, IUserProfile } from '../../services/TeqplayAPIService/TeqplayApi'
import { isValidPhoneNumber, isEmail } from '../../utils/general'

interface IProps {
  auth: ILoggedInUser
  userProfile: IUserProfile | null
  currentLocation: IShipInfo | null
  teqplayAPIService: TeqplayApiService
  confirmDeleteAccount: () => void
  refreshUserProfile: () => Promise<void>
  locationStatus: ILocationStatus
}

const ChangeUserProfileForm = ({
  auth,
  userProfile,
  currentLocation,
  teqplayAPIService,
  confirmDeleteAccount,
  refreshUserProfile,
  locationStatus
}: IProps) => {
  const analytics = useAnalytics('ChangeUserProfileForm')
  const previousProfileMMSI = usePrevious(userProfile?.mmsi)

  const [email, setEmail] = useState<string | undefined>(userProfile?.email || undefined)
  const [phoneNumber, setPhoneNumber] = useState<string | undefined>(
    userProfile?.mobile || undefined
  )
  const [currentShip, setCurrentShip] = useState<IShipInfo | null>(currentLocation)
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<string | undefined>()

  useEffect(() => {
    // Execute check if there is no current ship loaded yet, but there is a currentLocation
    if (currentShip === null && currentLocation) {
      setCurrentShip(currentLocation)
    }

    if (!email && userProfile?.email) {
      setEmail(userProfile?.email)
    }

    if (phoneNumber === undefined && phoneNumber !== userProfile?.mobile) {
      setPhoneNumber(userProfile?.mobile)
    }
  }, [currentShip, currentLocation, userProfile, email, phoneNumber])

  useEffect(() => {
    // In case the MMSI inside the profile is updated, the userLocation needs to be updated
    // in order to pass the detectUnsavedChanges check
    if (previousProfileMMSI && previousProfileMMSI !== userProfile?.mmsi) {
      locationStatus.refreshLocation()
    }
  }, [previousProfileMMSI, userProfile, locationStatus, currentLocation])

  const hasUnsavedChanges = detectUnsavedChanges()

  return (
    <form className="settings-form" onSubmit={handleFormSubmit}>
      <div className="page-block">
        <h3>{I18n.t('settings.selectLanguage')}</h3>
        <div className="form-row dimension-row">
          <div className="dimension-column">
            <div className="multi-label">
              <label className="label">{I18n.t('settings.currentLanguage')}</label>
            </div>
            <LocalePicker />
          </div>
        </div>
      </div>
      <div className="ship-select-wrapper page-block">
        <div className="form-row">
          <h3>{I18n.t('settings.titleProfile')}</h3>
        </div>
        <div className="form-row dimension-row">
          <div className="dimension-column">
            <div className="multi-label">
              <label className="label" htmlFor={'username'}>
                {I18n.t('settings.username')}
              </label>
              <div className="sub-label">{I18n.t('settings.usernameUnableChange')}</div>
            </div>
            <input
              id="settings-input-username"
              name="username"
              type="text"
              value={auth.username || userProfile?.userName}
              className="textfield"
              disabled={true}
              autoCapitalize="off"
            />
          </div>
        </div>
        <div className="form-row dimension-row">
          <div className="dimension-column">
            <label className="label" htmlFor={'email'}>
              {I18n.t('settings.email')}
            </label>
            <input
              id="settings-input-email"
              name="email"
              type="text"
              value={email}
              className="textfield"
              onChange={e => setEmail(e.target.value)}
              disabled={false}
              autoCapitalize="off"
            />
          </div>
          {error === I18n.t('settings.emailError') && (
            <span className="error-message">{I18n.t('settings.emailError')}</span>
          )}
        </div>
        <div className="form-row dimension-row">
          <div className="dimension-column">
            <div className="multi-label">
              <label className="label" htmlFor={'mobile'}>
                {I18n.t('settings.mobile')}
              </label>
              <div className="sub-label">{I18n.t('settings.optional')}</div>
            </div>
            <input
              id="settings-input-mobile"
              name="mobile"
              type="tel"
              value={phoneNumber || ''}
              onChange={e => setPhoneNumber(e.target.value)}
              className="textfield"
            />
          </div>
          {error === I18n.t('settings.mobileError') && (
            <span className="error-message">{I18n.t('settings.mobileError')}</span>
          )}
        </div>
        <div className="form-row seperator">
          <h3>{I18n.t('settings.titleShip')}</h3>
        </div>
        <div className="form-row dimension-row">
          <div className="dimension-column">
            <label className="label" htmlFor={'ship'}>
              {I18n.t('settings.ship.ship')}
            </label>

            <ShipSelector
              value={currentShip}
              onChange={s => setCurrentShip(s)}
              teqplayAPIService={teqplayAPIService}
            />
          </div>
        </div>

        <div className="warning info">
          <i className="icon-info" />
          {I18n.t('settings.ship.info')}
        </div>

        <div className="profile-buttons">
          {hasUnsavedChanges && <div className="warning">{I18n.t('settings.unsavedChanges')}</div>}
          <div className="buttons">
            <button
              type="reset"
              disabled={!hasUnsavedChanges}
              onClick={() => handleResetChanges()}
              className="button save-button large"
            >
              {I18n.t('settings.cancel')}
            </button>
            <button type="submit" className="button primary large">
              {loading ? <i className="icon-circle-notch animate-spin" /> : I18n.t('settings.save')}
            </button>
            <button
              type="button"
              onClick={() => confirmDeleteAccount()}
              className="button danger large delete-accout-button"
              id="settings-button-delete-account"
            >
              {I18n.t('settings.titleDeleteAccount')}
            </button>
          </div>
        </div>
      </div>
    </form>
  )

  async function handleFormSubmit(e: React.ChangeEvent<any>) {
    if (!userProfile) {
      toast.error(I18n.t('settings.userProfileError'))
      return
    }

    setLoading(true)
    e.preventDefault()

    if (!detectUnsavedChanges()) {
      toast.info(I18n.t('settings.noChangesToSave'))
      setLoading(false)
      return
    }

    if (!email || email.length === 0 || !isEmail(email)) {
      analytics.newEvent('settings_email_error', {})
      setError(I18n.t('settings.emailError'))
      toast.error(I18n.t('settings.emailError'))
      setLoading(false)
      return
    }

    if (!isValidPhoneNumber(phoneNumber)) {
      analytics.newEvent('settings_mobile_error', {})
      setError(I18n.t('settings.mobileError'))
      toast.error(I18n.t('settings.mobileError'))
      setLoading(false)
      return
    }

    if (!currentShip?.mmsi) {
      analytics.newEvent('settings_ship_error', {})
      setError(I18n.t('settings.shipError'))
      toast.error(I18n.t('settings.shipError'))
      setLoading(false)
      return
    }

    setError(undefined)

    try {
      const newProfile: IUserProfile = {
        ...userProfile,
        email,
        mobile: phoneNumber,
        mmsi: currentShip.mmsi
      }

      analytics.newEvent('settings_update_profile', {
        _id: newProfile._id
      })

      await teqplayAPIService.updateUserProfile(newProfile)
      await refreshUserProfile()
      toast.success(I18n.t('settings.updateProfileSuccess'))
    } catch (err) {
      toast.error(I18n.t('settings.updateProfileFailed'))
    } finally {
      setLoading(false)
    }
  }

  function detectUnsavedChanges() {
    if (
      email !== userProfile?.email ||
      phoneNumber !== userProfile?.mobile ||
      currentShip?.mmsi !== currentLocation?.mmsi
    ) {
      return true
    } else {
      return false
    }
  }

  function handleResetChanges() {
    setError(undefined)
    setCurrentShip(currentLocation)
    setEmail(userProfile?.email)
    setPhoneNumber(userProfile?.mobile)
  }
}

export default ChangeUserProfileForm
