import React, { useState, useEffect, useMemo } from 'react'
import { FeatureGroup, Polygon } from 'react-leaflet'
import { LatLngBounds } from 'leaflet'
import area from '@turf/area'
import TeqplayApiService from '../../../../services/TeqplayAPIService/TeqplayApiService'
import { IOperatingArea } from '../../../../services/TeqplayAPIService/TeqplayApi'

interface IProps {
  bounds: LatLngBounds | undefined
  teqplayApiService: TeqplayApiService
  selectedItem?: IOperatingArea
  setSelectedItem?: (item: IOperatingArea) => void
  getPopup?: (item: IOperatingArea) => React.ReactNode
  disableInteraction?: boolean
}

const OperatingAreasLayer: React.FC<IProps> = props => {
  const [operatingAreas, setOperatingAreas] = useState<IOperatingArea[]>([])

  const sortedOperatingAreas = useMemo(() => {
    return [...operatingAreas].sort((a, b) => {
      const areaA = calculateTotalArea(a)
      const areaB = calculateTotalArea(b)
      // Sort ascending by area, so larger polygons, (France etc) are rendered first.
      return areaB - areaA
    })
  }, [operatingAreas])

  useEffect(() => {
    fetchOperatingAreas(props.bounds)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.bounds])

  return (
    <FeatureGroup>
      {sortedOperatingAreas.map((operatingArea, index) =>
        operatingArea.polygon.map((polygon, polyIndex) => (
          <Polygon
            key={`operating-layer${index}-${polyIndex}`}
            positions={polygon.coordinates[0].map(
              coord => [coord[1], coord[0]] as [number, number]
            )}
            weight={props.selectedItem?.objectId === operatingArea.objectId ? 6 : 3}
            onClick={() => props.setSelectedItem && props.setSelectedItem(operatingArea)}
            color="#2e6771"
            interactive={!props.disableInteraction}
          >
            {props.getPopup && props.getPopup(operatingArea)}
          </Polygon>
        ))
      )}
    </FeatureGroup>
  )

  function calculateTotalArea(operatingArea: IOperatingArea): number {
    return operatingArea.polygon.reduce(
      (sum: number, poly) => sum + area({ type: 'Polygon', coordinates: poly.coordinates }),
      0
    )
  }

  async function fetchOperatingAreas(bounds: LatLngBounds | undefined) {
    try {
      const areas = await props.teqplayApiService.getAllCompetenceAreasIn(bounds)
      setOperatingAreas(areas)
    } catch (err) {
      console.error(err)
    }
  }
}

export default OperatingAreasLayer
