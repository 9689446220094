import { Search } from 'history'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { useHistory, useLocation } from 'react-router'
import { toast } from 'react-toastify'

import TeqplayApiService from '../TeqplayAPIService/TeqplayApiService'

import { IRootProps } from '../../@types/types'
import { clearAuth, setAuth } from '../../pages/loginPage/actions'
import { BACKEND_URL, GENERAL_HEADERS } from '../../utils/constants'
import { isCordovaApp } from '../../utils/cordovaUtils'
import { sendMessageToSentry } from '../../utils/sentry'
import { convertObjectToQueryString, convertQueryStringToObject } from '../../utils/url'
import { ILoggedInUser } from '../TeqplayAPIService/TeqplayApi'

interface IProps {
  children: (teqplayAPIService: TeqplayApiService) => React.ReactNode
}

const AuthenticationWrapper = (props: IProps) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()

  // Redux store gets initial user
  const { currentUser } = useSelector((reduxProps: IRootProps) => reduxProps)

  // Dispatchables
  const setAuthentication = (auth: ILoggedInUser | undefined) => dispatch(setAuth(auth))
  const clearAuthentication = () => {
    dispatch(clearAuth())
  }

  const BACKEND = determineBackendURL(location.search, currentUser)
  const TeqplayAPIService = TeqplayApiService.Instance(
    setAuthentication,
    clearAuthentication,
    currentUser.auth,
    BACKEND,
    GENERAL_HEADERS
  )

  React.useEffect(() => {
    const queryParams = convertQueryStringToObject(location.search)

    if (BACKEND === BACKEND_URL) {
      delete queryParams.url

      // Only push the new search otherwise you get a blank screen
      history.push({
        search: Object.keys(queryParams).length !== 0 ? convertObjectToQueryString(queryParams) : ''
      })
    } else if (
      currentUser &&
      currentUser.auth &&
      currentUser.auth.url &&
      currentUser.auth.url !== BACKEND
    ) {
      toast.error(I18n.t('loggedOutDueToURLMismatch'))
      history.push({
        pathname: '/login',
        search: Object.keys(queryParams).length !== 0 ? convertObjectToQueryString(queryParams) : ''
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <React.Fragment>{props.children ? props.children(TeqplayAPIService) : null}</React.Fragment>
  )
}

export function determineBackendURL(query: Search, user?: any): string {
  const queryParams = convertQueryStringToObject(query)

  // Disallow URL overrides on live url or inside apps
  if (isCordovaApp || window?.location?.hostname === 'riverguide.teqplay.nl') {
    if (queryParams.url || queryParams.URL) {
      toast.error(I18n.t('notAllowedToPerform'))
    }

    // Always return backend for apps or live website
    return BACKEND_URL
  }

  // Prevention for any potential MITM attacks
  // Only allow localhost:8080 or .teqplay.nl hosts
  const SAFE_DOMAINS = ['localhost:8080', '.teqplay.nl']

  const url: string | undefined = queryParams.url || queryParams.URL

  const isApproved = (domain: string) => url?.endsWith(domain)
  const approvedChange = SAFE_DOMAINS.some(isApproved)

  if (url) {
    try {
      // User attempted to change URL
      if (approvedChange) {
        return url
      } else {
        // Not inside approved domains, throwing error
        throw new Error('Unsafe URL override detected')
      }
    } catch (error) {
      // Only here send the message to sentry with extra information
      toast.error(I18n.t('notAllowedToPerform'))
      sendMessageToSentry('Unsafe URL override detected', { SAFE_DOMAINS, attempedURL: url }, user)
      return BACKEND_URL
    }
  }

  return BACKEND_URL
}

export interface IAuthenticationWrapper {
  teqplayAPIService: TeqplayApiService
}

export default AuthenticationWrapper
