import React from 'react'
import { I18n } from 'react-redux-i18n'

import {
  BicsCargo,
  BicsDestination,
  BicsVoyageCargo,
  BicsHull
} from '../../../services/TeqplayAPIService/TeqplayApi'

import './BICSTravelCargoRow.scss'

interface IProps {
  cargoInfo: BicsCargo | undefined
  onBoardInfo: BicsVoyageCargo
  fromLocation: BicsDestination | undefined
  toLocation: BicsDestination | undefined
  editMode?: boolean
  index: number
  handleShowCargoEditDialog: (i: number) => void
  hasMultipleHulls: boolean
  relatedHull: BicsHull | undefined
  hullsInVoyage: BicsHull[]
}

const BICSTravelCargoRow = ({
  cargoInfo,
  onBoardInfo,
  fromLocation,
  toLocation,
  editMode,
  index,
  handleShowCargoEditDialog,
  hasMultipleHulls,
  relatedHull,
  hullsInVoyage
}: IProps) => {
  const hasUnboundHull = !hullsInVoyage.find(
    h => relatedHull?.eni === h.eni || relatedHull?.mmsi === h.mmsi
  )

  return (
    <>
      <div
        className={`cargo-row ${editMode ? ' view-only' : ''}${hasUnboundHull ? ' unbound' : ''}`}
        onClick={() => handleShowCargoEditDialog(index)}
      >
        <div className="left">
          <div className="goods-name">
            {cargoInfo?.name || cargoInfo?.nameLocal || onBoardInfo.vnNr || onBoardInfo.hsCode}
          </div>
          <div className="trip">
            <span className="departure">
              {fromLocation?.name || fromLocation?.nameLocal || onBoardInfo.fromLocation}
            </span>
            <i className="icon-right" />
            <span className="arrival">
              {toLocation?.name || toLocation?.nameLocal || onBoardInfo.toLocation}
            </span>
          </div>

          {(onBoardInfo.weightTn !== undefined || onBoardInfo.volumeM3 !== undefined) && (
            <div className="tags">
              {hasMultipleHulls && relatedHull ? (
                <div className={`tag hull${hasUnboundHull ? ' unbound' : ''}`}>
                  <i className={hasUnboundHull ? 'icon-attention' : 'icon-ship'} />
                  <span>{relatedHull?.name || relatedHull?.mmsi || relatedHull?.eni}</span>
                </div>
              ) : null}
              {onBoardInfo.weightTn ? (
                <div className="tag weight">
                  <i className="icon-balance-scale" />
                  <span>
                    {onBoardInfo.weightTn.toString().split('.').join(',')}{' '}
                    {I18n.t('announcements.fields.tonnage')}
                  </span>
                </div>
              ) : null}
              {onBoardInfo.volumeM3 ? (
                <div className="tag volume">
                  <i className="icon-resize-full" />
                  <span>
                    {onBoardInfo.volumeM3.toString().split('.').join(',')}{' '}
                    {I18n.t('announcements.fields.m3')}
                  </span>
                </div>
              ) : null}
            </div>
          )}
        </div>
        <div className="right">
          <i className="icon-right-open" />
        </div>
      </div>
      {hasUnboundHull && (
        <div className="unbound-warning">
          {I18n.t('announcements.travel.unboundWarning', {
            named: relatedHull?.name || relatedHull?.mmsi || relatedHull?.eni
          })}
        </div>
      )}
    </>
  )
}

export default BICSTravelCargoRow
