/**
 * Test whether we're running as a cordova app
 * (in that case the url is something like 'file:///android_asset/www/index.html'
 * @type {boolean}
 */
export const isCordovaApp =
  window.hasOwnProperty('cordova') ||
  (window.location.href.indexOf('http://') === -1 &&
    window.location.href.indexOf('https://') === -1)

export const isMobile =
  // eslint-disable-next-line max-len
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini|Mobile/i.test(
    navigator.userAgent
  )
