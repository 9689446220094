import { LatLngBounds } from 'leaflet'
import { cloneDeep } from 'lodash'
import {
  IFairway,
  IHardcodedBerthFile,
  IHardcodedTerminal,
  ITeqplayBounds,
  RWSCurrent,
  RWSDataLegend,
  SupportedLocales
} from '../../@types/types'
import { IBottleneck } from '../../components/mapShared/customLayers/layers/BottleneckLayer'
import { IBuoy } from '../../components/mapShared/customLayers/layers/BuoysLayer/BuoysLayer'
import { IDepthPolygon } from '../../components/mapShared/customLayers/layers/DepthsLayer/DepthsLayer'
import { IKilometrering } from '../../components/mapShared/customLayers/layers/KilometreringsLayer/KilometreringsLayer'
import { IWaterwaySign } from '../../components/mapShared/customLayers/layers/WaterwaySignsLayer/WaterwaySignsLayer'
import { IWaveObject } from '../../components/mapShared/customLayers/layers/WavesLayer/WavesLayer'
import { leafletBoundsToTeqplayBoundsObject } from '../../utils/layers'
import { sendMessageToSentry } from '../../utils/sentry'
import { convertObjectToQueryString } from '../../utils/url'
import {
  BargeRegistrationUser,
  BicsCargo,
  BicsDestination,
  BicsHull,
  BicsReportingPoint,
  BicsShipType,
  BicsVoyage,
  IBerth,
  IBridgeDetails,
  IBridgeMovement,
  IBridgePlanning,
  IBridgeRequest,
  ICarDropoffPoint,
  IChargingStation,
  IDrinkwaterPoint,
  IElectricalRoute,
  IGeocodedLocation,
  IGeoServiceLocation,
  IInlandHarbour,
  IJulianakanaalResponse,
  ILockDetails,
  ILockPlanning,
  ILoggedInUser,
  INavigationLocation,
  INotificationDetails,
  IOperatingArea,
  IRecentDraught,
  IRecreationalRoute,
  IRestError,
  IRoute,
  IRouteEtaUpdate,
  IRouteLocation,
  IRouteScoutNetwork,
  ISABPoint,
  ISelectedRoute,
  IShipInfo,
  IShipNotificationStatus,
  IShorePower,
  IStoredRoute,
  ITrailerSlipway,
  IUserProfile,
  IWasteWaterStation,
  IWaterMeterDetails,
  IWeatherStation,
  IWinterRestArea,
  IWaterHeight,
  RouteItemTypes,
  SpeedTypes,
  IWaterHeightMeasurements
} from './TeqplayApi'

import NLAMSBerth from '../../assets/locations/NLAMS_berths.json'
import HardcodedTerminals from '../../assets/locations/terminals.json'
import { loadFromLocalStorage } from '../../utils/localStorage'
import { localeToBELang } from '../../utils/localeUtils'
import {
  RWS_CURRENTS_ENDPOINT,
  RWS_CURRENTS_LAYER
} from '../../components/mapShared/customLayers/layers/CurrentsLayer/CurrentsUtils.ts/CurrentsUtils'

interface IFetchOptions {
  method: 'GET' | 'POST' | 'PATCH' | 'PUT' | 'DELETE'
  // Proper TypeScript safe way is to create a Headers() object, but we decided against this
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  headers: any
  body?: string | FormData
}

class TeqplayApiService {
  // static reference to the class itself. Uses singleton pattern to enforce only 1 entity.
  private static _instance: TeqplayApiService

  protected userAuth: ILoggedInUser // Internal use only
  private clearAuthInRedux: () => void
  private setAuthInRedux: (user: ILoggedInUser) => void
  private GENERAL_HEADERS: RequestInit
  private fcmId: string | null = null
  public BACKEND_URL: string

  private constructor(
    setAuth: (user: ILoggedInUser) => void,
    clearAuth: () => void,
    userAuth: ILoggedInUser,
    BACKEND_URL: string,
    GENERAL_HEADERS: object
  ) {
    this.setAuthInRedux = setAuth
    this.clearAuthInRedux = clearAuth
    this.userAuth = userAuth
    this.BACKEND_URL = BACKEND_URL
    this.GENERAL_HEADERS = GENERAL_HEADERS
  }

  // method to return the service if it has been initialised already, otherwise it creates a instance and returns it.
  public static Instance(
    setAuth: (user: ILoggedInUser) => void,
    clearAuth: () => void,
    userAuth: ILoggedInUser,
    BACKEND_URL: string,
    GENERAL_HEADERS: object
  ) {
    return (
      this._instance ||
      (this._instance = new this(setAuth, clearAuth, userAuth, BACKEND_URL, GENERAL_HEADERS))
    )
  }

  public logoutUser() {
    if (this.fcmId) {
      // Clear FCM id so user doesnt get any more notifications
      this.deleteUserFCM()
    }

    this.logoutUserBackend()

    // Wipe the currentUser in both APIService and redux
    this.clearUserAuth()
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private handleRestResponse(response: Response, noErrorNotification?: boolean): Promise<any> {
    if (response?.status >= 200 && response?.status < 300 && response.json) {
      return Promise.resolve(response.json())
    } else {
      return Promise.resolve((response.json && response.json()) || response)
        .catch(err => ({
          status: response.status || -1,
          message: err.message || 'An unknown error has occurred'
        }))
        .then((restError: IRestError) => {
          // This ensures that the status of the request is ALWAYS passed on
          // The app will break if removed
          const tempError = { ...restError, status: response?.status }

          // Makes for easier debugging to turn on toasts when things go wrong
          // if (!noErrorNotification) {
          //   toast.error(`${tempError.statusCode} ${tempError.message}`)
          // }

          // eslint-disable-next-line @typescript-eslint/no-throw-literal
          throw tempError
        })
    }
  }

  private handleEmptyResponse(response: Response): Promise<void> {
    if (response?.status >= 200 && response?.status < 300 && response.json) {
      return Promise.resolve()
    } else {
      return Promise.reject(response.status)
    }
  }

  private async fetchApiCall(
    url: string,
    options: IFetchOptions,
    fetchWithoutReturn?: boolean,
    noErrorNotification?: boolean
  ) {
    try {
      // returns null in case of requests without a body being returned by the backend
      if (fetchWithoutReturn === true) {
        return await fetch(url, options).then(r => this.handleEmptyResponse(r))
      }

      return await fetch(url, options).then(r => this.handleRestResponse(r, noErrorNotification))
    } catch (error) {
      if (error.status === 401) {
        try {
          if (this.userAuth?.refreshToken) {
            const username = this.userAuth.username || loadFromLocalStorage('username') || ''

            const newAuth = await this.fetchLoginWithRefreshToken(
              username,
              this.userAuth.refreshToken
            )

            this.userAuth = { ...this.userAuth, ...newAuth }
            this.setAuthInRedux(newAuth)

            const newOptions = cloneDeep(options)
            newOptions.headers.Authorization = newAuth.token
            return await fetch(url, newOptions).then(this.handleRestResponse)
          } else {
            // No refresh token present, should logout
            this.logoutUser()
            throw new Error('No refreshToken present')
          }
        } catch (refreshError) {
          if (refreshError.status === 401 || this.userAuth.refreshToken) {
            sendMessageToSentry('refreshTokenFailure', {
              status: refreshError.status,
              message: refreshError.message,
              statusCode: refreshError.statusCode
            })

            this.logoutUser()
          }
          throw error
        }
      } else {
        throw error
      }
    }
  }

  private fetchLoginWithRefreshToken(
    username: ILoggedInUser['username'],
    refreshToken: ILoggedInUser['refreshToken']
  ): Promise<ILoggedInUser> {
    const options = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ username, refreshToken })
    }

    return fetch(`${this.BACKEND_URL}/auth/loginWithRefreshToken`, options).then(
      this.handleRestResponse
    )
  }

  public async registerUser(newUser: BargeRegistrationUser): Promise<void> {
    const options: IFetchOptions = {
      method: 'GET',
      headers: { ...this.GENERAL_HEADERS }
    }

    const query = convertObjectToQueryString({
      name: newUser.name,
      phoneNumber: newUser.phoneNumber,
      emailAddress: newUser.emailAddress,
      shipMmsi: newUser.shipMmsi,
      shipName: newUser.shipName,
      comment: newUser.comment
    })

    return fetch(`${this.BACKEND_URL}/register/riverguide${query}`, options).then(
      this.handleRestResponse
    )
  }

  /**
   * Login function
   * @param username
   * @param password
   */
  public async loginUser(username: string, password: string): Promise<void> {
    const options: RequestInit = {
      method: 'POST',
      headers: { ...this.GENERAL_HEADERS } as Record<string, string>,
      body: JSON.stringify({
        username,
        password
      })
    }

    try {
      const loggedInUser: {
        createdAt: string
        expiresInSeconds: number
        refreshToken: string
        token: string
        userName: string
      } = await fetch(`${this.BACKEND_URL}/auth/login`, options).then(this.handleRestResponse)

      // Set the user auth inside of the class
      this.userAuth = { ...loggedInUser, username: loggedInUser.userName, url: this.BACKEND_URL }
      this.setAuthInRedux({
        ...loggedInUser,
        username: loggedInUser.userName,
        url: this.BACKEND_URL
      })
    } catch (error) {
      throw error
    }
  }

  public deleteAccount = () => {
    const options: IFetchOptions = {
      method: 'DELETE',
      headers: {
        ...this.GENERAL_HEADERS,
        Authorization: this.userAuth.token
      }
    }

    if (this.fcmId) {
      this.deleteUserFCM()
    }

    return (
      fetch(`${this.BACKEND_URL}/userProfile/current`, options)
        // Clear the pre-existing instance of this
        // To ensure whenever a new one is created it does not link to
        // any old APIService containing old credentials
        .then(this.clearUserAuth)
    )
  }

  private clearUserAuth = () => {
    this.userAuth = {
      username: '',
      token: '',
      createdAt: '',
      expiresInSeconds: 0,
      refreshToken: '',
      url: ''
    }

    this.clearAuthInRedux()
  }

  public updatePassword = (passwordFields: {
    username: string
    currentPassword: string
    newPassword: string
  }) => {
    const options: IFetchOptions = {
      method: 'POST',
      headers: {
        ...this.GENERAL_HEADERS,
        Authorization: this.userAuth.token
      },
      body: JSON.stringify(passwordFields)
    }

    return fetch(`${this.BACKEND_URL}/auth/changePassword`, options).then(this.handleRestResponse)
  }

  public resetPassword = (username: string) => {
    const options: IFetchOptions = {
      method: 'POST',
      headers: {
        ...this.GENERAL_HEADERS
      },
      body: JSON.stringify({ username })
    }

    return fetch(`${this.BACKEND_URL}/auth/resetPassword`, options).then(this.handleRestResponse)
  }

  public logoutUserBackend() {
    const options = {
      method: 'GET',
      headers: {
        ...this.GENERAL_HEADERS,
        Authorization: this.userAuth.token
      }
    }

    return (
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      fetch(`${this.BACKEND_URL}/auth/logout`, options as any)
        // Clear the pre-existing instance of this
        // To ensure whenever a new one is created it does not link to
        // any old APIService containing old credentials
        // .then(() => { instance = null })
        .then(() => null)
    )
  }

  public fetchAllInlandHarbours(): Promise<INavigationLocation[]> {
    const options: IFetchOptions = {
      method: 'GET',
      headers: {
        ...this.GENERAL_HEADERS,
        Authorization: this.userAuth.token
      }
    }

    return this.fetchApiCall(`${this.BACKEND_URL}/static/inlandHarbour`, options)
  }

  public fetchChargingStations(bounds?: LatLngBounds): Promise<IChargingStation[]> {
    const options: IFetchOptions = {
      method: 'GET',
      headers: {
        ...this.GENERAL_HEADERS,
        Authorization: this.userAuth.token
      }
    }

    const urlBounds = leafletBoundsToTeqplayBoundsObject(bounds)
    const query = convertObjectToQueryString({
      ...urlBounds
    })
    return this.fetchApiCall(`${this.BACKEND_URL}/static/chargingStation${query}`, options)
  }

  public fetchBridges(bounds?: LatLngBounds): Promise<IBridgeDetails[]> {
    const options: IFetchOptions = {
      method: 'GET',
      headers: {
        ...this.GENERAL_HEADERS,
        Authorization: this.userAuth.token
      }
    }

    const urlBounds = leafletBoundsToTeqplayBoundsObject(bounds)
    const query = convertObjectToQueryString({
      ...urlBounds
    })
    return this.fetchApiCall(`${this.BACKEND_URL}/static/bridge${query}`, options)
  }

  public fetchLocks(bounds?: LatLngBounds): Promise<ILockDetails[]> {
    const options: IFetchOptions = {
      method: 'GET',
      headers: {
        ...this.GENERAL_HEADERS,
        Authorization: this.userAuth.token
      }
    }

    const urlBounds = leafletBoundsToTeqplayBoundsObject(bounds)
    const query = convertObjectToQueryString({
      ...urlBounds
    })
    return this.fetchApiCall(`${this.BACKEND_URL}/static/lock${query}`, options)
  }

  public fetchWasteWaterStations(bounds?: LatLngBounds): Promise<IWasteWaterStation[]> {
    const options: IFetchOptions = {
      method: 'GET',
      headers: {
        ...this.GENERAL_HEADERS,
        Authorization: this.userAuth.token
      }
    }
    const urlBounds = leafletBoundsToTeqplayBoundsObject(bounds)
    const query = convertObjectToQueryString({
      ...urlBounds
    })
    return this.fetchApiCall(`${this.BACKEND_URL}/static/wasteWaterStation${query}`, options)
  }

  public fetchTrailerSlipways(bounds?: LatLngBounds): Promise<ITrailerSlipway[]> {
    const options: IFetchOptions = {
      method: 'GET',
      headers: {
        ...this.GENERAL_HEADERS,
        Authorization: this.userAuth.token
      }
    }

    const urlBounds = leafletBoundsToTeqplayBoundsObject(bounds)
    const query = convertObjectToQueryString({
      ...urlBounds
    })
    return this.fetchApiCall(`${this.BACKEND_URL}/static/trailerslipway${query}`, options)
  }

  public fetchWinterRestAreas(bounds?: LatLngBounds): Promise<IWinterRestArea[]> {
    const options: IFetchOptions = {
      method: 'GET',
      headers: {
        ...this.GENERAL_HEADERS,
        Authorization: this.userAuth.token
      }
    }

    const urlBounds = leafletBoundsToTeqplayBoundsObject(bounds)
    const query = convertObjectToQueryString({
      ...urlBounds
    })
    return this.fetchApiCall(`${this.BACKEND_URL}/static/winterRestArea${query}`, options)
  }

  public fetchBerths(bounds?: LatLngBounds, includeOccupationInfo?: boolean): Promise<IBerth[]> {
    const options: IFetchOptions = {
      method: 'GET',
      headers: {
        ...this.GENERAL_HEADERS,
        Authorization: this.userAuth.token
      }
    }

    const urlBounds = leafletBoundsToTeqplayBoundsObject(bounds)
    const query = convertObjectToQueryString({
      ...urlBounds,
      includeOccupationInfo
    })

    return this.fetchApiCall(`${this.BACKEND_URL}/static/berth${query}`, options)
  }

  public async fetchDrinkwaterPoints(
    bounds?: LatLngBounds,
    locale?: SupportedLocales
  ): Promise<IDrinkwaterPoint[]> {
    const options: IFetchOptions = {
      method: 'GET',
      headers: {
        ...this.GENERAL_HEADERS,
        Authorization: this.userAuth.token
      }
    }

    const urlBounds = leafletBoundsToTeqplayBoundsObject(bounds)
    const query = convertObjectToQueryString({
      language: locale ? localeToBELang(locale) : undefined,
      ...urlBounds
    })
    return this.fetchApiCall(`${this.BACKEND_URL}/static/drinkwaterPoint${query}`, options)
  }

  public fetchRecentDraught(): Promise<IRecentDraught[]> {
    const options: IFetchOptions = {
      method: 'GET',
      headers: {
        ...this.GENERAL_HEADERS,
        Authorization: this.userAuth.token
      }
    }

    return this.fetchApiCall(`${this.BACKEND_URL}/static/recentDraught`, options)
  }

  public async fetchInlandHarbours(bounds?: LatLngBounds): Promise<IInlandHarbour[]> {
    const options: IFetchOptions = {
      method: 'GET',
      headers: {
        ...this.GENERAL_HEADERS,
        Authorization: this.userAuth.token
      }
    }

    const urlBounds = leafletBoundsToTeqplayBoundsObject(bounds)
    const query = convertObjectToQueryString({
      ...urlBounds
    })

    const inlandHarbours: IInlandHarbour[] = await this.fetchApiCall(
      `${this.BACKEND_URL}/static/inlandHarbour${query}`,
      options
    )
    const filteredInlandHarbours = inlandHarbours.filter(
      x => !x.shortStayPlaces || x.shortStayPlaces === 0
    ) // filter out any inlandharbours which don't have shortstayplaces

    return filteredInlandHarbours
  }

  public fetchElectricRoutes(bounds?: LatLngBounds): Promise<IElectricalRoute[]> {
    const options: IFetchOptions = {
      method: 'GET',
      headers: {
        ...this.GENERAL_HEADERS,
        Authorization: this.userAuth.token
      }
    }

    const urlBounds = leafletBoundsToTeqplayBoundsObject(bounds)
    const query = convertObjectToQueryString({
      ...urlBounds
    })
    return this.fetchApiCall(`${this.BACKEND_URL}/static/electricalRoute${query}`, options)
  }

  public fetchRouteNetwork(): Promise<IRouteScoutNetwork> {
    const options: IFetchOptions = {
      method: 'GET',
      headers: {
        ...this.GENERAL_HEADERS,
        Authorization: this.userAuth.token
      }
    }

    return this.fetchApiCall(`${this.BACKEND_URL}/route/routescout/network/FRYSLAN`, options)
  }

  public fetchShipsInBounds(bounds?: ITeqplayBounds): Promise<IShipInfo[]> {
    const options: IFetchOptions = {
      method: 'GET',
      headers: {
        ...this.GENERAL_HEADERS,
        Authorization: this.userAuth.token
      }
    }

    const query = convertObjectToQueryString({
      ...bounds
    })
    return this.fetchApiCall(`${this.BACKEND_URL}/ship/details${query}`, options)
  }

  /**
   * Set the user FCM
   * @param {string} authToken
   * @param {string} fcmid
   * @returns {Promise.<T>}
   */
  public setUserFCM(fcmid: string): Promise<string[] | null> {
    if (fcmid === this.fcmId) {
      console.warn(`[setUserFCM] FCM id has already been set to ${fcmid}`)
      return Promise.resolve(null)
    }

    const options: IFetchOptions = {
      method: 'PUT',
      headers: {
        ...this.GENERAL_HEADERS,
        Authorization: this.userAuth.token
      },
      body: JSON.stringify({ fcmid })
    }

    this.fcmId = fcmid

    // Set user ID for external viewability
    if (this.userAuth.username && window.FirebasePlugin) {
      throw new Error('Unimplemented feature, try adding MMSI?')
    }

    return this.fetchApiCall(`${this.BACKEND_URL}/userProfile/fcm`, options)
  }

  /**
   * Delete the user FCM
   * @param {string} authToken
   * @param {string} fcmid
   * @returns {Promise.<T>}
   */
  public deleteUserFCM(): Promise<void> {
    if (!window.cordova || !window.FirebasePlugin || !this.fcmId) {
      return Promise.reject(new Error('No FCM id present'))
    }

    try {
      const options: IFetchOptions = {
        method: 'DELETE',
        headers: {
          ...this.GENERAL_HEADERS,
          Authorization: this.userAuth.token
        },
        body: JSON.stringify({ fcmid: this.fcmId })
      }

      return fetch(`${this.BACKEND_URL}/userProfile/fcm`, options).then(() => {
        this.fcmId = null
      })
    } catch (err) {
      console.error(err)
      return Promise.reject(new Error('Unexpected exception while deleting FCM id inside backend'))
    }
  }

  public getCurrentShipInformation(): Promise<IShipInfo> {
    const options: IFetchOptions = {
      method: 'GET',
      headers: {
        ...this.GENERAL_HEADERS,
        Authorization: this.userAuth.token
      }
    }

    return this.fetchApiCall(`${this.BACKEND_URL}/ship/current`, options)
  }

  public patchCurrentShipInformation(dimensions: {
    width?: number
    length?: number
    height?: number
    draught?: number
  }): Promise<IShipInfo> {
    const options: IFetchOptions = {
      method: 'PATCH',
      headers: {
        ...this.GENERAL_HEADERS,
        Authorization: this.userAuth.token
      },
      body: JSON.stringify({
        dimensions
      })
    }

    return this.fetchApiCall(`${this.BACKEND_URL}/ship/current`, options, true)
  }

  public updateUserProfile(updatedProfile: IUserProfile): Promise<IUserProfile> {
    const options: IFetchOptions = {
      method: 'PUT',
      headers: {
        ...this.GENERAL_HEADERS,
        Authorization: this.userAuth.token
      },
      body: JSON.stringify(updatedProfile)
    }

    return this.fetchApiCall(`${this.BACKEND_URL}/userProfile/current`, options)
  }

  public getUserProfile(): Promise<IUserProfile> {
    const options: IFetchOptions = {
      method: 'GET',
      headers: {
        ...this.GENERAL_HEADERS,
        Authorization: this.userAuth.token
      }
    }

    return this.fetchApiCall(`${this.BACKEND_URL}/userProfile/current`, options)
  }

  public fetchRouteSuggestions(
    fromLocation: string | null,
    toLocation: string,
    viaRoutes: IRouteLocation[],
    departureTime: number | null,
    cruiseSpeed?: number,
    locale?: SupportedLocales,
    additionalItems?: boolean
  ): Promise<IRoute[]> {
    const viaRouteCoordinates: string[] = []

    viaRoutes.forEach(viaRoute => {
      if (viaRoute.coordinates) {
        const stringCoord =
          viaRoute.coordinates.lat.toString() + ',' + viaRoute.coordinates.lng.toString()
        viaRouteCoordinates.push(stringCoord)
      } else if (viaRoute.displayName) {
        viaRouteCoordinates.push(viaRoute.displayName)
      }
    })

    const options: IFetchOptions = {
      method: 'POST',
      headers: {
        ...this.GENERAL_HEADERS,
        Authorization: this.userAuth.token
      },
      body: JSON.stringify(viaRouteCoordinates)
    }

    const query = convertObjectToQueryString({
      cemtFilter: false,
      etaToObjects: true,
      heightBridges: true,
      maximumResults: 5,
      startTime: departureTime || undefined,
      cruiseSpeed,
      language: localeToBELang(locale),
      additionalItems,
      from: fromLocation || undefined,
      to: toLocation || undefined
    })

    return this.fetchApiCall(`${this.BACKEND_URL}/route/routescout${query}`, options)
  }

  public setUsersActiveRoute(route: IRoute, cruiseSpeed?: number): Promise<ISelectedRoute> {
    const options: IFetchOptions = {
      method: 'POST',
      headers: {
        ...this.GENERAL_HEADERS,
        Authorization: this.userAuth.token
      },
      body: JSON.stringify(route)
    }

    const query = convertObjectToQueryString({
      cruiseSpeed
    })

    return this.fetchApiCall(`${this.BACKEND_URL}/route/selectedRoute${query}`, options)
  }

  public getSelectedRoute(): Promise<ISelectedRoute> {
    const options: IFetchOptions = {
      method: 'GET',
      headers: {
        ...this.GENERAL_HEADERS,
        Authorization: this.userAuth.token
      }
    }

    return this.fetchApiCall(`${this.BACKEND_URL}/route/selectedRoute`, options, undefined, true)
  }

  public getSelectedRouteEtaUpdate(): Promise<IRouteEtaUpdate> {
    const options: IFetchOptions = {
      method: 'GET',
      headers: {
        ...this.GENERAL_HEADERS,
        Authorization: this.userAuth.token
      }
    }

    return this.fetchApiCall(`${this.BACKEND_URL}/route/selectedRoute/eta`, options)
  }

  public updateSelectedRoute(speedType: SpeedTypes, cruiseSpeed: number): Promise<ISelectedRoute> {
    const options: IFetchOptions = {
      method: 'PATCH',
      headers: {
        ...this.GENERAL_HEADERS,
        Authorization: this.userAuth.token
      },
      body: JSON.stringify({
        cruiseSpeed: speedType === 'CUSTOM' ? cruiseSpeed : null
      })
    }

    return this.fetchApiCall(`${this.BACKEND_URL}/route/selectedRoute`, options)
  }

  public fetchItemDetails(
    type: RouteItemTypes,
    itemId: string,
    locale?: SupportedLocales
  ): Promise<IBridgeDetails | IWaterMeterDetails | ILockDetails | INotificationDetails> {
    const options: IFetchOptions = {
      method: 'GET',
      headers: {
        ...this.GENERAL_HEADERS,
        Authorization: this.userAuth.token
      }
    }
    // Currently only language support for the bridge,locks and notifications
    const query = convertObjectToQueryString({
      language:
        type === 'BRIDGE' || type === 'LOCK' || type === 'NOTIFICATION'
          ? localeToBELang(locale)
          : undefined
    })
    return this.fetchApiCall(
      `${this.BACKEND_URL}/static/${type.toLowerCase()}/${itemId}${query}`,
      options
    )
  }

  public getShipNotificationStatus(): Promise<IShipNotificationStatus> {
    const options: IFetchOptions = {
      method: 'GET',
      headers: {
        ...this.GENERAL_HEADERS,
        Authorization: this.userAuth.token
      }
    }

    return this.fetchApiCall(`${this.BACKEND_URL}/route/notifications`, options)
  }

  public pauseRoute() {
    const options: IFetchOptions = {
      method: 'GET',
      headers: {
        ...this.GENERAL_HEADERS,
        Authorization: this.userAuth.token
      }
    }

    return this.fetchApiCall(`${this.BACKEND_URL}/route/selectedRoute/pause`, options)
  }

  public resumeRoute() {
    const options: IFetchOptions = {
      method: 'GET',
      headers: {
        ...this.GENERAL_HEADERS,
        Authorization: this.userAuth.token
      }
    }

    return this.fetchApiCall(`${this.BACKEND_URL}/route/selectedRoute/resume`, options)
  }

  public stopRoute() {
    const options: IFetchOptions = {
      method: 'GET',
      headers: {
        ...this.GENERAL_HEADERS,
        Authorization: this.userAuth.token
      }
    }

    return this.fetchApiCall(`${this.BACKEND_URL}/route/selectedRoute/stop`, options)
  }

  public addToStoredRoutes(route: IRoute, customName: string) {
    // const routeName = encodeURIComponent(fromName)
    const options: IFetchOptions = {
      method: 'PUT',
      headers: {
        ...this.GENERAL_HEADERS,
        Authorization: this.userAuth.token
      },
      body: JSON.stringify(route)
    }

    return this.fetchApiCall(`${this.BACKEND_URL}/route/storedRoutes?key=${customName}`, options)
  }

  public getStoredRoutes(): Promise<IStoredRoute[]> {
    const options: IFetchOptions = {
      method: 'GET',
      headers: {
        ...this.GENERAL_HEADERS,
        Authorization: this.userAuth.token
      }
    }

    return this.fetchApiCall(`${this.BACKEND_URL}/route/storedRoutes`, options)
  }

  public deleteStoredRoute(key: string): Promise<void> {
    const options: IFetchOptions = {
      method: 'DELETE',
      headers: {
        ...this.GENERAL_HEADERS,
        Authorization: this.userAuth.token
      }
    }

    const query = convertObjectToQueryString({ key })

    return this.fetchApiCall(`${this.BACKEND_URL}/route/storedRoutes${query}`, options)
  }

  public fetchPhoneLocations(): Promise<IGeoServiceLocation[]> {
    const options = {
      method: 'GET'
    }

    return fetch(
      'https://api.mlab.com/api/1/databases/locationupdates/collections/phonelocations?apiKey=6j20x7E8rCWKAPz7nci4mgFbdcDoaERU&l=2000',
      options
    ).then(this.handleRestResponse)
  }

  public getDepthPolygonsInBounds(
    bounds?: LatLngBounds,
    accuracy?: number
  ): Promise<IDepthPolygon[]> {
    const options: IFetchOptions = {
      method: 'GET',
      headers: {
        ...this.GENERAL_HEADERS,
        Authorization: this.userAuth.token
      }
    }

    const topLeftLat = bounds?.getNorthEast().lat
    const topLeftLon = bounds?.getSouthWest().lng
    const bottomRightLat = bounds?.getSouthWest().lat
    const bottomRightLon = bounds?.getNorthEast().lng

    return this.fetchApiCall(
      `${
        this.BACKEND_URL
      }/static/depth?topLeftLat=${topLeftLat}&topLeftLon=${topLeftLon}&bottomRightLat=${bottomRightLat}&bottomRightLon=${bottomRightLon}&accuracy=${
        accuracy || 0
      }`,
      options
    )
  }

  public getWaveMeasurementsInBounds(bounds?: LatLngBounds): Promise<IWaveObject[]> {
    const options: IFetchOptions = {
      method: 'GET',
      headers: {
        ...this.GENERAL_HEADERS,
        Authorization: this.userAuth.token
      }
    }

    const topLeftLat = bounds?.getNorthEast().lat
    const topLeftLon = bounds?.getSouthWest().lng
    const bottomRightLat = bounds?.getSouthWest().lat
    const bottomRightLon = bounds?.getNorthEast().lng

    return this.fetchApiCall(
      `${this.BACKEND_URL}/static/rws/waveHeightMeasurement?topLeftLat=${topLeftLat}&topLeftLon=${topLeftLon}&bottomRightLat=${bottomRightLat}&bottomRightLon=${bottomRightLon}`,
      options
    )
  }

  public fetchFairwayInformation(bounds?: LatLngBounds): Promise<IFairway[]> {
    const options: IFetchOptions = {
      method: 'GET',
      headers: {
        ...this.GENERAL_HEADERS,
        Authorization: this.userAuth.token
      }
    }

    const topLeftLat = bounds?.getNorthEast().lat
    const topLeftLon = bounds?.getSouthWest().lng
    const bottomRightLat = bounds?.getSouthWest().lat
    const bottomRightLon = bounds?.getNorthEast().lng

    return this.fetchApiCall(
      `${this.BACKEND_URL}/static/fairwayInformation/?topLeftLat=${topLeftLat}&topLeftLon=${topLeftLon}&bottomRightLat=${bottomRightLat}&bottomRightLon=${bottomRightLon}`,
      options
    )
  }

  public fetchFairwayInformationID(id: string, locale?: SupportedLocales): Promise<IFairway> {
    const options: IFetchOptions = {
      method: 'GET',
      headers: {
        ...this.GENERAL_HEADERS,
        Authorization: this.userAuth.token
      }
    }
    return this.fetchApiCall(
      `${this.BACKEND_URL}/static/fairwayInformation/${id}?language=${
        localeToBELang(locale) || 'nl'
      }`,
      options
    )
  }

  public fetchCurrent(
    startForecastTimeISO: string,
    endForecastTimeISO: string
  ): Promise<RWSCurrent> {
    const options = {
      method: 'GET'
    }

    return fetch(
      `${RWS_CURRENTS_ENDPOINT}?service=WMS&request=GetCapabilities&version=1.3&layers=${RWS_CURRENTS_LAYER}&startForecastTime=${startForecastTimeISO}&endForecastTime=${endForecastTimeISO}&format=application/json`,
      options
    ).then(this.handleRestResponse)
  }

  public fetchCurrentGrade(): Promise<RWSDataLegend> {
    const options = {
      method: 'GET'
    }
    return fetch(
      `${RWS_CURRENTS_ENDPOINT}?service=WMS&request=GetLegendGraphic&version=1.3&layers=${RWS_CURRENTS_LAYER}&format=application/json`,
      options
    ).then(this.handleRestResponse)
  }

  public fetchBottlenecks(bounds?: LatLngBounds): Promise<IBottleneck[]> {
    const options: IFetchOptions = {
      method: 'GET',
      headers: {
        ...this.GENERAL_HEADERS,
        Authorization: this.userAuth.token
      }
    }

    const topLeftLat = bounds?.getNorthEast().lat
    const topLeftLon = bounds?.getSouthWest().lng
    const bottomRightLat = bounds?.getSouthWest().lat
    const bottomRightLon = bounds?.getNorthEast().lng

    return this.fetchApiCall(
      `${this.BACKEND_URL}/static/bottleneck/?topLeftLat=${topLeftLat}&topLeftLon=${topLeftLon}&bottomRightLat=${bottomRightLat}&bottomRightLon=${bottomRightLon}`,
      options
    )
  }

  public fetchJulianaKanaalTraffic(): Promise<IJulianakanaalResponse> {
    const options: IFetchOptions = {
      method: 'GET',
      headers: {
        ...this.GENERAL_HEADERS,
        Authorization: this.userAuth.token
      }
    }

    return this.fetchApiCall(`${this.BACKEND_URL}/route/julianakanaal`, options)
  }

  public fetchMultipleShipInformation(mmsiList: string[]): Promise<IShipInfo[]> {
    const options: IFetchOptions = {
      method: 'POST',
      headers: {
        ...this.GENERAL_HEADERS,
        Authorization: this.userAuth.token
      },
      body: JSON.stringify(mmsiList)
    }

    return this.fetchApiCall(`${this.BACKEND_URL}/ship/mmsilist`, options)
  }

  public getBuoysInBounds(bounds?: LatLngBounds): Promise<IBuoy[]> {
    const options: IFetchOptions = {
      method: 'GET',
      headers: {
        ...this.GENERAL_HEADERS,
        Authorization: this.userAuth.token
      }
    }

    const topLeftLat = bounds?.getNorthEast().lat
    const topLeftLon = bounds?.getSouthWest().lng
    const bottomRightLat = bounds?.getSouthWest().lat
    const bottomRightLon = bounds?.getNorthEast().lng

    return this.fetchApiCall(
      `${this.BACKEND_URL}/static/buoy?topLeftLat=${topLeftLat}&topLeftLon=${topLeftLon}&bottomRightLat=${bottomRightLat}&bottomRightLon=${bottomRightLon}`,
      options
    )
  }

  public getTrafficSignsInBounds(
    bounds?: LatLngBounds,
    locale?: SupportedLocales
  ): Promise<IWaterwaySign[]> {
    const options: IFetchOptions = {
      method: 'GET',
      headers: {
        ...this.GENERAL_HEADERS,
        Authorization: this.userAuth.token
      }
    }

    const urlBounds = leafletBoundsToTeqplayBoundsObject(bounds)
    const query = convertObjectToQueryString({
      language: locale ? localeToBELang(locale) : undefined,
      ...urlBounds
    })

    return this.fetchApiCall(`${this.BACKEND_URL}/static/trafficSign${query}`, options)
  }

  public getKilometreringInBounds(bounds?: LatLngBounds): Promise<IKilometrering[]> {
    const options: IFetchOptions = {
      method: 'GET',
      headers: {
        ...this.GENERAL_HEADERS,
        Authorization: this.userAuth.token
      }
    }

    const topLeftLat = bounds?.getNorthEast().lat
    const topLeftLon = bounds?.getSouthWest().lng
    const bottomRightLat = bounds?.getSouthWest().lat
    const bottomRightLon = bounds?.getNorthEast().lng

    return this.fetchApiCall(
      `${this.BACKEND_URL}/static/hectometer?topLeftLat=${topLeftLat}&topLeftLon=${topLeftLon}&bottomRightLat=${bottomRightLat}&bottomRightLon=${bottomRightLon}`,
      options
    )
  }

  public fetchWeather(latitude: number, longitude: number): Promise<IWeatherStation> {
    const options: IFetchOptions = {
      method: 'GET',
      headers: {
        ...this.GENERAL_HEADERS,
        Authorization: this.userAuth.token
      }
    }

    return this.fetchApiCall(
      `${this.BACKEND_URL}/weather/find?lon=${longitude}&lat=${latitude}`,
      options
    )
  }

  public fetchBridgeMovement(): Promise<IBridgeMovement[]> {
    const options: IFetchOptions = {
      method: 'GET',
      headers: {
        ...this.GENERAL_HEADERS,
        Authorization: this.userAuth.token
      }
    }

    return this.fetchApiCall(`${this.BACKEND_URL}/bridgeMovement`, options)
  }

  public fetchRecreationalRoutes(bounds?: LatLngBounds): Promise<IRecreationalRoute[]> {
    const options: IFetchOptions = {
      method: 'GET',
      headers: {
        ...this.GENERAL_HEADERS,
        Authorization: this.userAuth.token
      }
    }

    const topLeftLat = bounds?.getNorthEast().lat
    const topLeftLon = bounds?.getSouthWest().lng
    const bottomRightLat = bounds?.getSouthWest().lat
    const bottomRightLon = bounds?.getNorthEast().lng

    const queryParam = bounds
      ? `?topLeftLat=${topLeftLat}&topLeftLon=${topLeftLon}&bottomRightLat=${bottomRightLat}&bottomRightLon=${bottomRightLon}`
      : ''

    return this.fetchApiCall(`${this.BACKEND_URL}/static/recreationalRoute${queryParam}`, options)
  }

  public fetchSingleRecreationalRouteByID(id: string): Promise<IRecreationalRoute> {
    const options: IFetchOptions = {
      method: 'GET',
      headers: {
        ...this.GENERAL_HEADERS,
        Authorization: this.userAuth.token
      }
    }

    return this.fetchApiCall(`${this.BACKEND_URL}/static/recreationalRoute/${id}`, options)
  }

  public getRouteBridgePlanningOpenings(): Promise<IBridgePlanning[]> {
    const options: IFetchOptions = {
      method: 'GET',
      headers: {
        ...this.GENERAL_HEADERS,
        Authorization: this.userAuth.token
      }
    }

    return this.fetchApiCall(`${this.BACKEND_URL}/route/selectedRoute/bridgePlanning`, options)
  }

  public getRouteBridgeRequests(): Promise<IBridgeRequest[]> {
    const options: IFetchOptions = {
      method: 'GET',
      headers: {
        ...this.GENERAL_HEADERS,
        Authorization: this.userAuth.token
      }
    }

    return this.fetchApiCall(`${this.BACKEND_URL}/route/selectedRoute/bridgeRequest`, options)
  }

  public getRouteLockOpenings(): Promise<ILockPlanning[]> {
    const options: IFetchOptions = {
      method: 'GET',
      headers: {
        ...this.GENERAL_HEADERS,
        Authorization: this.userAuth.token
      }
    }

    return this.fetchApiCall(`${this.BACKEND_URL}/route/selectedRoute/lockPlanning`, options)
  }

  public getGeocodedLocation(placeName: string, locale?: string): Promise<IGeocodedLocation[]> {
    const options: IFetchOptions = {
      method: 'GET',
      headers: {
        ...this.GENERAL_HEADERS,
        Authorization: this.userAuth.token
      }
    }

    return this.fetchApiCall(
      `${this.BACKEND_URL}/route/location/geocode?placeName=${encodeURIComponent(
        placeName
      )}&locale=${locale || 'nl'}`,
      options
    )
  }

  public searchShipsByQuery(
    name: string,
    mmsi?: string,
    imo?: string,
    eni?: string,
    destination?: string,
    shipType?: string,
    teqplayId?: string,
    limit?: number,
    globalSupport?: boolean,
    onlySeaVessels?: boolean,
    showOldVessels?: boolean,
    manualTokenOverride?: string
  ): Promise<IShipInfo[]> {
    const options: IFetchOptions = {
      method: 'GET',
      headers: {
        ...this.GENERAL_HEADERS,
        Authorization: manualTokenOverride || this.userAuth.token
      }
    }

    const query = convertObjectToQueryString({
      name,
      mmsi,
      imo,
      eni,
      destination,
      shipType,
      teqplayId,
      limit,
      globalSupport: globalSupport === false ? undefined : globalSupport,
      onlySeaVessels: onlySeaVessels === false ? undefined : onlySeaVessels,
      showOldVessels: showOldVessels === false ? undefined : showOldVessels
    })

    return this.fetchApiCall(`${this.BACKEND_URL}/ship/search/query${query}`, options)
  }

  public getHardcodedTerminals(): Promise<IHardcodedTerminal[]> {
    return Promise.resolve(HardcodedTerminals)
  }

  public getHardcodedBerths(): Promise<IHardcodedBerthFile[]> {
    return Promise.resolve(NLAMSBerth)
  }

  public fetchSABPoints(bounds?: LatLngBounds, locale?: SupportedLocales): Promise<ISABPoint[]> {
    const options: IFetchOptions = {
      method: 'GET',
      headers: {
        ...this.GENERAL_HEADERS,
        Authorization: this.userAuth.token
      }
    }

    const urlBounds = leafletBoundsToTeqplayBoundsObject(bounds)
    const query = convertObjectToQueryString({
      language: locale ? localeToBELang(locale) : undefined,
      ...urlBounds
    })

    return this.fetchApiCall(`${this.BACKEND_URL}/static/sabCollectionPoint${query}`, options)
  }

  public fetchCarDropoffPoints(bounds?: LatLngBounds): Promise<ICarDropoffPoint[]> {
    const options: IFetchOptions = {
      method: 'GET',
      headers: {
        ...this.GENERAL_HEADERS,
        Authorization: this.userAuth.token
      }
    }

    const urlBounds = leafletBoundsToTeqplayBoundsObject(bounds)
    const query = convertObjectToQueryString({
      ...urlBounds
    })

    return this.fetchApiCall(`${this.BACKEND_URL}/static/cardropoff${query}`, options)
  }

  public fetchShorePower(bounds?: LatLngBounds): Promise<IShorePower[]> {
    const options: IFetchOptions = {
      method: 'GET',
      headers: {
        ...this.GENERAL_HEADERS,
        Authorization: this.userAuth.token
      }
    }

    const urlBounds = leafletBoundsToTeqplayBoundsObject(bounds)
    const query = convertObjectToQueryString({
      ...urlBounds
    })

    return this.fetchApiCall(`${this.BACKEND_URL}/static/onshorePower${query}`, options)
  }

  public bicsCheckCasco(): Promise<IShipInfo | BicsHull> {
    const options: IFetchOptions = {
      method: 'GET',
      headers: {
        ...this.GENERAL_HEADERS,
        Authorization: this.userAuth.token
      }
    }

    return this.fetchApiCall(`${this.BACKEND_URL}/bics/checkCasco`, options)
  }

  public bicsEnterHull(hull: BicsHull): Promise<BicsHull> {
    const options: IFetchOptions = {
      method: 'POST',
      headers: {
        ...this.GENERAL_HEADERS,
        Authorization: this.userAuth.token
      },
      body: JSON.stringify(hull)
    }

    return this.fetchApiCall(`${this.BACKEND_URL}/bics/enterCasco`, options)
  }

  public bicsDeleteHull(eni: string | undefined): Promise<void> {
    const options: IFetchOptions = {
      method: 'DELETE',
      headers: {
        ...this.GENERAL_HEADERS,
        Authorization: this.userAuth.token
      }
    }

    const query = convertObjectToQueryString({ eni })

    return this.fetchApiCall(`${this.BACKEND_URL}/bics/casco${query}`, options, true)
  }

  public bicsGetHulls(): Promise<BicsHull[]> {
    const options: IFetchOptions = {
      method: 'GET',
      headers: {
        ...this.GENERAL_HEADERS,
        Authorization: this.userAuth.token
      }
    }

    return this.fetchApiCall(`${this.BACKEND_URL}/bics/casco`, options)
  }

  public retrieveBICSDestinations(
    queryString: string,
    locale?: SupportedLocales
  ): Promise<BicsDestination[]> {
    const options: IFetchOptions = {
      method: 'GET',
      headers: {
        ...this.GENERAL_HEADERS,
        Authorization: this.userAuth.token
      }
    }
    const query = convertObjectToQueryString({
      query: queryString,
      language: locale ? localeToBELang(locale) : undefined
    })

    return this.fetchApiCall(`${this.BACKEND_URL}/bics/destination${query}`, options)
  }

  public retrieveBICSDestinationsList(identifiers: string[]): Promise<BicsDestination[]> {
    const options: IFetchOptions = {
      method: 'POST',
      headers: {
        ...this.GENERAL_HEADERS,
        Authorization: this.userAuth.token
      },
      body: JSON.stringify(identifiers)
    }

    return this.fetchApiCall(`${this.BACKEND_URL}/bics/destination`, options)
  }

  public getBICSTravelRoutes(): Promise<BicsVoyage[]> {
    const options: IFetchOptions = {
      method: 'GET',
      headers: {
        ...this.GENERAL_HEADERS,
        Authorization: this.userAuth.token
      }
    }

    return this.fetchApiCall(`${this.BACKEND_URL}/bics/travel`, options)
  }

  public getBICSReportingPoints(
    query?: string,
    locale?: SupportedLocales
  ): Promise<BicsReportingPoint[]> {
    const options: IFetchOptions = {
      method: 'GET',
      headers: {
        ...this.GENERAL_HEADERS,
        Authorization: this.userAuth.token
      }
    }

    const queryString = convertObjectToQueryString({
      query: query,
      language: locale ? localeToBELang(locale) : undefined
    })

    return this.fetchApiCall(`${this.BACKEND_URL}/bics/reportingPoint${queryString}`, options)
  }

  public resolveBICSReportingPoint(providerId: string): Promise<BicsReportingPoint | undefined> {
    const options: IFetchOptions = {
      method: 'GET',
      headers: {
        ...this.GENERAL_HEADERS,
        Authorization: this.userAuth.token
      }
    }

    const queryString = convertObjectToQueryString({ providerId })

    return this.fetchApiCall(
      `${this.BACKEND_URL}/bics/reportingPoint${queryString}`,
      options,
      undefined,
      true
    )
  }

  public getBICSCargoByQuery(queryString: string, locale?: SupportedLocales): Promise<BicsCargo[]> {
    const options: IFetchOptions = {
      method: 'GET',
      headers: {
        ...this.GENERAL_HEADERS,
        Authorization: this.userAuth.token
      }
    }

    const query = convertObjectToQueryString({
      query: queryString,
      language: locale ? localeToBELang(locale) : undefined
    })

    return this.fetchApiCall(`${this.BACKEND_URL}/bics/goods${query}`, options)
  }

  public getBICSCargoList(
    identifiers: string[],
    locale?: SupportedLocales
  ): Promise<BicsDestination[]> {
    const options: IFetchOptions = {
      method: 'POST',
      headers: {
        ...this.GENERAL_HEADERS,
        Authorization: this.userAuth.token
      },
      body: JSON.stringify(identifiers)
    }
    const query = convertObjectToQueryString({
      language: locale ? localeToBELang(locale) : undefined
    })
    return this.fetchApiCall(`${this.BACKEND_URL}/bics/goods${query}`, options)
  }

  public createBICSTravel(travel: BicsVoyage): Promise<BicsVoyage> {
    const options: IFetchOptions = {
      method: 'POST',
      headers: {
        ...this.GENERAL_HEADERS,
        Authorization: this.userAuth.token
      },
      body: JSON.stringify(travel)
    }

    return this.fetchApiCall(`${this.BACKEND_URL}/bics/travel/create`, options)
  }

  public updateBICSTravel(travel: BicsVoyage): Promise<BicsVoyage> {
    const options: IFetchOptions = {
      method: 'POST',
      headers: {
        ...this.GENERAL_HEADERS,
        Authorization: this.userAuth.token
      },
      body: JSON.stringify(travel)
    }

    return this.fetchApiCall(`${this.BACKEND_URL}/bics/travel/update`, options)
  }

  public deleteBICSTravel(travelId: string): Promise<BicsVoyage> {
    const options: IFetchOptions = {
      method: 'DELETE',
      headers: {
        ...this.GENERAL_HEADERS,
        Authorization: this.userAuth.token
      }
    }

    const query = convertObjectToQueryString({ travelId })
    return this.fetchApiCall(`${this.BACKEND_URL}/bics/travel/delete${query}`, options, true)
  }

  public startBICSTravel(travelId: number): Promise<void> {
    const options: IFetchOptions = {
      method: 'GET',
      headers: {
        ...this.GENERAL_HEADERS,
        Authorization: this.userAuth.token
      }
    }

    const query = convertObjectToQueryString({ travelId })
    return this.fetchApiCall(`${this.BACKEND_URL}/bics/travel/start${query}`, options, true)
  }

  public getBICSTravelStatus(travelId: number): Promise<void> {
    const options: IFetchOptions = {
      method: 'GET',
      headers: {
        ...this.GENERAL_HEADERS,
        Authorization: this.userAuth.token
      }
    }

    const query = convertObjectToQueryString({ travelId })
    return this.fetchApiCall(`${this.BACKEND_URL}/bics/travel/status${query}`, options, true)
  }

  public stopBICSTravel(travelId: number): Promise<void> {
    const options: IFetchOptions = {
      method: 'GET',
      headers: {
        ...this.GENERAL_HEADERS,
        Authorization: this.userAuth.token
      }
    }

    const query = convertObjectToQueryString({ travelId })
    return this.fetchApiCall(`${this.BACKEND_URL}/bics/travel/stop${query}`, options, true)
  }

  public pauseBICSTravel(travelId: number): Promise<void> {
    const options: IFetchOptions = {
      method: 'GET',
      headers: {
        ...this.GENERAL_HEADERS,
        Authorization: this.userAuth.token
      }
    }

    const query = convertObjectToQueryString({ travelId })
    return this.fetchApiCall(`${this.BACKEND_URL}/bics/travel/pause${query}`, options, true)
  }

  public getBICSVesselTypesByQuery(
    queryString: string,
    showConvoyTypes?: boolean,
    locale?: SupportedLocales
  ): Promise<BicsShipType[]> {
    const options: IFetchOptions = {
      method: 'GET',
      headers: {
        ...this.GENERAL_HEADERS,
        Authorization: this.userAuth.token
      }
    }

    const query = convertObjectToQueryString(
      {
        query: queryString,
        convoy: showConvoyTypes,
        language: locale ? localeToBELang(locale) : undefined
      },
      true
    )
    return this.fetchApiCall(`${this.BACKEND_URL}/bics/vesselType${query}`, options)
  }

  public getAllCompetenceAreasIn(bounds?: LatLngBounds): Promise<IOperatingArea[]> {
    const options: IFetchOptions = {
      method: 'GET',
      headers: {
        ...this.GENERAL_HEADERS,
        Authorization: this.userAuth.token
      }
    }

    const urlBounds = leafletBoundsToTeqplayBoundsObject(bounds)
    const query = convertObjectToQueryString({
      ...urlBounds
    })
    return this.fetchApiCall(`${this.BACKEND_URL}/static/competenceArea${query}`, options)
  }

  public getWaterHeight(bounds?: LatLngBounds): Promise<IWaterHeight[]> {
    const options: IFetchOptions = {
      method: 'GET',
      headers: {
        ...this.GENERAL_HEADERS,
        Authorization: this.userAuth.token
      }
    }

    const topLeftLat = bounds?.getNorthEast().lat
    const topLeftLon = bounds?.getSouthWest().lng
    const bottomRightLat = bounds?.getSouthWest().lat
    const bottomRightLon = bounds?.getNorthEast().lng

    return this.fetchApiCall(
      `${this.BACKEND_URL}/static/euris/waterHeightMeasurement?topLeftLat=${topLeftLat}&topLeftLon=${topLeftLon}&bottomRightLat=${bottomRightLat}&bottomRightLon=${bottomRightLon}`,
      options
    )
  }

  public getWaterHeightDetails(id: string): Promise<IWaterHeightMeasurements> {
    const options: IFetchOptions = {
      method: 'GET',
      headers: {
        ...this.GENERAL_HEADERS,
        Authorization: this.userAuth.token
      }
    }
    return this.fetchApiCall(
      `${this.BACKEND_URL}/static/euris/waterHeightMeasurement/${id}`,
      options
    )
  }
}

export default TeqplayApiService
