import * as React from 'react'
import * as ReactDOM from 'react-dom'
import Modal from 'react-modal'
import Popup from 'react-popup'
import { QueryClient, QueryClientProvider } from 'react-query'
import { Provider } from 'react-redux'
import { Route, Switch } from 'react-router'
import { BrowserRouter } from 'react-router-dom'
import { Slide, ToastContainer } from 'react-toastify'
import { PersistGate } from 'redux-persist/integration/react'
import { HelmetProvider } from 'react-helmet-async'

import CreateAccountPage from './pages/createAccountPage/CreateAccountPage'
import ForgotPasswordPage from './pages/forgotPasswordPage/ForgotPasswordPage'
import LoginPageContainer from './pages/loginPage/LoginPage'
import Routes from './routes/Routes'
import AuthenticationWrapper from './services/AuthenticationWrapper/AuthenticationWrapper'
import PrivacyPolicy from './pages/privacyPolicy/PrivacyPolicy'

import configureStore from './utils/configureStore'
import { isCordovaApp } from './utils/cordovaUtils'
import { initializeSentry } from './utils/sentry'
import { AnalyticsProvider } from './services/AnalyticsWrapper/AnalyticsWrapper'

import 'leaflet/dist/leaflet.css'
import 'react-toastify/dist/ReactToastify.css'
import './style/index.scss'
import './style/popup-styling.scss'

Modal.setAppElement('#root')

function onDeviceReady() {
  const queryClient = new QueryClient()
  const { store, persistor } = configureStore()

  initializeSentry()

  ReactDOM.render(
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <PersistGate loading={null} persistor={persistor}>
          <AnalyticsProvider>
            <HelmetProvider>
              <ToastContainer autoClose={5000} transition={Slide} position="bottom-left" />
              <Popup
                closeBtn={true}
                escToClose={true}
                closeOnOutsideClick={true}
                closeHtml={<div className="icon-close_privacy" />}
              />

              <BrowserRouter>
                <AuthenticationWrapper>
                  {apiService => (
                    <Switch>
                      <Route
                        exact
                        path="/register"
                        render={() => <CreateAccountPage teqplayAPIService={apiService} />}
                      />
                      <Route
                        exact
                        path="/forgot"
                        render={() => <ForgotPasswordPage teqplayAPIService={apiService} />}
                      />
                      <Route exact path="/privacy" render={() => <PrivacyPolicy />} />
                      <Route
                        exact
                        path="/login"
                        render={() => <LoginPageContainer teqplayAPIService={apiService} />}
                      />
                      <Route render={() => <Routes teqplayAPIService={apiService} />} />
                    </Switch>
                  )}
                </AuthenticationWrapper>
              </BrowserRouter>
            </HelmetProvider>
          </AnalyticsProvider>
        </PersistGate>
      </QueryClientProvider>
    </Provider>,
    document.getElementById('root')
  )
}

if (isCordovaApp) {
  document.addEventListener('deviceready', onDeviceReady, false)
} else {
  onDeviceReady()
}
