import { divIcon as DIVIcon, LatLngBounds } from 'leaflet'
import * as React from 'react'

import TeqplayApiService from '../../../../services/TeqplayAPIService/TeqplayApiService'
import DefaultMarkersLayer from './defaultMarkerLayer/DefaultMarkerLayer'

import { ILockDetails } from '../../../../services/TeqplayAPIService/TeqplayApi'
import { getIconNameByType } from '../../../../utils/iconUtils'

interface IProps {
  name: string
  checked: boolean
  bounds: LatLngBounds | undefined
  teqplayApiService: TeqplayApiService
  mapZoomLevel: number
  minimumZoomLevel: number
  setSelectedItem: (item: ILockDetails) => void
  disableInteraction?: boolean
}

const LocksLayer: React.FunctionComponent<IProps> = props => {
  if (props.mapZoomLevel < props.minimumZoomLevel) {
    return null
  }

  return (
    <DefaultMarkersLayer<ILockDetails>
      fetchCall={(bounds?: LatLngBounds) => props.teqplayApiService.fetchLocks(bounds)}
      fontIconClass="icon-lock"
      backgroundColor="#2e6771"
      onClick={props.setSelectedItem}
      determineDivIcon={i => {
        return DIVIcon({
          className: '',
          html: `<div class='circle-icon lock purple'><span class=${getIconNameByType(
            'LOCK'
          )}></span></div>`,
          iconAnchor: [20, 20],
          iconSize: [40, 40]
        })
      }}
      {...props}
    />
  )
}

export default LocksLayer
