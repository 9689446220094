const englishLocale = {
  app: 'RiverGuide',
  appTitle: 'RiverGuide Binnenvaart',
  version: 'Version %{version}',
  save: 'Save',
  yes: 'Yes',
  no: 'No',
  proceed: 'Continue',
  confirm: 'Confirm',
  proceedAndConfirm: 'Confirm and continue',
  cancel: 'Cancel',
  close: 'Close',
  now: 'Now',
  loading: 'Loading',
  processing: 'Processing',
  required: 'Required',
  delete: 'Delete',
  generalError: 'Unfortunately, something went wrong. Please try again later or contact us.',
  noInfoAvailable: 'No information available',
  empty: 'Empty',
  disclaimer: {
    title: 'Riverguide Binnenvaart is under development, be careful!',
    content:
      'Keep your eyes on the waterway and obey the law. There is a possibility that the routes are incomplete, incorrect, dangerous or prohibited. Data may not always be real-time and location precision may not always be sufficient. Teqplay, its clients and its data suppliers are not responsible for damage resulting from the use of this application.',
    agree: 'Understood'
  },
  fillInAllFields: 'Please fill in all fields',
  unknownValue: 'Unknown value',
  privacy: 'Privacy',
  title: {
    createAccount: 'Account request',
    settings: 'Settings',
    weather: 'Weather',
    routeSelection: 'Route planner',
    routeSelectionPicker: 'Routes for %{from}%{via} to %{to}',
    routeSelectionPickerVia: 'via %{number} locations',
    navigation: 'Navigation active',
    navigationPoint: '%{distance} · %{time}',
    routePaused: 'Route paused'
  },
  locale: {
    selectLocale: 'Language',
    nl_NL: 'Dutch',
    en_GB: 'English',
    fr_FR: 'French',

    nl_NL_native: 'Nederlands',
    en_GB_native: 'English',
    fr_FR_native: 'Française'
  },
  login: {
    login: 'Login',
    welcome: {
      morning: 'Good morning, welcome back!',
      afternoon: 'Good afternoon, welcome back!',
      evening: 'Good evening, welcome back!',
      night: 'Welcome back!',
      day: 'Good to see you again!'
    },
    developMode: 'Linked to %{url}',
    welcomeBack: 'Hello %{name}',
    email: 'Email',
    password: 'Password',
    submit: 'Send',
    registerLink: 'Request a new account',
    forgot: 'Forgot password?',
    wrongCredentials:
      'Something went wrong with logging in, please try requesting a new account or contact us',
    back: 'Back to login',
    forgotPassword: 'Request a new password with your username',
    username: 'Username',
    successForgot: 'Success!',
    forgotPasswordSuccess:
      'If you have an account for RiverGuide, an email has been sent to your email address with further instructions.'
  },
  register: {
    createAccount: 'Register an account',
    submit: 'Register',
    backToLogin: 'Back to login',
    name: "Skipper's name",
    emailAddress: 'Email',
    password: 'Password',
    mmsiNumber: 'MMSI number',
    repeatPassword: 'Repeat password',
    phoneNumber: 'Phone number',
    shipName: 'Ship name',
    ship: 'Ship',
    helpName:
      'If we cannot find your ship with your MMSI number, we will use the ship name to search for it.',
    comment: 'Extra message',
    passwordsNotMatch: 'Passwords do not match',
    fieldsRequired: 'Please fill in all required fields (*)',
    alreadyExists: 'An account already exists for this email address',
    mandatory: 'Required field',
    confirmAccount: 'Confirm your account',
    readApproveGDPR: 'Click to read the privacy policy',
    accepted: 'Privacy policy accepted',
    confirmed: 'Registration request received',
    activeAISTransponderRequired:
      'You need an active AIS transponder to use RiverGuide Binnenvaart',
    awaitActivation:
      'We have received your request successfully. Before the account can be used, it must be approved. You will receive a confirmation email about this.',
    help: {
      PERSONAL_INFO: 'We would like to know your name and email address to create an account.',
      SHIP: 'We use the name of your ship to personalize the application.',
      SHIP_DIMENSIONS:
        'To determine where you can and cannot pass, we need the dimensions of your ship.'
    },
    step: 'Step',
    steps: {
      PERSONAL_INFO: 'Personal information',
      SHIP: 'Ship information',
      SHIP_DIMENSIONS: 'Ship dimensions',
      CONFIRM: 'Confirm'
    },
    back: 'Back',
    cancel: 'Cancel',
    next: 'Next',
    mmsiError: {
      empty: 'Select a ship',
      oldAISTitle: 'Outdated AIS signal',
      oldAISFirstLine: 'Your AIS signal was last received %{time}.',
      oldAISSecondLine: 'Make sure you have a working and active AIS transponder.',
      oldAISThirdLine:
        'Without an active AIS signal, RiverGuide Binnenvaart will not function as expected, and it will not be able to show you correctly on the map.',
      oldAISFourthLine:
        'As soon as your AIS transponder sends a signal and we receive your signal, your location will be updated immediately',
      oldAISFifthLine: 'Are you sure you want to continue?',
      noMMSI:
        'The selected ship has no MMSI, please choose another ship or contact support@teqplay.nl'
    },
    gdpr: {
      title: 'Privacy RiverGuide Binnenvaart',
      privacyBodyFirstLine: 'We want you to understand what data we collect and use.',
      privacyBodySecondLine:
        'When you use RiverGuide, you trust us with your data. It is our responsibility to make clear what exactly we collect and how we use it to improve our services for you.',
      privacyBodyThirdLine:
        "When you use RiverGuide, we collect data to provide and improve our services. When you sign up for a RiverGuide account, we store the account information you provide. This includes your ship's name, name, email address and phone number.We also store information about location and your device.",
      privacyBodyFourthLine:
        'The data is available to Dutch waterway managers and Teqplay. The purpose of using the data is to realize smooth, safe, and easy use of the waterways. Your data is used to provide the service, manage your user account, inform you about (new) services, and answer your questions.',
      privacyBodyFifthLine:
        'You have the means in RiverGuide to manage your privacy, you can view your data yourself, delete your account and withdraw your consent. After termination of your account, personal data will be deleted and anonymized. Personal data is kept as long as you have an account.',
      privacyBodySixthLine:
        "We protect our and your data and applications against unauthorized access or modification. That's why we encrypt many of our services with SSL and evaluate our data collection, storage, and processing practices.We limit access to personal data to employees and waterway managers who need this data to provide our service.",
      privacyBodySeventhLine: 'For questions about privacy, please contact: ',
      accept: 'Accept',
      deny: 'Decline',
      error: 'You must accept the privacy terms to use RiverGuide Binnenvaart.',
      view: 'Read terms',
      close: 'Close'
    }
  },
  routeSelection: {
    selectRoutePlaceholder: 'Select your route',
    selectRoute: 'Select route',
    startingPoint: 'Departure point',
    viaPoint: 'Via point',
    destinationPoint: 'Destination',
    selectDestination: 'Select your destination',
    selectStartingPoint: 'Select your departure point',
    selectViaPoint: 'Select a via point',
    options: 'Options',
    calculateRoute: 'Calculate route',
    arrival: 'Arrival',
    departure: 'Departure',
    searchOnLocation: 'Search by location',
    noOptions: 'No locations found',
    searchAdvice: 'Search by location',
    currentLocation: 'Current location',
    selectOnMap: 'Select location on map',
    distance: 'Distance',
    departureTime: 'Departure time',
    arrivalTime: 'Arrival time',
    viewList: 'List display',
    startRoute: 'Start',
    saveRoute: 'Save',
    cancel: 'Cancel',
    list: 'Route',
    info: 'Info',
    details: 'Details',
    map: 'Map',
    notAllowedDimensions: 'This route is not navigable with the entered ship dimensions',
    notAllowedBridge: 'Not all bridges can be passed with the entered ship height',
    noRoutesFoundWarning: 'No routes found for this route',
    noDetailsFoundWarning: 'No details available for this object',
    routeDetails: 'Route information',
    routeStoredSucces:
      'The route has been saved. When selecting the locations, you will see the saved routes.',
    showSavedRoutes: 'Show saved routes',
    hideSavedRoutes: 'Hide saved routes',
    confirmDeleteStoredRoute: 'Are you sure you want to delete Route "%{key}"?',
    fromTo: '%{from} to %{to}',
    dateSaved: 'Saved on',
    saveRouteTitle: 'Save route',
    noNameFilled: 'Enter a name to save the route',
    routeNameInput: 'Route name',
    selectionWarning: 'Select a departure point and destination',
    pauseRoute: 'Pause route',
    resumeRoute: 'Resume route',
    noLocationWarning:
      'Turn on your GPS to start the route. Once your location is visible on the map, the route can be started!',
    shipDepth: 'Shallowest point',
    shipHeight: 'Lowest clearance',
    maxWidth: 'Narrowest point',
    waitingTime: 'Expected wait time',
    duration: 'Total duration',
    openInfo: 'View info',
    closeInfo: 'View map',
    reverse: 'Swap start and destination',
    deleteRoute: 'Delete route',
    routeOptions: 'Options',
    avoidMajorRoutes: 'Avoid major waterways',
    avoidMajorRoutesInfo: 'Prefer smaller routes over larger waterways where possible.',
    staandeMast: 'Mast-up route',
    createGeolocation: '%{value} - Search for location',
    speed: 'Average speed',
    saveSpeedModal: 'Enter your average speed',
    saveSpeedError: 'Enter a valid value between 0 and 50 km/h',
    saveSpeedErrorKnots: 'Enter a valid value between 0 and 27 knots',
    searchingFor: 'Searching for %{placeName}...',
    notFound: 'Place could not be found',
    timeInvalid: 'Entered date and time is not valid',
    timeInvalidUsingOther: 'Entered date and time is not valid, %{time} will be used',
    savedRouteNameAlreadyExists:
      'You already have a saved route named "%{key}", change the name of the route to save the route.',
    over: 'Over %{time}'
  },
  routeList: {
    itemDetails: {
      cannot_pass_bridge: 'Be careful! Your ship does not fit under this bridge.',
      communication: 'Communication',
      vhf: 'VHF',
      control_times: 'Operating hours',
      yes: 'Yes',
      no: 'No',
      NAP: 'NAP',
      opening: 'Opening',
      type: 'Type',
      opening_width: 'Width',
      width: 'Width',
      length: 'Length',
      closed_height: 'Closed height',
      opened_height: 'Opened height',
      telephone: 'Phone',
      startTime: 'Start date',
      endTime: 'End date',
      lock_chamber: 'Lock chamber',
      max_width: 'Max. Width (m)',
      threshold_outside: 'Threshold down/outside',
      threshold_inside: 'Threshold up/inside',
      address: 'Address',
      from: 'From',
      to: 'to',
      wholeDay: 'All day',
      name: 'Name',
      bridge_type: {
        VST: 'Fixed',
        DR: 'Swing',
        BC: 'Bascule',
        OPH: 'Drawbridge',
        HEF: 'Lifting',
        DBC: 'DBC'
      },
      bridge_status: {
        open: 'This bridge is currently open.',
        closed: 'This bridge is currently closed.',
        unknown: 'The current status of this bridge is unknown.'
      },
      bridge_operating_rules: {
        today: 'today',
        tomorrow: 'tomorrow',
        holiday: 'Holiday',
        noService: 'No operation'
      },
      no_items_found: 'No details available for this object',
      waitingTime: '%{waitingTime} waiting time expected for next %{objectType}',
      waitingTimeSelected: '%{waitingTime} waiting time expected for this %{objectType}',
      note: 'Note',
      bridgeOpeningNote: 'Note about bridge opening',
      chamberNote: 'Note about lock chamber',
      waterLevel: 'Water level',
      operable: 'Operable',
      arrival: 'Arrival',
      departure: 'Passing time',
      phone: 'Phone',
      status: {
        OPEN: 'This bridge is currently open',
        CLOSED: 'This bridge is currently closed',
        PLANNED: 'Bridge opening planned',
        OPENSHORT: 'Bridge is open',
        CLOSEDSHORT: 'Bridge is closed',
        PLANNEDSHORT: 'Bridge opening planned'
      },
      plannedOpeningTimes: 'Planned bridge opening times',
      infoBridgeHasToOpen: 'Possible bridge opening required to pass',
      openingRequiredBMS:
        'A bridge opening is required for your ship. This will be automatically requested.',
      openingRequestedBMS: 'A bridge opening has been requested for your expected arrival time',
      openingPlanned: 'Opening requested, the bridge will open soon',
      openingOpened: 'This bridge is currently open',
      openingRequestPaused:
        'Bridge opening required, resume your route to have it automatically requested',
      bmsOpening: {
        status: 'Status',
        plannedOpening: 'Planned opening time',
        plannedClosing: 'Planned closing time',
        direction: 'Direction',
        UPSTREAM: 'Upstream',
        DOWNSTREAM: 'Downstream',
        certainty: {
          PLANNED: 'Possible',
          EXPECTED: 'Probable',
          APPROVED: 'Almost certain',
          CANCELLED: 'Canceled',
          CURRENT: 'Current',
          TERMINATED: 'Performed'
        }
      },
      schematicView: 'Schematic representation',
      heightToWater: 'All heights using %{referenceLevel} as reference.'
    },
    objectTypes: {
      BRIDGE: 'bridge',
      LOCK: 'lock',
      START: 'start',
      END: 'end',
      NOTIFICATION: 'notification',
      WATERMETER: 'water gauge',
      TRAFFIC_SIGN: 'traffic sign',
      BOTTLENECK: 'narrows',
      VHFSECTOR: 'VHF sector',
      UNKNOWN: 'unknown object'
    }
  },
  navigationPage: {
    destination: 'Destination',
    arrival: 'Arrival',
    next_object: 'Next object',
    no_user_location_warning: 'No GPS location found',
    no_notifications: 'There are no notifications for the current navigation location',
    list_view: 'Route',
    notifications: 'Notifications',
    ship_speed: 'Speed',
    route_paused_warning: 'Route is paused',
    stop_route_confirmation: 'Are you sure you want to stop the route?',
    pause_route: 'Pause',
    stop_route: 'Stop route',
    resume_route: 'Resume route',
    route: 'Route',
    viewRouteItems: 'Open route information',
    closeRouteItems: 'Close route information',
    routePausedETA: 'Route is paused',
    routePausedETAInfo: 'Click here to resume your route',
    moreInformation: 'Click for more information'
  },
  modalSelectPreviousRoute: {
    question: 'Do you want to continue with your previous route?',
    yes: 'yes',
    no: 'no'
  },
  confirmDimensions: {
    title: 'Confirm data for %{ship}',
    length: 'Length',
    width: 'Width',
    height: 'Height',
    draught: 'Draught',
    meters: 'm',
    noShip: 'No ship data found',
    doNotAskMeToday: 'Do not ask again today',
    patchError:
      'An error occurred while sending your new ship dimensions, routes are calculated with old dimensions',
    fillInAllFields: 'Please fill in all dimension data'
  },
  pages: {
    welcome: 'Welcome',
    navigation: 'Route planner',
    navigationActive: 'Current route',
    settings: 'Settings',
    logout: 'Logout',
    anonymousLogout: 'Back to registration',
    weather: 'Weather forecast',
    information: 'Information',
    announcements: 'E-notifications',
    ais: 'AIS information',
    privacy: 'Privacy'
  },
  map: {
    followButton: 'Center ship',
    speedButton: 'Set speed',
    activelayers: 'Select map layers',
    category: {
      categoryBerths: 'Berths',
      categoryFairway: 'Waterways',
      categoryElements: 'Elements',
      maptype: 'Map'
    },
    limitedRange: 'Coverage limited',
    limitedRangeMessage: 'Map layer %{layerCode} is only available in the following regions:',
    limitedRangePost: 'This information can be found in the Information Menu later.',
    limitedRangePostPagination: 'Menu > Information > Map layer information',
    limitedRangeOneTime: 'This message will be displayed only once.',
    limitedRangePolygonCoverage:
      'Red areas on the map have no coverage from this or other map layers.',
    dataPlanWarning: 'High data usage possible',
    dataPlanMessage:
      'Map layer %{layerCode} contains information that may use a lot of data. Monitor your data usage.',
    dataPlanUsage: '%{layerCode} uses approximately %{data}/%{unit}',
    dataPlanOneTime: 'This message will be displayed only once',
    dataPlanDataUsage: 'Data usage (average)',
    dataPlanSingleTime: 'once when the map layer is turned on',
    dataPlanSwipeUnload: 'every swipe to not yet loaded map area',
    dataPlanSwipe: 'per swipe',
    dataPlanIgnore: 'By clicking "%{proceed}" this message will not be shown again.',
    layers: {
      crossings: 'Nodes',
      suitable_berths: 'Suitable berths',
      public_berths: 'Public berths',
      terminal_berths: 'Terminal berths',
      fairways: 'Maximum speed',
      CEMTClass: 'CEMT Class',
      fairwayDepth: 'Waterway depth (Friesland)',
      recreationalClass: 'Recreational Class',
      electricRoutes: 'Electric routes',
      inlandHarbours: 'Marinas',
      chargingStations: 'Charging stations',
      bridges: 'Bridges',
      locks: 'Locks',
      drinkwaterPoints: 'Drinking water points',
      kilometrering: 'Kilometrage',
      wasteWaterStations: 'Onshore waste water',
      AISShips: 'AIS Ships',
      depths: 'Depth',
      recentDraught: 'Depth (Wadden Sea)',
      waveHeight: 'Wave height',
      currents: 'Current',
      bottlenecks: 'Narrowings',
      futureAIS: 'AIS Julianakanaal prediction',
      waterHeight: 'Water level',
      buoys: 'Buoys',
      waterwaySigns: 'Traffic signs',
      weather: 'Weather',
      basMessages: 'Maritime messages',
      recreationalRoutes: 'Recreational routes',
      sabPoints: 'Waste collection points',
      carDropoffPoints: 'Car drop-off points',
      shorePower: 'Shore power',
      berths: 'Berths',
      operatingAreas: 'Operating areas'
    },
    berthsOutside: 'Rural berths',
    contractDepth: 'Contract depth',
    levelDepth: 'Level depth',
    speed: {
      title: 'Set the speed for calculating arrival times',
      useShip: 'Use ship speed',
      useCustom: 'Set manually',
      setRealisticSpeed: 'Set a speed between 1 and 50 km/h',
      saved: 'The change has been saved'
    },
    details: 'details',
    notification: {
      tooMuchBerths: 'Too many berths found, zoom in further to display the berths.',
      approachVdjsHeader: 'VDJS Node',
      approachingVdjsCrossing:
        'You are approaching node %{crossingName}. Recommended routes are available from Varen Doe je Samen for this node.',
      approachingVdjsCrossingShort: 'You are approaching node %{crossingName}',
      show: 'Show on map',
      channelNotificationHeader: 'Busy waterway',
      nearingChannelNotification:
        'You are approaching %{channelName}. Be careful when sailing, there is a lot of commercial traffic here.',
      zoomInNotification: 'Zoom in to see the following map layers on the map:',
      title: 'Waterway notifications',
      viaPointHeader: 'You are approaching a via point on your route',
      endOfRouteHeader: 'Destination reached',
      endOfRouteSubtitle: 'You are within %{radius}m of your destination',
      clickForMoreInfo: 'Click for more information...',
      clickForLessInfo: 'Click to close more information'
    },
    chargingStationPopup: {
      chargingPossibilities: 'Charging options',
      unavailable: 'Not available',
      connections: 'connections'
    },
    measurementDate: 'Measurement date',
    depthNap: 'Depth MSL',
    depthMv: 'Depth mid waterway',
    description: 'Description',
    depthLegendTitle: 'Depth in meters (LAT)',
    depthLegendAboveLAT: 'above LAT',
    depthLegendBelowLAT: 'below LAT',
    depthPopupTitle: 'Depth measurement',
    depthPopupDate: 'Date',
    waveHeight: {
      name: 'Location',
      height: 'Height',
      timeHeightLastUpdated: 'Last update'
    },
    currents: {
      current: 'Flow in',
      kmh: 'km/h',
      ms: 'm/s',
      error: 'An unknown error occurred, please try again.',
      now: '~ Now'
    },
    futureAIS: {
      estimatedPosition: 'Estimated position %{time}',
      icon: 'Estimated position %{time}',
      timeSeconds: 'in %{seconds} seconds',
      timeMinutes: 'in %{minutes} minutes',
      notInsideArea:
        'You are not near the Julianakanaal. To use this map layer, you must be near the Julianakanaal.',
      noFutureTrace: 'No prediction could be made for your ship in the Julianakanaal.',
      noRoute:
        'The prediction can only be made if you have an active route through the Julianakanaal.',
      extrapolatedTime: 'Position time',
      kmh: 'km/h',
      knots: 'knots',
      explain: 'Ships with a red outline have a predicted position that may differ.'
    },
    waterHeight: {
      name: 'Measurement point',
      height: 'Water level',
      timeHeightLastUpdated: 'Last update'
    },
    buoys: {
      buoy: 'Buoy'
    },
    waterwaySigns: {
      sign: 'Traffic sign',
      description: 'Description',
      anchoring: 'Anchored in'
    },
    crossings: {
      route: 'Recreational route',
      sea: 'Deep-sea route',
      moreInformation: 'More information',
      viewMap: 'View map'
    },
    inlandHarbours: {
      city: 'City',
      longStayPlaces: 'Long stay places',
      shortStayPlaces: 'Short stay places',
      suppliesFuel: 'Fuel available'
    },
    fairways: {
      maxSpeed: 'Maximum speed',
      moreInformation: 'More information'
    },
    drinkwaterPoints: {
      markerLand: 'Onshore drinking water point',
      markerWater: 'On-water drinking water point',
      Boat: 'Boat',
      Land: 'Land'
    },
    bridges: {
      solid: 'Fixed bridge',
      open: 'Open bridge',
      closed: 'Closed bridge',
      unknown: 'Bridge without open/close sensor',
      default: 'Movable bridge',
      opening: 'Opening',
      type: 'Type',
      heightClosed: 'Closed height',
      vhf: 'VHF',
      phoneNumber: 'Phone',
      operable: 'Operable',
      openingTimes: 'Opening hours',
      yes: 'Yes',
      no: 'No'
    },
    recreationalRoutes: {
      sailTowardsRoute: 'Navigate to this route'
    }
  },
  settings: {
    title: 'Settings',
    titleProfile: 'Profile information',
    titleShip: 'Ship information',
    myShip: 'My ship',
    username: 'Username',
    usernameUnableChange: 'Cannot be changed',
    password: 'Password',
    optional: 'Optional',
    name: 'Name',
    email: 'Email',
    emailError: 'This is not a valid email address',
    mobile: 'Phone number',
    mobileError: 'This is not a valid phone number',
    shipError: 'This ship does not have an MMSI and cannot be used',
    unsavedChanges: 'You have not saved your changes yet.',
    currentPassword: 'Current password',
    newPassword: 'New password',
    repeatNewPassword: 'Repeat new password',
    changePassword: 'Change password',
    fillInAllFields: 'Fill in all fields',
    save: 'Save',
    newPasswordNoMatch: 'The new passwords do not match',
    updatedPasswordSuccess: 'Your password has been changed',
    wrongPassword: 'Incorrect current password',
    weakPassword: 'Your password is too weak',
    titleDeleteAccount: 'Delete profile',
    selectAShip: 'Select a ship',
    updateProfileSuccess: 'Settings have been updated',
    updateProfileFailed:
      'There was an error while saving your profile, please try again later or contact support@teqplay.nl',
    noChangesToSave: 'There are no changes to save',
    areYouSureDeleteAccount:
      'Are you sure you want to delete your profile? This action cannot be undone.',
    removeSuccess: 'Profile has been deleted',
    no: 'No',
    yes: 'Yes',
    yesDelete: 'Yes, delete',
    cancel: 'Undo changes',
    speedUnit: 'Speed unit',
    kmh: 'kilometers per hour',
    knots: 'knots',
    ship: {
      ship: 'Ship',
      info: 'Routes are determined based on your ship dimensions where possible.'
    },
    theme: {
      style: 'Application style',
      theme: 'Theme',
      light: 'Light',
      dark: 'Dark',
      listenToDeviceTheme: 'Auto adjust',
      listenToDeviceThemeInfo:
        'Based on your device settings, the application determines whether to display a light or dark theme.'
    },
    other: 'Other',
    warnings: 'Warnings',
    resetWarnings: 'Reset warning popups',
    resetWarningsInfo:
      'By resetting the warning popups, warnings for Map Layers with potentially high data usage and Map Layers with limited coverage will be shown again if a map layer is turned on.',
    resetWarningsSuccess: 'All warnings reset',
    selectLanguage: 'Select language',
    currentLanguage: 'Current language',
    passwordTips: {
      title: 'Password requirements',
      match: 'Passwords are the same',
      noMatch: 'Passwords are not the same',
      requirements: 'Password requirements',
      requirementsInfo:
        'We want your password to be as secure as possible to protect you and your data. Therefore, we have these requirements for your password.',
      tips: 'Tips for a secure password',
      tipsInfo:
        'You are free to choose the content of your password, but we also have some tips to make it as secure as possible.',
      tipsCasing: 'Use both lowercase and uppercase letters',
      tipsNumber: 'Use a number',
      tipsSpecialCharacters: 'Use a special character such as !@#$%',
      tipsLength: 'Use 8 characters or more',
      tipsUnique: "Use a unique password that you don't use anywhere else",
      tipsUniqueSubtitle:
        "This is of course not mandatory and we don't know or check due to your encrypted password.But for the highest security standards, we recommend using a unique password.",
      tipsNoPersonalInfo: 'Do not use easily guessable personal information in your password',
      tipsStoreSafely: 'Securely store your passwords',
      tipsStoreSafelySubtitle:
        'We recommend using a password manager. You can also write down the password and hide it well, although this is less secure than using a password manager.',
      allMet: 'Look, this is safe, well done! 👍',
      abideToAll: 'Your new password does not meet the requirements'
    }
  },
  feedbackPage: {
    description: 'Description',
    descriptionText: 'Add a description of your feedback',
    location: 'Location',
    locationText: 'Select a location on the map where your feedback or comment is about.',
    photo: 'Photo',
    photoText: 'Add a photo for the relevant location',
    uploadText: 'Drag or click to add a photo',
    sendFeedback: 'Send feedback',
    warningMessage: 'Enter a description of your feedback',
    successMessage: 'Your feedback has been sent, thank you for your contribution!',
    errorMessage:
      'Something went wrong while sending your feedback, apologies for the inconvenience.',
    done: 'Done',
    chooseLocation: 'Click here to choose a location on the map'
  },
  mobile: {
    navigation_active: 'Navigation active',
    navigation_active_destination: 'Navigation to %{destination} active',
    offline_title: 'No connection',
    offline_text: 'There is no connection, your route will be paused.',
    high_speed_title: 'You are going too fast',
    high_speed_text:
      'Are you still navigating? You are going faster than 80 km/h, stop the route when you are no longer sailing in the app.'
  },
  mapLayers: {
    last_updated: 'Last update',
    past: 'ago',
    meters: ' meters',
    shipMarker: {
      width: 'Width',
      length: 'Length',
      heading: 'Course'
    },
    sabPoints: {
      point: 'Waste collection point',
      telephoneNumber: 'Phone number',
      mobileNumber: 'Mobile phone number'
    },
    carDropoffPoints: {
      point: 'Car drop-off point'
    },
    shorePower: {
      point: 'Shore power point'
    },
    berthLayer: {
      withCones: 'With %{cones} or more cones',
      free: 'Free berth',
      occupied: 'Occupied berth',
      full: 'Full berth',
      unknown: 'Berth with unknown occupancy',
      occupationPercentage: 'Occupancy',
      occupationDisclaimer: 'Calculation by Blauwe Golf Verbindend',
      ship: 'Ship',
      shipsInBerth: 'Ships in berth',
      unknownLength: 'Unknown dimensions',
      interpretationWarning:
        'Ships with an information icon are interpreted, the exact location and orientation may vary.',
      meters: ' meters',
      width: 'Width',
      length: 'Length',
      draught: 'Draught',
      berthName: 'Berth name',
      owner: 'Administrator',
      dangerousGoodsLevel: 'Cones',
      harbourName: 'Port name',
      terminalName: 'Terminal name',
      type: 'Type',
      mooringOptions: 'Mooring options',
      mooringServices: 'Mooring services',
      mooringFunctions: 'Mooring features',
      mooringHourTimeLimit: 'Mooring time limit',
      shoreConnection: 'Shore connection',
      sort: 'Type',
      viewFotoURL: 'View photo of berth',
      berth: 'Berth',
      conesAmount: 'Number of blue cones',
      publicBerth: 'Public berth',
      terminalBerth: 'Terminal berth',
      suitableError: 'Ship not found, cannot find suitable berth',
      shipTypes: {
        shipTypes: 'Ship type(s)',
        AKR: 'Anchor',
        DBKV: 'Loaded barge',
        DBKL: 'Empty barge',
        MVSC: 'Motor freighter Container/bulk carrier',
        MVSB: 'Motor freighter Bulk',
        ALLE: 'All ship types (car drop-off point)',
        DWB: 'Push boat',
        FDR: 'Feeder',
        MTS: 'Motor tanker (liquid cargo)',
        SLB: 'Tugboat',
        BVA: 'Inland shipping',
        ZVA: 'Seafaring'
      }
    }
  },
  pushNotification: {
    buttonClose: 'Okay'
  },
  months: {
    jan: 'January',
    feb: 'February',
    mar: 'March',
    apr: 'April',
    may: 'May',
    jun: 'June',
    jul: 'July',
    aug: 'August',
    sep: 'September',
    oct: 'October',
    nov: 'November',
    dec: 'December'
  },
  weather: {
    title: 'Weather',
    windDirection: 'Wind direction',
    windSpeed: 'Wind speed',
    gusts: 'Wind gusts',
    station: 'Weather station',
    speedUnit: 'km/h',
    date: 'Date',
    source: 'Source',
    loading: 'Loading...',
    noLocationError: 'Unable to fetch weather forecast, your location is unknown.',
    airPressure: 'Air pressure',
    airHumidity: 'Humidity',
    sightMeters: 'Visibility (m)',
    temprature10cm: 'Temperature',
    rainMiliMetersPerHour: 'Rainfall per hour',
    distanceTo: '~%{distanceTo}km %{heading}'
  },
  information: {
    information: 'Information',
    privacyStatement: 'Privacy statement',
    view: 'View privacy policy',
    dataSources: 'Data sources',
    downloadPrivacyStatement: 'Download Privacy statement',
    sourceExplainer:
      'For the creation of the map layers, RiverGuide relies on the data provided, which is why some map layers do not have full coverage over the Netherlands.',
    layerInformation: 'Map layer information',
    layer: 'Map layer',
    source: 'Sources',
    coverageArea: 'Coverage areas',
    translationNotice: 'Certain information is being translated automatically'
  },
  unit: {
    kmh: 'km/h',
    knots: 'knots'
  },
  time: {
    hourShort: 'h',
    hourFullSingle: 'hour',
    hourFullPlural: 'hours',
    minShort: 'min',
    minFullSingle: 'minute',
    minFullPlural: 'minutes',
    dayShort: 'd',
    daySingle: 'day',
    dayPlural: 'days'
  },
  routeSimulator: {
    title: 'Route Simulator',
    speed: 'Speed',
    distanceLeft: 'Distance',
    eta: 'ETA',
    interval: 'Interval',
    start: 'Start',
    pause: 'Stop',
    simulationStarted: 'Simulation started',
    simulationFinished: 'Simulation has ended, the route is finished',
    polylineError: 'No positions found in the polyline, simulation terminated.',
    speedError:
      'Set speed is 0 m/s, cannot perform simulation. (AUTO) speed is not supported in the Route Simulator.',
    startOfRoute: 'From the beginning of the route',
    startFromIndex: 'From index %{index} on the route'
  },
  source: {
    area: {
      AT: 'Austria',
      ATDONAU: 'Austrian Danube',
      BE: 'Belgium',
      BEANR: 'Port of Antwerp (BE)',
      BEVLA: 'Flanders (BE)',
      BEWAL: 'Wallonia (BE)',
      BENOO: 'North Sea (BE)',
      DE: 'Germany',
      DEWAD: 'Wadden Sea (DE)',
      FR: 'France',
      EU: 'Europe',
      LU: 'Luxembourg',
      NL: 'Netherlands',
      NLDR: 'Province of Drenthe',
      NLFL: 'Province of Flevoland',
      NLGE: 'Province of Gelderland',
      NLGR: 'Province of Groningen',
      NLLI: 'Province of Limburg',
      NLNB: 'Province of North Brabant',
      NLNH: 'Province of North Holland',
      NLOV: 'Province of Overijssel',
      NLUT: 'Province of Utrecht',
      NLZL: 'Province of Zeeland',
      NLZH: 'Province of South Holland',
      NLIJS: 'IJsselmeer',
      NLMAR: 'Markermeer',
      NLNOO: 'North Sea',
      NLWAD: 'Wadden Sea',
      NLAMS: 'Port of Amsterdam',
      NLRTM: 'Port of Rotterdam',
      NLMAJOR: 'Major Dutch waterways',
      NLGOUWE: 'Gouwe',
      NLHOLLANDSDIEP: 'Hollands Diep',
      NLJULIANAKANAAL: 'Julianakanaal (Limburg)',
      NLMERWEDEKANAAL: 'Merwedekanaal',
      NLNIEUWEWATERWEG: 'New Waterway',
      NLOOSTERSCHELDE: 'Eastern Scheldt',
      NLOUDERIJN: 'Old Rhine',
      NLRIJN: 'Rhine',
      NLWAAL: 'Waal',
      NLWESTERSCHELDE: 'Western Scheldt'
    }
  },
  phoneNumberButton: {
    title: 'Phone number'
  },
  notAllowedToPerform: 'You are not allowed to perform this action.',
  urlDiffers: 'Account is registered on another server %{url}',
  loggedOutDueToURLMismatch:
    'You have been logged out because this account is not registered on this server.',
  tutorial: {
    routePlanning: {
      title: 'How are routes determined?',
      intro:
        'Routes are determined based on the ship dimensions and any options you have indicated in the Options menu.',
      intro2:
        'If the route requested by you is not navigable, the app may still display a route but with a red warning, or it may not show any routes at all. Please check the dimensions of your ship and verify if the route is indeed navigable (for a ship of your size).',
      feedback:
        'If the route is navigable but the app does not display it or displays it incorrectly, please send us an email at: riverguide@teqplay.nl',
      yourDimensions: 'Your ship dimensions:',
      dimensions: 'Length: %{length}m, Width: %{width}m, Height: %{height}m, Draught: %{draught}m',
      changeDimensions: 'Change ship dimensions'
    }
  },
  prominentDisclosure: {
    title: 'Location usage',
    approve: 'I agree',
    reject: 'I refuse',
    explanation: 'This app uses location data to enable the following functions:',
    feature1: 'Navigation along a route',
    feature2: 'Calculating routes to/from your location',
    feature3: 'Calculating arrival times while navigating',
    feature4: 'Automatically requesting bridge openings',
    feature5: 'Displaying your location on the map',
    background:
      'The app may use these functionalities even when the app is in the background or closed.',
    notProperly: 'Not allowing these permissions may cause the app to not function as intended.'
  },
  shipSearch: {
    placeholder: 'Ship',
    noName: 'Unnamed ship',
    searchExtended: 'Search for a ship by name, MMSI, or ENI number',
    noResults: 'No ships found for this search query',
    lastAISSignal: 'AIS signal last received at %{time}'
  },
  shipTypes: {
    PILOT_VESSEL: 'Pilot vessel',
    SEARCH_AND_RESCUE_VESSEL: 'Search and rescue vessel',
    TUG: 'Tugboat',
    PORT_TENDER: 'Port tender',
    VESSEL_WITH_ANTI_POLLUTION_FACILITIES: 'Anti-pollution vessel',
    LAW_ENFORCEMENT_VESSEL: 'Police vessel',
    MEDICAL_TRANSPORT: 'Medical transport',
    NOT_PARTIES_TO_AN_ARMED_CONFLICT: 'Non-combatant ship',
    PASSENGER: 'Passenger',
    CARGO: 'Cargo',
    TANKER: 'Tanker',
    HIGHSPEED: 'High-speed',
    WING_IN_GROUND: 'Ekranoplan',
    OTHER_TYPE: 'Other type',
    PASSENGER_HAZCAT_A: 'Passenger HAZMAT A',
    PASSENGER_HAZCAT_B: 'Passenger HAZMAT B',
    PASSENGER_HAZCAT_C: 'Passenger HAZMAT C',
    PASSENGER_HAZCAT_D: 'Passenger HAZMAT D',
    HIGHSPEED_HAZCAT_A: 'High-speed HAZMAT A',
    HIGHSPEED_HAZCAT_B: 'High-speed HAZMAT B',
    HIGHSPEED_HAZCAT_C: 'High-speed HAZMAT C',
    HIGHSPEED_HAZCAT_D: 'High-speed HAZMAT D',
    TANKER_HAZCAT_A: 'Tanker HAZMAT A',
    TANKER_HAZCAT_B: 'Tanker HAZMAT B',
    TANKER_HAZCAT_C: 'Tanker HAZMAT C',
    TANKER_HAZCAT_D: 'Tanker HAZMAT D',
    CARGO_HAZCAT_A: 'Cargo HAZMAT A',
    CARGO_HAZCAT_B: 'Cargo HAZMAT B',
    CARGO_HAZCAT_C: 'Cargo HAZMAT C',
    CARGO_HAZCAT_D: 'Cargo HAZMAT D',
    OTHER_TYPE_HAZCAT_A: 'Other type HAZMAT A',
    OTHER_TYPE_HAZCAT_B: 'Other type HAZMAT B',
    OTHER_TYPE_HAZCAT_C: 'Other type HAZMAT C',
    OTHER_TYPE_HAZCAT_D: 'Other type HAZMAT D',
    WING_IN_GROUND_HAZCAT_A: 'Ekranoplan HAZMAT A',
    WING_IN_GROUND_HAZCAT_B: 'Ekranoplan HAZMAT B',
    WING_IN_GROUND_HAZCAT_C: 'Ekranoplan HAZMAT C',
    WING_IN_GROUND_HAZCAT_D: 'Ekranoplan HAZMAT D',
    FISHING: 'Fishing vessel',
    TOWING: 'Tugboat',
    TOWING_BIG: 'Large tugboat',
    DREDGING_UNDERWATER_OPS: 'Dredger',
    DIVING_OPS: 'Diving operations',
    MILITARY_OPS: 'Military operations',
    SAILING: 'Sailboat',
    PLEASURE_CRAFT: 'Recreational vessel',
    NOT_AVAILABLE: 'Not available',
    SPARE: 'Reserved',
    UNDEFINED: 'Undefined',
    BASESTATION: 'Base station',
    MOTOR_YACHT: 'Motor yacht',
    SPEEDBOAT: 'Speedboat',
    SAILING_WITH_MOTOR: 'Motor sailer',
    SPORT_FISHERMEN_VESSEL: 'Sport fishing vessel',
    SAILING_MOTOR_BIG: 'Large motor sailer',
    OTHER_RECREATIONAL_VESSEL: 'Other recreational vessel'
  },
  announcements: {
    announcements: 'E-notifications',
    welcome: 'Welcome to RiverGuide E-notifications',
    introduction:
      'To use E-notifications in RiverGuide Binnenvaart, your linked ship needs a valid ENI number',
    continue: 'Continue',
    editShip: 'Adjust ship',
    goBack: 'Go back',
    editHull: 'Adjust hull',
    editMainHull: 'Adjust main hull',
    viewHulls: 'Hulls',
    optional: 'Optional',
    eniCheck: {
      checking: 'Determine registration permission',
      valid: 'ENI number valid',
      missing: 'ENI number missing, please fill it in on the next step',
      notUsable: 'You cannot use E-notifications via RiverGuide',
      alreadyLinked: '%{ship} is already registered to another account.',
      editShip: 'Adjust your ship or contact support@teqplay.nl'
    },
    startNavigation: 'Start navigating',
    navigationTitle: 'Do you want to calculate a route between these points?',
    navigationSuffix:
      'This only starts the navigation function in RiverGuide; your trip can be registered via the "Register trip" button.',
    isHullAddress: 'Hull address',
    hullUpdatedAgo: '%{distance} refreshed',
    validRoute: 'This route is valid',
    editRoute: 'Edit',
    addRoute: 'Add route',
    error: {
      present:
        'Errors have been detected in the entered information. Please check all fields again.',
      requiredEmpty: 'This field is mandatory',
      requiredEmptyNonZero: 'This field is mandatory with a value greater than 0',
      addressRequired:
        'Address is optional, but all fields except Company Name are mandatory if one is filled in',
      requiredDateEmpty: 'Specifying the date is mandatory',
      requiredTimeEmpty: 'Specifying the time is mandatory',
      requiredDateTimeEmpty: 'Specifying the date and time is mandatory',
      requiredRoute: 'Requires arrival + departure',
      minAmount: '%{amount} is less than the minimum allowed number of %{minAmount}',
      maxAmount: '%{amount} is more than the maximum allowed number of %{maxAmount}',
      minCargoAmount: 'At least 1 cargo must be associated with a trip',
      fetchError: 'Something went wrong while fetching the data',
      dangerousMismatch: 'Your selected cargo is hazardous, select %{yes} or change the cargo type',
      noTravelId: 'There is no trip ID linked, the action cannot be performed at this time',
      awaitingAction: 'Action cannot be performed yet because another action is ongoing',
      exceededHullCargoCapacity:
        'You have exceeded the total cargo capacity of your hull (%{cargoCapacityTn} Tn), all cargo combined has a weight of %{totalCargoWeight} Tn',
      hasActiveVoyage: 'You must end your current trip before you can perform this action.',
      hasActiveVoyageNoStart:
        'You must end your current trip before saving and registering your trip. You can save your trip and register it later.',
      exceededMaxDraught: 'The maximum draught is 99,99m',
      invalidDate: 'Date is not valid',
      invalidNumberValue: 'Number is not valid',
      invalidENILength: 'ENI number always consists of 8 digits.',
      invalidENILengthLeadingZero:
        'Hint: ENI numbers from the Netherlands, Belgium, France, Germany, and Switzerland start with a 0.',
      cantBeFloat: 'Number cannot consist of decimal values',
      noMainHull: 'Account has no main hull',
      sameMainHullENI: 'A hull already exist with this ENI',
      noEniOnHull: 'Hull has no ENI number',
      noMMSIOnHull: 'Hull has no MMSI number',
      noHullsFound: 'No hulls found',
      noHullSelected: 'You have not selected a hull',
      noConvoyTypeSelected: 'Composition type is mandatory for a composite hull',
      unboundCargo:
        'Hull associated with single cargo(s) is no longer linked to the navigating unit.'
    },
    hullCreation: {
      required: 'Mandatory',
      titleCreate: 'Complete registration',
      titleNew: 'New hull',
      titleUpdate: 'Update hull',
      editHull: 'Change hull',
      editMainHull: 'Change main hull',
      newHull: 'New hull',
      explanation:
        'With your ENI number, we have filled in the following information. To complete your registration, fill in the following fields:',
      changeEniWarning: 'To change your ENI number, adjust your ship in the RiverGuide settings.',
      deleteHull: 'Remove hull',
      errorNoENI: 'Hull has no ENI and cannot be removed',
      warnENIEdited:
        'Your ENI has been changed but not saved, save it or undo the changes to remove the ship.',
      deleteConfirmation: 'Are you sure you want to remove this hull?',
      notMatchingMMSI:
        'The entered MMSI (%{filledMMSI}) does not match the MMSI of your AIS signal (%{aisMMSI})',
      lockedMMSI:
        'The MMSI of your main hull must match the MMSI associated with your RiverGuide account. To change this, change vessels in RiverGuide settings and create a hull for that vessel.'
    },
    travelList: {
      title: 'Entered trips',
      subtitle: 'E-notifications',
      active: 'Active and planned',
      inactive: 'Completed trips',
      startNavigation: 'Start navigating',
      newTravel: 'New trip',
      status: {
        PLANNED: 'Planned',
        SENT: 'Sent',
        ANSWERED_OK: 'Registered',
        ANSWERED_OK_CANCELLED: 'Ended',
        ANSWERED_OK_PAUSED: 'Paused',
        ANSWERED_REJECT: 'Not registered',
        PENDING: 'Pending',
        PENDING_PAUSE: 'Pending trip pause',
        UNKNOWN: 'Unknown'
      }
    },
    travel: {
      via: 'via',
      new: 'New trip',
      edit: 'Adjust trip',
      currentVoyage: 'Current trip',
      view: 'View trip',
      tripInfo: 'Trip information',
      departure: 'Departure',
      arrival: 'Destination',
      viaPoint: 'Waypoint',
      exchange: 'Swap',
      goodsOnBoard: 'Cargo on board',
      addGoods: 'Add cargo',
      viewGoods: 'Cargo',
      deleteGoods: 'Delete',
      billingInfo: 'Billing address',
      editForThisTravel: 'Adjust for this trip',
      save: 'Save',
      delete: 'Delete trip',
      useAsTemplate: 'Use trip as template for new trip',
      announce: 'Save and register trip',
      noGoodsAdded: 'No cargo added',
      editAddress: 'Click to fill in billing address',
      editForJourney: 'Adjust billing address for this trip',
      startNavigating: 'Start navigating',
      startInBics: 'Register trip',
      pauseInBics: 'Pause trip',
      stopInBics: 'Stop trip',
      deleteConfirmation: 'Are you sure you want to delete this trip?',
      sentVoyageConfirmation:
        'Fairway authorities only allow one active e-announcement at a time. Registering this may lead to rejection. Do you want to proceed?',
      started: 'Your trip has started',
      paused: 'Your trip is paused',
      stopped: 'Your trip has stopped',
      planned: 'Planned trips',
      current: 'Current trip',
      noCargo: 'No cargo on board',
      resetState: 'Clear all fields',
      noTravels: 'You have not created any trips yet',
      noAddress: 'No billing address provided',
      none: 'No address',
      removeLocalAddress: 'Delete addresses',
      deleteAddressesConfirm:
        'This will remove %{amount} %{singularplural} from your device. This does not remove the addresses linked to other trips. Are you sure?',
      addressSingular: 'address',
      addressPlural: 'addresses',
      newAddress: 'New address',
      selected: 'Selected',
      onlyExecptions: 'only usable for trips through Belgium, France, and Germany',
      variousCargo: 'to different cargoes',
      locked: 'You cannot make changes to your trip at this time',
      partialEdit: 'You can only change a few fields of this trip.',
      unsavedChanges: 'You have not saved your changes. Are you sure you want to go back?',
      unsavedChangesNew: 'You have not saved your new trip. Are you sure you want to go back?',
      viaCount: 'via %{viaCount} other destinations',
      sailingUnity: 'Navigating unit',
      hullProperties: 'Composite properties',
      addHull: 'Add hull',
      selectHull: 'Select hull',
      clickToSelectHullForCargo: 'Click to assign cargo to a hull',
      clickToAddHull: 'Click to add a hull',
      willClearSelectedHulls: 'Are you sure you want to navigate only with your main hull?',
      willSwitchToCombinedHulls: 'This will change your hull type to Composite. Are you sure?',
      unboundWarning: 'Hull %{named} is no longer linked to the trip',
      otherWeight:
        '%{hull} has a cargo capacity of %{cargoCapacity} Tn, of which used by this trip: %{totalWeight} Tn',
      combinedDimensions: 'Dimensions',
      combinedDimensionsExplain:
        'You can adjust the dimensions of your navigating unit without changing your hull dimensions.',
      overrideDimensions: 'Apply dimensions',
      overrideWithLargestLength: 'All hulls in length',
      overrideWithLargestWidth: 'All hulls in width',
      totalWidth: 'Total width',
      totalLength: 'Total length',
      createNewHullSave: 'Are you sure you want to leave this screen? Changes may not be saved.'
    },
    fields: {
      general: 'General info',
      dimensions: 'Ship dimensions',
      billingInfo: 'Billing address',
      eni: 'ENI number',
      eniShort: 'ENI',
      mmsi: 'MMSI',
      name: 'Ship name',
      nationality: 'Nationality',
      clickToSelectCountry: 'Select a country',
      ownerAddress: 'Billing address',
      type: 'Hull type',
      clickToSelectType: 'Select a hull type',
      mode: 'Transport mode',
      INLAND: 'Inland shipping',
      SEA: 'Sea shipping',
      unknown: 'Unknown',
      lengthM: 'Length',
      widthM: 'Width',
      maxDraughtM: 'Maximum draught',
      heightM: 'Height',
      lengthBowToBridgeM: 'Length bow to bow',
      cargoCapacityTn: 'Cargo capacity',
      deadWeightTn: 'Gross tonnage',
      doubleWalled: 'Double hull',
      callSign: 'Call sign',
      placeOfRegistration: 'Registration location',
      dateOfRegistration: 'Registration date',
      fuelType: 'Fuel type',
      clickToSelectFuelType: 'Select a fuel type',
      NONE: 'None',
      LNG: 'LNG',
      HYDROGEN: 'Hydrogen',
      ELECTRICITY: 'Electricity',
      LOW_SULPHUR_FUEL: 'Low-sulfur fuel',
      DIESEL: 'Diesel',
      OTHER: 'Other',
      ownerName: 'Owner',
      meter: 'meter',
      tonnage: 'Tn',
      m3: 'm³',
      transportType: 'Transport type',
      addressName: 'Name',
      givenName: 'First name',
      familyName: 'Last name',
      street: 'Street and house number',
      streetName: 'Street and house number',
      postcode: 'Postal code',
      postalCode: 'Postal code',
      city: 'City',
      country: 'Country',
      vatNumber: 'VAT number',
      organisation: 'Company name',
      organisationShort: 'Company',
      organisationHint:
        'If the company name is left empty, billing will be done in care of your hull name.',
      organisationPlaceholder: 'c/o %{hullName}',
      optional: 'Optional',
      fromLocation: 'Departure',
      toLocation: 'Arrival',
      firstReportingPoint: 'First reporting point',
      riverguideSelectedRoute: 'Route',
      startTime: 'Departure date',
      endTime: 'Arrival date',
      crewOnBoard: 'Crew on board',
      paxOnBoard: 'Passengers on board',
      personsOnBoard: 'Persons on board',
      weightTn: 'Weight',
      volumeM3: 'Volume',
      dangerousGoods: 'Hazardous substances',
      dangerous: 'Hazardous substances',
      hsCode: 'Cargo type',
      personalName: 'Name and last name',
      cones: 'Cones',
      currentDraught: 'Current draught',
      residual: 'Empty from',
      hulls: 'Hulls',
      singleHull: 'Single hull',
      multiHull: 'Composite',
      convoyType: 'Composite type',
      shipId: 'Hull',
      fullName: 'Name',
      address: 'Address',
      vatNumberShort: 'VAT #'
    },
    hullSelect: {
      title: 'Your hulls',
      subtitle: 'Click on a hull to adjust it',
      createHull: 'Create new hull',
      mainHull: 'Main hull'
    }
  },
  bicsFeature: {
    title: 'We have updated the E-Notifications!',
    description:
      'The new E-Notifications have been updated with a brand-new look and enhanced features , which you will find in the new tab section.',
    dontShowAgain: "Don't show me again",
    ok: 'Okay',
    show: 'Show E-Notifications'
  },
  countrySelect: {
    placeholder: 'Select a country',
    noOptions: 'No countries found for "%{query}"'
  },
  destinationSelect: {
    placeholder: 'Select a destination',
    placeholderDeparture: 'Select a departure location',
    placeholderVia: 'Select a via point',
    placeholderArrival: 'Select an arrival location',
    noOptions: 'No destinations found for "%{query}"',
    derivedFromProviderId: 'Automatically applied based on departure location',
    errorNotFound: 'Could not assign first reporting point based on traffic post ID %{pid}',
    warningMax: 'Maximum 5 via points',
    destinationEstimate:
      'Multiple destinations found for %{id}, the entered destination is an estimate'
  },
  goodsSelect: {
    placeholder: 'Search for cargo type',
    noOptions: 'No cargo type found for "%{query}"',
    vnNr: 'UN #',
    dangerousClass: 'Class',
    classification: 'Classifaction',
    packingGroup: 'P.gr',
    table: 'Table',
    showAll: 'Show all cargo types',
    estimateCargo: 'Multiple cargos found for %{id}, the entered cargo type is an estimate'
  },
  hullSelect: {
    placeholder: 'Select hull',
    noOptions: 'No hull found for "%{query}"',
    unboundShipId:
      'Cargo was bound to a hull no longer associated with the journey. Please select a hull again.'
  },
  transportSelect: {
    placeholder: 'Select transport type',
    BULK: 'Bulk',
    CONTAINER: 'Container',
    TANKER: 'Tanker'
  },
  shipTypeSelect: {
    placeholder: 'Select ship type',
    estimate: 'Multiple ship types were found for %{id}, the entered ship type is an estimation',
    noOptions: 'No ship type found for "%{query}"'
  },
  destinationPresetList: {
    showPresets: 'Show route locations',
    hidePresets: 'Hide route locations',
    departure: 'Departure point of route',
    viaIndex: 'Via point %{viaIndex}',
    arrival: 'Destination point of route'
  },
  datetime: {
    clickToAddDate: 'Click to select a date',
    clickToAddTime: 'Click to select a time',
    clear: 'Clear',
    date: 'Date',
    time: 'Time',
    selectDate: 'Select date',
    selectDateTime: 'Select date and time',
    fullFormat: 'MM-dd-yyyy HH:mm',
    dateFormat: 'MM-dd-yyyy'
  },
  aisPage: {
    title: 'AIS information',
    staticInfo: 'Static information',
    dynamicInfo: 'Dynamic information',
    kmh: 'kilometers per hour',
    knots: 'knots',
    lastSignal: 'AIS signal was last received %{time}.',
    lastSignalNotUptoDate:
      'Your AIS signal is not up-to-date. Check your AIS transponder to ensure it is on and functioning correctly.',
    latestDataFetched: 'Last known AIS signal successfully retrieved',
    latestDataFetchedError:
      'An error occurred while retrieving the last known AIS signal. Check your internet connection and try again.',
    refresh: 'Refresh',
    viewOnMap: 'View on map',
    aisVersion: 'AIS version',
    alternateDraught: 'Alternative draught',
    callsign: 'Call sign',
    category: 'Category',
    coms: 'COMS',
    combinationType: 'Combination type',
    communicationState: 'Communication status',
    courseOverGround: 'Course',
    dataTerminalEquipped: 'Data terminal on board',
    destination: 'Destination',
    draught: 'Draught',
    eni: 'ENI',
    eta: 'ETA',
    hazardCode: 'Hazard code',
    height: 'Height',
    id: 'ID',
    imo: 'IMO',
    imoNumber: 'IMO',
    isSeaVessel: 'Seagoing vessel',
    lastUpdate: 'Last updated',
    latitude: 'Latitude',
    length: 'Length',
    loadedStatus: 'Loading status',
    longitude: 'Longitude',
    maxDraught: 'Maximum draught',
    mmsi: 'MMSI',
    name: 'Ship name',
    posAccuracyMeters: 'Position accuracy in meters',
    positionAccuracy: 'Position accuracy',
    positionSensorType: 'Position sensor type',
    shipType: 'Ship type',
    specialManeuverIndicator: 'Special maneuver indicator',
    speedOverGround: 'Speed',
    source: 'Source',
    status: 'Status',
    timeLastUpdate: 'Last updated',
    rateOfTurn: 'Turn rate',
    role: 'Roll',
    usingRaim: 'Used RAIM',
    width: 'Width',
    visible: 'Visible'
  }
}

export default englishLocale
