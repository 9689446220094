import React, { useEffect, useState } from 'react'
import Sidebar from 'react-sidebar'

import Menu from './menu/Menu'

import { IApplicationTheme } from '../../@types/types'
import { usePrevious } from '../../hooks/usePrevious'
import { useAnalytics } from '../../services/AnalyticsWrapper/AnalyticsWrapper'
import { ISelectedRoute, IUserProfile } from '../../services/TeqplayAPIService/TeqplayApi'
import './SideMenu.scss'

interface IProps {
  activePage: string
  userProfile: IUserProfile | null
  shipName: string
  logout: () => void
  navigationRoute: ISelectedRoute | null
  theme: IApplicationTheme
  setStatusbarTheme: (theme?: IApplicationTheme) => void
}

const SideMenu = ({
  activePage,
  shipName,
  logout,
  navigationRoute,
  theme,
  setStatusbarTheme,
  userProfile
}: IProps) => {
  const analytics = useAnalytics('SideMenu')
  const [sidebarOpen, setSidebarOpen] = useState(false)

  const previousTheme = usePrevious(theme)
  const previousSidebarOpen = usePrevious(sidebarOpen)

  useEffect(() => {
    if (sidebarOpen !== previousSidebarOpen || theme !== previousTheme) {
      setStatusbarTheme(sidebarOpen ? theme : undefined)
    }
  }, [sidebarOpen, previousSidebarOpen, theme, previousTheme, setStatusbarTheme])

  return (
    <Sidebar
      sidebar={
        <Menu
          toggleSidebarOpen={() => toggleSidebar('menu')}
          activePage={activePage}
          shipName={shipName}
          logout={logout}
          navigationRoute={navigationRoute}
          userProfile={userProfile}
        />
      }
      open={sidebarOpen}
      onSetOpen={() => toggleSidebar('sidebar')}
      rootClassName="sidebar-root"
      sidebarClassName="sidebar"
      contentClassName="sidebar-content"
      overlayClassName="sidebar-overlay"
    >
      <button
        id="button-menu-select-toggle"
        className="menu-select"
        onClick={() => toggleSidebar('sidebar')}
      >
        <i className="icon-menu " />
      </button>
    </Sidebar>
  )

  function toggleSidebar(from?: string) {
    analytics.newEvent(!sidebarOpen === true ? 'open_sidebar' : 'close_sidebar', {
      isSideEffect: from === 'menu'
    })
    setSidebarOpen(!sidebarOpen)
  }
}

export default SideMenu
