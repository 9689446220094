export const appPrefixedStorageKey = (key: string) => `RiverGuideBarge-${key}`

export function loadFromLocalStorage<T>(key: string, defaultValue?: T): T | undefined {
  try {
    const value = localStorage[appPrefixedStorageKey(key)]
    return value !== undefined && value !== 'undefined' ? JSON.parse(value) : defaultValue
  } catch (err) {
    console.error('error in function loadProperty')
    console.error(err)
    return defaultValue
  }
}

export function saveToLocalStorage<T>(key: string, value: T) {
  try {
    localStorage[appPrefixedStorageKey(key)] = JSON.stringify(value)
  } catch (err) {
    console.error('error in function saveProperty  ' + err.message)
  }
}

export function removeFromLocalStorage(key: string) {
  try {
    delete localStorage[appPrefixedStorageKey(key)]
  } catch (err) {
    console.error('error in function removeProperty  ' + err.message)
  }
}
