import React from 'react'
import { Helmet } from 'react-helmet-async'
import { I18n } from 'react-redux-i18n'
import { getEnvironment } from '../../utils/constants'

interface IProps {
  title: string
}

const TitleComponent: React.FC<IProps> = ({ title }) => {
  return (
    <Helmet>
      <title>{convertTitle(title)}</title>
    </Helmet>
  )

  function convertTitle(newTitle: string) {
    const appName = `${I18n.t('app')}${
      getEnvironment() === 'production' ? '' : getEnvironment() === 'development' ? ' DEV' : ''
    }`

    return `${newTitle} - ${appName}`
  }
}

export default TitleComponent
