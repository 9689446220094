import React from 'react'
import { DateTimePicker } from '@teqplay/teqplay-ui'
import { I18n } from 'react-redux-i18n'

import MobileDateTimePicker from './MobileDateTimePicker'
import { isCordovaApp } from '../../../utils/cordovaUtils'

import '../DateTime.scss'

interface IProps {
  onDateTimeChange: (
    isoDate: string | null,
    inputValue: string,
    isValid: boolean,
    fieldName?: string
  ) => void
  icon: React.ReactElement
  value?: string
  dateSeperator?: string
  timeSeperator?: string
  dialogTitle?: string
  locale?: string
  hideDialogHeader?: boolean
  disableClock?: boolean
  timeListId?: string
  timeListStep?: number
  timeListStartLimit?: {
    hour: number
    minute: number
  }
  timeListEndLimit?: {
    hour: number
    minute: number
  }
  timeZone?: string
  dialogId?: string
  datePickerId?: string
  useInitialStart?: 'year' | 'month' | 'week' | 'day' | 'hour'
  id?: string
  placeholder?: string
  name?: string
  autoFocus?: boolean
  disabled?: boolean
}

const MobileOrRegularDateTimePicker = (props: IProps) => {
  if (isCordovaApp) {
    return <MobileDateTimePicker {...props} />
  }
  return (
    <DateTimePicker
      className="datetimepicker-input"
      dialogTitle={I18n.t('datetime.selectDateTime')}
      {...props}
    />
  )
}

export default MobileOrRegularDateTimePicker
