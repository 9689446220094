import cloneDeep from 'lodash/cloneDeep'
import { Dispatch } from 'redux'
import { setUserInputSpeed } from '../../components/mapShared/setSpeed/actions'
import {
  IBridgeMovement,
  IBridgePlanning,
  ILockPlanning,
  ISelectedRoute,
  SpeedTypes
} from '../../services/TeqplayAPIService/TeqplayApi'
import TeqplayApiService from '../../services/TeqplayAPIService/TeqplayApiService'
import { mergeTimeUpdatesInSelectedRoute } from '../../utils/routeUtils'
import { sendExceptionToSentry, setExtraValueInSentry } from '../../utils/sentry'

export function setNavigationRoute(route: ISelectedRoute) {
  setExtraValueInSentry('navigationRoute', route)
  return {
    type: 'SET_NAVIGATION_ROUTE',
    route
  }
}

export function setBridgeOpenings(
  bridgePlanning: IBridgePlanning[],
  bridgeMovement: IBridgeMovement[],
  lockPlanning: ILockPlanning[]
) {
  return {
    type: 'SET_BRIDGE_AND_LOCK_OPENINGS',
    bridgePlanning,
    bridgeMovement,
    lockPlanning
  }
}

export function clearNavigationRoute() {
  setExtraValueInSentry('navigationRoute', undefined)
  return {
    type: 'SET_NAVIGATION_ROUTE',
    route: null
  }
}

export async function updateNavigationRoute(
  dispatch: Dispatch,
  teqplayApiService: TeqplayApiService,
  speedType: SpeedTypes,
  cruiseSpeed: number
) {
  try {
    const selectedRoute = await teqplayApiService.updateSelectedRoute(speedType, cruiseSpeed)
    dispatch(setNavigationRoute(selectedRoute))
  } catch (err) {
    sendExceptionToSentry(err)
  }
}

export async function checkAndSetActiveRoute(
  dispatch: Dispatch,
  teqplayApiService: TeqplayApiService,
  redirectToHomePage: () => void
): Promise<ISelectedRoute | null> {
  try {
    const selectedRoute = await teqplayApiService.getSelectedRoute()
    if (selectedRoute.paused === true) {
      await teqplayApiService.resumeRoute()
      selectedRoute.paused = false
    }

    if (selectedRoute.cruiseSpeed) {
      await dispatch(setUserInputSpeed(selectedRoute.cruiseSpeed))
    }

    await dispatch(setNavigationRoute(selectedRoute))
    // updateBridgeOpenings(dispatch, teqplayApiService, selectedRoute)

    return selectedRoute
  } catch (err) {
    if (err.status === 404) {
      dispatch(clearNavigationRoute())
      redirectToHomePage()
      console.error(`Route cancelled not by current user, stopping inside frontend`)
    } else {
      sendExceptionToSentry(err)
    }
    return null
  }
}

export async function updateRouteEta(
  dispatch: Dispatch,
  teqplayApiService: TeqplayApiService,
  selectedRoute: ISelectedRoute,
  redirectToHomePage: () => void
) {
  try {
    const updatedEtaObject = await teqplayApiService.getSelectedRouteEtaUpdate()
    if (updatedEtaObject) {
      const updatedSelectedRoute = mergeTimeUpdatesInSelectedRoute(updatedEtaObject, selectedRoute)
      dispatch(setNavigationRoute(updatedSelectedRoute))
    }
  } catch (err) {
    if (err.status === 404) {
      dispatch(clearNavigationRoute())
      redirectToHomePage()
      console.error(`Route cancelled not by current user, stopping inside frontend`)
    } else {
      sendExceptionToSentry(err)
    }
  }
}

export async function pauseCurrentRoute(
  dispatch: Dispatch,
  teqplayApiService: TeqplayApiService,
  selectedRoute: ISelectedRoute
) {
  const route = cloneDeep(selectedRoute)
  route.paused = true
  try {
    teqplayApiService.pauseRoute()
  } catch (error) {
    console.warn(error)
  }
  dispatch(setNavigationRoute(route))
}

export async function resumeCurrentRoute(
  dispatch: Dispatch,
  teqplayApiService: TeqplayApiService,
  selectedRoute: ISelectedRoute
) {
  const route = cloneDeep(selectedRoute)
  route.paused = false
  try {
    teqplayApiService.resumeRoute()
  } catch (error) {
    console.warn(error)
  }
  dispatch(setNavigationRoute(route))
}

export async function stopCurrentRoute(dispatch: Dispatch, teqplayApiService: TeqplayApiService) {
  try {
    await teqplayApiService.stopRoute()
  } catch (error) {
    console.warn(error)
  }

  dispatch(clearNavigationRoute())
}
