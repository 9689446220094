import React, { useEffect, useState } from 'react'
import { I18n } from 'react-redux-i18n'
import { toast } from 'react-toastify'
import { useSelector } from 'react-redux'

import TeqplayApiService from '../../../services/TeqplayAPIService/TeqplayApiService'
import {
  BicsHull,
  IRoute,
  IRouteLocation,
  IShipInfo
} from '../../../services/TeqplayAPIService/TeqplayApi'
import { ILocationStatus, IRootProps, IRouteSearchProps } from '../../../@types/types'
import { sendExceptionToSentry } from '../../../utils/sentry'
import { useBICSHulls } from '../../../hooks/useBICSHulls'
import { useBICSVoyages } from '../../../hooks/useBICSVoyages'
import { useSessionStorage } from '../../../hooks/useSessionStorage'

import BICSHullList from '../bicsHullList/BICSHullList'
import BICSTravelMain from '../BicsTravelMain/BICSTravelMain'
import BICSTravelList from '../bicsTravelList/BICSTravelList'

import './BicsSelect.scss'

interface IProps {
  teqplayAPIService: TeqplayApiService
  locationStatus: ILocationStatus
  setRouteSelection: (route: IRouteSearchProps) => void
  setRouteSelectionInactive: () => void
  setFromLocation: (fromLocation: IRouteLocation | null) => void
  setToLocation: (toLocation: IRouteLocation | null) => void
  setViaRoutes: (viaRoutes: IRouteLocation[]) => void
  searchRouteSuggesions: (
    overrideAbleRouteSelection?: IRouteSearchProps,
    overridableDepartureTime?: number
  ) => void
  resetAvailableRoutes?: () => void
  BICSRoute?: IRoute | null
  setBICSRoute: (route: IRoute | null) => void
  startRoute: (route: IRoute) => void
}

function BicsSelect(props: IProps) {
  const [selectedTravelID, setSelectedTravelID] = useSessionStorage<string | undefined>(
    'travelID',
    undefined
  )
  const [selectedHullID, setSelectedHullID] = useState<string | undefined>()
  const [hull, setHull] = useState<BicsHull>()
  const { userLocation, currentUser, i18n } = useSelector((rootProps: IRootProps) => rootProps)

  const { voyages, refresh, loading } = useBICSVoyages(
    props.teqplayAPIService,
    `${currentUser.auth.username}-${hull?.name || hull?.eni}`,
    i18n.locale
  )

  const {
    hulls,
    refresh: refreshHulls,
    lastUpdatedAt: hullsLastUpdatedAt
  } = useBICSHulls(
    props.teqplayAPIService,
    `${currentUser.auth.username}-${hull?.name || hull?.eni}`
  )

  useEffect(() => {
    checkHull()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedHullID])

  const currentVoyage = voyages?.find(
    v =>
      (v.status === 'SENT' && !v.paused && !v.cancelled) ||
      (v.status === 'ANSWERED_OK' && !v.paused && !v.cancelled) ||
      (v.status === 'ANSWERED_OK' && v.paused && !v.cancelled)
  )
  const otherVoyages = voyages && voyages.filter(v => v.travelId !== currentVoyage?.travelId)

  return (
    <div className="route-select-wrapper main-menu" id="BICS-MENU">
      <div className="select-multiline-inner-wrapper">
        {selectedHullID ? (
          <BICSHullList
            mainHull={hull}
            refreshHulls={async () => {
              await refreshHulls()
              await checkHull()
            }}
            hulls={hulls}
            currentLocation={userLocation.currentLocation}
            teqplayAPIService={props.teqplayAPIService}
            selectedHullID={selectedHullID}
            setSelectedHullID={setSelectedHullID}
          />
        ) : selectedTravelID ? (
          <BICSTravelMain
            mainHull={hull}
            hulls={hulls}
            voyages={otherVoyages}
            currentVoyage={currentVoyage}
            teqplayAPIService={props.teqplayAPIService}
            updateDraughtInProfile={updateDraughtInProfile}
            travelId={selectedTravelID}
            setSelectedTravelID={setSelectedTravelID}
            setSelectedHullID={setSelectedHullID}
            setFromLocation={props.setFromLocation}
            setToLocation={props.setToLocation}
            setViaRoutes={props.setViaRoutes}
            searchRouteSuggesions={props.searchRouteSuggesions}
            setRouteSelection={props.setRouteSelection}
            BICSRoute={props.BICSRoute}
            setBICSRoute={props.setBICSRoute}
            resetAvailableRoutes={props.resetAvailableRoutes}
          />
        ) : (
          <BICSTravelList
            voyages={otherVoyages}
            currentVoyage={currentVoyage}
            loading={loading}
            refresh={refresh}
            teqplayAPIService={props.teqplayAPIService}
            updateDraughtInProfile={updateDraughtInProfile}
            mainHull={hull}
            hulls={hulls}
            hullsLastUpdatedAt={hullsLastUpdatedAt}
            setRouteSelection={props.setRouteSelection}
            setSelectedTravelID={setSelectedTravelID}
            setSelectedHullID={setSelectedHullID}
            startRoute={props.startRoute}
            setRouteSelectionInactive={props.setRouteSelectionInactive}
          />
        )}
      </div>
    </div>
  )

  async function checkHull() {
    try {
      const responseShip = await props.teqplayAPIService.bicsCheckCasco()
      if ((responseShip as BicsHull)?.userId !== undefined) {
        setHull(responseShip as BicsHull)
      }
    } catch (err) {
      // Handle error depending on backend status
      if (err.status !== 404) {
        toast.error(I18n.t('generalError'))
        setHull(undefined)
        setSelectedHullID(undefined)
      }
    }
  }

  async function updateDraughtInProfile(
    currentDraught: number,
    shipDimensions: IShipInfo['dimensions']
  ) {
    try {
      await props.teqplayAPIService.patchCurrentShipInformation({
        ...shipDimensions,
        draught: currentDraught
      })
      await props.locationStatus.refreshLocation()
    } catch (err) {
      sendExceptionToSentry(err)
    }
  }
}

export default BicsSelect
