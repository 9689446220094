import { cloneDeep } from 'lodash'

/**
 * Converts a query string to an object with all keys in lowercase
 * @example
 * convertQueryStringToObject(location.search)
 */
export const convertQueryStringToObject = (query: string) => {
  let splitObject = cloneDeep(query)

  // Remove ?
  if (splitObject.charAt(0) === '?') {
    splitObject = splitObject.slice(1)
  }

  // Create array by splitting on &
  const splittedQuery = splitObject.split('&')
  const keyValueObj: { [key: string]: string } = {}
  // Make key value objects ( key=value -> {key: value} )
  splittedQuery.forEach(queryParam => {
    const keyValue = queryParam.split('=')
    if (keyValue[1]) {
      // eslint-disable-next-line prefer-destructuring
      keyValueObj[keyValue[0]] = decodeURIComponent(keyValue[1])
    }
  })

  return keyValueObj
}

/**
 * Converts an object with keys to a query string
 * @param queryObject
 */
export const convertObjectToQueryString = (
  queryObject: {
    [key: string]: string | number | boolean | undefined
  },
  passNullValues?: boolean
) => {
  if (Object.keys(queryObject).length === 0) {
    return ''
  }

  const str = `?${Object.keys(queryObject)
    .reduce((a: string[], k) => {
      const value = queryObject[k]
      if (passNullValues || value || value === 0 || value === false) {
        a.push(`${k}=${encodeURIComponent(value || '')}`)
      }
      return a
    }, [])
    .join('&')}`

  return str
}
