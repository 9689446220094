import React from 'react'
import { parseStringForID } from '../../utils/tests'
import './PhoneNumberButton.scss'

interface IProps {
  number: string | number
}

const PhoneNumberButton = (props: IProps) => {
  return (
    <div
      className="phone-number-button"
      id={`phone-number-button-${parseStringForID(props.number.toString())}`}
    >
      <a href={'tel:' + props.number}>
        <button
          className="button phone"
          id={`phone-number-button-${parseStringForID(props.number.toString())}-call-button`}
        >
          <i className="icon-phone" />
          <div className="number">{props.number}</div>
        </button>
      </a>
    </div>
  )
}

export default PhoneNumberButton
