import React from 'react'
import Modal from 'react-modal'
import { I18n, Translate } from 'react-redux-i18n'

import { kmhToKnots, knotsToKmh } from '../../../../utils/general'
import './SpeedOptions.scss'

interface IProps {
  showSpeedOptions: boolean
  cruiseSpeed: number | undefined
  setCruiseSpeed: (s: number) => void
}

const SpeedOptions = (props: IProps) => {
  const { cruiseSpeed, setCruiseSpeed, showSpeedOptions } = props
  const [showKnots, setShowKnots] = React.useState<boolean>(false)
  const [showModal, setShowModal] = React.useState<boolean>(false)
  const [tempValue, setTempValue] = React.useState<string | undefined>()
  const [validationError, setError] = React.useState<string>('')

  if (!showSpeedOptions) {
    return null
  }

  const convertedCruiseSpeedValue = cruiseSpeed
    ? showKnots
      ? Math.round(cruiseSpeed * 100) / 100
      : knotsToKmh(cruiseSpeed)
    : ''

  return (
    <div className="speed-options-wrapper">
      <div className="option-label">
        <Translate value="routeSelection.speed" className="form-label" />
      </div>
      <div className="inputs">
        <div
          className="input speed-input"
          id="speed-options-button"
          onClick={() => setShowModal(true)}
        >
          {convertedCruiseSpeedValue}
        </div>
        <div
          className="unit-toggle"
          onClick={() => setShowKnots(!showKnots)}
          id="speed-options-button-toggle-units"
        >
          <b>{I18n.t(showKnots ? 'unit.knots' : 'unit.kmh')}</b>
        </div>
      </div>
      <Modal
        isOpen={showModal}
        onRequestClose={closeModal}
        className="modal speed-options-modal"
        style={{ overlay: { backgroundColor: 'rgba(0,0,0,0.75)' } }}
      >
        <form className="speed-options-modal-content" onSubmit={e => handleSpeedChange(e)}>
          <span className="edit-speed">{I18n.t('routeSelection.saveSpeedModal')}</span>
          <div className="inputs">
            <input
              onChange={e => setTempValue(e.target.value)}
              id="speed-options-input"
              type="number"
              autoFocus
            />

            <div
              className="unit-toggle"
              onClick={() => setShowKnots(!showKnots)}
              id="speed-options-button-toggle-units"
            >
              <b>{I18n.t(showKnots ? 'unit.knots' : 'unit.kmh')}</b>
            </div>
          </div>

          {validationError && <div className="error">{I18n.t(validationError)}</div>}

          <div className="buttons">
            <button
              className="button primary large"
              onClick={handleSpeedChange}
              id="speed-options-save-button"
            >
              {I18n.t('save')}
            </button>
            <button
              className="button large"
              onClick={() => closeModal()}
              id="speed-options-cancel-button"
            >
              {I18n.t('cancel')}
            </button>
          </div>
        </form>
      </Modal>
    </div>
  )

  function closeModal() {
    setShowModal(false)
    setError('')
  }

  function handleSpeedChange(e: React.FormEvent) {
    e.preventDefault()
    e.stopPropagation()

    const rawInput = tempValue as any
    const value = Number(showKnots ? rawInput : kmhToKnots(rawInput))

    if (!rawInput) {
      closeModal()
      return
    }

    if (value <= 0 || value > 27 || isNaN(value)) {
      setError(showKnots ? 'routeSelection.saveSpeedErrorKnots' : 'routeSelection.saveSpeedError')
    } else if (value) {
      setCruiseSpeed(Math.min(Math.abs(value), 27))
      closeModal()
    }
  }
}

export default SpeedOptions
