import { syncTranslationWithStore } from 'react-redux-i18n'
import { applyMiddleware, createStore } from 'redux'
import { createMigrate, PersistConfig, persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import thunk from 'redux-thunk'

import { II18nState } from '../@types/types'
import { initialMapState } from '../components/mapShared/reducers'
import { initialSettingsState } from '../pages/settings/reducers'
import { loadFromLocalStorage } from './localStorage'
import nl_NL from '../translations/nl_NL'
import en_GB from '../translations/en_GB'
import fr_FR from '../translations/fr_FR'

import { getEnvironment } from './constants'
import { isCordovaApp } from './cordovaUtils'
import { migrations } from './migrations'
import rootReducer from './reducers'

const persistConfig: PersistConfig = {
  key: 'riverguide-binnenvaart',
  storage,
  whitelist: ['currentUser', 'map', 'settings', 'speed'],
  version: 4,
  migrate: createMigrate(migrations, { debug: !isCordovaApp }),
  debug: getEnvironment() !== 'production'
}

const initialStore = {
  currentUser: undefined,
  i18n: {
    translations: { nl_NL, en_GB, fr_FR },
    locale: loadFromLocalStorage('locale') || 'nl_NL'
  } as II18nState,
  settings: initialSettingsState,
  map: initialMapState
}

const persistedReducer = persistReducer(persistConfig, rootReducer())
const store = createStore(persistedReducer, initialStore, applyMiddleware(thunk))

// Translations
syncTranslationWithStore(store)

const varReturn = () => {
  const persistor = persistStore(store)
  return { store, persistor }
}

export default varReturn
