import * as React from 'react'
import { I18n } from 'react-redux-i18n'
import { INotificationDetails } from '../../services/TeqplayAPIService/TeqplayApi'
import { formatDateTime } from '../../utils/dates'
import PhoneNumberButton from '../phoneNumberButton/PhoneNumberButton'

interface IProps {
  itemDetails: INotificationDetails | null
  isExpanded: boolean
}

const NotificationDetails = ({ itemDetails, isExpanded }: IProps) => {
  if (!itemDetails || isExpanded === false) {
    return null
  }

  return (
    <div className="item-info-details" id={`notification-details-${itemDetails._id}`}>
      <div className="notification-inner">
        <section className="details-section">
          <div className="route-item-caption" id="notification-details-title">
            <b>{itemDetails.title}</b>
          </div>
          {itemDetails.message && (
            <div className="route-item-details-item" id="notification-details-message">
              {itemDetails.message}
            </div>
          )}
          {itemDetails.fairway.name && (
            <div className="route-item-details-item" id="notification-details-fairway">
              {itemDetails.fairway.name}
            </div>
          )}
          {itemDetails.startTime && (
            <div className="route-item-details-item" id="notification-details-start-time">
              <b>{I18n.t('routeList.itemDetails.startTime')}: </b>
              {formatDateTime(itemDetails.startTime)}
            </div>
          )}
          {itemDetails.endTime && itemDetails.endTime !== 2051226061000 && (
            <div className="route-item-details-item" id="notification-details-end-time">
              <b>{I18n.t('routeList.itemDetails.endTime')}: </b>
              {formatDateTime(itemDetails.endTime)}
            </div>
          )}
          {itemDetails.telephoneNumber ? (
            <div className="route-item-details-item phone" id="notification-details-phone">
              <div className="title">
                <b>{I18n.t('phoneNumberButton.title')}:</b>
              </div>
              <PhoneNumberButton number={itemDetails.telephoneNumber} />
            </div>
          ) : null}
          {itemDetails.origin && (
            <div className="route-item-details-item" id="notification-details-origin">
              {itemDetails.origin}
            </div>
          )}
        </section>
      </div>
    </div>
  )
}

export default NotificationDetails
