import React from 'react'

import { BicsAddress } from '../../../services/TeqplayAPIService/TeqplayApi'
import { getCountryFromCode } from '../../../utils/countriesUtil'
import { I18n } from 'react-redux-i18n'
import { confirmPopup } from '../../shared/confirmPopup/ConfirmPopup'

interface IProps {
  address: BicsAddress
  setAddress: () => void
  isSelected?: boolean
  isHullAddress?: boolean
  isTemporaryPreviousValue?: boolean

  onEditAddress?: () => void
  onDeleteAddress?: () => void
}

const AddressButton = ({
  address,
  setAddress,
  isSelected,
  isHullAddress,
  onEditAddress,
  onDeleteAddress
}: IProps) => {
  const uniqueAddressId = `${address.givenName}-${address.familyName}-${address.streetName}-${address.postalCode}-${address.city}-${address.country}`

  return (
    <>
      <button
        className={`button ${
          isSelected ? 'primary selected' : 'secondary unselected'
        } address-button`}
        type="button"
        id={`address-hull-address-${uniqueAddressId}`}
        onClick={e => {
          e.preventDefault()
          setAddress()
        }}
      >
        <div className="address-inner">
          <div className="address-row name">
            <div className="prefix">{I18n.t('announcements.fields.fullName')}</div>
            {address?.givenName} {address.familyName}
          </div>

          <div className="address-row streetName">
            <div className="prefix">{I18n.t('announcements.fields.address')}</div>
            {address?.streetName}
          </div>
          <div className="address-row postal-city">
            <div className="prefix" />
            <div className="postalCode">{address?.postalCode}</div>
            <div className="city">{address?.city}</div>
          </div>
          <div className="address-row country">
            <div className="prefix" />
            {address.country && getCountryFromCode(address.country)?.nameNL}
          </div>
          {address.organisation && (
            <div className="address-row organisation">
              <div className="prefix">{I18n.t('announcements.fields.organisationShort')}</div>
              {address.organisation}
            </div>
          )}
          {address.vatNumber && (
            <div className="address-row vatNumber">
              <div className="prefix">{I18n.t('announcements.fields.vatNumberShort')}</div>
              {address.vatNumber}
            </div>
          )}
          {isHullAddress && (
            <div className="is-hull-address">{I18n.t('announcements.isHullAddress')}</div>
          )}
        </div>
        {
          <div className="edit-buttons">
            {onEditAddress && (
              <div
                className="button inline-button edit"
                onClick={e => {
                  e.preventDefault()
                  e.stopPropagation()
                  onEditAddress()
                }}
              >
                <i className="icon-edit" />
              </div>
            )}
            {onDeleteAddress && (
              <div
                className="button inline-button delete"
                onClick={e => {
                  e.preventDefault()
                  e.stopPropagation()

                  confirmPopup({
                    message: I18n.t('announcements.travel.deleteAddressesConfirm', {
                      amount: 1,
                      singularplural: I18n.t('announcements.travel.addressSingular')
                    }),
                    onConfirm: () => onDeleteAddress()
                  })
                }}
              >
                <i className="icon-trash" />
              </div>
            )}
          </div>
        }
      </button>
    </>
  )
}

export default AddressButton
