import { divIcon as DIVIcon, LatLngBounds } from 'leaflet'
import * as React from 'react'
import { I18n } from 'react-redux-i18n'

import TeqplayApiService from '../../../../../services/TeqplayAPIService/TeqplayApiService'
import CollapsableLegendComponent from '../../../controls/CollapsableLegendComponent'
import DefaultMarkersLayer from '../defaultMarkerLayer/DefaultMarkerLayer'

import { ISABPoint } from '../../../../../services/TeqplayAPIService/TeqplayApi'
import { SupportedLocales } from '../../../../../@types/types'

interface IProps {
  name: string
  checked: boolean
  bounds?: LatLngBounds
  teqplayApiService: TeqplayApiService
  mapZoomLevel: number
  minimumZoomLevel: number
  setSelectedItem: (item: ISABPoint) => void
  locale?: SupportedLocales
  disableInteraction?: boolean
}

const SABCollectionPointsLayer: React.FunctionComponent<IProps> = props => {
  if (!props.checked) {
    return null
  }

  const color = '#2e6771'

  return (
    <React.Fragment>
      <DefaultMarkersLayer<ISABPoint>
        fetchCall={(bounds?: LatLngBounds) =>
          props.teqplayApiService.fetchSABPoints(bounds, props.locale)
        }
        determineDivIcon={() =>
          DIVIcon({
            className: '',
            html: `<div class="div-icon-marker-layer" style="background: ${color};
            color:${'#fff'}"><i class="icon-trash"/>`,
            iconAnchor: [20, 20],
            iconSize: [40, 40]
          })
        }
        onClick={props.disableInteraction ? undefined : props.setSelectedItem}
        {...props}
      />
      <CollapsableLegendComponent
        icon={<span className="icon-trash" />}
        position={'topright'}
        id={props.name}
      >
        <div className="item-legend sab">
          <div className="name">{I18n.t('mapLayers.sabPoints.point')}</div>
          <div className="icon">
            <i className="icon-trash" />
          </div>
        </div>
      </CollapsableLegendComponent>
    </React.Fragment>
  )
}

export default SABCollectionPointsLayer
