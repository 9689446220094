import { isAfter, subMinutes } from 'date-fns'
import React from 'react'
import { I18n } from 'react-redux-i18n'

import { useAnalytics } from '../../../services/AnalyticsWrapper/AnalyticsWrapper'
import { IShipInfo } from '../../../services/TeqplayAPIService/TeqplayApi'
import { knotsToKmh } from '../../../utils/general'
import { useLocalStorage } from '../../../hooks/useLocalStorage'

interface IProps {
  userLocation: IShipInfo | null
}

const ShipSpeedBox: React.FC<IProps> = props => {
  const analytics = useAnalytics('ShipSpeedBox')

  const [isKmhActive, setKmhActive] = useLocalStorage('show-kmh', false)
  const userLocation = props.userLocation
  const activityThresholdMinutes = 2

  // to knots
  const speedInKnots = Math.round(userLocation?.speedOverGround || 0)
  const speedInKMH = knotsToKmh(userLocation?.speedOverGround || 0)

  const hasRecentLocationUpdate =
    userLocation &&
    isAfter(userLocation.timeLastUpdate, subMinutes(new Date(), activityThresholdMinutes))

  if (
    userLocation &&
    userLocation.timeLastUpdate &&
    hasRecentLocationUpdate &&
    userLocation.speedOverGround &&
    userLocation.speedOverGround >= 0
  ) {
    return (
      <div
        className="tab secondary clickable"
        id="ship-speed-box-button"
        onClick={handleToggleSpeedUnit}
      >
        {isKmhActive ? speedInKMH : speedInKnots}
        {` ${I18n.t(isKmhActive ? 'unit.kmh' : 'unit.knots')}`}
      </div>
    )
  } else {
    return (
      <div
        className="tab secondary clickable"
        id="ship-speed-box-button"
        onClick={handleToggleSpeedUnit}
      >
        0 {I18n.t(isKmhActive ? 'unit.kmh' : 'unit.knots')}
      </div>
    )
  }

  function handleToggleSpeedUnit() {
    analytics.newEvent('toggle_shipspeedbox_unit', {
      activeUnit: !isKmhActive ? 'km/h' : 'knots',
      speed: userLocation?.speedOverGround
    })
    setKmhActive(!isKmhActive)
  }
}

export default ShipSpeedBox
