import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { format } from 'date-fns'
import isEqual from 'lodash/isEqual'
import { I18n } from 'react-redux-i18n'
import { toast } from 'react-toastify'

import TeqplayApiService from '../../../services/TeqplayAPIService/TeqplayApiService'
import BridgeDetails from '../../itemDetails/BridgeDetails'
import LockDetails from '../../itemDetails/LockDetails'
import NotificationDetails from '../../itemDetails/NotificationDetails'
import WatermeterDetails from '../../itemDetails/WatermeterDetails'
import LoadingIndicator from '../../loadingIndicator/LoadingIndicator'

import { useAnalytics } from '../../../services/AnalyticsWrapper/AnalyticsWrapper'
import {
  IBridgeDetails,
  IBridgeMovement,
  IBridgePlanning,
  ILockDetails,
  ILockPlanning,
  INotificationDetails,
  IRouteItem,
  IWaterMeterDetails,
  RouteItemTypes
} from '../../../services/TeqplayAPIService/TeqplayApi'
import { getBMSIcon } from '../../../utils/bridgePlanning'
import { TIME_FORMAT } from '../../../utils/constants'
import { formatTime, identifyAndParseDate } from '../../../utils/dates'
import { getIconNameByType } from '../../../utils/iconUtils'
import { parseStringForID } from '../../../utils/tests'

import '../../itemDetails/ItemDetails.scss'
import { usePrevious } from '../../../hooks/usePrevious'
import { IRootProps } from '../../../@types/types'

interface IProps {
  routeItem: IRouteItem
  bridgePlanning?: IBridgePlanning
  bridgeMovement?: IBridgeMovement
  lockPlanning?: ILockPlanning
  teqplayApiService: TeqplayApiService
  getTimeToReach: (eta: number) => string | null
  departureTime?: number | null
}

const RouteListItem = ({
  routeItem,
  bridgeMovement,
  bridgePlanning,
  lockPlanning,
  teqplayApiService,
  getTimeToReach,
  departureTime
}: IProps) => {
  const analytics = useAnalytics('RouteListItem')
  const previousRouteItem = usePrevious(routeItem)
  const locale = useSelector((props: IRootProps) => props.i18n.locale)
  const [itemDetails, setItemDetails] = useState<
    IBridgeDetails | IWaterMeterDetails | ILockDetails | INotificationDetails | null
  >(null)
  const [isExpanded, setIsExpanded] = useState<boolean>(false)
  const [isLoadingDetails, setIsLoadingDetails] = useState<boolean>(false)

  useEffect(() => {
    if (!isEqual(routeItem, previousRouteItem)) {
      if (isExpanded) {
        // Could refetch information but chose not to
        // this.fetchRouteItem(this.props.routeItem)
      } else {
        setItemDetails(null)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [routeItem, previousRouteItem])

  const impassable = routeItem.canBePassed !== undefined && routeItem.canBePassed === false
  const timeToReach =
    routeItem.eta !== '-' && typeof routeItem.eta !== 'string' && routeItem.eta
      ? getTimeToReach(routeItem.eta)
      : null
  const isBMSBridge = routeItem.bridgeType === 'BMS'
  const isPlanned =
    routeItem.type === 'BRIDGE'
      ? bridgePlanning?.bridgeRequestId
      : routeItem.type === 'LOCK'
      ? lockPlanning?.bridgeRequestId // todo: improve logic
      : undefined

  return (
    <div
      id={`route-list-item-${routeItem._id}`}
      className={`route-list-item${!routeItem.eta || routeItem.hasPassedFrontend ? ' faded' : ''}`}
    >
      {routeItem.eta && (
        <div className="item-eta" id="route-list-item-eta">
          <div className="eta-time" id="absolute-time">
            {routeItem.eta === '-' ? '-' : format(identifyAndParseDate(routeItem.eta), TIME_FORMAT)}
          </div>
          {timeToReach && (
            <div className="time-to-reach" id="relative-time">
              {timeToReach}
            </div>
          )}
        </div>
      )}

      <div
        className={'item-info'}
        onClick={() => {
          onExpandRouteItem(routeItem)
        }}
        id="route-list-item-button-expand"
      >
        {getDot(routeItem.type)}

        <div
          className={`info-wrapper${routeItem.openingRequired ? ' openingRequired' : ''}${
            isExpanded ? ' expanded' : ''
          }${impassable ? ' isImpassable' : ''}`}
          id={`route-list-item-header-${routeItem._id}`}
        >
          <div className="row">
            <div className="item-identifier">
              <span
                id="route-list-item-icon"
                className={`${getIconNameByType(routeItem.type, {
                  ...routeItem,
                  status: bridgeMovement?.status
                })} item-detail-icon`}
              />
              <span id="route-list-item-title" className="item-title">
                {routeItem.name}
                {bridgeMovement &&
                  (bridgeMovement.status === 'OPEN' || bridgeMovement.status === 'CLOSED') && (
                    <div className="status">
                      <div className={`dot ${bridgeMovement.status}`} />
                      <span className="message">
                        {I18n.t(`routeList.itemDetails.status.${bridgeMovement.status}SHORT`)}
                      </span>
                    </div>
                  )}
              </span>
            </div>

            {routeItem && routeItem.vhfChannel ? (
              <span className="vhf-sign">
                <div className="vhf-sign-content" id="route-list-item-vhf-channel">
                  <div>VHF</div>
                  <div>{routeItem.vhfChannel}</div>
                </div>
              </span>
            ) : null}
          </div>
          {getBridgePlannedOpeningTimes()}
        </div>
        {impassable && (
          <div
            className={`item-impassable ${isExpanded ? 'isExpanded' : ''}`}
            id="route-list-item-impassable"
          >
            <i className="icon-attention" />
            <div className="info">{I18n.t('routeList.itemDetails.cannot_pass_bridge')}</div>
          </div>
        )}
        {routeItem.openingRequired && routeItem.canBePassed && routeItem.eta && (
          <div
            className={`opening-required${isExpanded ? ' isExpanded' : ''}${
              bridgePlanning
                ? isPlanned
                  ? bridgePlanning.bridgeStatus === 'OPEN'
                    ? ' opening-opened'
                    : ' opening-planned'
                  : isBMSBridge
                  ? ' opening-bms'
                  : ''
                : ''
            }`}
            id="route-list-item-opening-required"
          >
            <i className="icon-info-1" />
            <div className="info">
              {I18n.t(
                bridgePlanning
                  ? isPlanned
                    ? bridgePlanning?.bridgeStatus === 'OPEN'
                      ? 'routeList.itemDetails.openingOpened'
                      : 'routeList.itemDetails.openingPlanned'
                    : isBMSBridge
                    ? 'routeList.itemDetails.openingRequiredBMS'
                    : 'routeList.itemDetails.infoBridgeHasToOpen' // todo: improve when lock
                  : 'routeList.itemDetails.infoBridgeHasToOpen' // todo: improve when lock
              )}
            </div>
          </div>
        )}
        {isLoadingDetails && <LoadingIndicator loading={true} />}
        {renderExpandedItem(routeItem)}
      </div>
    </div>
  )

  function renderExpandedItem(renderedRouteItem: IRouteItem) {
    switch (renderedRouteItem.type) {
      case 'BRIDGE':
        return (
          <BridgeDetails
            bridgePlanning={bridgePlanning}
            bridgeMovement={bridgeMovement}
            isExpanded={isExpanded}
            itemDetails={itemDetails as IBridgeDetails}
            departureTime={departureTime}
          />
        )
      case 'LOCK':
        return (
          <LockDetails
            isExpanded={isExpanded}
            itemDetails={itemDetails as ILockDetails}
            departureTime={departureTime}
          />
        )
      case 'WATERMETER':
        return (
          <WatermeterDetails
            isExpanded={isExpanded}
            itemDetails={itemDetails as IWaterMeterDetails}
          />
        )
      case 'NOTIFICATION':
        return (
          <NotificationDetails
            isExpanded={isExpanded}
            itemDetails={itemDetails as INotificationDetails}
          />
        )
      default:
        return null
    }
  }

  function onExpandRouteItem(item: IRouteItem) {
    analytics.newEvent('click_route_item', {
      id: item._id,
      name: item.name,
      locatableType: item.locatableType,
      isExpanded: !isExpanded
    })

    if (isExpanded) {
      setIsExpanded(false)
    } else {
      if (itemDetails) {
        setIsExpanded(true)
      } else {
        fetchRouteItem(item)
      }
    }
  }

  async function fetchRouteItem(item: IRouteItem) {
    analytics.newEvent('fetch_route_item', {
      id: item._id,
      locatableType: item.locatableType,
      name: item.name,
      uuid: item.refUuid
    })

    try {
      setIsLoadingDetails(true)
      const routeItemDetails = await getRouteItemDetails(item.type, item.refUuid)
      setItemDetails(routeItemDetails)
      setIsExpanded(true)
    } catch (error) {
      toast.warn(I18n.t('routeList.itemDetails.no_items_found'))
    } finally {
      setIsLoadingDetails(false)
    }
  }

  function getDot(itemType: IRouteItem['type']): JSX.Element | null {
    if (
      itemType === 'BRIDGE' ||
      itemType === 'LOCK' ||
      itemType === 'START' ||
      itemType === 'END'
    ) {
      if (itemType === 'BRIDGE' && isInstanceOfBridge(routeItem)) {
        const passageClass =
          routeItem.canBePassed !== undefined
            ? routeItem.canBePassed === true
              ? 'passable'
              : 'impassable'
            : 'unknown'
        return <div className={`list-item-icon ${passageClass}`} />
      } else {
        return <div className="list-item-icon" />
      }
    } else return null
  }

  function isInstanceOfBridge(object: any): object is IBridgeDetails {
    return object.type && object.type === 'BRIDGE'
  }

  async function getRouteItemDetails(
    type: RouteItemTypes,
    itemId: string
  ): Promise<IBridgeDetails | IWaterMeterDetails | ILockDetails | INotificationDetails> {
    const newItemDetails = await teqplayApiService.fetchItemDetails(type, itemId, locale)
    return newItemDetails
  }

  function getBridgePlannedOpeningTimes() {
    if (
      routeItem.type !== 'BRIDGE' ||
      !bridgePlanning ||
      !routeItem.openingRequired ||
      !routeItem.eta ||
      routeItem.hasPassedFrontend
    ) {
      return null
    } else if (
      bridgePlanning.plannedOpeningTimes &&
      bridgePlanning.plannedOpeningTimes.length > 0
    ) {
      return (
        <div
          className="row bridge-planned-opening-times"
          id={`bridge-planned-opening-times-${bridgePlanning.isrs}`}
        >
          {bridgePlanning.plannedOpeningTimes.slice(0, 3).map((ot, i) => (
            <div
              className={`planned-opening-time ${parseStringForID(ot.certainty)}`}
              id={`planned-opening-time-${bridgePlanning.isrs}-${i}`}
              key={i}
            >
              <div>
                <span>
                  <i className={getBMSIcon(ot.certainty)} />
                </span>
                <span>{formatTime(ot.plannedOpen)}</span>
              </div>
            </div>
          ))}
        </div>
      )
    } else {
      return null
    }
  }
}

export default RouteListItem
