import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import Select, { ValueType } from 'react-select'
import { toast } from 'react-toastify'
import { orderBy } from 'lodash'

import TeqplayApiService from '../../../services/TeqplayAPIService/TeqplayApiService'
import BICSDestinationRow from './BICSDestinationRow'

import { useDebounce } from '../../../hooks/useDebounce'
import { BicsDestination } from '../../../services/TeqplayAPIService/TeqplayApi'
import { selectStyles } from '../../../utils/general'
import { IRootProps } from '../../../@types/types'

import './BICSDestinationPicker.scss'

interface IProps {
  value: BicsDestination | undefined
  initialDestinationIdCode?: string
  onChange: (value: BicsDestination) => void
  teqplayAPIService: TeqplayApiService
  viaIndex?: number
  placeholder?: string
  isClearable?: boolean
  additionalId?: string
  disabled?: boolean
  travelId?: string | number
  type: 'departure' | 'via' | 'arrival'
}

const BICSDestinationPicker = ({
  value,
  initialDestinationIdCode,
  onChange,
  teqplayAPIService,
  viaIndex,
  placeholder,
  isClearable,
  additionalId,
  disabled,
  travelId,
  type
}: IProps) => {
  const [destinationOptions, setDestinationOptions] = useState<BicsDestination[]>([])
  const [searchValue, setSearchValue] = useState<string>()
  const [loading, setLoading] = useState(false)
  const locale = useSelector((s: IRootProps) => s.i18n.locale)

  const debouncedSearchValue = useDebounce(searchValue || '', 400)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedDestinationsSearch = useCallback(getDestinationsByQuery, [teqplayAPIService])

  useEffect(() => {
    if (debouncedSearchValue.length >= 3) {
      memoizedDestinationsSearch(debouncedSearchValue)
    } else {
      setDestinationOptions([])
    }
  }, [debouncedSearchValue, memoizedDestinationsSearch])

  useEffect(() => {
    if (initialDestinationIdCode) {
      resolveInitialDestinationIdToValue(initialDestinationIdCode)
    } else if (value?.srsCode) {
      resolveInitialDestinationIdToValue(value.srsCode)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialDestinationIdCode, travelId])

  return (
    <Select<BicsDestination>
      isSearchable
      value={value}
      onChange={(destination: ValueType<BicsDestination>) =>
        onChange(destination as BicsDestination)
      }
      inputValue={searchValue}
      onInputChange={(newValue, { action }) => {
        if (action !== 'menu-close' && action !== 'input-blur') {
          setSearchValue(newValue)
          setDestinationOptions([])
        }
      }}
      formatOptionLabel={destination => (
        <BICSDestinationRow destination={destination} disabled={disabled} viaIndex={viaIndex} />
      )}
      openMenuOnFocus
      placeholder={
        placeholder ||
        I18n.t(
          type === 'departure'
            ? 'destinationSelect.placeholderDeparture'
            : type === 'via'
            ? 'destinationSelect.placeholderVia'
            : type === 'arrival'
            ? 'destinationSelect.placeholderArrival'
            : 'destinationSelect.placeholder'
        )
      }
      isClearable={isClearable}
      noOptionsMessage={({ inputValue }) =>
        inputValue.length > 2 && searchValue === debouncedSearchValue && !loading
          ? I18n.t('destinationSelect.noOptions', { query: inputValue })
          : I18n.t(
              type === 'departure'
                ? 'destinationSelect.placeholderDeparture'
                : type === 'via'
                ? 'destinationSelect.placeholderVia'
                : type === 'arrival'
                ? 'destinationSelect.placeholderArrival'
                : 'destinationSelect.placeholder'
            )
      }
      styles={selectStyles}
      options={destinationOptions}
      filterOption={() => true}
      isLoading={loading}
      inputId={`destination-picker-INPUT_${additionalId}`}
      classNamePrefix="bics-destination generic-select selector"
      getOptionValue={destination => destination.name || 'no-nameLocal'}
      maxMenuHeight={250}
      isDisabled={disabled}
    />
  )

  async function resolveInitialDestinationIdToValue(id: string) {
    try {
      setLoading(true)
      const results = await teqplayAPIService.retrieveBICSDestinations(id)
      const matchedResult = results.find(r => r.srsCode === id)
      onChange(matchedResult || results[0] || null)

      if (!matchedResult && results.length > 1) {
        toast.warn(I18n.t('destinationSelect.destinationEstimate', { id }))
      }
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  async function getDestinationsByQuery(query: string): Promise<void> {
    try {
      setLoading(true)
      const results = await teqplayAPIService.retrieveBICSDestinations(query, locale)
      // Improved sorting, so when user search it will be are prio order on the query
      const sortedResults = orderBy(
        results,
        [o => o.name?.toLowerCase().startsWith(query.toLowerCase()), o => o.name?.toLowerCase()],
        ['desc', 'asc']
      )
      setDestinationOptions(sortedResults)
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }
}

export default BICSDestinationPicker
