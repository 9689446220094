import React from 'react'
import { activeClass } from '../../../utils/general'
import SwitchButton from '../../shared/switchButton/SwitchButton'

import './BICSConeSelection.scss'

interface IProps {
  cones: '0' | '1' | '2' | '3' | undefined
  setCones: (newValue: '0' | '1' | '2' | '3' | undefined) => void
  disabled?: boolean
}

const BICSConeSelection = ({ cones, setCones, disabled }: IProps) => (
  <SwitchButton isDisabled={disabled}>
    <button
      className={`button cone-button ${activeClass(cones === '0')}`}
      type="button"
      onClick={e => {
        e.preventDefault()
        setCones('0')
      }}
      disabled={disabled}
    >
      <div className="cone-images">
        <span>0x</span>
      </div>
    </button>
    <button
      className={`button cone-button ${activeClass(cones === '1')}`}
      type="button"
      onClick={e => {
        e.preventDefault()
        setCones('1')
      }}
      disabled={disabled}
    >
      <div className="cone-images">
        <span>1x</span>
        <div className="cone-image" />
      </div>
    </button>
    <button
      className={`button cone-button ${activeClass(cones === '2')}`}
      type="button"
      onClick={e => {
        e.preventDefault()
        setCones('2')
      }}
      disabled={disabled}
    >
      <div className="cone-images">
        <span>2x</span>
        <div className="cone-image" />
        <div className="cone-image" />
      </div>
    </button>
    <button
      className={`button cone-button ${activeClass(cones === '3')}`}
      type="button"
      onClick={e => {
        e.preventDefault()
        setCones('3')
      }}
      disabled={disabled}
    >
      <div className="cone-images">
        <span>3x</span>
        <div className="cone-image" />
        <div className="cone-image" />
        <div className="cone-image" />
      </div>
    </button>
  </SwitchButton>
)

export default BICSConeSelection
