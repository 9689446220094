import React, { useCallback, useEffect, useState } from 'react'
import { I18n } from 'react-redux-i18n'
import Select, { ValueType } from 'react-select'
import { toast } from 'react-toastify'
import { useSelector } from 'react-redux'

import TeqplayApiService from '../../../services/TeqplayAPIService/TeqplayApiService'

import { useDebounce } from '../../../hooks/useDebounce'
import { BicsCargo } from '../../../services/TeqplayAPIService/TeqplayApi'
import { selectStyles, sortByKey } from '../../../utils/general'
import { IRootProps } from '../../../@types/types'
import { EMPTY_CARGO_BICS_OPTION } from '../../../utils/constants'

interface IProps {
  value: BicsCargo | undefined
  initialCargoCode: string | undefined
  onChange: (value: BicsCargo | undefined) => void
  teqplayAPIService: TeqplayApiService
  dangerousGoods?: boolean
  placeholder?: string
  isClearable?: boolean
  additionalId?: string
  disabled?: boolean
}

const BICSCargoSelector = ({
  value,
  initialCargoCode,
  onChange,
  teqplayAPIService,
  dangerousGoods,
  placeholder,
  isClearable,
  additionalId,
  disabled
}: IProps) => {
  const [cargoOptions, setCargoOptions] = useState<BicsCargo[]>([])
  const [searchValue, setSearchValue] = useState<string>('')
  const [loading, setLoading] = useState(false)
  const [initialized, setInitialized] = useState(false)
  const locale = useSelector((s: IRootProps) => s.i18n.locale)

  const debouncedSearchValue = useDebounce(searchValue, 400)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedCargoSearch = useCallback(getCargoByQuery, [teqplayAPIService])

  useEffect(() => {
    if (debouncedSearchValue.length >= 2) {
      memoizedCargoSearch(debouncedSearchValue)
    } else {
      setCargoOptions([])
    }
  }, [debouncedSearchValue, memoizedCargoSearch])

  useEffect(() => {
    async function resolveCargoIdToValue(id: string) {
      try {
        setLoading(true)
        const results = await teqplayAPIService.getBICSCargoByQuery(id, locale)
        onChange(results[0] || null)

        if (results.length > 1) {
          toast.warn(I18n.t('goodsSelect.estimateCargo', { id }))
        }
        setInitialized(true)
      } catch (error) {
        console.error(error)
      } finally {
        setLoading(false)
      }
    }

    if (!initialized && initialCargoCode && !value) {
      resolveCargoIdToValue(initialCargoCode)
    } else if (value) {
      setInitialized(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialCargoCode, value])

  return (
    <Select<BicsCargo>
      isSearchable
      value={value}
      onChange={(cargo: ValueType<BicsCargo>) => onChange(cargo as BicsCargo)}
      inputValue={searchValue}
      onInputChange={(newValue, { action }) => {
        if (action !== 'menu-close' && action !== 'input-blur') {
          setSearchValue(newValue)
        }
      }}
      formatOptionLabel={(cargo, labelMeta) => (
        <div
          className={`goods-selector-row${cargo.dangerous ? ' dangerous' : ''} ${
            labelMeta.context === 'value' ? 'value' : ''
          }${disabled ? ' disabled' : ''}`}
          data-hscode={cargo.hsCode}
          data-vnnr={cargo.vnNr}
          title={cargo.name || cargo.nameLocal}
        >
          {cargo.dangerous ? (
            <>
              <div className="names">
                <div className="name main" title={cargo.name}>
                  {cargo.name}
                </div>
                {cargo.nameLocal !== cargo.name && (
                  <div className="name local" title={cargo.nameLocal}>
                    {cargo.nameLocal}
                  </div>
                )}
              </div>
              <div className="cargo-info-row">
                <div className="inline-table vnNr">
                  <div className="header">{I18n.t('goodsSelect.vnNr')}</div>
                  <div className="value">{cargo.vnNr}</div>
                </div>
                <div className="inline-table class">
                  <div className="header">{I18n.t('goodsSelect.dangerousClass')}</div>
                  <div className="value">{cargo.dangerous.dangerousClass}</div>
                </div>
                <div className="inline-table classification">
                  <div className="header">{I18n.t('goodsSelect.classification')}</div>
                  <div className="value">{cargo.dangerous.classification}</div>
                </div>
                <div className="inline-table vgr">
                  <div className="header">{I18n.t('goodsSelect.packingGroup')}</div>
                  <div className="value">{cargo.dangerous.packingGroup}</div>
                </div>
                <div className="inline-table table">
                  <div className="header">{I18n.t('goodsSelect.table')}</div>
                  <div className="value">{cargo.dangerous.table}</div>
                </div>
              </div>
            </>
          ) : (
            <>
              {cargo.hsCode === EMPTY_CARGO_BICS_OPTION.hsCode ? (
                <div className="name main">{I18n.t('announcements.travel.noGoodsAdded')}</div>
              ) : (
                <>
                  <div className="name main">{cargo.name}</div>
                  {cargo.nameLocal !== cargo.name && cargo.hsCode && (
                    <div className="name local">{cargo.nameLocal}</div>
                  )}
                </>
              )}
            </>
          )}
        </div>
      )}
      openMenuOnFocus
      placeholder={placeholder || I18n.t('goodsSelect.placeholder')}
      isClearable={isClearable}
      noOptionsMessage={({ inputValue }) =>
        inputValue.length > 2 && searchValue === debouncedSearchValue && !loading
          ? I18n.t('goodsSelect.noOptions', { query: inputValue })
          : I18n.t('goodsSelect.placeholder')
      }
      styles={selectStyles}
      options={cargoOptions.filter(g =>
        dangerousGoods === undefined
          ? g
          : dangerousGoods
          ? g.dangerous !== undefined
          : g.dangerous === undefined
      )}
      filterOption={() => true}
      isLoading={loading}
      inputId={`cargo-picker-INPUT_${additionalId}`}
      classNamePrefix={`cargo-selector ${
        value?.dangerous ? ' dangerous' : ''
      } generic-select selector`}
      getOptionValue={cargo => cargo.name || 'no-nameLocal'}
      maxMenuHeight={250}
      isDisabled={disabled}
    />
  )

  async function getCargoByQuery(query: string): Promise<void> {
    try {
      setLoading(true)
      const results = await teqplayAPIService.getBICSCargoByQuery(query, locale)
      setCargoOptions(sortByKey(results, 'name'))
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }
}

export default BICSCargoSelector
