import React, { useState } from 'react'
import { I18n } from 'react-redux-i18n'

import TeqplayApiService from '../../../services/TeqplayAPIService/TeqplayApiService'
import RouteList from '../../routeList/RouteList'
import DefaultTransition from '../../shared/defaultTransition/DefaultTransition'
import RouteDetailedInfo from './routeDetailedInfo/RouteDetailedInfo'
import RouteOptionsList from './routeOptionsList/RouteOptionsList'

import { IRoute } from '../../../services/TeqplayAPIService/TeqplayApi'
import { SCREEN_WIDTH_TO_SPLIT_VIEW } from '../../../utils/constants'

interface IProps {
  routeSuggestions: IRoute[] | null
  selectedRouteSuggestion: IRoute | null
  teqplayApiService: TeqplayApiService
  setSelectedRouteSuggestion: (routeSuggestion: IRoute | null) => void
}

const RouteOptionsSelect = ({
  routeSuggestions,
  selectedRouteSuggestion,
  teqplayApiService,
  setSelectedRouteSuggestion
}: IProps) => {
  const [windowOpened, setWindowOpened] = useState(
    window.innerWidth > SCREEN_WIDTH_TO_SPLIT_VIEW ? true : false
  )
  const [activeScreen, setActiveScreen] = useState<'INFO' | 'ROUTE'>('ROUTE')

  return (
    <div
      className={`content-wrapper-top ${
        windowOpened ? 'active-route-list' : 'inactive-route-list'
      }`}
    >
      <div className="route-select-wrapper">
        <RouteOptionsList
          routeSuggestions={routeSuggestions}
          setSelectedRouteSuggestion={setSelectedRouteSuggestion}
          selectedRouteSuggestion={selectedRouteSuggestion}
          toggleWindowOpened={() => setWindowOpened(!windowOpened)}
          handleSwitchScreens={handleSwitchScreens}
          listViewActive={windowOpened}
          activeScreen={activeScreen}
        />

        <div className="small-screen-buttons">
          {windowOpened && (
            <div
              className="single-button"
              onClick={() => handleSwitchScreens(activeScreen === 'INFO' ? 'ROUTE' : 'INFO')}
              id="route-option-button-switch"
            >
              {activeScreen === 'INFO' ? (
                <span>
                  <i className="icon-list-bullet" /> {I18n.t('routeSelection.list')}
                </span>
              ) : (
                <span>
                  <i className="icon-info-1" /> {I18n.t('routeSelection.details')}
                </span>
              )}
            </div>
          )}
          <div
            className="single-button"
            onClick={() => setWindowOpened(!windowOpened)}
            id="route-option-button-toggle"
          >
            <i className={'icon-angle-up'} />
            <span>{I18n.t('close')}</span>
          </div>
        </div>

        <DefaultTransition active={windowOpened} slideFrom="top" offset={50}>
          {props => (
            <div style={props} className="route-list-wrapper">
              {activeScreen === 'INFO' ? (
                <RouteDetailedInfo
                  route={selectedRouteSuggestion || undefined}
                  closeModal={() => setWindowOpened(!windowOpened)}
                />
              ) : (
                <RouteList
                  selectedRoute={selectedRouteSuggestion}
                  teqplayApiService={teqplayApiService}
                />
              )}
            </div>
          )}
        </DefaultTransition>
      </div>
    </div>
  )

  function handleSwitchScreens(screen: 'INFO' | 'ROUTE') {
    setActiveScreen(screen)
  }
}

export default RouteOptionsSelect
