import L from 'leaflet'
import { decode } from 'query-params'
import { isCordovaApp } from './cordovaUtils'
import {
  ILayerCodeName,
  ILayerCoverageDetails,
  IRouteSearchProps,
  MapTypes,
  SupportedLocales
} from '../@types/types'
import { BicsCargo } from '../services/TeqplayAPIService/TeqplayApi'

/**
 * Get environment from url
 */
export function getEnvironment() {
  if (isCordovaApp) {
    return 'production'
  }

  const route = window.location.hash.slice(1)
  const index = route.indexOf('?')
  const queryParams = index !== -1 ? decode(route.slice(index + 1)) : null
  const env = queryParams && queryParams.environment ? queryParams.environment.toLowerCase() : ''

  return env === 'production'
    ? 'production'
    : env.indexOf('local') === 0 || window.location.host.includes('localhost') // 'local' or 'localhost'
    ? 'localhost'
    : env.indexOf('dev') === 0 // 'dev', 'develop' or 'development'
    ? 'development'
    : window.location.host.includes('riverguide.teqplay.nl')
    ? 'production'
    : 'development'
}

export const BACKEND_URL =
  getEnvironment() === 'production' ? 'https://backend.teqplay.nl' : 'https://backenddev.teqplay.nl'

export const BASIC_AUTH_TOKEN = 'Basic cHJvbnRvaGFtaXM6cmFjIXdhckFkdSFBNmE='

export const GENERAL_HEADERS = {
  'Content-Type': 'application/json',
  'Cache-Control': 'no-cache',
  'TEQPLAY-APP': 'RIVERGUIDE'
}

export const GENERAL_HEADERS_FORM_DATA = {
  'Cache-Control': 'no-cache',
  'TEQPLAY-APP': 'RIVERGUIDE'
}

export const retina = L.Browser.retina
const retinaUrlAddition = retina ? '@2x' : ''

export const MAPBOX_ACCESS_TOKEN =
  'pk.eyJ1IjoidGVxcGxheSIsImEiOiJjank1eTYwMmIwYmp4M21xbjYyOGVmdDM0In0.quaQ656ZUHu4UhuQG_sgqQ'
export const MAP_URL_DEPTH_OVERLAY = `https://api.mapbox.com/v4/teqplay.an91he7v/{z}/{x}/{y}${retinaUrlAddition}.png?access_token=${MAPBOX_ACCESS_TOKEN}`

export const WADDENZEE_DEPTH_POLYGONS_URLS = [
  './data/waddenzeeDepthPolygons.json',
  'data/waddenzeeDepthPolygons.json',
  'https://riverguide.teqplay.nl/data/waddenzeeDepthPolygons.json',
  'https://riverguidedev.teqplay.nl/data/waddenzeeDepthPolygons.json'
]

export const SMALL_SHIP_SIZE = 40

export const NETHERLANDS_CENTER = { lat: 52.30476566809046, lng: 5.489902496337891 }

export const SCREEN_WIDTH_TO_SPLIT_VIEW = 1000
// TIME
export const TIME_FORMAT = 'HH:mm'
// DATE FORMATS
export const SHORT_DATE_FORMAT = 'd MMM yyyy'
// DATE + TIME FORMATS
export const DATE_TIME_FORMAT = 'd MMMM yyyy HH:mm'
export const SHORT_DATE_TIME_FORMAT = 'd MMM HH:mm'

// Bridge times
export const BRIDGE_DATE_FORMAT = 'EEEEEE d MMMM' // weekday day month
export const BRIDGE_SHORT_DATE_FORMAT = 'd MMMM' // day month

// Interval times
export const INTERVAL_LOCATION_PLUGGED_IN = 3 * 1000
export const INTERVAL_INACTIVE_ROUTE = 30 * 1000
export const INTERVAL_ROUTE_ETA = 1000 * 30
export const INTERVAL_NOTIFICATIONS = 1000 * 120
export const INTERVAL_BRIDGE_OPENINGS = 1000 * 60

// IOS
export const INTERVAL_LOCATION_IOS_FOREGROUND = 5 * 1000
export const INTERVAL_LOCATION_IOS_BACKGROUND = 30 * 1000

// ANDROID
export const INTERVAL_LOCATION_ANDROID_FOREGROUND = 5 * 1000
export const INTERVAL_LOCATION_ANDROID_BACKGROUND = 30 * 1000
export const CHECK_INTERVAL_PASSED_ANDROID = 10 * 1000
export const TIMEOUT_LOCATION_WATCH = 10 * 1000

export const FETCH_BRIDGE_OPENINGS = 10 * 1000
export const CHECK_USER_OFFLINE = 60 * 1000
export const FETCH_CURRENT_MAX_SPEED = 60 * 1000
export const INTERVAL_FETCH_CURRENT = 60 * 1000
export const INTERVAL_FETCH_ETA = 60 * 1000

// refresh interval to retrieve the actual weather information
export const UPDATE_WEATHER_INTERVAL = 5 * 60 * 1000 // 5 min

export const AISSHIPS_ZOOM_LEVEL = 13

export const PRIVACY_STATEMENT_URL = 'https://riverguide.eu/files/Privacy_Statement_RiverGuide.pdf'

export const GOOGLE_ANALYTICS_ID = 'G-N2GXND082K'

export const ALL_LOCALES: SupportedLocales[] = ['nl_NL', 'en_GB', 'fr_FR']

export const NL_COVERAGE_AREA = [
  [
    [51.936222078757964, 4.40826416015625],
    [51.93368194751477, 4.416675567626953],
    [51.94024365913649, 4.429550170898437],
    [51.96203858429277, 4.4226837158203125],
    [52.05629052213462, 4.390411376953125],
    [52.10481822023322, 4.4721221923828125],
    [52.11388493646863, 4.519844055175781],
    [52.116204032860416, 4.658203125],
    [52.03939895408626, 4.637603759765624],
    [51.99333702056398, 4.675025939941406],
    [51.99291421446105, 4.690132141113281],
    [51.998410382390325, 4.699058532714844],
    [52.01035165199044, 4.714679718017578],
    [52.01743036932765, 4.767723083496094],
    [52.07887301438546, 4.827289581298828],
    [52.08372570381539, 4.821710586547852],
    [52.11030064110678, 4.746952056884766],
    [52.127165400871284, 4.697685241699219],
    [52.18319485497131, 4.746608734130859],
    [52.20918456174935, 4.746265411376952],
    [52.227063386251594, 4.650821685791016],
    [52.22485521518378, 4.557952880859375],
    [52.2204385435938, 4.546966552734374],
    [52.20108413763479, 4.530658721923828],
    [52.1819318100605, 4.510917663574218],
    [52.1584536976032, 4.501991271972656],
    [52.146551986825955, 4.477100372314453],
    [52.17635293259852, 4.457187652587891],
    [52.20308308081979, 4.435558319091797],
    [52.215600732579105, 4.4103240966796875],
    [52.205081934091304, 4.3979644775390625],
    [52.18614182026113, 4.406890869140625],
    [52.15413574555202, 4.445343017578125],
    [52.13095909247831, 4.450836181640625],
    [52.0972260631284, 4.39453125],
    [52.057979327779506, 4.317626953125],
    [52.02334604998951, 4.324493408203125],
    [51.9823427642921, 4.357452392578125],
    [51.936222078757964, 4.40826416015625]
  ],
  [
    [51.9990445121961, 5.086326599121094],
    [51.978536431336764, 5.071563720703125],
    [51.95569164116401, 5.045814514160156],
    [51.93347026342011, 5.0214385986328125],
    [51.924366902616114, 4.992942810058594],
    [51.9012826678341, 4.986419677734375],
    [51.86377197456952, 4.983329772949219],
    [51.82707872891347, 4.962644577026367],
    [51.8241610750348, 4.976463317871094],
    [51.822675651375896, 4.997749328613281],
    [51.8419293097545, 5.004100799560547],
    [51.868860004633994, 5.011310577392577],
    [51.92013215213121, 5.035686492919921],
    [51.95389317724264, 5.064525604248047],
    [51.9792765769638, 5.101947784423827],
    [52.002109346295015, 5.102806091308594],
    [51.9990445121961, 5.086326599121094]
  ]
]

export const BE_EURIS_COVERAGE_AREA_SPEED = [
  [51.4608524, 3.4222412],
  [51.1586769, 2.5653076],
  [50.9757238, 2.4993896],
  [50.8232885, 2.7301025],
  [50.7086344, 2.9223633],
  [50.6947178, 3.0706787],
  [50.5204122, 3.2574463],
  [50.3664888, 3.4991455],
  [50.3366958, 3.7380981],
  [50.2507175, 3.9935303],
  [50.1944845, 4.0924072],
  [50.1100107, 4.2297363],
  [50.1170559, 4.4384766],
  [50.1064877, 4.6746826],
  [50.1276217, 4.9713135],
  [50.3174081, 5.3448486],
  [50.5343804, 5.6964111],
  [50.5954428, 5.7376099],
  [50.6407521, 5.7513428],
  [50.7451457, 5.8255005],
  [50.831963, 5.8721924],
  [51.0448476, 5.9408569],
  [51.2361271, 5.8831787],
  [51.3409073, 5.4519653],
  [51.3803525, 5.2377319],
  [51.4094859, 4.9520874],
  [51.4694079, 4.2352295],
  [51.4608524, 3.4222412]
]

// All these predefined coverage areas have proper translations in place,
// with this, adding a new unknown source area will always force the user to add it in this list
// and add the translation
export const LAYER_COVERAGE: ILayerCoverageDetails[] = [
  {
    prompts: {
      hasLimitedRange: true,
      highDataUsage: { usage: '6.0 MB', unit: 'map.dataPlanSwipe' }
    },
    codeName: 'depths',
    areas: ['NLZH', 'NLMERWEDEKANAAL'],
    coverageArea: NL_COVERAGE_AREA,
    sources: ['Provincie Zuid-Holland - ArcGis open data tool']
  },
  {
    prompts: {
      hasLimitedRange: false,
      highDataUsage: { usage: '6.0 MB', unit: 'map.dataPlanSwipeUnload' }
    },
    codeName: 'ECDIS',
    areas: ['NL'],
    sources: ['Rijkswaterstaat - vaarweginformatie.nl'],
    note: 'NLMAJOR'
  },
  {
    prompts: {
      hasLimitedRange: false,
      highDataUsage: false
    },
    codeName: 'waveHeight',
    areas: [
      'NLNOO',
      'NLWAD',
      'NLIJS',
      'NLMAR',
      'NLOOSTERSCHELDE',
      'NLWESTERSCHELDE',
      'BENOO',
      'DEWAD'
    ],
    sources: ['Rijkswaterstaat - Waterberichtgeving']
  },
  {
    prompts: {
      hasLimitedRange: false,
      highDataUsage: false
    },
    codeName: 'crossings',
    areas: ['NL'],
    sources: ['Varen Doe Je Samen']
  },
  {
    prompts: {
      hasLimitedRange: true,
      highDataUsage: false
    },
    codeName: 'fairways',
    areas: ['NLZH', 'NLMERWEDEKANAAL', 'BE'],
    coverageArea: [...NL_COVERAGE_AREA, BE_EURIS_COVERAGE_AREA_SPEED],
    sources: ['Provincie Zuid-Holland - ArcGis open data tool, EURIS']
  },
  {
    prompts: {
      hasLimitedRange: true,
      highDataUsage: false
    },
    codeName: 'currents',
    areas: ['NLRTM', 'NLWAD', 'DEWAD'],
    coverageArea: [
      [
        [51.9495554, 3.4727097],
        [51.7381913, 3.7252235],
        [51.7431872, 3.7724304],
        [51.7423369, 3.8218689],
        [51.7771874, 3.8665009],
        [51.8188027, 3.8465881],
        [51.8482923, 3.9935303],
        [51.7988491, 4.0920639],
        [51.7733636, 4.1816711],
        [51.7472261, 4.2173767],
        [51.7021404, 4.3148804],
        [51.7008638, 4.379425],
        [51.6959696, 4.3904114],
        [51.672981, 4.5689392],
        [51.7219241, 4.7399139],
        [51.7053319, 4.7605133],
        [51.7040554, 5.039978],
        [51.7236255, 5.1972198],
        [51.7378724, 5.3348923],
        [51.7557278, 5.376091],
        [51.824108, 5.4289627],
        [51.8847564, 5.5041504],
        [51.8930203, 5.5010605],
        [51.9002235, 5.4581451],
        [51.8911134, 5.4200363],
        [51.880094, 5.3980637],
        [51.8315344, 5.3503418],
        [51.8366261, 5.2507782],
        [51.8289884, 5.1436615],
        [51.8387475, 5.0777435],
        [51.836414, 5.0125122],
        [51.8404445, 4.9326897],
        [51.8367322, 4.8920059],
        [51.8270787, 4.7756195],
        [51.8351411, 4.7220612],
        [51.8841206, 4.706955],
        [51.9103907, 4.8339844],
        [51.9899016, 5.1273537],
        [51.993707, 5.1478672],
        [52.0027434, 5.160141],
        [52.0117252, 5.0997162],
        [51.9916458, 4.9891663],
        [51.9556916, 4.8326111],
        [51.9222496, 4.7289276],
        [51.9129322, 4.6115112],
        [51.93093, 4.5116043],
        [51.9114497, 4.3993378],
        [51.9086963, 4.3004608],
        [51.9795409, 4.1175556],
        [52.1773003, 4.369812],
        [52.3655376, 4.5167542],
        [52.4685602, 4.0374756],
        [52.2160214, 3.8534546],
        [51.949767, 3.4727097],
        [53.5999418, 4.6497917],
        [53.5999418, 7.2434235],
        [53.2265906, 7.2454834],
        [53.231523, 7.0628357],
        [53.3275924, 6.9241333],
        [53.4496247, 6.7950439],
        [53.3939755, 6.3116455],
        [53.330873, 6.3102722],
        [53.3185694, 6.1592102],
        [53.4005262, 6.1262512],
        [53.3333333, 5.7019043],
        [53.2175463, 5.4629517],
        [52.90269, 5.4087067],
        [52.9006192, 4.65065],
        [53.5999418, 4.6497917],
        [51.9495554, 3.4727097]
      ],
      [
        [53.59994177238322, 4.649791717529298],
        [53.59994177238322, 7.243423461914063],
        [53.22659056698573, 7.245483398437501],
        [53.23152302270159, 7.062835693359376],
        [53.32759237756109, 6.92413330078125],
        [53.4496246783658, 6.795043945312501],
        [53.39397547944379, 6.3116455078125],
        [53.33087298301706, 6.310272216796875],
        [53.31856941172296, 6.159210205078125],
        [53.40052622473161, 6.126251220703125],
        [53.33333327155682, 5.701904296875],
        [53.21754625569073, 5.462951660156251],
        [52.90269002800473, 5.408706665039062],
        [52.90061915680727, 4.650650024414063],
        [53.59994177238322, 4.649791717529298]
      ]
    ],
    sources: ['Rijkswaterstaat - Waterberichtgeving']
  },
  {
    prompts: {
      hasLimitedRange: true,
      highDataUsage: false
    },
    codeName: 'bottlenecks',
    areas: ['NLZH', 'NLMERWEDEKANAAL'],
    coverageArea: NL_COVERAGE_AREA,
    sources: ['Provincie Zuid-Holland - ArcGis open data tool']
  },
  {
    prompts: {
      hasLimitedRange: false,
      highDataUsage: false
    },
    codeName: 'bridges',
    areas: ['AT', 'BE', 'DE', 'FR', 'NL'],
    sources: [
      'Rijkswaterstaat - Fairway Information Service',
      'Blauwe Golf verbindend',
      'Nationale Databank Wegverkeer'
    ]
  },
  {
    prompts: {
      hasLimitedRange: false,
      highDataUsage: false
    },
    codeName: 'drinkwaterPoints',
    areas: ['NL'],
    sources: ['Crowdsourced']
  },
  {
    prompts: {
      hasLimitedRange: true,
      highDataUsage: false
    },
    codeName: 'waterwaySigns',
    areas: ['NLZH', 'NLMERWEDEKANAAL'],
    coverageArea: NL_COVERAGE_AREA,
    sources: ['Provincie Zuid-Holland - ArcGis open data tool']
  },
  {
    prompts: {
      hasLimitedRange: false,
      highDataUsage: false
    },
    codeName: 'basMessages',
    areas: ['NL'],
    sources: ['Rijkswaterstaat - Scheepvaartberichten']
  },
  {
    prompts: {
      hasLimitedRange: false,
      highDataUsage: false
    },
    codeName: 'buoys',
    areas: ['NL'],
    sources: ['Rijkswaterstaat - Fairway Information Service']
  },
  {
    prompts: {
      hasLimitedRange: false,
      highDataUsage: false
    },
    codeName: 'kilometrering',
    areas: ['NL'],
    sources: ['Rijkswaterstaat - Fairway Information Service']
  },
  {
    prompts: {
      hasLimitedRange: false,
      highDataUsage: false
    },
    codeName: 'locks',
    areas: ['NL', 'BE', 'FR', 'DE'],
    sources: ['Rijkswaterstaat - Fairway Information Service', 'Blauwe Golf verbindend']
  },
  {
    prompts: {
      hasLimitedRange: false,
      highDataUsage: false
    },
    codeName: 'weather',
    areas: ['NL'],
    sources: ['Buienradar.nl']
  },
  {
    prompts: {
      hasLimitedRange: false,
      highDataUsage: false
    },
    codeName: 'berths',
    areas: ['NL', 'BEVLA'],
    sources: ['Rijkswaterstaat - Fairway Information Service', 'Blauwe Golf verbindend']
  },
  {
    prompts: {
      hasLimitedRange: false,
      highDataUsage: false
    },
    codeName: 'sabPoints',
    areas: ['NL'],
    sources: ['Rijkswaterstaat - Fairway Information Service']
  },
  {
    prompts: {
      hasLimitedRange: false,
      highDataUsage: false
    },
    codeName: 'carDropoffPoints',
    areas: ['NL', 'BEANR'],
    sources: ['Rijkswaterstaat - Fairway Information Service']
  },
  {
    prompts: {
      hasLimitedRange: true,
      highDataUsage: false
    },
    codeName: 'shorePower',
    areas: ['NLZH', 'NLMERWEDEKANAAL'],
    coverageArea: NL_COVERAGE_AREA,
    sources: ['Rijkswaterstaat - Fairway Information Service']
  }
]

// This is the hsCode + BICS for Empty cargo
export const EMPTY_CARGO_BICS_OPTION: BicsCargo = {
  hsCode: '9909000001'
}

export const DEFAULT_LAYER_RESTRICTION = 12

export const LAYER_LIST: {
  [category: string]: ILayerCodeName[]
} = {
  categoryBerths: ['public_berths', 'terminal_berths', 'suitable_berths'],
  categoryFairway: [
    'depths',
    'ECDIS',
    'waveHeight',
    'crossings',
    'fairways',
    'currents',
    'bottlenecks',
    'waterHeight'
  ],
  categoryElements: [
    'sabPoints',
    'carDropoffPoints',
    'buoys',
    'bridges',
    'drinkwaterPoints',
    'kilometrering',
    'locks',
    'waterwaySigns',
    'shorePower',
    'operatingAreas'
  ]
}

export const LAYERS_WITH_ZOOM_LEVEL_RESTRICTIONS: {
  layerName: ILayerCodeName
  minZoomLevel: number
}[] = [
  { layerName: 'bridges', minZoomLevel: DEFAULT_LAYER_RESTRICTION },
  { layerName: 'kilometrering', minZoomLevel: DEFAULT_LAYER_RESTRICTION },
  { layerName: 'buoys', minZoomLevel: DEFAULT_LAYER_RESTRICTION },
  { layerName: 'waterwaySigns', minZoomLevel: DEFAULT_LAYER_RESTRICTION },
  { layerName: 'locks', minZoomLevel: DEFAULT_LAYER_RESTRICTION },
  { layerName: 'public_berths', minZoomLevel: DEFAULT_LAYER_RESTRICTION },
  { layerName: 'suitable_berths', minZoomLevel: DEFAULT_LAYER_RESTRICTION },
  { layerName: 'terminal_berths', minZoomLevel: DEFAULT_LAYER_RESTRICTION },
  { layerName: 'waterHeight', minZoomLevel: DEFAULT_LAYER_RESTRICTION }
]

export const MAP_TYPE_OPTIONS: MapTypes[] = ['DEFAULT', 'SATELLITE', 'ECDIS', 'OPENSTREETMAP']

export const DEFAULT_ROUTE_SEARCH: IRouteSearchProps = {
  fromLocation: null,
  viaRoutes: [],
  toLocation: null
}
