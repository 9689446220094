import React, { useState } from 'react'
import './passwordInput.scss'

interface IProps {
  password: string
  setPassword: (password: string) => void
  className?: string
  autoFocus?: boolean
}

function PasswordInput(props: IProps) {
  const [showPassword, setShowPassword] = useState<boolean>(false)
  return (
    <div id="password-container">
      <input
        type={!showPassword ? 'password' : 'text'}
        value={props.password}
        onChange={e => props.setPassword(e.target.value)}
        className={props.className}
        autoFocus={props.autoFocus}
        autoCapitalize="off"
      />
      {props.password.length > 0 && (
        <div className="icon">
          <i
            className={`eye icon-eye${!showPassword ? '-off' : ''}`}
            onClick={() => setShowPassword(!showPassword)}
          />
        </div>
      )}
    </div>
  )
}

export default PasswordInput
