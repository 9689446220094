import * as React from 'react'
import { useLocation } from 'react-router'

import SideMenu from '../../components/sideMenu/SideMenu'

import { IApplicationTheme } from '../../@types/types'
import { ISelectedRoute, IUserProfile } from '../../services/TeqplayAPIService/TeqplayApi'

import './CustomPage.scss'

interface IProps {
  children: React.ReactChild
  userProfile: IUserProfile | null
  shipName: string
  onLogout: () => void
  navigationRoute: ISelectedRoute | null

  disableStyling?: boolean

  theme: IApplicationTheme
  setStatusbarTheme: (theme: IApplicationTheme) => void
}

const CustomPageWrapper: React.FunctionComponent<IProps> = props => {
  const location = useLocation()

  const {
    children,
    userProfile,
    shipName,
    onLogout,
    navigationRoute,
    disableStyling,
    theme,
    setStatusbarTheme
  } = props
  return (
    <div className={`custom-page-wrapper${disableStyling ? ' disableStyling' : ''}`}>
      <div className="custom-page-header">
        <SideMenu
          activePage={location.pathname}
          userProfile={userProfile}
          shipName={shipName}
          logout={onLogout}
          navigationRoute={navigationRoute}
          theme={theme}
          setStatusbarTheme={t => setStatusbarTheme(t || theme)}
        />
      </div>
      <div className="custom-page">
        <div className="content">{children}</div>
      </div>
    </div>
  )
}

export default CustomPageWrapper
