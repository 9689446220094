import * as React from 'react'
import { I18n } from 'react-redux-i18n'
import { IBridgeDetails } from '../../../services/TeqplayAPIService/TeqplayApi'
import { sortByKey } from '../../../utils/general'

interface IProps {
  itemDetails: IBridgeDetails
}

const BridgeDrawing: React.FunctionComponent<IProps> = props => {
  const itemDetails = props.itemDetails

  if (itemDetails && itemDetails.bridgeOpening && itemDetails.bridgeOpening.length > 0) {
    return itemDetails.bridgeOpening.length > 0 && itemDetails.bridgeOpening[0].heightClosed ? (
      <>
        <section className="details-section waterlevel">
          <h3>{I18n.t('routeList.itemDetails.schematicView')}</h3>
          <b>
            {I18n.t('routeList.itemDetails.heightToWater', {
              referenceLevel: itemDetails.referencelevel || I18n.t('routeList.itemDetails.NAP')
            })}
          </b>
        </section>
        <div
          className={`detailed-bridge-wrapper${
            itemDetails.bridgeOpening.length > 3 ? ' large' : ''
          }`}
        >
          {itemDetails.waterLevel && (
            <div className="detailed-bridge waterlevel">
              <div className="value" id={`bridge-drawing-${itemDetails.isrsCode}-water-level`}>
                {Math.round(itemDetails.waterLevel.heightInCm) / 100} m
              </div>
              <div className="line" id={`bridge-drawing-${itemDetails.isrsCode}-water-line`} />
            </div>
          )}
          {sortByKey(itemDetails.bridgeOpening, 'number').map((opening, key) => {
            const meterInPixels = 5 // 1m in pixels
            const bridgePixelHeight = opening.heightClosed
              ? opening.heightClosed * meterInPixels
              : 25
            const extraOpeningHeight = opening.heightOpened
              ? opening.heightOpened * meterInPixels
              : 10

            return (
              <div
                className={`detailed-bridge ${opening.type}`}
                key={key}
                id={`bridge-drawing-${opening.isrsCode}-${key}`}
              >
                <div className="bridge edge" />
                <div className="bridge inner">
                  <div
                    className="parts"
                    style={{ transform: `translate(0, ${-bridgePixelHeight}px)` }}
                  >
                    {opening.type === 'HEF' && opening.heightOpened ? (
                      <div
                        className="openedHeight"
                        style={{
                          height:
                            bridgePixelHeight + extraOpeningHeight > 175
                              ? 175 - (bridgePixelHeight + 29 + (itemDetails.waterLevel ? 25 : 0))
                              : extraOpeningHeight - bridgePixelHeight - 29,
                          lineHeight:
                            bridgePixelHeight + extraOpeningHeight > 175
                              ? (175 -
                                  (bridgePixelHeight + 29 + (itemDetails.waterLevel ? 25 : 0))) /
                                  2 +
                                'px'
                              : (extraOpeningHeight - bridgePixelHeight - 29) / 2 + 'px'
                        }}
                        id={`bridge-drawing-${opening.isrsCode}-opened-height`}
                      >
                        <i className="icon-resize-vertical" />
                        {opening.heightOpened} m
                      </div>
                    ) : null}
                    <div
                      className="closedHeight"
                      style={{
                        height: bridgePixelHeight + 29 - (itemDetails.waterLevel ? 25 : 0),
                        lineHeight:
                          (bridgePixelHeight + 29 - (itemDetails.waterLevel ? 25 : 0)) / 2 + 'px'
                      }}
                      id={`bridge-drawing-${opening.isrsCode}-closed-height`}
                    >
                      <i className="icon-depth icon-flip" />
                      {opening.heightClosed ? opening.heightClosed + 'm' : ''}
                    </div>
                    <div className="left" />
                    <div className="right" />
                    <div
                      className="bridgeWidth"
                      id={`bridge-drawing-${opening.isrsCode}-bridge-width`}
                    >
                      <i className="icon-resize-horizontal" />
                      {opening.width || '?'} m
                    </div>
                  </div>
                  {itemDetails.waterLevel ? (
                    <div
                      className="water"
                      id={`bridge-drawing-${opening.isrsCode}-water-drawing`}
                    />
                  ) : (
                    <div
                      className="water low"
                      id={`bridge-drawing-${opening.isrsCode}-water-drawing`}
                    />
                  )}
                </div>
              </div>
            )
          })}
          <div className="detailed-bridge end">
            <div className="bridge edge" />
          </div>
        </div>
      </>
    ) : null
  } else {
    return null
  }
}

export default BridgeDrawing
