import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import Select, { ValueType } from 'react-select'
import { toast } from 'react-toastify'

import TeqplayApiService from '../../../services/TeqplayAPIService/TeqplayApiService'

import { useDebounce } from '../../../hooks/useDebounce'
import { BicsShipType } from '../../../services/TeqplayAPIService/TeqplayApi'
import { selectStyles, sortByKey } from '../../../utils/general'
import { IRootProps } from '../../../@types/types'

interface IProps {
  value: BicsShipType | null
  initialShipTypeCode: string | undefined
  onChange: (value: BicsShipType | null) => void
  error?: string
  isDisabled?: boolean
  id?: string
  inputId?: string
  placeholder?: React.ReactNode
  teqplayAPIService: TeqplayApiService
  showConvoyTypes?: boolean
  isClearable?: boolean
}

const BICSShipTypeSelector = ({
  value,
  initialShipTypeCode,
  onChange,
  isDisabled,
  error,
  id,
  inputId,
  placeholder,
  teqplayAPIService,
  showConvoyTypes,
  isClearable
}: IProps) => {
  const [shipTypeOptions, setShipTypeOptions] = useState<BicsShipType[]>([])
  const [searchValue, setSearchValue] = useState<string>('')
  const [loading, setLoading] = useState(false)
  const [initialized, setInitialized] = useState(false)
  const locale = useSelector((s: IRootProps) => s.i18n.locale)

  const debouncedSearchValue = useDebounce(searchValue, 400)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedCargoSearch = useCallback(getTypesByQuery, [teqplayAPIService, showConvoyTypes])

  useEffect(() => {
    if (debouncedSearchValue.length >= 0) {
      memoizedCargoSearch(debouncedSearchValue)
    } else {
      setShipTypeOptions([])
    }
  }, [debouncedSearchValue, memoizedCargoSearch])

  useEffect(() => {
    async function resolveShipTypeIdToValue(sid: string) {
      try {
        setLoading(true)
        const results = await teqplayAPIService.getBICSVesselTypesByQuery(sid, undefined, locale)
        onChange(results[0] || null)

        if (results.length > 1) {
          toast.warn(I18n.t('shipTypeSelect.estimate', { id }))
        }
        setInitialized(true)
      } catch (err) {
        console.error(err)
      } finally {
        setLoading(false)
      }
    }

    if (!initialized && initialShipTypeCode && !value) {
      resolveShipTypeIdToValue(initialShipTypeCode)
    } else if (value) {
      setInitialized(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialShipTypeCode, value])

  return (
    <Select<BicsShipType>
      isSearchable
      value={value}
      inputValue={searchValue}
      onInputChange={(newValue, { action }) => {
        if (action !== 'menu-close' && action !== 'input-blur') {
          setSearchValue(newValue)
        }
      }}
      options={shipTypeOptions}
      isClearable={isClearable}
      onChange={handleChangeShipType}
      error={error}
      isDisabled={isDisabled}
      id={id}
      inputId={`ship-type-picker-INPUT_${inputId}`}
      placeholder={placeholder || I18n.t('shipTypeSelect.placeholder')}
      className="generic-select"
      classNamePrefix={`ship-type-selector generic-select selector`}
      formatOptionLabel={c => c.name}
      styles={selectStyles}
      getOptionValue={shipType => shipType.name || 'no-nameLocal'}
      openMenuOnFocus
      noOptionsMessage={({ inputValue }) =>
        inputValue.length > 2 && searchValue === debouncedSearchValue && !loading
          ? I18n.t('shipTypeSelect.noOptions', { query: inputValue })
          : I18n.t('shipTypeSelect.placeholder')
      }
      filterOption={() => true}
      isLoading={loading}
      maxMenuHeight={250}
    />
  )

  function handleChangeShipType(vt: ValueType<BicsShipType>) {
    const v = vt as BicsShipType
    onChange(v)
  }

  async function getTypesByQuery(query: string): Promise<void> {
    try {
      setLoading(true)
      const results = await teqplayAPIService.getBICSVesselTypesByQuery(
        query,
        showConvoyTypes,
        locale
      )
      setShipTypeOptions(sortByKey(results, 'name'))
    } catch (err) {
      console.error(err)
    } finally {
      setLoading(false)
    }
  }
}

export default BICSShipTypeSelector
