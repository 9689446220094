import React, { useState, useEffect } from 'react'
import ReactGA from 'react-ga4'

import { isCordovaApp } from '../../utils/cordovaUtils'
import { getEnvironment } from '../../utils/constants'

export interface AnalyticsContextType {
  newEvent: (eventName: string, properties: object, category?: string) => void
  setScreen: (path: string) => void
  setThemeProperty: (value: string) => void
}

const AnalyticsContext = React.createContext<AnalyticsContextType>({
  newEvent: () => console.error('[ANALYTICS] No default value supplied to AnalyticsContext'),
  setScreen: () => console.error('[ANALYTICS] No default value supplied to AnalyticsContext'),
  setThemeProperty: () => console.error('[ANALYTICS] No default value supplied to AnalyticsContext')
})
AnalyticsContext.displayName = 'AnalyticsContext'

export function AnalyticsProvider({ children }: { children: React.ReactNode }) {
  function handleSetScreen(path: string) {
    if (window.FirebasePlugin && isCordovaApp) {
      window.FirebasePlugin.setScreenName(path)
      window.FirebasePlugin.logEvent('opened_screen_' + path, {}) // Don't see the screen names in GA, so also sending event for it
    } else {
      ReactGA.send({
        hitType: 'pageview',
        page: window.location.pathname + window.location.search,
        title: window.document.title
      })
    }
  }

  function handleNewEvent(eventName: string, properties: object, category?: string) {
    if (window.FirebasePlugin && isCordovaApp) {
      window.FirebasePlugin.logEvent(eventName, properties)
    } else {
      ReactGA.event({
        action: eventName,
        category: category || '<UNSET-CATEGORY>'
      })
    }

    if (getEnvironment() !== 'production') {
      console.info(eventName, properties, category)
    }
  }

  function handleSetThemeProperty(value: string) {
    if (window.FirebasePlugin && isCordovaApp) {
      window.FirebasePlugin.setUserProperty('theme', value)
    } else {
      ReactGA.set({ dimension1: value })
    }
  }

  return (
    <AnalyticsContext.Provider
      value={{
        newEvent: handleNewEvent,
        setScreen: handleSetScreen,
        setThemeProperty: handleSetThemeProperty
      }}
    >
      {children}
    </AnalyticsContext.Provider>
  )
}

export function useAnalytics(newCategory: string): AnalyticsContextType {
  const analytics = React.useContext(AnalyticsContext)
  const [category, setCategory] = useState<string>(newCategory)

  useEffect(() => {
    if (newCategory !== category) {
      setCategory(category)
    }
  }, [category, newCategory, setCategory])

  return {
    ...analytics,
    newEvent: (eventName: string, properties: object, overrideCategory?: string) =>
      analytics.newEvent(eventName, properties, overrideCategory || category)
  }
}
