import React from 'react'
import { I18n } from 'react-redux-i18n'
import Select from 'react-select'

import { BicsHull } from '../../../services/TeqplayAPIService/TeqplayApi'
import { selectStyles } from '../../../utils/general'

interface IProps {
  value: string
  onChange: (value: BicsHull['fuelType'] | null) => void
  error?: string
  isDisabled?: boolean
  id?: string
  inputId?: string
  placeholder?: React.ReactNode
}

const FUEL_TYPES = ['NONE', 'LNG', 'HYDROGEN', 'ELECTRICITY', 'LOW_SULPHUR_FUEL', 'DIESEL', 'OTHER']

const BICSFuelTypeSelector = ({
  onChange,
  value,
  isDisabled,
  error,
  id,
  inputId,
  placeholder
}: IProps) => {
  return (
    <Select
      value={
        value
          ? {
              label: I18n.t(`announcements.fields.${value}`),
              value
            }
          : undefined
      }
      options={FUEL_TYPES.map(f => ({
        label: I18n.t(`announcements.fields.${f}`),
        value: f
      }))}
      isClearable={false}
      onChange={(f: any) => {
        if (f && f.value) {
          onChange(f.value)
        }
      }}
      error={error}
      isDisabled={isDisabled}
      id={id}
      inputId={inputId}
      placeholder={placeholder}
      className="generic-select"
      classNamePrefix="generic-select selector"
      getOptionValue={c => c.value}
      getOptionLabel={c => c.label}
      formatOptionLabel={c => c.label}
      styles={selectStyles}
    />
  )
}

export default BICSFuelTypeSelector
