import { getHours, getMinutes, setHours, setMinutes } from 'date-fns'
import React from 'react'
import { I18n } from 'react-redux-i18n'

import { formatISOToInputDateFormat, formatISOToInputTimeFormat } from '../../../utils/dates'
import { identifyAndParseDate } from '../../../utils/dates'

interface IProps {
  onDateTimeChange: (
    isoDate: string | null,
    inputValue: string,
    isValid: boolean,
    fieldName?: string
  ) => void
  value?: string
  id?: string
  disabled?: boolean
  showClearButton?: boolean
}

const MobileDateTimePicker = (props: IProps) => {
  const CURRENT_DATE_ISO = new Date().toISOString()
  const { onDateTimeChange, value, id, disabled, showClearButton } = props

  return (
    <div className="mobile-date-time-picker">
      <input
        id={`mobile-date-picker-startDate-${id}`}
        className="input date-input-native date"
        placeholder={I18n.t('datetime.date')}
        type="date"
        value={value ? formatISOToInputDateFormat(value) : ''}
        onChange={e => handleOnChange(e, 'date')}
        disabled={disabled}
      />
      <input
        id={`mobile-datetime-picker-endDate-${id}`}
        className="input date-input-native time"
        placeholder={I18n.t('datetime.time')}
        type="time"
        pattern="[0-9]{2}:[0-9]{2}" // Used as a fallback in case shit hits the fan, component should only be used on modern
        value={value ? formatISOToInputTimeFormat(value) : ''}
        onChange={e => handleOnChange(e, 'time')}
        disabled={disabled}
      />
      {value && !disabled && showClearButton && (
        <button
          className="button clear-button"
          onClick={e => {
            e.stopPropagation()
            e.preventDefault()
            onDateTimeChange(null, '', false)
          }}
          type="button"
        >
          <i className="icon-cancel" />
          <span>{I18n.t('datetime.clear')}</span>
        </button>
      )}
    </div>
  )

  function handleOnChange(e: React.ChangeEvent<HTMLInputElement>, field: 'date' | 'time') {
    // Value will be formatted in YYYY-MM-DD format for date and HH:mm for time
    const inputValue = e.target?.value

    // Already parse the pre-existing hours, minutes from the initial value
    const existingValueHours = value ? getHours(identifyAndParseDate(value)) : undefined
    const existingValueMinutes = value ? getMinutes(identifyAndParseDate(value)) : undefined

    if (field === 'time') {
      const newHoursString = inputValue.split(':')?.[0]
      const newMinutesString = inputValue.split(':')?.[1]

      // Apply the new hours, minutes to the pre-existing time or the current dateTime
      const dateWithNewTime = setHours(
        setMinutes(identifyAndParseDate(value || CURRENT_DATE_ISO), parseInt(newMinutesString, 10)),
        parseInt(newHoursString, 10)
      )

      if (dateWithNewTime && newHoursString && newMinutesString) {
        onDateTimeChange(dateWithNewTime.toISOString(), inputValue, true)
      } else {
        const dateWithResetTime = setHours(
          setMinutes(identifyAndParseDate(value || CURRENT_DATE_ISO), 0),
          0
        )
        onDateTimeChange(dateWithResetTime.toISOString(), inputValue, true)
      }
    } else if (field === 'date') {
      if (inputValue) {
        // Apply new date value
        const newDate = setHours(
          setMinutes(new Date(inputValue), existingValueMinutes || 0),
          existingValueHours || 0
        )

        onDateTimeChange(newDate.toISOString(), inputValue, true)
      } else {
        // Reset date to null but keep hours, minutes
        const valueHours = getHours(identifyAndParseDate(value || CURRENT_DATE_ISO))
        const valueMinutes = getMinutes(identifyAndParseDate(value || CURRENT_DATE_ISO))

        // Apply hours, minutes to default null value
        const adjustedDate = setHours(
          setMinutes(new Date(CURRENT_DATE_ISO), valueMinutes),
          valueHours
        )
        onDateTimeChange(adjustedDate.toISOString(), inputValue, false)
      }
    }
  }
}

export default MobileDateTimePicker
