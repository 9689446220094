import get from 'lodash/get'
import React, { useState } from 'react'
import { I18n } from 'react-redux-i18n'
import { toast } from 'react-toastify'

import TeqplayApiService from '../../../services/TeqplayAPIService/TeqplayApiService'
import SaveRouteModal from '../../routeSelect/routeOptionsSelect/SaveRouteModal'

import { IRouteSearchProps } from '../../../@types/types'
import { useAnalytics } from '../../../services/AnalyticsWrapper/AnalyticsWrapper'
import { IRoute } from '../../../services/TeqplayAPIService/TeqplayApi'

import './RouteSelectionControls.scss'

interface IProps {
  routeSelection?: IRouteSearchProps
  routeSuggestions?: IRoute[] | null
  selectedRouteSuggestion?: IRoute | null
  teqplayApiService: TeqplayApiService
  resetAvailableRoutes?: () => void
  setSelectedRouteSuggestion?: (routeSuggestion: IRoute | null) => void
  startRoute?: (route: IRoute) => void
  setBICSRoute?: (routeSuggestion: IRoute | null) => void
  selectedTab?: 'BICS' | 'ROUTE'
}

const RouteSelectionControls = (props: IProps) => {
  const analytics = useAnalytics('RouteSelectionControls')
  const [saveRouteModalActive, setSaveRouteModalActive] = useState<boolean>(false)
  const [savedRouteName, setSavedRouteName] = useState<string>('')

  if (!props.routeSuggestions) {
    return null
  }

  function handleStartRoute() {
    if (props.selectedRouteSuggestion && props.startRoute) {
      props.startRoute(props.selectedRouteSuggestion)
    }
  }

  function openSaveRouteModal() {
    const fromName = get(props.routeSelection, 'fromLocation.displayName', '...')
    const toName = get(props.routeSelection, 'toLocation.displayName', '...')
    setSavedRouteName(I18n.t('routeSelection.fromTo', { from: fromName, to: toName }))
    setSaveRouteModalActive(true)
  }

  async function handleSaveRoute() {
    if (props.selectedRouteSuggestion) {
      setSaveRouteModalActive(!saveRouteModalActive)
      try {
        analytics.newEvent('save_route', {
          name: savedRouteName,
          routeId: props.selectedRouteSuggestion._id
        })
        await props.teqplayApiService.addToStoredRoutes(
          props.selectedRouteSuggestion,
          savedRouteName
        )
        toast.success(I18n.t('routeSelection.routeStoredSucces'))
      } catch (err) {
        console.error(err)
        toast.error(I18n.t('generalError'))
      }
    }
  }

  return (
    <React.Fragment>
      <SaveRouteModal
        isOpen={saveRouteModalActive}
        closeModal={() => setSaveRouteModalActive(false)}
        onNameChange={setSavedRouteName}
        savedRouteName={savedRouteName}
        saveRoute={handleSaveRoute}
        teqplayApiService={props.teqplayApiService}
      />
      <div className="route-selection-controls" id="route-selection-controls">
        <button
          type="button"
          className="button alt"
          onClick={props.resetAvailableRoutes}
          id="route-selection-controls-button-reset"
        >
          <i className="icon-left-big close-button" />
          <div className="text">{I18n.t('routeSelection.cancel')}</div>
        </button>
        {props.selectedTab !== 'BICS' && (
          <button
            type="button"
            className="button save"
            onClick={openSaveRouteModal}
            id="route-selection-controls-button-save"
          >
            <i className="icon-floppy" />
            <div className="text">{I18n.t('routeSelection.saveRoute')}</div>
          </button>
        )}
        {props.selectedTab === 'BICS' && props.setBICSRoute ? (
          <button
            type="button"
            className="button start"
            onClick={() =>
              props.selectedRouteSuggestion &&
              props.setBICSRoute &&
              props.setBICSRoute(props.selectedRouteSuggestion)
            }
            id="route-selection-controls-button-start"
            disabled={props.selectedRouteSuggestion ? false : true}
          >
            <i className="icon-play" />
            <div className="text">{I18n.t('routeSelection.selectRoute')}</div>
          </button>
        ) : (
          <button
            type="button"
            className="button start"
            onClick={handleStartRoute}
            id="route-selection-controls-button-start"
          >
            <i className="icon-play" />
            <div className="text">{I18n.t('routeSelection.startRoute')}</div>
          </button>
        )}
      </div>
    </React.Fragment>
  )
}

export default RouteSelectionControls
