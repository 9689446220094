import { isToday } from 'date-fns'
import get from 'lodash/get'
import React, { useCallback, useEffect, useState } from 'react'
import Modal from 'react-modal'
import { I18n } from 'react-redux-i18n'
import { toast } from 'react-toastify'

import TeqplayApiService from '../../services/TeqplayAPIService/TeqplayApiService'
import DimensionRow from './DimensionRow'

import { useLocalStorage } from '../../hooks/useLocalStorage'
import { IShipInfo } from '../../services/TeqplayAPIService/TeqplayApi'
import { ICustomError } from '../../@types/types'

import './DimensionsModal.scss'
import { filterUnique } from '../../utils/general'

interface IProps {
  teqplayAPIService: TeqplayApiService
  userId: string
  ship: IShipInfo | null
  isOpenSource: 'routeSubmit' | 'help' | undefined
  onClose: () => void
  onSubmit: () => void
  onUpdateDimensionsInState: () => void
}

const DimensionsModal = ({
  teqplayAPIService,
  ship,
  isOpenSource,
  onClose,
  onSubmit,
  onUpdateDimensionsInState
}: IProps) => {
  const memoizedGetDimensions = useCallback(getDimension, [ship])
  const [loading, setLoading] = useState<boolean>(false)
  const [initialized, setInitialized] = useState<boolean>(false)
  const [errors, setErrors] = useState<ICustomError<'length' | 'width' | 'height' | 'draught'>[]>(
    []
  )

  const [length, setLength] = useState<number | string | undefined>(memoizedGetDimensions('length'))
  const [width, setWidth] = useState<number | string | undefined>(memoizedGetDimensions('width'))
  const [height, setHeight] = useState<number | string | undefined>(memoizedGetDimensions('height'))
  const [draught, setDraught] = useState<number | string | undefined>(
    memoizedGetDimensions('draught')
  )

  const [doNotAskMeToday, setDoNotAskMeToday] = useLocalStorage<string | undefined>(
    `dimensions-skip`,
    undefined
  )

  const isDifferentFromInitial =
    memoizedGetDimensions('length') !== length ||
    memoizedGetDimensions('width') !== width ||
    memoizedGetDimensions('height') !== height ||
    memoizedGetDimensions('draught') !== draught

  useEffect(() => {
    // Check if the modal should be opened and there is a previous ignore active
    if (isOpenSource && doNotAskMeToday) {
      const previousDate = new Date(doNotAskMeToday)
      // Check if the date is still today
      if (!isToday(previousDate)) {
        // Reset doNotAskMeToday date when date has passed
        setDoNotAskMeToday(undefined)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpenSource])

  useEffect(() => {
    // Initialize ship variables upon updation of ship dimensions
    if (
      !initialized &&
      ship &&
      ((ship?.length && !length) ||
        (ship?.width && !width) ||
        (ship?.dimensions?.length && !length) ||
        (!ship?.positionOfTransponder?.distanceToBow && !length))
    ) {
      setLength(memoizedGetDimensions('length'))
      setWidth(memoizedGetDimensions('width'))
      setHeight(memoizedGetDimensions('height'))
      setDraught(memoizedGetDimensions('draught'))
      setInitialized(true)
    }
  }, [ship, memoizedGetDimensions, length, width, initialized])

  useEffect(() => {
    // Bypass screen in case criteria to not ask today have been met
    if (
      doNotAskMeToday &&
      isToday(new Date(doNotAskMeToday)) &&
      isOpenSource === 'routeSubmit' &&
      !initialized &&
      ship
    ) {
      setInitialized(true)
      onSubmit()
    }
  }, [doNotAskMeToday, onSubmit, isOpenSource, initialized, ship])

  return (
    <Modal
      className="modal dark dimensions-modal"
      style={{ overlay: { backgroundColor: 'rgba(0,0,0,0.75)' } }}
      isOpen={isOpenSource !== undefined}
      shouldCloseOnOverlayClick={false}
      onRequestClose={onClose}
    >
      {!ship ? (
        <div className="dimensions-inner">
          <div className="icon-close_privacy close-modal" onClick={onClose} />
          <h3>{I18n.t('confirmDimensions.noShip')}</h3>
        </div>
      ) : (
        <form className="dimensions-inner" onSubmit={applyDimensionsChanges}>
          <h3 className="title">
            {I18n.t('confirmDimensions.title', { ship: ship.name || ship.mmsi })}
          </h3>

          <DimensionRow
            dimension={'length'}
            value={length}
            onChange={n => setLength(n)}
            errors={errors}
          />
          <DimensionRow
            dimension={'width'}
            value={width}
            onChange={n => setWidth(n)}
            errors={errors}
          />
          <DimensionRow
            dimension={'height'}
            value={height}
            onChange={n => setHeight(n)}
            errors={errors}
          />
          <DimensionRow
            dimension={'draught'}
            value={draught}
            onChange={n => setDraught(n)}
            errors={errors}
          />

          {errors.length > 0 ? (
            <div className="error">
              <div>{I18n.t('announcements.error.present')}</div>
              <div className="key-list">
                {errors
                  .map(e => e.key)
                  .filter(filterUnique)
                  .map(key => I18n.t(`confirmDimensions.${key}`))
                  .join(', ')}
              </div>
            </div>
          ) : null}

          <div className="buttons">
            {isOpenSource === 'routeSubmit' && (
              <button
                className="button secondary"
                type="button"
                onClick={handleOnIgnoreForADay}
                defaultChecked={doNotAskMeToday !== undefined}
                id="button-checkbox-doNotAskMeToday"
              >
                {I18n.t('confirmDimensions.doNotAskMeToday')}
              </button>
            )}
            <button className="button primary submit-button" type="submit" disabled={loading}>
              {loading ? <i className="animate-spin icon-circle-notch" /> : I18n.t('confirm')}
            </button>
          </div>
        </form>
      )}
    </Modal>
  )

  function handleOnIgnoreForADay(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    e.preventDefault()
    e.stopPropagation()
    setErrors([])

    const validationErrors = validate()

    if (validationErrors.length > 0) {
      setErrors(validationErrors)
      setLoading(false)
      console.warn(
        'confirmDimensions.fillInAllFields\n\n',
        validationErrors.map(err => `${err.key}: ${err.error}`).join('\n')
      )
      return
    } else {
      setDoNotAskMeToday(new Date().toISOString())
      setInitialized(false)
      onSubmit()
    }
  }

  function validate(): ICustomError<'length' | 'width' | 'height' | 'draught'>[] {
    const validationErrors: ICustomError<'length' | 'width' | 'height' | 'draught'>[] = []

    if (!length) {
      validationErrors.push({
        key: 'length',
        error: I18n.t('announcements.error.requiredEmpty')
      })
    } else if (isNaN(length as number)) {
      // Assertion to pass value as a number, check will go true if a string is passed
      validationErrors.push({
        key: 'length',
        error: I18n.t('announcements.error.invalidNumberValue')
      })
    }

    if (!width) {
      validationErrors.push({
        key: 'width',
        error: I18n.t('announcements.error.requiredEmpty')
      })
    } else if (isNaN(width as number)) {
      // Assertion to pass value as a number, check will go true if a string is passed
      validationErrors.push({
        key: 'width',
        error: I18n.t('announcements.error.invalidNumberValue')
      })
    }

    if (!height) {
      validationErrors.push({
        key: 'height',
        error: I18n.t('announcements.error.requiredEmpty')
      })
    } else if (isNaN(height as number)) {
      // Assertion to pass value as a number, check will go true if a string is passed
      validationErrors.push({
        key: 'height',
        error: I18n.t('announcements.error.invalidNumberValue')
      })
    }

    if (!draught) {
      validationErrors.push({
        key: 'draught',
        error: I18n.t('announcements.error.requiredEmpty')
      })
    } else if (isNaN(draught as number)) {
      // Assertion to pass value as a number, check will go true if a string is passed
      validationErrors.push({
        key: 'draught',
        error: I18n.t('announcements.error.invalidNumberValue')
      })
    }

    return validationErrors
  }

  async function applyDimensionsChanges(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault()
    setErrors([])

    const validationErrors = validate()

    if (validationErrors.length > 0) {
      setErrors(validationErrors)
      setLoading(false)
      console.warn(
        'confirmDimensions.fillInAllFields\n\n',
        validationErrors.map(err => `${err.key}: ${err.error}`).join('\n')
      )
      return
    }

    if (isDifferentFromInitial) {
      // Apply dimension changes first
      setLoading(true)
      try {
        await teqplayAPIService
          .patchCurrentShipInformation({
            width: (width as number) || 0,
            length: (length as number) || 0,
            height: (height as number) || 0,
            draught: (draught as number) || 0
          })
          .then(() => {
            onUpdateDimensionsInState()
          })
      } catch (err) {
        console.error(err)
        toast.error(I18n.t('confirmDimensions.patchError'))
      }
    }

    setLoading(false)
    onSubmit()
  }

  function getDimension(type: 'length' | 'width' | 'height' | 'draught') {
    if (!ship) {
      // No ship = no dimensions
      return undefined
    }

    const dimension = get(ship, `dimensions.${type}`) as number | undefined
    if (dimension !== undefined) {
      // Specific dimension found, return it even if it is 0
      return dimension
    }

    if (type === 'length' && ship.length) {
      return ship.length
    } else if (type === 'width' && ship.width) {
      return ship.width
    } else if (type === 'draught' && ship.maxDraught) {
      return ship.maxDraught
    }

    if (!ship.positionOfTransponder) {
      // No specific dimension found nor positionOfTransponder found so it cannot be derived
      return undefined
    }

    switch (type) {
      case 'length': {
        return ship.positionOfTransponder.distanceToBow + ship.positionOfTransponder.distanceToStern
      }
      case 'width': {
        return (
          ship.positionOfTransponder.distanceToPort + ship.positionOfTransponder.distanceToStarboard
        )
      }
      default: {
        return undefined
      }
    }
  }
}

export default DimensionsModal
