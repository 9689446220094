import React, { useState } from 'react'
import Modal from 'react-modal'
import { useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'

import CountrySelector from '../../countrySelector/CountrySelector'
import BICSGenericInputRow from '../bicsGenericInputRow/BICSGenericInputRow'

import { ICustomError, IRootProps } from '../../../@types/types'
import { useAnalytics } from '../../../services/AnalyticsWrapper/AnalyticsWrapper'
import { BicsAddress } from '../../../services/TeqplayAPIService/TeqplayApi'
import { determineApplicationTheme } from '../../../utils/style'

import './BICSBillingInfoModal.scss'

interface IProps {
  showModal: boolean
  onSubmit: (address: BicsAddress) => void
  onClose: () => void
  initialAddress?: BicsAddress
}

const BICSBillingInfoModal = ({ showModal, onSubmit, onClose, initialAddress }: IProps) => {
  const { listenToDeviceTheme, themeOverride } = useSelector((props: IRootProps) => props.settings)
  const analytics = useAnalytics('BICSBillingInfoModal')
  const theme = determineApplicationTheme(listenToDeviceTheme, themeOverride)

  const [givenName, setGivenName] = useState<string | undefined>(initialAddress?.givenName)
  const [familyName, setFamilyName] = useState<string | undefined>(initialAddress?.familyName)
  const [streetName, setStreetName] = useState<string | undefined>(initialAddress?.streetName)
  const [postalCode, setPostalcode] = useState<string | undefined>(initialAddress?.postalCode)
  const [city, setCity] = useState<string | undefined>(initialAddress?.city)
  const [country, setCountry] = useState<string | undefined>(initialAddress?.country)
  const [vatNumber, setVATNumber] = useState<string | undefined>(initialAddress?.vatNumber)
  const [organisation, setOrganisation] = useState<string | undefined>(
    initialAddress?.organisation || undefined
  )

  const [errors, setErrors] = useState<ICustomError<keyof BicsAddress>[]>([])

  return (
    <Modal
      isOpen={showModal}
      onRequestClose={onClose}
      className={`billing-info-modal ${theme}`}
      style={{ overlay: { backgroundColor: 'rgba(0,0,0,0.50)' } }}
      shouldCloseOnOverlayClick={true}
    >
      <form className="billing-modal-inner" onSubmit={handleSubmitBillingInfoForm}>
        <div className="title top">
          <h2>{I18n.t('announcements.travel.billingInfo')}</h2>
        </div>
        <BICSGenericInputRow<keyof BicsAddress>
          keyName="givenName"
          errors={errors}
          className="flex-reverse"
        >
          <input
            name="givenName"
            type="text"
            value={givenName}
            onChange={e => setGivenName(e.target.value)}
            className="textfield"
            autoCapitalize="true"
          />
        </BICSGenericInputRow>
        <BICSGenericInputRow<keyof BicsAddress>
          keyName="familyName"
          errors={errors}
          className="flex-reverse"
        >
          <input
            name="familyName"
            type="text"
            value={familyName}
            onChange={e => setFamilyName(e.target.value)}
            className="textfield"
            autoCapitalize="true"
          />
        </BICSGenericInputRow>
        <BICSGenericInputRow<keyof BicsAddress>
          keyName="streetName"
          errors={errors}
          className="flex-reverse"
        >
          <input
            name="streetName"
            type="text"
            value={streetName}
            onChange={e => setStreetName(e.target.value)}
            className="textfield"
            autoCapitalize="true"
          />
        </BICSGenericInputRow>
        <BICSGenericInputRow<keyof BicsAddress>
          keyName="postalCode"
          errors={errors}
          className="flex-reverse"
        >
          <input
            name="postalCode"
            type="text"
            value={postalCode}
            onChange={e => setPostalcode(e.target.value)}
            className="textfield"
            autoCapitalize="true"
          />
        </BICSGenericInputRow>
        <BICSGenericInputRow<keyof BicsAddress>
          keyName="city"
          errors={errors}
          className="flex-reverse"
        >
          <input
            name="city"
            type="text"
            value={city}
            onChange={e => setCity(e.target.value)}
            className="textfield"
            autoCapitalize="true"
          />
        </BICSGenericInputRow>
        <BICSGenericInputRow<keyof BicsAddress>
          keyName="country"
          errors={errors}
          className="flex-reverse"
        >
          <CountrySelector value={country} onChange={c => setCountry(c?.code)} />
        </BICSGenericInputRow>
        <BICSGenericInputRow<keyof BicsAddress>
          keyName="organisation"
          errors={errors}
          className="flex-reverse"
          childrenWrapperClassName="flex-column"
          extraSubtitle={I18n.t('announcements.fields.optional')}
        >
          <input
            name="organisation"
            type="text"
            value={organisation}
            onChange={e => setOrganisation(e.target.value)}
            className="textfield"
          />
          <span className="subtitle">{I18n.t('announcements.fields.organisationHint')}</span>
        </BICSGenericInputRow>
        <BICSGenericInputRow<keyof BicsAddress>
          keyName="vatNumber"
          errors={errors}
          className="flex-reverse"
        >
          <input
            name="vatNumber"
            type="text"
            value={vatNumber}
            onChange={e => setVATNumber(e.target.value)}
            className="textfield"
          />
        </BICSGenericInputRow>

        <div className="buttons">
          <button
            className="button secondary cancel"
            type="button"
            onClick={e => {
              e.preventDefault()
              onClose()
            }}
          >
            {I18n.t('cancel')}
          </button>
          <button className="button primary save" type="submit">
            {I18n.t('save')}
          </button>
        </div>
      </form>
    </Modal>
  )

  function validate(): ICustomError<keyof BicsAddress>[] {
    const validationErrors: ICustomError<keyof BicsAddress>[] = []

    if (!givenName) {
      validationErrors.push({
        key: 'givenName',
        error: I18n.t('announcements.error.requiredEmpty')
      })
    }

    if (!familyName) {
      validationErrors.push({
        key: 'familyName',
        error: I18n.t('announcements.error.requiredEmpty')
      })
    }

    if (!streetName) {
      validationErrors.push({
        key: 'streetName',
        error: I18n.t('announcements.error.requiredEmpty')
      })
    }

    if (!postalCode) {
      validationErrors.push({
        key: 'postalCode',
        error: I18n.t('announcements.error.requiredEmpty')
      })
    }

    if (!city) {
      validationErrors.push({ key: 'city', error: I18n.t('announcements.error.requiredEmpty') })
    }

    if (!country) {
      validationErrors.push({ key: 'country', error: I18n.t('announcements.error.requiredEmpty') })
    }

    if (!vatNumber) {
      validationErrors.push({
        key: 'vatNumber',
        error: I18n.t('announcements.error.requiredEmpty')
      })
    }

    return validationErrors
  }

  function handleSubmitBillingInfoForm(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault()
    e.stopPropagation()

    const validationErrors = validate()

    if (validationErrors.length > 0) {
      setErrors(validationErrors)
      return
    }

    const address: BicsAddress = {
      city,
      country,
      givenName,
      familyName,
      postalCode,
      streetName,
      organisation: organisation || undefined,
      vatNumber: vatNumber || undefined
    }

    analytics.newEvent('create_new_billinginfo', {})
    onSubmit(address)
    onClose()
  }
}

export default BICSBillingInfoModal
