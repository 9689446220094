import { divIcon } from 'leaflet'

import { IRouteItem } from '../../services/TeqplayAPIService/TeqplayApi'
import { getIconNameByType } from '../../utils/iconUtils'
import { SelectedItemModalEnum } from '../../@types/types'

export const arrivalIcon = divIcon({
  className: '',
  html: `<div id="icon-arrival" class='circle-icon arrival small green'}><span class=${'icon-flag'}></span></div>`,
  iconAnchor: [20, 20]
})

export const departureIcon = divIcon({
  className: '',
  html: `<div id="icon-departure" class='circle-icon departure small red'}><span class=${'icon-dot-circled'}></span></div>`,
  iconAnchor: [20, 20]
})

export const viaIcon = (viaNumber: React.ReactText) =>
  divIcon({
    className: '',
    html: `<div id="icon-via-${viaNumber}" class='small-dot-icon via'}>${viaNumber}</div>`,
    iconAnchor: [12, 12]
  })

export const waitIcon = (waitingTime: React.ReactText) =>
  divIcon({
    className: '',
    html: `<div id="icon-waiting" class='circle-icon wait dark-orange wait'>${waitingTime} min</div>`,
    iconAnchor: [20, 20]
  })

const bridgeDivIcon = (i: IRouteItem) =>
  divIcon({
    className: '',
    html: `<div id="icon-bridge" class='circle-icon bridge ${
      i.openingRequired ? 'purple' : 'blue'
    }'><span class=${getIconNameByType('BRIDGE', i)}></span></div>`,
    iconAnchor: [20, 20]
  })

const bridgeWaitingDivIcon = (i: IRouteItem) =>
  divIcon({
    className: '',
    html: `<div class='waiting-icon' id="icon-bridge-waiting"><div class='circle-icon bridgeWaiting purple'>
      <span class='${getIconNameByType('BRIDGE', i)}'></span>
    </div>
    <span class='waiting-time'>+${
      i.waitingTime ? Math.round(i.waitingTime / 60000) : '?'
    } min</span>
  </div>`,
    iconAnchor: [20, 20]
  })

const bridgeNotPassableDivIcon = (i: IRouteItem) =>
  divIcon({
    className: '',
    html: `<div id="icon-bridge-impassable" class='circle-icon bridgeNotPassable red'}><span class=${getIconNameByType(
      'BRIDGE',
      i
    )}></span></div>`,
    iconAnchor: [20, 20]
  })

const lockDivIcon = (i: IRouteItem) =>
  divIcon({
    className: '',
    html: `<div id="icon-lock" class='circle-icon lock purple'><span class=${getIconNameByType(
      'LOCK',
      i
    )}></span></div>`,
    iconAnchor: [20, 20]
  })

const lockWaitingDivIcon = (i: IRouteItem) =>
  divIcon({
    className: '',
    html: `<div class='waiting-icon' id="icon-lock-waiting"><div class='circle-icon lockWaiting purple'>
      <span class='${getIconNameByType('LOCK', i)}'></span>
    </div>
    <span class='waiting-time'>+${
      i.waitingTime ? Math.round(i.waitingTime / 60000) : '?'
    } min</span>
  </div>`,
    iconAnchor: [20, 20]
  })

const notificationDivIcon = (i: IRouteItem) =>
  divIcon({
    className: i.eta ? '' : 'faded',
    html: `<div id="icon-notification" class='circle-icon notification dark-orange'><span class=${getIconNameByType(
      'NOTIFICATION',
      i
    )}></span></div>`,
    iconAnchor: [20, 20]
  })

const watermeterDivIcon = (i: IRouteItem) =>
  divIcon({
    className: '',
    html: `<div id="icon-watermeter" class='circle-icon watermeter dark-blue'><span class=${getIconNameByType(
      'WATERMETER',
      i
    )}></span></div>`,
    iconAnchor: [20, 20]
  })

const vhfDivIcon = (i: IRouteItem) =>
  divIcon({
    className: '',
    html: `<div id="icon-vhf" class='circle-icon vhf dark-blue'><span class=${getIconNameByType(
      'VHFSECTOR',
      i
    )}></span></div>`,
    iconAnchor: [20, 20]
  })

const defaultDivIcon = divIcon({
  className: '',
  html: `<div id="icon-default" class='circle-icon gray'><span class=${'icon-location'}></span></div>`,
  iconAnchor: [20, 20]
})

export function getRouteItemClassNames(
  type: IRouteItem['type'] | SelectedItemModalEnum['type'],
  canBePassed: boolean,
  waitingTime?: number,
  openingRequired?: boolean
) {
  switch (type) {
    case 'START':
      return 'departure small red'
    case 'END':
      return 'arrival small green'
    case 'BRIDGE':
      if (canBePassed !== true) {
        return 'bridgeNotPassable red'
      } else if (waitingTime && waitingTime > 120000) {
        return 'bridgeWaiting purple'
      } else {
        return `bridge ${openingRequired ? 'purple' : 'blue'}`
      }
    case 'LOCK':
      if (waitingTime && waitingTime > 120000) {
        return 'lockWaiting purple'
      } else {
        return 'lock purple'
      }
    case 'NOTIFICATION':
      return 'notification dark-orange'
    case 'WATERMETER':
      return 'watermeter dark-blue'
    case 'VHFSECTOR':
      return 'vhf dark-blue'

    default:
      return 'gray'
  }
}

export function getRouteItemIcon(routeItem: IRouteItem, canBePassed: boolean) {
  switch (routeItem.type) {
    case 'BRIDGE':
      if (canBePassed !== true) {
        return bridgeNotPassableDivIcon(routeItem)
      } else if (routeItem.waitingTime && routeItem.waitingTime > 120000) {
        return bridgeWaitingDivIcon(routeItem)
      } else {
        return bridgeDivIcon(routeItem)
      }
    case 'LOCK':
      if (routeItem.waitingTime && routeItem.waitingTime > 120000) {
        return lockWaitingDivIcon(routeItem)
      } else {
        return lockDivIcon(routeItem)
      }
    case 'NOTIFICATION':
      return notificationDivIcon(routeItem)
    case 'WATERMETER':
      return watermeterDivIcon(routeItem)
    case 'VHFSECTOR':
      return vhfDivIcon(routeItem)
    default:
      return defaultDivIcon
  }
}
