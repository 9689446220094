import { LatLngBounds, LatLngTuple } from 'leaflet'
import { cloneDeep, isEqual } from 'lodash'
import { useEffect, useState } from 'react'

import { IBerth, IShipInfo } from '../services/TeqplayAPIService/TeqplayApi'
import TeqplayApiService from '../services/TeqplayAPIService/TeqplayApiService'
import { filterSuitableBerth } from '../utils/berths'
import { usePrevious } from './usePrevious'

export function useBerths(
  apiService: TeqplayApiService,
  ship: IShipInfo | null,
  isChecked: boolean,
  types: {
    suitable: boolean
    public: boolean
    terminal: boolean
  },
  passedZoomCheck: boolean,
  cones?: number,
  berthBounds?: LatLngBounds
) {
  const [berths, setBerths] = useState<IBerth[] | null>(null)
  const [error, setError] = useState<any | null>(null)

  const previousBounds = usePrevious(berthBounds)
  const previousChecked = usePrevious(isChecked)

  useEffect(() => {
    async function fetchBerths() {
      try {
        setError(null)
        const fetchedBerths = await apiService.fetchBerths(berthBounds, true)
        setBerths(processBerths(fetchedBerths))
      } catch (err) {
        setError(err)
        throw err
      }
    }

    function processBerths(berthList: IBerth[]) {
      return berthList
        .filter(
          b =>
            // Some berths only have a single location
            b.area.coordinates[0]
        )
        .map(b => ({
          ...b,
          area: {
            ...b.area,
            coordinates: b.area.coordinates.map(coordinate => {
              const coord = cloneDeep(coordinate)
              const reversedCoords = coord.map(c => c.reverse()) as LatLngTuple[]
              return reversedCoords
            })
          }
        }))
    }

    if (
      isChecked === true &&
      passedZoomCheck &&
      (!isEqual(isChecked, previousChecked) ||
        !isEqual(berthBounds?.getCenter(), previousBounds?.getCenter()))
    ) {
      fetchBerths()
    } else if (!passedZoomCheck) {
      setBerths([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isChecked, error, berthBounds, types])

  return (
    berths?.filter(
      b =>
        (types?.public && !b.terminalId) ||
        (types?.terminal && b.terminalId) ||
        // Supply all berths for cones filtering
        (types.suitable && filterSuitableBerth(b, ship, cones || 0))
    ) || null
  )
}
