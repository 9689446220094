import { useSelector } from 'react-redux'
import L, { LatLngBounds } from 'leaflet'
import isEqual from 'lodash/isEqual'
import React, { useCallback, useEffect, useState } from 'react'
import { CircleMarker, Marker, Popup } from 'react-leaflet'
import { I18n } from 'react-redux-i18n'

import { usePrevious } from '../../../../../hooks/usePrevious'
import TeqplayApiService from '../../../../../services/TeqplayAPIService/TeqplayApiService'
import waterwaySignsDataNL from './waterwaySignsResources/nl_waterwaySigns.json'
import waterwaySignsDataFR from './waterwaySignsResources/fr_waterwaySigns.json'
import { IRootProps, SupportedLocales } from '../../../../../@types/types'

import './WaterwaySignsLayer.scss'

interface IProps {
  name: string
  checked: boolean
  bounds: LatLngBounds | undefined
  teqplayApiService: TeqplayApiService

  minimumZoomLevel?: number
  mapZoomLevel?: number
  disablePopup?: boolean
  handleFetchError?: (err: Error) => void
  disableInteraction?: boolean
}

export interface IWaterwaySign {
  bprCode?: string
  anchoring?: string
  description?: string
  globalId: string
  locatableType: 'TRAFFIC_SIGN'
  location: {
    latitude: number
    longitude: number
    coordinates: [number, number]
    type: string
  }
  singleLocation: {
    latitude: number
    longitude: number
    coordinates: [number, number]
    type: string
  }
  _id: string
}

const WaterwaySignsLayer = (props: IProps) => {
  const { checked, bounds, teqplayApiService, mapZoomLevel, minimumZoomLevel, disableInteraction } =
    props
  const locale = useSelector((s: IRootProps) => s.i18n.locale)
  const signs = useWaterwaySigns(teqplayApiService, bounds, checked, locale)
  const [mappedDetailedObjects, setMappedDetailedObjects] = useState<JSX.Element[]>([])
  const memoizedMapObjectToSign = useCallback(mapObjectToSign, []) // eslint-disable-line react-hooks/exhaustive-deps
  const waterwaySignsData = locale === 'fr_FR' ? waterwaySignsDataFR : waterwaySignsDataNL

  useEffect(() => {
    async function mapAllObjectsToSigns() {
      const mappedObj = await Promise.all((signs || []).map(s => memoizedMapObjectToSign(s)))
      setMappedDetailedObjects(mappedObj.filter(e => e) as JSX.Element[])
    }

    if (checked && (mapZoomLevel || 12) >= 14 && (signs || []).length > 0) {
      mapAllObjectsToSigns()
    } else {
      setMappedDetailedObjects([])
    }
  }, [checked, mapZoomLevel, memoizedMapObjectToSign, signs])

  if (!checked || !signs || (mapZoomLevel || 12) < (minimumZoomLevel || 11)) {
    return null
  }

  if ((mapZoomLevel || 12) < 14) {
    return (
      <React.Fragment>
        {signs.map((s, i) => (
          <CircleMarker
            key={i}
            center={{ lat: s.location.coordinates[1], lng: s.location.coordinates[0] }}
            radius={2}
            color={getDotColor(s.bprCode)}
            interactive={!disableInteraction}
          >
            {getPopup(
              s.description,
              waterwaySignsData.find(x => x.bprCode === s.bprCode)?.description,
              s.anchoring,
              s._id
            )}
          </CircleMarker>
        ))}
      </React.Fragment>
    )
  }

  return <>{mappedDetailedObjects}</>

  async function mapObjectToSign(s: IWaterwaySign) {
    const icon = await createIconImage(s)

    if (icon) {
      return (
        <Marker
          key={`MARKER-${s.bprCode}-${s._id}-${s.location.coordinates[1]}_${s.location.coordinates[0]}`}
          position={{ lat: s.location.coordinates[1], lng: s.location.coordinates[0] }}
          icon={icon}
        >
          {getPopup(
            s.description,
            waterwaySignsData.find(x => x.bprCode === s.bprCode)?.description,
            s.anchoring,
            s._id
          )}
        </Marker>
      )
    } else {
      return (
        <CircleMarker
          key={`CIRCLEMARKER-${s.bprCode}-${s._id}-${s.location.coordinates[1]}_${s.location.coordinates[0]}`}
          center={{ lat: s.location.coordinates[1], lng: s.location.coordinates[0] }}
          radius={4}
          fillColor={getDotColor(s.bprCode)}
          fillOpacity={1}
          color={getDotColor(s.bprCode)}
        >
          {getPopup(
            s.description,
            waterwaySignsData.find(x => x.bprCode === s.bprCode)?.description,
            s.anchoring,
            s._id
          )}
        </CircleMarker>
      )
    }
  }

  async function createIconImage(sign: IWaterwaySign) {
    try {
      const waterwayItem = waterwaySignsData.find(x => x.bprCode === sign.bprCode)
      if (!waterwayItem) {
        return null
      }

      let height = 32
      let width = 32

      if (
        waterwayItem.actualDimensions &&
        waterwayItem.actualDimensions.height < waterwayItem.actualDimensions.width
      ) {
        const multiplier =
          waterwayItem.actualDimensions.width / waterwayItem.actualDimensions.height
        width = 32 * multiplier
      } else if (waterwayItem.actualDimensions != null) {
        const multiplier =
          waterwayItem.actualDimensions.height / waterwayItem.actualDimensions.width
        height = 32 * multiplier
      }

      if (waterwayItem.dynamicRendering) {
        const fixedBPR = sign.bprCode?.split('.').join('-')

        const prefix = (bprCode: string) => {
          switch (bprCode) {
            case 'B.11.b':
              return `<div class='waterwayIcon-prefix'>VHF</div>`
            case 'E.23':
              return `<div class='waterwayIcon-prefix'>VHF</div>`
            default:
              return ''
          }
        }

        const value = (bprCode: string, description: string) => {
          switch (bprCode) {
            case 'A.5.1':
              return description.slice(-3, -1)
            case 'G.5.2':
              return description || '?'
            default:
              if (description.length === 0 || description.length >= 3) return '?'
              return description
          }
        }

        return L.divIcon({
          className: 'waterwayIcon',
          html: `<div class="waterwayIcon-${fixedBPR}">${prefix(
            sign.bprCode || ''
          )}<div><div class="waterwayIcon-${fixedBPR}-text">${value(
            sign.bprCode || '',
            sign.description || ''
          )}</div></div>`,
          iconSize: [width, height],
          iconAnchor: [width / 2, height / 2],
          popupAnchor: [0, -height / 2]
        })
      } else if (waterwayItem.imgUrl) {
        const base64Image = await import(`./waterwaySignsResources${waterwayItem.imgUrl}`)

        return L.icon({
          className: 'waterwayIcon',
          iconUrl: base64Image.default,
          iconSize: [width, height],
          iconAnchor: [width / 2, height / 2],
          popupAnchor: [0, -height / 2]
        })
      } else {
        return null
      }
    } catch (error) {
      console.error(error)
      return null
    }
  }

  function getDotColor(bprCode?: string) {
    switch (bprCode?.charAt(0)) {
      case 'A':
        return 'rgb(223, 30, 37)'
      case 'B':
        return 'rgb(223, 30, 37)'
      case 'C':
        return 'rgb(223, 30, 37)'
      case 'D':
        return 'rgb(2, 118, 189)'
      case 'E':
        return 'rgb(2, 118, 189)'
      case 'F':
        return 'rgb(255,255,255)'
      case 'G':
        return 'rgb(237,173,0)'
      case 'H':
        return 'rgb(255,255,255)'
      default:
        return 'rgb(0,0,255)'
    }
  }

  function getPopup(
    description?: string,
    itemdescription?: string,
    anchoring?: string,
    key?: string
  ) {
    return (
      <Popup key={key} autoPan={false}>
        <div className="popup-waterwaySign">
          {!description && !itemdescription && (
            <div className="popup-waterwaySign-description">{I18n.t('noInfoAvailable')}</div>
          )}
          <div className="popup-waterwaySign-description">{description}</div>
          {itemdescription ? (
            <div className="popup-waterwaySign-note">
              {I18n.t('map.waterwaySigns.description')}: {itemdescription}
            </div>
          ) : null}
          {anchoring ? (
            <div className="popup-waterwaySign-note">
              {I18n.t('map.waterwaySigns.anchoring')}: {anchoring}
            </div>
          ) : null}
        </div>
      </Popup>
    )
  }
}

export function useWaterwaySigns(
  teqplayApiService: TeqplayApiService,
  bounds?: LatLngBounds,
  isChecked?: boolean,
  locale?: SupportedLocales
) {
  const [waterwaySigns, setWaterwaySigns] = useState<IWaterwaySign[] | null>(null)
  const [error, setError] = useState<any | null>(null)

  const previousBounds = usePrevious(bounds)
  const previousChecked = usePrevious(isChecked)

  async function fetchWaterwaySigns() {
    try {
      setError(null)
      const fetchedSigns = await teqplayApiService.getTrafficSignsInBounds(bounds, locale)
      setWaterwaySigns(fetchedSigns)
    } catch (err) {
      setError(err)
      throw err
    }
  }

  useEffect(() => {
    if (
      isChecked === true &&
      (!isEqual(isChecked, previousChecked) ||
        !isEqual(bounds?.getCenter(), previousBounds?.getCenter()))
    ) {
      fetchWaterwaySigns()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isChecked, error, bounds])

  return waterwaySigns
}

export default WaterwaySignsLayer
