import React, { useState } from 'react'
import { I18n } from 'react-redux-i18n'

import { useAnalytics } from '../../../services/AnalyticsWrapper/AnalyticsWrapper'
import { IRouteNotification } from '../../../services/TeqplayAPIService/TeqplayApi'
import { formatDateTime } from '../../../utils/dates'
import PhoneNumberButton from '../../phoneNumberButton/PhoneNumberButton'
import DefaultTransition from '../../shared/defaultTransition/DefaultTransition'

interface IProps {
  notification: IRouteNotification
}

const NotificationItem = (props: IProps) => {
  const { notification } = props
  const analytics = useAnalytics('NotificationItem')
  const [isOpened, setIsOpened] = useState(false)

  return (
    <div
      className={`notification-wrapper-item ${notification.color}${
        notification.description && notification.title ? ' clickable' : ''
      }`}
      id={`notification-${notification._id || notification.identifier}`}
      onClick={() => {
        setIsOpened(!isOpened)
        analytics.newEvent('click-notification-item', {
          item: notification._id || notification.identifier
        })
      }}
    >
      <div className="notification-title" id="notification-title">
        <span className={`icon-attention ${notification.color}`} />
        <div className="title">{notification.title || notification.description}</div>
      </div>

      {notification.description && notification.title && (
        <div className="more-info">
          <span>
            {I18n.t(
              isOpened ? 'map.notification.clickForLessInfo' : 'map.notification.clickForMoreInfo'
            )}
          </span>
          <span className="identifier">{notification.identifier}</span>
        </div>
      )}

      <DefaultTransition slideFrom={'top'} offset={10} active={isOpened}>
        {style =>
          notification.description && notification.title ? (
            <div className={`notification-content${isOpened ? ' opened' : ''}`} style={style}>
              {(notification.title || notification.message) && (
                <div className="notification-inner">
                  <section className="details-section">
                    {notification.title &&
                      notification.description &&
                      notification.description !== notification.title && (
                        <div className="caption" id="notification-description">
                          <b>{notification.description}</b>
                        </div>
                      )}
                    {notification.titleTranslated && (
                      <div className="details-item small" id="notification-message">
                        {notification.titleTranslated}
                      </div>
                    )}
                    {notification.message && notification.message !== notification.description && (
                      <div className="details-item" id="notification-message">
                        {notification.message}
                      </div>
                    )}
                    {notification.fairway?.name && (
                      <div className="details-item" id="notification-fairway">
                        {notification.fairway.name}
                      </div>
                    )}
                    {notification.startTime && (
                      <div className="details-item" id="notification-start-time">
                        <b>{I18n.t('routeList.itemDetails.startTime')}: </b>
                        {formatDateTime(notification.startTime)}
                      </div>
                    )}
                    {notification.endTime && notification.endTime !== 2051226061000 && (
                      <div className="details-item" id="notification-end-time">
                        <b>{I18n.t('routeList.itemDetails.endTime')}: </b>
                        {formatDateTime(notification.endTime)}
                      </div>
                    )}
                    {notification.telephoneNumber && (
                      <div className="details-item" id="notification-phone-number">
                        <PhoneNumberButton number={notification.telephoneNumber} />
                      </div>
                    )}
                    {notification.origin && (
                      <div className="details-item" id="notification-origin">
                        {notification.origin}
                      </div>
                    )}
                  </section>
                </div>
              )}
            </div>
          ) : null
        }
      </DefaultTransition>
    </div>
  )
}

export default NotificationItem
