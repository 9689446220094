import React, { useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { I18n, setLocale } from 'react-redux-i18n'
import { setDefaultOptions } from 'date-fns'
import { IRootProps, SupportedLocales } from '../../@types/types'
import { saveToLocalStorage } from '../../utils/localStorage'
import { ALL_LOCALES } from '../../utils/constants'
import { currentLocale } from '../../utils/localeUtils'
import { useOnClickOutside } from '../../hooks/useOnClickOutside'
import './LocalePicker.scss'

interface IProps {
  alwaysSelecting?: boolean
}

const LocalePicker = ({ alwaysSelecting }: IProps) => {
  const locale = useSelector((s: IRootProps) => s.i18n.locale)
  const [selectingLocale, setSelectingLocale] = useState<boolean | undefined>(alwaysSelecting)
  const ref = useRef<HTMLDivElement | null>(null)
  const allLocales = ALL_LOCALES.filter(l => l !== locale)
  const dispatch = useDispatch()

  useOnClickOutside(ref, () => setSelectingLocale(false))

  return (
    <div className={`locale-wrapper ${selectingLocale ? 'selecting' : ''}`} ref={ref}>
      <div className="locale-item current" onClick={() => setSelectingLocale(!selectingLocale)}>
        <div className="flag-container">
          <div className={`locale-flag ${locale}`} />
        </div>
        <div className="locale-name">{I18n.t(`locale.${locale}`)}</div>
        <i className={`icon-angle-down ${selectingLocale ? 'rotated' : ''}`} />
      </div>
      {(selectingLocale || alwaysSelecting) && (
        <div className={`drop-down-menu${alwaysSelecting ? ' persisted' : ''}`}>
          {allLocales.map(loc => (
            <div key={loc} className="locale-item" onClick={() => selectLocale(loc)}>
              <div className="flag-container">
                <div className={`locale-flag ${loc}`} />
              </div>
              <div className="locale-name">{I18n.t(`locale.${loc}_native`)}</div>
            </div>
          ))}
        </div>
      )}
    </div>
  )

  function selectLocale(loc: SupportedLocales) {
    setSelectingLocale(false)
    saveToLocalStorage('locale', loc)
    dispatch(setLocale(loc))
    // This will set all the date-fns options so this as default
    setDefaultOptions({ locale: currentLocale(loc) })
  }
}

export default LocalePicker
