import * as React from 'react'
import { I18n, Translate } from 'react-redux-i18n'

import { IRoute } from '../../../../../services/TeqplayAPIService/TeqplayApi'
import { formatDateTime } from '../../../../../utils/dates'
import { parseStringForID } from '../../../../../utils/tests'

import './SavedRoute.scss'

interface IProps {
  name: string
  route: IRoute
  date: number
  startRoute: (route: IRoute) => void
  deleteRoute: () => void
}

const SavedRoute: React.FunctionComponent<IProps> = props => {
  return (
    <li className="saved-route-wrapper" id={parseStringForID(`saved-route-${props.name}`)}>
      <div className="properties">
        <h3 className="route-title">{props.name}</h3>
        <div className="route-property">
          <Translate value="routeSelection.distance" className="property-title" />
          <span className="property-value">{props.route.distance.toFixed(0)} km </span>
        </div>
        <div className="route-property">
          <Translate value="routeSelection.dateSaved" className="property-title" />
          <span className="property-value">{formatDateTime(props.date)}</span>
        </div>
      </div>
      <div className="buttons">
        <button
          className="button secondary large right resume"
          onClick={() => props.startRoute(props.route)}
          id={parseStringForID(`button-start-saved-route-${props.name}`)}
        >
          {I18n.t('routeSelection.startRoute')}
        </button>
        <button
          className="button danger large"
          onClick={() => props.deleteRoute()}
          id={parseStringForID(`button-delete-saved-route-${props.name}`)}
        >
          {I18n.t('delete')}
        </button>
      </div>
    </li>
  )
}

export default SavedRoute
