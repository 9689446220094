import React from 'react'
import { WMSTileLayer } from 'react-leaflet'

interface IProps {
  checked?: boolean
}

const ECDISLayer = (props: IProps) => {
  if (!props.checked) {
    return null
  }

  return (
    <WMSTileLayer
      url={
        'https://geo.rijkswaterstaat.nl/arcgis/rest/services/ENC/mcs_inland/MapServer/exts/MaritimeChartService/WMSServer'
      }
      detectRetina={true}
    />
  )
}

export default ECDISLayer
