import { format } from 'date-fns'
import React from 'react'
import { I18n } from 'react-redux-i18n'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'

import TeqplayApiService from '../../../services/TeqplayAPIService/TeqplayApiService'
import { confirmPopup } from '../../shared/confirmPopup/ConfirmPopup'

import { IBicsVoyage, IRootProps, IRouteSearchProps } from '../../../@types/types'
import { useAnalytics } from '../../../services/AnalyticsWrapper/AnalyticsWrapper'
import {
  BicsCargo,
  BicsDestination,
  IShipInfo,
  BicsHull,
  IRoute
} from '../../../services/TeqplayAPIService/TeqplayApi'
import { DATE_TIME_FORMAT } from '../../../utils/constants'
import { sendExceptionToSentry } from '../../../utils/sentry'
import './BICSVoyageListItem.scss'

interface IProps {
  travel: IBicsVoyage
  isCurrentVoyage?: boolean
  teqplayAPIService: TeqplayApiService
  loading: { type: 'BICS' | 'START'; travelId: number } | undefined
  setLoading: (value: { type: 'BICS' | 'START'; travelId: number } | undefined) => void
  refreshData: () => void
  hasCurrentVoyage: boolean
  updateDraughtInProfile: (
    currentDraught: number,
    dimensions: IShipInfo['dimensions']
  ) => Promise<void>
  hulls: BicsHull[] | null
  mainHull: BicsHull | undefined
  setRouteSelection: (route: IRouteSearchProps) => void
  setSelectedTravelID: (travelID: string) => void
  startRoute?: (route: IRoute) => void
  hasSentVoyage: boolean
}

const BICSVoyageListItem = ({
  travel,
  isCurrentVoyage,
  teqplayAPIService,
  loading,
  setLoading,
  refreshData,
  hasCurrentVoyage,
  updateDraughtInProfile,
  hulls,
  mainHull,
  setRouteSelection,
  setSelectedTravelID,
  startRoute,
  hasSentVoyage
}: IProps) => {
  const ship = useSelector((props: IRootProps) => props.userLocation.currentLocation)
  const analytics = useAnalytics('BICSVoyageListItem')
  const adjustedStatus =
    travel.status === 'ANSWERED_OK' && travel.cancelled
      ? 'ANSWERED_OK_CANCELLED'
      : travel.status === 'PENDING' && travel.paused
      ? 'PENDING_PAUSE'
      : travel.status === 'ANSWERED_OK' && travel.paused
      ? 'ANSWERED_OK_PAUSED'
      : travel.status

  const allCargo = travel.cargo.map(c => ({
    cargoInfo: mapCargoToObject(c.vnNr || c.hsCode),
    onBoardInfo: c
  }))

  const hullsForVoyage = (travel.hulls || [])?.map(h =>
    hulls?.find(hx => hx.mmsi === h || hx.eni === h)
  )

  return (
    <div
      className={`travel-row button ${isCurrentVoyage ? 'current-travel primary' : 'secondary'}`}
      key={`${travel.userId}-${travel.travelId}`}
      onClick={e => {
        e.preventDefault()
        e.stopPropagation()
        analytics.newEvent('bics_view_voyage', { travelId: travel.travelId })
        if (travel.travelId) {
          setSelectedTravelID(travel.travelId.toString())
        }
      }}
    >
      <div className="left">
        <div className="journey-cones">
          <div className="journey">
            <span className="from-location">
              {getDestinationDisplayName(travel.fromLocationObject) || travel.fromLocation}
            </span>
            {(travel.viaLocationObjects || []).map((loc, i) => (
              <span className="sub-travel desktop via-point" key={`${loc}-${i}`}>
                <i className="icon-right" />
                {getDestinationDisplayName(loc)}
              </span>
            ))}

            {!travel.viaPoints || travel.viaPoints.length === 0 ? null : (travel.viaPoints || [])
                .length > 2 ? (
              <span className="sub-travel mobile via-point summary">
                <i className="icon-right" />
                {I18n.t('announcements.travel.viaCount', {
                  viaCount: travel.viaLocationObjects?.length
                })}
              </span>
            ) : (
              travel.viaLocationObjects
                ?.map(vlo => vlo?.term || vlo?.termLocal || vlo?.name || vlo?.nameLocal)
                .map((loc, i) => (
                  <span className="sub-travel mobile via-point" key={`${loc}-${i}`}>
                    <i className="icon-right" />
                    <span className="loc-item" data-loc={loc}>
                      {loc}
                    </span>
                  </span>
                ))
            )}

            <i className="icon-right" />
            <span className="to-location">
              {travel.toLocationObject?.name ||
                travel.toLocationObject?.nameLocal ||
                travel.toLocation}
            </span>
          </div>
          <div className="cones">
            {travel.cones === '0' ? null : (
              <>
                {travel.cones === '1' || travel.cones === '2' || travel.cones === '3' ? (
                  <div className="cone-image" />
                ) : null}
                {travel.cones === '2' || travel.cones === '3' ? (
                  <div className="cone-image" />
                ) : null}
                {travel.cones === '3' ? <div className="cone-image" /> : null}
              </>
            )}{' '}
          </div>
        </div>

        <div className="cargo-summary">
          {allCargo.length === 0 ? (
            <span className="cargo-name">{I18n.t('announcements.travel.noCargo')}</span>
          ) : allCargo.length === 1 ? (
            <>
              <span className="weight">
                {allCargo[0].onBoardInfo.weightTn} {I18n.t('announcements.fields.tonnage')}
              </span>
              <span className="cargo-name">{allCargo[0].cargoInfo?.name}</span>
            </>
          ) : (
            <>
              <span className="weight">
                {allCargo.length > 0
                  ? allCargo.map(c => c.onBoardInfo.weightTn).reduce((a, b) => (a || 0) + (b || 0))
                  : null}{' '}
                {I18n.t('announcements.fields.tonnage')}
              </span>
              <span className="cargo-name">
                {I18n.t('announcements.travel.variousCargo', { cargoAmount: allCargo.length })}
              </span>
            </>
          )}
        </div>
        <div className="dates">
          <span>{format(new Date(travel.startTime), DATE_TIME_FORMAT)}</span>
          <span> - </span>
          <span>{format(new Date(travel.endTime), DATE_TIME_FORMAT)}</span>
        </div>
        <div className="status-personsOnBoard">
          <div className={`status ${adjustedStatus || 'UNKNOWN'}`}>
            <div className={`status-dot ${adjustedStatus || 'UNKNOWN'}`} />
            <div className="status-name">
              {I18n.t(`announcements.travelList.status.${adjustedStatus || 'UNKNOWN'}`)}
            </div>
          </div>
          <div className="persons-depth">
            <div className="personsOnBoard">
              <i className="icon-user" />
              <span className="user-amount">{travel.personsOnBoard}</span>
            </div>
            <div className="depth">
              <i className="icon-depth" />
              <span className="user-amount">{travel.currentDraught}m</span>
            </div>
          </div>
        </div>
        {hullsForVoyage.length > 1 && (
          <div className="hulls-summary">
            <div className="circle-dot">
              <i className="icon-ship" />
              {hullsForVoyage.length}
            </div>
            <span className="hulls-names">
              {hullsForVoyage
                .slice(0, 3)
                .map(h => h?.name || h?.mmsi || h?.eni)
                .join(', ')}
            </span>
            <span className="hulls-extra">
              {hullsForVoyage.length > 3 ? `+${hullsForVoyage.slice(3).length}` : null}
            </span>
          </div>
        )}
        {travel.status !== 'ANSWERED_REJECT' && (
          <div className="voyage-row-buttons">
            {!isCurrentVoyage && !travel.cancelled && (
              <button
                className="button primary"
                type="button"
                disabled={travel.status === 'PENDING' || travel.status === 'SENT'}
                onClick={e => {
                  e.preventDefault()
                  e.stopPropagation()
                  startNavigation()
                }}
              >
                <i className="icon icon-play" />
                <span>{I18n.t('announcements.travel.startInBics')}</span>
              </button>
            )}
            {isCurrentVoyage && !travel.cancelled && travel.status === 'ANSWERED_OK' && (
              <button
                className="button primary"
                type="button"
                onClick={e => {
                  e.preventDefault()
                  e.stopPropagation()

                  // Start navigation in BICS
                  pauseTravelInBICS(travel.travelId)
                }}
              >
                <i className="icon icon-pause" />
                <span>{I18n.t('announcements.travel.pauseInBics')}</span>
              </button>
            )}
            {isCurrentVoyage &&
              travel.status !== 'PLANNED' &&
              !travel.cancelled &&
              travel.status === 'ANSWERED_OK' && (
                <button
                  className="button primary"
                  type="button"
                  onClick={e => {
                    e.preventDefault()
                    e.stopPropagation()

                    // Stop navigation in BICS
                    stopTravelInBICS(travel.travelId)
                  }}
                >
                  <i className="icon icon-stop" />
                  <span>{I18n.t('announcements.travel.stopInBics')}</span>
                </button>
              )}
            <button
              className="button primary"
              type="button"
              onClick={e => {
                e.preventDefault()
                e.stopPropagation()

                // Start navigation in RiverGuide
                if (travel.riverguideSelectedRoute && startRoute) {
                  startRoute(travel.riverguideSelectedRoute)
                }
              }}
              disabled={!travel.riverguideSelectedRoute ? true : false}
            >
              <i className="icon icon-user-location" />
              {I18n.t('announcements.travel.startNavigating')}
            </button>
          </div>
        )}
      </div>
      <div className="right">
        <i className="icon-right-open" />
      </div>
    </div>
  )

  function startNavigation() {
    // Start navigation in BICS
    if (hasSentVoyage) {
      confirmPopup({
        cancelText: I18n.t('no'),
        confirmText: I18n.t('yes'),
        closeOnOutsideClick: false,
        onConfirm: () => {
          startTravelInBICS(travel.travelId)
        },
        message: I18n.t('announcements.travel.sentVoyageConfirmation')
      })
    } else {
      startTravelInBICS(travel.travelId)
    }
  }

  async function startTravelInBICS(travelId: number | undefined) {
    if (loading) {
      toast.error(I18n.t('announcements.error.awaitingAction'))
      return
    }

    if (!isCurrentVoyage && hasCurrentVoyage) {
      toast.error(I18n.t('announcements.error.hasActiveVoyage'))
      return
    }

    if (!travelId) {
      toast.error(I18n.t('announcements.error.noTravelId'))
      return
    }

    setLoading({ type: 'BICS', travelId })
    analytics.newEvent('bics_start_travel', { travelId })

    try {
      await teqplayAPIService.startBICSTravel(travelId)
      if (travel.currentDraught && ship?.dimensions) {
        await updateDraughtInProfile(travel.currentDraught, ship.dimensions)
      }
      toast.success(I18n.t('announcements.travel.started'))
    } catch (error) {
      console.error(error)
      toast.error(I18n.t('generalError'))
      sendExceptionToSentry(
        undefined,
        { message: '[BICS] Starting travel failed' },
        {
          error,
          travelId,
          action: 'start'
        }
      )
    } finally {
      setLoading(undefined)
      refreshData()
    }
  }

  async function pauseTravelInBICS(travelId: number | undefined) {
    if (loading) {
      toast.error(I18n.t('announcements.error.awaitingAction'))
      return
    }

    if (!travelId) {
      toast.error(I18n.t('announcements.error.noTravelId'))
      return
    }

    setLoading({ type: 'BICS', travelId })
    analytics.newEvent('bics_pause_travel', { travelId })

    try {
      await teqplayAPIService.pauseBICSTravel(travelId)
      toast.warn(I18n.t('announcements.travel.paused'))
    } catch (error) {
      console.error(error)
      toast.error(I18n.t('generalError'))
      sendExceptionToSentry(
        undefined,
        { message: '[BICS] Pausing travel failed' },
        {
          error,
          travelId,
          action: 'pause'
        }
      )
    } finally {
      setLoading(undefined)
      refreshData()
    }
  }

  async function stopTravelInBICS(travelId: number | undefined) {
    if (loading) {
      toast.error(I18n.t('announcements.error.awaitingAction'))
      return
    }

    if (!travelId) {
      toast.error(I18n.t('announcements.error.noTravelId'))
      return
    }

    setLoading({ type: 'BICS', travelId })
    analytics.newEvent('bics_stop_travel', { travelId })

    try {
      await teqplayAPIService.stopBICSTravel(travelId)
      toast.warn(I18n.t('announcements.travel.stopped'))
    } catch (error) {
      console.error(error)
      toast.error(I18n.t('generalError'))
      sendExceptionToSentry(
        undefined,
        { message: '[BICS] Stopping travel failed' },
        {
          error,
          travelId,
          action: 'stop'
        }
      )
    } finally {
      setLoading(undefined)
      refreshData()
    }
  }

  function mapCargoToObject(code: string | undefined): BicsCargo | undefined {
    return travel.cargoObjects?.find(co => co.vnNr === code || co.hsCode === code)
  }

  function getDestinationDisplayName(location?: BicsDestination): string | undefined {
    return location?.term || location?.termLocal || location?.name || location?.nameLocal
  }
}

export default BICSVoyageListItem
