import { IBridgePlanningCertainty } from '../services/TeqplayAPIService/TeqplayApi'

export function getBMSIcon(status: IBridgePlanningCertainty) {
  switch (status) {
    case 'CANCELLED':
      return 'icon-cancel-1'
    case 'CURRENT':
      return 'icon-circle'
    case 'APPROVED':
    case 'EXPECTED':
    case 'PLANNED':
      return 'icon-clock-1'
    case 'TERMINATED':
      return 'icon-check'
  }
}
