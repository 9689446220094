import React, { useState } from 'react'
import { I18n } from 'react-redux-i18n'
import { differenceInDays, formatDistanceToNow } from 'date-fns'

import ShipSelector from '../../../components/shipSelector/ShipSelector'
import TeqplayApiService from '../../../services/TeqplayAPIService/TeqplayApiService'

import { IShipInfo } from '../../../services/TeqplayAPIService/TeqplayApi'
import { BASIC_AUTH_TOKEN } from '../../../utils/constants'
import { confirmPopup } from '../../../components/shared/confirmPopup/ConfirmPopup'

interface IProps {
  shipInfo: IShipInfo | null
  setShipInfo: (name: IShipInfo | null) => void
  teqplayApiService: TeqplayApiService

  onPreviousStep: () => void
  onNextStep: () => void
}

const ShipInfoSubForm = (props: IProps) => {
  const { shipInfo, setShipInfo, onNextStep, onPreviousStep } = props
  const [valid, setValid] = useState<{ shipInfo: boolean } | undefined>(undefined)

  const [errors, setErrors] = useState<string[]>()

  return (
    <form id="form-SHIP" onSubmit={e => onSubmit(e)}>
      <div className="inputs">
        <div className="info-box">{I18n.t('register.activeAISTransponderRequired')}</div>

        <div className={`help${valid?.shipInfo === false ? ' invalid' : ''}`}>
          {I18n.t(`register.ship`)}
        </div>

        <ShipSelector
          value={shipInfo}
          onChange={s => setShipInfo(s)}
          teqplayAPIService={props.teqplayApiService}
          manualTokenOverride={BASIC_AUTH_TOKEN}
          isClearable={true}
        />

        {errors?.map(error => (
          <div className="mmsi-error" key={error}>
            {error}
          </div>
        ))}
      </div>
      <div className="step-buttons">
        <button
          id="button-previous"
          className="button previous"
          type="reset"
          onClick={() => onPreviousStep()}
        >
          {I18n.t('register.back')}
        </button>
        <button id="button-next" className="button primary next" type="submit">
          {I18n.t('register.next')}
        </button>
      </div>
    </form>
  )

  function onSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault()
    e.stopPropagation()

    const hasOldAISSignal = shipInfo
      ? differenceInDays(new Date(), shipInfo.timeLastUpdate) > 7
      : false

    if (shipInfo && shipInfo.mmsi) {
      if (hasOldAISSignal) {
        confirmPopup({
          onConfirm: onNextStep,
          title: I18n.t('register.mmsiError.oldAISTitle'),
          message: (
            <div className="confirm-old-ais">
              <p>
                {I18n.t('register.mmsiError.oldAISFirstLine', {
                  time: formatDistanceToNow(shipInfo.timeLastUpdate, {
                    addSuffix: true
                  })
                })}
              </p>
              <p>{I18n.t('register.mmsiError.oldAISSecondLine')}</p>
              <p>{I18n.t('register.mmsiError.oldAISThirdLine')}</p>
              <p>{I18n.t('register.mmsiError.oldAISFourthLine')}</p>
              <p>{I18n.t('register.mmsiError.oldAISFifthLine')}</p>
            </div>
          )
        })
      } else {
        onNextStep()
      }
    } else {
      setValid({
        shipInfo: false
      })

      const newErrors = []

      if (!shipInfo) {
        newErrors.push(I18n.t('register.mmsiError.empty'))
      } else {
        if (!shipInfo.mmsi) {
          newErrors.push(I18n.t('register.mmsiError.noMMSI'))
        }
      }

      setErrors(newErrors)
    }
  }
}

export default ShipInfoSubForm
