import React from 'react'
import { I18n } from 'react-redux-i18n'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'

import SetSpeed from '../../../components/mapShared/setSpeed/SetSpeed'
import NotifcationMonitor from '../../../components/notificationMonitor/NotificationMonitor'
import RouteList from '../../../components/routeList/RouteList'
import DefaultTransition from '../../../components/shared/defaultTransition/DefaultTransition'
import TeqplayApiService from '../../../services/TeqplayAPIService/TeqplayApiService'

import { ISpeedState } from '../../../components/LocationWatcher/LocationWatcherTypes'
import {
  IBridgeMovement,
  IBridgePlanning,
  ILockPlanning,
  IRoute,
  IShipNotificationStatus
} from '../../../services/TeqplayAPIService/TeqplayApi'
import { knotsToKmh } from '../../../utils/general'

interface IProps {
  active: boolean
  teqplayApiService: TeqplayApiService
  navigationRoute: IRoute | null
  shipNotificationStatus: IShipNotificationStatus | null
  VDJSNotificationMessage: string | null
  channelApproachNotificationMessage: string | null
  isRoutePaused: boolean
  bridgePlanning: IBridgePlanning[]
  bridgeMovement: IBridgeMovement[]
  lockPlanning: ILockPlanning[]
  toggleOptions: () => void
  totalNotificationsCount: number
  selectedTime: number | undefined
  speed: ISpeedState
}

const NavigationOptions: React.FunctionComponent<IProps> = props => {
  return (
    <DefaultTransition active={props.active} slideFrom="top" offset={10}>
      {styleProps => (
        <div style={styleProps} className={`navigation-options-wrapper`}>
          <div className="route-item-background" />
          <Tabs className="tabs-wrapper">
            <TabList className="tabs-header">
              <Tab
                className="tab"
                itemID="navigation-options-route-list"
                selectedClassName={`active-tab${props.isRoutePaused ? ' paused' : ''}`}
              >
                <span className="icon-list" />
                <div className="tab-name">{I18n.t('navigationPage.list_view')}</div>
              </Tab>
              <Tab
                className="tab"
                itemID="navigation-options-notifications"
                selectedClassName={`active-tab${props.isRoutePaused ? ' paused' : ''}`}
              >
                <span className="icon-notification-bell" />
                <div className="tab-name">{I18n.t('navigationPage.notifications')}</div>
                {props.totalNotificationsCount > 0 && (
                  <span className="notifications-alert">{props.totalNotificationsCount}</span>
                )}
              </Tab>
              <Tab
                className="tab"
                itemID="navigation-options-speed"
                selectedClassName={`active-tab${props.isRoutePaused ? ' paused' : ''}`}
              >
                <span className="icon-gauge-1" />
                <div className="tab-name">{I18n.t('navigationPage.ship_speed')}</div>
                <span className="notifications-alert">
                  {props.speed.speedType === 'SHIP'
                    ? 'AUTO'
                    : knotsToKmh(props.speed.userInputSpeed)}
                </span>
              </Tab>
            </TabList>

            <div className="tabs-content">
              <TabPanel>
                <RouteList
                  teqplayApiService={props.teqplayApiService}
                  selectedRoute={props.navigationRoute}
                  bridgePlanning={props.bridgePlanning}
                  bridgeMovement={props.bridgeMovement}
                  lockPlanning={props.lockPlanning}
                  selectionTime={props.selectedTime}
                />
              </TabPanel>
              <TabPanel>
                <NotifcationMonitor
                  notifications={
                    props.shipNotificationStatus ? props.shipNotificationStatus.notifications : null
                  }
                  VDJSNotificationMessage={props.VDJSNotificationMessage}
                  channelApproachNotificationMessage={props.channelApproachNotificationMessage}
                />
              </TabPanel>
              <TabPanel>
                <SetSpeed teqplayApiService={props.teqplayApiService} />
              </TabPanel>
            </div>
          </Tabs>
        </div>
      )}
    </DefaultTransition>
  )
}

export default NavigationOptions
