import { useQuery } from 'react-query'

import { BicsHull } from '../services/TeqplayAPIService/TeqplayApi'
import TeqplayApiService from '../services/TeqplayAPIService/TeqplayApiService'

export function useBICSHulls(
  api: TeqplayApiService,
  userId: string
): {
  hulls: BicsHull[] | null
  lastUpdatedAt: number
  // The sole reason there is an `any` here is because I could not get the types to work.
  // The function does return a Promise<void> but its return type is not cooperating
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  refresh: () => Promise<any>
  loading: boolean
  error: {} | null
} {
  const {
    data,
    dataUpdatedAt,
    isFetching: loading,
    error,
    refetch: refresh
  } = useQuery<BicsHull[] | null | undefined, {} | null>(
    ['all-bics-hulls', { userId }],
    () => api.bicsGetHulls(),
    {
      refetchIntervalInBackground: false,
      refetchInterval: 30 * 1000
    }
  )

  return {
    hulls: data || null,
    lastUpdatedAt: dataUpdatedAt,
    refresh,
    loading,
    error
  }
}
