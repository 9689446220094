import React, { useEffect, useState } from 'react'
import { I18n } from 'react-redux-i18n'

import TeqplayApiService from '../../../../services/TeqplayAPIService/TeqplayApiService'
import DefaultTransition from '../../../shared/defaultTransition/DefaultTransition'
import SavedRoute from './savedRoute/SavedRoute'

import { IRoute, IStoredRoute } from '../../../../services/TeqplayAPIService/TeqplayApi'
import { confirmPopup } from '../../../shared/confirmPopup/ConfirmPopup'

import './SavedRoutesList.scss'

interface IProps {
  teqplayApiService: TeqplayApiService
  startRoute: (route: IRoute) => void
}
const SavedRoutesList = ({ teqplayApiService, startRoute }: IProps) => {
  const [savedRoutes, setSavedRoutes] = useState<IStoredRoute[]>([])
  const [showSavedRoutes, setShowSavedRoutes] = useState<boolean>(false)

  useEffect(() => {
    fetchSavedRoutes()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (savedRoutes.length === 0) {
    return null
  }

  return (
    <DefaultTransition active={true} offset={10} slideFrom="top">
      {props => (
        <div className="saved-routes">
          <button
            className="button large secondary saved-routes-button"
            style={props}
            onClick={() => setShowSavedRoutes(!showSavedRoutes)}
            id="button-show-saved-routes"
          >
            <i className={`icon-angle-${showSavedRoutes ? 'up' : 'down'}`} />
            {I18n.t(`routeSelection.${showSavedRoutes ? 'hideSavedRoutes' : 'showSavedRoutes'}`)}
          </button>

          <DefaultTransition active={showSavedRoutes}>
            {style => (
              <ul style={style} className="saved-routes-wrapper">
                {savedRoutes
                  .sort((a, b) => (a.date > b.date ? -1 : 1))
                  .map(storedRoute => (
                    <SavedRoute
                      key={storedRoute.route._id}
                      route={storedRoute.route}
                      date={storedRoute.date}
                      name={storedRoute.key}
                      startRoute={startRoute}
                      deleteRoute={() => deleteStoredRoute(storedRoute.key)}
                    />
                  ))}
              </ul>
            )}
          </DefaultTransition>
        </div>
      )}
    </DefaultTransition>
  )

  async function fetchSavedRoutes() {
    try {
      const allSavedRoutes = await teqplayApiService.getStoredRoutes()
      if (allSavedRoutes) {
        setSavedRoutes(allSavedRoutes)
      }
    } catch (err) {
      console.error(err)
    }
  }

  async function deleteStoredRoute(key: string) {
    confirmPopup({
      onConfirm: async () => {
        await teqplayApiService.deleteStoredRoute(key)
        await fetchSavedRoutes()
      },
      message: I18n.t('routeSelection.confirmDeleteStoredRoute', { key }),
      cancelText: I18n.t('no'),
      confirmText: I18n.t('delete'),
      confirmClassName: 'button danger large'
    })
  }
}

export default SavedRoutesList
