export const safelyStubAndThenCleanup = (target: any, method: any, value: any) => {
  const original = target[method]
  beforeEach(() => {
    Object.defineProperty(target, method, { configurable: true, value })
  })
  afterEach(() => {
    Object.defineProperty(target, method, { configurable: true, value: original })
  })
}

export function parseStringForID(s: string | any) {
  if (s && s?.toLowerCase) {
    return s.replace(/ /g, '-').toLowerCase()
  } else {
    // TO-DO: Maybe improve by sending error to Sentry upon failing to parse?
    console.error(`${JSON.stringify(s)} not parsable as string to use for ID`)
    return 'error-string-not-parsable'
  }
}
