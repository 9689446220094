import React from 'react'
import { I18n } from 'react-redux-i18n'

import { SelectedItemModalEnum } from '../../../@types/types'
import { IBridgePlanning } from '../../../services/TeqplayAPIService/TeqplayApi'

interface IProps {
  selectedItem: SelectedItemModalEnum
  selectedBridgePlanning: IBridgePlanning | undefined
}

const BridgeOpeningBanner = ({ selectedItem, selectedBridgePlanning }: IProps) => {
  if (!selectedItem || (selectedItem.type !== 'ROUTE_ITEM' && selectedItem.type !== 'BRIDGE')) {
    return null
  }

  const canBePassed = selectedItem.item.canBePassed
  const openingRequired =
    selectedItem.type === 'ROUTE_ITEM' ? selectedItem.item.openingRequired : undefined
  const eta = selectedItem.type === 'ROUTE_ITEM' ? selectedItem.item.eta : undefined
  const bridgeType = selectedItem.type === 'ROUTE_ITEM' ? selectedItem.item.bridgeType : undefined

  const isBMSBridge = bridgeType === 'BMS'
  const isPlanned = selectedBridgePlanning?.bridgeRequestId

  if ((!openingRequired || !canBePassed || !eta) && !selectedBridgePlanning) {
    return null
  }

  return (
    <div
      className={`route-item-row opening-required${
        selectedBridgePlanning
          ? isPlanned
            ? selectedBridgePlanning?.bridgeStatus === 'OPEN'
              ? ' opening-opened'
              : ' opening-planned'
            : isBMSBridge
            ? ' opening-bms'
            : ''
          : ''
      }`}
      id="selected-route-item-opening-required"
    >
      <i className="icon-info-1" />
      <div className="info">
        {I18n.t(
          selectedBridgePlanning
            ? isPlanned
              ? selectedBridgePlanning?.bridgeStatus === 'OPEN'
                ? 'routeList.itemDetails.openingOpened'
                : 'routeList.itemDetails.openingPlanned'
              : isBMSBridge
              ? 'routeList.itemDetails.openingRequiredBMS'
              : 'routeList.itemDetails.infoBridgeHasToOpen' // todo: improve when lock
            : 'routeList.itemDetails.infoBridgeHasToOpen' // todo: improve when lock
        )}
      </div>
    </div>
  )
}

export default BridgeOpeningBanner
