import * as React from 'react'
import { I18n } from 'react-redux-i18n'

import { IRouteItem } from '../../../services/TeqplayAPIService/TeqplayApi'
import '../../itemDetails/ItemDetails.scss'

interface IProps {
  routeItem: IRouteItem
}

const WaitingTimeItem = ({ routeItem }: IProps) => {
  if (!routeItem.waitingTime || !routeItem.eta) {
    return null
  }

  const waitingTimeString = determineWaitingTimeString(routeItem.waitingTime)
  const objectType = I18n.t('routeList.objectTypes.' + routeItem.type)

  return (
    <div className="route-list-item waiting-item-item">
      <div className={'item-info'}>
        <div className="info-wrapper waiting">
          <div className="row">
            <span className={'icon-clock orange item-detail-icon'} />
            <span className="item-title">
              {I18n.t('routeList.itemDetails.waitingTime', {
                waitingTime: waitingTimeString,
                objectType
              })}
            </span>
          </div>
        </div>
      </div>
    </div>
  )

  function determineWaitingTimeString(waitingTime: number) {
    let waitingTimeMinutes = Math.round(waitingTime / 60000) // From miliseconds to minutes
    let waitingTimeHours = 0

    if (waitingTimeMinutes > 60) {
      const fullHours = Math.floor(waitingTimeMinutes / 60)
      waitingTimeMinutes -= fullHours * 60
      waitingTimeHours = fullHours
    }

    return `${waitingTimeHours ? waitingTimeHours + 'h, ' : ''}${waitingTimeMinutes}min`
  }
}

export default WaitingTimeItem
