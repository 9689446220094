import L from 'leaflet'
import React from 'react'

import UpdatableControl from './UpdatableControl'

interface IProps {
  children: React.ReactNode
  title?: string
  position?: L.ControlPosition
  onClick: () => void
  tag?: string
}

const CustomControlButton = (props: IProps) => {
  const { position, title, onClick, tag } = props
  const buttonPosition = position || 'bottomright'

  // Leaflet 1.8.0 causes a bug where the touch target is ridiculously small on iOS
  // Do not use this version in conjunction with this component

  return (
    <UpdatableControl
      className="leaflet-control-zoom leaflet-bar"
      position={buttonPosition || 'topright'}
    >
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a
        className={`leaflet-control-custom leaflet-control-${tag}`}
        title={title}
        role="button"
        aria-label={title}
        onClick={onClick}
        id={`leaflet-button-${(props.tag || '').replace(/ /g, '-').toLowerCase()}`}
      >
        {props.children}
      </a>
    </UpdatableControl>
  )
}

export default CustomControlButton
