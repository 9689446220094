import React, { useState } from 'react'
import { I18n } from 'react-redux-i18n'
import { useHistory } from 'react-router'

import InputField from '../../../components/shared/inputField/InputField'
import { isEmail, isValidPhoneNumber } from '../../../utils/general'

interface IProps {
  name: string
  setName: (name: string) => void
  email: string
  setEmail: (email: string) => void
  phoneNumber: string | undefined
  setPhoneNumber: (num: string) => void

  onNextStep: () => void
}

const PersonalInfoSubForm = (props: IProps) => {
  const { name, email, setName, setEmail, phoneNumber, setPhoneNumber, onNextStep } = props
  const [valid, setValid] = useState<
    { name: boolean; email: boolean; phoneNumber: boolean } | undefined
  >(undefined)
  const history = useHistory()

  function processChange(changeHandler: () => void) {
    setValid(undefined)
    changeHandler()
  }

  function onSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault()
    e.stopPropagation()

    if (name.length > 0 && email.length > 0 && isEmail(email) && isValidPhoneNumber(phoneNumber)) {
      onNextStep()
    } else {
      setValid({
        name: name.length > 0,
        email: email.length > 0 && email.includes('@') && email.includes('.') && isEmail(email),
        phoneNumber: isValidPhoneNumber(phoneNumber)
      })
    }
  }

  return (
    <form id="register-form-personalinfo" onSubmit={e => onSubmit(e)}>
      <div className="inputs">
        <div className={`help${valid?.name === false ? ' invalid' : ''}`}>
          {I18n.t(`register.name`)}
        </div>
        <InputField
          name={I18n.t('register.name')}
          value={name}
          onChange={e => processChange(() => setName(e.target.value))}
          placeholder={I18n.t('register.mandatory')}
          autoFocus
          className={`input${valid?.name === false ? ' invalid' : ''}`}
          id="register-name-input"
        />
        <div className={`help${valid?.email === false ? ' invalid' : ''}`}>
          {I18n.t(`register.emailAddress`)}
        </div>
        <InputField
          name={I18n.t('register.emailAddress')}
          value={email}
          onChange={e => processChange(() => setEmail(e.target.value))}
          placeholder={I18n.t('register.mandatory')}
          className={`input${valid?.email === false ? ' invalid' : ''}`}
          id="register-email-input"
          autoCapitalize="off"
        />
        <div className={`help${valid?.phoneNumber === false ? ' invalid' : ''}`}>
          {I18n.t(`register.phoneNumber`)}
        </div>
        <InputField
          name={I18n.t('register.phoneNumber')}
          value={phoneNumber}
          onChange={e => processChange(() => setPhoneNumber(e.target.value))}
          className={`input${valid?.phoneNumber === false ? ' invalid' : ''}`}
          id="register-phone-number-input"
          autoCapitalize="off"
        />
      </div>
      <div className="step-buttons">
        <button
          id="button-previous"
          className="button previous"
          type="reset"
          onClick={() => history.push('/' + history.location.search)}
        >
          {I18n.t('register.cancel')}
        </button>
        <button id="button-next" className="button primary next" type="submit">
          {I18n.t('register.next')}
        </button>
      </div>
    </form>
  )
}

export default PersonalInfoSubForm
