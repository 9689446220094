import { IRouteItem } from '../services/TeqplayAPIService/TeqplayApi'
import { SelectedItemModalEnum } from '../@types/types'

export function getIconNameByType(
  itemType: IRouteItem['type'] | SelectedItemModalEnum['type'],
  item?: IRouteItem
) {
  switch (itemType) {
    case 'BRIDGE':
      return item && item.bridgeType === 'VST'
        ? 'icon-bridge-solid'
        : item?.status === 'OPEN'
        ? 'icon-bridge-open'
        : 'icon-bridge-closed'

    case 'LOCK':
      return 'icon-lock'
    case 'NOTIFICATION':
      return 'icon-notification-bell'
    case 'WATERMETER':
      return 'icon-waves'
    case 'VHFSECTOR':
      return 'icon-vts-sector'
    case 'START':
      return 'icon-dot-circled'
    case 'END':
      return 'icon-flag'
    default:
      return 'icon-location'
  }
}
