import React from 'react'
import { I18n } from 'react-redux-i18n'

import './BICSGenericInputRow.scss'

interface IProps<T> {
  keyName: T
  errors: { key: T; error: string }[]
  isRequired?: boolean
  children: React.ReactNode
  labelOverride?: React.ReactNode
  extraSubtitle?: React.ReactNode
  className?: string
  childrenWrapperClassName?: string
}

const BICSGenericInputRow = <T extends string>({
  keyName,
  errors,
  isRequired,
  children,
  labelOverride,
  className,
  childrenWrapperClassName,
  extraSubtitle
}: IProps<T>) => {
  const currentKeyErrors = errors.filter(e => e.key === keyName)

  return (
    <div
      className={`bics-generic-input-row form-row dimension-row ${className || ''}`}
      id={`row-${keyName}`}
    >
      <div className={`dimension-column ${className || ''}`}>
        <div className="label-wrapper">
          {labelOverride || (
            <label className="label" htmlFor={keyName}>
              {I18n.t(`announcements.fields.${keyName}`)}
            </label>
          )}
          {extraSubtitle ? <div className="subtitle">{extraSubtitle}</div> : null}
          {isRequired ? (
            <div
              className={`mandatory${
                currentKeyErrors.find(e => e.error === I18n.t('announcements.error.requiredEmpty'))
                  ? ' highlight'
                  : ''
              }`}
            >
              {I18n.t('required')}
            </div>
          ) : null}
        </div>
        <div
          className={`children-wrapper${
            childrenWrapperClassName ? ` ${childrenWrapperClassName}` : ''
          }`}
        >
          {children}
        </div>
      </div>
      {currentKeyErrors.map((e, i) => (
        <span className="error-message" key={`${e.key}-${i}`}>
          {e.error}
        </span>
      ))}
    </div>
  )
}

export default BICSGenericInputRow
