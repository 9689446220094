import { IShipInfo } from '../services/TeqplayAPIService/TeqplayApi'

export function getShipWidth(ship: IShipInfo) {
  if (ship.positionOfTransponder) {
    return (
      ship.positionOfTransponder.distanceToStarboard + ship.positionOfTransponder.distanceToPort
    )
  } else if (ship.dimensions && ship.dimensions.width) {
    return ship.dimensions.width
  } else {
    return -1
  }
}

export function getShipLength(ship: IShipInfo) {
  if (ship.positionOfTransponder) {
    return ship.positionOfTransponder.distanceToBow + ship.positionOfTransponder.distanceToStern
  } else if (ship.dimensions && ship.dimensions.length) {
    return ship.dimensions.length
  } else {
    return -1
  }
}
