import * as React from 'react'
import Modal from 'react-modal'
import { I18n } from 'react-redux-i18n'
import { toast } from 'react-toastify'

import TeqplayApiService from '../../../services/TeqplayAPIService/TeqplayApiService'
// import Checkboxes from './checkboxes/Checkboxes'
import RouteSelectField from './routeSelectField/RouteSelectField'
import SavedRoutesList from './savedRoutesList/SavedRoutesList'
import SpeedOptions from './speedOptions/SpeedOptions'
import TimeOptions from './timeOptions/TimeOptions'
import ViaRouteSelection from './viaRouteSelection/ViaRouteSelection'

import { IRouteSearchProps, RouteSelectionFields } from '../../../@types/types'
import {
  IRoute,
  IRouteLocation,
  IShipDimensions,
  IShipInfo
} from '../../../services/TeqplayAPIService/TeqplayApi'
import './RouteSelectForm.scss'

interface IProps {
  routeSelection: IRouteSearchProps
  teqplayApiService: TeqplayApiService
  currentLocation: IShipInfo | null
  searchAvailableRoutes: () => void
  setRouteSelectionInactive: () => void
  setRouteSelectionFormFieldActive: (
    fieldName: RouteSelectionFields,
    viaRouteIndex?: number
  ) => void
  setViaRoutes: (viaRoutes: IRouteLocation[]) => void
  setFromLocation: (fromLocation: IRouteLocation | null) => void
  setDepartureTime: (arrivalDate: Date) => void
  departureTime: number | null
  startRoute: (route: IRoute) => void
  reverseRoute: () => void
  cruiseSpeed: number | undefined
  setCruiseSpeed: (s: number) => void
  showExtraOptions: boolean
  setShowExtraOptions: (s: boolean) => void
  dimensions: IShipDimensions | null
  setShowDimensionsDialog: () => void
}

const RouteSelectForm = (props: IProps) => {
  const [showHelp, toggleShowHelp] = React.useState(false)

  const searchRoutes = () => {
    props.searchAvailableRoutes()
  }

  const showRouteSelectWarning = () => {
    toast.warn(I18n.t('routeSelection.selectionWarning'))
  }

  const toggleExtraOptions = () => {
    props.setShowExtraOptions(!props.showExtraOptions)
  }

  const fromLocationValue =
    props.routeSelection.fromLocation && props.routeSelection.fromLocation.displayName !== null
      ? props.routeSelection.fromLocation.displayName
      : ''

  const toLocationValue =
    props.routeSelection.toLocation && props.routeSelection.toLocation.displayName !== null
      ? props.routeSelection.toLocation.displayName
      : ''

  return (
    <React.Fragment>
      <div className="route-select-wrapper main-menu">
        <div className="select-multiline-inner-wrapper">
          <div className="route-select-menu-buttons">
            <i
              className="icon-left-big close-button"
              onClick={props.setRouteSelectionInactive}
              id="button-close-route-select-wrapper-menu"
            />
          </div>

          <form action="" className="route-selection-form">
            <RouteSelectField
              key="source-select-field"
              displayName={I18n.t('routeSelection.startingPoint')}
              fieldName={'FROM_LOCATION'}
              value={fromLocationValue}
              setrouteSelectionFormFieldActive={props.setRouteSelectionFormFieldActive}
              placeholder={I18n.t('routeSelection.selectStartingPoint')}
            />

            <ViaRouteSelection
              routeSelection={props.routeSelection}
              setRouteSelectionFormFieldActive={props.setRouteSelectionFormFieldActive}
              setViaRoutes={props.setViaRoutes}
              reverseRoute={props.reverseRoute}
            />

            <RouteSelectField
              key="destination-select-field"
              displayName={I18n.t('routeSelection.destinationPoint')}
              fieldName={'TO_LOCATION'}
              value={toLocationValue}
              setrouteSelectionFormFieldActive={props.setRouteSelectionFormFieldActive}
              placeholder={I18n.t('routeSelection.selectDestination')}
            />
          </form>

          <TimeOptions
            showTimeOptions={props.showExtraOptions}
            setDepartureTime={props.setDepartureTime}
            departureTime={props.departureTime}
          />

          <SpeedOptions
            showSpeedOptions={props.showExtraOptions}
            cruiseSpeed={props.cruiseSpeed}
            setCruiseSpeed={props.setCruiseSpeed}
          />

          <div className="button-wrapper">
            <button className="button help-button" onClick={() => toggleShowHelp(true)}>
              <i className="icon-help" />
            </button>
            <button
              type="button"
              className="button large options-toggle"
              onClick={toggleExtraOptions}
              id="button-toggle-options"
            >
              <i className={`icon-${props.showExtraOptions ? 'up' : 'down'}-open`} />
              {I18n.t('routeSelection.options')}
            </button>
            {props.routeSelection.toLocation && props.routeSelection.fromLocation ? (
              <button
                type="button"
                className="button primary route-select-button large"
                onClick={searchRoutes}
                id="button-calculate-route"
              >
                {I18n.t('routeSelection.calculateRoute')}
              </button>
            ) : (
              <button
                type="button"
                className="button disabled-button route-select-button large"
                onClick={showRouteSelectWarning}
                id="button-calculate-route"
              >
                {I18n.t('routeSelection.calculateRoute')}
              </button>
            )}
          </div>
        </div>

        <SavedRoutesList
          teqplayApiService={props.teqplayApiService}
          startRoute={props.startRoute}
        />
      </div>

      <Modal
        isOpen={showHelp}
        onRequestClose={onCloseModal}
        className="modal help-route"
        style={{ overlay: { backgroundColor: 'rgba(0,0,0,0.75)' } }}
      >
        <div className="help-info">
          <h4>{I18n.t('tutorial.routePlanning.title')}</h4>
          <p>{I18n.t('tutorial.routePlanning.intro')}</p>
          <p>{I18n.t('tutorial.routePlanning.intro2')}</p>
          <p>{I18n.t('tutorial.routePlanning.feedback')}</p>
          <p>{I18n.t('tutorial.routePlanning.yourDimensions')}</p>
          <p>
            {I18n.t(
              'tutorial.routePlanning.dimensions',
              props.dimensions || { width: '?', height: '?', draught: '?', length: '?' }
            )}
          </p>
          <div className="buttons">
            <button
              className="button large primary"
              onClick={() => {
                toggleShowHelp(false)
                props.setShowDimensionsDialog()
              }}
            >
              {I18n.t('tutorial.routePlanning.changeDimensions')}
            </button>
            <button className="button large" onClick={onCloseModal}>
              {I18n.t('close')}
            </button>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  )

  function onCloseModal() {
    toggleShowHelp(false)
  }
}

export default RouteSelectForm
