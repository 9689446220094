import * as React from 'react'

import TeqplayApiService from '../../services/TeqplayAPIService/TeqplayApiService'
import RouteListItem from './listItems/RouteListItem'
import WaitingTimeItem from './listItems/WaitingTimeItem'

import { useAnalytics } from '../../services/AnalyticsWrapper/AnalyticsWrapper'
import {
  IBridgeMovement,
  IBridgePlanning,
  ILockPlanning,
  IRoute
} from '../../services/TeqplayAPIService/TeqplayApi'
import './RouteList.scss'

interface IProps {
  selectedRoute: IRoute | null
  teqplayApiService: TeqplayApiService
  bridgePlanning?: IBridgePlanning[]
  bridgeMovement?: IBridgeMovement[]
  lockPlanning?: ILockPlanning[]
  selectionTime?: number
}

const MIN_HIGH_WAITING_TIME = 300000
const MIN_HIGH_WAITING_TIME_LOCK = 2500000

const RouteList = (props: IProps) => {
  const analytics = useAnalytics('RouteList')
  const departureTime =
    props.selectionTime || props.selectedRoute?.startTime || new Date().valueOf()

  React.useEffect(() => {
    analytics.newEvent('show_route_list', {})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (props.selectedRoute) {
    const routeItems = props.selectedRoute.routeItems

    return (
      <React.Fragment>
        <div className="route-line" />
        <div className="route-plan-itemwrapper" id="route-list">
          {routeItems.map((routeItem, index) => {
            if (routeItem.type === 'TRAFFIC_SIGN' || routeItem.type === 'BOTTLENECK') {
              return null
            }
            const maxWaitingTime =
              routeItem.type === 'LOCK' ? MIN_HIGH_WAITING_TIME_LOCK : MIN_HIGH_WAITING_TIME
            const bridgePlanning =
              routeItem && routeItem.type === 'BRIDGE' && props.bridgePlanning
                ? props.bridgePlanning.find(x => x.isrs === routeItem.refUuid)
                : undefined

            const lockPlanning =
              routeItem && routeItem.type === 'LOCK' && props.lockPlanning
                ? props.lockPlanning.find(x => x.isrs === routeItem.refUuid)
                : undefined

            const bridgeMovement =
              routeItem.type === 'BRIDGE' && props.bridgeMovement
                ? props.bridgeMovement.find(x => x.isrsId === routeItem.refUuid)
                : undefined

            return (
              <React.Fragment key={'routeListItem' + index}>
                {routeItem.waitingTime && routeItem.waitingTime > maxWaitingTime ? (
                  <WaitingTimeItem routeItem={routeItem} />
                ) : null}
                <RouteListItem
                  key={'routeListItem' + routeItem._id}
                  routeItem={routeItem}
                  bridgePlanning={bridgePlanning}
                  bridgeMovement={bridgeMovement}
                  lockPlanning={lockPlanning}
                  getTimeToReach={getTimeToReach}
                  teqplayApiService={props.teqplayApiService}
                  departureTime={departureTime}
                />
              </React.Fragment>
            )
          })}
        </div>
      </React.Fragment>
    )
  } else {
    return null
  }

  function getTimeToReach(eta: number): string | null {
    const now = new Date().valueOf()
    let calculateFrom = now

    if (departureTime > now) {
      calculateFrom = departureTime
    }

    let time = ''
    let minutes = Math.round((eta - calculateFrom) / 60000)
    let hours = 0

    if (minutes > 60) {
      hours = Math.floor(minutes / 60)
      minutes -= hours * 60
      time += hours + 'h'
      if (minutes > 0) time += ' '
    }

    if (minutes > 0) {
      time += minutes + 'min'
    }

    return time ? '(' + time + ')' : null
  }
}

export default RouteList
