import React from 'react'
import { I18n } from 'react-redux-i18n'

import coneImage from '../../../../../images/kegel.png'
import { IBerth, IShipInfo } from '../../../../../services/TeqplayAPIService/TeqplayApi'
import { labelAndValue } from '../../../../../utils/layers'
import CollapsableLegendComponent from '../../../controls/CollapsableLegendComponent'

import './ModalBerthInfo.scss'

interface IProps {
  activeBerth: IBerth | undefined
  clearSelectedBerth: () => void
  vessels: IShipInfo[]
  hideLegendCollapsable?: boolean
  legendPosition?: L.ControlPosition
}

const ModalBerthInfo = ({
  activeBerth,
  clearSelectedBerth,
  vessels,
  hideLegendCollapsable,
  legendPosition
}: IProps) => {
  //  Set the amount of cones of the berth to use next to the name
  const cones = []

  if (!activeBerth) {
    return null
  }

  if (activeBerth) {
    for (let i = 0; i < activeBerth.dangerousGoodsLevel; i++) {
      cones.push(
        <img className="berth-cone" key={'berthCone' + i} alt="berth cone icon" src={coneImage} />
      )
    }
  }

  const shipsWithoutLength =
    activeBerth && vessels.length > 0
      ? vessels.filter(ship => {
          return ship.calculatedHeading !== null && ship.calculatedHeading !== undefined
        }).length
      : 0

  return (
    <CollapsableLegendComponent
      className="berth-legend"
      icon={activeBerth.berthName}
      hideCollapsible={hideLegendCollapsable}
      position={legendPosition || 'topright'}
      id={activeBerth.ownerId}
    >
      <div className="simple-notification berth">
        <div
          className="icon-close_privacy close-modal"
          onClick={() => {
            clearSelectedBerth()
          }}
        />
        {activeBerth ? (
          <div className="berth-info">
            <h3 className="berthName">
              {activeBerth.berthName || I18n.t('mapLayers.berthLayer.berth')}
              <div className="cones-wrapper">{cones}</div>
            </h3>
            <p className="subtitle">
              {I18n.t(
                `mapLayers.berthLayer.${!activeBerth.terminalId ? 'publicBerth' : 'terminalBerth'}`
              )}
            </p>

            {labelAndValue(I18n.t('mapLayers.berthLayer.terminalName'), activeBerth.terminalName)}
            {labelAndValue(I18n.t('mapLayers.berthLayer.harbourName'), activeBerth.harbourName)}
            {labelAndValue(I18n.t('mapLayers.berthLayer.owner'), activeBerth.owner)}
            {labelAndValue(I18n.t('mapLayers.berthLayer.berthName'), activeBerth.berthName)}

            <br />

            {(activeBerth.length > 0 || activeBerth.width > 0 || activeBerth.draught > 0) && (
              <>
                {labelAndValue(
                  I18n.t('mapLayers.berthLayer.length'),
                  activeBerth.length || activeBerth.berthDimensions?.length,
                  I18n.t('mapLayers.berthLayer.meters')
                )}
                {labelAndValue(
                  I18n.t('mapLayers.berthLayer.width'),
                  activeBerth.width || activeBerth.berthDimensions?.width,
                  I18n.t('mapLayers.berthLayer.meters')
                )}
                {labelAndValue(
                  I18n.t('mapLayers.berthLayer.draught'),
                  activeBerth.draught,
                  I18n.t('mapLayers.berthLayer.meters')
                )}
              </>
            )}

            <br />

            {activeBerth.occupiedPercentage >= 0 && (
              <>
                {labelAndValue(
                  I18n.t('mapLayers.berthLayer.occupationPercentage'),
                  activeBerth.occupiedPercentage === 'Infinity' ||
                    activeBerth.occupiedPercentage > 100
                    ? '> 100'
                    : activeBerth.occupiedPercentage,
                  '%'
                )}
                {activeBerth.updatedByBlauweGolf && (
                  <div>{I18n.t('mapLayers.berthLayer.occupationDisclaimer')}</div>
                )}
                <br />
              </>
            )}
            {labelAndValue(I18n.t('mapLayers.berthLayer.type'), activeBerth.type)}
            {labelAndValue(
              I18n.t('mapLayers.berthLayer.shipTypes.shipTypes'),
              (activeBerth.shipTypes || []).join(', ')
            )}
            {labelAndValue(
              I18n.t('mapLayers.berthLayer.dangerousGoodsLevel'),
              activeBerth.dangerousGoodsLevel
            )}
            {labelAndValue(
              I18n.t('mapLayers.berthLayer.mooringOptions'),
              activeBerth.mooringOptions
            )}
            {labelAndValue(
              I18n.t('mapLayers.berthLayer.mooringServices'),
              activeBerth.mooringServices
            )}
            {labelAndValue(
              I18n.t('mapLayers.berthLayer.mooringFunctions'),
              activeBerth.mooringFunctions
            )}
            {labelAndValue(
              I18n.t('mapLayers.berthLayer.mooringHourTimeLimit'),
              activeBerth.mooringHourTimeLimit
            )}
            {labelAndValue(
              I18n.t('mapLayers.berthLayer.shoreConnection'),
              activeBerth.shoreConnection
            )}
            {labelAndValue(I18n.t('mapLayers.berthLayer.sort'), activeBerth.sort)}
            {activeBerth.fotoURL && (
              <button
                className="button-fotoURL"
                onClick={() => window.open(activeBerth.fotoURL, '_system')}
              >
                {I18n.t('mapLayers.berthLayer.viewFotoURL')}
              </button>
            )}

            {activeBerth && vessels.length > 0 ? (
              <div className="ship-info">
                <hr />
                <div className="title">{I18n.t('mapLayers.berthLayer.shipsInBerth')}</div>
                {vessels.map((ship, index) => {
                  return (
                    <div className="ship-row" key={index}>
                      <div className="index">
                        {I18n.t('mapLayers.berthLayer.ship') + ' ' + (index + 1)}
                      </div>
                      <div
                        className={
                          !(
                            ship.positionOfTransponder &&
                            ship.positionOfTransponder.distanceToBow +
                              ship.positionOfTransponder.distanceToStern >
                              0
                          )
                            ? 'value icon-attention'
                            : ship.calculatedHeading != null
                            ? 'value icon-info'
                            : 'value'
                        }
                      >
                        {ship.positionOfTransponder &&
                        ship.positionOfTransponder.distanceToBow +
                          ship.positionOfTransponder.distanceToStern >
                          0
                          ? `${
                              ship.positionOfTransponder.distanceToBow +
                              ship.positionOfTransponder.distanceToStern
                            }m x ${
                              ship.positionOfTransponder.distanceToStarboard +
                              ship.positionOfTransponder.distanceToPort
                            }m`
                          : I18n.t('mapLayers.berthLayer.unknownLength')}
                      </div>
                    </div>
                  )
                })}
                {shipsWithoutLength > 0 && (
                  <div className="value icon-info">
                    {I18n.t('mapLayers.berthLayer.interpretationWarning')}
                  </div>
                )}
              </div>
            ) : null}
          </div>
        ) : null}
      </div>
    </CollapsableLegendComponent>
  )
}

export default ModalBerthInfo
