import React from 'react'
import ReactCountryFlag from 'react-country-flag'
import { I18n } from 'react-redux-i18n'

import { BicsHull } from '../../../services/TeqplayAPIService/TeqplayApi'
import { isMainHull } from '../../../utils/bics'

import './BICSHullEntry.scss'

interface IProps {
  mainHull?: BicsHull | undefined
  hull: BicsHull
  disabled?: boolean
  showCargoTags?: boolean
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  onDelete?: (mmsiOrEni: string) => void
  className?: string
}

const BICSHullEntry = ({
  mainHull,
  hull,
  onDelete,
  disabled,
  onClick,
  showCargoTags,
  className
}: IProps) => {
  return (
    <div
      className={`hull-entry${onClick ? ' with-click' : ''}${className ? ` ${className}` : ''}`}
      key={hull.eni}
      onClick={onClick}
    >
      <div className="information">
        <div className="row identifier">
          <ReactCountryFlag countryCode={hull?.nationality} className="flag" svg />
          <span className="identifier">
            {hull.name || hull.eni || hull.mmsi || I18n.t('unknownValue')}
          </span>

          {showCargoTags && hull.cargoCapacityTn && (
            <div className="tag">
              <div className="key">{I18n.t('announcements.fields.cargoCapacityTn')}</div>
              <div className="value">
                {hull.cargoCapacityTn.toString().split('.').join(',')}{' '}
                {I18n.t('announcements.fields.tonnage')}
              </div>
            </div>
          )}
        </div>
        {showCargoTags ? null : (
          <div className="row tags">
            {isMainHull(mainHull, hull) && (
              <div className="tag main">
                <div className="key">{I18n.t('announcements.hullSelect.mainHull')}</div>
              </div>
            )}
            {hull.name && (
              <div className="tag">
                <div className="key">{I18n.t('announcements.fields.eniShort')}</div>
                <div className="value">{hull.eni}</div>
              </div>
            )}
            {hull.mmsi ? (
              <div className="tag">
                <div className="key">{I18n.t('announcements.fields.mmsi')}</div>
                <div className="value">{hull.mmsi}</div>
              </div>
            ) : null}
          </div>
        )}
        {showCargoTags ? null : (
          <div className="row tags">
            {hull.lengthM && (
              <div className="tag">
                <div className="key">{I18n.t('announcements.fields.lengthM')}</div>
                <div className="value">{hull.lengthM.toString().split('.').join(',')}m</div>
              </div>
            )}
            {hull.widthM && (
              <div className="tag">
                <div className="key">{I18n.t('announcements.fields.widthM')}</div>
                <div className="value">{hull.widthM.toString().split('.').join(',')}m</div>
              </div>
            )}
            {hull.heightM && (
              <div className="tag">
                <div className="key">{I18n.t('announcements.fields.heightM')}</div>
                <div className="value">{hull.heightM.toString().split('.').join(',')}m</div>
              </div>
            )}
            {hull.maxDraughtM && (
              <div className="tag">
                <div className="key">{I18n.t('announcements.fields.maxDraughtM')}</div>
                <div className="value">{hull.maxDraughtM.toString().split('.').join(',')}m</div>
              </div>
            )}
          </div>
        )}
      </div>
      {onDelete && (
        <div className="side-buttons">
          <button
            className="button primary"
            onClick={e => {
              e.preventDefault()
              e.stopPropagation()
              onDelete(hull.mmsi || hull.eni)
            }}
            disabled={disabled}
          >
            <i className="icon-trash" />
          </button>
        </div>
      )}
    </div>
  )
}

export default BICSHullEntry
