import * as React from 'react'
import { I18n } from 'react-redux-i18n'
import { IRouteInformation } from '../../@types/types'

interface IProps {
  itemDetails: IRouteInformation | null
  isExpanded: boolean
}

const CrossingsDetails = ({ itemDetails, isExpanded }: IProps) => {
  if (!itemDetails || isExpanded === false) {
    return null
  }

  return (
    <div className="item-info-details" id={`crossing-details-${itemDetails.properties.FID_}`}>
      <div className="notification-inner">
        <section className="details-section">
          <div className="route-item-caption" id="crossing-details-title">
            <b>{itemDetails.properties.FID_}</b>
          </div>

          <div className="route-item-caption" id="crossing-details-description">
            <b>
              <span>{itemDetails.properties.Uitlegs}</span>
            </b>
          </div>

          <div className="buttons" id="crossing-details-website">
            {itemDetails.properties.Website && (
              <button
                className="button btn-more-information"
                onClick={() => {
                  if (window.cordova && window.cordova.InAppBrowser) {
                    window.cordova.InAppBrowser.open(itemDetails.properties.Website, '_system')
                  } else {
                    window.open(itemDetails.properties.Website, '_system')
                  }
                }}
              >
                {I18n.t('map.crossings.moreInformation')}
              </button>
            )}
            {itemDetails.properties.PDF && (
              <button
                className="button btn-more-information"
                id="crossing-details-pdf"
                onClick={() => {
                  if (window.cordova && window.cordova.InAppBrowser) {
                    window.cordova.InAppBrowser.open(itemDetails.properties.PDF, '_system')
                  } else {
                    window.open(itemDetails.properties.PDF, '_system')
                  }
                }}
              >
                {I18n.t('map.crossings.viewMap')}
              </button>
            )}
          </div>
        </section>
      </div>
    </div>
  )
}

export default CrossingsDetails
