import { RWSLegend } from '../../../../../../@types/types'

export const RWS_CURRENTS_ENDPOINT = 'https://rwsos.rws.nl/matroos-open/wms'

export const RWS_CURRENTS_LAYER = '-zeedelta_v1--stroom-'

export const RWS_CURRENTS_PARAMS = {
  url: RWS_CURRENTS_ENDPOINT,
  layers: RWS_CURRENTS_LAYER,
  format: 'image/png',
  service: 'WMS',
  request: 'GetMap',
  crs: 'EPSG:3857',
  version: '1.3'
}

export const RWS_CURRENTS_FALLBACK_LEGEND: RWSLegend[] = [
  {
    lowerValue: 0,
    color: '#356556'
  },
  {
    lowerValue: 0.1,
    color: '#276727'
  },
  {
    lowerValue: 0.2,
    color: '#328d32'
  },
  {
    lowerValue: 0.3,
    color: '#8e8432'
  },
  {
    lowerValue: 0.4,
    color: '#8e7132'
  },
  {
    lowerValue: 0.5,
    color: '#824d3d'
  },
  {
    lowerValue: 0.6,
    color: '#733244'
  },
  {
    lowerValue: 0.7,
    color: '#8e3268'
  },
  {
    lowerValue: 0.8,
    color: '#694483'
  },
  {
    lowerValue: 1,
    color: '#514683'
  },
  {
    lowerValue: 1.2,
    color: '#416283'
  },
  {
    lowerValue: 1.5,
    color: '#497a83'
  },
  {
    lowerValue: 2,
    color: '#86b3bc'
  },
  {
    lowerValue: 3,
    color: '#9f9f9f'
  }
]
