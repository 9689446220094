import * as React from 'react'
import { divIcon as DIVIcon, LatLngBounds } from 'leaflet'
import TeqplayApiService from '../../../../../services/TeqplayAPIService/TeqplayApiService'
import DefaultMarkersLayer from '../defaultMarkerLayer/DefaultMarkerLayer'
import { IWaterHeight } from '../../../../../services/TeqplayAPIService/TeqplayApi'
import './WaterHeightLayer.scss'

interface IProps {
  name: string
  checked: boolean
  bounds?: LatLngBounds
  teqplayApiService: TeqplayApiService
  mapZoomLevel: number
  minimumZoomLevel: number
  setSelectedItem: (item: IWaterHeight) => void
  disableInteraction?: boolean
}

const WaterHeightLayer: React.FunctionComponent<IProps> = props => {
  if (!props.checked || props.mapZoomLevel < props.minimumZoomLevel) {
    return null
  }

  return (
    <React.Fragment>
      <DefaultMarkersLayer<IWaterHeight>
        fetchCall={(bounds?: LatLngBounds) => props.teqplayApiService.getWaterHeight(bounds)}
        determineDivIcon={waterHeight =>
          DIVIcon({
            className: 'div-icon-marker',
            html: `<div class="div-icon water-height-icon">
              <span class="popup">${waterHeight.height} ${waterHeight.unit}</span>
              <div class="icon-depth"/>
            </div>`,
            iconAnchor: [36, 10],
            iconSize: [72, 20]
          })
        }
        onClick={props.disableInteraction ? undefined : props.setSelectedItem}
        {...props}
      />
    </React.Fragment>
  )
}

export default WaterHeightLayer
