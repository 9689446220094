import { differenceInCalendarDays, format, differenceInMinutes } from 'date-fns'
import React from 'react'
import Popup from 'react-popup'
import { I18n } from 'react-redux-i18n'
import { useSelector } from 'react-redux'

import DefaultTransition from '../../shared/defaultTransition/DefaultTransition'
import TitleComponent from '../../titleComponent/TitleComponent'

import { useAnalytics } from '../../../services/AnalyticsWrapper/AnalyticsWrapper'
import { ISelectedRoute } from '../../../services/TeqplayAPIService/TeqplayApi'
import { TIME_FORMAT } from '../../../utils/constants'
import { formatIntoHoursAndMinutesFromNow } from '../../../utils/dates'
import { getDistanceLeftOnRoute, formatLengthDistance } from '../../../utils/routeUtils'
import { IRootProps } from '../../../@types/types'

import './ActiveRouteControls.scss'

interface IProps {
  navigationRoute: ISelectedRoute | null
  isRoutePaused: boolean
  pauseRoute?: () => void
  resumeRoute?: () => void
  stopRoute?: () => void
  openedRouteControls: boolean
  toggleRouteControls: (b: boolean) => void
}

const ActiveRouteControls = (props: IProps) => {
  const {
    navigationRoute,
    isRoutePaused,
    resumeRoute,
    pauseRoute,
    stopRoute,
    openedRouteControls,
    toggleRouteControls
  } = props
  const analytics = useAnalytics('ActiveRouteControls')
  const currentLocation = useSelector((p: IRootProps) => p.userLocation.currentLocation)
  const locationTooOld = differenceInMinutes(currentLocation?.timeLastUpdate, new Date()) > 3

  const routeDistance = navigationRoute ? getDistanceLeftOnRoute(navigationRoute) : 0
  const nextDays = navigationRoute
    ? differenceInCalendarDays(navigationRoute.route.eta, new Date())
    : 0

  if (!navigationRoute || !resumeRoute || !pauseRoute || !stopRoute) {
    return null
  }

  return (
    <React.Fragment>
      <div className="active-route-controls" id="active-route-controls">
        {isRoutePaused && (
          <div
            className="route-stats paused"
            id="route-stats-paused-button-resume-route"
            onClick={() => resumeRoute()}
          >
            <TitleComponent title={I18n.t('title.routePaused')} />
            <div className="row tiny">
              <b>{I18n.t('navigationPage.routePausedETAInfo')}</b>
            </div>
          </div>
        )}
        {!isRoutePaused && (
          <div className={`route-stats${isRoutePaused ? ' paused' : ''}`} id="route-stats">
            <TitleComponent
              title={I18n.t(locationTooOld ? 'title.navigation' : 'title.navigationPoint', {
                distance: formatLengthDistance(routeDistance),
                time: formatIntoHoursAndMinutesFromNow(navigationRoute.route.eta)
              })}
            />
            <div className="row tiny">{I18n.t('navigationPage.arrival')}</div>
            <div className="row" id="route-stats-information">
              <div className="relativeETA">
                {formatIntoHoursAndMinutesFromNow(navigationRoute.route.eta)}
              </div>
              <div className="eta">
                ({format(navigationRoute.route.eta, TIME_FORMAT)}
                {nextDays && nextDays > 0 ? <sup className="extra-days">+{nextDays}</sup> : ''})
              </div>
              <div className="dot">·</div>
              <div className="distance">
                {routeDistance > 0 ? Math.round(routeDistance / 1000) : '?'} km
              </div>
            </div>
          </div>
        )}

        <button
          className="button round route-control-button"
          id="route-stats-button-toggle-controls"
          onClick={toggleRouteControlWindow}
        >
          <i className={isRoutePaused ? 'icon-play' : 'icon-pause'} />
        </button>
      </div>

      <DefaultTransition offset={10} slideFrom="bottom" active={openedRouteControls}>
        {styleProps => (
          <div className="route-controls-popout" style={styleProps} id="route-controls-popout">
            <button
              className={`button ${isRoutePaused ? 'resume' : 'pause'}`}
              onClick={() =>
                isRoutePaused ? closeMenuThenExecute(resumeRoute) : closeMenuThenExecute(pauseRoute)
              }
              id="route-controls-popout-button-toggle"
            >
              {I18n.t(isRoutePaused ? 'navigationPage.resume_route' : 'navigationPage.pause_route')}
            </button>
            <button
              className={`button stop`}
              onClick={() => closeMenuThenExecute(handleRouteStopping)}
              id="route-controls-popout-button-stop"
            >
              {I18n.t('navigationPage.stop_route')}
            </button>
          </div>
        )}
      </DefaultTransition>
    </React.Fragment>
  )

  function toggleRouteControlWindow() {
    analytics.newEvent(
      !openedRouteControls ? 'open_route_stoppause_controls' : 'close_route_stoppause_controls',
      { isRoutePaused }
    )
    toggleRouteControls(!openedRouteControls)
  }

  function closeMenuThenExecute(passthrough: () => void) {
    toggleRouteControls(false)
    passthrough()
  }

  function handleRouteStopping() {
    if (stopRoute) {
      Popup.create({
        title: I18n.t('navigationPage.stop_route_confirmation'),
        className: 'route-stopping',
        buttons: {
          left: [
            {
              text: I18n.t('navigationPage.stop_route'),
              className: 'button large danger stop',
              action: () => {
                stopRoute()
                Popup.close()
              }
            }
          ],
          right: [
            {
              text: I18n.t('no'),
              className: 'button large close',
              action: () => Popup.close()
            }
          ]
        }
      })
    }
  }
}

export default ActiveRouteControls
