export function supportsThemePreference() {
  if (window.cordova?.platformId === 'android') {
    // Android Webview which cordova uses does NOT support dynamic themes
    // https://stackoverflow.com/questions/57449900/letting-webview-on-android-work-with-prefers-color-scheme-dark
    return false
  } else {
    return window.matchMedia('(prefers-color-scheme)').media !== 'not all' ? true : false
  }
}

export function determineDynamicTheme(): 'light' | 'dark' {
  return window.matchMedia('(prefers-color-scheme)').media !== 'not all'
    ? window.matchMedia('(prefers-color-scheme: dark)').matches
      ? 'dark'
      : 'light'
    : 'light'
}

export function determineApplicationTheme(
  listenToDeviceTheme: boolean,
  themeOverride: 'light' | 'dark'
) {
  if (listenToDeviceTheme && supportsThemePreference()) {
    return determineDynamicTheme()
  } else return themeOverride
}
