import { LatLngBounds } from 'leaflet'
import cloneDeep from 'lodash/cloneDeep'
import isEqual from 'lodash/isEqual'
import * as React from 'react'
import { Marker, Polygon } from 'react-leaflet'
import { ISingleLocation } from '../../../../../services/TeqplayAPIService/TeqplayApi'

interface IProps<T> {
  name: string
  checked: boolean
  useBounds?: boolean // default is true
  bounds?: LatLngBounds
  getPopup?: (item: T) => any
  fetchCall: (bounds?: LatLngBounds) => Promise<T[]>
  color?: string
}

interface ICustomPolygon {
  // in some cases the polygon is called polygon, in others it's defined as area.
  polygon?: {
    coordinates: [[[number, number]]]
  }
  area?: {
    coordinates: [[[number, number]]]
  }
  polygons?: {
    coordinates: [number, number]
  }[][]
  singleLocation: ISingleLocation
  _id: any
}

interface IState<T> {
  data: T[]
}

export default class DefaultPolygonLayer<T extends ICustomPolygon> extends React.PureComponent<
  IProps<T>,
  IState<T>
> {
  public readonly state: Readonly<IState<T>> = {
    data: []
  }

  public componentDidMount() {
    this.fetchData()
  }

  public componentDidUpdate(prevProps: IProps<T>) {
    if (
      !isEqual(prevProps.bounds, this.props.bounds) ||
      (!prevProps.checked && this.props.checked)
    ) {
      this.fetchData()
    }
  }

  public render() {
    const { checked, getPopup, color } = this.props

    if (!checked) {
      return null
    }

    return (
      <React.Fragment>
        {this.state.data.map((item, index) => {
          const areaCoords = item.polygon
            ? item.polygon.coordinates[0]
            : item.area
            ? item.area.coordinates[0]
            : item.polygons
            ? item.polygons[0].map(x => x.coordinates)
            : null

          if (areaCoords) {
            const coordinates = areaCoords.map(coordinate => {
              const coord = cloneDeep(coordinate)
              const reversedCoords = coord.reverse() as [number, number]
              return reversedCoords
            })
            return (
              <Polygon
                positions={coordinates}
                key={'polygonLayer' + index}
                weight={3}
                color={color}
              >
                {getPopup ? getPopup(item) : null}
              </Polygon>
            )
          } else {
            return (
              <Marker
                key={item._id || index}
                position={[item.singleLocation.coordinates[0], item.singleLocation.coordinates[1]]}
              >
                {getPopup ? getPopup(item) : null}
              </Marker>
            )
          }
        })}
      </React.Fragment>
    )
  }

  public fetchData = async () => {
    const { fetchCall, bounds, useBounds, checked } = this.props
    if (!fetchCall || !checked || (useBounds !== false && !bounds)) {
      return
    }

    try {
      const boundsToUse = useBounds !== false ? bounds : undefined
      const data = await this.props.fetchCall(boundsToUse)
      this.setState({ data })
    } catch (err) {
      console.error(err)
    }
  }
}
