import { toLatLng } from 'geolocation-utils'
import isEqual from 'lodash/isEqual'
import React, { useEffect, useState } from 'react'
import { Polygon, Popup } from 'react-leaflet'

import { usePrevious } from '../../../../../hooks/usePrevious'
import TeqplayApiService from '../../../../../services/TeqplayAPIService/TeqplayApiService'
import CollapsableLegendComponent from '../../../controls/CollapsableLegendComponent'

import './DepthsLayer.scss'

interface IProps {
  name: string
  bounds: L.LatLngBounds | undefined
  checked: boolean
  teqplayApiService: TeqplayApiService
  accuracy?: number
  color?: string
  disablePopup?: boolean
  handleFetchError?: () => void
  hideLegendCollapsable?: boolean
  legendPosition?: L.ControlPosition
  onClick?: (area: IDepthPolygon) => void
  showLegend?: boolean
  showStroke?: boolean
}

export interface IDepthPolygon {
  depth: number // negative
  polygons: { coordinates: number[][][]; type: 'Polygon' }[]
  singleLocation: { type: 'Point'; coordinates: [number, number] }
  locatableType: 'DEPTH'
  _id: string
}

const DepthsLayer = (props: IProps) => {
  const {
    bounds,
    checked,
    teqplayApiService,
    accuracy,
    color,
    disablePopup,
    hideLegendCollapsable,
    legendPosition,
    onClick,
    showStroke
  } = props

  const depths = useDepths(teqplayApiService, bounds, checked, accuracy)
  const [depthPolygons, setDepthPolygons] = useState<any[]>([])

  useEffect(() => {
    // Convert depths to polygons here
    setDepthPolygons(convertDepthsToPolygons(depths))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [depths, color, disablePopup, hideLegendCollapsable, legendPosition, showStroke])

  const fallbackedColor = color || 'rgba(41, 62, 78, 1)'
  const border = showStroke ? 'solid 2px ' + color : '0'

  if (!checked) {
    return null
  }

  return (
    <React.Fragment>
      <CollapsableLegendComponent
        icon={<span className="icon-depth" />}
        hideCollapsible={hideLegendCollapsable}
        position={legendPosition || 'topright'}
        id={props.name}
      >
        <div className="item-legend depths">
          <div className="depths">
            <div
              className="five"
              style={{ backgroundColor: fallbackedColor, border, opacity: 0.2 }}
            />
            <div className="five text">-5m</div>
            <div
              className="four"
              style={{ backgroundColor: fallbackedColor, border, opacity: 0.2 }}
            />
            <div className="four text">-4m</div>
            <div
              className="three"
              style={{ backgroundColor: fallbackedColor, border, opacity: 0.2 }}
            />
            <div className="three text">-3m</div>
            <div
              className="two"
              style={{ backgroundColor: fallbackedColor, border, opacity: 0.2 }}
            />
            <div className="two text">-2m</div>
            <div
              className="one"
              style={{ backgroundColor: fallbackedColor, border, opacity: 0.2 }}
            />
            <div className="one text">-1m</div>
          </div>
        </div>
      </CollapsableLegendComponent>

      {depthPolygons}
    </React.Fragment>
  )

  function convertDepthsToPolygons(depthPolygonsToConvert: IDepthPolygon[] | null) {
    if (depthPolygonsToConvert) {
      return depthPolygonsToConvert.map((area, index) =>
        area.polygons.map((poly, j) => {
          const locations = poly.coordinates[0].map(location => toLatLng(location as any))
          const popup = !disablePopup ? (
            <Popup key={`${area._id}-${index}`} autoPan={false} className="popup-depth">
              <div className="popup-depth-value">{area.depth}m</div>
            </Popup>
          ) : null

          return (
            <Polygon
              key={`depth-polygon-${index}-${j}-${area.depth}`}
              onClick={() => handleClick(area)}
              color={color || 'rgba(41, 62, 78, 1)'}
              positions={locations}
              stroke={showStroke}
            >
              {popup}
            </Polygon>
          )
        })
      )
    } else {
      return []
    }
  }

  function handleClick(area: IDepthPolygon) {
    if (onClick) {
      onClick(area)
    }
  }
}

function useDepths(
  apiService: TeqplayApiService,
  bounds: L.LatLngBounds | undefined,
  isChecked?: boolean,
  accuracy?: number
) {
  const [depths, setDepths] = useState<IDepthPolygon[] | null>(null)
  const [error, setError] = useState<any | null>(null)

  const previousBounds = usePrevious(bounds)
  const previousChecked = usePrevious(isChecked)

  useEffect(() => {
    async function fetchDepths() {
      if (bounds) {
        try {
          setError(null)
          const fetchedDepths = await apiService.getDepthPolygonsInBounds(bounds, accuracy)
          setDepths(fetchedDepths)
        } catch (err) {
          setError(err)
          throw err
        }
      }
    }

    // Double inversion to prevent any null or undefined values
    if (
      isChecked === true &&
      (!isEqual(isChecked, previousChecked) ||
        !isEqual(bounds?.getCenter(), previousBounds?.getCenter()))
    ) {
      fetchDepths()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isChecked, bounds, accuracy, error])

  return depths
}

export default DepthsLayer
