import React, { useEffect } from 'react'
import { connect, MapDispatchToPropsFunction, useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { toast } from 'react-toastify'
import { Action } from 'redux'
import { ThunkDispatch } from 'redux-thunk'

import DefaultTransition from '../../components/shared/defaultTransition/DefaultTransition'
import SwitchButton from '../../components/shared/switchButton/SwitchButton'
import ChangePasswordForm from './ChangePasswordForm'
import ChangeUserProfileForm from './ChangeUserProfileForm'
import TitleComponent from '../../components/titleComponent/TitleComponent'

import { IApplicationTheme, ILocationStatus, IRootProps } from '../../@types/types'
import { confirmPopup } from '../../components/shared/confirmPopup/ConfirmPopup'
import { useAnalytics } from '../../services/AnalyticsWrapper/AnalyticsWrapper'
import { IAuthenticationWrapper } from '../../services/AuthenticationWrapper/AuthenticationWrapper'
import { activeClass } from '../../utils/general'
import { removeFromLocalStorage } from '../../utils/localStorage'
import { supportsThemePreference } from '../../utils/style'
import { clearNavigationRoute } from '../routeSelectionPage/actions'
import { resetWarnings, setThemeOverride, toggleListenToDeviceTheme } from './actions'
import { useLocalStorage } from '../../hooks/useLocalStorage'

import './Settings.scss'

interface IProps {
  refreshUserProfile: () => Promise<void>
  locationStatus: ILocationStatus
}

interface IDispatchProps {
  changeTheme: (theme: IApplicationTheme) => void
  handleToggleListenToDeviceTheme: () => void
  resetWarnings: () => void
  handleClearNavigationRoute: () => void
}

const Settings = ({
  teqplayAPIService,
  changeTheme,
  handleToggleListenToDeviceTheme,
  resetWarnings: executeResetWarnings,
  handleClearNavigationRoute,
  refreshUserProfile,
  locationStatus
}: IAuthenticationWrapper & IDispatchProps & IProps) => {
  const analytics = useAnalytics('SettingsPage')
  const { currentUser, routeSelection, settings, userLocation } = useSelector(
    (rootProps: IRootProps) => rootProps
  )

  const supportsDynamicTheme = supportsThemePreference()
  const [isKmhActive, setKmhActive] = useLocalStorage('show-kmh', false)

  useEffect(() => {
    analytics.setScreen('settings')
    // Only execute on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <DefaultTransition active={true} offset={10} slideFrom="top">
      {styleProps => (
        <div className="main-wrapper settings-page" style={styleProps}>
          <TitleComponent title={I18n.t('settings.title')} />
          <h2>{I18n.t('settings.title')}</h2>

          <ChangeUserProfileForm
            auth={currentUser.auth}
            currentLocation={userLocation.currentLocation}
            confirmDeleteAccount={confirmDeleteAccount}
            teqplayAPIService={teqplayAPIService}
            userProfile={currentUser.userProfile}
            refreshUserProfile={refreshUserProfile}
            locationStatus={locationStatus}
          />

          <ChangePasswordForm
            username={currentUser.auth.username}
            teqplayAPIService={teqplayAPIService}
          />

          <div className="page-block">
            <h3>{I18n.t('settings.theme.style')}</h3>
            {supportsDynamicTheme && (
              <div className="form-column">
                <div className="form-row withInfo">
                  <label className="label" htmlFor="listenToDeviceTheme">
                    {I18n.t('settings.theme.listenToDeviceTheme')}
                  </label>
                  <SwitchButton>
                    <button
                      className={`button ${activeClass(settings.listenToDeviceTheme)}`}
                      onClick={e => {
                        e.preventDefault()
                        changeHandleListenToDeviceTheme(true)
                      }}
                      id="settings-button-toggle-listen-to-device-theme-enable"
                    >
                      {I18n.t('yes')}
                    </button>
                    <button
                      className={`button ${activeClass(!settings.listenToDeviceTheme)}`}
                      onClick={e => {
                        e.preventDefault()
                        changeHandleListenToDeviceTheme(false)
                      }}
                      id="settings-button-toggle-listen-to-device-theme-disable"
                    >
                      {I18n.t('no')}
                    </button>
                  </SwitchButton>
                </div>
                <div className="info">{I18n.t('settings.theme.listenToDeviceThemeInfo')}</div>
              </div>
            )}
            {(!supportsDynamicTheme || !settings.listenToDeviceTheme) && (
              <div className="form-row">
                <label className="label" htmlFor={'theme'}>
                  {I18n.t('settings.theme.theme')}
                </label>
                <SwitchButton>
                  <button
                    className={`button ${activeClass(settings.themeOverride === 'light')}`}
                    onClick={e => {
                      e.preventDefault()
                      handleChangeTheme('light')
                    }}
                    id="settings-button-set-theme-light"
                  >
                    {I18n.t('settings.theme.light')}
                  </button>
                  <button
                    className={`button ${activeClass(settings.themeOverride === 'dark')}`}
                    onClick={e => {
                      e.preventDefault()
                      handleChangeTheme('dark')
                    }}
                    id="settings-button-set-theme-dark"
                  >
                    {I18n.t('settings.theme.dark')}
                  </button>
                </SwitchButton>
              </div>
            )}
          </div>

          <div className="page-block">
            <h3>{I18n.t('settings.other')}</h3>
            <div className="form-column">
              <div className="form-row withInfo">
                <label className="label" htmlFor={'warnings'}>
                  {I18n.t('settings.warnings')}
                </label>
                <button
                  type="button"
                  onClick={() => handleResetWarnings()}
                  className="button large"
                  id="settings-button-reset-warnings"
                >
                  {I18n.t('settings.resetWarnings')}
                </button>
              </div>
              <div className="info">{I18n.t('settings.resetWarningsInfo')}</div>
            </div>
            <div className="form-row">
              <label className="label" htmlFor={'theme'}>
                {I18n.t('settings.speedUnit')}
              </label>
              <SwitchButton>
                <button
                  className={`button ${activeClass(isKmhActive)}`}
                  onClick={e => {
                    e.preventDefault()
                    setKmhActive(true)
                  }}
                  id="settings-button-set-theme-light"
                >
                  {I18n.t('settings.kmh')}
                </button>
                <button
                  className={`button ${activeClass(!isKmhActive)}`}
                  onClick={e => {
                    e.preventDefault()
                    setKmhActive(false)
                  }}
                  id="settings-button-set-theme-dark"
                >
                  {I18n.t('settings.knots')}
                </button>
              </SwitchButton>
            </div>
          </div>
        </div>
      )}
    </DefaultTransition>
  )

  function confirmDeleteAccount() {
    confirmPopup({
      message: I18n.t('settings.areYouSureDeleteAccount'),
      cancelText: I18n.t('settings.no'),
      confirmText: I18n.t('settings.yesDelete'),
      confirmClassName: 'button large danger',
      onConfirm: async () => {
        toast.success(I18n.t('settings.removeSuccess'))
        removeFromLocalStorage('auth')
        // Make sure we clear the route and any other saved items
        if (routeSelection.navigationRoute) {
          handleClearNavigationRoute()
        }

        resetWarnings()
        analytics.newEvent('delete_account', {})
        await teqplayAPIService.deleteAccount()

        // It will automatically logout
      }
    })
  }

  function changeHandleListenToDeviceTheme(option: boolean) {
    if (option !== settings.listenToDeviceTheme) {
      handleToggleListenToDeviceTheme()
      analytics.newEvent('settings_toggle_listen_to_device_theme', { option })
      toast.success(I18n.t('settings.updateProfileSuccess'))
    }
  }

  function handleChangeTheme(theme: IApplicationTheme) {
    changeTheme(theme)
    analytics.newEvent('settings_change_theme', { theme })
    toast.success(I18n.t('settings.updateProfileSuccess'))
  }

  function handleResetWarnings() {
    executeResetWarnings()
    analytics.newEvent('settings_reset_warnings', {
      suppressedWarnings: settings.suppressedWarnings
    })
    toast.success(I18n.t('settings.resetWarningsSuccess'))
  }
}

const mapDispatchToProps: MapDispatchToPropsFunction<IDispatchProps, any> = (
  dispatch: ThunkDispatch<IRootProps, void, Action>
) => {
  return {
    changeTheme: (theme: IApplicationTheme) => dispatch(setThemeOverride(theme)),
    handleToggleListenToDeviceTheme: () => dispatch(toggleListenToDeviceTheme()),
    resetWarnings: () => dispatch(resetWarnings()),
    handleClearNavigationRoute: () => dispatch(clearNavigationRoute())
  }
}

const mapStateToProps = (state: IRootProps, ownProps: {}) => {
  return state
}

export default connect(mapStateToProps, mapDispatchToProps)(Settings)
