import { toLatLng } from 'geolocation-utils'
import { LatLngBounds } from 'leaflet'
import isEqual from 'lodash/isEqual'
import React, { useEffect, useState } from 'react'
import { Polyline, Popup } from 'react-leaflet'
import { I18n } from 'react-redux-i18n'
import { toast } from 'react-toastify'
import { useSelector } from 'react-redux'

import { usePrevious } from '../../../../../hooks/usePrevious'
import TeqplayApiService from '../../../../../services/TeqplayAPIService/TeqplayApiService'
import CollapsableLegendComponent from '../../../controls/CollapsableLegendComponent'
import { IFairway, IRootProps } from '../../../../../@types/types'

import './FairwaysLayer.scss'

interface IProps {
  name: string
  bounds: L.LatLngBounds | undefined
  checked: boolean
  teqplayApiService: TeqplayApiService
  color?: string
  setSelectedItem: (item: IFairway | undefined) => void
  selectedItem: IFairway | null
  disableInteraction?: boolean
}

const units = [6, 9, 12, 16]

const FairwaysLayer = (props: IProps) => {
  const { checked, teqplayApiService, bounds, setSelectedItem, disableInteraction } = props
  const locale = useSelector((s: IRootProps) => s.i18n.locale)
  const fairways = useFairways(teqplayApiService, bounds, checked)
  if (!checked || !fairways) {
    return null
  }

  return (
    <React.Fragment>
      {fairways.map(fairway => (
        <React.Fragment key={`${fairway._id}-polygon`}>
          {/* Invisible thicker polyline for easier click interaction */}
          <Polyline
            key={`${fairway._id}-interactive`}
            positions={fairway.paths.map(locations =>
              locations.map(position => toLatLng(position.coordinates))
            )}
            weight={55} // Increase the weight to make it easier to click
            interactive={!disableInteraction}
            opacity={0}
            onClick={() =>
              fairway?.note ? fetchFairwayID(fairway._id) : setSelectedItem(undefined)
            }
          >
            <Popup
              key={`${fairway._id}-popup`}
              autoPan={false}
              className="popup-fairway"
              onOpen={() =>
                fairway?.note ? fetchFairwayID(fairway._id) : setSelectedItem(undefined)
              }
            >
              <div className="popup-fairway-inner">
                <span className="popup-fairway-name">{fairway.fairwayName || fairway.fairway}</span>
                <div className="popup-fairway-maxspeed">
                  {fairway.maxSpeedKmH === 0 ? '?' : fairway.maxSpeedKmH}
                </div>
              </div>
            </Popup>
          </Polyline>
          {/* Visible polyline */}
          <Polyline
            key={fairway._id}
            color={getFairwayColor(fairway.maxSpeedKmH)}
            positions={fairway.paths.map(locations =>
              locations.map(position => toLatLng(position.coordinates))
            )}
            dashArray={fairway?.note ? '6,6' : undefined}
            weight={props.selectedItem?._id === fairway._id ? 7 : 3}
            interactive={false}
          />
        </React.Fragment>
      ))}
      <CollapsableLegendComponent
        position={'topright'}
        icon={<span className="icon-gauge" />}
        id={props.name}
      >
        <div className="timeslider-wrapper fairways">
          <div className="timeslider-legend-description">{I18n.t('map.fairways.maxSpeed')}</div>
          <div className="timeslider-legend-units">
            <div className="unit unit-unknown">? {I18n.t('unit.kmh')}</div>
            {units.map(unit => (
              <div key={unit} className={`unit unit-${unit}`}>
                {unit} {I18n.t('unit.kmh')}
              </div>
            ))}
          </div>
          <div className="timeslider-legend-units">
            <div className="unit unknown">{I18n.t('map.fairways.moreInformation')}</div>
          </div>
        </div>
      </CollapsableLegendComponent>
    </React.Fragment>
  )

  function getFairwayColor(speed: number) {
    const relativeSpeedFactor = (s: number, min: number, max: number) => {
      const correctedMax = max - min
      const correctedSpeed = s < min ? 0 : s > max ? correctedMax : s - min
      return correctedMax === 0 && correctedSpeed === 0 ? 0 : correctedSpeed / correctedMax
    }

    const getColorBasedOnSpeedFactor = (sfactor: number) => {
      const end = 120 // HSL green
      const a = sfactor * end
      return 'hsl(' + a + ',100%,40%)'
    }

    const speedfactor = relativeSpeedFactor(speed, Math.min(...units), Math.max(...units))
    // Gray if the speed is unknown
    if (speed === 0) {
      return 'hsl(0deg 0% 56.47%)'
    } else {
      return getColorBasedOnSpeedFactor(speedfactor)
    }
  }

  async function fetchFairwayID(id: string) {
    try {
      const fetchedFairway = await props.teqplayApiService.fetchFairwayInformationID(id, locale)
      setSelectedItem(fetchedFairway)
    } catch (err) {
      toast.error(I18n.t('generalError'))
    }
  }
}

export function useFairways(
  apiService: TeqplayApiService,
  bounds?: LatLngBounds,
  isChecked?: boolean
) {
  const [fairways, setFairways] = useState<IFairway[] | null>(null)
  const [error, setError] = useState<any | null>(null)

  const previousBounds = usePrevious(bounds)
  const previousChecked = usePrevious(isChecked)

  useEffect(() => {
    async function fetchFairways() {
      try {
        setError(null)
        const fetchedDraughts = await apiService.fetchFairwayInformation(bounds)
        setFairways(fetchedDraughts)
      } catch (err) {
        setError(err)
        throw err
      }
    }

    if (
      isChecked === true &&
      (!isEqual(isChecked, previousChecked) ||
        !isEqual(bounds?.getCenter(), previousBounds?.getCenter()))
    ) {
      fetchFairways()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isChecked, error, bounds])

  return fairways
}

export default FairwaysLayer
