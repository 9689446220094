import * as React from 'react'
import { Link } from 'react-router-dom'
import { useAnalytics } from '../../../../services/AnalyticsWrapper/AnalyticsWrapper'

interface IProps {
  onClick: (e: React.ChangeEvent<any>) => void
  pageName: string
  iconClass: string
  url: string
  activePage?: string
  queryParams?: string
}

const MenuItem: React.FunctionComponent<IProps> = props => {
  const analytics = useAnalytics('MenuItem')
  const { onClick, pageName, iconClass, url, activePage, queryParams } = props
  const activePageClass = isPageActive(props.url, props.activePage) ? 'active' : ''

  return (
    <Link
      id={`menu-button-${pageName.replace(/ /g, '-').toLowerCase()}`}
      onClick={handleClick}
      className={`menu-item ${activePageClass}`}
      to={{ pathname: url, search: queryParams }}
    >
      <i className={`${iconClass}`} />
      <span>{pageName}</span>
    </Link>
  )

  function handleClick(e: React.ChangeEvent<any>) {
    analytics.newEvent('click_menu_button', {
      menuButtonName: pageName,
      menuButtonURL: url,
      currentActivePage: activePage
    })
    onClick(e)
  }
}

export default MenuItem

export function isPageActive(pageName: string, activePage?: string) {
  if (!activePage) return false

  if ((activePage === '/' || activePage === '') && pageName !== '/') {
    // If the active page is empty (homepage) make sure not all pages are set to active
    return false
  }

  if (pageName === '/' && activePage !== '/') {
    return false
  }

  return activePage.includes(pageName)
}
