import React, { useState, useEffect } from 'react'
import ReactCountryFlag from 'react-country-flag'
import { useSelector } from 'react-redux'
import { usePrevious } from '../../../hooks/usePrevious'
import COUNTRY_LIST from '../../../assets/countryCodes.json'
import { IRootProps } from '../../../@types/types'
import { getCountryName } from '../../../utils/countriesUtil'

import './BICSENINumberInput.scss'

interface IProps {
  value: string | undefined
  setValue: (newValue: string | undefined) => void
}

const BICSENINumberInput = ({ value, setValue }: IProps) => {
  const [tempValue, setTempValue] = useState<string>()
  const [isValid, setIsValid] = useState<boolean>(false)
  const previousValue = usePrevious(value)
  const locale = useSelector((s: IRootProps) => s.i18n.locale)

  useEffect(() => {
    // If a new value is given externally
    if (value && !previousValue) {
      // Set its value as the input value and check its validity
      setTempValue(value)
    }

    // If the new value is cleared externally
    if (!value && previousValue) {
      // Clear the input value and mark as invalid
      setTempValue(undefined)
      setIsValid(false)
    }
  }, [value, previousValue])

  const countryValue = tempValue
    ? COUNTRY_LIST.find(c => c.code === showENICountryOfOrigin(tempValue))
    : null

  return (
    <div className="eni-input-wrapper">
      <input
        className="textfield"
        inputMode="numeric"
        value={tempValue}
        onChange={e => handleCheckValidity(e)}
        lang="nl"
        data-valid={isValid}
        data-tempvalue={tempValue}
        data-value={value}
        maxLength={8}
      />
      {tempValue?.length === 8 && countryValue ? (
        <div className="country-subtitle">
          <ReactCountryFlag countryCode={countryValue.code} className="flag" svg />{' '}
          {getCountryName(countryValue, locale)}
        </div>
      ) : null}
    </div>
  )

  function handleCheckValidity(e: React.ChangeEvent<HTMLInputElement>) {
    e.preventDefault()
    e.stopPropagation()

    const rawInput = e.target.value

    // Check if all characters inside rawInput are numbers
    const allNumericCharacters = /^\d+$/.test(rawInput)

    if (rawInput && !allNumericCharacters) {
      setIsValid(false)
      setValue(rawInput)
      return
    } else {
      setTempValue(rawInput)

      if (!rawInput) {
        setValue(undefined)
        return
      }

      if (rawInput.length === 8) {
        // ENI numbers must be 8 characters long
        setIsValid(true)
        setValue(rawInput)
      } else {
        setIsValid(false)
        setValue(rawInput)
      }
    }
  }

  function showENICountryOfOrigin(eniNumber: string): string | 'OLD' | undefined {
    const eniRegistrationCode = Number(eniNumber.slice(0, 3))

    function fallsWithinRange(higherBound: number, lowerBound?: number) {
      return (
        eniRegistrationCode <= higherBound &&
        (lowerBound ? eniRegistrationCode >= lowerBound : true)
      )
    }

    if (fallsWithinRange(19) && eniNumber[0] === '0') {
      return 'FR'
    } else if (fallsWithinRange(39) && eniNumber[0] === '0') {
      return 'NL'
    } else if (fallsWithinRange(59) && eniNumber[0] === '0') {
      return 'DE'
    } else if (fallsWithinRange(69) && eniNumber[0] === '0') {
      return 'BE'
    } else if (fallsWithinRange(79) && eniNumber[0] === '0') {
      return 'CH'
    } else if (fallsWithinRange(99) && eniNumber[0] === '0') {
      return 'OLD' // Reserved for certificates issued before 1 April 2007'
    } else if (fallsWithinRange(119)) {
      return 'NO'
    } else if (fallsWithinRange(139)) {
      return 'DK'
    } else if (fallsWithinRange(159)) {
      return 'GB'
    } else if (fallsWithinRange(169)) {
      return 'IS'
    } else if (fallsWithinRange(179)) {
      return 'IE'
    } else if (fallsWithinRange(189)) {
      return 'PT'
    } else if (fallsWithinRange(199)) {
      return undefined // Reserved
    } else if (fallsWithinRange(219)) {
      return 'LU'
    } else if (fallsWithinRange(239)) {
      return 'FI'
    } else if (fallsWithinRange(259)) {
      return 'PL'
    } else if (fallsWithinRange(269)) {
      return 'EE'
    } else if (fallsWithinRange(279)) {
      return 'LT'
    } else if (fallsWithinRange(289)) {
      return 'LV'
    } else if (fallsWithinRange(299)) {
      return undefined // Reserved
    } else if (fallsWithinRange(309)) {
      return 'AT'
    } else if (fallsWithinRange(319)) {
      return 'LI'
    } else if (fallsWithinRange(329)) {
      return 'CZ'
    } else if (fallsWithinRange(339)) {
      return 'SK'
    } else if (fallsWithinRange(349)) {
      return undefined // Reserved
    } else if (fallsWithinRange(359)) {
      return 'HR'
    } else if (fallsWithinRange(369)) {
      return 'RS'
    } else if (fallsWithinRange(379)) {
      return 'BA'
    } else if (fallsWithinRange(399)) {
      return 'HU'
    } else if (fallsWithinRange(419)) {
      return 'RU'
    } else if (fallsWithinRange(439)) {
      return 'UA'
    } else if (fallsWithinRange(449)) {
      return 'BY'
    } else if (fallsWithinRange(459)) {
      return 'MD'
    } else if (fallsWithinRange(469)) {
      return 'RO'
    } else if (fallsWithinRange(479)) {
      return 'BU'
    } else if (fallsWithinRange(489)) {
      return 'GE'
    } else if (fallsWithinRange(499)) {
      return undefined // Reserved
    } else if (fallsWithinRange(519)) {
      return 'TR'
    } else if (fallsWithinRange(539)) {
      return 'GR'
    } else if (fallsWithinRange(549)) {
      return 'CY'
    } else if (fallsWithinRange(559)) {
      return 'AL'
    } else if (fallsWithinRange(569)) {
      return 'MK'
    } else if (fallsWithinRange(579)) {
      return 'SI'
    } else if (fallsWithinRange(589)) {
      return 'ME'
    } else if (fallsWithinRange(599)) {
      return undefined // Reserved
    } else if (fallsWithinRange(619)) {
      return 'IT'
    } else if (fallsWithinRange(639)) {
      return 'ES'
    } else if (fallsWithinRange(649)) {
      return 'AD'
    } else if (fallsWithinRange(659)) {
      return 'MT'
    } else if (fallsWithinRange(669)) {
      return 'MC'
    } else if (fallsWithinRange(679)) {
      return 'SM'
    } else if (fallsWithinRange(699)) {
      return undefined // Reserved
    } else if (fallsWithinRange(719)) {
      return 'SE'
    } else if (fallsWithinRange(739)) {
      return 'CA'
    } else if (fallsWithinRange(759)) {
      return 'US'
    } else if (fallsWithinRange(769)) {
      return 'IL'
    } else if (fallsWithinRange(799)) {
      return undefined // Reserved
    } else if (fallsWithinRange(809)) {
      return 'AZ'
    } else if (fallsWithinRange(819)) {
      return 'KZ'
    } else if (fallsWithinRange(829)) {
      return 'KG'
    } else if (fallsWithinRange(839)) {
      return 'TJ'
    } else if (fallsWithinRange(849)) {
      return 'TM'
    } else if (fallsWithinRange(859)) {
      return 'UZ'
    } else if (fallsWithinRange(869)) {
      return 'AR'
    } else if (fallsWithinRange(999)) {
      return undefined // Reserved
    } else {
      return undefined
    }
  }
}

export default BICSENINumberInput
