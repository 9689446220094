import React, { useState } from 'react'
import { I18n } from 'react-redux-i18n'
import { parseStringForID } from '../../../utils/tests'

interface IProps {
  mapZoomLevel: number
  activeLayers: string[]
  layersWithZoomLevelRestrictions: { layerName: string; minZoomLevel: number }[]
}

const LayerZoomWarning: React.FunctionComponent<IProps> = props => {
  const { layersWithZoomLevelRestrictions } = props
  const [isMinimized, setMinimized] = useState(false)

  const layerWarnings: string[] = []

  layersWithZoomLevelRestrictions.forEach(layer => {
    if (props.activeLayers.includes(layer.layerName) && props.mapZoomLevel < layer.minZoomLevel) {
      layerWarnings.push(I18n.t(`map.layers.${layer.layerName}`))
    }
  })

  if (layerWarnings.length === 0) {
    return null
  }

  return (
    <div
      className={`zoom-in-message ${isMinimized ? 'minimized' : ''}`}
      onClick={() => setMinimized(!isMinimized)}
      id="zoom-in-message"
    >
      <div className="inner-wrapper">
        {isMinimized && <span className="open-control icon-up-open" />}
        <p>{I18n.t('map.notification.zoomInNotification')}</p>
        <ul>
          {layerWarnings.map((warning, key) => {
            return (
              <li id={parseStringForID(`zoom-in-item-${warning}`)} key={'layerWarning' + key}>
                - {warning}
              </li>
            )
          })}
        </ul>
        <span className="minify-control icon-down-open" />
      </div>
    </div>
  )
}

export default LayerZoomWarning
