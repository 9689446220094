import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { useHistory } from 'react-router'
import { toast } from 'react-toastify'

import DefaultTransition from '../../components/shared/defaultTransition/DefaultTransition'
import ConfirmSubForm from './steps/Confirm'
import PersonalInfoSubForm from './steps/PersonalInfo'
import ShipInfoSubForm from './steps/ShipInfo'
import TitleComponent from '../../components/titleComponent/TitleComponent'

import { confirmPopup } from '../../components/shared/confirmPopup/ConfirmPopup'
import { useSessionStorage } from '../../hooks/useSessionStorage'
import { IAuthenticationWrapper } from '../../services/AuthenticationWrapper/AuthenticationWrapper'
import { BargeRegistrationUser, IShipInfo } from '../../services/TeqplayAPIService/TeqplayApi'
import { determineDynamicTheme, supportsThemePreference } from '../../utils/style'
import { resetWarnings } from '../settings/actions'

import './CreateAccountPage.scss'

type IRegistrationStep = 'PERSONAL_INFO' | 'SHIP' | 'CONFIRM'
const totalStepAmount = 3

const CreateAccountPage = (props: IAuthenticationWrapper) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const [registrationStep, setRegistrationStep] = useState<IRegistrationStep>('PERSONAL_INFO')

  // Using session storage here to aid
  const [name, setName] = useSessionStorage<string>('register-name', '')
  const [email, setEmail] = useSessionStorage<string>('register-email', '')
  const [phoneNumber, setPhoneNumber] = useSessionStorage<string>('register-phone', '')
  const [shipInfo, setShipInfo] = useSessionStorage<IShipInfo | null>('register-shipInfo', null)
  const [comment, setComment] = useSessionStorage<string>('register-comment', '')
  const [approvedGDPR, setReadAndApprovedGDPR] = useState<boolean>(false)

  const [loading, setLoading] = useState<boolean>(false)

  const renderedRegistrationStep = renderStep()
  const theme = supportsThemePreference() ? determineDynamicTheme() : 'light'

  return (
    <div className={`theme-wrapper ${theme}`}>
      <TitleComponent title={I18n.t('register.createAccount')} />
      <DefaultTransition offset={10} slideFrom="top" active={true}>
        {styleProps => (
          <div className="registration-wrapper" style={styleProps}>
            <div className={`inner ${registrationStep}`}>
              <div className="progress">
                <div className="step">
                  <span id="register-step">
                    {`${I18n.t('register.step')} ${getCurrentStepAmount() + 1}`}/{totalStepAmount}
                  </span>
                  <div id="register-step-name" className="current-step">
                    {I18n.t(`register.steps.${registrationStep}`)}
                  </div>
                </div>
                <div className="progress-amount">
                  {Math.floor((getCurrentStepAmount() / (totalStepAmount - 1)) * 100)}%
                </div>
              </div>
              <div className="progress-bar-outer">
                <div
                  className="progress-bar"
                  style={{ width: `${(getCurrentStepAmount() / (totalStepAmount - 1)) * 100}%` }}
                />
              </div>
              <div className="registration-step-wrapper">{renderedRegistrationStep}</div>
            </div>
          </div>
        )}
      </DefaultTransition>
    </div>
  )

  function getCurrentStepAmount() {
    switch (registrationStep) {
      case 'PERSONAL_INFO':
        return 0
      case 'SHIP':
        return 1
      case 'CONFIRM':
        return 2
      default:
        return 0
    }
  }

  function renderStep() {
    switch (registrationStep) {
      case 'PERSONAL_INFO':
        return (
          <PersonalInfoSubForm
            name={name}
            email={email}
            setName={setName}
            setEmail={setEmail}
            phoneNumber={phoneNumber}
            setPhoneNumber={setPhoneNumber}
            onNextStep={() => setRegistrationStep('SHIP')}
          />
        )

      case 'SHIP':
        return (
          <ShipInfoSubForm
            shipInfo={shipInfo}
            setShipInfo={setShipInfo}
            onPreviousStep={() => setRegistrationStep('PERSONAL_INFO')}
            onNextStep={() => setRegistrationStep('CONFIRM')}
            teqplayApiService={props.teqplayAPIService}
          />
        )

      case 'CONFIRM':
        return (
          <ConfirmSubForm
            name={name}
            email={email}
            phoneNumber={phoneNumber}
            shipInfo={shipInfo}
            comment={comment}
            setComment={setComment}
            approvedGDPR={approvedGDPR}
            loading={loading}
            openGDPR={(postAccept?: () => void) =>
              confirmPopup({
                title: I18n.t('register.gdpr.title'),
                message: (
                  <div className="privacy-statement">
                    <p className="gdpr-line">{I18n.t('register.gdpr.privacyBodyFirstLine')}</p>
                    <p className="gdpr-line">{I18n.t('register.gdpr.privacyBodySecondLine')}</p>
                    <p className="gdpr-line">{I18n.t('register.gdpr.privacyBodyThirdLine')}</p>
                    <p className="gdpr-line">{I18n.t('register.gdpr.privacyBodyFourthLine')}</p>
                    <p className="gdpr-line">{I18n.t('register.gdpr.privacyBodyFifthLine')}</p>
                    <p className="gdpr-line">{I18n.t('register.gdpr.privacyBodySixthLine')}</p>
                    <p className="gdpr-line">
                      {I18n.t('register.gdpr.privacyBodySeventhLine')}&nbsp;
                      <a
                        href="mailto:riverguide@teqplay.nl?subject=Privacy RiverGuide"
                        target="_system"
                      >
                        riverguide@teqplay.nl
                      </a>
                    </p>
                  </div>
                ),
                className: 'gdpr-popup',
                cancelText: I18n.t('register.gdpr.deny'),
                confirmText: I18n.t('register.gdpr.accept'),
                confirmClassName: 'button large primary',
                cancelClassName: 'button large empty',
                onConfirm: () => {
                  setReadAndApprovedGDPR(true)
                  if (postAccept) {
                    postAccept()
                  }
                },
                onCancel: () => {
                  setReadAndApprovedGDPR(false)
                  toast.error(I18n.t('register.gdpr.error'))
                }
              })
            }
            onPreviousStep={() => setRegistrationStep('SHIP')}
            onNextStep={() => handleRegistration()}
          />
        )

      default:
        return null
    }
  }

  async function handleRegistration() {
    const newUser: BargeRegistrationUser = {
      name,
      emailAddress: email,
      phoneNumber: phoneNumber || '',
      shipName: shipInfo?.name,
      shipMmsi: shipInfo?.mmsi || '',
      comment
    }

    try {
      // API service will send request to be registered to backend
      await props.teqplayAPIService.registerUser(newUser)
      setLoading(true)
      dispatch(resetWarnings())

      confirmPopup({
        noCancel: true,
        title: I18n.t('register.confirmed'),
        message: I18n.t('register.awaitActivation'),
        className: 'gdpr-popup',
        confirmText: I18n.t('proceed'),
        confirmClassName: 'button large primary',
        onConfirm: () => {
          history.push(`/${history.location.search}`)
        }
      })
    } catch (error) {
      console.error()
      toast.error(error.message)
    } finally {
      setLoading(false)
    }
  }
}

export default CreateAccountPage
