import { format } from 'date-fns'
import * as React from 'react'
import { I18n } from 'react-redux-i18n'
import { useSelector } from 'react-redux'

import PhoneNumberButton from '../phoneNumberButton/PhoneNumberButton'
import BridgeDrawing from './bridgeDrawing/BridgeDrawing'

import {
  IBridgeDetails,
  IBridgeMovement,
  IBridgePlanning
} from '../../services/TeqplayAPIService/TeqplayApi'
import { getBMSIcon } from '../../utils/bridgePlanning'
import { SHORT_DATE_TIME_FORMAT } from '../../utils/constants'
import { formatTime } from '../../utils/dates'
import { sortByKey } from '../../utils/general'
import { determineOperatingRulesForDaySet } from '../../utils/openingHours'
import { parseStringForID } from '../../utils/tests'
import { IRootProps } from '../../@types/types'

import './BridgeDetails.scss'

interface IProps {
  itemDetails: IBridgeDetails | null
  isExpanded?: boolean
  departureTime?: number | null
  bridgePlanning?: IBridgePlanning
  bridgeMovement?: IBridgeMovement
}

const BridgeDetails = ({
  itemDetails,
  isExpanded,
  departureTime,
  bridgeMovement,
  bridgePlanning
}: IProps) => {
  const locale = useSelector((s: IRootProps) => s.i18n.locale)
  if (!itemDetails || !itemDetails.name || isExpanded === false) {
    return null
  }
  const { dateBasedRules, activeOperatingPeriods } = determineOperatingRulesForDaySet(
    itemDetails.operatingTimes,
    departureTime ? new Date(departureTime) : undefined,
    undefined,
    locale
  )

  const notes: string[] = [itemDetails.note, itemDetails?.operatingTimes?.note]
    .concat(activeOperatingPeriods.map(period => period.note))
    .filter(x => x)
    .filter((v, i, s) => s.indexOf(v) === i) as string[]

  return (
    <div className="item-info-details" id={`bridge-details-${itemDetails.isrsCode}`}>
      <section className="details-section">
        {itemDetails.name && (
          <div className="route-item-caption" id="bridge-details-name">
            <b>
              <span>{itemDetails.name}</span>
            </b>
          </div>
        )}
        {itemDetails.city && itemDetails.city !== itemDetails.name && (
          <div className="route-item-caption" id="bridge-details-city">
            <b>
              <span>{itemDetails.city}</span>
            </b>
          </div>
        )}
        {bridgeMovement?.status && (
          <div className={`item-status ${bridgeMovement.status}`}>
            {I18n.t(`routeList.itemDetails.status.${bridgeMovement.status}`)}
          </div>
        )}
      </section>

      {(itemDetails.vhfChannel || itemDetails.phoneNumber) && (
        <section className="details-section">
          {itemDetails.vhfChannel ? (
            <div className="route-item-details-item" id="bridge-details-vhf-channel">
              <b>{I18n.t('routeList.itemDetails.vhf')}:</b> {itemDetails.vhfChannel}
            </div>
          ) : null}
          {itemDetails.phoneNumber ? (
            <div className="route-item-details-item phone" id="bridge-details-phone-number">
              <div className="title">
                <b>{I18n.t('phoneNumberButton.title')}:</b>
              </div>
              <PhoneNumberButton number={itemDetails.phoneNumber} />
            </div>
          ) : null}
        </section>
      )}

      {bridgePlanning && bridgePlanning.plannedOpeningTimes && (
        <section className="details-section" id="bridge-planning">
          <div className="route-item-caption opening-times">
            <b>
              <span>{I18n.t('routeList.itemDetails.plannedOpeningTimes')}</span>
            </b>
            {bridgePlanning.plannedOpeningTimes.map((ot, i) => (
              <div
                className={`planned-opening-time ${parseStringForID(ot.certainty)}`}
                id={`planned-opening-time-${bridgePlanning.isrs}-${i}`}
                key={i}
              >
                <i className={getBMSIcon(ot.certainty)} />
                <div className="time">
                  {formatTime(ot.plannedOpen)} - {formatTime(ot.plannedClosed)} (
                  {I18n.t(`routeList.itemDetails.bmsOpening.certainty.${ot.certainty}`)})
                </div>
              </div>
            ))}
          </div>
        </section>
      )}

      {(itemDetails.canOpen || itemDetails.waterLevel || itemDetails.bridgeOpening) && (
        <section className="details-section">
          {itemDetails.canOpen !== undefined ? (
            <div id="bridge-details-operable">
              <b>{I18n.t('routeList.itemDetails.operable')}:</b>{' '}
              {itemDetails.canOpen
                ? I18n.t('routeList.itemDetails.yes')
                : I18n.t('routeList.itemDetails.no')}
            </div>
          ) : null}

          {dateBasedRules.length > 0 && (
            <div className="opening-item" id="bridge-details-opening-hours">
              <div className="route-item-caption">
                <b>{I18n.t('routeList.itemDetails.control_times')}:</b>
              </div>
              {dateBasedRules.map((time, key) => (
                <div
                  key={key}
                  className="opening-time-wrapper"
                  id={`bridge-details-opening-hour-${parseStringForID(
                    time.validDates[0].date.toDateString()
                  )}`}
                >
                  <div className="opening-time">
                    <div className="day-row">
                      <div className="dates">
                        {time.validDates.map((d, i) => (
                          <div className="date" key={i}>
                            {i !== 0 ? '& ' : ''}
                            {d.textFormatted} {d.isHoliday && `(${d.isHoliday.name})`}
                          </div>
                        ))}
                      </div>
                      <div className="rules">
                        {time.validRulesForToday.length === 0 && (
                          <div
                            className="rule"
                            id={`bridge-details-opening-hour-${parseStringForID(
                              time.validDates[0].date.toDateString()
                            )}-no-service`}
                          >
                            {I18n.t('routeList.itemDetails.bridge_operating_rules.noService')}
                          </div>
                        )}
                        {time.validRulesForToday.map((rule, ruleKey) => (
                          <div
                            className="rule"
                            key={ruleKey}
                            id={`bridge-details-opening-hour-${parseStringForID(
                              time.validDates[0].date.toDateString()
                            )}-${ruleKey}`}
                          >
                            <div className="time">
                              {rule.timespan === '00:00 - 23:59'
                                ? I18n.t('routeList.itemDetails.wholeDay')
                                : rule.timespan}
                            </div>
                            <div className="note">{rule.notes}</div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}

          {notes.map((note, noteIndex) => (
            <div key={noteIndex} id={`bridge-details-note-${noteIndex}`}>
              <b>
                {I18n.t('routeList.itemDetails.note')}
                {notes.length > 1 ? ' ' + (noteIndex + 1) : ''}
              </b>
              : {note}
            </div>
          ))}

          {sortByKey(itemDetails.bridgeOpening, 'number').map((opening, key) => (
            <div key={key} id={`bridge-details-bridge-opening-${opening.number}`}>
              <div className="route-item-caption">
                <b>
                  {I18n.t('routeList.itemDetails.opening')} {opening.number}: {opening.name}
                </b>
              </div>
              <div
                className="route-item-details-item"
                id={`bridge-details-bridge-opening-${opening.number}-type`}
              >
                <b>{I18n.t('routeList.itemDetails.type')}:</b>{' '}
                {I18n.t('routeList.itemDetails.bridge_type.' + opening.type)}
              </div>
              {opening.width && (
                <div
                  className="route-item-details-item"
                  id={`bridge-details-bridge-opening-${opening.number}-width`}
                >
                  <b>{I18n.t('routeList.itemDetails.opening_width')}:</b> {opening.width} m
                </div>
              )}
              {opening.heightClosed !== undefined && (
                <div
                  className="route-item-details-item"
                  id={`bridge-details-bridge-opening-${opening.number}-height-closed`}
                >
                  <b>{I18n.t('routeList.itemDetails.closed_height')}:</b> {opening.heightClosed} m,
                  [{itemDetails.referencelevel}]
                </div>
              )}
              {opening.heightOpened !== undefined && (
                <div
                  className="route-item-details-item"
                  id={`bridge-details-bridge-opening-${opening.number}-height-opened`}
                >
                  <b>{I18n.t('routeList.itemDetails.opened_height')}:</b> {opening.heightOpened} m
                </div>
              )}
              {opening.note && (
                <div
                  className="route-item-details-item"
                  id={`bridge-details-bridge-opening-${opening.number}-note`}
                >
                  <b>{I18n.t('routeList.itemDetails.bridgeOpeningNote')}:</b> {opening.note}
                </div>
              )}
            </div>
          ))}

          {itemDetails.waterLevel && (
            <div id="bridge-details-water-level">
              <b>{I18n.t('routeList.itemDetails.waterLevel')}</b>:{' '}
              {Math.round(itemDetails.waterLevel.heightInCm) / 100} m [
              {I18n.t('routeList.itemDetails.NAP')}] (
              {format(itemDetails.waterLevel.timestamp, SHORT_DATE_TIME_FORMAT)})
            </div>
          )}
        </section>
      )}

      <BridgeDrawing itemDetails={itemDetails} />
    </div>
  )
}

export default BridgeDetails
