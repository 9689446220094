import React from 'react'
import Popup from 'react-popup'
import { I18n } from 'react-redux-i18n'
import { parseStringForID } from '../../../utils/tests'
import { ILayerCodeName, ILayerCoverageDetails } from '../../../@types/types'

export const showDataWarning = (
  clickedLayer: ILayerCodeName,
  layerCoverage: ILayerCoverageDetails,
  openLayer: () => void
) => {
  if (layerCoverage.prompts.highDataUsage) {
    Popup.create({
      title: (
        <div className="title" id="data-plan-message-title">
          <i className="icon-info-circled" />
          <span>{I18n.t('map.dataPlanWarning')}</span>
        </div>
      ),
      content: (
        <div className="limited-range-content" id={`data-plan-message-${clickedLayer}`}>
          <div className="subtitle">
            {I18n.t('map.dataPlanMessage', { layerCode: I18n.t(`map.layers.${clickedLayer}`) })}
          </div>
          <div className="bottom">
            <span>{I18n.t('map.dataPlanDataUsage')}</span>
            <span className="pagination" id={`data-plan-message-${clickedLayer}-usage`}>
              {layerCoverage.prompts.highDataUsage.usage}{' '}
              {I18n.t(layerCoverage.prompts.highDataUsage.unit)}
            </span>
          </div>
          <div className="bottom">
            <span>{I18n.t('map.limitedRangePost')}</span>
            <span className="pagination">{I18n.t('map.limitedRangePostPagination')}</span>
            <span>{I18n.t('map.dataPlanIgnore', { proceed: I18n.t('proceed') })}</span>
          </div>
        </div>
      ),
      className: 'popup-limited-range',
      closeOnOutsideClick: true,
      buttons: {
        left: [
          {
            text: I18n.t('cancel'),
            className: 'button large primary cancel',
            key: 'enter',
            action: () => {
              Popup.close()
            }
          }
        ],
        right: [
          {
            text: I18n.t('proceed'),
            className: 'button large proceed',
            key: 'enter',
            action: () => {
              Popup.close()
              openLayer()
            }
          }
        ]
      }
    })
  }
}

export const showLimitedCoveragePopup = (
  clickedLayer: ILayerCodeName,
  layerCoverage: ILayerCoverageDetails,
  handleContinue: () => void
) => {
  Popup.create({
    title: (
      <div className="title" id="limited-range-message-title">
        <i className="icon-info-circled" />
        <span>{I18n.t('map.limitedRange')}</span>
      </div>
    ),
    content: (
      <div className="limited-range-content" id={`limited-range-message-${clickedLayer}`}>
        <div className="subtitle">
          {I18n.t('map.limitedRangeMessage', { layerCode: I18n.t(`map.layers.${clickedLayer}`) })}
        </div>
        <ul>
          {layerCoverage.areas.map((a, i) => (
            <li key={i} id={parseStringForID(`limited-range-area-${a}`)}>
              {I18n.t(`source.area.${a}`)}
            </li>
          ))}
        </ul>
        <div className="bottom">
          <span>{I18n.t('map.limitedRangePost')}</span>
          <span className="pagination">{I18n.t('map.limitedRangePostPagination')}</span>
          <span>{I18n.t('map.limitedRangePolygonCoverage')}</span>
        </div>
      </div>
    ),
    className: 'popup-limited-range',
    closeOnOutsideClick: true,
    buttons: {
      left: [
        {
          text: I18n.t('close'),
          className: 'button large primary close',
          key: 'enter',
          action: () => {
            handleContinue()
            Popup.close()
          }
        }
      ]
    }
  })
}
