import React from 'react'
import ReactCountryFlag from 'react-country-flag'

import { BicsDestination } from '../../../services/TeqplayAPIService/TeqplayApi'

interface IProps {
  destination: BicsDestination
  disabled?: boolean
  viaIndex?: number
}

const BICSDestinationRow = ({ destination, disabled, viaIndex }: IProps) => {
  return (
    <div
      className={`destination-row${disabled ? ' disabled' : ''}`}
      data-srscode={destination.srsCode}
      data-unlocode={destination.loCode}
    >
      <div className="left">
        {viaIndex && <div className="via-circle">{viaIndex}</div>}
        {destination.loCode && (
          <ReactCountryFlag countryCode={destination.loCode?.substr(0, 2)} className="flag" svg />
        )}
        <div className="names">
          <div className="main-name">
            {(destination.name || destination.term) && (
              <div className="name" title={destination.name || destination.term}>
                {destination.name || destination.term}
              </div>
            )}
          </div>
          <div
            className={`name main${
              destination.term || destination.termLocal ? ' with-terminal' : ''
            }`}
            title={destination.term || destination.termLocal}
          >
            {destination.term || destination.termLocal}
          </div>
          {!destination.termLocal &&
            destination.name &&
            destination.nameLocal &&
            destination.name !== destination.nameLocal && (
              <div className="name local" title={destination.nameLocal}>
                {destination.nameLocal}
              </div>
            )}
        </div>
      </div>
      {destination.loCode && <div className="tag">{destination.loCode}</div>}
    </div>
  )
}

export default BICSDestinationRow
