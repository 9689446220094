import React, { useEffect, useState } from 'react'
import Modal from 'react-modal'
import { I18n } from 'react-redux-i18n'
import { toast } from 'react-toastify'

import TeqplayApiService from '../../../services/TeqplayAPIService/TeqplayApiService'

import { IStoredRoute } from '../../../services/TeqplayAPIService/TeqplayApi'

interface IProps {
  isOpen: boolean
  savedRouteName: string
  closeModal: () => void
  onNameChange: (name: string) => void
  saveRoute: () => Promise<void>
  teqplayApiService: TeqplayApiService
}

const SaveRouteModal: React.FunctionComponent<IProps> = props => {
  const [savedRoutes, setSavedRoutes] = useState<IStoredRoute[]>([])

  useEffect(() => {
    // Fetch saved routes upon initialisation
    fetchSavedRoutes()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Modal
      isOpen={props.isOpen}
      onRequestClose={props.closeModal}
      className="modal save-route-modal"
      style={{ overlay: { backgroundColor: 'rgba(0,0,0,0.75)' } }}
    >
      <form onSubmit={onSubmit} id="form-save-route">
        <h3>{I18n.t('routeSelection.saveRouteTitle')}</h3>

        <div className="input-wrapper">
          <label className="label" htmlFor={'routeNameInput'}>
            {I18n.t('routeSelection.routeNameInput')}:
          </label>
          <input
            id="save-route-name-input"
            name="routeNameInput"
            className="input"
            value={props.savedRouteName}
            onChange={onChangeName}
          />
        </div>

        <button className="button primary" type="submit" id="save-route-button-save">
          {I18n.t('routeSelection.saveRoute')}
        </button>
      </form>
    </Modal>
  )

  function onChangeName(e: React.ChangeEvent<any>) {
    props.onNameChange(e.target.value)
  }

  async function onSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault()

    const savedRouteNameAlreadyExists = props.savedRouteName
      ? savedRoutes.find(r => r.key === props.savedRouteName)
      : false

    if (savedRouteNameAlreadyExists) {
      toast.error(
        I18n.t('routeSelection.savedRouteNameAlreadyExists', { key: props.savedRouteName })
      )
      return
    }

    if (props.savedRouteName) {
      await props.saveRoute()

      // After saving, re-fetch the routes inside the modal to ensure savedRouteNameAlreadyExists
      // check is done correctly for next route.
      await fetchSavedRoutes()
    } else {
      toast.error(I18n.t('routeSelection.noNameFilled'))
    }
  }

  async function fetchSavedRoutes() {
    try {
      const allSavedRoutes = await props.teqplayApiService.getStoredRoutes()
      if (allSavedRoutes) {
        setSavedRoutes(allSavedRoutes)
      }
    } catch (err) {
      console.error(err)
    }
  }
}

export default SaveRouteModal
