import React, { useEffect, useState } from 'react'
import { I18n } from 'react-redux-i18n'

import BICSHullSelectorModal from '../bicsHullSelectorModal/BICSHullSelectorModal'
import BICSVoyageListItem from '../bicsVoyageListItem/BICSVoyageListItem'
import TeqplayApiService from '../../../services/TeqplayAPIService/TeqplayApiService'
import TitleComponent from '../../titleComponent/TitleComponent'

import { IBicsVoyage, IRouteSearchProps } from '../../../@types/types'
import { useAnalytics } from '../../../services/AnalyticsWrapper/AnalyticsWrapper'
import { IShipInfo, BicsHull, IRoute } from '../../../services/TeqplayAPIService/TeqplayApi'

import './BICSTravelList.scss'

interface IProps {
  voyages: IBicsVoyage[] | null
  currentVoyage?: IBicsVoyage | undefined
  loading: boolean
  refresh: () => void
  teqplayAPIService: TeqplayApiService
  updateDraughtInProfile: (
    currentDraught: number,
    dimensions: IShipInfo['dimensions']
  ) => Promise<void>
  hulls: BicsHull[] | null
  mainHull: BicsHull | undefined
  hullsLastUpdatedAt: number
  setRouteSelection: (route: IRouteSearchProps) => void
  setSelectedTravelID: (travelID: string | undefined) => void
  setSelectedHullID: (eni: string) => void
  startRoute?: (route: IRoute) => void
  setRouteSelectionInactive: () => void
}

const BICSTravelList = ({
  voyages,
  currentVoyage,
  loading,
  refresh,
  teqplayAPIService,
  updateDraughtInProfile,
  hulls,
  mainHull,
  hullsLastUpdatedAt,
  setRouteSelection,
  setSelectedTravelID,
  setSelectedHullID,
  startRoute,
  setRouteSelectionInactive
}: IProps) => {
  const analytics = useAnalytics('BICSTravelList')

  const [awaitingAction, setLoading] = useState<
    { type: 'BICS' | 'START'; travelId: number } | undefined
  >()
  const [showHullEditModal, setShowHullEditModal] = useState<boolean>(false)
  const sortedVoyagesDate =
    voyages &&
    voyages.length > 0 &&
    voyages.sort((a, b) => +new Date(b.startTime) - +new Date(a.startTime))
  const hasSentVoyage = voyages?.find(v => v.status === 'SENT') ? true : false

  useEffect(() => {
    // Set analytics screen upon mount
    analytics.setScreen('bics-travellist')

    // Refresh travels upon mount
    refresh()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="travel-list-screen">
      <BICSHullSelectorModal
        showModal={showHullEditModal}
        hulls={hulls}
        mainHull={mainHull}
        onClose={() => setShowHullEditModal(false)}
        onClickHull={(eni: string) => selectHull(eni)}
        hullsLastUpdatedAt={hullsLastUpdatedAt}
      />
      <TitleComponent title={I18n.t('announcements.travelList.title')} />
      <div className="title">
        <div className="left">
          <div className="route-select-menu-buttons">
            <i
              className="icon-left-big close-button"
              onClick={setRouteSelectionInactive}
              id="button-close-route-select-wrapper-menu"
            />
          </div>
        </div>
        <div className="right buttons">
          <button
            className="button secondary"
            onClick={() => {
              analytics.newEvent('bics-open-hull-edit-modal', {})
              setShowHullEditModal(true)
            }}
          >
            <i className="icon-edit" />
            {I18n.t('announcements.viewHulls')}
          </button>
          <button
            className="button primary new-button"
            onClick={e => {
              e.preventDefault()
              analytics.newEvent('bics-goto-new-voyage', {})
              setSelectedTravelID('new')
            }}
            disabled={!hulls && !loading}
          >
            <i className="icon-plus" />
            {I18n.t('announcements.travel.new')}
          </button>
        </div>
      </div>

      {!hulls && !loading ? (
        <div className="error-hint">
          <i className="icon-attention-1" />
          {I18n.t('announcements.error.noHullsFound')}
        </div>
      ) : (
        !currentVoyage &&
        (voyages || [])?.length === 0 && (
          <div className="current-voyage">
            <h3 className="no-travel-message">
              {loading ? (
                <div>
                  <i className="animate-spin icon-circle-notch" />
                  {I18n.t('loading')}...
                </div>
              ) : (
                I18n.t('announcements.travel.noTravels')
              )}
            </h3>
          </div>
        )
      )}

      {currentVoyage && (
        <div className="current-voyage">
          <h3>{I18n.t('announcements.travel.current')}</h3>
          <BICSVoyageListItem
            travel={currentVoyage}
            isCurrentVoyage={true}
            hasCurrentVoyage={true}
            teqplayAPIService={teqplayAPIService}
            loading={awaitingAction}
            setLoading={setLoading}
            refreshData={refresh}
            updateDraughtInProfile={updateDraughtInProfile}
            hulls={hulls}
            mainHull={mainHull}
            setRouteSelection={setRouteSelection}
            setSelectedTravelID={setSelectedTravelID}
            startRoute={startRoute}
            hasSentVoyage={hasSentVoyage}
          />
        </div>
      )}

      {sortedVoyagesDate && (
        <>
          <h3>{I18n.t('announcements.travel.planned')}</h3>
          <div className="travel-list">
            {sortedVoyagesDate.map(t => (
              <BICSVoyageListItem
                travel={t}
                key={`${t.travelId}-${t.fromLocation}-${t.toLocation}`}
                isCurrentVoyage={false}
                hasCurrentVoyage={currentVoyage?.travelId !== undefined}
                teqplayAPIService={teqplayAPIService}
                loading={awaitingAction}
                setLoading={setLoading}
                refreshData={refresh}
                updateDraughtInProfile={updateDraughtInProfile}
                hulls={hulls}
                mainHull={mainHull}
                setRouteSelection={setRouteSelection}
                setSelectedTravelID={setSelectedTravelID}
                startRoute={startRoute}
                hasSentVoyage={hasSentVoyage}
              />
            ))}
          </div>
        </>
      )}
    </div>
  )

  function selectHull(eni: string) {
    setSelectedHullID(eni)
    setShowHullEditModal(false)
  }
}

export default BICSTravelList
