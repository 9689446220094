import React from 'react'
import { formatDistance } from 'date-fns'
import { useSelector } from 'react-redux'
import Modal from 'react-modal'
import { I18n } from 'react-redux-i18n'
import ReactCountryFlag from 'react-country-flag'

import { BicsHull } from '../../../services/TeqplayAPIService/TeqplayApi'
import { IRootProps } from '../../../@types/types'
import { useAnalytics } from '../../../services/AnalyticsWrapper/AnalyticsWrapper'
import { determineApplicationTheme } from '../../../utils/style'
import { sortByKey } from '../../../utils/general'
import './BICSHullSelectorModal.scss'

interface IProps {
  mainHull: BicsHull | undefined
  hulls: BicsHull[] | null
  showModal: boolean
  onClose: () => void
  onClickHull: (eni: string) => void
  filterHulls?: string[]
  hideCreateNewButton?: boolean
  titleOverride?: React.ReactNode
  subtitleOverride?: React.ReactNode
  hullsLastUpdatedAt?: number
}

const BICSHullSelectorModal = ({
  mainHull,
  hulls,
  showModal,
  onClose,
  onClickHull,
  filterHulls,
  hideCreateNewButton,
  titleOverride,
  subtitleOverride,
  hullsLastUpdatedAt
}: IProps) => {
  const { listenToDeviceTheme, themeOverride } = useSelector((props: IRootProps) => props.settings)
  const analytics = useAnalytics('BICSBillingInfoModal')
  const theme = determineApplicationTheme(listenToDeviceTheme, themeOverride)

  const allHulls = (
    mainHull
      ? [mainHull, ...sortByKey(hulls || [], 'name')?.filter(c => c.eni !== mainHull.eni)]
      : sortByKey(hulls || [], 'name')
  ).filter(c =>
    filterHulls !== undefined ? (c.mmsi || c.eni ? !filterHulls.includes(c.mmsi || c.eni) : c) : c
  )

  return (
    <Modal
      isOpen={showModal}
      onRequestClose={onClose}
      className={`hull-select-modal ${theme}`}
      style={{ overlay: { backgroundColor: 'rgba(0,0,0,0.50)' } }}
      shouldCloseOnOverlayClick={true}
    >
      <div className="hull-edit-inner">
        <div className="title">
          <div>
            <h1>{titleOverride ? titleOverride : I18n.t('announcements.hullSelect.title')}</h1>
            {!hideCreateNewButton && subtitleOverride && (
              <h3>{I18n.t('announcements.hullSelect.subtitle')}</h3>
            )}
            {hullsLastUpdatedAt ? (
              <div className="time-update">
                {I18n.t('announcements.hullUpdatedAgo', {
                  distance: formatDistance(hullsLastUpdatedAt, new Date(), {
                    addSuffix: true,
                    includeSeconds: true
                  })
                })}
              </div>
            ) : null}
          </div>
        </div>
        {!hideCreateNewButton ? (
          <button
            className="button secondary new-hull"
            onClick={() => {
              analytics.newEvent('bics-open-hull-edit-modal', {})
              onClickHull(allHulls.length < 0 ? 'create' : 'new')
            }}
          >
            <i className="icon-edit" />
            {I18n.t('announcements.hullSelect.createHull')}
          </button>
        ) : null}
        <div className="hull-list">
          {allHulls.length === 0 ? (
            <div className="error-hint">{I18n.t('announcements.error.noHullsFound')}</div>
          ) : (
            allHulls.map(hull => (
              <button
                className="button secondary hull-item"
                key={hull.eni}
                onClick={() => onClickHull(`${hull.eni}-${hull.mmsi}`)}
              >
                <h3 className="hull-name">
                  <ReactCountryFlag countryCode={hull.nationality} className="flag" svg />
                  {hull.name || hull.eni}
                </h3>
                <div className="tags">
                  {mainHull?.eni === hull.eni &&
                    mainHull.mmsi === hull.mmsi &&
                    mainHull.type === hull.type && (
                      <div className="tag main">
                        <div className="key">{I18n.t('announcements.hullSelect.mainHull')}</div>
                      </div>
                    )}
                  {hull.name ? (
                    <div className="tag">
                      <div className="key">{I18n.t('announcements.fields.eniShort')}</div>
                      <div className="value">{hull.eni}</div>
                    </div>
                  ) : null}
                  {hull.mmsi && (
                    <div className="tag">
                      <div className="key">{I18n.t('announcements.fields.mmsi')}</div>
                      <div className="value">{hull.mmsi}</div>
                    </div>
                  )}
                </div>
              </button>
            ))
          )}
        </div>

        <div className="buttons">
          <button
            className="button primary"
            onClick={e => {
              e.preventDefault()
              onClose()
            }}
          >
            {I18n.t('cancel')}
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default BICSHullSelectorModal
