import { ILoggedInUser, IUserProfile } from '../../services/TeqplayAPIService/TeqplayApi'
import { setUserInSentry, setExtraValueInSentry } from '../../utils/sentry'

export type ILoginAction = ISetAuth | IClearAuth | ISetUserProfile

type ISetAuth = { type: 'SET_AUTH'; auth?: ILoggedInUser }
export function setAuth(auth?: ILoggedInUser): ISetAuth {
  setUserInSentry(auth || null)
  return {
    type: 'SET_AUTH',
    auth
  }
}

type IClearAuth = { type: 'CLEAR_AUTH' }
export function clearAuth(): IClearAuth {
  setUserInSentry(null)
  return {
    type: 'CLEAR_AUTH'
  }
}

type ISetUserProfile = { type: 'SET_USERPROFILE'; userProfile?: IUserProfile }
export function setUserProfile(userProfile?: IUserProfile): ISetUserProfile {
  setExtraValueInSentry('userProfile', userProfile || null)
  return {
    type: 'SET_USERPROFILE',
    userProfile
  }
}
