import { toLatLng } from 'geolocation-utils'
import L, { ControlPosition, LatLngBounds } from 'leaflet'
import isEqual from 'lodash/isEqual'
import React, { useEffect, useState } from 'react'
import { CircleMarker, Marker, Popup, Tooltip } from 'react-leaflet'
import { I18n } from 'react-redux-i18n'

import { usePrevious } from '../../../../../hooks/usePrevious'
import TeqplayApiService from '../../../../../services/TeqplayAPIService/TeqplayApiService'
import { sendMessageToSentry } from '../../../../../utils/sentry'
import './BuoysLayer.scss'

interface IProps {
  name: string
  checked: boolean
  bounds: LatLngBounds | undefined
  teqplayApiService: TeqplayApiService

  minimumZoomLevel?: number
  mapZoomLevel?: number
  legendPosition?: ControlPosition
  disableInteraction?: boolean
}

export interface IBuoy {
  codeName: string
  fairway: string
  ialaCategory: number
  locatableType: string
  location: {
    latitude: number
    longitude: number
    coordinates: [number, number]
    type: string
  }
  name: string
  objectColor: string
  objectColorCode: string
  objectShape: string
  objectShapeCode: string
  objectType: string
  singleLocation: {
    latitude: number
    longitude: number
    coordinates: [number, number]
    type: string
  }
  colorPattern?: string
  colorPatternCode?: string
  lightColor?: string
  lightColorCode?: string
  signCharacteristic?: string
  signCharacteristicCode?: string
  signPeriod?: string
  ttColor?: string
  ttColorCode?: string
  ttTipShape?: string
  ttTipShapeCode?: string
}

const BuoysLayer = (props: IProps) => {
  const { checked, bounds, teqplayApiService, minimumZoomLevel, mapZoomLevel, disableInteraction } =
    props
  const buoys = useBuoys(teqplayApiService, bounds, checked)

  if (!checked || !buoys || (mapZoomLevel || 12) < (minimumZoomLevel || 11)) {
    return null
  }

  const buoyMarkers = buoys?.map((b, i) =>
    convertBuoyToMarker(b, i, mapZoomLevel || 12, undefined, undefined, disableInteraction)
  )
  return <React.Fragment>{buoyMarkers}</React.Fragment>

  function convertBuoyToMarker(
    buoy: IBuoy,
    index: number,
    mapZoom: number,
    disableDetails?: boolean,
    showTooltip?: boolean,
    disablePopup?: boolean
  ): React.ReactNode {
    const location = toLatLng(buoy.singleLocation.coordinates)
    if (buoy.objectColor == null) {
      return null
    } else if (
      mapZoom > 13 &&
      !disableDetails &&
      (buoy.objectColorCode != null || buoy.objectShapeCode != null || buoy.ttTipShapeCode != null)
    ) {
      let iconCode = 'buoy-'
      buoy.objectColorCode != null ? (iconCode += buoy.objectColorCode) : (iconCode += '')
      buoy.objectShapeCode != null ? (iconCode += '/' + buoy.objectShapeCode) : (iconCode += '')
      buoy.ttTipShapeCode != null ? (iconCode += '/' + buoy.ttTipShapeCode) : (iconCode += '')
      buoy.signCharacteristic != null ? (iconCode += '/2') : (iconCode += '/1')

      const determineFallbackColor = (c: string) => {
        if (c.startsWith('Green')) {
          return 'green'
        } else if (c.startsWith('Red')) {
          return 'red'
        } else if (c.startsWith('Black')) {
          return 'black'
        } else if (c.startsWith('Yellow')) {
          return 'yellow'
        } else {
          sendMessageToSentry(`Failed parsing buoy with an unknown color ${c}`, { buoy })
          return 'black'
        }
      }

      // Trycatch is needed here because the require image line can fail if a buoy image does not exist.
      // When this happens, the function is executed again but is forced to be shown as a dot
      try {
        const markerIcon = L.divIcon({
          className: 'div-buoy-icon',
          html: `<div class="buoy-icon buoy-fallback-${determineFallbackColor(
            buoy.objectColor
          )} ${iconCode.split(',').join('-').split('/').join('_')}"></div>`,
          iconAnchor: [10, 10],
          iconSize: [20, 20]
        })

        const markerInfo = showTooltip ? (
          <Tooltip
            className="tooltip-buoy"
            key={index}
            permanent={true}
            direction="bottom"
            offset={[0, 6]}
          >
            <span>{buoy.name}</span>
          </Tooltip>
        ) : disablePopup ? null : (
          <Popup autoPan={false} className="popup-buoy">
            <span>
              {I18n.t('map.buoys.buoy')} {buoy.name}
            </span>
          </Popup>
        )

        return (
          <Marker
            key={`${buoy.codeName || buoy.name}-i-${index}`}
            position={location}
            icon={markerIcon}
            interactive={!disableInteraction}
          >
            {markerInfo}
          </Marker>
        )
      } catch (error) {
        return convertBuoyToMarker(buoy, index, mapZoom, true)
      }
    } else {
      let color = 'rgb(110, 110, 110)'
      if (buoy.objectColor != null) {
        if (buoy.objectColor.startsWith('Green')) {
          color = '#11BD1F'
        } else if (buoy.objectColor.startsWith('Red')) {
          color = '#ED243E'
        } else if (buoy.objectColor.startsWith('Black')) {
          color = 'rgb(60,60,60)'
        } else if (buoy.objectColor.startsWith('Yellow')) {
          color = '#F0C74D'
        }
      }

      const popup = disablePopup ? null : (
        <Popup autoPan={false} className="popup-buoy">
          <span>
            {I18n.t('map.buoys.buoy')} {buoy.name}
          </span>
        </Popup>
      )
      return (
        <CircleMarker
          key={(buoy.name || buoy.codeName) + ' ' + index}
          center={location}
          radius={2}
          color={color}
          interactive={!disablePopup}
        >
          {popup}
        </CircleMarker>
      )
    }
  }
}

export function useBuoys(
  teqplayApiService: TeqplayApiService,
  bounds?: LatLngBounds,
  isChecked?: boolean
) {
  const [buoys, setBuoys] = useState<IBuoy[] | null>(null)
  const [error, setError] = useState<string | null>(null)

  const previousBounds = usePrevious(bounds)
  const previousChecked = usePrevious(isChecked)

  useEffect(() => {
    async function fetchBuoys() {
      try {
        setError(null)
        const fetchedBuoys = await teqplayApiService.getBuoysInBounds(bounds)
        setBuoys(fetchedBuoys)
      } catch (err) {
        setError(err)
        throw err
      }
    }

    if (
      isChecked === true &&
      (!isEqual(isChecked, previousChecked) ||
        !isEqual(bounds?.getCenter(), previousBounds?.getCenter()))
    ) {
      fetchBuoys()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isChecked, error, bounds])

  return buoys
}

export default BuoysLayer
