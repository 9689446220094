import * as React from 'react'
import { IShorePower } from '../../services/TeqplayAPIService/TeqplayApi'

interface IProps {
  itemDetails: IShorePower | null
  isExpanded: boolean
}

const ShorePowerDetails = ({ itemDetails, isExpanded }: IProps) => {
  if (!itemDetails || isExpanded === false) {
    return null
  }

  return (
    <div className="item-info-details" id={`shorepower-details-${itemDetails.description}`}>
      <div className="notification-inner">
        <section className="details-section">
          <div className="route-item-caption" id="shorepower-details-description">
            <b>{itemDetails.description}</b>
          </div>
        </section>
      </div>
    </div>
  )
}

export default ShorePowerDetails
