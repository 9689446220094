const frenchLocale = {
  app: 'RiverGuide',
  appTitle: 'RiverGuide Binnenvaart',
  version: 'Version %{version}',
  save: 'Sauvegarder',
  yes: 'Oui',
  no: 'non',
  proceed: 'Monter',
  confirm: 'Confirmer',
  proceedAndConfirm: 'Confirmer et continuer',
  cancel: 'Annuler',
  close: 'Fermer',
  now: 'Maintenant',
  loading: 'charger',
  processing: 'En traitement',
  required: 'Obligatoire',
  delete: 'Supprimer',
  generalError:
    "Malheureusement, quelque chose s'est mal passé, veuillez réessayer plus tard ou contactez-nous.",
  noInfoAvailable: "Pas d'information disponible",
  empty: 'Vide',
  disclaimer: {
    title: 'Riverguide Binnenvaart est en cours de développement, soyez prudent !',
    content:
      "Gardez les yeux sur la voie navigable et respectez la loi. Il est possible que les itinéraires soient incomplets, incorrects, dangereux ou interdits. Les données ne sont pas toujours disponibles en temps réel et la précision de la localisation n'est pas toujours suffisante. Teqplay, ses clients et ses fournisseurs de données ne sont pas responsables des dommages résultant de l'utilisation de cette application.",
    agree: 'Compris'
  },
  fillInAllFields: 'Remplissez tous les champs',
  unknownValue: 'Valeur inconnue',
  privacy: 'Confidentialité',
  title: {
    createAccount: 'Demander un compte',
    settings: 'Paramètres',
    weather: 'Météo',
    routeSelection: "Planificateur d'itinéraire",
    routeSelectionPicker: 'Itinéraires pour %{from}%{via} vers %{to}',
    routeSelectionPickerVia: 'via %{number} emplacements',
    navigation: 'Navigation active',
    navigationPoint: '%{distance} · %{temps}',
    routePaused: 'Itinéraire en pause'
  },
  locale: {
    selectLocale: 'Langue',
    nl_NL: 'Néerlandais',
    en_GB: 'Anglais',
    fr_FR: 'Français',

    nl_NL_native: 'Nederlands',
    en_GB_native: 'English',
    fr_FR_native: 'Française'
  },
  login: {
    login: 'Se connecter',
    welcome: {
      morning: 'Bonjour, bon retour !',
      afternoon: 'Bonjour, bienvenue à nouveau !',
      evening: 'Bonsoir, bon retour !',
      night: 'Content de te revoir!',
      day: "C'est bon de te revoir!"
    },
    developMode: 'Lié à %{url}',
    welcomeBack: 'Bonjour %{nom}',
    email: 'E-mail',
    password: 'mot de passe',
    submit: 'Envoyer',
    registerLink: 'Demander un nouveau compte',
    forgot: 'Avez-vous oublié votre mot de passe?',
    wrongCredentials:
      "Une erreur s'est produite lors de la connexion, veuillez essayer de créer un nouveau compte ou contactez-nous",
    back: 'Retour connexion',
    forgotPassword: "Demandez un nouveau mot de passe avec votre nom d'utilisateur",
    username: "Nom d'utilisateur",
    successForgot: 'Succès!',
    forgotPasswordSuccess:
      'Si vous avez un compte RiverGuide, un e-mail a été envoyé à votre adresse e-mail avec des instructions supplémentaires.'
  },
  register: {
    createAccount: 'Créer un compte',
    submit: 'Registre',
    backToLogin: 'Retour connexion',
    name: 'Nom du capitaine',
    emailAddress: 'E-mail',
    password: 'mot de passe',
    mmsiNumber: 'Numéro MMSI',
    repeatPassword: 'Répéter le mot de passe',
    phoneNumber: 'numéro de téléphone',
    shipName: 'Nom du navire',
    ship: 'Navire',
    helpName:
      'Si nous ne trouvons pas votre navire avec votre numéro MMSI, nous utiliserons le nom du navire pour le rechercher.',
    comment: 'Message supplémentaire',
    passwordsNotMatch: 'Les mots de passe ne sont pas les mêmes',
    fieldsRequired: 'Remplissez tous les champs obligatoires (*)',
    alreadyExists: 'Un compte existe déjà pour cette adresse email',
    mandatory: 'champs obligatoire',
    confirmAccount: 'Confirmez votre compte',
    readApproveGDPR: 'Cliquez pour lire la politique de confidentialité',
    accepted: 'Politique de confidentialité acceptée',
    confirmed: "Demande d'inscription reçue",
    activeAISTransponderRequired:
      "Vous avez besoin d'un transpondeur AIS actif pour utiliser Riverguide Binnenvaart",
    awaitActivation:
      'Nous avons bien reçu votre demande. Avant que le compte puisse être utilisé, il doit être approuvé. Vous recevrez un e-mail de confirmation à ce sujet.',
    help: {
      PERSONAL_INFO:
        'Nous aimerions connaître votre nom et votre adresse e-mail pour créer un compte.',
      SHIP: "Nous utilisons le nom de votre navire pour personnaliser l'application.",
      SHIP_DIMENSIONS:
        'Pour déterminer où vous pouvez et ne pouvez pas passer, nous avons besoin des dimensions de votre navire.'
    },
    step: 'Étape',
    steps: {
      PERSONAL_INFO: 'Informations personnelles',
      SHIP: 'Informations sur le navire',
      SHIP_DIMENSIONS: 'Dimensions du navire',
      CONFIRM: 'Confirmer'
    },
    back: 'Dos',
    cancel: 'Annuler',
    next: 'Suivant',
    mmsiError: {
      empty: 'Sélectionnez un navire',
      oldAISTitle: 'Signal AIS obsolète',
      oldAISFirstLine: 'Votre signal AIS a été reçu pour la dernière fois %{time}.',
      oldAISSecondLine: 'Assurez-vous d’avoir un transpondeur AIS fonctionnel et actif.',
      oldAISThirdLine:
        'Sans signal AIS actif, Riverguide Binnenvaart ne fonctionnera pas comme prévu et ne pourra pas vous afficher correctement sur la carte.',
      oldAISFourthLine:
        'Dès que votre transpondeur AIS envoie un signal et que nous recevons votre signal, votre position sera immédiatement mise à jour.',
      oldAISFifthLine: 'Êtes-vous sur de vouloir continuer?',
      noMMSI:
        "La navire sélectionné n'a pas de MMSI, choisissez un autre navire ou contactez support@teqplay.nl"
    },
    gdpr: {
      title: 'Confidentialité RiverGuide Binnenvaart',
      privacyBodyFirstLine:
        'Nous voulons que vous compreniez quelles informations nous collectons et utilisons.',
      privacyBodySecondLine:
        "Lorsque vous utilisez RiverGuide, vous nous confiez vos données. Il est de notre responsabilité d'indiquer clairement ce que nous collectons exactement et comment nous l'utilisons pour rendre nos services encore meilleurs pour vous.",
      privacyBodyThirdLine:
        "Lorsque vous utilisez RiverGuide, nous collectons des informations pour fournir et améliorer nos services. Lorsque vous créez un compte RiverGuide, nous stockons les informations de compte que vous fournissez. Il s'agit du nom de votre navire, de votre nom, de votre adresse e-mail et de votre numéro de téléphone. Nous stockons également des informations sur la localisation et les données de votre appareil.",
      privacyBodyFourthLine:
        'Les données sont à la disposition des gestionnaires des voies navigables néerlandaises et de Teqplay. L’objectif de l’utilisation des données est de parvenir à une utilisation fluide, sûre et facile des voies navigables. Vos données sont utilisées pour fournir le service, gérer votre compte utilisateur, vous informer sur les (nouveaux) services et répondre à vos questions.',
      privacyBodyFifthLine:
        "Vous disposez des outils dans RiverGuide pour gérer votre vie privée, accéder à vos données, supprimer votre compte et retirer votre consentement. Après la résiliation de votre compte, les données personnelles seront supprimées et anonymisées. Les données personnelles sont conservées aussi longtemps que vous disposez d'un compte.",
      privacyBodySixthLine:
        "Nous protégeons nos et vos données et applications contre tout accès ou modification non autorisé. C'est pourquoi nous chiffrons bon nombre de nos services avec SSL et évaluons nos pratiques de collecte, de stockage et de traitement des données. Nous limitons l'accès aux données personnelles aux employés et gestionnaires de voies navigables qui ont besoin de ces données pour fournir notre service.",
      privacyBodySeventhLine:
        'Pour toutes les question concernant la confidentialité, veuillez contacter : riverguide@teqplay.nl',
      accept: 'Accepter',
      deny: 'Refuser',
      error:
        'Vous devez accepter les conditions de confidentialité pour utiliser RiverGuide Binnenvaart.',
      view: 'Lire les termes et conditions',
      close: 'Fermer'
    }
  },
  routeSelection: {
    selectRoutePlaceholder: 'Sélectionnez votre itinéraire',
    startingPoint: 'Point de départ',
    viaPoint: "Point d'accès",
    destinationPoint: 'Destination',
    selectDestination: 'Sélectionnez votre destination',
    selectStartingPoint: 'Sélectionnez votre point de départ',
    selectViaPoint: 'Sélectionnez un point de passage',
    options: 'Possibilités',
    calculateRoute: "calculer l'itinéraire",
    arrival: 'Arrivée',
    departure: 'Départ',
    searchOnLocation: 'Rechercher par localisation',
    noOptions: 'Aucun emplacement trouvé',
    searchAdvice: 'Rechercher par emplacement',
    currentLocation: 'Localisation actuelle',
    selectOnMap: 'Sélectionnez un emplacement sur la carte',
    distance: 'Distance',
    departureTime: 'Heure de départ',
    arrivalTime: "Heure d'arrivée",
    viewList: 'Affichage en liste',
    startRoute: 'Commencer',
    saveRoute: 'Sauvegarder',
    cancel: 'Annuler',
    list: 'Itinéraire',
    info: 'Info',
    details: 'Détails',
    map: 'Carte',
    notAllowedDimensions:
      "Cet itinéraire n'est pas navigable avec les dimensions du navire saisies",
    notAllowedBridge:
      'Tous les ponts ne peuvent pas être franchis avec la hauteur du navire indiquée',
    noRoutesFoundWarning: "Aucun itinéraire n'a été trouvé pour cette section",
    noDetailsFoundWarning: 'Aucun détail disponible pour cet article',
    routeDetails: "Informations sur l'itinéraire",
    routeStoredSucces:
      "L'itinéraire a été enregistré. Lors de la sélection des emplacements, vous verrez les itinéraires enregistrés.",
    showSavedRoutes: 'Afficher les itinéraires enregistrés',
    hideSavedRoutes: 'Masquer les itinéraires enregistrés',
    confirmDeleteStoredRoute: 'Êtes-vous sûr de vouloir supprimer l\'itinéraire "%{key}" ?',
    fromTo: '%{de} à %{à}',
    dateSaved: 'Enregistré le',
    saveRouteTitle: "Enregistrer l'itinéraire",
    noNameFilled: "Entrez un nom pour enregistrer l'itinéraire",
    routeNameInput: "Nom de l'itinéraire",
    selectionWarning: 'Sélectionnez un point de départ et une destination',
    pauseRoute: "Suspendre l'itinéraire",
    resumeRoute: 'Itinéraire continu',
    noLocationWarning:
      "Allumez votre GPS pour démarrer l'itinéraire. Dès que votre position est visible sur la carte, l'itinéraire peut commencer !",
    shipDepth: 'Point le moins profond',
    shipHeight: 'Dégagement le plus bas',
    maxWidth: 'Point le plus étroit',
    waitingTime: "Temps d'attente prévu",
    duration: 'Durée totale',
    openInfo: 'Afficher les informations',
    closeInfo: 'Voir la carte',
    reverse: 'Inverser le départ et la destination',
    deleteRoute: "Supprimer l'itinéraire",
    routeOptions: 'Possibilités',
    avoidMajorRoutes: 'Évitez les grands cours d’eau',
    avoidMajorRoutesInfo:
      'Préférez les itinéraires plus petits aux itinéraires de navigation plus grands lorsque cela est possible.',
    staandeMast: 'Itinéraire du mât debout',
    createGeolocation: '%{value} - Rechercher un emplacement',
    speed: 'Vitesse moyenne',
    saveSpeedModal: 'Entrez votre vitesse moyenne',
    saveSpeedError: 'Entrez une valeur valide entre 0 et 50 km/h',
    saveSpeedErrorKnots: 'Entrez une valeur valide entre 0 et 27 nœuds',
    searchingFor: 'Recherche de %{placeName}...',
    notFound: "L'emplacement n'a pas pu être trouvé",
    timeInvalid: "La date et l'heure saisies ne sont pas valides",
    timeInvalidUsingOther: "La date et l'heure saisies ne sont pas valides, %{time} sera utilisé",
    savedRouteNameAlreadyExists:
      "Vous disposez déjà d'un itinéraire enregistré nommé \"%{key}\", modifiez le nom de l'itinéraire pour enregistrer l'itinéraire."
  },
  routeList: {
    itemDetails: {
      cannot_pass_bridge: 'Attention ! Votre vaisseau ne peut pas passer sous ce pont.',
      communication: 'Communication',
      vhf: 'VHF',
      control_times: 'Horaires de fonctionnement',
      yes: 'Oui',
      no: 'non',
      NAP: 'NAP',
      opening: 'Ouverture',
      type: 'Taper',
      opening_width: 'Largeur',
      width: 'Largeur',
      length: 'Longueur',
      closed_height: 'Hauteur fermée',
      opened_height: 'Hauteur ouverte',
      telephone: 'Téléphone',
      startTime: 'Date de début',
      endTime: 'Date de fin',
      lock_chamber: "Bassin d'écluse",
      max_width: 'Max. Largeur (m)',
      threshold_outside: "Seuil en dessous/à l'extérieur",
      threshold_inside: "Seuil au dessus/à l'intérieur",
      address: 'Adresse à terre',
      from: 'Depuis',
      to: "jusqu'à",
      wholeDay: 'Toute la journée',
      name: 'Nom',
      bridge_type: {
        VST: 'Pont fixé',
        DR: 'Pont tournant',
        BC: 'Pont basculant',
        OPH: 'Pont-levis',
        HEF: 'Pont levant',
        DBC: 'DBC'
      },
      bridge_status: {
        open: 'Ce pont est actuellement ouvert',
        closed: 'Ce pont est actuellement fermé.',
        unknown: "L'état actuel de ce pont est inconnu."
      },
      bridge_operating_rules: {
        today: "Aujourd'hui",
        tomorrow: 'demain',
        holiday: 'Jour férié',
        noService: 'Pas de service'
      },
      no_items_found: 'Aucun détail disponible pour cet objet',
      waitingTime: "%{waitingTime} temps d'attente prévu pour le prochain %{objectType}",
      waitingTimeSelected: "%{waitingTime} temps d'attente prévu pour ce %{objectType}",
      note: 'Remarque',
      bridgeOpeningNote: "Remarque sur l'ouverture du pont",
      chamberNote: "Remarque sur le bassin d'écluse",
      waterLevel: "Hauteur d'eau",
      operable: 'Opérable',
      arrival: 'Arrivée',
      departure: 'Le temps qui passe',
      phone: 'Téléphone',
      status: {
        OPEN: 'Ce pont est actuellement ouvert',
        CLOSED: 'Ce pont est actuellement fermé',
        PLANNED: 'Ouverture du pont prévue',
        OPENSHORT: 'Le pont est ouvert',
        CLOSEDSHORT: 'Le pont est fermé',
        PLANNEDSHORT: 'Ouverture du pont prévue'
      },
      plannedOpeningTimes: "Horaires d'ouverture prévus du pont",
      infoBridgeHasToOpen: 'Une ouverture de pont peut être nécessaire pour passer',
      openingRequiredBMS:
        'Une ouverture du pont requise pour passer. Ceci est demandé automatiquement.',
      openingRequestedBMS: "Une ouverture de pont a été demandée pour votre heure d'arrivée prévue",
      openingPlanned: 'Ouverture demandée, le pont ouvrira bientôt',
      openingOpened: 'Ce pont est actuellement ouvert',
      openingRequestPaused:
        "Ouverture du pont obligatoire, reprenez votre itinéraire pour qu'il soit demandé automatiquement",
      bmsOpening: {
        status: 'Statut',
        plannedOpening: "Heure d'ouverture prévue",
        plannedClosing: 'Heure de fermeture prévue',
        direction: 'Direction',
        UPSTREAM: 'En amont',
        DOWNSTREAM: 'En aval',
        certainty: {
          PLANNED: 'Possible',
          EXPECTED: 'Probable',
          APPROVED: 'Presque certain',
          CANCELLED: 'Annulé',
          CURRENT: 'Actuel',
          TERMINATED: 'Réalisé'
        }
      },
      schematicView: 'Représentation schématique',
      heightToWater: 'Toutes les hauteurs utilisent %{referenceLevel} comme référence.'
    },
    objectTypes: {
      BRIDGE: 'pont',
      LOCK: 'écluse',
      START: 'Commencer',
      END: 'fin',
      NOTIFICATION: 'notification',
      WATERMETER: "jauge d'eau",
      TRAFFIC_SIGN: 'panneau de signalisation',
      BOTTLENECK: 'constriction',
      VHFSECTOR: 'Secteur VHF',
      UNKNOWN: 'objet inconnu'
    }
  },
  navigationPage: {
    destination: 'Destination',
    arrival: 'Arrivée',
    next_object: 'Objet suivant',
    no_user_location_warning: 'Aucune localisation GPS trouvée',
    no_notifications: "Il n'y a aucun notification pour le lieu de navigation actuel",
    list_view: 'Itinéraire',
    notifications: 'Notifications',
    ship_speed: 'Vitesse',
    route_paused_warning: "L'itinéraire est en pause",
    stop_route_confirmation: "Êtes-vous sûr de vouloir arrêter l'itinéraire ?",
    pause_route: 'Pause',
    stop_route: "Arrêter l'itinéraire",
    resume_route: "Reprendre l'itinéraire",
    route: 'Itinéraire',
    viewRouteItems: "Ouvrir les informations sur l'itinéraire",
    closeRouteItems: "Fermer les informations sur l'itinéraire",
    routePausedETA: "L'itinéraire est en pause",
    routePausedETAInfo: 'Cliquez ici pour reprendre votre itinéraire',
    moreInformation: "Cliquez pour plus d'informations"
  },
  modalSelectPreviousRoute: {
    question: 'Souhaitez-vous continuer votre itinéraire précédent ?',
    yes: 'Oui',
    no: 'non'
  },
  confirmDimensions: {
    title: 'Confirmer les données pour %{ship}',
    length: 'Longueur',
    width: 'Largeur',
    height: 'Hauteur',
    draught: 'Profondeur de quille',
    meters: 'm',
    noShip: "Aucun détail du navire n'a été trouvé",
    doNotAskMeToday: "Ne demande ça plus aujourd'hui",
    patchError:
      "Une erreur s'est produite lors de l'envoi des nouvelles dimensions de votre navire, les itinéraires sont calculés avec les anciennes dimensions",
    fillInAllFields: 'Remplissez toutes les données de dimension'
  },
  pages: {
    welcome: 'Accueillir',
    navigation: "Planificateur d'itinéraire",
    navigationActive: 'Itinéraire actuel',
    settings: 'Paramètres',
    logout: 'Se déconnecter',
    anonymousLogout: "Retour à l'inscription",
    weather: 'Prévisions météorologiques',
    information: 'Information',
    announcements: 'Notifications électroniques',
    ais: 'Informations AIS',
    privacy: 'Confidentialité'
  },
  map: {
    followButton: 'Centrez vortre navire',
    speedButton: 'Régler la vitesse',
    activelayers: 'Sélectionner des couches de carte',
    category: {
      categoryBerths: "postes d'amarrage",
      categoryFairway: 'Voies navigables',
      categoryElements: 'Éléments',
      maptype: 'Carte'
    },
    limitedRange: 'Couverture limitée',
    limitedRangeMessage:
      'La couche de carte %{layerCode} est uniquement disponible dans les régions suivantes :',
    limitedRangePost:
      'Vous pourrez retrouver ces informations plus tard dans le menu Informations.',
    limitedRangePostPagination: 'Menu > Informations > Informations sur la couche cartographique',
    limitedRangeOneTime: "Ce message ne sera affiché qu'une seule fois.",
    limitedRangePolygonCoverage:
      "Les zones rouges sur la carte ne sont pas couvertes par cette couche ou par d'autres couches cartographiques.",
    dataPlanWarning: 'Utilisation élevée des données possible',
    dataPlanMessage:
      'La couche de carte %{layerCode} contient des informations qui peuvent utiliser beaucoup de trafic de données.  Surveillez votre utilisation des données.',
    dataPlanUsage: '%{layerCode} utilise environ %{data}/%{unit}',
    dataPlanOneTime: "Ce message ne sera affiché qu'une seule fois",
    dataPlanDataUsage: 'Utilisation des données (moyenne)',
    dataPlanSingleTime: 'une fois lorsque la couche de carte est activée',
    dataPlanSwipeUnload: 'par balayage vers la zone de carte déchargée',
    dataPlanSwipe: 'par coup',
    dataPlanIgnore: 'En cliquant sur "%{proceed}", ce message ne sera plus affiché.',
    layers: {
      crossings: 'Nœuds',
      suitable_berths: "Postes d'amarrage appropriées",
      public_berths: "Postes d'amarrage publics",
      terminal_berths: "Postes d'amarrage du terminal",
      fairways: 'Vitesse maximum',
      CEMTClass: 'Classe CEMT',
      fairwayDepth: 'Profondeur de la voie navigable (Frise)',
      recreationalClass: 'Cours de loisirs',
      electricRoutes: 'Voies électriques',
      inlandHarbours: 'Ports de plaisance',
      chargingStations: 'Bornes de recharge',
      bridges: 'Des ponts',
      locks: 'Serrures',
      drinkwaterPoints: "Points d'eau potable",
      kilometrering: 'Kilométrage',
      wasteWaterStations: 'Eau sale sur le rivage',
      AISShips: 'Navires AIS',
      depths: 'Profondeur',
      recentDraught: 'Profondeur (mer des Wadden)',
      waveHeight: 'Hauteur des vagues',
      currents: 'Flux',
      bottlenecks: 'Rétrécissements',
      futureAIS: 'Prévisions AIS Julianakanaal',
      waterHeight: "Hauteur d'eau",
      buoys: 'Bouées',
      waterwaySigns: 'Panneaux routiers',
      weather: 'Temps',
      basMessages: "Avis à l'expédition",
      recreationalRoutes: 'Itinéraires récréatifs',
      sabPoints: 'Points de collecte des déchets',
      carDropoffPoints: 'Points de dépôt des voitures',
      shorePower: 'Alimentation à quai',
      berths: "Postes d'amarrage",
      operatingAreas: "Operating Zones d'exploitation"
    },
    berthsOutside: "Postes d'amarrage à l'extérieur",
    contractDepth: 'Profondeur du contrat',
    levelDepth: 'Profondeur de sondage',
    speed: {
      title: "Régler la vitesse de calcul des heures d'arrivée",
      useShip: 'Utiliser la vitesse du navire',
      useCustom: 'Fixez-vous',
      setRealisticSpeed: 'Réglez une vitesse entre 1 et 50 km/h',
      saved: 'La modification a été enregistrée'
    },
    details: 'détails',
    notification: {
      tooMuchBerths:
        "Trop de postes d'amarrage trouvés, zoomez davantage pour afficher les postes d'amarrage.",
      approachVdjsHeader: 'Jonction VDJS',
      approachingVdjsCrossing:
        'Vous approchez du nœud %{crossingName}. Des itinéraires recommandés de Varen Doe je Samen sont disponibles pour cette jonction.',
      approachingVdjsCrossingShort: 'Vous approchez du nœud %{crossingName}',
      show: 'montrer sur la carte',
      channelNotificationHeader: 'Voie navigable très fréquentée',
      nearingChannelNotification:
        'Vous approchez de %{channelName}. Soyez prudent lorsque vous naviguez, il y a beaucoup de navigation commerciale active ici.',
      zoomInNotification:
        'Effectuez un zoom avant pour voir les couches cartographiques suivantes sur la carte :',
      title: 'Avis sur les voies navigables',
      viaPointHeader: "Vous approchez d'un point de passage sur votre itinéraire",
      endOfRouteHeader: 'Destination atteinte',
      endOfRouteSubtitle: 'Vous êtes à moins de %{radius}m de votre destination',
      clickForMoreInfo: "Cliquez pour plus d'informations...",
      clickForLessInfo: "Cliquez pour fermer plus d'informations"
    },
    chargingStationPopup: {
      chargingPossibilities: 'Options de chargement',
      unavailable: 'Pas disponible',
      connections: 'Connexions'
    },
    measurementDate: 'Date de mesure',
    depthNap: 'Profondeur NAP',
    depthMv: 'Profondeur au milieu de la voie navigable',
    description: 'Description',
    depthLegendTitle: 'Profondeur en mètres (LAT)',
    depthLegendAboveLAT: 'au dessus de LAT',
    depthLegendBelowLAT: 'en dessous de LAT',
    depthPopupTitle: 'Mesure de profondeur',
    depthPopupDate: 'Date',
    waveHeight: {
      name: 'Emplacement',
      height: 'Hauteur',
      timeHeightLastUpdated: 'Dernière mise à jour'
    },
    currents: {
      current: 'Flux entrant',
      kmh: 'km/h',
      ms: 'MS',
      error: "Une erreur inconnue s'est produite, veuillez réessayer.",
      now: '~ Maintenant'
    },
    futureAIS: {
      estimatedPosition: 'Position estimée %{time}',
      icon: 'Position estimée %{time}',
      timeSeconds: 'en %{secondes} secondes',
      timeMinutes: 'en %{minutes} minutes',
      notInsideArea:
        "Vous n'êtes pas près du canal Juliana. Pour utiliser cette couche de carte, vous devez être à proximité du canal Juliana.",
      noFutureTrace: "Aucune prédiction n'a pu être faite pour votre navire dans le canal Juliana.",
      noRoute:
        "La prévision ne peut être faite que si vous disposez d'un itinéraire actif à travers le canal Juliana.",
      extrapolatedTime: 'Temps de positionnement',
      kmh: 'km/h',
      knots: 'nœuds',
      explain: 'Les navires avec un contour rouge ont une position prévue qui peut différer.'
    },
    waterHeight: {
      name: 'Point de mesure',
      height: "Hauteur d'eau",
      timeHeightLastUpdated: 'Dernière mise à jour'
    },
    buoys: {
      buoy: 'Bouée'
    },
    waterwaySigns: {
      sign: 'Panneau de signalisation',
      description: 'Description',
      anchoring: 'Ancré dans'
    },
    crossings: {
      route: 'Itinéraire récréatif',
      sea: 'Route en haute mer',
      moreInformation: "Plus d'information",
      viewMap: 'Voir la carte'
    },
    inlandHarbours: {
      city: 'Ville',
      longStayPlaces: 'Lieux de long séjour',
      shortStayPlaces: 'Lieux de court séjour',
      suppliesFuel: 'Carburant disponible'
    },
    fairways: {
      maxSpeed: 'Vitesse maximum',
      moreInformation: "Plus d'information"
    },
    drinkwaterPoints: {
      markerLand: "Point d'eau potable à terre",
      markerWater: "Point d'eau potable sur l'eau",
      Boat: 'Bateau',
      Land: 'Pays'
    },
    bridges: {
      solid: 'Pont fixe',
      open: 'Pont ouvert',
      closed: 'Pont fermé',
      unknown: "Pont sans capteur d'ouverture/fermeture",
      default: 'Pont mobile',
      opening: 'Ouverture',
      type: 'Type',
      heightClosed: 'Hauteur fermée',
      vhf: 'VHF',
      phoneNumber: 'Téléphone',
      operable: 'Opérable',
      openingTimes: "Horaires d'ouvertures",
      yes: 'Oui',
      no: 'non'
    },
    recreationalRoutes: {
      sailTowardsRoute: 'Accédez à cet itinéraire'
    }
  },
  settings: {
    title: 'Paramètres',
    titleProfile: 'Informations sur le profil',
    titleShip: 'Informations sur le navire',
    myShip: 'Mon navire',
    username: "Nom d'utilisateur",
    usernameUnableChange: 'Ne peut pas être modifié',
    password: 'mot de passe',
    optional: 'Facultatif',
    name: 'Nom',
    email: 'E-mail',
    emailError: "Cette adresse email n'est pas valide",
    mobile: 'numéro de téléphone',
    mobileError: "Ce n'est pas un numéro de téléphone valide",
    shipError: "Ce vaisseau n'a pas de MMSI et ne peut pas être utilisé",
    unsavedChanges: "Vous n'avez pas encore enregistré vos modifications.",
    currentPassword: 'Mot de passe actuel',
    newPassword: 'Nouveau mot de passe',
    repeatNewPassword: 'Répété le nouveau mot de passe',
    changePassword: 'Changer le mot de passe',
    fillInAllFields: 'remplissez tous les champs',
    save: 'Sauvegarder',
    newPasswordNoMatch: 'Les nouveaux mots de passe ne correspondent pas',
    updatedPasswordSuccess: 'votre mot de passe a été changé',
    wrongPassword: 'Mot de passe actuel incorrect',
    weakPassword: 'Votre mot de passe est trop faible',
    titleDeleteAccount: 'Supprimer le profil',
    selectAShip: 'Sélectionnez un bateau',
    updateProfileSuccess: 'Les paramètres ont été mis à jour',
    updateProfileFailed:
      "Quelque chose s'est mal passé lors de l'enregistrement de votre profil, veuillez réessayer plus tard ou contacter support@teqplay.nl",
    noChangesToSave: "Il n'y a aucune modification à enregistrer",
    areYouSureDeleteAccount:
      'Êtes-vous sûr de vouloir supprimer votre profil ? Ça ne peut pas être annulé.',
    removeSuccess: 'Le profil a été supprimé',
    no: 'non',
    yes: 'Oui',
    yesDelete: 'Oui, supprimer',
    cancel: 'Annuler les changements',
    speedUnit: 'Unité de vitesse',
    kmh: 'kilomètres par heure',
    knots: 'nœuds',
    ship: {
      ship: 'Bateau',
      info: 'Les itinéraires sont déterminés en fonction des dimensions de votre navire, lorsque cela est possible.'
    },
    theme: {
      style: "Style d'application",
      theme: 'Thème',
      light: 'lumière',
      dark: 'Foncé',
      listenToDeviceTheme: 'Ajustement automatique',
      listenToDeviceThemeInfo:
        "En fonction des paramètres de votre appareil, l'application détermine si elle affiche un thème clair ou sombre."
    },
    other: 'Autre',
    warnings: 'Avertissements',
    resetWarnings: "Réinitialiser les fenêtres contextuelles d'avertissement",
    resetWarningsInfo:
      "En réinitialisant les fenêtres contextuelles d'avertissement, les avertissements pour les couches cartographiques avec une utilisation potentiellement élevée des données et les couches cartographiques avec une couverture limitée seront à nouveau affichés lorsqu'une couche cartographique est activée.",
    resetWarningsSuccess: 'Tous les avertissements sont réinitialisés',
    selectLanguage: 'Choisir la langue',
    currentLanguage: 'Langue courante',
    passwordTips: {
      title: 'Exigences de mot de passe',
      match: 'Les mots de passe sont les mêmes',
      noMatch: 'les mots de passe ne sont pas les mêmes',
      requirements: 'Exigences de mot de passe',
      requirementsInfo:
        "Nous voulons que votre mot de passe soit aussi sécurisé que possible pour vous protéger ainsi que vos données. C'est pourquoi nous appliquons ces exigences à votre mot de passe.",
      tips: 'Conseils pour un mot de passe sécurisé',
      tipsInfo:
        'Vous pouvez saisir vous-même le contenu de votre mot de passe, mais nous avons également un certain nombre de conseils pour le rendre aussi sécurisé que possible.',
      tipsCasing: 'Utilisez des lettres minuscules et majuscules',
      tipsNumber: 'Utiliser un numéro',
      tipsSpecialCharacters: 'Utilisez un caractère spécial comme !@#$%',
      tipsLength: 'Utilisez 8 caractères ou plus',
      tipsUnique: "Utilisez un mot de passe unique que vous n'utilisez nulle part ailleurs",
      tipsUniqueSubtitle:
        "Ceci n'est bien sûr pas obligatoire et nous ne pouvons même pas connaître ou vérifier en raison de votre mot de passe crypté, mais pour les exigences de sécurité les plus élevées, nous vous recommandons d'utiliser un mot de passe unique.",
      tipsNoPersonalInfo:
        "N'utilisez pas d'informations personnelles facilement devinables dans votre mot de passe",
      tipsStoreSafely: 'Stockez vos mots de passe en toute sécurité',
      tipsStoreSafelySubtitle:
        "Nous vous recommandons d'utiliser un mot de passe ou un gestionnaire de mots de passe. Vous pouvez également noter le mot de passe et bien le cacher, mais c'est moins sécurisé qu'un gestionnaire de mots de passe.",
      allMet: "Écoutez, c'est sûr, bon travail ! 👍",
      abideToAll: 'Votre nouveau mot de passe ne répond pas aux exigences'
    }
  },
  feedbackPage: {
    description: 'Description',
    descriptionText: 'Ajoutez une description de vos commentaires',
    location: 'lieu',
    locationText:
      'Sélectionnez un lieu sur la carte sur lequel portent vos commentaires ou commentaires.',
    photo: 'Photo',
    photoText: "Ajouter une photo pour l'emplacement concerné",
    uploadText: 'Faites glisser ou cliquez pour ajouter une photo',
    sendFeedback: 'Envoyer des commentaires',
    warningMessage: 'Entrez une description de vos commentaires',
    successMessage: 'Votre feedback a été envoyé, merci beaucoup pour votre contribution !',
    errorMessage:
      "Une erreur s'est produite lors de l'envoi de vos commentaires, désolé pour le désagrément occasionné.",
    done: 'Prêt',
    chooseLocation: 'Cliquez ici pour choisir un emplacement sur la carte'
  },
  mobile: {
    navigation_active: 'Navigation active',
    navigation_active_destination: 'Navigation vers %{destination} active',
    offline_title: 'Pas de connection',
    offline_text: "Il n'y a pas de connexion, votre itinéraire sera mis en pause.",
    high_speed_title: 'Tu vas trop vite',
    high_speed_text:
      "Vous naviguez toujours ? Vous roulez à plus de 80 km/h, arrêtez le parcours lorsque vous n'avancez plus dans l'application."
  },
  mapLayers: {
    last_updated: 'Dernière mise à jour',
    past: 'passé',
    meters: 'mètres',
    shipMarker: {
      width: 'Largeur',
      length: 'Longueur',
      heading: 'Cours'
    },
    sabPoints: {
      point: 'Point de collecte des déchets',
      telephoneNumber: 'numéro de téléphone',
      mobileNumber: 'numéro de téléphone portable'
    },
    carDropoffPoints: {
      point: 'Point de dépôt des voitures'
    },
    shorePower: {
      point: 'Prise de courant à quai'
    },
    berthLayer: {
      withCones: 'Avec %{cones} ou plus de cônes',
      free: "Place d'amarrage gratuite",
      occupied: 'Place occupée',
      full: 'Couchette complète',
      unknown: "Place d'amarrage avec occupation inconnue",
      occupationPercentage: 'Occupation',
      occupationDisclaimer: 'Calcul par Blauwe Golf Verbindend',
      ship: 'Bateau',
      shipsInBerth: 'Navires à quai',
      unknownLength: 'Dimensions inconnues',
      interpretationWarning:
        "Les navires avec une icône d'information ont été interprétés, l'emplacement précis et l'orientation peuvent différer.",
      meters: 'mètres',
      width: 'Largeur',
      length: 'Longueur',
      draught: 'Profondeur',
      berthName: "Nom du poste d'amarrage",
      owner: 'Administrateur',
      dangerousGoodsLevel: 'Cônes',
      harbourName: 'Nom du port',
      terminalName: 'Nom du terminal',
      type: 'Taper',
      mooringOptions: "Options d'ammarage",
      mooringServices: "Services d'ammarage",
      mooringFunctions: "Fonctions d'ammarage",
      mooringHourTimeLimit: "Limite de temps d'amarrage",
      shoreConnection: 'Connexion à quai',
      sort: 'Taper',
      viewFotoURL: "Voir la photo du poste d'amarrage",
      berth: "Poste d'ammarage",
      conesAmount: 'Nombre de cônes bleus',
      publicBerth: 'Amarrage public',
      terminalBerth: "Poste d'amarrage",
      suitableError: 'Navire introuvable, impossible de trouver des amarres appropriées',
      shipTypes: {
        shipTypes: 'Type(s) de navire',
        AKR: 'ancre',
        DBKV: 'Barge chargée',
        DBKL: 'Barge vide',
        MVSC: 'Navire cargo à moteur Conteneur/vraquier',
        MVSB: 'Cargo à moteur en vrac',
        ALLE: 'Tous types de navires (point de dépose voiture)',
        DWB: 'Bateau pousseur',
        FDR: 'Feeder',
        MTS: 'Pétrolier à moteur (cargo liquide)',
        SLB: 'Remorqueur',
        BVA: 'Navigation intérieure',
        ZVA: 'Navigation'
      }
    }
  },
  pushNotification: {
    buttonClose: "D'accord"
  },
  months: {
    jan: 'Janvier',
    feb: 'Février',
    mar: 'Mars',
    apr: 'Avril',
    may: 'Mai',
    jun: 'Juin',
    jul: 'Juillet',
    aug: 'Août',
    sep: 'Septembre',
    oct: 'Octobre',
    nov: 'Novembre',
    dec: 'Décembre'
  },
  weather: {
    title: 'Météo',
    windDirection: 'Direction du vent',
    windSpeed: 'Vitesse du vent',
    gusts: 'Des rafales de vent',
    station: 'Station météo',
    speedUnit: 'km/h',
    date: 'Date',
    source: 'Source',
    loading: 'Chargement...',
    noLocationError:
      'Impossible de récupérer les prévisions météorologiques, votre position est inconnue.',
    airPressure: "Pression de l'air",
    airHumidity: 'Humidité',
    sightMeters: 'Visibilité (m)',
    temprature10cm: 'Température',
    rainMiliMetersPerHour: 'Précipitations par heure',
    distanceTo: '~%{distanceTo}km %{heading}'
  },
  information: {
    information: 'Information',
    privacyStatement: 'Déclaration de confidentialité',
    view: 'Afficher la politique de confidentialité',
    dataSources: 'Les sources de données',
    downloadPrivacyStatement: 'Télécharger la déclaration de confidentialité',
    sourceExplainer:
      'RiverGuide dépend des données fournies pour créer les couches cartographiques, ce qui signifie que certaines couches cartographiques ne couvrent pas complètement les Pays-Bas.',
    layerInformation: 'Informations sur la couche cartographique',
    layer: 'Couche de carte',
    source: 'Sources',
    coverageArea: 'Zones de couverture',
    translationNotice: 'Certaines informations sont traduites automatiquement'
  },
  unit: {
    kmh: 'km/h',
    knots: 'boutons'
  },
  time: {
    hourShort: 'h',
    hourFullSingle: 'heure',
    hourFullPlural: 'heures',
    minShort: 'min',
    minFullSingle: 'minute',
    minFullPlural: 'minutes',
    dayShort: 'j',
    daySingle: 'jour',
    dayPlural: 'jours'
  },
  routeSimulator: {
    title: "Simulateur d'itinéraire",
    speed: 'Vitesse',
    distanceLeft: 'Distance',
    eta: 'ETA',
    interval: 'Intervalle',
    start: 'Commencer',
    pause: 'Arrêt',
    simulationStarted: 'Simulation démarrée',
    simulationFinished: "La simulation est terminée, l'itinéraire est terminé",
    polylineError: 'Aucune position trouvée dans la polyligne, simulation terminée.',
    speedError:
      "La vitesse définie est de 0 m/s, impossible d'exécuter la simulation. La vitesse (AUTO) n'est pas prise en charge dans RouteSimulator.",
    startOfRoute: 'Dès le début du parcours',
    startFromIndex: "Depuis l'index %{index} sur l'itinéraire"
  },
  source: {
    area: {
      AT: 'Autriche',
      ATDONAU: 'Danube autrichien',
      BE: 'Belgique',
      BEANR: "Port d'Anvers (BE)",
      BEVLA: 'Flandre (BE)',
      BEWAL: 'Wallonie (BE)',
      BENOO: 'Mer du Nord (BE)',
      DE: 'Allemagne',
      DEWAD: 'Mer des Wadden (DE)',
      FR: 'France',
      EU: "L'Europe ×",
      LU: 'Luxembourg',
      NL: 'les Pays-Bas',
      NLDR: 'Province de Drenthe',
      NLFL: 'Province du Flevoland',
      NLGE: 'Province de Gueldre',
      NLGR: 'Province de Groningue',
      NLLI: 'Province du Limbourg',
      NLNB: 'Province du Brabant-Septentrional',
      NLNH: 'Province de Hollande du Nord',
      NLOV: "Province d'Overijssel",
      NLUT: "Province d'Utrecht",
      NLZL: 'Province de Zélande',
      NLZH: 'Province de Hollande-Méridionale',
      NLIJS: 'IJsselmeer',
      NLMAR: 'Marqueur',
      NLNOO: 'la mer du Nord',
      NLWAD: 'Mer des Wadden',
      NLAMS: "Port d'Amsterdam",
      NLRTM: 'Port de Rotterdam',
      NLMAJOR: 'Principales voies navigables Frenches',
      NLGOUWE: 'Gouwe',
      NLHOLLANDSDIEP: 'Hollands Diep',
      NLJULIANAKANAAL: 'Julianakanaal (Limbourg)',
      NLMERWEDEKANAAL: 'Canal Merwede',
      NLNIEUWEWATERWEG: 'Nouvelle voie navigable',
      NLOOSTERSCHELDE: 'Escaut oriental',
      NLOUDERIJN: 'Vieux Rhin',
      NLRIJN: 'Rhin',
      NLWAAL: 'Waal',
      NLWESTERSCHELDE: 'Escaut occidental'
    }
  },
  phoneNumberButton: {
    title: 'numéro de téléphone'
  },
  notAllowedToPerform: "Vous n'êtes pas autorisé à effectuer cette action.",
  urlDiffers: 'Le compte est enregistré sur un autre serveur %{url}',
  loggedOutDueToURLMismatch:
    "Vous êtes déconnecté car ce compte n'est pas enregistré sur ce serveur.",
  tutorial: {
    routePlanning: {
      title: 'Comment sont déterminés les itinéraires ?',
      intro:
        'Les itinéraires sont déterminés par les dimensions du navire et les options que vous avez spécifiées dans le menu Options.',
      intro2:
        "Si l'itinéraire que vous avez demandé n'est pas navigable, l'application peut afficher un itinéraire mais avec un avertissement rouge, ou l'application peut n'afficher aucun itinéraire. Vérifiez les dimensions de votre navire et vérifiez que la route est effectivement navigable (pour un navire de votre taille).",
      feedback:
        "Si l'itinéraire est navigable mais que l'application ne l'affiche pas ou l'affiche de manière incorrecte, envoyez-nous un e-mail à : riverguide@teqplay.nl",
      yourDimensions: 'Dimensions de votre navire :',
      dimensions:
        "Longueur : %{length}m, Largeur : %{width}m, Hauteur : %{height}m, Tirant d'eau : %{draught}m",
      changeDimensions: 'Modifier les dimensions du navire'
    }
  },
  prominentDisclosure: {
    title: 'Utilisation de la localisation',
    approve: "Je suis d'accord",
    reject: 'je refuse',
    explanation:
      'Cette application utilise les données de localisation pour activer les fonctionnalités suivantes :',
    feature1: "Naviguer le long d'un itinéraire",
    feature2: 'Calculer des itinéraires vers/depuis votre emplacement',
    feature3: "Calculer des heures d'arrivée en navigation",
    feature4: 'Demander automatiquement des ouvertures de pont',
    feature5: 'Afficher votre position sur la carte',
    background:
      "L'application peut également être en mesure d'utiliser ces fonctionnalités lorsqu'elle est en arrière-plan ou fermée.",
    notProperly:
      "Si vous n'autorisez pas ces autorisations, l'application risque de ne pas fonctionner comme prévu."
  },
  shipSearch: {
    placeholder: 'Bateau',
    noName: 'Navire sans nom',
    searchExtended: 'Rechercher un navire par nom, numéro MMSI ou ENI',
    noResults: "Aucun navire n'a été trouvé pour cette recherche",
    lastAISSignal: 'Signal AIS reçu pour la dernière fois à %{time}'
  },
  shipTypes: {
    PILOT_VESSEL: 'Navire pilote',
    SEARCH_AND_RESCUE_VESSEL: 'Navire de recherche et de sauvetage',
    TUG: 'Remorqueur',
    PORT_TENDER: 'Annexe portuaire',
    VESSEL_WITH_ANTI_POLLUTION_FACILITIES: "Navire doté d'installations anti-pollution",
    LAW_ENFORCEMENT_VESSEL: 'Navire de police',
    MEDICAL_TRANSPORT: 'Transport médical',
    NOT_PARTIES_TO_AN_ARMED_CONFLICT: 'Aucune partie au conflit armé',
    PASSENGER: 'Passagers',
    CARGO: 'Cargaison',
    TANKER: 'Pétrolier',
    HIGHSPEED: 'Grande vitesse',
    WING_IN_GROUND: 'Ékranoplan',
    OTHER_TYPE: 'Autre type',
    PASSENGER_HAZCAT_A: 'Passagers HAZMAT A',
    PASSENGER_HAZCAT_B: 'Passagers HAZMAT B',
    PASSENGER_HAZCAT_C: 'Passagers HAZMAT C',
    PASSENGER_HAZCAT_D: 'Passagers HAZMAT D',
    HIGHSPEED_HAZCAT_A: 'HAZMAT A à grande vitesse',
    HIGHSPEED_HAZCAT_B: 'HAZMAT B à grande vitesse',
    HIGHSPEED_HAZCAT_C: 'HAZMAT C à grande vitesse',
    HIGHSPEED_HAZCAT_D: 'HAZMAT D à grande vitesse',
    TANKER_HAZCAT_A: 'Navire-citerne HAZMAT A',
    TANKER_HAZCAT_B: 'Navire-citerne HAZMAT B',
    TANKER_HAZCAT_C: 'Navire-citerne HAZMAT C',
    TANKER_HAZCAT_D: 'Navire-citerne HAZMAT D',
    CARGO_HAZCAT_A: 'Cargo HAZMAT A',
    CARGO_HAZCAT_B: 'Cargo HAZMAT B',
    CARGO_HAZCAT_C: 'Cargo HAZMAT C',
    CARGO_HAZCAT_D: 'Cargo HAZMAT D',
    OTHER_TYPE_HAZCAT_A: 'Autre type HAZMAT A',
    OTHER_TYPE_HAZCAT_B: 'Autre type HAZMAT B',
    OTHER_TYPE_HAZCAT_C: 'Autre type HAZMAT C',
    OTHER_TYPE_HAZCAT_D: 'Autre type HAZMAT D',
    WING_IN_GROUND_HAZCAT_A: 'Ekranoplan HAZMAT A',
    WING_IN_GROUND_HAZCAT_B: 'Ekranoplan HAZMAT B',
    WING_IN_GROUND_HAZCAT_C: 'Ekranoplan HAZMAT C',
    WING_IN_GROUND_HAZCAT_D: 'Ekranoplan HAZMAT D',
    FISHING: 'Bateau de pêche',
    TOWING: 'Remorqueur',
    TOWING_BIG: 'Grand remorqueur',
    DREDGING_UNDERWATER_OPS: 'Dragueur',
    DIVING_OPS: 'Opérations de plongée',
    MILITARY_OPS: 'Des opérations militaires',
    SAILING: 'Voilier',
    PLEASURE_CRAFT: 'Navire de plaisance',
    NOT_AVAILABLE: 'Pas disponible',
    SPARE: 'Réservé',
    UNDEFINED: 'Indéfini',
    BASESTATION: 'Station de base',
    MOTOR_YACHT: 'Yacht à moteur',
    SPEEDBOAT: 'Vedette',
    SAILING_WITH_MOTOR: 'Voilier à moteur',
    SPORT_FISHERMEN_VESSEL: 'Navire de pêche sportive',
    SAILING_MOTOR_BIG: 'Grand voilier à moteur',
    OTHER_RECREATIONAL_VESSEL: 'Autre bateau de plaisance'
  },
  announcements: {
    announcements: 'E-notifications',
    welcome: 'Bienvenue dans les notifications électroniques RiverGuide',
    introduction:
      'Pour utiliser les notifications électroniques dans Riverguide Binnenvaart, votre navire lié nécessite un numéro ENI valide.',
    continue: 'Continuer',
    editShip: 'Ajuster le navire',
    goBack: 'Retourner',
    editHull: 'Ajuster la coque',
    editMainHull: 'Ajuster la coque principale',
    viewHulls: 'Cascos ou coques',
    optional: 'Facultatif',
    eniCheck: {
      checking: "Déterminer l'autorisation d'enregistrement",
      valid: 'Numéro ENI valide',
      missing: "Le numéro ENI est manquant, saisissez-le à l'étape suivante",
      notUsable: 'Vous ne pouvez pas utiliser les notifications électroniques via RiverGuide',
      alreadyLinked: '%{ship} est déjà enregistré sur un autre compte.',
      editShip: 'Personnalisez votre vaisseau ou contactez support@teqplay.nl'
    },
    startNavigation: 'Commencez à naviguer',
    navigationTitle: 'Souhaitez-vous calculer un itinéraire entre ces points ?',
    navigationSuffix:
      'Cela démarre uniquement la fonction de navigation dans RiverGuide, votre voyage peut être enregistré via le bouton "Enregistrer le voyage".',
    isHullAddress: 'Adresse de votre coque',
    hullUpdatedAgo: '%{distance} actualisé',
    validRoute: 'Cet itinéraire est valide',
    editRoute: 'Modifier',
    addRoute: 'Ajouter un itinéraire',
    error: {
      present:
        'Des erreurs ont été détectées avec les informations saisies. Veuillez vérifier à nouveau tous les champs.',
      requiredEmpty: 'Ce champ est obligatoire',
      requiredEmptyNonZero: 'Ce champ est obligatoire avec une valeur supérieure à 0',
      addressRequired:
        "L'adresse est facultative, mais tous les champs, à l'exception du nom de l'entreprise, sont obligatoires si 1 est saisi",
      requiredDateEmpty: 'Préciser la date est obligatoire',
      requiredTimeEmpty: 'Préciser l’heure est obligatoire',
      requiredDateTimeEmpty: "Préciser la date et l'heure est obligatoire",
      requiredRoute: 'Nécessite arrivée + départ',
      minAmount: '%{amount} est inférieur au minimum autorisé %{minAmount}',
      maxAmount: '%{amount} est supérieur au maximum autorisé %{maxAmount}',
      minCargoAmount: 'Au moins 1 cargo doit être lié à un trajet',
      fetchError: "Une erreur s'est produite lors de la récupération des données",
      dangerousMismatch:
        'La cargaison sélectionnée est dangereuse, sélectionnez %{yes} ou modifiez le type de cargaison',
      noTravelId:
        "Il n'y a pas d'ID de voyage lié, l'action ne peut pas être effectuée pour le moment",
      awaitingAction:
        "L'action ne peut pas encore être effectuée car une autre action est en cours",
      exceededHullCargoCapacity:
        'Vous avez dépassé la capacité de chargement totale de votre coque (%{cargoCapacityTn} Tn), toutes les marchandises ensemble pèsent %{totalCargoWeight} Tn',
      hasActiveVoyage:
        'Vous devez terminer votre voyage en cours avant de pouvoir effectuer cette action.',
      hasActiveVoyageNoStart:
        'Vous devez mettre fin à votre voyage en cours avant de pouvoir enregistrer et vous connecter à votre voyage. Vous pouvez sauvegarder votre voyage et vous inscrire plus tard.',
      exceededMaxDraught: "Le tirant d'eau maximum est de 99,99 m",
      invalidDate: "La date n'est pas valide",
      invalidNumberValue: "Le numéro n'est pas valide",
      invalidENILength: 'Le numéro ENI est toujours composé de 8 chiffres.',
      invalidENILengthLeadingZero:
        "Astuce : les numéros ENI des Pays-Bas, de la Belgique, de la France, de l'Allemagne et de la Suisse commencent par 0.",
      cantBeFloat: 'Le nombre ne peut pas être composé de valeurs décimales',
      noMainHull: "Le compte n'a pas de coque principale",
      sameMainHullENI: 'Une coque existe déjà avec cet ENI',
      noEniOnHull: "Casco n'a pas de numéro ENI",
      noMMSIOnHull: "Casco n'a pas de numéro MMSI",
      noHullsFound: "Aucune coque n'a été trouvée",
      noHullSelected: "Vous n'avez pas sélectionné de coque",
      noConvoyTypeSelected: 'Le type d’assemblage est obligatoire pour une coque composite',
      unboundCargo: "La coque reliée à un seul chargement n'est plus liée à l'unité de navigation."
    },
    hullCreation: {
      required: 'Obligé',
      titleCreate: 'Enregistrement complet',
      titleNew: 'Nouvelle coque',
      titleUpdate: 'Mettre à jour la coque',
      editHull: 'Changer de coque',
      editMainHull: 'Changer la coque principale',
      newHull: 'Nouvelle coque',
      explanation:
        'Nous avons réussi à remplir les informations suivantes avec votre numéro ENI. Pour finaliser votre inscription, veuillez remplir les champs suivants :',
      changeEniWarning:
        'Pour modifier votre numéro ENI, ajustez votre navire dans les paramètres de Riverguide Binnenvaart.',
      deleteHull: 'Retirer la coque',
      errorNoENI: "Casco n'a pas ENI et ne peut pas être supprimé",
      warnENIEdited:
        'Votre ENI a été modifié mais pas enregistré, enregistrez ou annulez les modifications pour supprimer le navire.',
      deleteConfirmation: 'Êtes-vous sûr de vouloir supprimer cette coque ?',
      notMatchingMMSI:
        "Le MMSI renseigné (%{filledMMSI}) n'est pas égal au MMSI de votre signal AIS (%{aisMMSI})",
      lockedMMSI:
        'Le MMSI de votre coque principale doit correspondre au MMSI associé à votre compte RiverGuide. Pour changer cela, modifiez les navires dans les paramètres RiverGuide et créez une coque pour ce navire.'
    },
    travelList: {
      title: 'Voyages importés',
      subtitle: 'E-notifications',
      active: 'Actif et planifié',
      inactive: 'Voyages terminés',
      startNavigation: 'Commencez à naviguer',
      newTravel: 'Nouveau voyage',
      status: {
        PLANNED: 'Prévu',
        SENT: 'Envoyé',
        ANSWERED_OK: 'Signalé',
        ANSWERED_OK_CANCELLED: 'Rapport terminé',
        ANSWERED_OK_PAUSED: 'En pause',
        ANSWERED_REJECT: 'Non enregistré',
        PENDING: 'En attente',
        PENDING_PAUSE: 'En attendant une pause de voyage',
        UNKNOWN: 'Inconnu'
      }
    },
    travel: {
      via: 'via',
      new: 'Nouveau voyage',
      edit: 'Ajuster le trajet',
      currentVoyage: 'Voyage en cours',
      view: 'Voir le voyage',
      tripInfo: 'Informations sur le voyage',
      departure: 'Départ',
      arrival: 'Destination',
      viaPoint: 'Point de cheminement',
      exchange: 'Échanger',
      goodsOnBoard: 'Cargaison à bord',
      addGoods: 'Ajouter une cargaison',
      viewGoods: 'Cargaison',
      deleteGoods: 'Supprimer',
      billingInfo: 'Adresse de facturation',
      editForThisTravel: 'Ajuster pour ce voyage',
      save: 'Sauvegarder',
      delete: 'Supprimer le voyage',
      useAsTemplate: 'Utiliser le parcours comme modèle pour un nouveau parcours',
      announce: 'Enregistrer et enregistrer un voyage',
      noGoodsAdded: 'Aucune cargaison ajoutée',
      editAddress: "Cliquez pour remplir l'adresse de facturation",
      editForJourney: "Ajuster l'adresse de facturation pour ce voyage",
      startNavigating: 'Commencez à naviguer',
      startInBics: 'Enregistrer un voyage',
      pauseInBics: 'Suspendre le voyage',
      stopInBics: 'Arrêter le voyage',
      deleteConfirmation: 'Êtes-vous sûr de vouloir supprimer ce voyage ?',
      sentVoyageConfirmation:
        'Les autorités de la voie navigable n’autorisent qu’une seule e-annonce active à la fois. Enregistrer celle-ci pourrait entraîner un rejet. Voulez-vous continuer?',
      started: 'Votre voyage a commencé',
      paused: 'Votre voyage est interrompu',
      stopped: "Votre voyage s'est arrêté",
      planned: 'Déplacements prévus',
      current: 'Voyage en cours',
      noCargo: 'Aucune cargaison à bord',
      resetState: 'Effacer tous les champs',
      noTravels: "Vous n'avez pas encore créé de voyages",
      noAddress: 'Aucune adresse de facturation fournie',
      none: "Pas d'adresse",
      removeLocalAddress: 'Supprimer des adresses',
      deleteAddressesConfirm:
        'Cela supprimera %{amount} %{singularplural} de votre appareil. Cela ne supprime pas les adresses associées aux autres voyages. Es-tu sûr?',
      addressSingular: 'adresse',
      addressPlural: 'adresses',
      newAddress: 'Nouvelle adresse',
      selected: 'Choisi',
      onlyExecptions:
        "utilisable uniquement pour les voyages à travers la Belgique, la France et l'Allemagne",
      variousCargo: 'à différentes cargaisons',
      locked: 'Vous ne pouvez pas modifier votre voyage pour le moment',
      partialEdit: 'Vous ne pouvez modifier que quelques champs de ce voyage.',
      unsavedChanges:
        "Vous n'avez pas enregistré vos modifications. Etes-vous sûr de vouloir y retourner ?",
      unsavedChangesNew:
        "Vous n'avez pas enregistré votre nouveau voyage. Etes-vous sûr de vouloir y retourner ?",
      viaCount: 'via %{viaCount} autres destinations',
      sailingUnity: 'Unité de navigation',
      hullProperties: "Propriétés de l'assemblage",
      addHull: 'Ajouter une coque',
      selectHull: 'Sélectionnez la coque',
      clickToSelectHullForCargo: 'Cliquez pour attribuer une cargaison à une coque',
      clickToAddHull: 'Cliquez pour ajouter une coque',
      willClearSelectedHulls:
        'Etes-vous sûr de vouloir naviguer uniquement avec votre coque principale ?',
      willSwitchToCombinedHulls: 'Cela changera votre type de coque en Composite. Es-tu sûr?',
      unboundWarning: "La coque %{named} n'est plus liée au voyage",
      otherWeight:
        '%{hull} a une capacité de chargement de %{cargoCapacity} Tn, dont ce voyage utilise : %{totalWeight} Tn',
      combinedDimensions: 'Dimensions',
      combinedDimensionsExplain:
        'Vous pouvez ajuster les dimensions de votre unité de navigation sans ajuster les dimensions de votre coque.',
      overrideDimensions: 'Appliquer des cotes',
      overrideWithLargestLength: 'Toutes les coques en longueur',
      overrideWithLargestWidth: 'Toutes les coques en largeur',
      totalWidth: 'Largeur totale',
      totalLength: 'Longueur totale',
      createNewHullSave:
        'Êtes-vous sûr de vouloir quitter cet écran ? Les modifications ne peuvent pas être enregistrées.'
    },
    fields: {
      general: 'Informations générales',
      dimensions: 'Dimensions du navire',
      billingInfo: 'Adresse de facturation',
      eni: 'Numéro ENI',
      eniShort: 'ENI',
      mmsi: 'MMSI',
      name: 'Nom du navire',
      nationality: 'Nationalité',
      clickToSelectCountry: 'Choisissez un pays',
      ownerAddress: 'Adresse de facturation',
      type: 'Type de coque',
      clickToSelectType: 'Sélectionnez un type de coque',
      mode: 'Mode de transport',
      INLAND: 'Navigation intérieure',
      SEA: 'Transport maritime',
      unknown: 'Inconnu',
      lengthM: 'Longueur',
      widthM: 'Largeur',
      maxDraughtM: "Tirant d'eau maximum",
      heightM: 'Hauteur',
      lengthBowToBridgeM: "Longueur d'arc à arc",
      cargoCapacityTn: 'Capacité de chargement',
      deadWeightTn: 'Jauge brute',
      doubleWalled: 'Double paroi',
      callSign: "Signe d'appel",
      placeOfRegistration: "Lieu d'inscription",
      dateOfRegistration: "Date d'inscription",
      fuelType: 'Type de carburant',
      clickToSelectFuelType: 'Sélectionnez un type de carburant',
      NONE: 'Aucun',
      LNG: 'GNL',
      HYDROGEN: 'Hydrogène',
      ELECTRICITY: 'Électricité',
      LOW_SULPHUR_FUEL: 'Carburant à faible teneur en soufre',
      DIESEL: 'Diesel',
      OTHER: 'Autre',
      ownerName: 'Propriétaire',
      meter: 'mètre',
      tonnage: 'Tn',
      m3: 'm³',
      transportType: 'Type de transport',
      addressName: 'Nom',
      givenName: 'Prénom',
      familyName: 'nom de famille',
      street: 'Rue et numéro de maison',
      streetName: 'Rue et numéro de maison',
      postcode: 'Code postal',
      postalCode: 'Code postal',
      city: 'Ville',
      country: 'Pays',
      vatNumber: 'numéro de TVA',
      organisation: "Nom de l'entreprise",
      organisationShort: 'Entreprise',
      organisationHint:
        "Si le nom de l'entreprise est laissé vide, le nom de votre coque sera facturé.",
      organisationPlaceholder: 'c/o %{hullName}',
      optional: 'Facultatif',
      fromLocation: 'Départ',
      toLocation: 'Arrivée',
      firstReportingPoint: 'Premier point de rapport',
      riverguideSelectedRoute: 'Itinéraire',
      startTime: 'Date de départ',
      endTime: "Date d'arrivée",
      crewOnBoard: 'Equipage à bord',
      paxOnBoard: 'Passagers à bord',
      personsOnBoard: 'Personnes à bord',
      weightTn: 'Poids',
      volumeM3: 'Volume',
      dangerousGoods: 'Substances dangereuses',
      dangerous: 'Substances dangereuses',
      hsCode: 'Type de chargement',
      personalName: 'Prénom et nom',
      cones: 'Cônes',
      currentDraught: 'Profondeur actuel',
      residual: 'Vide de',
      hulls: 'Coques',
      singleHull: 'Coque unique',
      multiHull: 'Assemblée',
      convoyType: "Type d'assemblage",
      shipId: 'Coque',
      fullName: 'Nom',
      address: 'Adresse',
      vatNumberShort: 'Numéro de TVA'
    },
    hullSelect: {
      title: 'Vos coques',
      subtitle: "Cliquez sur une coque pour l'ajuster",
      createHull: 'Créer une nouvelle coque',
      mainHull: 'Coque principale'
    }
  },
  bicsFeature: {
    title: 'Nous avons mis à jour les E-Notifications!',
    description:
      'Les nouvelles E-Notifications ont été mises à jour avec un tout nouveau look et des fonctionnalités améliorées, que vous trouverez dans la nouvelle section d’onglets.',
    dontShowAgain: 'Ne plus afficher',
    ok: "D'accord",
    show: 'Afficher les E-Notifications'
  },
  countrySelect: {
    placeholder: 'Choisissez un pays',
    noOptions: 'Aucun pays trouvé pour "%{query}"'
  },
  destinationSelect: {
    placeholder: 'Choisissez une destination',
    placeholderDeparture: 'Sélectionnez un lieu de départ',
    placeholderVia: 'Sélectionnez un point de passage',
    placeholderArrival: "Sélectionnez un lieu d'arrivée",
    noOptions: 'Aucune destination trouvée pour "%{query}"',
    derivedFromProviderId: 'Appliqué automatiquement en fonction du lieu de départ',
    errorNotFound:
      "Impossible d'attribuer le premier point de reporting en fonction de trafficPostId %{pid}",
    warningMax: 'Maximum 5 via points',
    destinationEstimate:
      'Plusieurs destinations ont été trouvées pour %{id}, la destination saisie est une estimation'
  },
  goodsSelect: {
    placeholder: 'Rechercher le type de charge',
    noOptions: 'Aucun type de charge utile trouvé pour "%{query}"',
    vnNr: 'ONU#',
    dangerousClass: 'Classe',
    classification: 'Classification',
    packingGroup: 'V.gr',
    table: 'Tableau',
    showAll: 'Afficher tous les types de marchandises',
    estimateCargo:
      'Plusieurs charges ont été trouvées pour %{id}, le type de charge saisi est une estimation'
  },
  hullSelect: {
    placeholder: 'Sélectionnez la cellule',
    noOptions: 'Aucune cellule trouvée pour "%{query}"',
    unboundShipId:
      "La cargaison était attachée à une coque qui n'est plus liée au voyage. Sélectionnez à nouveau une cellule."
  },
  transportSelect: {
    placeholder: 'Sélectionnez le type de transport',
    BULK: 'En gros',
    CONTAINER: 'Récipient',
    TANKER: 'Pétrolier'
  },
  shipTypeSelect: {
    placeholder: 'Sélectionnez le type de navire',
    estimate:
      'Plusieurs types de navires ont été trouvés pour %{id}, le type de navire saisi est une estimation',
    noOptions: 'Aucun type de navire trouvé pour "%{query}"'
  },
  destinationPresetList: {
    showPresets: 'Afficher les emplacements des itinéraires',
    hidePresets: 'Masquer les emplacements des itinéraires',
    departure: 'Itinéraire du point de départ',
    viaIndex: 'Via le point %{viaIndex}',
    arrival: "Itinéraire du point d'arrivée"
  },
  datetime: {
    clickToAddDate: 'Cliquez pour sélectionner une date',
    clickToAddTime: 'Cliquez pour sélectionner une heure',
    clear: 'rendre vide',
    date: 'Date',
    time: 'Temps',
    selectDate: 'Sélectionner une date',
    selectDateTime: "Sélectionnez la date et l'heure",
    fullFormat: 'jj-mm-aaaa HH:mm',
    dateFormat: 'jj-mm-aaaa'
  },
  aisPage: {
    title: 'Informations AIS',
    staticInfo: 'Informations statiques',
    dynamicInfo: 'Informations dynamiques',
    kmh: 'kilomètres par heure',
    knots: 'boutons',
    lastSignal: 'Le signal AIS a été reçu pour la dernière fois %{time}.',
    lastSignalNotUptoDate:
      "Votre signal AIS n'est pas à jour. Vérifiez votre transpondeur AIS pour vous assurer qu’il est allumé et fonctionne correctement.",
    latestDataFetched: 'Dernier signal AIS connu récupéré avec succès',
    latestDataFetchedError:
      "Une erreur s'est produite lors de la récupération du dernier signal AIS connu. Vérifiez votre connexion Internet et réessayez.",
    refresh: 'Renouveler',
    viewOnMap: 'Voir sur la carte',
    aisVersion: 'Version AIS',
    alternateDraught: 'Profondeur alternative',
    callsign: "Signe d'appel",
    category: 'Catégorie',
    coms: 'COMS',
    combinationType: 'Type de combinaison',
    communicationState: 'État des communications',
    courseOverGround: 'Cours',
    dataTerminalEquipped: 'Terminal de données à bord',
    destination: 'Destination',
    draught: 'Profondeur   ',
    eni: 'ENI',
    eta: 'ETA',
    hazardCode: 'Code de danger',
    height: 'Hauteur',
    id: 'IDENTIFIANT',
    imo: 'OMI',
    imoNumber: 'OMI',
    isSeaVessel: 'Navire de mer',
    lastUpdate: 'Dernière mise à jour',
    latitude: 'Longitude',
    length: 'Longueur',
    loadedStatus: 'Statut chargé',
    longitude: 'Latitude',
    maxDraught: "Tirant d'eau maximum",
    mmsi: 'MMSI',
    name: 'Nom du navire',
    posAccuracyMeters: 'Précision de la position en mètres',
    positionAccuracy: 'Précision du positionnement',
    positionSensorType: 'Type de capteur de position',
    shipType: 'Type de navire',
    specialManeuverIndicator: 'Indicateur de manœuvre spéciale',
    speedOverGround: 'Vitesse',
    source: 'Source',
    status: 'Statut',
    timeLastUpdate: 'Dernière mise à jour',
    rateOfTurn: 'Vitesse de rotation',
    role: 'Rôle',
    usingRaim: 'Utilise RAIM',
    width: 'Largeur',
    visible: 'Visible'
  }
}
export default frenchLocale
