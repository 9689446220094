import * as React from 'react'
import { I18n } from 'react-redux-i18n'
import { useLocation } from 'react-router'

import MenuItem from './menuItem/MenuItem'
import NavigationItem from './navigationItem/NavigationItem'

import { ISelectedRoute, IUserProfile } from '../../../services/TeqplayAPIService/TeqplayApi'

import pjson from '../../../../package.json'
import './Menu.scss'

interface IProps {
  toggleSidebarOpen: () => void
  activePage: string
  shipName: string
  logout: () => void
  navigationRoute: ISelectedRoute | null
  userProfile: IUserProfile | null
}

const Menu: React.FunctionComponent<IProps> = ({
  toggleSidebarOpen,
  activePage,
  shipName,
  logout,
  navigationRoute,
  userProfile
}) => {
  const location = useLocation()
  const username = userProfile?.userName

  const BICSAllowed =
    userProfile?.isAdmin ||
    userProfile?.apiAuthorizationGroups?.includes('BICS') ||
    userProfile?.authorizationGroups?.includes('BICS')

  return (
    <div className="menu">
      <h1 id="menu-title">Menu</h1>

      {username && shipName && (
        <div className="user-info">
          <i className="icon-user" />
          <div className="names">
            <span className="username" id="value-user">
              {username}
            </span>
            <span className="ship" id="value-ship-name">
              {shipName}
            </span>
          </div>
        </div>
      )}

      <div className="menu-items">
        {navigationRoute ? (
          <NavigationItem
            onClick={toggleSidebarOpen}
            url="/navigation"
            activePage={activePage}
            navigationRoute={navigationRoute}
            queryParams={location.search}
          />
        ) : (
          <MenuItem
            onClick={toggleSidebarOpen}
            pageName={I18n.t('pages.navigation')}
            iconClass="icon-map-o"
            url="/route"
            queryParams={location.search}
            activePage={activePage}
          />
        )}
        <MenuItem
          onClick={toggleSidebarOpen}
          pageName={I18n.t('pages.information')}
          iconClass="icon-info"
          url="/information"
          queryParams={location.search}
          activePage={activePage}
        />
        <MenuItem
          onClick={toggleSidebarOpen}
          pageName={I18n.t('pages.ais')}
          iconClass="icon-signal"
          url="/ais"
          queryParams={location.search}
          activePage={activePage}
        />
        {BICSAllowed ? (
          <MenuItem
            onClick={toggleSidebarOpen}
            pageName={I18n.t('pages.announcements')}
            iconClass="icon-mail"
            url="/announcements"
            queryParams={location.search}
            activePage={activePage}
          />
        ) : null}
        <MenuItem
          onClick={toggleSidebarOpen}
          pageName={I18n.t('pages.weather')}
          iconClass="icon-sun"
          url="/weather"
          queryParams={location.search}
          activePage={activePage}
        />
        <MenuItem
          onClick={toggleSidebarOpen}
          pageName={I18n.t('pages.settings')}
          iconClass="icon-cog-1"
          url="/settings"
          queryParams={location.search}
          activePage={activePage}
        />
        <MenuItem
          onClick={toggleSidebarOpen}
          pageName={I18n.t('pages.privacy')}
          iconClass="icon-lock-1"
          url="/privacy"
          queryParams={location.search}
        />
        <MenuItem
          onClick={logout}
          pageName={I18n.t('pages.logout')}
          iconClass="icon-logout"
          url="/login"
          queryParams={location.search}
        />
      </div>
      <div className="footer">
        <div className="app-title">
          <div className="app-logo" />
          <span>{I18n.t('appTitle')}</span>
        </div>
        <div className="version" id="value-app-version">
          {I18n.t('version', { version: pjson.version })}
        </div>
      </div>
    </div>
  )
}

export default Menu
