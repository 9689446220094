import { IUserLocation } from '../@types/types'
import { IShipInfo } from '../services/TeqplayAPIService/TeqplayApi'

// Set inital login state
const initialState: IUserLocation = {
  currentLocation: null
}

export function locationReducer(
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state = initialState,
  action: { type: string; currentLocation: IShipInfo | null }
) {
  switch (action.type) {
    case 'SET_CURRENT_LOCATION': {
      return Object.assign({}, state, { currentLocation: action.currentLocation })
    }

    default:
      return state
  }
}
