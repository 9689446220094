import React, { useState } from 'react'
import { I18n, Translate } from 'react-redux-i18n'
import { isValidDateTime } from '@teqplay/teqplay-ui'

import MobileOrRegularDateTimePicker from '../../../datetime/mobileDateTimePicker/MobileOrRegularDateTimePicker'
import { formatDateTime } from '../../../../utils/dates'

import './TimeOptions.scss'

interface IProps {
  showTimeOptions: boolean
  setDepartureTime: (arrivalDate: Date) => void
  departureTime: number | null
}

const TimeOptions = ({ showTimeOptions, setDepartureTime, departureTime }: IProps) => {
  const [selectedDateTime, setSelectedDateTime] = useState<string | null>(
    departureTime ? new Date(departureTime).toISOString() : null
  )

  if (!showTimeOptions) {
    return null
  }

  return (
    <div className="time-options-wrapper">
      <div className="option-label">
        <Translate value="routeSelection.departure" className="form-label" />
      </div>

      <div className="time-inputs">
        <MobileOrRegularDateTimePicker
          value={selectedDateTime || undefined}
          locale={'nl'}
          dateSeperator="-"
          onDateTimeChange={(iso, inputValue, isValid) =>
            handleSetSelectedDateTime(isValid && iso ? iso : inputValue)
          }
          icon={<i className="icon-calendar" />}
        />

        <button
          type="button"
          className="now-button"
          onClick={setDateAndTimeToNow}
          id="button-set-to-now"
        >
          {I18n.t('now')}
        </button>
      </div>
      {selectedDateTime && !isValidDateTime(selectedDateTime) ? (
        <div className="time-invalid-hint">
          {departureTime
            ? I18n.t('routeSelection.timeInvalidUsingOther', {
                time: formatDateTime(departureTime)
              })
            : I18n.t('routeSelection.timeInvalid')}
        </div>
      ) : null}
    </div>
  )

  function setDateAndTimeToNow() {
    const now = new Date()
    setSelectedDateTime(now.toISOString())
    setDepartureTime(now)
  }

  function handleSetSelectedDateTime(input: string) {
    setSelectedDateTime(input)

    if (isValidDateTime(input)) {
      setDepartureTime(new Date(input))
    }
  }
}

export default TimeOptions
