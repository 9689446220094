import React from 'react'
import { I18n } from 'react-redux-i18n'

import { SelectedItemModalEnum } from '../../../@types/types'

interface IProps {
  selectedItem: SelectedItemModalEnum
}

const ImpassableBanner = ({ selectedItem }: IProps) => {
  if (!selectedItem || selectedItem.type !== 'ROUTE_ITEM') {
    return null
  }

  const { canBePassed } = selectedItem.item

  if (canBePassed === undefined || canBePassed === true) {
    return null
  }

  return (
    <div className="bridge-warning" id="selected-route-item-impassable">
      <i className="icon-attention" />
      {I18n.t('routeList.itemDetails.cannot_pass_bridge')}
    </div>
  )
}

export default ImpassableBanner
