import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { headingDistanceTo, moveTo, toLatLng } from 'geolocation-utils'
import { ControlPosition, divIcon, LatLngLiteral } from 'leaflet'
import flatMap from 'lodash/flatMap'
import { Circle, CircleMarker, GeoJSON, Marker, Polygon } from 'react-leaflet'
import { I18n } from 'react-redux-i18n'

import CollapsableLegendComponent from '../../../controls/CollapsableLegendComponent'

import { detailedCrossings } from '../../../../../assets/vdjs/detailedCrossings'
import routesInformationNL from '../../../../../assets/vdjs/nl_vdjsCrossings.json'
import routesInformationFR from '../../../../../assets/vdjs/fr_vdjsCrossings.json'
import routes1 from '../../../../../assets/vdjs/VDJS_Knooppunten_Adviesroute01.json'
import routes2 from '../../../../../assets/vdjs/VDJS_Knooppunten_Adviesroute02.json'
import routes3 from '../../../../../assets/vdjs/VDJS_Knooppunten_Adviesroute03.json'
import { IRootProps, IRouteInformation } from '../../../../../@types/types'

import './CrossingsLayer.scss'

interface IProps {
  name: string
  checked: boolean
  zoom: number | undefined

  legendPosition?: ControlPosition
  routeColor?: string
  deepSeaColor?: string
  arrowColor?: string
  showDots?: boolean
  dotColor?: string
  dotsZoomLimit?: number
  hideLegendCollapsable?: boolean
  setSelectedItem: (item: IRouteInformation) => void
  disableInteraction?: boolean
}

export interface IDetailedCrossing {
  name: string
  center: LatLngLiteral
  geofenceRadius: number
  routeLines: LatLngLiteral[][]
  routeAreas: LatLngLiteral[][]
  bridges?: LatLngLiteral[][]
  deepSeaRouteAreas?: LatLngLiteral[][]
  trafficLights?: { center: [number, number]; color: 'red' | 'yellow' | 'green' }[]
}

const CrossingsLayer = (props: IProps) => {
  const {
    checked,
    zoom,
    legendPosition,
    routeColor,
    deepSeaColor,
    arrowColor,
    showDots,
    dotColor,
    dotsZoomLimit,
    hideLegendCollapsable
  } = props
  const [crossingsPolygons, setCrossingsPolygons] = useState<JSX.Element[][][] | undefined>()
  const locale = useSelector((s: IRootProps) => s.i18n.locale)
  const routesInformation = locale === 'fr_FR' ? routesInformationFR : routesInformationNL

  const ROUTE_ARROW_DISTANCE = 250
  const ARROW_SIZE = 50
  const ARROW_ANGLE = 20
  const COLOR_DEEP_SEA_AREA = '#b28886'
  const COLOR_ROUTE_AREA = '#75bfdd'
  const COLOR_ROUTE_ARROW = '#80cbea'
  const COLOR_TRAFFIC_LIGHTS = {
    green: '#33cc33',
    yellow: '#ffd700',
    red: '#ff0000'
  }

  const typedCrossings = detailedCrossings as IDetailedCrossing[]
  const interactiveLayer = props.disableInteraction ? false : true

  useEffect(() => {
    if (!crossingsPolygons && checked) {
      setCrossingsInState()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checked])

  if (!checked || !crossingsPolygons) {
    return null
  }

  return (
    <React.Fragment>
      {getLegendComponent()}
      <GeoJSON
        data={routes1 as GeoJSON.GeoJsonObject}
        style={routesStyle()}
        interactive={interactiveLayer}
      />
      <GeoJSON
        data={routes2 as GeoJSON.GeoJsonObject}
        style={routesStyle()}
        interactive={interactiveLayer}
      />
      <GeoJSON
        data={routes3 as GeoJSON.GeoJsonObject}
        style={routesStyle()}
        interactive={interactiveLayer}
      />
      {routesInformation.features.map((ri, index) => renderMarker(ri, index, interactiveLayer))}
      {crossingsPolygons}
    </React.Fragment>
  )

  function setCrossingsInState() {
    const mappedCrossings = typedCrossings.map((crossing, index) =>
      renderSingleCrossing(crossing, index)
    )
    setCrossingsPolygons(mappedCrossings)
  }

  function renderSingleCrossing(crossing: IDetailedCrossing, index: number) {
    return [
      renderDeepSeaRouteAreas(crossing.deepSeaRouteAreas || [], index, getDeepSeaColor()),
      renderRouteAreas(crossing.routeAreas, index, getRouteColor()),
      renderRouteArrows(crossing.routeLines, index, getArrowColor()),
      renderBridges(crossing.bridges || [], index),
      renderTrafficLights(crossing.trafficLights || [], index)
    ]
  }

  function routesStyle() {
    return {
      weight: 1,
      color: getRouteColor(),
      fillColor: getRouteColor(),
      fillOpacity: 1
    }
  }

  function getRouteColor() {
    return routeColor || COLOR_ROUTE_AREA
  }

  function getDeepSeaColor() {
    return deepSeaColor || COLOR_DEEP_SEA_AREA
  }

  function getArrowColor() {
    return arrowColor || COLOR_ROUTE_ARROW
  }

  function renderMarker(ri: IRouteInformation, index: number, disableInteractive?: boolean) {
    //  There are 2 different rendering types: dots and markers.
    //  When the dots are not externally clustered, dots are not supposed to be shown or if the zoom is higher or equal as the zoom limit
    //  Render markers clustered internally
    if (!showDots || (zoom || 12) >= (dotsZoomLimit || 14)) {
      const markerIcon = divIcon({
        className: 'div-icon-marker',
        html: '<div class="div-icon crossing-icon"></div>',
        iconAnchor: [20, 20],
        iconSize: [40, 40]
      })
      return (
        <Marker
          key={index}
          position={[ri.geometry.coordinates[1], ri.geometry.coordinates[0]]}
          icon={markerIcon}
          onClick={!disableInteractive ? undefined : () => props.setSelectedItem(ri)}
          interactive={disableInteractive}
        />
      )
      //  When dots are supposed to be shown and the zoom is not higher than the dots zoom limit
      //  Render CircleMarkers
    } else if (showDots && (zoom || 12) < (dotsZoomLimit || 14)) {
      return (
        <CircleMarker
          key={index}
          center={[ri.geometry.coordinates[1], ri.geometry.coordinates[0]]}
          radius={2}
          color={dotColor || '#000'}
          onClick={!disableInteractive ? undefined : () => props.setSelectedItem(ri)}
          interactive={disableInteractive}
        />
      )
    } else {
      return null
    }
  }

  function renderDeepSeaRouteAreas(
    deepSeaRouteAreas: LatLngLiteral[][],
    crossingIndex: number,
    color: string
  ) {
    return deepSeaRouteAreas.map((deepSeaRouteArea, index) => (
      <Polygon
        key={'deep-sea-route-' + crossingIndex + '-' + index}
        color={color}
        stroke={false}
        fill={true}
        fillOpacity={1}
        positions={deepSeaRouteArea}
      />
    ))
  }

  function renderRouteAreas(routeAreas: LatLngLiteral[][], crossingIndex: number, color: string) {
    return routeAreas.map((routeArea, index) => (
      <Polygon
        key={'route-area-' + crossingIndex + '-' + index}
        color={color}
        stroke={false}
        fill={true}
        fillOpacity={1}
        positions={routeArea}
      />
    ))
  }

  function renderRouteArrows(routeLines: LatLngLiteral[][], crossingIndex: number, color: string) {
    return flatMap(routeLines, (routeLine, routeIndex) =>
      calculateArrowPositions(routeLine, 100).map((arrow, arrowIndex) => (
        <Polygon
          key={'arrow-' + crossingIndex + '-' + routeIndex + '-' + arrowIndex}
          color={color}
          stroke={false}
          fill={true}
          fillOpacity={1}
          positions={arrow}
        />
      ))
    )
  }

  function renderBridges(bridges: LatLngLiteral[][], crossingIndex: number) {
    return bridges.map((polygon, index) => (
      <Polygon
        key={'bridge-' + crossingIndex + '-' + index}
        color={'white'}
        stroke={false}
        fill={true}
        fillOpacity={1}
        positions={polygon}
      />
    ))
  }

  function renderTrafficLights(
    trafficLights: IDetailedCrossing['trafficLights'],
    crossingIndex: number
  ) {
    return (trafficLights || []).map((trafficLight, index) => (
      <Circle
        key={'traffic-light' + crossingIndex + '-' + index}
        center={trafficLight.center}
        radius={10}
        color={COLOR_TRAFFIC_LIGHTS[trafficLight.color] || 'black'}
        stroke={false}
        fill={true}
        fillOpacity={1}
      />
    ))
  }

  function calculateArrowPositions(routeLine: LatLngLiteral[], initialOffset: number) {
    const arrows = []
    let arrowOffset = initialOffset // meter

    let index = 0
    while (index < routeLine.length - 1) {
      const startPoint = routeLine[index]
      const endPoint = routeLine[index + 1]
      const headingDistance = headingDistanceTo(startPoint, endPoint)
      const heading = headingDistance.heading
      const lineLength = headingDistance.distance

      while (arrowOffset < lineLength) {
        const arrowPoint = moveTo(startPoint, { heading, distance: arrowOffset })
        const arrowPointLeft = moveTo(arrowPoint, {
          heading: heading + 180 + ARROW_ANGLE,
          distance: ARROW_SIZE
        })
        const arrowPointRight = moveTo(arrowPoint, {
          heading: heading + 180 - ARROW_ANGLE,
          distance: ARROW_SIZE
        })

        const arrow = [toLatLng(arrowPointLeft), toLatLng(arrowPoint), toLatLng(arrowPointRight)]
        arrows.push(arrow)

        arrowOffset += ROUTE_ARROW_DISTANCE
      }

      arrowOffset -= lineLength
      index = index + 1
    }

    return arrows
  }

  function getLegendComponent() {
    const rc = getRouteColor()
    const dsc = getDeepSeaColor()

    return (
      <CollapsableLegendComponent
        icon={<span className="icon-map-pin" />}
        hideCollapsible={hideLegendCollapsable}
        position={legendPosition || 'topright'}
        id={props.name}
      >
        <div className="item-legend">
          <div className="name">{I18n.t('map.crossings.route')}</div>
          <div className="icon">
            <div
              className={'polygon'}
              style={{
                borderColor: 'rgb(41, 62, 78)',
                backgroundColor: rc || 'rgba(41, 62, 78, 0.2)'
              }}
            />
          </div>
        </div>
        <div className="item-legend">
          <div className="name">{I18n.t('map.crossings.sea')}</div>
          <div className="icon">
            <div
              className={'polygon'}
              style={{
                borderColor: 'rgb(41, 62, 78)',
                backgroundColor: dsc || 'rgba(41, 62, 78, 0.2)'
              }}
            />
          </div>
        </div>
      </CollapsableLegendComponent>
    )
  }
}

export default CrossingsLayer
