import isEqual from 'lodash/isEqual'
import React, { useEffect, useState } from 'react'
import { I18n } from 'react-redux-i18n'

import DefaultTransition from '../../components/shared/defaultTransition/DefaultTransition'
import TeqplayApiService from '../../services/TeqplayAPIService/TeqplayApiService'
import TitleComponent from '../../components/titleComponent/TitleComponent'

import { headingDistanceTo } from 'geolocation-utils'
import { usePrevious } from '../../hooks/usePrevious'
import { useAnalytics } from '../../services/AnalyticsWrapper/AnalyticsWrapper'
import { IShipInfo, IWeatherStation } from '../../services/TeqplayAPIService/TeqplayApi'
import { formatDateTime } from '../../utils/dates'
import { degreesToCardinals } from '../../utils/general'

import './WeatherPage.scss'

interface IProps {
  teqplayApiService: TeqplayApiService
  location: IShipInfo | null
}

const WeatherPage = (props: IProps) => {
  const { teqplayApiService, location } = props
  const analytics = useAnalytics('WeatherPage')

  const userLocation: Partial<IShipInfo> = location || {
    posAccuracyMeters: '1',
    speedOverGround: 0.1,
    courseOverGround: 1,
    location: {
      latitude: 52.101792,
      longitude: 5.177896,
      type: 'MOCKED',
      coordinates: [5.177896, 52.101792]
    },
    timeLastUpdate: new Date().valueOf()
  }

  const { forecast, error, loading } = useWeather(teqplayApiService, userLocation)

  const forecastLocation =
    forecast?.latDegrees && forecast.lonDegrees
      ? { lat: parseFloat(forecast.latDegrees), lng: parseFloat(forecast.lonDegrees) }
      : undefined
  const windDirection = forecast?.windDirectionDegrees
    ? (parseFloat(forecast.windDirectionDegrees) + 180) % 360
    : undefined
  const distanceToMeasuringPoint =
    userLocation && forecastLocation
      ? headingDistanceTo(
          {
            lat: userLocation?.location?.latitude || 0,
            lng: userLocation?.location?.longitude || 0
          },
          forecastLocation
        )
      : undefined

  React.useEffect(() => {
    analytics.setScreen('weather')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <DefaultTransition active={true} offset={10} slideFrom="top">
      {styleProps => (
        <div className="main-wrapper weather-page" style={styleProps}>
          <TitleComponent title={I18n.t('weather.title')} />
          <h2>
            {I18n.t('weather.title')}
            {loading && <i className="icon-circle-notch animate-spin" />}
          </h2>
          {error && <div className="page-block error">{error}</div>}
          {forecast && (
            <div className="page-block weather">
              {forecast.windDirection && forecast.windDirection !== '-' && (
                <div className="row">
                  <span className="label">{I18n.t('weather.windDirection')}</span>
                  <span className="value" id="weather-wind">
                    {forecast.windDirection}
                    <div
                      className="wind-arrow"
                      style={{ transform: `rotate(${windDirection}deg)` }}
                    >
                      <i className="icon-user-location" />
                    </div>
                  </span>
                </div>
              )}
              {forecast.windSpeedBeaufort && forecast.windSpeedBeaufort !== '-' && (
                <div className="row">
                  <span className="label">{I18n.t('weather.windSpeed')}</span>
                  <span className="value" id="weather-wind-speed">
                    {forecast.windSpeedBeaufort} beaufort
                  </span>
                </div>
              )}
              {forecast.gustsMetersPerSecond && forecast.gustsMetersPerSecond !== '-' && (
                <div className="row">
                  <span className="label">{I18n.t('weather.gusts')}</span>
                  <span className="value" id="weather-wind-gusts">
                    {Math.round(parseFloat(forecast.gustsMetersPerSecond) * 3.6)}{' '}
                    {I18n.t('weather.speedUnit')}
                  </span>
                </div>
              )}
              {forecast.windSpeedBeaufort && forecast.windSpeedBeaufort !== '-' && (
                <div className="row">
                  <span className="label">{I18n.t('weather.airPressure')}</span>
                  <span className="value" id="weather-air-pressure">
                    {forecast.airPressure} mBar
                  </span>
                </div>
              )}
              {forecast.airHumidity && forecast.airHumidity !== '-' && (
                <div className="row">
                  <span className="label">{I18n.t('weather.airHumidity')}</span>
                  <span className="value" id="weather-air-humidity">
                    {forecast.airHumidity} %
                  </span>
                </div>
              )}
              {forecast.stationName && forecast.stationName !== '-' && (
                <React.Fragment>
                  <div className="row">
                    <span className="label">{I18n.t('weather.station')}</span>
                    <span className="value" id="weather-station-name">
                      {forecast.stationName}&nbsp;
                    </span>
                  </div>
                  <div className="row">
                    <span className="label" />
                    <span className="value bold" id="weather-relative-direction">
                      (
                      {distanceToMeasuringPoint &&
                        I18n.t('weather.distanceTo', {
                          distanceTo: Math.ceil(distanceToMeasuringPoint.distance / 1000),
                          heading: degreesToCardinals(distanceToMeasuringPoint.heading)
                        })}
                      )
                    </span>
                  </div>
                </React.Fragment>
              )}

              <div className="weather-footer">
                {I18n.t('weather.date')}:{' '}
                {forecast.retrievedDateTime ? formatDateTime(forecast.retrievedDateTime) : ''}
                <br />
                {I18n.t('weather.source')}: Buienradar.nl
              </div>
            </div>
          )}
        </div>
      )}
    </DefaultTransition>
  )
}

const useWeather = (teqplayApiService: TeqplayApiService, location: Partial<IShipInfo> | null) => {
  const [forecast, setForecast] = useState<IWeatherStation>()
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<string | undefined>()

  const previousLocation = usePrevious(location?.location)

  async function fetchWeather(api: TeqplayApiService, loc: Partial<IShipInfo> | null) {
    if (!loc || !loc?.location) {
      setError('weather.noLocationError')
    } else {
      try {
        setLoading(true)
        setError(undefined)
        const weather = await api.fetchWeather(loc.location.latitude, loc.location.longitude)

        setLoading(false)
        setForecast(weather)
      } catch (err) {
        setLoading(false)
        setError(err.message)
      }
    }
  }

  useEffect(() => {
    // Only fetches when the location changes
    if (!isEqual(location?.location, previousLocation)) {
      fetchWeather(teqplayApiService, location)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  return { forecast, loading, error }
}

export default WeatherPage
