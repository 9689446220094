import get from 'lodash/get'
import Popup from 'react-popup'
import { I18n } from 'react-redux-i18n'
import ReactGA from 'react-ga4'

import TeqplayApiService from '../services/TeqplayAPIService/TeqplayApiService'

import { sendExceptionToSentry } from './sentry'
import { getEnvironment, GOOGLE_ANALYTICS_ID } from './constants'

export function initAnalytics(teqplayApiService: TeqplayApiService) {
  if (getEnvironment() === 'production') {
    if (window.cordova) {
      if (window.FirebasePlugin) {
        initFirebase(teqplayApiService)
      } else {
        console.warn('[ANALYTICS] Firebase was not initialized')
      }
    } else {
      ReactGA.initialize(GOOGLE_ANALYTICS_ID)
    }
    ReactGA.send({
      hitType: 'pageview',
      page: window.location.pathname + window.location.search,
      title: window.document.title
    })
    console.info('[ANALYTICS] Initialized')
  } else {
    console.warn('[ANALYTICS] Not running because this is not a production environment')
  }
}

/**
 * Initialize Firebase for the push notifications
 */
export function initFirebase(teqplayApiService: TeqplayApiService) {
  // Ask user for permission to send notifications (if not yet asked)
  window.FirebasePlugin.hasPermission((hasPermission: boolean) => {
    if (hasPermission) {
      console.info('[fcmUtils.ts] initFirebase Permission already granted')
    } else {
      window.FirebasePlugin.grantPermission(
        (value: boolean) =>
          value
            ? console.info('[fcmUtils.ts] initFirebase FirebasePlugin permission granted.')
            : console.error('[fcmUtils.ts] initFirebase FirebasePlugin permission NOT granted.'),
        (err: string) =>
          sendExceptionToSentry(
            new Error(`[fcmUtils.ts] ERROR initFirebase FirebasePlugin permissions error: ${err}`)
          )
      )
    }
  })

  // Send a new/updated token to the backend
  window.FirebasePlugin.onTokenRefresh(
    (fcmId: string) => setFirebaseToken(teqplayApiService, fcmId),
    (error: string) => console.error('error in function initFirebase ' + error)
  )

  // On opening a notificaton
  window.FirebasePlugin.onMessageReceived(
    (notification: any) => {
      try {
        // tap = 0 application already open
        // tap = 1 opened app on notification click
        // if (!notification.tap) {
        const title = notification.title ? notification.title : get(notification, 'aps.alert.title')
        const body = notification.body ? notification.body : get(notification, 'aps.alert.body')

        if (title && body) {
          Popup.create({
            title,
            content: body,
            buttons: {
              right: [
                {
                  text: I18n.t('pushNotification.buttonClose'),
                  className: 'button large primary',
                  action() {
                    Popup.close()
                  }
                }
              ]
            }
          })
        }
      } catch (error) {
        console.error('An error occurred while trying to show the notification', error)
      }
    },
    (error: string) => console.error(`Error inside onMessageReceived: "${error}"`)
  )
}

/**
 * Initialize Firebase for the push notifications
 */
export function getFirebaseTokenOnce(teqplayApiService: TeqplayApiService) {
  if (!window.cordova || !window.FirebasePlugin) return

  // Ask user for permission to send notifications
  window.FirebasePlugin.hasPermission(hasPermission => {
    if (!hasPermission) {
      window.FirebasePlugin.grantPermission(
        (value: boolean) =>
          value
            ? console.info('[fcmUtils.ts] getFirebaseTokenOnce FirebasePlugin permission granted.')
            : console.error(
                '[fcmUtils.ts] getFirebaseTokenOnce FirebasePlugin permission NOT granted.'
              ),
        (err: string) =>
          sendExceptionToSentry(
            new Error(
              `[fcmUtils.ts] ERROR getFirebaseTokenOnce FirebasePlugin permissions error: "${err}"`
            )
          )
      )
    } else {
      console.info('[fcmUtils.ts] getFirebaseTokenOnce Permission already granted')
    }
  })

  // Send a new/updated token to the backend
  window.FirebasePlugin.getToken(
    (fcmId: string) => setFirebaseToken(teqplayApiService, fcmId),
    (error: string) =>
      sendExceptionToSentry(new Error('error in function getFirebaseTokenOnce ' + error))
  )
}

/**
 * Push the new firebase token to the backend
 */
async function setFirebaseToken(teqplayApiService: TeqplayApiService, fcmId: string) {
  try {
    await teqplayApiService.setUserFCM(fcmId)
  } catch (err) {
    console.error(err)
    return err
  }
}
