import React, { InputHTMLAttributes } from 'react'
import './InputField.scss'

interface IProps {
  name: string
  label?: string
  required?: boolean
  id?: string
}

const InputField: React.FunctionComponent<IProps & InputHTMLAttributes<any>> = props => {
  return (
    <div className="input-wrapper">
      {props.label && (
        <label htmlFor={props.name}>
          {props.label}
          {props.required ? <span>*</span> : ''}
        </label>
      )}
      <input
        {...props}
        className="textfield"
        name={props.name}
        id={props.id || `input-${props.name}`.replace(/ /g, '-').toLowerCase()}
      />
    </div>
  )
}

// const Input = styled.input`
//   width: 100%;
//   max-width: 300px;
//   border: none;
//   border-bottom: 1px solid ${p => p.theme.color.border.dark};
//   padding: 8px 0px;
//   background: transparent;
//   margin-bottom: 10px;
//   font-size: ${p => p.theme.font.size.normal};

//   &:active, &:hover, &:focus {
//     outline: none;
//     border-bottom-color: ${p => p.theme.color.main}
//   }
// `

export default InputField
