import { divIcon } from 'leaflet'
import * as React from 'react'
import { Marker } from 'react-leaflet'
import { ILatLng } from '../../../services/TeqplayAPIService/TeqplayApi'

interface IProps {
  location: ILatLng
  courseOverGround: number | null
}

const UserShipMarker: React.FunctionComponent<IProps> = props => {
  const shipHeading = props.courseOverGround ? props.courseOverGround : 40
  const location = props.location
  const shipDivIcon = divIcon({
    className: '',
    html: `<div class='ship-user-icon gray' style="transform: rotate(${
      shipHeading + 'deg'
    }"><span class=${'icon-user-location'}></span></div>`,
    iconAnchor: [20, 20]
  })

  if (!location) {
    return null
  }
  return (
    <Marker
      position={[location.lat, location.lng]}
      icon={shipDivIcon}
      className="top-icon"
      zIndexOffset={1000}
    />
  )
}

export default UserShipMarker
