import { divIcon } from 'leaflet'
import React from 'react'

import TeqplayApiService from '../../../../../services/TeqplayAPIService/TeqplayApiService'
import DefaultMarkersLayer from '../defaultMarkerLayer/DefaultMarkerLayer'

import './WavesLayer.scss'

interface IProps {
  name: string
  bounds: L.LatLngBounds | undefined
  checked: boolean
  teqplayApiService: TeqplayApiService
  zoom: number | undefined
  setSelectedItem: (item: IWaveObject) => void
  disableInteraction?: boolean
}

export interface IWaveObject {
  _id: string
  name: string
  height?: number
  timeHeightLastUpdated?: number
  linkToGraphData?: string
  location: {
    type: 'Point'
    coordinates: [number, number]
  }
  singleLocation: {
    type: 'Point'
    coordinates: [number, number]
  }
  locatableType: 'WATER_INFO'
}

const WavesLayer = (props: IProps) => {
  return (
    <DefaultMarkersLayer<IWaveObject>
      fetchCall={(bounds?: L.LatLngBounds) =>
        props.teqplayApiService.getWaveMeasurementsInBounds(bounds)
      }
      filterData={results => results.filter(x => x.height && x.height <= 3000)} // highest wave ever in NL was 25m, + 5m lenience * 100cm = 3000cm
      fontIconClass="icon-wave"
      backgroundColor="#2e6771"
      onClick={props.disableInteraction ? undefined : props.setSelectedItem}
      determineDivIcon={wave => {
        const heightClass = wave.height
          ? wave.height <= 100
            ? 'calm'
            : wave.height <= 200
            ? 'low'
            : wave.height <= 300
            ? 'medium'
            : wave.height <= 400
            ? 'high'
            : 'extreme'
          : 'unknown'

        return divIcon({
          className: 'div-icon-marker',
          html: `<div class="div-icon wave-icon height-${heightClass}">
            <span class="popup-wave">${wave.height || '?'} cm</span>
            <div class="wave-symbol"></div>
          </div>`,
          iconAnchor: [36, 10],
          iconSize: [72, 20]
        })
      }}
      {...props}
    />
  )
}

// Unused hook but ready to be used
// function useWaves(
//   apiService: TeqplayApiService,
//   bounds: L.LatLngBounds | undefined,
//   isChecked?: boolean,
// ) {
//   const [waves, setWaves] = useState<IWaveObject[] | null>(null)
//   const [error, setError] = useState<any | null>(null)

//   useEffect(() => {
//     async function fetchDepths() {
//       if (bounds) {
//         try {
//           setError(null)
//           const fetchedWaveMeasurements = await apiService.getWaveMeasurementsInBounds(bounds)
//           setWaves(fetchedWaveMeasurements)
//         } catch (error) {
//           setError(error)
//           throw error
//         }
//       }
//     }

//     // Double inversion to prevent any null or undefined values
//     if (!!isChecked === true) {
//       fetchDepths()
//     }
//   }, [isChecked, bounds])

//   return waves
// }

export default WavesLayer
