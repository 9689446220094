import { isValidDate } from '@teqplay/teqplay-ui'
import React, { useEffect, useState } from 'react'
import { I18n } from 'react-redux-i18n'
import { toast } from 'react-toastify'
import { Link } from 'react-router-dom'

import BICSENINumberInput from '../bicsENINumberInput/BICSENINumberInput'
import BICSFuelTypeSelector from '../bicsFuelTypeSelector/BICSFuelTypeSelector'
import BICSGenericInputRow from '../bicsGenericInputRow/BICSGenericInputRow'
import BICSShipTypeSelector from '../bicsShipTypeSelector/BICSShipTypeSelector'
import CountrySelector from '../../countrySelector/CountrySelector'
import SwitchButton from '../../shared/switchButton/SwitchButton'
import TeqplayApiService from '../../../services/TeqplayAPIService/TeqplayApiService'
import TitleComponent from '../../titleComponent/TitleComponent'
import MobileOrRegularDatePicker from '../../datetime/mobileDatePicker/MobileOrRegularDatePicker'
import CustomNumberInput from '../../customNumberInput/CustomNumberInput'

import { BICSHullExtraErrorKeys, ICustomError } from '../../../@types/types'
import { useSessionStorage } from '../../../hooks/useSessionStorage'
import { useAnalytics } from '../../../services/AnalyticsWrapper/AnalyticsWrapper'
import {
  BicsAddress,
  BicsHull,
  BicsShipType,
  IShipInfo
} from '../../../services/TeqplayAPIService/TeqplayApi'
import { activeClass, filterUnique } from '../../../utils/general'
import { sendExceptionToSentry } from '../../../utils/sentry'
import { confirmPopup } from '../../shared/confirmPopup/ConfirmPopup'

import './BICSHullList.scss'

interface IProps {
  currentLocation: IShipInfo | null
  mainHull: BicsHull | undefined
  hulls: BicsHull[] | null
  refreshHulls: () => Promise<void>
  teqplayAPIService: TeqplayApiService
  selectedHullID?: string
  setSelectedHullID: (id: string | undefined) => void
}

const BICSHullList = ({
  currentLocation,
  mainHull,
  hulls,
  refreshHulls,
  teqplayAPIService,
  selectedHullID,
  setSelectedHullID
}: IProps) => {
  const analytics = useAnalytics('BICSHullList')

  // Match the hull if it exists from the list of hulls

  const hull =
    selectedHullID !== 'new' && selectedHullID
      ? hulls?.find(
          c =>
            c.eni === selectedHullID.split('-')[0] &&
            (selectedHullID.split('-')[1] === 'undefined' ||
              c.mmsi === selectedHullID.split('-')[1])
        )
      : null

  const isMainHull =
    selectedHullID === 'create' || selectedHullID
      ? selectedHullID.split('-')[1] === mainHull?.mmsi
      : false
  const autoFill = selectedHullID === 'create' ? currentLocation : null

  const uniqueKey = (suffix: string) => `BICS-${selectedHullID}-${suffix}`
  const [eni, setEni] = useState<undefined | string>(autoFill?.eni || '')
  const [mmsi, setMMSI] = useState<number | undefined | string>(
    autoFill?.mmsi || currentLocation?.mmsi || ''
  )
  const [name, setName] = useState<string>(autoFill?.name || '')
  const [mode, setMode] = useSessionStorage<BicsHull['mode'] | undefined>(
    uniqueKey('mode'),
    'INLAND'
  )
  const [type, setType] = useSessionStorage<BicsShipType | null | undefined>(
    uniqueKey('type'),
    undefined
  )
  const [fuelType, setFuelType] = useSessionStorage<BicsHull['fuelType'] | null | undefined>(
    uniqueKey('fuelType'),
    null
  )
  const [doubleWalled, setDoubleWalled] = useSessionStorage<boolean | undefined>(
    uniqueKey('doubleWalled'),
    undefined
  )
  const [nationality, setNationality] = useSessionStorage<
    BicsHull['nationality'] | null | undefined
  >(uniqueKey('nationality'), null)
  const [placeOfRegistration, setPlaceOfRegistration] = useSessionStorage<string | undefined>(
    uniqueKey('placeOfRegistration'),
    undefined
  )
  const [dateOfRegistration, setDateOfRegistration] = useState<string | null>(null)
  const [length, setLength] = useState<number | undefined | string>()
  const [width, setWidth] = useState<number | undefined | string>()
  const [maxDraught, setMaxDraught] = useState<number | undefined | string>()
  const [height, setHeight] = useSessionStorage<number | undefined | string>(
    uniqueKey('height'),
    undefined
  )
  const [lengthBowToBow, setLengthBowToBow] = useSessionStorage<number | undefined | string>(
    uniqueKey('lengthBowToBow'),
    undefined
  )
  const [cargoCapacity, setCargoCapacity] = useSessionStorage<number | undefined | string>(
    uniqueKey('cargoCapacity'),
    undefined
  )
  const [dwt, setDWT] = useState<number | undefined | string>()
  const [callsign, setCallsign] = useState<string | undefined>()
  const [billingAddress, setBillingAddress] = useState<BicsAddress | undefined>(undefined)

  const [loading, setLoading] = useState<boolean>(false)
  const [errors, setErrors] = useState<ICustomError<keyof BicsHull | BICSHullExtraErrorKeys>[]>([])

  useEffect(() => {
    if (!name && autoFill) {
      setName(autoFill?.name || '')
      setLength(autoFill?.length || 0)
      setWidth(autoFill?.width || 0)
      setMaxDraught(autoFill?.maxDraught || 0)
      setCallsign(autoFill?.callSign || '')
      setMMSI(autoFill?.mmsi)
      setEni(autoFill?.eni || '')
    }
  }, [autoFill, name])

  useEffect(() => {
    if (hull && !dateOfRegistration) {
      setEni(hull.eni)
      setMMSI(hull.mmsi)
      setName(hull.name)
      setMode(hull.mode)
      setFuelType(hull.fuelType)
      setDoubleWalled(
        hull.doubleWalled === 'true' ? true : hull.doubleWalled === 'false' ? false : undefined
      )
      setNationality(hull.nationality)
      setPlaceOfRegistration(hull.placeOfRegistration)
      setDateOfRegistration(hull.dateOfRegistration || null)
      setLength(hull.lengthM)
      setWidth(hull.widthM)
      setHeight(hull.heightM)
      setMaxDraught(hull.maxDraughtM)
      setLengthBowToBow(hull.lengthBowToBridgeM)
      setCargoCapacity(hull.cargoCapacityTn)
      setDWT(hull.deadWeightTn)
      setCallsign(hull.callSign)
      setBillingAddress(hull.ownerAddress)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hulls, selectedHullID])

  useEffect(() => {
    analytics.setScreen('bics-hull')
    // Only execute on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="hull-creation-screen">
      <TitleComponent
        title={I18n.t(
          selectedHullID === 'new'
            ? 'announcements.hullCreation.newHull'
            : selectedHullID === 'create'
            ? 'announcements.hullCreation.titleCreate'
            : isMainHull
            ? 'announcements.hullCreation.editMainHull'
            : 'announcements.hullCreation.editHull'
        )}
      />
      <button
        className="button back-button"
        type="button"
        onClick={() => {
          handleCleanUpSessionStorage()
          setSelectedHullID(undefined)
        }}
      >
        <i className="icon-left" />
        <span>{I18n.t('announcements.goBack')}</span>
      </button>
      <div className="title">
        <div className="left">
          <h1>
            {I18n.t(
              selectedHullID === 'new'
                ? 'announcements.hullCreation.newHull'
                : selectedHullID === 'create'
                ? 'announcements.hullCreation.titleCreate'
                : isMainHull
                ? 'announcements.hullCreation.editMainHull'
                : 'announcements.hullCreation.editHull'
            )}
          </h1>
          {selectedHullID === 'create' ? (
            <p>{I18n.t('announcements.hullCreation.newHull')}</p>
          ) : null}
        </div>
        <div className="right buttons">
          {selectedHullID !== 'new' && hull?.eni !== mainHull?.eni && (
            <button
              className="button danger delete-button"
              type="button"
              onClick={e => {
                e.preventDefault()
                analytics.newEvent('bics_click_button_delete_hull', { selectedHullID })
                handleDeleteHull()
              }}
            >
              <i className="icon-cancel-1" />
              {I18n.t('announcements.hullCreation.deleteHull')}
            </button>
          )}
        </div>
      </div>
      <form className="hull-form" onSubmit={handleEnterHull}>
        <h3 className="block-preheader">{I18n.t('announcements.fields.general')}</h3>
        <div className="page-block">
          <BICSGenericInputRow keyName={'name'} isRequired={true} errors={errors}>
            <input
              name="name"
              type="text"
              value={name}
              className="textfield"
              onChange={e => setName(e.target.value)}
              autoCapitalize="off"
            />
          </BICSGenericInputRow>
          <BICSGenericInputRow keyName={'eni'} isRequired={true} errors={errors}>
            <BICSENINumberInput value={eni} setValue={num => setEni(num)} />
          </BICSGenericInputRow>
          <BICSGenericInputRow keyName={'mmsi'} isRequired={isMainHull} errors={errors}>
            <div className="row-wrapper">
              <CustomNumberInput
                name="mmsi"
                value={mmsi}
                setValue={num => setMMSI(num)}
                className="textfield"
                step="1"
                disabled={isMainHull}
              />
              {isMainHull && (
                <div className="hull-warning">
                  <div>{I18n.t('announcements.hullCreation.lockedMMSI')}</div>
                  <Link className="button primary" to="/settings">
                    <i className="icon-link-ext"></i>
                    {I18n.t('title.settings')}
                  </Link>
                </div>
              )}
            </div>
          </BICSGenericInputRow>
          <BICSGenericInputRow keyName={'fuelType'} isRequired={true} errors={errors}>
            <BICSFuelTypeSelector
              value={fuelType || ''}
              onChange={f => setFuelType(f)}
              placeholder={I18n.t('announcements.fields.clickToSelectFuelType')}
            />
          </BICSGenericInputRow>
          <BICSGenericInputRow keyName={'type'} isRequired={true} errors={errors}>
            <BICSShipTypeSelector
              value={type || null}
              initialShipTypeCode={hull?.type}
              onChange={bst => setType(bst || null)}
              placeholder={I18n.t('announcements.fields.clickToSelectType')}
              teqplayAPIService={teqplayAPIService}
            />
          </BICSGenericInputRow>
          <BICSGenericInputRow keyName={'doubleWalled'} isRequired={false} errors={errors}>
            <SwitchButton>
              <button
                className={`button ${activeClass(doubleWalled === true)}`}
                type="button"
                onClick={e => {
                  e.preventDefault()
                  setDoubleWalled(true)
                }}
              >
                {I18n.t('yes')}
              </button>
              <button
                className={`button ${activeClass(doubleWalled === false)}`}
                type="button"
                onClick={e => {
                  e.preventDefault()
                  setDoubleWalled(false)
                }}
              >
                {I18n.t('no')}
              </button>
              <button
                className={`button ${activeClass(doubleWalled === undefined)}`}
                type="button"
                onClick={e => {
                  e.preventDefault()
                  setDoubleWalled(undefined)
                }}
              >
                {I18n.t('announcements.fields.unknown')}
              </button>
            </SwitchButton>
          </BICSGenericInputRow>
          <BICSGenericInputRow keyName={'nationality'} isRequired={true} errors={errors}>
            <CountrySelector
              value={nationality || ''}
              onChange={n => setNationality(n?.code || null)}
              placeholder={I18n.t('announcements.fields.clickToSelectCountry')}
            />
          </BICSGenericInputRow>
          <BICSGenericInputRow keyName={'placeOfRegistration'} isRequired={false} errors={errors}>
            <input
              name="placeOfRegistration"
              type="text"
              value={placeOfRegistration}
              onChange={e => setPlaceOfRegistration(e.target.value)}
              className="textfield"
              autoCapitalize="off"
            />
          </BICSGenericInputRow>
          <BICSGenericInputRow keyName={'dateOfRegistration'} isRequired={false} errors={errors}>
            <MobileOrRegularDatePicker
              value={dateOfRegistration || undefined}
              locale={'nl'}
              dateSeperator="-"
              onDateChange={(iso, inputValue, isValid) =>
                setDateOfRegistration(isValid && iso ? iso : inputValue)
              }
              icon={<i className="icon-calendar" />}
            />
          </BICSGenericInputRow>
          <BICSGenericInputRow keyName={'callSign'} isRequired={false} errors={errors}>
            <input
              name="callsign"
              type="text"
              value={callsign}
              onChange={e => setCallsign(e.target.value)}
              className="textfield"
              autoCapitalize="off"
            />
          </BICSGenericInputRow>
        </div>

        <h3 className="block-preheader">{I18n.t('announcements.fields.dimensions')}</h3>
        <div className="page-block">
          <BICSGenericInputRow
            className="has-suffix"
            keyName={'lengthM'}
            isRequired={true}
            errors={errors}
          >
            <CustomNumberInput
              name="length"
              value={length}
              setValue={num => setLength(num)}
              className="textfield"
              step="0.01"
            />
            <span className="suffix">{I18n.t('announcements.fields.meter')}</span>
          </BICSGenericInputRow>
          <BICSGenericInputRow
            className="has-suffix"
            keyName={'widthM'}
            isRequired={true}
            errors={errors}
          >
            <CustomNumberInput
              name="width"
              value={width}
              setValue={num => setWidth(num)}
              className="textfield"
              step="0.01"
            />
            <span className="suffix">{I18n.t('announcements.fields.meter')}</span>
          </BICSGenericInputRow>
          <BICSGenericInputRow
            className="has-suffix"
            keyName={'heightM'}
            isRequired={true}
            errors={errors}
          >
            <CustomNumberInput
              name="height"
              value={height}
              setValue={num => setHeight(num)}
              className="textfield"
              step="0.01"
            />
            <span className="suffix">{I18n.t('announcements.fields.meter')}</span>
          </BICSGenericInputRow>
          <BICSGenericInputRow
            className="has-suffix"
            keyName={'maxDraughtM'}
            isRequired={true}
            errors={errors}
          >
            <CustomNumberInput
              name="maxDraught"
              value={maxDraught}
              setValue={num => setMaxDraught(num)}
              className="textfield"
              step="0.01"
            />
            <span className="suffix">{I18n.t('announcements.fields.meter')}</span>
          </BICSGenericInputRow>
          <BICSGenericInputRow
            className="has-suffix"
            keyName={'lengthBowToBridgeM'}
            isRequired={false}
            errors={errors}
          >
            <CustomNumberInput
              name="lengthBowToBow"
              value={lengthBowToBow}
              setValue={num => setLengthBowToBow(num)}
              className="textfield"
              step="0.01"
            />
            <span className="suffix">{I18n.t('announcements.fields.meter')}</span>
          </BICSGenericInputRow>

          <br />

          <BICSGenericInputRow
            className="has-suffix"
            keyName={'cargoCapacityTn'}
            isRequired={true}
            errors={errors}
          >
            <CustomNumberInput
              name="cargoCapacity"
              value={cargoCapacity}
              setValue={num => setCargoCapacity(num)}
              className="textfield"
              step="0.01"
            />
            <span className="suffix">{I18n.t('announcements.fields.tonnage')}</span>
          </BICSGenericInputRow>
          <BICSGenericInputRow
            className="has-suffix"
            keyName={'deadWeightTn'}
            isRequired={false}
            errors={errors}
          >
            <CustomNumberInput
              name="deadWeightTn"
              value={dwt}
              setValue={num => setDWT(num)}
              className="textfield"
            />
            <span className="suffix">{I18n.t('announcements.fields.tonnage')}</span>
          </BICSGenericInputRow>
        </div>

        <div className="billing-title">
          <h3>{I18n.t('announcements.fields.billingInfo')}</h3>
          <p>
            {I18n.t('announcements.optional')}, {I18n.t('announcements.travel.onlyExecptions')}
          </p>
        </div>
        <div className="page-block">
          <BICSGenericInputRow keyName={'givenName'} isRequired={false} errors={errors}>
            <input
              name="givenName"
              type="text"
              value={billingAddress?.givenName}
              onChange={e => setBillingAddress({ ...billingAddress, givenName: e.target.value })}
              className="textfield"
              autoCapitalize="true"
            />
          </BICSGenericInputRow>
          <BICSGenericInputRow keyName={'familyName'} isRequired={false} errors={errors}>
            <input
              name="familyName"
              type="text"
              value={billingAddress?.familyName}
              onChange={e => setBillingAddress({ ...billingAddress, familyName: e.target.value })}
              className="textfield"
              autoCapitalize="true"
            />
          </BICSGenericInputRow>
          <BICSGenericInputRow keyName={'street'} isRequired={false} errors={errors}>
            <input
              name="street"
              type="text"
              value={billingAddress?.streetName}
              onChange={e => setBillingAddress({ ...billingAddress, streetName: e.target.value })}
              className="textfield"
              autoCapitalize="true"
            />
          </BICSGenericInputRow>
          <BICSGenericInputRow keyName={'postcode'} isRequired={false} errors={errors}>
            <input
              name="postcode"
              type="text"
              value={billingAddress?.postalCode}
              onChange={e => setBillingAddress({ ...billingAddress, postalCode: e.target.value })}
              className="textfield"
              autoCapitalize="true"
            />
          </BICSGenericInputRow>
          <BICSGenericInputRow keyName={'city'} isRequired={false} errors={errors}>
            <input
              name="city"
              type="text"
              value={billingAddress?.city}
              onChange={e => setBillingAddress({ ...billingAddress, city: e.target.value })}
              className="textfield"
              autoCapitalize="true"
            />
          </BICSGenericInputRow>
          <BICSGenericInputRow keyName={'country'} isRequired={false} errors={errors}>
            <CountrySelector
              value={billingAddress?.country || ''}
              onChange={v => setBillingAddress({ ...billingAddress, country: v?.code })}
              placeholder={I18n.t('announcements.fields.clickToSelectCountry')}
              isClearable={true}
            />
          </BICSGenericInputRow>
          <BICSGenericInputRow
            keyName={'organisation'}
            isRequired={false}
            errors={errors}
            extraSubtitle={I18n.t('announcements.optional')}
            childrenWrapperClassName="flex-column"
          >
            <input
              name="organisation"
              type="text"
              value={billingAddress?.organisation || ''}
              onChange={e => setBillingAddress({ ...billingAddress, organisation: e.target.value })}
              className="textfield"
            />
            <span className="subtitle">{I18n.t('announcements.fields.organisationHint')}</span>
          </BICSGenericInputRow>
          <BICSGenericInputRow keyName={'vatNumber'} isRequired={false} errors={errors}>
            <input
              name="vatNumber"
              type="text"
              value={billingAddress?.vatNumber}
              onChange={e => setBillingAddress({ ...billingAddress, vatNumber: e.target.value })}
              className="textfield"
            />
          </BICSGenericInputRow>
        </div>

        {errors.length > 0 ? (
          <div className="error-hint">
            <div>{I18n.t('announcements.error.present')}</div>
            <div className="key-list">
              {errors
                .map(e => e.key)
                .filter(filterUnique)
                .map(key => I18n.t(`announcements.fields.${key}`))
                .join(', ')}
            </div>
          </div>
        ) : null}

        <div className="buttons">
          <button className="button primary" type="submit">
            {!loading ? (
              I18n.t('save')
            ) : (
              <>
                <i className="animate-spin icon-circle-notch" />
                <span>{I18n.t('processing')}</span>
              </>
            )}
          </button>
        </div>
      </form>
    </div>
  )
  function validateInputs(): ICustomError<keyof BicsHull | BICSHullExtraErrorKeys>[] {
    const validationErrors: ICustomError<keyof BicsHull | BICSHullExtraErrorKeys>[] = []

    if (!name) {
      validationErrors.push({ key: 'name', error: I18n.t('announcements.error.requiredEmpty') })
    }
    if (!eni) {
      validationErrors.push({ key: 'eni', error: I18n.t('announcements.error.requiredEmpty') })
    } else if (eni.length !== 8) {
      // Assertion to pass value as a number, check will go true if a string is passed
      validationErrors.push({
        key: 'eni',
        error: I18n.t('announcements.error.invalidENILength')
      })
      validationErrors.push({
        key: 'eni',
        error: I18n.t('announcements.error.invalidENILengthLeadingZero')
      })
    } else if (eni === mainHull?.eni && selectedHullID === 'new') {
      validationErrors.push({
        key: 'eni',
        error: I18n.t('announcements.error.sameMainHullENI')
      })
    }
    // MMSI is not mandatory, however once it is it should be passed as a number
    if (mmsi && isNaN(mmsi as number)) {
      // Assertion to pass value as a number, check will go true if a string is passed
      validationErrors.push({
        key: 'mmsi',
        error: I18n.t('announcements.error.invalidNumberValue')
      })
    }

    if (!mode) {
      validationErrors.push({ key: 'mode', error: I18n.t('announcements.error.requiredEmpty') })
    }
    if (!type) {
      validationErrors.push({ key: 'type', error: I18n.t('announcements.error.requiredEmpty') })
    }
    if (!fuelType) {
      validationErrors.push({ key: 'fuelType', error: I18n.t('announcements.error.requiredEmpty') })
    }
    if (!nationality) {
      validationErrors.push({
        key: 'nationality',
        error: I18n.t('announcements.error.requiredEmpty')
      })
    }
    if (!length) {
      validationErrors.push({ key: 'lengthM', error: I18n.t('announcements.error.requiredEmpty') })
    } else if (length <= 0) {
      validationErrors.push({
        key: 'lengthM',
        error: I18n.t('announcements.error.minAmount', { amount: length, minAmount: 0.1 })
      })
    } else if (length > 9999) {
      validationErrors.push({
        key: 'lengthM',
        error: I18n.t('announcements.error.maxAmount', { amount: length, maxAmount: 9999 })
      })
    } else if (isNaN(length as number)) {
      // Assertion to pass value as a number, check will go true if a string is passed
      validationErrors.push({
        key: 'lengthM',
        error: I18n.t('announcements.error.invalidNumberValue')
      })
    }

    if (!width) {
      validationErrors.push({ key: 'widthM', error: I18n.t('announcements.error.requiredEmpty') })
    } else if (width <= 0) {
      validationErrors.push({
        key: 'widthM',
        error: I18n.t('announcements.error.minAmount', { amount: width, minAmount: 0.1 })
      })
    } else if (width > 9999) {
      validationErrors.push({
        key: 'widthM',
        error: I18n.t('announcements.error.maxAmount', { amount: width, maxAmount: 9999 })
      })
    } else if (isNaN(width as number)) {
      // Assertion to pass value as a number, check will go true if a string is passed
      validationErrors.push({
        key: 'widthM',
        error: I18n.t('announcements.error.invalidNumberValue')
      })
    }

    if (!height) {
      validationErrors.push({ key: 'heightM', error: I18n.t('announcements.error.requiredEmpty') })
    } else if (height <= 0) {
      validationErrors.push({
        key: 'heightM',
        error: I18n.t('announcements.error.minAmount', { amount: height, minAmount: 0.1 })
      })
    } else if (height > 9999) {
      validationErrors.push({
        key: 'heightM',
        error: I18n.t('announcements.error.maxAmount', { amount: height, maxAmount: 9999 })
      })
    } else if (isNaN(height as number)) {
      // Assertion to pass value as a number, check will go true if a string is passed
      validationErrors.push({
        key: 'heightM',
        error: I18n.t('announcements.error.invalidNumberValue')
      })
    }

    if (!maxDraught) {
      validationErrors.push({
        key: 'maxDraughtM',
        error: I18n.t('announcements.error.requiredEmpty')
      })
    } else if (maxDraught <= 0) {
      validationErrors.push({
        key: 'maxDraughtM',
        error: I18n.t('announcements.error.minAmount', { amount: maxDraught, minAmount: 0.1 })
      })
    } else if (maxDraught > 99.99) {
      validationErrors.push({
        key: 'maxDraughtM',
        error: I18n.t('announcements.error.maxAmount', { amount: maxDraught, maxAmount: 99.99 })
      })
    } else if (isNaN(maxDraught as number)) {
      // Assertion to pass value as a number, check will go true if a string is passed
      validationErrors.push({
        key: 'maxDraughtM',
        error: I18n.t('announcements.error.invalidNumberValue')
      })
    }

    if (lengthBowToBow && lengthBowToBow <= 0) {
      validationErrors.push({
        key: 'lengthBowToBridgeM',
        error: I18n.t('announcements.error.minAmount', { amount: lengthBowToBow, minAmount: 0.1 })
      })
    } else if (lengthBowToBow && lengthBowToBow > 99999) {
      validationErrors.push({
        key: 'lengthBowToBridgeM',
        error: I18n.t('announcements.error.maxAmount', { amount: lengthBowToBow, maxAmount: 99999 })
      })
    } else if (lengthBowToBow && isNaN(lengthBowToBow as number)) {
      // Assertion to pass value as a number, check will go true if a string is passed
      validationErrors.push({
        key: 'lengthBowToBridgeM',
        error: I18n.t('announcements.error.invalidNumberValue')
      })
    }

    if (dwt && dwt <= 0) {
      validationErrors.push({
        key: 'deadWeightTn',
        error: I18n.t('announcements.error.minAmount', { amount: dwt, minAmount: 1 })
      })
    } else if (dwt && dwt > 999999) {
      validationErrors.push({
        key: 'deadWeightTn',
        error: I18n.t('announcements.error.maxAmount', { amount: dwt, maxAmount: 999999 })
      })
    } else if (dwt && isNaN(dwt as number)) {
      // Assertion to pass value as a number, check will go true if a string is passed
      validationErrors.push({
        key: 'deadWeightTn',
        error: I18n.t('announcements.error.invalidNumberValue')
      })
    }

    if (!cargoCapacity) {
      validationErrors.push({
        key: 'cargoCapacityTn',
        error: I18n.t('announcements.error.requiredEmpty')
      })
    } else if (cargoCapacity && cargoCapacity <= 0) {
      validationErrors.push({
        key: 'cargoCapacityTn',
        error: I18n.t('announcements.error.minAmount', { amount: cargoCapacity, minAmount: 0.01 })
      })
    } else if (cargoCapacity && cargoCapacity > 999999) {
      validationErrors.push({
        key: 'cargoCapacityTn',
        error: I18n.t('announcements.error.maxAmount', { amount: cargoCapacity, maxAmount: 999999 })
      })
    } else if (isNaN(cargoCapacity as number)) {
      // Assertion to pass value as a number, check will go true if a string is passed
      validationErrors.push({
        key: 'cargoCapacityTn',
        error: I18n.t('announcements.error.invalidNumberValue')
      })
    }

    if (dateOfRegistration && !isValidDate(dateOfRegistration)) {
      validationErrors.push({
        key: 'dateOfRegistration',
        error: I18n.t('announcements.error.invalidDate')
      })
    }

    const hasAnyBillingAddressValue =
      billingAddress?.givenName ||
      billingAddress?.familyName ||
      billingAddress?.city ||
      billingAddress?.postalCode ||
      billingAddress?.streetName ||
      billingAddress?.country ||
      billingAddress?.vatNumber ||
      billingAddress?.organisation

    if (hasAnyBillingAddressValue) {
      if (!billingAddress?.givenName) {
        validationErrors.push({
          key: 'givenName',
          error: I18n.t('announcements.error.addressRequired')
        })
      }
      if (!billingAddress?.familyName) {
        validationErrors.push({
          key: 'familyName',
          error: I18n.t('announcements.error.addressRequired')
        })
      }
      if (!billingAddress?.city) {
        validationErrors.push({
          key: 'city',
          error: I18n.t('announcements.error.addressRequired')
        })
      }
      if (!billingAddress?.postalCode) {
        validationErrors.push({
          key: 'postcode',
          error: I18n.t('announcements.error.addressRequired')
        })
      }
      if (!billingAddress?.streetName) {
        validationErrors.push({
          key: 'street',
          error: I18n.t('announcements.error.addressRequired')
        })
      }
      if (!billingAddress?.country) {
        validationErrors.push({
          key: 'country',
          error: I18n.t('announcements.error.addressRequired')
        })
      }
      if (!billingAddress?.vatNumber) {
        validationErrors.push({
          key: 'vatNumber',
          error: I18n.t('announcements.error.addressRequired')
        })
      }
    }

    return validationErrors
  }

  async function handleEnterHull(e: React.FormEvent<HTMLFormElement>): Promise<void> {
    setLoading(true)
    setErrors([])
    e.preventDefault()

    const validationErrors = validateInputs()

    if (validationErrors.length > 0) {
      setErrors(validationErrors)
      setLoading(false)
      return
    }

    try {
      // All x as number | undefined assertions are checked by the validateInputs() function
      // But ideally this should be done better...
      const hullMMSI =
        !isMainHull && currentLocation?.mmsi && mmsi && +mmsi === +currentLocation?.mmsi
          ? undefined
          : mmsi?.toString()
      /*
      If already a hull exist and MMSI is not the current one, then leave the MMSI empty
      */
      const newHull: BicsHull = {
        callSign: callsign,
        cargoCapacityTn: (cargoCapacity as number) || 0,
        dateOfRegistration: dateOfRegistration as string,
        deadWeightTn: dwt as number | undefined,
        doubleWalled: `${doubleWalled || null}`,
        eni: eni || (currentLocation?.eni as string), // Should never be null,
        mmsi: hullMMSI,
        fuelType: fuelType as BicsHull['fuelType'],
        heightM: (height as number) || 0,
        lengthM: (length as number) || 0,
        lengthBowToBridgeM: lengthBowToBow as number | undefined,
        maxDraughtM: (maxDraught as number) || 0,
        mode: mode as BicsHull['mode'],
        name,
        nationality: nationality as string,
        ownerAddress:
          !billingAddress?.city &&
          !billingAddress?.country &&
          !billingAddress?.givenName &&
          !billingAddress?.familyName &&
          !billingAddress?.postalCode &&
          !billingAddress?.streetName &&
          !billingAddress?.vatNumber
            ? undefined
            : {
                ...billingAddress,
                organisation: billingAddress?.organisation
                  ? billingAddress.organisation
                  : undefined,
                vatNumber: billingAddress?.vatNumber ? billingAddress.vatNumber : undefined
              },
        placeOfRegistration,
        type: type?.id || '',
        widthM: (width as number) || 0
      }
      analytics.newEvent('post_hull', {})
      await teqplayAPIService.bicsEnterHull(newHull)
      handleCleanUpSessionStorage()
      await refreshHulls()
      setSelectedHullID(undefined)
    } catch (error) {
      console.error(error)
      sendExceptionToSentry(
        undefined,
        { message: '[BICS] Hull creation/updation failed' },
        {
          error,
          action: 'hull_enter'
        }
      )
      toast.error(I18n.t('generalError'))
    } finally {
      setLoading(false)
    }
  }

  async function handleDeleteHull() {
    if (hull?.eni !== eni) {
      toast.warn(I18n.t('announcements.hullCreation.warnENIEdited'))
      return
    }

    if (!hull?.eni) {
      toast.error(I18n.t('announcements.hullCreation.errorNoENI'))
      return
    } else if (hull.eni) {
      try {
        confirmPopup({
          cancelText: I18n.t('no'),
          confirmText: I18n.t('yes'),
          closeOnOutsideClick: true,
          onConfirm: async () => {
            try {
              analytics.newEvent('bics_delete_hull', {})
              await teqplayAPIService.bicsDeleteHull(hull.eni)
              handleCleanUpSessionStorage()
              await refreshHulls()
              setSelectedHullID(undefined)
            } catch (error) {
              console.error(error)
              sendExceptionToSentry(new Error('[BICS] Deleting hull failed'), undefined, {
                error,
                selectedHullID,
                eni: hull.eni,
                mmsi: hull.mmsi,
                action: 'delete'
              })
              toast.error(I18n.t('generalError'))
            }
          },
          message: I18n.t('announcements.hullCreation.deleteConfirmation')
        })
      } catch (error) {
        console.error(error)
      }
    }
  }

  function handleCleanUpSessionStorage() {
    setMode(undefined)
    setType(undefined)
    setFuelType(undefined)
    setDoubleWalled(undefined)
    setPlaceOfRegistration(undefined)
    setHeight(undefined)
    setLengthBowToBow(undefined)
    setCargoCapacity(undefined)
    setNationality(undefined)
  }
}

export default BICSHullList
