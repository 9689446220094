import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { toast } from 'react-toastify'
import { addMilliseconds, format } from 'date-fns'
import isEqual from 'lodash/isEqual'

import TeqplayApiService from '../../services/TeqplayAPIService/TeqplayApiService'
import BridgeDetails from '../itemDetails/BridgeDetails'
import LockDetails from '../itemDetails/LockDetails'
import NotificationDetails from '../itemDetails/NotificationDetails'
import WatermeterDetails from '../itemDetails/WatermeterDetails'
import LoadingIndicator from '../loadingIndicator/LoadingIndicator'
import DefaultTransition from '../shared/defaultTransition/DefaultTransition'
import BasicInfoItem from './BasicInfoItem'
import DrinkwaterPointDetails from '../itemDetails/DrinkwaterPointDetails'
import WaveObjectDetails from '../itemDetails/WaveHeightDetails'
import CrossingsDetails from '../itemDetails/CrossingsDetails'
import SABPointDetails from '../itemDetails/SABPointDetails'
import CarDropoffPointDetails from '../itemDetails/CarDropoffPointDetails'
import ShorePowerDetails from '../itemDetails/ShorePowerDetails'
import WaterHeightDetails from '../itemDetails/WaterHeightDetails'
import WaitingBanner from './banners/WaitingBanner'
import BridgeOpeningBanner from './banners/BridgeOpeningBanner'
import ImpassableBanner from './banners/ImpassableBanner'
import BridgePlannedOpeningTimes from './BridgePlannedOpeningTimes'

import {
  IBridgeDetails,
  IBridgeMovement,
  IBridgePlanning,
  ILockDetails,
  INotificationDetails,
  IWaterMeterDetails,
  RouteItemTypes,
  IDrinkwaterPoint,
  ISABPoint,
  IShorePower,
  ICarDropoffPoint,
  IOperatingArea,
  IWaterHeight
} from '../../services/TeqplayAPIService/TeqplayApi'
import { TIME_FORMAT } from '../../utils/constants'
import { identifyAndParseDate } from '../../utils/dates'
import { getIconNameByType } from '../../utils/iconUtils'
import { sendExceptionToSentry } from '../../utils/sentry'
import { getRouteItemClassNames } from '../mapShared/mapIcons'
import { useAnalytics } from '../../services/AnalyticsWrapper/AnalyticsWrapper'
import { usePrevious } from '../../hooks/usePrevious'
import { SelectedItemModalEnum, IRouteInformation, IRootProps, IFairway } from '../../@types/types'
import { IWaveObject } from '../mapShared/customLayers/layers/WavesLayer/WavesLayer'

import '../itemDetails/ItemDetails.scss'
import './SelectedItemModal.scss'

interface IProps {
  selectedItem: SelectedItemModalEnum | undefined
  clearSelectedItem: () => void
  teqplayApiService: TeqplayApiService
  bridgePlanning?: IBridgePlanning[]
  bridgeMovement?: IBridgeMovement[]
}

const SelectedItemModal = ({
  selectedItem,
  clearSelectedItem,
  teqplayApiService,
  bridgeMovement,
  bridgePlanning
}: IProps) => {
  const analytics = useAnalytics('SelectedItemModal')
  const [extendedRouteItemDetails, setExtendedRouteItemDetails] = useState<
    IBridgeDetails | IWaterMeterDetails | ILockDetails | INotificationDetails | null
  >(null)
  const [loadingItemDetails, setLoadingItemDetails] = useState<boolean>(false)
  const previousSelectedItem = usePrevious(selectedItem)
  const locale = useSelector((props: IRootProps) => props.i18n.locale)

  const blacklistedRouteItemTypes: RouteItemTypes[] = [
    'END',
    'BOTTLENECK',
    'START',
    'TRAFFIC_SIGN',
    'VHFSECTOR'
  ]

  useEffect(() => {
    if (
      !isEqual(previousSelectedItem, selectedItem) &&
      (!selectedItem || selectedItem.type === 'ROUTE_ITEM')
    ) {
      // Only clear the fetched details if selectedItem is a routeItem
      setExtendedRouteItemDetails(null)
      setLoadingItemDetails(false)
    }
    // Fetch the translated items
    if (selectedItem && (selectedItem.type === 'BRIDGE' || selectedItem.type === 'LOCK')) {
      getRouteItemDetails(selectedItem.type, selectedItem.item.isrsCode)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [previousSelectedItem, selectedItem])

  const iconClass =
    selectedItem?.type === 'ROUTE_ITEM' && selectedItem?.item?.type === 'BRIDGE'
      ? selectedItem?.item.openingRequired
        ? 'bridge-opening-required'
        : 'bridge'
      : selectedItem?.type.toLowerCase()

  const canBePassed =
    selectedItem?.type === 'ROUTE_ITEM' &&
    selectedItem?.item?.canBePassed !== undefined &&
    selectedItem?.item.canBePassed === false
      ? false
      : true

  const refUUID =
    selectedItem?.type === 'ROUTE_ITEM'
      ? selectedItem?.item.refUuid
      : selectedItem?.type === 'BRIDGE'
      ? selectedItem.item.isrsCode
      : undefined

  const selectedBridgePlanning = bridgePlanning?.find(bp => bp.isrs === refUUID)
  const selectedBridgeMovement =
    selectedItem?.type === 'BRIDGE'
      ? selectedItem.item.bridgeMovement
      : bridgeMovement?.find(bm => bm.isrsId === refUUID)

  return (
    <DefaultTransition active={selectedItem?.item !== undefined} slideFrom="bottom" offset={500}>
      {props => (
        <div
          style={props}
          className="selected-route-item-wrapper"
          id={`selected-route-item-wrapper-${getUniqueItemIdentifier()}`}
        >
          {selectedItem?.item && (
            <React.Fragment>
              <div className="selected-route-item-header">
                <div className="route-item-titles">
                  {determineItemModalIcon()}

                  <h2 id="selected-route-item-name">{getUniqueItemDisplayName()}</h2>
                  <span className="icon-cancel-1 close-icon" onClick={() => clearSelectedItem()} />
                </div>

                {/** Various banners to display information related to the user */}
                <WaitingBanner selectedItem={selectedItem} />
                <ImpassableBanner selectedItem={selectedItem} />
                <BridgeOpeningBanner
                  selectedItem={selectedItem}
                  selectedBridgePlanning={selectedBridgePlanning}
                />

                <div className="basic-info">
                  <BasicInfoItem
                    label={I18n.t('routeList.itemDetails.arrival')}
                    id="eta"
                    value={
                      selectedItem.type === 'ROUTE_ITEM' && selectedItem.item.eta
                        ? format(identifyAndParseDate(selectedItem.item.eta), TIME_FORMAT)
                        : undefined
                    }
                  />

                  <BasicInfoItem
                    label={I18n.t('routeList.itemDetails.departure')}
                    id="departureTime"
                    value={
                      selectedItem.type === 'ROUTE_ITEM' &&
                      selectedItem.item.waitingTime &&
                      selectedItem.item.eta
                        ? format(
                            addMilliseconds(
                              identifyAndParseDate(selectedItem.item.eta),
                              selectedItem.item.waitingTime
                            ),
                            TIME_FORMAT
                          )
                        : undefined
                    }
                  />

                  <BasicInfoItem
                    label={I18n.t('routeList.itemDetails.phone')}
                    id="phone"
                    extraType="phone"
                    value={
                      selectedItem.type === 'ROUTE_ITEM'
                        ? selectedItem.item.telephone
                        : selectedItem.type === 'BRIDGE' || selectedItem.type === 'LOCK'
                        ? selectedItem.item.phoneNumber
                        : undefined
                    }
                  />

                  <BasicInfoItem
                    label={I18n.t('routeList.itemDetails.vhf')}
                    id="vhf"
                    extraType="vhf"
                    value={
                      selectedItem.type === 'ROUTE_ITEM' ||
                      selectedItem.type === 'BRIDGE' ||
                      selectedItem.type === 'LOCK'
                        ? selectedItem.item.vhfChannel
                        : undefined
                    }
                  />
                </div>
              </div>

              <BridgePlannedOpeningTimes
                selectedItem={selectedItem}
                selectedBridgePlanning={selectedBridgePlanning}
              />

              <div
                className={`route-item-detailed${
                  (extendedRouteItemDetails &&
                    selectedItem.type === 'ROUTE_ITEM' &&
                    !blacklistedRouteItemTypes.includes(selectedItem.item.type)) ||
                  selectedItem
                    ? ''
                    : ' not-loaded'
                }`}
              >
                {extendedRouteItemDetails ? (
                  <div
                    className="details-bar selected"
                    onClick={() => {
                      setExtendedRouteItemDetails(null)
                    }}
                    id="selected-route-item-collapse-button"
                  >
                    <span className="icon-up-open collapse-icon" />
                  </div>
                ) : loadingItemDetails ? (
                  <div className="item-info-details loading" id="selected-route-item-loading">
                    <LoadingIndicator loading={true} />
                  </div>
                ) : (
                  selectedItem.type === 'ROUTE_ITEM' &&
                  !blacklistedRouteItemTypes.includes(selectedItem.item.type) && (
                    <div
                      className="details-bar"
                      onClick={() =>
                        getRouteItemDetails(selectedItem.item.type, selectedItem.item.refUuid)
                      }
                      id="selected-route-item-more-info-button"
                    >
                      {I18n.t('navigationPage.moreInformation')}
                    </div>
                  )
                )}

                {showRouteDetails(
                  selectedItem.type === 'ROUTE_ITEM' ? selectedItem.item.type : selectedItem.type,
                  selectedItem.type === 'ROUTE_ITEM' ||
                    selectedItem.type === 'BRIDGE' ||
                    selectedItem.type === 'LOCK'
                    ? extendedRouteItemDetails
                    : selectedItem.item
                )}
              </div>
            </React.Fragment>
          )}
        </div>
      )}
    </DefaultTransition>
  )

  async function getRouteItemDetails(routeItemType: RouteItemTypes, itemId: string) {
    if (itemId) {
      try {
        analytics.newEvent('click_selectedrouteitem_more_info', { routeItemType, itemId })
        setLoadingItemDetails(true)
        const routeItemDetails = await fetchRouteItemDetails(routeItemType, itemId)

        setExtendedRouteItemDetails(routeItemDetails)
        setLoadingItemDetails(false)
      } catch (error) {
        sendExceptionToSentry(error)
      }
    } else {
      toast.warn(I18n.t('routeSelection.noDetailsFoundWarning'))
    }
  }

  async function fetchRouteItemDetails(
    type: RouteItemTypes,
    itemId: string
  ): Promise<IBridgeDetails | IWaterMeterDetails | ILockDetails | INotificationDetails> {
    const itemDetails = teqplayApiService.fetchItemDetails(type, itemId, locale)
    return itemDetails
  }

  function showRouteDetails(
    type: RouteItemTypes | SelectedItemModalEnum['type'],
    itemDetails:
      | IBridgeDetails
      | IWaterMeterDetails
      | ILockDetails
      | INotificationDetails
      | IDrinkwaterPoint
      | IRouteInformation
      | ISABPoint
      | IShorePower
      | ICarDropoffPoint
      | IWaterHeight
      | null
  ) {
    switch (type) {
      case 'BRIDGE': {
        return (
          <BridgeDetails
            bridgePlanning={selectedBridgePlanning}
            bridgeMovement={selectedBridgeMovement}
            isExpanded={true}
            itemDetails={itemDetails as IBridgeDetails}
          />
        )
      }
      case 'LOCK':
        return <LockDetails isExpanded={true} itemDetails={itemDetails as ILockDetails} />
      case 'WATERMETER':
        return (
          <WatermeterDetails isExpanded={true} itemDetails={itemDetails as IWaterMeterDetails} />
        )
      case 'NOTIFICATION':
        return (
          <NotificationDetails
            isExpanded={true}
            itemDetails={itemDetails as INotificationDetails}
          />
        )
      case 'DRINKWATERPOINT': {
        return (
          <DrinkwaterPointDetails isExpanded={true} itemDetails={itemDetails as IDrinkwaterPoint} />
        )
      }
      case 'WAVEHEIGHT': {
        return <WaveObjectDetails isExpanded={true} itemDetails={itemDetails as IWaveObject} />
      }
      case 'CROSSING': {
        return <CrossingsDetails isExpanded={true} itemDetails={itemDetails as IRouteInformation} />
      }
      case 'SABPOINT': {
        return <SABPointDetails isExpanded={true} itemDetails={itemDetails as ISABPoint} />
      }
      case 'CARDROPOFF': {
        return (
          <CarDropoffPointDetails isExpanded={true} itemDetails={itemDetails as ICarDropoffPoint} />
        )
      }
      case 'SHOREPOWER': {
        return <ShorePowerDetails isExpanded={true} itemDetails={itemDetails as IShorePower} />
      }
      case 'OPERATING_AREA': {
        const operatingArea = itemDetails as IOperatingArea
        return <p>{operatingArea.organistation}</p>
      }
      case 'WATER_HEIGHT': {
        return (
          itemDetails && (
            <WaterHeightDetails
              teqplayApiService={teqplayApiService}
              itemDetails={itemDetails as IWaterHeight}
            />
          )
        )
      }
      case 'FAIRWAY': {
        const fairway = itemDetails as IFairway
        return <p>{fairway.note}</p>
      }
      default:
        return null
    }
  }

  function determineItemModalIcon() {
    if (!selectedItem) {
      return null
    }

    switch (selectedItem.type) {
      case 'ROUTE_ITEM': {
        return (
          <div
            className={`circle-icon ${iconClass} ${getRouteItemClassNames(
              selectedItem.type,
              canBePassed,
              selectedItem.item.waitingTime,
              selectedItem.item.openingRequired
            )}`}
          >
            <span
              className={
                getIconNameByType(selectedItem.item.type, {
                  ...selectedItem.item,
                  status: selectedBridgeMovement?.status
                }) +
                ' ' +
                'item-icon ' +
                (selectedItem?.item.type).toLowerCase() +
                '-color'
              }
            />
          </div>
        )
      }

      case 'BRIDGE': {
        return (
          <div className="circle-icon bridge gray">
            <i
              className={`icon-bridge-${
                selectedItem.item.bridgeMovement?.status === 'OPEN'
                  ? 'open'
                  : !selectedItem.item.canOpen
                  ? 'solid'
                  : 'closed'
              } `}
            />
          </div>
        )
      }

      case 'DRINKWATERPOINT': {
        return (
          <div className="circle-icon drinkwaterpoint primary">
            <i
              className={
                selectedItem.item.type === 'Land'
                  ? 'icon-drinkwaterpoint'
                  : 'icon-drinkwaterpoint-wave'
              }
            />
          </div>
        )
      }

      case 'LOCK': {
        return (
          <div className="circle-icon lock purple">
            <i className={getIconNameByType('LOCK')} />
          </div>
        )
      }

      case 'WAVEHEIGHT': {
        return (
          <div className="circle-icon waveHeight blue">
            <i className="wave-symbol" />
          </div>
        )
      }
      case 'SABPOINT': {
        return (
          <div className="circle-icon sabpoint primary">
            <i className="icon-trash" />
          </div>
        )
      }

      case 'CROSSING': {
        return <div className="div-icon crossing-icon" />
      }
      case 'CARDROPOFF': {
        return (
          <div className="circle-icon cardropoff primary">
            <i className="icon-cab" />
          </div>
        )
      }
      case 'SHOREPOWER': {
        return (
          <div className="circle-icon shorepower primary">
            <i className="icon-plug" />
          </div>
        )
      }
      case 'OPERATING_AREA': {
        return (
          <div className="circle-icon operating primary">
            <i className="icon-map" />
          </div>
        )
      }
      case 'WATER_HEIGHT': {
        return (
          <div className="circle-icon water-height">
            <i className="icon-depth" />
          </div>
        )
      }
      case 'FAIRWAY': {
        return (
          <div className="circle-icon fairway primary">
            <i className="icon-gauge" />
          </div>
        )
      }

      default: {
        return <div className="circle-icon" />
      }
    }
  }

  function getUniqueItemIdentifier(): string | undefined {
    if (!selectedItem?.item) {
      return undefined
    }

    switch (selectedItem.type) {
      case 'CROSSING': {
        return selectedItem.item.properties.FID_
      }
      case 'DRINKWATERPOINT': {
        return selectedItem.item.name
      }

      default: {
        return selectedItem.item._id
      }
    }
  }

  function getUniqueItemDisplayName(): string | undefined {
    if (!selectedItem) {
      return undefined
    }

    switch (selectedItem.type) {
      case 'NOTIFICATION': {
        return selectedItem.item.title
      }
      case 'CROSSING': {
        return selectedItem.item.properties.FID_
      }
      case 'CARDROPOFF': {
        return selectedItem.item.Name
      }
      case 'SHOREPOWER': {
        return selectedItem.item.description
      }
      case 'FAIRWAY': {
        return selectedItem.item.fairwayName || selectedItem.item.fairway
      }

      default: {
        return selectedItem.item.name
      }
    }
  }
}

export default SelectedItemModal
