import * as React from 'react'
import Popup from 'react-popup'
import { I18n } from 'react-redux-i18n'

import DefaultTransition from '../../components/shared/defaultTransition/DefaultTransition'
import TitleComponent from '../../components/titleComponent/TitleComponent'

import { useAnalytics } from '../../services/AnalyticsWrapper/AnalyticsWrapper'
import { LAYER_COVERAGE, PRIVACY_STATEMENT_URL } from '../../utils/constants'
import { sortByKey } from '../../utils/general'
import { parseStringForID } from '../../utils/tests'

import './InformationPage.scss'

const InformationPage = () => {
  const analytics = useAnalytics('InformationPage')
  const [openedPopup, setOpenedPopup] = React.useState(false)

  const sortedLayerCoverage = sortByKey(
    LAYER_COVERAGE.map(lc => {
      const translatedCodeName = I18n.t(`map.layers.${lc.codeName}`)
      const translatedAreas = lc.areas.map(a => I18n.t(`source.area.${a}`))

      return {
        ...lc,
        originalCodeName: lc.codeName,
        codeName: translatedCodeName,
        originalAreas: lc.areas,
        areas: translatedAreas
      }
    }),
    'codeName'
  )

  React.useEffect(() => {
    analytics.setScreen('information')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <DefaultTransition active={true} offset={10} slideFrom="top">
      {styleProps => (
        <div
          className={`main-wrapper information-page${openedPopup ? ' gdpr-open' : ''}`}
          style={styleProps}
        >
          <TitleComponent title={I18n.t('information.information')} />
          <div className="main-page-wrapper">
            <h2 id="informationpage-title">{I18n.t('information.information')}</h2>

            <div className="page-block">
              <h3>{I18n.t('information.privacyStatement')}</h3>
              <div className="form-row">
                <button
                  id="informationpage-button-view-privacy"
                  onClick={showPrivacyPopup}
                  className="button"
                >
                  {I18n.t('information.view')}
                </button>
              </div>
              <div className="form-row">
                {/* Link to privacy statement */}
                <button
                  id="informationpage-button-download-privacy"
                  className="button"
                  onClick={() => {
                    analytics.newEvent('click_open_privacy_statement', {})
                    if (window.cordova && window.cordova.InAppBrowser) {
                      window.cordova.InAppBrowser.open(PRIVACY_STATEMENT_URL, '_system')
                    } else {
                      window.open(PRIVACY_STATEMENT_URL, '_system')
                    }
                  }}
                >
                  {I18n.t('information.downloadPrivacyStatement')}
                </button>
              </div>
            </div>

            <div className="page-block">
              <div className="form-row">
                <h3 id="informationpage-layer-information">
                  {I18n.t('information.layerInformation')}
                </h3>
              </div>
              <div className="form-row">{I18n.t('information.sourceExplainer')}</div>
              <div className="automatic-notice">
                <i className="icon-info-circled" />
                {I18n.t('information.translationNotice')}
              </div>
              <div className="source-row">
                <div className="sources-table">
                  <div className="row header">
                    <span className="label">{I18n.t('information.layer')}</span>
                    <div className="info-columns">
                      <div className="sources">
                        <span className="label">{I18n.t('information.source')}</span>
                      </div>
                      <div className="areas">
                        <span className="label">{I18n.t('information.coverageArea')}</span>
                      </div>
                    </div>
                  </div>
                  {sortedLayerCoverage.map((item, index) => (
                    <div
                      className="row"
                      key={index}
                      id={parseStringForID(`informationpage-row-${item.originalCodeName}`)}
                    >
                      <span
                        className="label"
                        id={parseStringForID(`informationpage-row-label-${item.originalCodeName}`)}
                      >
                        {item.codeName}
                      </span>
                      <div className="info-columns">
                        <div
                          className="sources"
                          id={parseStringForID(
                            `informationpage-row-sources-${item.originalCodeName}`
                          )}
                        >
                          {item.sources.map((source, i) => (
                            <span
                              key={i}
                              id={parseStringForID(`source-${item.originalCodeName}-${source}`)}
                            >
                              {source}
                            </span>
                          ))}
                        </div>
                        <div
                          className="areas"
                          id={`informationpage-row-areas-${item.originalCodeName}`}
                        >
                          {item.areas.sort().map((area, j) => (
                            <span
                              key={j}
                              id={parseStringForID(
                                `source-${item.originalCodeName}-${item.originalAreas[j]}`
                              )}
                            >
                              {area}
                            </span>
                          ))}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </DefaultTransition>
  )

  function showPrivacyPopup() {
    setOpenedPopup(true)
    analytics.newEvent('open_privacy_popup', {})
    Popup.create({
      title: I18n.t('register.gdpr.title'),
      content: (
        <div className="privacy-statement">
          <p className="text-line">{I18n.t('register.gdpr.privacyBodyFirstLine')}</p>
          <p className="text-line">{I18n.t('register.gdpr.privacyBodySecondLine')}</p>
          <p className="text-line">{I18n.t('register.gdpr.privacyBodyThirdLine')}</p>
          <p className="text-line">{I18n.t('register.gdpr.privacyBodyFourthLine')}</p>
          <p className="text-line">{I18n.t('register.gdpr.privacyBodyFifthLine')}</p>
          <p className="text-line">{I18n.t('register.gdpr.privacyBodySixthLine')}</p>
          <p className="text-line">
            {I18n.t('register.gdpr.privacyBodySeventhLine')}
            <a href="mailto:riverguide@teqplay.nl?subject=Privacy RiverGuide" target="_system">
              riverguide@teqplay.nl
            </a>
          </p>
        </div>
      ),
      buttons: {
        left: [
          {
            text: I18n.t('register.gdpr.close'),
            className: 'button large primary',
            action: () => {
              Popup.close()
              setOpenedPopup(false)
            }
          }
        ]
      }
    })
  }
}

export default InformationPage
