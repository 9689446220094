import { i18nReducer } from 'react-redux-i18n'
import { combineReducers } from 'redux'

import { mapReducer } from '../components/mapShared/reducers'
import { speedReducer } from '../components/mapShared/setSpeed/reducers'
import { loginReducer } from '../pages/loginPage/reducers'
import { routeSelectionReducer } from '../pages/routeSelectionPage/reducers'
import { settingsReducer } from '../pages/settings/reducers'
import { locationReducer } from '../routes/reducers'

const rootReducer = () =>
  combineReducers({
    currentUser: loginReducer,
    speed: speedReducer,
    userLocation: locationReducer,
    routeSelection: routeSelectionReducer,
    i18n: i18nReducer,
    map: mapReducer,
    settings: settingsReducer
  })

export default rootReducer
