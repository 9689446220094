import { normalizeHeading } from 'geolocation-utils'
import phoneUtils from 'google-libphonenumber'
import { produce } from 'immer'
import { get } from 'lodash'
import { Styles } from 'react-select'
import { CSSProperties } from 'react'

const phoneUtil = phoneUtils.PhoneNumberUtil.getInstance()

export function activeClass(value: boolean, customName: string = 'active'): string {
  return value ? customName : ''
}

export function knotsToKmh(knots: number): number | string {
  if (knots === 0 || knots === null) return ''
  return Math.round((knots / 0.539956803) * 10) / 10
}

export function kmhToKnots(kmh: number): number {
  return kmh * 0.539956803
}

/**
 * Returns true if the given phone number is valid
 */
export function isValidPhoneNumber(phone?: string): boolean {
  if (!phone) {
    return true
  }

  if (phone.length < 5) {
    return false
  }

  function hasNotAllowedSpecialCharacters(str: string) {
    // Allows spaces, +, #, * and -
    return !/[~`!@$%^&=[\]\\';,/{}|\\":<>?]/g.test(str)
  }

  if (!hasNotAllowedSpecialCharacters(phone)) {
    return false
  }

  try {
    // Any fallback numbers will be parsed with NL country code
    const phoneNumber = phoneUtil.parseAndKeepRawInput(phone, 'nl')

    if (!phoneUtil.isValidNumber(phoneNumber)) {
      return false
    } else {
      return true
    }
  } catch (err) {
    // Parsing with phoneUtils is giving errors when not a valid number, so catching that here
    console.error(err)
    return false
  }
}

export function isEmail(email?: string): boolean {
  if (!email) {
    return true
  }

  // For the TLD at the end, it can be 2+ characters
  // In `set` Unicode 33-126 are allowed
  // in addition: ()<>,;:\"[] are not allowed
  const set = `[A-Za-z0-9!#$%^&*\\-_+=@\`~{}|.-]`
  const fullRegex = new RegExp(`^${set}+@${set}+\\.[A-Za-z0-9-]{2,}`, 'i')
  return fullRegex.test(email)
}

export function degreesToCardinals(initialDegrees: number) {
  let cardinalDirection = ''
  const degrees = normalizeHeading(initialDegrees)

  if (degrees >= 0 && degrees <= 11.25) {
    cardinalDirection = 'N'
  } else if (degrees > 11.25 && degrees <= 33.75) {
    cardinalDirection = 'NNO'
  } else if (degrees > 33.75 && degrees <= 56.25) {
    cardinalDirection = 'NO'
  } else if (degrees > 56.25 && degrees <= 78.75) {
    cardinalDirection = 'ONO'
  } else if (degrees > 78.75 && degrees <= 101.25) {
    cardinalDirection = 'O'
  } else if (degrees > 101.25 && degrees <= 123.75) {
    cardinalDirection = 'OZO'
  } else if (degrees > 123.75 && degrees <= 146.25) {
    cardinalDirection = 'ZO'
  } else if (degrees > 146.25 && degrees <= 168.75) {
    cardinalDirection = 'ZZO'
  } else if (degrees > 168.75 && degrees <= 191.25) {
    cardinalDirection = 'Z'
  } else if (degrees >= 191.25 && degrees <= 213.75) {
    cardinalDirection = 'ZZW'
  } else if (degrees > 213.75 && degrees <= 236.25) {
    cardinalDirection = 'ZW'
  } else if (degrees > 236.25 && degrees <= 258.75) {
    cardinalDirection = 'WZW'
  } else if (degrees > 258.75 && degrees <= 281.25) {
    cardinalDirection = 'W'
  } else if (degrees > 281.25 && degrees <= 303.75) {
    cardinalDirection = 'WNW'
  } else if (degrees > 303.75 && degrees <= 326.25) {
    cardinalDirection = 'NW'
  } else if (degrees > 326.25 && degrees <= 348.75) {
    cardinalDirection = 'NNW'
  } else if (degrees > 348.75) {
    cardinalDirection = 'N'
  } else {
    cardinalDirection = 'N/A'
  }
  return cardinalDirection
}

export function getTimebasedGreeting() {
  const time = new Date()

  if (time.getHours() < 6) {
    return 'night'
  } else if (time.getHours() < 12) {
    return 'morning'
  } else if (time.getHours() < 18) {
    return 'afternoon'
  } else if (time.getHours() < 24) {
    return 'evening'
  } else {
    return 'day'
  }
}

/**
 * Sort an array using a specific key in ascending or descending order
 *
 * **⚠ Function will manipulate original array ⚠**
 *
 * *This might cause errors when used in conjunction with data fetched using `react-query`*
 * @see sortImmutableByKey for the same function which accepts and returns an immutable array
 * @returns new Array which has also edited the original
 */
export function sortByKey<A>(
  array: A[],
  key: string,
  order: 'asc' | 'desc' = 'asc',
  optionalFirstKey?: string
): A[] {
  return array.sort((a, b) => {
    const smaller = order === 'asc' ? -1 : 1
    const bigger = order === 'asc' ? 1 : -1
    const x = optionalFirstKey && get(a, optionalFirstKey) ? get(a, optionalFirstKey) : get(a, key)
    const y = optionalFirstKey && get(b, optionalFirstKey) ? get(b, optionalFirstKey) : get(b, key)

    if (x && y) {
      return x < y ? smaller : x > y ? bigger : 0
    }
    if (!x) {
      return 1
    }
    return -1
  })
}

export const selectStyles: Partial<Styles> = {
  container: (styles: CSSProperties) => ({ ...styles, width: '100%' }),
  control: (styles: CSSProperties) => ({
    ...styles
  }),
  option: (styles: CSSProperties) => ({
    ...styles,
    fontSize: '14px',
    fontWeight: 400,
    color: '#525252'
  }),
  input: (styles: CSSProperties) => ({ ...styles, color: '#2e6771' }),
  menuList: (styles: CSSProperties) => ({ ...styles, borderRadius: '0px' })
}

export function filterUnique<T>(v: T, i: number, s: T[]) {
  return s.indexOf(v) === i
}

/**
 * Sort an array using a specific key in ascending or descending order
 *
 * *Function will not manipulate original array, but might be slower than sortByKey*
 * @see sortByKey for the same function which accepts and returns an immutable array
 * @returns Immutable array
 */
export function sortImmutableByKey<A>(array: A[], key: string, order: 'asc' | 'desc' = 'asc'): A[] {
  return produce(array, draft =>
    draft.sort((a, b) => {
      const smaller = order === 'asc' ? -1 : 1
      const bigger = order === 'asc' ? 1 : -1
      const x = get(a, key)
      const y = get(b, key)

      if (x && y) {
        return x < y ? smaller : x > y ? bigger : 0
      }
      if (!x) {
        return 1
      }
      return -1
    })
  )
}
