import * as React from 'react'
import './SwitchButton.scss'

const SwitchButton: React.FunctionComponent<{ isDisabled?: boolean }> = ({
  children,
  isDisabled
}) => {
  return <div className={`switch-button${isDisabled ? ' disabled' : ''}`}>{children}</div>
}

export default SwitchButton
