import React, { useMemo } from 'react'
import ReactCountryFlag from 'react-country-flag'
import { I18n } from 'react-redux-i18n'
import { useSelector } from 'react-redux'
import Select, { ValueType } from 'react-select'
import { Option } from 'react-select/src/filters'

import COUNTRY_LIST from '../../assets/countryCodes.json'
import { getCountryFromCode, getCountryName, ICountry } from '../../utils/countriesUtil'
import { selectStyles } from '../../utils/general'
import { IRootProps } from '../../@types/types'

import './CountrySelector.scss'

interface IProps {
  value: string | undefined
  onChange: (value: ICountry | null) => void
  error?: string
  isDisabled?: boolean
  id?: string
  inputId?: string
  placeholder?: React.ReactNode
  noOptionsMessage?: (obj: { inputValue: string }) => string
  menuIsOpen?: boolean
  isClearable?: boolean
}

const CountrySelector = ({
  onChange,
  value,
  isDisabled,
  error,
  id,
  inputId,
  placeholder,
  noOptionsMessage,
  menuIsOpen,
  isClearable
}: IProps) => {
  const activeCountry = value ? getCountryFromCode(value) : null
  const locale = useSelector((s: IRootProps) => s.i18n.locale)

  // Sorting the countries based on priority
  const options = useMemo(() => {
    const priorityCountries = ['NL', 'BE', 'DE', 'FR']
    const priority = COUNTRY_LIST.filter(country => priorityCountries.includes(country.code))
    const nonPriority = COUNTRY_LIST.filter(
      country => !priorityCountries.includes(country.code)
    ).sort((a, b) => a.name.localeCompare(b.name))
    return [...priority, ...nonPriority]
  }, [])

  return (
    <Select<ICountry>
      defaultValue={activeCountry}
      value={activeCountry}
      options={options}
      isClearable={isClearable}
      onChange={handleChangeCountry}
      error={error}
      isDisabled={isDisabled}
      id={id}
      inputId={inputId}
      placeholder={placeholder || I18n.t('countrySelect.placeholder')}
      className="generic-select"
      classNamePrefix="generic-select selector"
      formatOptionLabel={c => (
        <span className="country-option" id={`value-COUNTRY-${c.code}`} data-code={c.code}>
          <ReactCountryFlag countryCode={c.code} className="flag" svg /> {getCountryName(c, locale)}
        </span>
      )}
      styles={selectStyles}
      getOptionValue={option => getCountryName(option, locale) || option.name}
      noOptionsMessage={obj =>
        noOptionsMessage
          ? noOptionsMessage(obj)
          : I18n.t('countrySelect.noOptions', { query: obj.inputValue })
      }
      menuIsOpen={menuIsOpen}
      key={locale}
      filterOption={customFilter}
    />
  )

  function handleChangeCountry(countryValue: ValueType<ICountry>) {
    const v = countryValue as ICountry
    onChange(v)
  }

  function customFilter(option: Option, rawInput: string) {
    const country = option.data as ICountry
    const search = rawInput.toLowerCase()
    const countryName = getCountryName(country, locale)?.toLowerCase()
    const matchesName = countryName?.includes(search)
    const matchesTags = country.tags?.some(tag => tag.toLowerCase().includes(search))
    // Always return a boolean
    return !!matchesName || !!matchesTags
  }
}

export default CountrySelector
