import { getBoundingBox, toLonLatTuple } from 'geolocation-utils'
import { useEffect, useState } from 'react'

import { IBerth, IShipInfo } from '../services/TeqplayAPIService/TeqplayApi'
import TeqplayApiService from '../services/TeqplayAPIService/TeqplayApiService'

import { geolocationBoundingBoxToTeqplayBoundsObject } from '../utils/layers'

export function useShipsInBerth(
  berths: IBerth[] | null,
  teqplayApiService: TeqplayApiService,
  isChecked: boolean
) {
  const [selectedBerthOwnerId, setSelectedBerthOwnerId] = useState<string>()
  const [vesselsNearBerth, setVesselsNearBerth] = useState<IShipInfo[]>()
  const selectedBerth = berths?.find(b => b.ownerId === selectedBerthOwnerId)

  useEffect(() => {
    async function fetchShipsNearBerth(b: IBerth) {
      try {
        const boundingBox = getBoundingBox(
          b.area.coordinates[0].map(l => toLonLatTuple(l)),
          20
        )
        const vessels = await teqplayApiService.fetchShipsInBounds(
          geolocationBoundingBoxToTeqplayBoundsObject(boundingBox)
        )
        setVesselsNearBerth(vessels)
      } catch (error) {
        console.error(error)
      }
    }

    if (selectedBerth) {
      fetchShipsNearBerth(selectedBerth)
    } else {
      setVesselsNearBerth(undefined)
    }
  }, [selectedBerth, teqplayApiService])

  useEffect(() => {
    if (!isChecked) {
      setSelectedBerthOwnerId(undefined)
    }
  }, [isChecked])

  return {
    selectedBerthOwnerId,
    setSelectedBerthOwnerId,
    selectedBerth,
    vesselsNearBerth
  }
}
