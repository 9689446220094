import React from 'react'
import { I18n } from 'react-redux-i18n'

import { formatISOToInputDateFormat } from '../../../utils/dates'

interface IProps {
  onDateChange: (
    isoString: string | null,
    inputValue: string,
    isValid: boolean,
    fieldName?: string
  ) => void
  value?: string
  id?: string
  disabled?: boolean
  showClearButton?: boolean
}

const MobileDatePicker = (props: IProps) => {
  const { onDateChange, value, id, disabled, showClearButton } = props

  return (
    <div className="mobile-date-picker">
      <input
        id={`mobile-date-picker-startDate-${id}`}
        className="input date-input-native date"
        placeholder={I18n.t('datetime.date')}
        type="date"
        value={value ? formatISOToInputDateFormat(value) : ''}
        onChange={handleOnChange}
        disabled={disabled}
      />
      {value && !disabled && showClearButton && (
        <button
          className="button clear-button"
          onClick={e => {
            e.stopPropagation()
            e.preventDefault()
            onDateChange(null, '', false)
          }}
          type="button"
        >
          <i className="icon-cancel" />
          <span>{I18n.t('datetime.clear')}</span>
        </button>
      )}
    </div>
  )

  function handleOnChange(e: React.ChangeEvent<HTMLInputElement>) {
    // Value will be formatted in YYYY-MM-DD format
    const inputValue = e.target?.value

    if (inputValue) {
      const isoString = new Date(inputValue).toISOString()
      onDateChange(isoString, formatISOToInputDateFormat(inputValue), true)
    } else {
      onDateChange('', formatISOToInputDateFormat(inputValue), false)
    }
  }
}

export default MobileDatePicker
