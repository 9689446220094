import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { useHistory, useLocation } from 'react-router'
import { toast } from 'react-toastify'

import LoginForm from './loginForm/LoginForm'

import { IRootProps } from '../../@types/types'
import { confirmPopup } from '../../components/shared/confirmPopup/ConfirmPopup'
import { IAuthenticationWrapper } from '../../services/AuthenticationWrapper/AuthenticationWrapper'
import { IUserProfile } from '../../services/TeqplayAPIService/TeqplayApi'
import { getFirebaseTokenOnce } from '../../utils/fcmUtils'
import { determineDynamicTheme, supportsThemePreference } from '../../utils/style'
import { clearUserInSentry } from '../../utils/sentry'

const LoginPage = ({ teqplayAPIService }: IAuthenticationWrapper) => {
  const currentUser = useSelector((rootProps: IRootProps) => rootProps.currentUser)
  const history = useHistory()
  const location = useLocation()
  const theme = supportsThemePreference() ? determineDynamicTheme() : 'light'

  const [loading, setLoading] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>()

  useEffect(() => {
    clearUserInSentry()
  }, [])

  useEffect(() => {
    if (!loading && currentUser.auth?.token) {
      teqplayAPIService.logoutUser()
    }
  }, [loading, currentUser, teqplayAPIService])

  return (
    <div className={theme} id={'root-loginpage'}>
      <LoginForm
        handleLogin={(u, p) => handleLogin(u, p)}
        errorMessage={errorMessage}
        loading={loading}
        backendURL={teqplayAPIService.BACKEND_URL}
      />
    </div>
  )

  async function handleLogin(username: string, password: string) {
    try {
      setErrorMessage(undefined)
      setLoading(true)

      if (!username || !password) {
        setErrorMessage(I18n.t('fillInAllFields'))
        return
      }

      const strippedUsername = username.replace(/\s+/g, '')
      await teqplayAPIService.loginUser(strippedUsername, password)

      const user = await teqplayAPIService.getUserProfile()

      if (user.gdprAcceptanceDate) {
        // Perform call to backend to set GDPR acceptance date
        history.push(`/${location.search}`)
        // Setup FCM for Push Notifications
        getFirebaseTokenOnce(teqplayAPIService)
      } else {
        confirmPopup({
          title: I18n.t('register.gdpr.title'),
          message: (
            <div className="privacy-statement">
              <p className="gdpr-line">{I18n.t('register.gdpr.privacyBodyFirstLine')}</p>
              <p className="gdpr-line">{I18n.t('register.gdpr.privacyBodySecondLine')}</p>
              <p className="gdpr-line">{I18n.t('register.gdpr.privacyBodyThirdLine')}</p>
              <p className="gdpr-line">{I18n.t('register.gdpr.privacyBodyFourthLine')}</p>
              <p className="gdpr-line">{I18n.t('register.gdpr.privacyBodyFifthLine')}</p>
              <p className="gdpr-line">{I18n.t('register.gdpr.privacyBodySixthLine')}</p>
              <p className="gdpr-line">
                {I18n.t('register.gdpr.privacyBodySeventhLine')}
                <a href="mailto:riverguide@teqplay.nl?subject=Privacy RiverGuide" target="_system">
                  riverguide@teqplay.nl
                </a>
              </p>
            </div>
          ),
          className: 'gdpr-popup',
          cancelText: I18n.t('register.gdpr.deny'),
          confirmText: I18n.t('register.gdpr.accept'),
          confirmClassName: 'button large primary',
          cancelClassName: 'button large empty',
          onConfirm: () => {
            handleUpdateGDPRApproved(user)
          },
          onCancel: () => {
            toast.error(I18n.t('register.gdpr.error'))
            teqplayAPIService.logoutUser()
            setLoading(false)
          }
        })
      }
    } catch (err) {
      if (err === 'ACCOUNT_TYPE_ERROR') {
        const errMessage = I18n.t('login.wrongAccountType')
        setErrorMessage(errMessage)
      }
      // Set error message
      let message = err.message
      if (err.status === 401 || err.status === 400) {
        // login failed
        message = I18n.t('login.wrongCredentials')
        setErrorMessage(message)
      } else {
        message = I18n.t('generalError')
        setErrorMessage(message)
      }

      setLoading(false)
    }
  }

  async function handleUpdateGDPRApproved(u: IUserProfile) {
    try {
      await teqplayAPIService.updateUserProfile({
        ...u,
        gdprAcceptanceDate: new Date().valueOf(),
        gdprOptInMethod: 'app_riverguide'
      })

      // Perform call to backend to set GDPR acceptance date
      history.push(`/${location.search}`)
      // Setup FCM for Push Notifications
      getFirebaseTokenOnce(teqplayAPIService)
    } catch (error) {
      console.error(error)
      toast.error(I18n.t('generalError'))
      setLoading(false)
    }
  }
}

export default LoginPage
