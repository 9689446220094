import L, { LatLng, Map } from 'leaflet'
import cloneDeep from 'lodash/cloneDeep'
import React, { useEffect, useState } from 'react'
import { LayerGroup } from 'react-leaflet'
import { I18n } from 'react-redux-i18n'
import { useSelector } from 'react-redux'
import { isEqual } from 'lodash'

import TeqplayApiService from '../../../services/TeqplayAPIService/TeqplayApiService'
import DefaultTransition from '../../shared/defaultTransition/DefaultTransition'
import BerthLayer from './layers/BerthLayer/BerthLayer'
import BottleneckLayer from './layers/BottleneckLayer'
import BridgesLayer from './layers/BridgesLayer'
import BuoysLayer from './layers/BuoysLayer/BuoysLayer'
import CarDropoffPointsLayer from './layers/CarDropoffPointsLayer/CarDropoffPointsLayer'
import CoverageAreaLayer from './layers/CoverageAreaLayer/CoverageAreaLayer'
import CrossingsLayer from './layers/CrossingsLayer/CrossingsLayer'
import CurrentsLayer from './layers/CurrentsLayer/CurrentsLayer'
import DepthsLayer from './layers/DepthsLayer/DepthsLayer'
import DrinkingWaterPointsLayer from './layers/DrinkingWaterPointsLayer/DrinkingWaterPointsLayer'
import ECDISLayer from './layers/ECDISLayer/ECDISLayer'
import FairwaysLayer from './layers/FairwaysLayer/FairwaysLayer'
import KilometreringsLayer from './layers/KilometreringsLayer/KilometreringsLayer'
import LocksLayer from './layers/LocksLayer'
import SABCollectionPointsLayer from './layers/SABCollectionPointsLayer/SABCollectionPointsLayer'
import ShorePowerLayer from './layers/ShorePowerLayer/ShorePowerLayer'
import WaterwaySignsLayer from './layers/WaterwaySignsLayer/WaterwaySignsLayer'
import WavesLayer from './layers/WavesLayer/WavesLayer'
import WaterHeightLayer from './layers/WaterHeightLayer/WaterHeightLayer'
import LayerZoomWarning from './LayerZoomWarning'

import { useAnalytics } from '../../../services/AnalyticsWrapper/AnalyticsWrapper'
import { IBridgeMovement, IShipInfo } from '../../../services/TeqplayAPIService/TeqplayApi'
import {
  BACKEND_URL,
  DEFAULT_LAYER_RESTRICTION,
  LAYERS_WITH_ZOOM_LEVEL_RESTRICTIONS,
  LAYER_COVERAGE,
  LAYER_LIST,
  MAP_TYPE_OPTIONS
} from '../../../utils/constants'
import { parseStringForID } from '../../../utils/tests'
import { formatLayerStateName } from '../../mapShared/mapUtils'
import { showDataWarning, showLimitedCoveragePopup } from './LayerPopups'
import OperatingAreasLayer from './layers/OperatingAreasLayer'
import {
  IApplicationTheme,
  MapTypes,
  SuppressedWarnings,
  SelectedItemModalEnum,
  IRootProps,
  ILayerCodeName,
  ILayerCoverageDetails
} from '../../../@types/types'
import { usePrevious } from '../../../hooks/usePrevious'

import './CustomLayers.scss'

interface IProps {
  mapApi: Map | null
  activeLayers: ILayerCodeName[]
  token: string
  popupActive: boolean
  activeMap: MapTypes
  teqplayApiService: TeqplayApiService
  mapZoomLevel: number
  onChangeActiveLayers: (activeLayers: ILayerCodeName[]) => void
  togglePopupActive: (hide?: boolean) => void
  onChangeActiveMap: (activeMap: MapTypes) => void
  currentLocation: IShipInfo | null
  clickedLocation?: LatLng | null
  suppressedWarnings: SuppressedWarnings
  setSuppressedWarnings: (
    l: ILayerCodeName,
    prompts: { [prompt in keyof ILayerCoverageDetails['prompts']]: boolean }
  ) => void
  showLimitedRangeLayer: boolean
  theme: IApplicationTheme
  bridgeMovement?: IBridgeMovement[]

  selectedItem: SelectedItemModalEnum | undefined
  setSelectedItem: (newItem: SelectedItemModalEnum | undefined) => void
  disableInteraction?: boolean
}

const CustomLayers = ({
  activeLayers,
  token,
  mapApi,
  onChangeActiveLayers,
  popupActive,
  togglePopupActive,
  onChangeActiveMap,
  activeMap,
  teqplayApiService,
  mapZoomLevel,
  currentLocation,
  clickedLocation,
  suppressedWarnings,
  setSuppressedWarnings,
  showLimitedRangeLayer,
  theme,
  bridgeMovement,
  selectedItem,
  setSelectedItem,
  disableInteraction
}: IProps) => {
  const locale = useSelector((s: IRootProps) => s.i18n.locale)
  const analytics = useAnalytics('CustomLayers')
  const bounds = mapApi && mapApi.getBounds ? mapApi.getBounds() : undefined
  const zoom = mapApi && mapApi.getZoom ? mapApi.getZoom() : undefined
  const defaultLayerProps = { bounds, zoom, token, url: BACKEND_URL, disableInteraction }
  // Used only in BerthLayer, but saved here to display amount of cones
  const [cones, setCones] = useState<number | undefined>()
  const previousActiveLayers = usePrevious(activeLayers)

  useEffect(() => {
    // Clear active selected item in case it is origined from a layer which is now inactive
    if (!isEqual(previousActiveLayers, activeLayers) && selectedItem) {
      handleDismissSelectedItemOnLayerChange()
    }
    // This useEffect is in here and not a level above so it only has to be written once and can be applied to both maps (RouteSelection, Navigation)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeLayers])

  if (!popupActive) {
    if (mapApi && mapApi.scrollWheelZoom) {
      mapApi.scrollWheelZoom.enable()
    }
  }

  return (
    <>
      <LayerZoomWarning
        mapZoomLevel={mapZoomLevel}
        layersWithZoomLevelRestrictions={LAYERS_WITH_ZOOM_LEVEL_RESTRICTIONS}
        activeLayers={activeLayers}
      />

      <DefaultTransition active={popupActive} slideFrom="bottom">
        {props => (
          <>
            <div
              className="click-outside-layer"
              onClick={() => popupActive && togglePopupActive(true)}
            />
            <div
              style={props}
              id="custom-layer-control-wrapper"
              className="layer-control-wrapper"
              ref={customLayer => {
                if (customLayer) {
                  L.DomEvent.disableClickPropagation(customLayer)
                }
              }}
              onMouseEnter={e => {
                if (mapApi) {
                  mapApi.scrollWheelZoom.disable()
                }
              }}
              onMouseLeave={e => {
                if (mapApi) {
                  mapApi.scrollWheelZoom.enable()
                }
              }}
            >
              <button
                className="button close-button"
                id="button-menu-close"
                onClick={() => {
                  togglePopupActive()
                  if (mapApi) {
                    mapApi.scrollWheelZoom.enable()
                  }
                }}
              >
                <span>{I18n.t('close')}</span>
                <i className="icon-cancel" />
              </button>
              <div className="custom-layer-control">
                {Object.keys(LAYER_LIST).map(categoryName => (
                  <div className="category" key={categoryName}>
                    <div className="category-name">{I18n.t('map.category.' + categoryName)}</div>
                    <div className="layer-buttons">
                      {LAYER_LIST[categoryName].map((layer: ILayerCodeName) => {
                        const translation = I18n.t(`map.layers.${layer}`)
                        const formattedName = formatLayerStateName(translation)
                        const layerInfo = LAYER_COVERAGE.find(l => l.codeName === layer)

                        return (
                          <button
                            key={layer}
                            className={`layer-button ${
                              activeLayers.includes(layer) ? 'active' : ''
                            }`}
                            id={parseStringForID(`layer-button-${layer}`)}
                            onClick={() => handleClickLayer(layer)}
                          >
                            <div className="content">
                              <span className={`layer-icon ${getLayerIcon(layer)}`} />
                              <label htmlFor={formattedName}>{translation}</label>
                            </div>
                            {layerInfo?.prompts.hasLimitedRange && (
                              <div className="limited-range">
                                <i className="icon-info-circled" />
                                <span>{I18n.t('map.limitedRange')}</span>
                              </div>
                            )}
                            {activeLayers.includes('suitable_berths') &&
                            cones !== undefined &&
                            cones >= 0 &&
                            layer === 'suitable_berths' ? (
                              <div className="limited-range">
                                <span>{I18n.t('mapLayers.berthLayer.withCones', { cones })}</span>
                              </div>
                            ) : null}
                          </button>
                        )
                      })}
                    </div>
                  </div>
                ))}

                <div className="map-types">
                  <div className="category-name">{I18n.t('map.category.maptype')}</div>
                  {MAP_TYPE_OPTIONS.map(mapType => (
                    <div
                      className={`map-type ${mapType}${mapType === activeMap ? ' active' : ''}`}
                      onClick={() => onChangeActiveMap(mapType)}
                      key={mapType}
                      id={`button-map-type-${mapType}`}
                    />
                  ))}
                </div>
              </div>
            </div>
          </>
        )}
      </DefaultTransition>

      <LayerGroup>
        <BerthLayer
          name={I18n.t('map.layers.public_berths')}
          teqplayApiService={teqplayApiService}
          minimumZoomLevel={getMapZoomRestriction('public_berths')}
          mapZoomLevel={mapZoomLevel}
          ship={currentLocation}
          types={{
            suitable: activeLayers.includes('suitable_berths'),
            terminal: activeLayers.includes('terminal_berths'),
            public: activeLayers.includes('public_berths')
          }}
          cones={cones}
          setCones={setCones}
          {...defaultLayerProps}
        />
        <BridgesLayer
          name={I18n.t('map.layers.bridges')}
          checked={activeLayers.includes('bridges')}
          teqplayApiService={teqplayApiService}
          minimumZoomLevel={getMapZoomRestriction('bridges')}
          mapZoomLevel={mapZoomLevel}
          bridgeMovement={bridgeMovement}
          setSelectedItem={item => setSelectedItem({ type: 'BRIDGE', item })}
          {...defaultLayerProps}
        />
        <DepthsLayer
          name={I18n.t('map.layers.depths')}
          checked={activeLayers.includes('depths')}
          teqplayApiService={teqplayApiService}
          bounds={bounds}
          color={
            (activeMap === 'DEFAULT' && theme === 'dark') || activeMap === 'SATELLITE'
              ? 'rgba(200,200,255,1)'
              : ''
          }
        />
        <DrinkingWaterPointsLayer
          name={I18n.t('map.layers.drinkwaterPoints')}
          checked={activeLayers.includes('drinkwaterPoints')}
          bounds={bounds}
          teqplayApiService={teqplayApiService}
          mapZoomLevel={mapZoomLevel}
          minimumZoomLevel={getMapZoomRestriction('drinkwaterPoints')}
          setSelectedItem={item => setSelectedItem({ type: 'DRINKWATERPOINT', item })}
          locale={locale}
          disableInteraction={disableInteraction}
        />
        <FairwaysLayer
          name={I18n.t('map.layers.fairways')}
          checked={activeLayers.includes('fairways')}
          teqplayApiService={teqplayApiService}
          bounds={bounds}
          setSelectedItem={item => setSelectedItem({ type: 'FAIRWAY', item })}
          selectedItem={selectedItem?.type === 'FAIRWAY' && selectedItem.item}
          disableInteraction={disableInteraction}
        />
        <LocksLayer
          name={I18n.t('map.layers.locks')}
          checked={activeLayers.includes('locks')}
          teqplayApiService={teqplayApiService}
          mapZoomLevel={mapZoomLevel}
          minimumZoomLevel={getMapZoomRestriction('locks')}
          bounds={bounds}
          setSelectedItem={item => setSelectedItem({ type: 'LOCK', item })}
          disableInteraction={disableInteraction}
        />
        <WavesLayer
          name={I18n.t('map.layers.waveHeight')}
          checked={activeLayers.includes('waveHeight')}
          teqplayApiService={teqplayApiService}
          setSelectedItem={item => setSelectedItem({ type: 'WAVEHEIGHT', item })}
          {...defaultLayerProps}
        />
        <CurrentsLayer
          name={I18n.t('map.layers.currents')}
          checked={activeLayers.includes('currents')}
          teqplayApiService={teqplayApiService}
          zoom={zoom}
        />
        <BottleneckLayer
          name={I18n.t('map.layers.bottlenecks')}
          checked={activeLayers.includes('bottlenecks')}
          teqplayApiService={teqplayApiService}
          bounds={bounds}
        />
        {activeLayers.includes('operatingAreas') && (
          <OperatingAreasLayer
            teqplayApiService={teqplayApiService}
            bounds={bounds}
            selectedItem={selectedItem?.type === 'OPERATING_AREA' && selectedItem.item}
            setSelectedItem={item => setSelectedItem({ type: 'OPERATING_AREA', item })}
            disableInteraction={disableInteraction}
          />
        )}
        <BuoysLayer
          name={I18n.t('map.layers.buoys')}
          checked={activeLayers.includes('buoys')}
          teqplayApiService={teqplayApiService}
          bounds={bounds}
          minimumZoomLevel={getMapZoomRestriction('buoys')}
          mapZoomLevel={mapZoomLevel}
          disableInteraction={disableInteraction}
        />
        <WaterwaySignsLayer
          name={I18n.t('map.layers.waterwaySigns')}
          checked={activeLayers.includes('waterwaySigns')}
          teqplayApiService={teqplayApiService}
          bounds={bounds}
          minimumZoomLevel={getMapZoomRestriction('waterwaySigns')}
          mapZoomLevel={mapZoomLevel}
          disableInteraction={disableInteraction}
        />
        <WaterHeightLayer
          name={I18n.t('map.layers.waterHeight')}
          checked={activeLayers.includes('waterHeight')}
          teqplayApiService={teqplayApiService}
          bounds={bounds}
          minimumZoomLevel={getMapZoomRestriction('waterHeight')}
          setSelectedItem={item => setSelectedItem({ type: 'WATER_HEIGHT', item })}
          mapZoomLevel={mapZoomLevel}
          disableInteraction={disableInteraction}
        />
        <KilometreringsLayer
          name={I18n.t('map.layers.kilometrering')}
          checked={activeLayers.includes('kilometrering')}
          teqplayApiService={teqplayApiService}
          bounds={bounds}
          minimumZoomLevel={getMapZoomRestriction('kilometrering')}
          mapZoomLevel={mapZoomLevel}
        />
        <CrossingsLayer
          name={I18n.t('map.layers.crossings')}
          checked={activeLayers.includes('crossings')}
          zoom={zoom}
          dotsZoomLimit={11}
          showDots
          setSelectedItem={item => setSelectedItem({ type: 'CROSSING', item })}
          disableInteraction={disableInteraction}
        />
        <SABCollectionPointsLayer
          name={I18n.t('map.layers.sabPoints')}
          checked={activeLayers.includes('sabPoints')}
          bounds={bounds}
          teqplayApiService={teqplayApiService}
          mapZoomLevel={mapZoomLevel}
          minimumZoomLevel={getMapZoomRestriction('sabPoints')}
          setSelectedItem={item => setSelectedItem({ type: 'SABPOINT', item })}
          locale={locale}
          disableInteraction={disableInteraction}
        />
        <CarDropoffPointsLayer
          name={I18n.t('map.layers.carDropffPoints')}
          checked={activeLayers.includes('carDropoffPoints')}
          bounds={bounds}
          teqplayApiService={teqplayApiService}
          mapZoomLevel={mapZoomLevel}
          minimumZoomLevel={getMapZoomRestriction('carDropoffPoints')}
          setSelectedItem={item => setSelectedItem({ type: 'CARDROPOFF', item })}
          disableInteraction={disableInteraction}
        />
        <ShorePowerLayer
          name={I18n.t('map.layers.shorePower')}
          checked={activeLayers.includes('shorePower')}
          bounds={bounds}
          teqplayApiService={teqplayApiService}
          mapZoomLevel={mapZoomLevel}
          minimumZoomLevel={getMapZoomRestriction('shorePower')}
          setSelectedItem={item => setSelectedItem({ type: 'SHOREPOWER', item })}
          disableInteraction={disableInteraction}
        />
        <ECDISLayer checked={activeLayers.includes('ECDIS')} />
        <CoverageAreaLayer activeLayers={activeLayers} checked={showLimitedRangeLayer} />
      </LayerGroup>
    </>
  )

  function getLayerIcon(layerName: ILayerCodeName) {
    switch (layerName) {
      case 'crossings':
        return 'icon-map-pin'
      case 'bridges':
        return 'icon-bridge-open'
      case 'kilometrering':
        return 'icon-kilometer'
      case 'public_berths':
      case 'terminal_berths':
      case 'suitable_berths':
        return 'icon-bolder'
      case 'depths':
        return 'icon-depth'
      case 'waterHeight':
        return 'icon-depth'
      case 'waveHeight':
      case 'currents':
        return 'icon-waves'
      case 'locks':
        return 'icon-lock'
      case 'drinkwaterPoints':
        return 'icon-drinkwaterpoint'
      case 'buoys':
        return 'icon-buoy'
      case 'waterwaySigns':
        return 'icon-attention-circled'
      case 'fairways':
        return 'icon-gauge'
      case 'bottlenecks':
        return 'icon-resize-small'
      case 'ECDIS':
        return 'icon-map'
      case 'sabPoints':
        return 'icon-trash'
      case 'carDropoffPoints':
        return 'icon-cab'
      case 'shorePower':
        return 'icon-plug'
      case 'operatingAreas':
        return 'icon-map'
      default:
        return ''
    }
  }

  function getMapZoomRestriction(layerName: ILayerCodeName) {
    const layer = LAYERS_WITH_ZOOM_LEVEL_RESTRICTIONS.find(x => x.layerName === layerName)
    if (layer) {
      return layer.minZoomLevel
    } else {
      return DEFAULT_LAYER_RESTRICTION
    }
  }

  function handleClickLayer(clickedLayer: ILayerCodeName) {
    const layerCoverage = LAYER_COVERAGE.find(l => l.codeName === clickedLayer)
    let newActiveLayers = cloneDeep(activeLayers)
    // Handles the rest of the layers ( of which more than 1 can be active )
    if (newActiveLayers.includes(clickedLayer)) {
      newActiveLayers = newActiveLayers.filter(layer => layer !== clickedLayer)
    } else {
      newActiveLayers.push(clickedLayer)
    }

    if (!activeLayers.includes(clickedLayer)) {
      // Show warning prompts
      if (
        layerCoverage?.prompts.hasLimitedRange &&
        !suppressedWarnings[clickedLayer]?.hasLimitedRange
      ) {
        showLimitedCoveragePopup(clickedLayer, layerCoverage, () => {
          setSuppressedWarnings(clickedLayer, { hasLimitedRange: true })

          if (!layerCoverage.prompts.highDataUsage) {
            analytics.newEvent('set_suppressed_warning', { clickedLayer, hasLimitedRange: true })
            analytics.newEvent('map_layer_enable', { clickedLayer })
            analytics.newEvent('enabled_layer_' + clickedLayer, { clickedLayer })
            onChangeActiveLayers(newActiveLayers)
          }
        })
      }

      if (
        (clickedLayer === 'public_berths' || clickedLayer === 'terminal_berths') &&
        newActiveLayers.includes('suitable_berths')
      ) {
        newActiveLayers = newActiveLayers.filter(l => l !== 'suitable_berths')
      } else if (
        clickedLayer === 'suitable_berths' &&
        (activeLayers.includes('public_berths') || activeLayers.includes('terminal_berths'))
      ) {
        newActiveLayers = newActiveLayers.filter(
          l => l !== 'public_berths' && l !== 'terminal_berths'
        )
      }

      if (
        layerCoverage?.prompts.highDataUsage &&
        !suppressedWarnings[clickedLayer]?.highDataUsage
      ) {
        showDataWarning(clickedLayer, layerCoverage, () => {
          setSuppressedWarnings(clickedLayer, { highDataUsage: true })
          analytics.newEvent('set_suppressed_warning', { clickedLayer, highDataUsage: true })
          analytics.newEvent('map_layer_enable', { clickedLayer })
          analytics.newEvent('enabled_layer_' + clickedLayer, { clickedLayer })
          onChangeActiveLayers(newActiveLayers)
        })
      } else {
        analytics.newEvent('map_layer_enable', { clickedLayer })
        analytics.newEvent('enabled_layer_' + clickedLayer, { clickedLayer })
        onChangeActiveLayers(newActiveLayers)
      }
    } else {
      // Disabling layer, show nothing
      analytics.newEvent('map_layer_disable', { clickedLayer })
      onChangeActiveLayers(newActiveLayers)
    }
  }

  function handleDismissSelectedItemOnLayerChange() {
    if (selectedItem) {
      if (
        // If an item is selected but the layer is not active anyore
        (selectedItem.type === 'BRIDGE' && !activeLayers.includes('bridges')) ||
        (selectedItem.type === 'CARDROPOFF' && !activeLayers.includes('carDropoffPoints')) ||
        (selectedItem.type === 'CROSSING' && !activeLayers.includes('crossings')) ||
        (selectedItem.type === 'DRINKWATERPOINT' && !activeLayers.includes('drinkwaterPoints')) ||
        (selectedItem.type === 'LOCK' && !activeLayers.includes('locks')) ||
        (selectedItem.type === 'SABPOINT' && !activeLayers.includes('sabPoints')) ||
        (selectedItem.type === 'SHOREPOWER' && !activeLayers.includes('shorePower')) ||
        (selectedItem.type === 'WAVEHEIGHT' && !activeLayers.includes('waveHeight'))
      ) {
        // Dismiss the selected item
        setSelectedItem(undefined)
      }
    }
  }
}

export default CustomLayers
