import * as React from 'react'

import TeqplayApiService from '../../services/TeqplayAPIService/TeqplayApiService'
import SideMenu from '../sideMenu/SideMenu'

import ActiveRouteControls from './activeRouteControls/ActiveRouteControls'
import RouteSelectionControls from './routeSelectionControls/RouteSelectionControls'

import { IApplicationTheme, IRouteSearchProps, ITabs } from '../../@types/types'
import {
  IRoute,
  ISelectedRoute,
  IShipInfo,
  IShipNotificationStatus,
  IUserProfile
} from '../../services/TeqplayAPIService/TeqplayApi'

import './NavigationFooter.scss'

export interface IProps {
  navigationRoute: ISelectedRoute | null
  isRoutePaused: boolean
  userProfile: IUserProfile | null
  shipName: string
  activePage: string
  userLocation: IShipInfo | null
  logout: () => void

  // Route selection controls
  routeSelection?: IRouteSearchProps
  routeSuggestions?: IRoute[] | null
  selectedRouteSuggestion?: IRoute | null
  teqplayApiService: TeqplayApiService
  resetAvailableRoutes?: () => void
  setSelectedRouteSuggestion?: (routeSuggestion: IRoute | null) => void
  startRoute?: (route: IRoute) => void
  setBICSRoute?: (routeSuggestion: IRoute | null) => void
  shipNotificationStatus?: IShipNotificationStatus | null
  toggleOptions?: () => void
  optionsActive?: boolean
  pauseRoute?: () => void
  resumeRoute?: () => void
  stopRoute?: () => void
  selectedTab?: ITabs

  // UI stuff
  theme: IApplicationTheme
  setStatusbarTheme: (theme?: IApplicationTheme) => void
}

const NavigationFooter = (props: IProps) => {
  const {
    activePage,
    userProfile,
    shipName,
    logout,
    navigationRoute,
    isRoutePaused,
    resumeRoute,
    pauseRoute,
    stopRoute,
    optionsActive,
    routeSuggestions,
    theme,
    setStatusbarTheme
  } = props
  const [openedRouteControls, toggleRouteControls] = React.useState(false)

  const appendedClasses = [
    optionsActive ? 'optionsActive' : '',
    navigationRoute ? 'routeActive' : '',
    isRoutePaused ? 'paused' : '',
    openedRouteControls ? 'openedRouteControls' : '',
    routeSuggestions && routeSuggestions.length > 0 ? 'selecting' : 'browsing'
  ]

  return (
    <div className={`bottom-navigation-bar ${appendedClasses.join(' ')}`}>
      <SideMenu
        activePage={activePage}
        userProfile={userProfile}
        shipName={shipName}
        logout={logout}
        navigationRoute={navigationRoute}
        theme={theme}
        setStatusbarTheme={setStatusbarTheme}
      />

      <ActiveRouteControls
        navigationRoute={navigationRoute}
        isRoutePaused={isRoutePaused}
        resumeRoute={resumeRoute}
        stopRoute={stopRoute}
        pauseRoute={pauseRoute}
        openedRouteControls={openedRouteControls}
        toggleRouteControls={toggleRouteControls}
      />

      <RouteSelectionControls
        routeSelection={props.routeSelection}
        routeSuggestions={props.routeSuggestions}
        selectedRouteSuggestion={props.selectedRouteSuggestion}
        setSelectedRouteSuggestion={props.setSelectedRouteSuggestion}
        teqplayApiService={props.teqplayApiService}
        resetAvailableRoutes={props.resetAvailableRoutes}
        startRoute={props.startRoute}
        selectedTab={props.selectedTab}
        setBICSRoute={props.setBICSRoute}
      />
    </div>
  )
}

export default NavigationFooter
