import React from 'react'
import { useSessionStorage } from '../../../hooks/useSessionStorage'
import { useAnalytics } from '../../../services/AnalyticsWrapper/AnalyticsWrapper'

interface IProps {
  vhfChannel?: string | null | undefined
  nextVhfChannel?: string | null
  vhfSectorName?: string
}

const VHFBox: React.FC<IProps> = props => {
  const analytics = useAnalytics('VHFBox')
  const { vhfChannel, vhfSectorName, nextVhfChannel } = props
  const [showVHFName, setShowVHFName] = useSessionStorage('show-VHF-name', false)
  const isClickable = vhfSectorName

  if (!props.vhfChannel && !props.vhfSectorName) {
    return null
  }

  return (
    <div
      className={`tab secondary ${showVHFName ? 'showing-sector' : ''} ${
        isClickable ? 'clickable' : ''
      }`}
      onClick={handleToggleShowVHFName}
      id="vhf-box-button"
    >
      {showVHFName ? (
        vhfSectorName
      ) : (
        <React.Fragment>
          VHF {vhfChannel}
          {nextVhfChannel && (
            <>
              <span className="header-icon icon-right-dir vhf-next-icon" />
              {nextVhfChannel}
            </>
          )}
        </React.Fragment>
      )}
    </div>
  )

  function handleToggleShowVHFName() {
    analytics.newEvent('toggle_vhfbox', {
      showVHFName: !showVHFName,
      vhf: { vhfChannel, vhfSectorName, nextVhfChannel }
    })
    setShowVHFName(!showVHFName)
  }
}

export default VHFBox
