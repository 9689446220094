import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import ReactCountryFlag from 'react-country-flag'
import { I18n } from 'react-redux-i18n'
import Select, { ValueType } from 'react-select'
import { toast } from 'react-toastify'

import TeqplayApiService from '../../../services/TeqplayAPIService/TeqplayApiService'

import { useDebounce } from '../../../hooks/useDebounce'
import { usePrevious } from '../../../hooks/usePrevious'
import { BicsReportingPoint } from '../../../services/TeqplayAPIService/TeqplayApi'
import { selectStyles, sortByKey } from '../../../utils/general'
import { IRootProps } from '../../../@types/types'

import './BICSReportingPointPicker.scss'

interface IProps {
  value: BicsReportingPoint | undefined
  departureIVSCode?: string | undefined
  voyageProviderId: string | undefined
  onChange: (value: BicsReportingPoint | undefined) => void
  teqplayAPIService: TeqplayApiService
  placeholder?: string
  isClearable?: boolean
  additionalId?: string
  disabled?: boolean
  travelId?: string | number
}

const BICSReportingPointPicker = ({
  value,
  departureIVSCode,
  voyageProviderId,
  onChange,
  teqplayAPIService,
  placeholder,
  isClearable,
  additionalId,
  disabled,
  travelId
}: IProps) => {
  const [reportingPoints, setReportingPoints] = useState<BicsReportingPoint[]>([])
  const [searchValue, setSearchValue] = useState<string>('')
  const [loading, setLoading] = useState(false)
  const [initialized, setInitialized] = useState(false)

  const [fromProviderId, setFromProviderId] = useState(false)
  const locale = useSelector((s: IRootProps) => s.i18n.locale)

  const previousIVSCode = usePrevious(departureIVSCode)
  const memoizedReportingPointResolve = useCallback(
    restoreInitialReportingId,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [departureIVSCode, onChange, value, voyageProviderId]
  )

  const debouncedSearchValue = useDebounce(searchValue, 400)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedReportingPointSearch = useCallback(getReportingPointsByQuery, [teqplayAPIService])
  const previousTravelId = usePrevious(travelId)

  useEffect(() => {
    // Execute search if search value is present
    if (debouncedSearchValue.length >= 1) {
      memoizedReportingPointSearch(debouncedSearchValue)
    } else {
      setReportingPoints([])
    }
  }, [debouncedSearchValue, memoizedReportingPointSearch])

  useEffect(() => {
    // When the travel Id changes but the component is still the same (page is not refreshed)
    // then component needs to reinitialize potentially from voyageProviderId
    if (travelId !== previousTravelId && travelId && previousTravelId) {
      setInitialized(false)
    }
  }, [travelId, previousTravelId])

  useEffect(() => {
    if (!initialized && !loading && (value || departureIVSCode || voyageProviderId)) {
      memoizedReportingPointResolve()
    } else if (departureIVSCode !== previousIVSCode && departureIVSCode && fromProviderId) {
      // Upon editing the departure location and it is fromProviderId
      // Recalculate reporting point
      memoizedReportingPointResolve(true)
    }
  }, [
    initialized,
    memoizedReportingPointResolve,
    loading,
    value,
    departureIVSCode,
    voyageProviderId,
    fromProviderId,
    previousIVSCode
  ])

  useEffect(() => {
    // Remove value in case it is fromProviderId
    if (fromProviderId && !departureIVSCode && previousIVSCode) {
      onChange(undefined)
      setFromProviderId(false)
      setInitialized(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fromProviderId, departureIVSCode, previousIVSCode])

  return (
    <div className="reporting-point-wrapper">
      <Select<BicsReportingPoint>
        isSearchable
        value={value}
        onChange={(destination: ValueType<BicsReportingPoint>) => {
          onChange(destination as BicsReportingPoint)

          // Set fromProviderId and Initialized as true when editing the destination manually
          // To prevent any unwanted changes
          setFromProviderId(false)
          setInitialized(true)
        }}
        inputValue={searchValue}
        onInputChange={(newValue, { action }) => {
          if (action !== 'menu-close' && action !== 'input-blur') {
            setSearchValue(newValue)
          }
        }}
        formatOptionLabel={destination => (
          <div
            className={`reporting-row${disabled ? ' disabled' : ''}`}
            data-srscode={destination.srsCode}
          >
            <div className="left">
              {destination.srsCode && (
                <ReactCountryFlag
                  countryCode={destination.srsCode?.substr(0, 2)}
                  className="flag"
                  svg
                />
              )}
              <div className="names">
                <div className="name main">{destination.name || destination.nameLocal}</div>
                {destination.name &&
                  destination.nameLocal &&
                  destination.name !== destination.nameLocal && (
                    <div className="name local">{destination.nameLocal}</div>
                  )}
              </div>
            </div>
          </div>
        )}
        openMenuOnFocus
        maxMenuHeight={250}
        placeholder={placeholder || I18n.t('destinationSelect.placeholder')}
        isClearable={isClearable}
        noOptionsMessage={({ inputValue }) =>
          inputValue.length > 2 && searchValue === debouncedSearchValue && !loading
            ? I18n.t('destinationSelect.noOptions', { query: inputValue })
            : I18n.t('destinationSelect.placeholder')
        }
        styles={selectStyles}
        options={reportingPoints}
        filterOption={() => true}
        isLoading={loading}
        inputId={`destination-picker-INPUT_${additionalId}`}
        classNamePrefix="bics-destination generic-select selector"
        getOptionValue={destination => destination.name || 'no-nameLocal'}
        isDisabled={disabled}
      />
      {fromProviderId && (
        <div className="derived-from">{I18n.t('destinationSelect.derivedFromProviderId')}</div>
      )}
    </div>
  )

  async function getReportingPointsByQuery(query: string): Promise<void> {
    try {
      const results = await teqplayAPIService.getBICSReportingPoints(query, locale)
      setReportingPoints(sortByKey(results, 'name'))
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  async function restoreInitialReportingId(forceCheckNewCode?: boolean): Promise<void> {
    setLoading(true)

    if (voyageProviderId) {
      // Voyage is being edited, should be reinitialized as value
      try {
        // Refetch from voyageProviderId
        const reportPointList = await teqplayAPIService.getBICSReportingPoints(
          voyageProviderId,
          locale
        )
        onChange(reportPointList[0])

        if (reportPointList[0] && departureIVSCode) {
          // Perform extra check to see if it is the same as the derived value
          await checkIfReportPointIsSameAsDerived(reportPointList[0], departureIVSCode)
        }
      } catch (error) {
        toast.error(I18n.t('destinationSelect.errorNotFound', { pid: voyageProviderId }))
        setSearchValue(voyageProviderId)
      }

      setInitialized(true)
    } else if ((!value && departureIVSCode) || (forceCheckNewCode && departureIVSCode)) {
      // No current value is selected, departure location is selected and has IVS code
      // Derive firstReportingPoint from corresponding ivsCode
      try {
        const reportingPoint = await resolveSingleIdToValue(departureIVSCode)
        if (reportingPoint) {
          // Set reporting point in state
          onChange(reportingPoint)
          // Mark that it has been derived from the departure location
          setFromProviderId(true)
          setInitialized(true)
        } else if (forceCheckNewCode && !reportingPoint) {
          // No reporting point found for id, clear id
          onChange(undefined)
          setFromProviderId(false)
          setInitialized(false)
        }
      } catch (error) {
        console.error(error)
        // Upon error, force user to fill in manually
        onChange(undefined)
        setFromProviderId(false)
        setInitialized(true)
      }

      setInitialized(true)
    } else if (value && departureIVSCode && !initialized) {
      // Both value from session present and IVSCode from departure is present,
      // Check if the value is the same as the derived departure IVS code
      await checkIfReportPointIsSameAsDerived(value, departureIVSCode)
      setInitialized(true)
    }

    setLoading(false)
  }

  async function resolveSingleIdToValue(pid: string): Promise<BicsReportingPoint | undefined> {
    try {
      return await teqplayAPIService.resolveBICSReportingPoint(pid)
    } catch (error) {
      throw new Error('Could not derive point')
    } finally {
      setLoading(false)
    }
  }

  async function checkIfReportPointIsSameAsDerived(point: BicsReportingPoint, depIVS: string) {
    try {
      const derivedReportingPoint = await resolveSingleIdToValue(depIVS)

      if (derivedReportingPoint?.verkeerspostId === point.verkeerspostId) {
        setFromProviderId(true)
      } else {
        setFromProviderId(false)
      }

      setInitialized(true)
    } catch (error) {
      console.error(error)
    }
  }
}

export default BICSReportingPointPicker
