import { LatLngBounds } from 'leaflet'
import isEqual from 'lodash/isEqual'
import React, { useEffect, useState } from 'react'

import { usePrevious } from '../../../../hooks/usePrevious'
import { ISingleLocation } from '../../../../services/TeqplayAPIService/TeqplayApi'
import TeqplayApiService from '../../../../services/TeqplayAPIService/TeqplayApiService'
import DefaultPolygonLayer from './defaultPolygonLayer/DefaultPolygonLayer'

interface IProps {
  teqplayApiService: TeqplayApiService
  name: string
  checked: boolean
  bounds: LatLngBounds | undefined
}

export interface IBottleneck {
  locatableType: string
  polygons: {
    coordinates: [number, number]
  }[][]
  singleLocation: ISingleLocation
  _id: string
}

const BottleneckLayer = (props: IProps) => {
  return (
    <DefaultPolygonLayer<IBottleneck>
      fetchCall={(bounds?: LatLngBounds) => props.teqplayApiService.fetchBottlenecks(bounds)}
      color={'#2e6771'}
      {...props}
    />
  )
}

export function useBottlenecks(
  apiService: TeqplayApiService,
  bounds?: LatLngBounds,
  isChecked?: boolean
) {
  const [bottlenecks, setBottlenecks] = useState<IBottleneck[] | null>(null)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [error, setError] = useState<any | null>(null)

  const previousBounds = usePrevious(bounds)

  useEffect(() => {
    async function fetchBottlenecks() {
      try {
        setError(null)
        const fetchedBottlenecks = await apiService.fetchBottlenecks(bounds)
        setBottlenecks(fetchedBottlenecks)
      } catch (err) {
        setError(err)
        throw err
      }
    }

    if (!isChecked === false && !isEqual(bounds?.getCenter(), previousBounds?.getCenter())) {
      fetchBottlenecks()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isChecked, error, bounds])

  return { bottlenecks, error }
}

export default BottleneckLayer
