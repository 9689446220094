import React, { useState, useEffect } from 'react'
import { I18n } from 'react-redux-i18n'
import Select, { ValueType } from 'react-select'
import { toast } from 'react-toastify'

import BICSHullEntry from '../bicsHullEntry/BICSHullEntry'

import { BicsHull } from '../../../services/TeqplayAPIService/TeqplayApi'
import { selectStyles } from '../../../utils/general'

interface IProps {
  value: string | undefined
  onChange: (value: BicsHull | undefined) => void
  hulls: BicsHull[]
  mainHull: BicsHull | undefined
  placeholder?: string
  isClearable?: boolean
  additionalId?: string
  disabled?: boolean
}

const BICSHullSelector = ({
  value,
  onChange,
  hulls,
  mainHull,
  placeholder,
  isClearable,
  additionalId,
  disabled
}: IProps) => {
  const [searchValue, setSearchValue] = useState<string>('')
  const mappedValue = value ? hulls.find(h => h.eni === value || h.mmsi === value) : undefined

  useEffect(() => {
    // In case the hull is not bound anymore to the voyage
    // reset the cargo bound hull
    if (!mappedValue && value) {
      onChange(undefined)
      toast.error(I18n.t('hullSelect.unboundShipId'))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Select<BicsHull>
      isSearchable
      value={mappedValue}
      onChange={(hull: ValueType<BicsHull>) => onChange(hull as BicsHull)}
      inputValue={searchValue}
      onInputChange={(newValue, { action }) => {
        if (action !== 'menu-close' && action !== 'input-blur') {
          setSearchValue(newValue)
        }
      }}
      getOptionLabel={hull => hull.name || hull.mmsi || hull.eni}
      formatOptionLabel={(hull, labelMeta) => {
        return (
          <BICSHullEntry
            hull={hull}
            mainHull={mainHull}
            showCargoTags={true}
            className={labelMeta.context}
          />
        )
      }}
      openMenuOnFocus
      placeholder={placeholder || I18n.t('hullSelect.placeholder')}
      isClearable={isClearable}
      noOptionsMessage={({ inputValue }) =>
        inputValue.length > 2
          ? I18n.t('hullSelect.noOptions', { query: inputValue })
          : I18n.t('hullSelect.placeholder')
      }
      styles={selectStyles}
      options={getAllOptions()}
      filterOption={(option, rawInput) => {
        const hull = option.data as BicsHull
        return (
          hull.eni.toLowerCase().includes(rawInput.toLowerCase()) ||
          (hull.mmsi || '').toLowerCase()?.includes(rawInput.toLowerCase()) ||
          hull.name.toLowerCase().includes(rawInput.toLowerCase()) ||
          (hull.callSign || '').toLowerCase()?.includes(rawInput.toLowerCase())
        )
      }}
      inputId={`hull-picker-INPUT_${additionalId}`}
      classNamePrefix={`hull-selector generic-select selector`}
      getOptionValue={hull => hull.mmsi || hull.eni}
      maxMenuHeight={250}
      isDisabled={disabled}
    />
  )

  function getAllOptions(): BicsHull[] {
    if (value && !mappedValue) {
      return [...hulls, { eni: value } as BicsHull]
    }

    return hulls
  }
}

export default BICSHullSelector
