import React from 'react'
import { I18n } from 'react-redux-i18n'

import { SelectedItemModalEnum } from '../../../@types/types'

interface IProps {
  selectedItem: SelectedItemModalEnum
}

const WaitingBanner = ({ selectedItem }: IProps) => {
  if (!selectedItem || selectedItem.type !== 'ROUTE_ITEM') {
    return null
  }

  const { type, waitingTime } = selectedItem.item

  if (!waitingTime || waitingTime < 120000) {
    return null
  }

  return (
    <div className="route-item-row waiting" id="selected-route-item-waiting-time">
      <i className="icon-attention" />
      <div className="message">
        {I18n.t('routeList.itemDetails.waitingTimeSelected', {
          waitingTime: Math.round(waitingTime / 60000) + ' min',
          objectType: I18n.t(`routeList.objectTypes.${type}`)
        })}
      </div>
    </div>
  )
}

export default WaitingBanner
