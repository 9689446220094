import React from 'react'
import { I18n } from 'react-redux-i18n'

import InputField from '../../../components/shared/inputField/InputField'
import { IShipInfo } from '../../../services/TeqplayAPIService/TeqplayApi'

interface IProps {
  name: string
  email: string
  phoneNumber: string | undefined
  shipInfo: IShipInfo | null
  comment: string
  setComment: (c: string) => void
  approvedGDPR: boolean
  openGDPR: (func?: () => void) => void
  loading: boolean

  onPreviousStep: () => void
  onNextStep: () => void
}

const ConfirmSubForm = (props: IProps) => {
  const {
    name,
    email,
    phoneNumber,
    shipInfo,
    comment,
    setComment,
    approvedGDPR,
    openGDPR,
    onNextStep,
    onPreviousStep,
    loading
  } = props

  function onSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault()
    e.stopPropagation()

    if (approvedGDPR) {
      onNextStep()
    } else {
      openGDPR(onNextStep)
    }
  }

  return (
    <form id="form-CONFIRM" onSubmit={e => onSubmit(e)}>
      <div className="account-block">
        <h3>{I18n.t('register.confirmAccount')}</h3>
        <div className="item">
          <span className="label">{I18n.t('register.name')}</span>
          <div className="value" id="register-confirm-name">
            {name}
          </div>
        </div>
        <div className="item">
          <span className="label">{I18n.t('register.emailAddress')}</span>
          <div className="value" id="register-confirm-email">
            {email}
          </div>
        </div>
        <div className="item">
          <span className="label">{I18n.t('register.phoneNumber')}</span>
          <div className="value" id="register-confirm-phonenumber">
            {phoneNumber}
          </div>
        </div>
        <div className="break" />
        <div className="item">
          <span className="label">{I18n.t('register.shipName')}</span>
          <div className="value" id="register-confirm-shipname">
            {shipInfo?.name}
          </div>
        </div>
        <div className="item">
          <span className="label">{I18n.t('register.mmsiNumber')}</span>
          <div className="value" id="register-confirm-mmsi">
            {shipInfo?.mmsi}
          </div>
        </div>
        <div className="break" />

        <div className="help">{I18n.t(`register.comment`)}</div>

        <InputField
          name={I18n.t('register.comment')}
          value={comment}
          onChange={e => setComment(e.target.value)}
          autoFocus
          className="input"
          id="register-ship-name-input"
        />

        <button
          id="button-approvegdpr"
          className={`button GDPR-button${approvedGDPR ? ` approved` : ''}`}
          onClick={e => {
            e.preventDefault()
            openGDPR()
          }}
        >
          <i className={approvedGDPR ? `icon-check` : `icon-check-empty`} />
          <span className="text">
            {approvedGDPR ? I18n.t('register.accepted') : I18n.t('register.readApproveGDPR')}
          </span>
        </button>
      </div>

      <div className="step-buttons confirm">
        <button
          id="button-previous"
          className="button previous"
          type="reset"
          onClick={() => onPreviousStep()}
        >
          {I18n.t('register.back')}
        </button>
        <button id="button-submit" disabled={loading} className="button primary next" type="submit">
          {loading ? I18n.t('processing') : I18n.t('register.submit')}
        </button>
      </div>
    </form>
  )
}

export default ConfirmSubForm
