import React, { useState } from 'react'
import { I18n } from 'react-redux-i18n'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'

import TeqplayApiService from '../../services/TeqplayAPIService/TeqplayApiService'
import TitleComponent from '../../components/titleComponent/TitleComponent'

import { useLocalStorage } from '../../hooks/useLocalStorage'
import { determineDynamicTheme, supportsThemePreference } from '../../utils/style'

import './ForgotPasswordPage.scss'

interface IProps {
  teqplayAPIService: TeqplayApiService
}

const ForgotPasswordPage = ({ teqplayAPIService }: IProps) => {
  const [username, setUsername] = useLocalStorage<string | undefined>('username', undefined)
  const [error, setError] = useState<string>()
  const [success, setSuccess] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)

  const theme = supportsThemePreference() ? determineDynamicTheme() : 'light'

  if (success) {
    return (
      <div className={theme}>
        <TitleComponent title={I18n.t('login.successForgot')} />
        <div className="forgot-password-container">
          <Link className="button previous-button-wrapper" to={`/login`}>
            <i className="icon-left" />
            {I18n.t('login.back')}
          </Link>
          <div className="success info-box">{I18n.t('login.forgotPasswordSuccess')}</div>
        </div>
      </div>
    )
  }

  return (
    <div className={theme}>
      <TitleComponent title={I18n.t('login.forgot')} />
      <div className="forgot-password-container">
        <Link className="button previous-button-wrapper" to={`/`}>
          <i className="icon-left" />
          {I18n.t('login.back')}
        </Link>
        <div className="password-form-wrapper">
          <p className="welcome-message">{I18n.t(`login.forgotPassword`)}</p>
          {error && <div className="error">{error}</div>}

          <form
            onSubmit={e => {
              e.preventDefault()
              handleSubmitForgotPasswordRequest()
            }}
          >
            <div className="input-wrappers">
              <input
                className="input"
                placeholder={I18n.t('login.username')}
                value={username}
                onChange={e => setUsername(e.target.value)}
                autoFocus
              />
              <button className="button login primary" type="submit">
                {loading ? <i className="fas fa-spin fa-spinner" /> : I18n.t('login.submit')}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )

  async function handleSubmitForgotPasswordRequest() {
    if (!username) {
      toast.error(I18n.t('fillInAllFields'))
      return
    }

    try {
      setLoading(true)
      await teqplayAPIService.resetPassword(username)
      setSuccess(true)
    } catch (err) {
      console.error(err)
      setError(I18n.t('generalError'))
    } finally {
      setLoading(false)
    }
  }
}

export default ForgotPasswordPage
