import { getBoundingBox } from 'geolocation-utils'
import {
  LatLng,
  LatLngBounds,
  LatLngLiteral,
  LatLngTuple,
  LeafletMouseEvent,
  Map as MapProps
} from 'leaflet'
import cloneDeep from 'lodash/cloneDeep'
import flatMap from 'lodash/flatMap'
import isEqual from 'lodash/isEqual'
import polyUtil from 'polyline-encoded'
import * as React from 'react'
import { Map, Marker, Polyline, ZoomControl } from 'react-leaflet'
import { I18n } from 'react-redux-i18n'
import { toast } from 'react-toastify'

import TeqplayApiService from '../../services/TeqplayAPIService/TeqplayApiService'
import CustomControlButton from '../mapShared/controls/CustomControlButton'
import CustomLayers from '../mapShared/customLayers/CustomLayers'
import UserShipMarker from '../mapShared/userShipMarker/UserShipMarker'
import SelectedItemModal from '../selectedItemModal/SelectedItemModal'

import {
  IApplicationTheme,
  IRouteSearchProps,
  MapTypes,
  RouteSelectionFields,
  SuppressedWarnings,
  SelectedItemModalEnum,
  ILayerCoverageDetails,
  ILayerCodeName
} from '../../@types/types'
import {
  ILatLng,
  IRoute,
  IRouteLocation,
  IShipInfo
} from '../../services/TeqplayAPIService/TeqplayApi'
import { NETHERLANDS_CENTER, SCREEN_WIDTH_TO_SPLIT_VIEW } from '../../utils/constants'
import { arrivalIcon, departureIcon, getRouteItemIcon, viaIcon } from '../mapShared/mapIcons'
import { centerViewOnLocation, getMapTiles } from '../mapShared/mapUtils'
import { AnalyticsContextType } from '../../services/AnalyticsWrapper/AnalyticsWrapper'

import '../mapShared/LeafletMap.scss'

interface IProps {
  analytics: AnalyticsContextType
  selectedField: RouteSelectionFields | null
  selectedViaRouteIndex: number | null
  routeSelection: IRouteSearchProps
  routeSelectionFormActive: boolean
  routeSelectionFormFieldActive: boolean
  routeSuggestions: IRoute[] | null
  selectedRouteSuggestion: IRoute | null
  hideControlButtons?: boolean
  currentLocation: IShipInfo | null
  token: string
  activeMap: MapTypes
  teqplayApiService: TeqplayApiService

  onChangeActiveMap: (activeMap: MapTypes) => void
  setFromLocation: (fromLocation: IRouteLocation | null) => void
  setToLocation: (toLocation: IRouteLocation | null) => void
  setViaRoutes: (viaRoutes: IRouteLocation[]) => void
  setSelectedRouteSuggestion: (routeSuggestion: IRoute | null) => void
  theme: IApplicationTheme

  suppressedWarnings: SuppressedWarnings
  setSuppressedWarnings: (
    l: ILayerCodeName,
    prompts: { [prompt in keyof ILayerCoverageDetails['prompts']]: boolean }
  ) => void
  showLimitedRangeLayer: boolean

  activeLayers: ILayerCodeName[]
  setActiveLayers: (layers: ILayerCodeName[]) => void
  handleSetRouteSelectionFormActive?: () => void
}

interface IState {
  followMode: boolean
  layerSelectActive: boolean
  mapApiIsSet: boolean
  bounds: LatLngBounds | null
  mapZoomLevel: number
  clickedLocation: LatLng | null
  center: LatLng | LatLngLiteral | LatLngTuple
  selectedItem: SelectedItemModalEnum | undefined
}

class MapRouteSelection extends React.Component<IProps, IState> {
  public mapApi: MapProps | null = null

  public readonly state: Readonly<IState> = {
    followMode: false, // not yet doing something
    layerSelectActive: false,
    mapApiIsSet: false,
    bounds: null,
    mapZoomLevel: this.props.currentLocation?.location ? 13 : 9,
    center: this.props.currentLocation?.location
      ? {
          lat: this.props.currentLocation.location.latitude,
          lng: this.props.currentLocation.location.longitude
        }
      : NETHERLANDS_CENTER,
    clickedLocation: null,
    selectedItem: undefined
  }

  public componentDidMount() {
    // TODO: Refactor component into functional component
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const mapRef: any = this.refs.map
    if (mapRef && mapRef.leafletElement) {
      this.mapApi = mapRef.leafletElement
      // make sure to reload view once, when mapApi is set
      this.setState({ mapApiIsSet: true })
    }
  }

  public componentDidUpdate(prevProps: IProps) {
    this.moveSelectedPolylineToFront()
    /**
     * Make sure that if the user is getting its location upon initialization, center
     * or center whenever the follow mode is active and currentLocation changes
     */
    if (this.state.followMode && !isEqual(prevProps.currentLocation, this.props.currentLocation)) {
      centerViewOnLocation(this.mapApi, this.props.currentLocation, this.state.mapZoomLevel)
    } else if (
      !isEqual(prevProps.currentLocation, this.props.currentLocation) &&
      this.state.center === NETHERLANDS_CENTER
    ) {
      centerViewOnLocation(this.mapApi, this.props.currentLocation, 12)
    }

    if (
      this.props.routeSuggestions &&
      this.props.routeSuggestions.length > 0 &&
      (!prevProps.routeSuggestions || prevProps.routeSuggestions.length === 0) &&
      this.mapApi
    ) {
      // Move the map if any route suggestions are received
      const coordinates = flatMap(
        this.props.routeSuggestions.map(rs => polyUtil.decode(rs.polyLine))
      )
      const bounds = getBoundingBox(coordinates) as unknown as {
        topLeft: number[]
        bottomRight: number[]
      }

      this.mapApi.fitBounds(
        [
          [bounds.topLeft[0], bounds.topLeft[1]],
          [bounds.bottomRight[0], bounds.bottomRight[1]]
        ],
        {
          paddingTopLeft: [
            window.innerWidth > SCREEN_WIDTH_TO_SPLIT_VIEW ? 452 + 15 + 15 : 15,
            window.innerWidth > SCREEN_WIDTH_TO_SPLIT_VIEW ? 50 : 200
          ],
          paddingBottomRight: [50, window.innerWidth > SCREEN_WIDTH_TO_SPLIT_VIEW ? 50 : 56]
        }
      )
    } else if (
      this.mapApi &&
      !isEqual(this.props.routeSelection, prevProps.routeSelection) &&
      this.state.bounds
    ) {
      // Check if either the from or to location is newly clicked on the map,
      // if this is the case we do not want to move the map again
      const newAndHasBeenClicked =
        (this.props.routeSelection.fromLocation?.isClicked &&
          !isEqual(
            this.props.routeSelection.fromLocation.coordinates,
            prevProps.routeSelection.fromLocation?.coordinates
          )) ||
        (this.props.routeSelection.toLocation?.isClicked &&
          !isEqual(
            this.props.routeSelection.toLocation.coordinates,
            prevProps.routeSelection.toLocation?.coordinates
          )) ||
        this.props.routeSelection.viaRoutes
          .map((viaPoint, i) =>
            // Or if a new via location has been clicked on the map
            (viaPoint.isClicked &&
              !isEqual(viaPoint.coordinates, prevProps.routeSelection.viaRoutes[i]?.coordinates)) ||
            // Or if any via location has been created, also do not move the map (viaPoint.displayName === "" && viaPoint.coordinates === null)
            (!viaPoint.displayName && !viaPoint.coordinates)
              ? true // explicit true statement needed or else value would be undefined
              : false
          )
          .filter(res => res).length > 0 // essentially flatmap the array and check if there are more than 0 true values inside of it

      // Create a flat array of all the relevant coordinates
      const coordinates = flatMap([
        this.props.currentLocation?.location && {
          lat: this.props.currentLocation.location.latitude,
          lng: this.props.currentLocation.location.longitude
        },
        this.props.routeSelection.fromLocation?.coordinates,
        this.props.routeSelection.viaRoutes.map(vr => vr.coordinates),
        this.props.routeSelection.toLocation?.coordinates
      ]).filter(x => x) as ILatLng[]

      if (!newAndHasBeenClicked && coordinates.length >= 2) {
        const bounds = getBoundingBox(coordinates) as unknown as {
          topLeft: ILatLng
          bottomRight: ILatLng
        }

        this.mapApi.fitBounds(
          [
            [bounds.topLeft.lat, bounds.topLeft.lng],
            [bounds.bottomRight.lat, bounds.bottomRight.lng]
          ],
          {
            paddingTopLeft: [
              window.innerWidth > SCREEN_WIDTH_TO_SPLIT_VIEW ? 400 : 15,
              window.innerWidth > SCREEN_WIDTH_TO_SPLIT_VIEW ? 50 : 200
            ],
            paddingBottomRight: [50, window.innerWidth > SCREEN_WIDTH_TO_SPLIT_VIEW ? 50 : 56]
          }
        )
      }
    }
  }

  public render() {
    const { followMode, layerSelectActive } = this.state
    const followClass = followMode ? 'follow-active' : ''
    const layerSelectClass = layerSelectActive ? 'layer-select-active' : ''
    const currentLocation = this.props.currentLocation

    return (
      <>
        <div
          className={`leaflet-container ${followClass} ${layerSelectClass}`}
          id="map-route-selection"
        >
          <div className="leaflet-map-wrapper">
            <SelectedItemModal
              selectedItem={this.state.selectedItem}
              clearSelectedItem={() => this.setSelectedItem(undefined)}
              teqplayApiService={this.props.teqplayApiService}
            />

            <Map
              center={this.state.center}
              zoom={this.state.mapZoomLevel}
              zoomControl={false}
              onMoveend={this.handleMoveend}
              onDragstart={this.handleDragStart}
              ref="map"
              attributionControl={false}
              onClick={this.onMapClick}
              onZoomEnd={this.handleZoom}
            >
              {getMapTiles(this.props.activeMap, this.props.theme)}

              {this.createRoutePolylines(
                this.props.routeSuggestions,
                this.props.selectedRouteSuggestion
              )}
              {currentLocation && currentLocation.location && (
                <UserShipMarker
                  courseOverGround={
                    currentLocation && currentLocation.courseOverGround
                      ? currentLocation.courseOverGround
                      : null
                  }
                  location={{
                    lat: currentLocation.location.latitude,
                    lng: currentLocation.location.longitude
                  }}
                />
              )}

              {/* Controls */}
              <>
                <ZoomControl position={'bottomright'} />
                <CustomControlButton
                  title={I18n.t('map.followButton')}
                  onClick={this.toggleFollowMode}
                  tag="follow"
                >
                  <i className="icon-user-location" />
                </CustomControlButton>
                <CustomControlButton
                  title={I18n.t('map.activelayers')}
                  onClick={this.toggleLayerSelect}
                  tag="layers"
                >
                  <i className="icon-layers" />
                </CustomControlButton>
                {this.props.children}
              </>

              {/* Custom layers */}
              <CustomLayers
                mapApi={this.mapApi}
                activeLayers={this.props.activeLayers}
                token={this.props.token}
                onChangeActiveLayers={this.handleChangeActiveLayers}
                togglePopupActive={this.toggleLayerSelect}
                popupActive={layerSelectActive}
                activeMap={this.props.activeMap}
                onChangeActiveMap={this.props.onChangeActiveMap}
                teqplayApiService={this.props.teqplayApiService}
                mapZoomLevel={this.state.mapZoomLevel}
                currentLocation={this.props.currentLocation}
                clickedLocation={this.state.clickedLocation}
                suppressedWarnings={this.props.suppressedWarnings}
                setSuppressedWarnings={this.props.setSuppressedWarnings}
                showLimitedRangeLayer={this.props.showLimitedRangeLayer}
                theme={this.props.theme}
                selectedItem={this.state.selectedItem}
                setSelectedItem={this.setSelectedItem}
                disableInteraction={
                  this.props.routeSelectionFormActive && this.props.routeSelectionFormFieldActive
                }
              />
              {this.createRouteMarkers(
                this.props.routeSelection,
                this.props.selectedRouteSuggestion
              )}
            </Map>
          </div>
        </div>
      </>
    )
  }

  /**
   * Refresh overlay's when map is moved
   */
  private handleMoveend = () => {
    if (!this.mapApi) return
    const mapBounds = this.mapApi.getBounds()
    const center = this.mapApi.getCenter()

    this.setState({ bounds: mapBounds, center })
  }

  private handleChangeActiveLayers = (activeLayers: ILayerCodeName[]) => {
    this.props.setActiveLayers(activeLayers)
  }

  private handleDragStart = () => {
    if (!this.mapApi || !this.state.followMode) return
    this.setState({ followMode: false })
  }

  private toggleFollowMode = () => {
    if (this.props.currentLocation) {
      this.props.analytics.newEvent('toggle_follow_mode', { followMode: !this.state.followMode })
      centerViewOnLocation(this.mapApi, this.props.currentLocation)
      this.setState({ followMode: !this.state.followMode })
    } else {
      this.props.analytics.newEvent('error_follow_mode', {
        currentLocation: this.props.currentLocation
      })
      this.setState({ followMode: false })
      toast.warn(I18n.t('navigationPage.no_user_location_warning'))
    }
  }

  private toggleLayerSelect = (hide?: boolean) => {
    this.setState({ layerSelectActive: hide === false || !this.state.layerSelectActive })
    // This will close down the menu for mobiles to get better overview
    if (this.props.handleSetRouteSelectionFormActive) {
      this.props.handleSetRouteSelectionFormActive()
    }
  }

  private onMapClick = (e: LeafletMouseEvent) => {
    const pickingLocation = this.props.routeSelectionFormFieldActive ? true : false

    this.setState({ clickedLocation: e.latlng })

    if (pickingLocation) {
      const latLng = e.latlng as ILatLng
      if (latLng.lat && latLng.lng && this.props.selectedField) {
        this.props.analytics.newEvent('set_location_by_map_tap', {
          selectedField: this.props.selectedField,
          location: latLng,
          selectedViaRouteIndex: this.props.selectedViaRouteIndex
        })
        this.setRouteLocation(latLng, this.props.selectedField)
      }
    }
  }

  private createRouteMarkers = (
    routeSelection: IRouteSearchProps,
    selectedRouteSuggestion: IRoute | null
  ) => {
    const locationMarkers = []

    if (this.props.routeSelectionFormActive === true || this.props.routeSuggestions) {
      const fromLocationCoords =
        routeSelection.fromLocation &&
        !routeSelection.fromLocation.isCurrentLocation &&
        routeSelection.fromLocation.coordinates
          ? routeSelection.fromLocation.coordinates
          : selectedRouteSuggestion?.routeItems[0]?.singleLocation
          ? {
              lat: selectedRouteSuggestion.routeItems[0].singleLocation.coordinates[1],
              lng: selectedRouteSuggestion.routeItems[0].singleLocation.coordinates[0]
            }
          : null

      const toLocationCoords =
        routeSelection.toLocation &&
        !routeSelection.toLocation.isCurrentLocation &&
        routeSelection.toLocation.coordinates
          ? routeSelection.toLocation.coordinates
          : selectedRouteSuggestion?.routeItems[selectedRouteSuggestion.routeItems.length - 1]
              .singleLocation
          ? {
              lat: selectedRouteSuggestion.routeItems[selectedRouteSuggestion.routeItems.length - 1]
                .singleLocation.coordinates[1],
              lng: selectedRouteSuggestion.routeItems[selectedRouteSuggestion.routeItems.length - 1]
                .singleLocation.coordinates[0]
            }
          : null
      if (selectedRouteSuggestion) {
        selectedRouteSuggestion.routeItems
          .filter(
            i =>
              (i.waitingTime && i.waitingTime > 120000) ||
              (i.canBePassed !== undefined && i.canBePassed === false)
          )
          .map((routeItem, wi) => (
            <Marker
              position={[
                routeItem.singleLocation.coordinates[1],
                routeItem.singleLocation.coordinates[0]
              ]}
              key={wi}
              icon={getRouteItemIcon(
                routeItem,
                routeItem.canBePassed !== undefined && routeItem.canBePassed === false
                  ? false
                  : true
              )}
            />
          ))
          .forEach(i => locationMarkers.push(i))
      }

      if (routeSelection.viaRoutes) {
        routeSelection.viaRoutes.forEach((viaPoint, index) => {
          const viaRouteCoord = viaPoint.coordinates
          if (viaRouteCoord) {
            locationMarkers.push(
              <Marker
                position={viaRouteCoord}
                key={'viaRouteMarker' + index}
                icon={viaIcon(index + 1)}
              />
            )
          }
        })
      }
      if (fromLocationCoords) {
        locationMarkers.push(
          <Marker
            position={fromLocationCoords}
            key={'fromLocationMarker'}
            icon={departureIcon}
            zIndexOffset={1000}
          />
        )
      }

      if (toLocationCoords) {
        locationMarkers.push(
          <Marker
            position={toLocationCoords}
            key={'toLocationMarker'}
            icon={arrivalIcon}
            zIndexOffset={1001}
          />
        )
      }
    }

    return locationMarkers
  }

  public handleZoom = () => {
    if (this.mapApi) {
      const zoomLevel = this.mapApi.getZoom()
      if (zoomLevel && Number.isInteger(zoomLevel)) {
        this.setState({
          mapZoomLevel: zoomLevel
        })
      }
    }
  }

  private createRoutePolylines = (
    routeSuggestions: IRoute[] | null,
    selectedRouteSuggestion: IRoute | null
  ) => {
    if (routeSuggestions && selectedRouteSuggestion) {
      const polyLines = routeSuggestions.map(routeSuggestion => {
        if (!routeSuggestion) {
          return null
        }

        const isSelectedRoute =
          this.props.selectedRouteSuggestion &&
          this.props.selectedRouteSuggestion._id === routeSuggestion._id
            ? true
            : false

        const polylinePositions = polyUtil.decode(routeSuggestion.polyLine)

        if (!polylinePositions) {
          return undefined
        }

        const lastRouteCoordinate = polylinePositions[polylinePositions.length - 1]

        const polylineColor = this.props.selectedRouteSuggestion
          ? routeSuggestion._id === this.props.selectedRouteSuggestion._id
            ? !routeSuggestion.canPassBridges || !routeSuggestion.shipAllowed
              ? '#c73326'
              : '#2e6771'
            : '#a5a5a5'
          : '#a5a5a5'

        const routePolyline = (
          <Polyline
            onClick={() => {
              this.props.setSelectedRouteSuggestion(routeSuggestion)
            }}
            key={routeSuggestion._id + 'routeSuggestion'}
            color={polylineColor}
            positions={polylinePositions}
            ref={routeSuggestion._id}
            stroke={true}
            weight={7}
          />
        )

        if (isSelectedRoute) {
          const fromLocationCoords =
            this.props.routeSelection.fromLocation &&
            !this.props.routeSelection.fromLocation.isCurrentLocation
              ? this.props.routeSelection.fromLocation.coordinates
              : this.props.routeSelection.fromLocation?.isCurrentLocation &&
                this.props.currentLocation &&
                this.props.currentLocation.location
              ? {
                  lat: this.props.currentLocation.location.latitude,
                  lng: this.props.currentLocation.location.longitude
                }
              : null

          const toLocationCoords =
            this.props.routeSelection.toLocation &&
            !this.props.routeSelection.toLocation.isCurrentLocation
              ? this.props.routeSelection.toLocation.coordinates
              : this.props.routeSelection.toLocation?.isCurrentLocation &&
                this.props.currentLocation &&
                this.props.currentLocation.location
              ? {
                  lat: this.props.currentLocation.location.latitude,
                  lng: this.props.currentLocation.location.longitude
                }
              : null

          const returnablePolylines = []

          if (fromLocationCoords) {
            returnablePolylines.push(
              <Polyline
                key={'pickedLocationToStart'}
                positions={[polylinePositions[0], fromLocationCoords]}
                color={'#878787'}
                dashArray={'10 10'}
                weight={4}
              />
            )
          }

          if (toLocationCoords) {
            returnablePolylines.push(
              <Polyline
                key={'pickedLocationToEnd'}
                positions={[lastRouteCoordinate, toLocationCoords]}
                color={'#878787'}
                dashArray={'10 10'}
                weight={4}
              />
            )
          }

          return [...returnablePolylines, routePolyline]
        } else {
          return routePolyline
        }
      })

      return polyLines
    } else {
      return null
    }
  }

  private moveSelectedPolylineToFront = () => {
    const selectedRouteSuggestion = this.props.selectedRouteSuggestion
    if (selectedRouteSuggestion) {
      // TODO: Refactor to not use explicit string refs
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const activeRouteSuggestion = this.refs[selectedRouteSuggestion._id] as any
      if (activeRouteSuggestion && activeRouteSuggestion.leafletElement) {
        activeRouteSuggestion.leafletElement.bringToFront()
      }
    }
  }

  public setRouteLocation = (latLng: ILatLng, selectedField: RouteSelectionFields) => {
    const location: IRouteLocation = {
      coordinates: latLng,
      displayName: `${latLng.lat.toFixed(4)} , ${latLng.lng.toFixed(4)}`,
      isClicked: true
    }

    switch (selectedField) {
      case 'FROM_LOCATION':
        this.props.setFromLocation(location)
        break
      case 'TO_LOCATION':
        this.props.setToLocation(location)
        break
      case 'VIA_ROUTES':
        if (this.props.selectedViaRouteIndex !== null) {
          const viaRoutes = cloneDeep(this.props.routeSelection.viaRoutes)
          viaRoutes[this.props.selectedViaRouteIndex] = location
          this.props.setViaRoutes(viaRoutes)
        }
        break
      default:
        break
    }
  }

  private setSelectedItem = (newItem: SelectedItemModalEnum | undefined) => {
    this.props.analytics.newEvent(!newItem ? 'clear_selected_item' : 'set_selected_item', {
      type: newItem?.type
    })
    this.setState({ selectedItem: newItem })
  }
}

export default MapRouteSelection
