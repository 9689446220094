import * as React from 'react'
import './LoadingIndicator.scss'

interface IProps {
  loading: boolean
}

const Loader = ({ loading }: IProps) => {
  if (!loading) {
    return null
  }

  return (
    <div className="loader-wrapper" id="loading-indicator">
      <div className="spinner">
        <div className="bounce1" />
        <div className="bounce2" />
        <div className="bounce3" />
      </div>
    </div>
  )
}

export default Loader
