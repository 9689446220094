import cloneDeep from 'lodash/cloneDeep'
import React, { useState } from 'react'
import { I18n } from 'react-redux-i18n'
import { createFilter } from 'react-select'
import CreatableSelect from 'react-select/creatable'
import { toast } from 'react-toastify'

import TeqplayApiService from '../../../services/TeqplayAPIService/TeqplayApiService'

import { IRouteSearchProps, RouteSelectionFields } from '../../../@types/types'
import {
  IGeocodedLocation,
  ILatLng,
  INavigationLocation,
  IRouteLocation,
  IShipInfo
} from '../../../services/TeqplayAPIService/TeqplayApi'
import { selectStyles } from '../../../utils/general'
import { sendMessageToSentry } from '../../../utils/sentry'
import { parseStringForID } from '../../../utils/tests'

import './LocationSelect.scss'

interface IProps {
  selectedField: RouteSelectionFields | null
  selectedViaRouteIndex: number | null
  routeSelection: IRouteSearchProps
  navigationLocations: INavigationLocation[]
  currentLocation: IShipInfo | null
  setFromLocation: (fromLocation: IRouteLocation | null) => void
  setToLocation: (toLocation: IRouteLocation | null) => void
  setViaRoutes: (viaRoutes: IRouteLocation[]) => void
  setRouteSelectionFieldInactive: () => void
  teqplayAPIService: TeqplayApiService
}

interface ILabelValue {
  label: string
  value: string
}

const MIN_SEARCH_LENGTH = 2

const LocationSelect = ({
  selectedField,
  selectedViaRouteIndex,
  routeSelection,
  navigationLocations: allNavigationLocations,
  currentLocation,
  setFromLocation,
  setToLocation,
  setViaRoutes,
  setRouteSelectionFieldInactive,
  teqplayAPIService
}: IProps) => {
  const [selectionModeActive, setSelectionModeActive] = useState<
    'currentLocation' | 'mapSelection'
  >('currentLocation')
  const [input, setInput] = useState<string>('')
  const [navigationLocations, setNavigationLocations] = useState<INavigationLocation[]>(
    allNavigationLocations.sort().slice(0, 30)
  )
  const [loading, setLoading] = useState<boolean>(false)

  return (
    <div className="route-select-wrapper location-select-wrapper">
      <div className="route-select-menu-buttons">
        <span
          className="close-button"
          id="route-select-wrapper-button-back"
          onClick={setRouteSelectionFieldInactive}
        >
          <i className="icon-left-big" />
        </span>
      </div>
      <div className="content">
        <label
          htmlFor=""
          className="form-label"
          id={`label-${parseStringForID(selectedField || 'unknown')}`}
        >
          {I18n.t(
            selectedField === 'FROM_LOCATION'
              ? 'routeSelection.startingPoint'
              : selectedField === 'VIA_ROUTES'
              ? 'routeSelection.viaPoint'
              : 'routeSelection.destinationPoint'
          )}
        </label>
        <CreatableSelect<ILabelValue>
          createOptionPosition={'first'}
          autoFocus
          placeholder={I18n.t('routeSelection.searchOnLocation')}
          classNamePrefix="location-search selector"
          formatCreateLabel={value => I18n.t('routeSelection.createGeolocation', { value })}
          options={navigationLocations.map(x => ({
            label: x.name,
            value: x._id
          }))}
          className="location-search"
          getOptionValue={option => option.value}
          getOptionLabel={option => option.label}
          noOptionsMessage={({ inputValue }) =>
            inputValue && inputValue.length >= MIN_SEARCH_LENGTH
              ? I18n.t('routeSelection.noOptions')
              : I18n.t('routeSelection.searchAdvice')
          }
          styles={selectStyles}
          filterOption={createFilter({ ignoreAccents: false })}
          onChange={option => {
            if (option) {
              selectNavigationOption(option as unknown as ILabelValue)
            }
          }}
          loadingMessage={o => I18n.t('routeSelection.searchingFor', { placeName: o.inputValue })}
          isLoading={loading}
          value={{ value: input, label: input }}
          onInputChange={i => getOptions(i)}
          onCreateOption={(option: string) => selectGeolocatableOption(option)}
          inputId="location-select-input"
          id="location-select-creatable"
          maxMenuHeight={200} // needed for mobile landscape
        />
        <div className="location-select-buttons-wrapper">
          <button
            className={`button map-location-button ${
              selectionModeActive === 'mapSelection' ? 'active' : 'inactive'
            }`}
            type="button"
            onClick={() => setSelectionModeActive('mapSelection')}
            id="location-select-button-select-location"
          >
            <i className="icon-map-o" />
            {I18n.t('routeSelection.selectOnMap')}
          </button>
          <button
            className={`button current-location-button ${!currentLocation ? 'disabled' : ''} ${
              selectionModeActive === 'currentLocation' ? 'active' : 'inactive'
            }`}
            type="button"
            onClick={selectCurrentLocation}
            id="location-select-button-current-location"
          >
            <i className="icon-location-1" />
            {I18n.t('routeSelection.currentLocation')}
          </button>
        </div>
      </div>
    </div>
  )

  function getOptions(i: string) {
    let optionsFiltered: INavigationLocation[] = []
    if (i.length >= MIN_SEARCH_LENGTH) {
      const searchString = i
        .toLowerCase()
        .replace(/[^a-z0-9-]/g, '')
        .trim()
      optionsFiltered = allNavigationLocations.filter((location: INavigationLocation) => {
        return (
          location.name
            .toLowerCase()
            .replace(/[^a-z0-9-]/g, '')
            .trim()
            .match(searchString) ||
          (location.city &&
            location.city
              .toLowerCase()
              .replace(/[^a-z0-9-]/g, '')
              .trim()
              .match(searchString))
        )
      })
    }

    setNavigationLocations(optionsFiltered.slice(0, 30))
  }

  function selectCurrentLocation() {
    if (currentLocation && currentLocation.location) {
      setSelectionModeActive('currentLocation')
      const location: IRouteLocation = {
        displayName: I18n.t('routeSelection.currentLocation'),
        coordinates: null,
        isCurrentLocation: true
      }
      selectLocation(location)
    }
  }

  function selectNavigationOption(labelValue: ILabelValue) {
    const selectedOption = allNavigationLocations.find(x => x._id === labelValue.value)
    if (selectedOption) {
      const latLng: ILatLng = {
        lat: selectedOption.location.coordinates[1],
        lng: selectedOption.location.coordinates[0]
      }
      const location: IRouteLocation = {
        displayName: selectedOption.name,
        coordinates: latLng
      }
      selectLocation(location)
    } else {
      selectGeolocatableOption(labelValue.label)
    }
  }

  async function selectGeolocatableOption(value: string) {
    // Converts every first letter of a word to a capital letter
    // e.g. : "den bosch" -> "Den Bosch"
    const displayName = value
      .split(' ')
      .map(w => (w.length > 1 ? w[0].toUpperCase() + w.slice(1, w.length) : w))
      .join(' ')

    setInput(displayName)
    const geocodedLocation = await retrieveGeocodedLocation(displayName)

    selectLocation({
      displayName: geocodedLocation?.name
        ? !geocodedLocation.name.toLowerCase().trim().includes(displayName.toLowerCase().trim())
          ? `${geocodedLocation.name} (${displayName})`
          : geocodedLocation.name
        : displayName,
      coordinates: geocodedLocation?.location.coordinates
        ? {
            lat: geocodedLocation?.location.coordinates[1],
            lng: geocodedLocation?.location.coordinates[0]
          }
        : null,
      isCurrentLocation: false,
      isGeocoded: true
    })
  }

  function selectLocation(location: IRouteLocation) {
    switch (selectedField) {
      case 'FROM_LOCATION':
        setFromLocation(location)
        break
      case 'TO_LOCATION':
        setToLocation(location)
        break
      case 'VIA_ROUTES':
        if (selectedViaRouteIndex !== null) {
          const viaRoutes = cloneDeep(routeSelection.viaRoutes)
          viaRoutes[selectedViaRouteIndex] = location
          setViaRoutes(viaRoutes)
        }
        break
      default:
        break
    }
  }

  async function retrieveGeocodedLocation(placeName: string): Promise<IGeocodedLocation | null> {
    // TO-DO: Add options to menu selection screen upon typing?
    // Might be expensive money wise though calling the API this often
    try {
      setLoading(true)
      const locations = await teqplayAPIService.getGeocodedLocation(placeName)

      if (locations.length === 0) {
        toast.error(I18n.t('routeSelection.notFound'))
        return null
      } else {
        return locations[0]
      }
    } catch (error) {
      if (error.message === 'The placeName has too much characters') {
        toast.error(I18n.t('routeSelection.notFound'))
      }

      sendMessageToSentry(error)
      return null
    } finally {
      setLoading(false)
    }
  }
}
export default LocationSelect
