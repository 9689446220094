import React from 'react'
import Modal from 'react-modal'
import { I18n } from 'react-redux-i18n'

import { useAnalytics } from '../../../../../services/AnalyticsWrapper/AnalyticsWrapper'

interface IProps {
  showSuitableDialog: boolean
  setCones: (value: number) => void
  setShowSuitableDialog: (value: boolean) => void
}

const ModalBerthCones = ({ showSuitableDialog, setCones, setShowSuitableDialog }: IProps) => {
  const analytics = useAnalytics('ModalBerthCones')

  return (
    <Modal
      isOpen={showSuitableDialog}
      className="active-route-modal modal"
      style={{ overlay: { backgroundColor: 'rgba(0,0,0,0.75)' } }}
    >
      <p>{I18n.t('mapLayers.berthLayer.conesAmount')}</p>
      <button
        className="button primary large activate-button"
        onClick={() => {
          analytics.newEvent('set_suitable_cones', {})
          setCones(0)
          setShowSuitableDialog(false)
        }}
        id="button-cones-0"
      >
        0
      </button>
      <button
        className="button primary large activate-button"
        onClick={() => {
          analytics.newEvent('set_suitable_cones', {})
          setCones(1)
          setShowSuitableDialog(false)
        }}
        id="button-cones-1"
      >
        1
      </button>
      <button
        className="button primary large activate-button"
        onClick={() => {
          analytics.newEvent('set_suitable_cones', {})
          setCones(2)
          setShowSuitableDialog(false)
        }}
        id="button-cones-2"
      >
        2
      </button>
    </Modal>
  )
}

export default ModalBerthCones
