import { headingDistanceTo, LonLatTuple, toLatLon } from 'geolocation-utils'
import produce from 'immer'
import { cloneDeep, first, isEqual, sortBy } from 'lodash'
import pointInPolygon from 'point-in-geopolygon'
import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { toast } from 'react-toastify'

import TeqplayApiService from '../../services/TeqplayAPIService/TeqplayApiService'
import RouteSimulator from './RouteSimulator/RouteSimulator'

import { ILocationStatus, IRootProps } from '../../@types/types'
import { useCurrentShip } from '../../hooks/useCurrentShip'
import { usePrevious } from '../../hooks/usePrevious'
import { useAnalytics } from '../../services/AnalyticsWrapper/AnalyticsWrapper'
import {
  IBridgeDetails,
  ISelectedRoute,
  IShipInfo
} from '../../services/TeqplayAPIService/TeqplayApi'
import { checkIfPassedBridge } from '../../utils/routeUtils'
import { sendMessageToSentry } from '../../utils/sentry'
import { VDJS_INNER_RADIUS, VDJS_OUTER_RADIUS } from './LocationWatcherConfig'
import {
  IChannelCrossing,
  ISpeedState,
  IVDJSCollection,
  IVDJSCrossing
} from './LocationWatcherTypes'
import crossingLocationsNL from '../../assets/vdjs/nl_vdjsCrossings.json'
import crossingLocationsFR from '../../assets/vdjs/fr_vdjsCrossings.json'

interface IProps {
  routeSelection: ISelectedRoute | null // current selected route
  isNavigating: boolean // wether the user is navigating or not, based on a url, or some kind of 'navigating mode'
  channelCrossings?: IChannelCrossing[] // optional: A document with dangerous channels the user needs to be warned for.
  shipId: string | undefined

  // Route Simulator specific
  routeSimulatorActive: boolean
  currentLocation: IShipInfo | null
  speedSetting: number

  setCurrentLocation: (location: IShipInfo | null, appInBackground?: boolean) => void
  setNavigationRoute: (route: ISelectedRoute) => void
  stopCurrentRoute: () => void
  children: (
    VDJSNotificationMessage: string | null,
    channelApproachNotificationMessage: string | null,
    locationStatus: ILocationStatus
  ) => React.ReactNode
  teqplayAPIService: TeqplayApiService
}

const LocationWatcher = (props: IProps) => {
  const {
    routeSelection,
    isNavigating,
    channelCrossings,
    shipId,
    routeSimulatorActive,
    currentLocation,
    speedSetting,
    setCurrentLocation,
    setNavigationRoute,
    stopCurrentRoute,
    children,
    teqplayAPIService
  } = props
  const analytics = useAnalytics('LocationWatcher')
  const previousNavigating = usePrevious(isNavigating)
  const previousRouteSelection = usePrevious(routeSelection)
  const locale = useSelector((s: IRootProps) => s.i18n.locale)
  const VDJSCrossings: IVDJSCollection =
    locale === 'fr_FR' ? crossingLocationsFR : crossingLocationsNL

  const { shipInformation, loading, error, refresh } = useCurrentShip(teqplayAPIService, shipId)

  const [speedNotificationSend, setSpeedNotificationSend] = useState<boolean>(false)
  const [lastLocations, setLastLocations] = useState<IShipInfo[]>([])
  const [fiveLastSpeeds, setFiveLastSpeeds] = useState<number[]>([])
  const [channelCrossing, setChannelCrossing] = useState<IChannelCrossing | null>(null)
  const [vdjsCrossing, setVDJSCrossing] = useState<IVDJSCrossing | null>(null)
  const [VDJSNotificationMessage, setVDJSNotificationMessage] = useState<string | null>(null)
  const [channelApproachNotificationMessage, setChannelApproachNotificationMessage] = useState<
    string | null
  >(null)
  const [nextBridgeItem, setNextBridgeItem] = useState<IBridgeDetails | null>(null)
  const [appInBackground, setAppInBackground] = useState<boolean>(false)

  const memoizedReconfigureKeepAwake = useCallback(reconfigureKeepAwake, [
    isNavigating,
    routeSelection
  ])

  useEffect(() => {
    addEventListeners()
    return () => removeEventListeners()
    // Only execute on mount and unmount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (
      window.cordova &&
      (isNavigating !== previousNavigating ||
        isRouteActive(routeSelection) !== isRouteActive(previousRouteSelection || null))
    ) {
      // Reconfigure keep device awake if navigating changes or routeSelection changes
      memoizedReconfigureKeepAwake()
    }
  }, [
    isNavigating,
    previousNavigating,
    routeSelection,
    previousRouteSelection,
    memoizedReconfigureKeepAwake
  ])

  useEffect(() => {
    onLocationSuccess(shipInformation)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shipInformation])

  return (
    <React.Fragment>
      <RouteSimulator
        enabled={routeSimulatorActive}
        currentPosition={currentLocation}
        route={routeSelection}
        speedSetting={speedSetting}
        processPosition={position => onLocationSuccess(position)}
      />
      {children(VDJSNotificationMessage, channelApproachNotificationMessage, {
        loading,
        error,
        refreshLocation: refresh
      })}
    </React.Fragment>
  )

  /**
   * Function to be executed upon a new location that has been retrieved
   */
  function onLocationSuccess(location: IShipInfo | null) {
    if (!location) {
      setCurrentLocation(null, appInBackground)
      return
    }

    const previousLocation: IShipInfo | undefined = lastLocations[lastLocations.length - 1]
    const previousLocations = produce(lastLocations, draft => {
      draft.push(location)

      // Make sure that there is always 2 minutes minimum of local trace saved or 20 items
      const MIN_LENGTH = 120 / 5 // 120 seconds / 5s interval
      if (draft.length > Math.max(MIN_LENGTH, 20)) {
        draft.shift()
      }

      return draft
    })

    // Filter out any locations which have appeared less than half a second after the previous location
    // BEFORE processing them in the app
    if (
      location &&
      previousLocation &&
      location.mmsi === previousLocation.mmsi &&
      location.timeLastUpdate - previousLocation.timeLastUpdate < 500 &&
      isEqual(location.dimensions, previousLocation.dimensions)
    ) {
      return
    }

    // New ship selected, clear last known locations for debugging
    if (location.mmsi !== previousLocation?.mmsi) {
      setLastLocations([])
    }

    // syncs the location with redux and saves it to the api
    setCurrentLocation(location, appInBackground)
    setLastLocations(previousLocations)

    // handles any notifications which are fired based on the location / speed
    if (routeSelection && isRouteActive(routeSelection)) {
      checkSpeedOverride(location.speedOverGround)
      if (location.location) {
        if (channelCrossings) {
          checkChannelCrossings(location.location, channelCrossings)
        }

        if (VDJSCrossings) {
          checkVDJSCrossing(location.location, VDJSCrossings)
        }

        findRouteItemsInRadius(location.location, routeSelection)
        detectIfNextBridgeHasBeenPassed()
      }
    }
  }

  function addEventListeners() {
    document.addEventListener('resume', () => onResumeApp(), false)
    document.addEventListener('pause', () => onPauseApp(), false)
  }

  /**
   * Removes event listeners so plugin does not keep listening in
   */
  function removeEventListeners() {
    document.removeEventListener('resume', () => onResumeApp(), false)
    document.removeEventListener('pause', () => onPauseApp(), false)
  }

  /**
   * Returns a boolean value indicating if the user is currently actively
   * navigating a route.
   */
  function isRouteActive(route: ISelectedRoute | null): boolean {
    if (route && route !== null && route.paused === false) {
      return true
    } else {
      return false
    }
  }

  function reconfigureKeepAwake() {
    // based on the page, change the gps fetch distance
    if (isNavigating) {
      // make a distinction between fetch distance based on wether the route is active
      if (isRouteActive(routeSelection)) {
        if (window.plugins?.insomnia) {
          console.info('[INSOMNIA] Keeping app awake, navigating an active route')
          window.plugins.insomnia.keepAwake()
        }
      } else {
        if (window.plugins?.insomnia) {
          console.info('[INSOMNIA] Allowing app to go sleep again, navigating but route is paused')
          window.plugins.insomnia.allowSleepAgain()
        }
      }
    } else {
      if (window.plugins?.insomnia) {
        console.info('[INSOMNIA] Allowing app to go sleep again, no active route')
        window.plugins.insomnia.allowSleepAgain()
      }
    }
  }

  /**
   * Pauses the retrieval of gps locations by the background geolocation plugin.
   */
  function onPauseApp() {
    setAppInBackground(true)
    analytics.newEvent('pause_app', {
      isRouteActive: isRouteActive(routeSelection)
    })
  }

  function onResumeApp() {
    setAppInBackground(false)
    analytics.newEvent('resume_app', {
      activeRoute: isRouteActive(routeSelection)
    })
  }

  /**
   * Sends a mobile notification to the user
   */
  function notifyUser(title: string, text: string) {
    if (window.cordova) {
      analytics.newEvent('sent_notification', { title, text })
      window.cordova.plugins.notification.local.schedule({
        title,
        text,
        foreground: true
      })
    } else if (routeSimulatorActive) {
      toast.info(`${title} | ${text}`)
    }
  }

  /**
   * Checks wether the users ship is moving too fast.
   * If so it sends the user a notification
   * @param speed
   */
  function checkSpeedOverride(speed: ISpeedState['userInputSpeed'] | null) {
    if (!speed) return

    const speedLimit = 80
    const speedKmh = speed / 0.539956803
    const lastSpeeds = produce(fiveLastSpeeds, draft => {
      draft.push(speedKmh)

      // If longer than 5 remove first item
      if (draft.length > 5) {
        draft.shift()
      }
      return draft
    })

    // Check if minimal 4/5 are higher than 80 km/h
    if (!speedNotificationSend && fiveLastSpeeds.filter(s => s >= speedLimit).length >= 4) {
      // Send speed override notification
      notifyUser(I18n.t('mobile.high_speed_title'), I18n.t('mobile.high_speed_text'))
      setSpeedNotificationSend(true)
    } else if (speedNotificationSend && fiveLastSpeeds.filter(s => s < speedLimit).length >= 4) {
      // Reset speed notification, so new one is send when user again overrides the speed
      setSpeedNotificationSend(false)
    }

    setFiveLastSpeeds(lastSpeeds)
  }

  /**
   * Checks if the user is approaching a channel of which he has to be notified of.
   * @param location
   */
  function checkChannelCrossings(
    location: { latitude: number; longitude: number },
    crossingsToCheck: IChannelCrossing[]
  ) {
    crossingsToCheck.forEach(channel => {
      const insideInnerPolygon =
        pointInPolygon.feature(channel.innerPolygon, [location.longitude, location.latitude]) === -1
          ? false
          : true
      const insideOuterPolygon =
        pointInPolygon.feature(channel.outerPolygon, [location.longitude, location.latitude]) === -1
          ? false
          : true

      const notificationMessage = I18n.t('map.notification.nearingChannelNotification', {
        channelName: channel.name
      })

      if (!channelCrossing) {
        if (insideInnerPolygon) {
          setChannelCrossing(channel)
          setChannelApproachNotificationMessage(notificationMessage)
          notifyUser(I18n.t('map.notification.channelNotificationHeader'), notificationMessage)
        }
      } else {
        if (!insideOuterPolygon) {
          setChannelCrossing(null)
          setChannelApproachNotificationMessage(null)
        }
      }
    })
  }

  /**
   * Checks if the users is approaching a VDJS crossing and notifies the user if required
   * @param location
   */
  function checkVDJSCrossing(
    location: { latitude: number; longitude: number },
    vdjsCrossings: IVDJSCollection
  ) {
    const prevCrossing = vdjsCrossing
    if (prevCrossing) {
      // see whether we left the crossing
      const distance = headingDistanceTo(location, prevCrossing.location).distance

      if (distance > VDJS_OUTER_RADIUS) {
        // we left the crossing
        setVDJSCrossing(null)
      } else {
        setVDJSCrossing({ ...vdjsCrossing, distance } as IVDJSCrossing)
      }
    } else {
      // see if we entered a crossing
      const crossing = findVDJSCrossing(location, vdjsCrossings, VDJS_INNER_RADIUS)
      if (crossing) {
        // we entered a crossing
        setVDJSCrossing(crossing)

        const message = I18n.t('map.notification.approachingVdjsCrossing', {
          crossingName: crossing.name
        })

        const userNotification = I18n.t('map.notification.approachingVdjsCrossingShort', {
          crossingName: crossing.name
        })

        notifyUser(I18n.t('map.notification.approachVdjsHeader'), userNotification)
        setVDJSNotificationMessage(message)
      }
    }
  }

  /**
   * Finds VDJS Crossings based on a given latitude, longitude and maximum distance
   * The function will return crossings if a crossing is found within the { maxDistance } away from the latitude and longitude
   * @param location
   * @param maxDistance
   */
  function findVDJSCrossing(
    location: { latitude: number; longitude: number },
    allCrossings: IVDJSCollection,
    maxDistance = 2500
  ): IVDJSCrossing | null {
    const crossings = allCrossings.features
      .map(feature => {
        const crossingLocation = toLatLon(feature.geometry.coordinates as LonLatTuple)
        const distance = headingDistanceTo(location, crossingLocation).distance

        const crossing: IVDJSCrossing = {
          id: feature.properties.F1,
          name: feature.properties.FID_,
          description: feature.properties.Uitlegs,
          location: crossingLocation,
          distance,
          website: feature.properties.Website,
          pdf: feature.properties.PDF
        }

        return crossing
      })
      .filter(crossing => crossing.distance <= maxDistance)

    return first(sortBy(crossings, 'distance')) || null
  }

  function findRouteItemsInRadius(
    location: { latitude: number; longitude: number },
    route: ISelectedRoute,
    radius = 100
  ) {
    const itemsInRange = route.route.routeItems
      .map(ri => ({
        ...ri,
        // Always looks at the first item containing a location
        distanceToSelf:
          ri.location[0].coordinates &&
          headingDistanceTo(location, ri.location[0].coordinates).distance // meters
      }))
      .filter(ri => ri.distanceToSelf < radius)

    if (itemsInRange.find(ri => ri.type === 'VIA')) {
      notifyUser(I18n.t('map.notification.viaPointHeader'), '')
    }

    if (itemsInRange.find(ri => ri.type === 'END')) {
      notifyUser(
        I18n.t('map.notification.endOfRouteHeader'),
        I18n.t('map.notification.endOfRouteSubtitle', { radius })
      )
      // Stop navigating
      stopCurrentRoute()
    }
  }

  async function detectIfNextBridgeHasBeenPassed() {
    const nextBridgeWithoutETA = routeSelection?.route.routeItems.find(
      ri => ri.type === 'BRIDGE' && ri.eta && !ri.hasPassedFrontend
    )
    let nextBridge = nextBridgeItem

    if (
      (!nextBridgeItem && nextBridgeWithoutETA) || // no bridge initialized yet
      (nextBridgeWithoutETA && nextBridgeWithoutETA?.refUuid !== nextBridgeItem?.isrsCode) // bridge changed, check next bridge
    ) {
      try {
        const fetchedBridgeItem = (await teqplayAPIService.fetchItemDetails(
          nextBridgeWithoutETA.type,
          nextBridgeWithoutETA?.refUuid
        )) as IBridgeDetails
        setNextBridgeItem(fetchedBridgeItem)
        nextBridge = nextBridgeItem
      } catch (err) {
        console.error(err)
        setNextBridgeItem(null)
        nextBridge = null
      }
    }

    const filteredLocations = lastLocations.map(x => x.location).filter(x => x) as {
      latitude: number
      longitude: number
    }[]
    if (nextBridge && nextBridgeWithoutETA && filteredLocations.length >= 2) {
      const hasPassedFrontend = checkIfPassedBridge(
        {
          latitude: nextBridgeWithoutETA.singleLocation.coordinates[1],
          longitude: nextBridgeWithoutETA.singleLocation.coordinates[0]
        },
        nextBridge.rotation,
        filteredLocations.map(p => [p.latitude, p.longitude])
      )

      if (hasPassedFrontend) {
        const newRoute = cloneDeep(routeSelection)
        const matchingIndex = newRoute?.route.routeItems.findIndex(
          ri => ri._id === nextBridgeWithoutETA._id
        )

        if (matchingIndex && newRoute) {
          const newBridgeItem = {
            ...newRoute?.route.routeItems[matchingIndex],
            hasPassedFrontend: true
          }
          newRoute.route.routeItems[matchingIndex] = newBridgeItem
          setNavigationRoute(newRoute)
        } else {
          sendMessageToSentry(
            `An error occurred while passing bridge ${nextBridge.name}, ${nextBridge.city}`,
            {
              tenLastLocations: lastLocations,
              nextBridge,
              nextBridgeWithoutETA,
              newRouteItems: newRoute?.route.routeItems,
              matchingIndex
            }
          )
        }
      }
    }
  }
}

export default LocationWatcher
