import { flatMap } from 'lodash'
import { useEffect, useState } from 'react'
import { useQuery } from 'react-query'

import { IBicsVoyage, SupportedLocales } from '../@types/types'
import { BicsVoyage } from '../services/TeqplayAPIService/TeqplayApi'
import TeqplayApiService from '../services/TeqplayAPIService/TeqplayApiService'

export function useBICSVoyages(
  api: TeqplayApiService,
  userId: string,
  locale?: SupportedLocales
): {
  voyages: IBicsVoyage[] | null
  // The sole reason there is an `any` here is because I could not get the types to work.
  // The function does return a Promise<void> but its return type is not cooperating
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  refresh: () => Promise<any>
  loading: boolean
  error: {} | null
} {
  const [correctedData, setCorrectedData] = useState<IBicsVoyage[] | null>(null)
  const [extraLoading, setExtraLoading] = useState<boolean>(false)

  const {
    data,
    isFetching: loading,
    error,
    refetch: refresh
  } = useQuery<BicsVoyage[] | null | undefined, {} | null>(
    ['all-bics-voyages', { userId }],
    () => api.getBICSTravelRoutes(),
    {
      refetchIntervalInBackground: false,
      refetchInterval: 30 * 1000
    }
  )

  useEffect(() => {
    async function addMissingItemsToVoyage(travels: BicsVoyage[]): Promise<void> {
      if (travels.length === 0) {
        setCorrectedData(null)
        return
      }

      try {
        setExtraLoading(true)
        const locationIdentifiers = flatMap(
          travels.map(t => [t.fromLocation, ...t.viaPoints.map(vp => vp.location), t.toLocation])
        )
        const cargoIdentifiers = flatMap(
          travels.map(t => t.cargo.map(c => c.vnNr || c.hsCode))
        ).filter(s => s) as string[]

        const allLocationObjects = await api.retrieveBICSDestinationsList([...locationIdentifiers])
        const allCargoObjects = await api.getBICSCargoList(cargoIdentifiers, locale)

        setCorrectedData(
          travels.map(t => ({
            ...t,
            fromLocationObject: allLocationObjects.find(l => l.srsCode === t.fromLocation),
            viaLocationObjects: t.viaPoints.map(vp =>
              allLocationObjects.find(l => l.srsCode === vp.location)
            ),
            toLocationObject: allLocationObjects.find(l => l.srsCode === t.toLocation),
            cargoObjects: allCargoObjects
          }))
        )
      } catch (err) {
        console.error(err)
        setCorrectedData(travels)
      } finally {
        setExtraLoading(false)
      }
    }

    addMissingItemsToVoyage(data || [])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, api, userId])

  return {
    voyages: correctedData || null,
    refresh,
    loading: loading || extraLoading,
    error
  }
}
