import React, { useState } from 'react'
import { I18n } from 'react-redux-i18n'
import { Link, useHistory } from 'react-router-dom'

import DefaultTransition from '../../../components/shared/defaultTransition/DefaultTransition'
import TitleComponent from '../../../components/titleComponent/TitleComponent'
import LocalePicker from '../../../components/localePicker/LocalePicker'
import PasswordInput from '../../../components/passwordInput/passwordInput'
import { useLocalStorage } from '../../../hooks/useLocalStorage'
import { isCordovaApp } from '../../../utils/cordovaUtils'
import { getTimebasedGreeting } from '../../../utils/general'

import './LoginForm.scss'

interface IProps {
  handleLogin: (username: string, password: string) => void
  errorMessage: string | undefined
  loading: boolean
  backendURL: string
}

const LoginForm = ({ handleLogin, errorMessage, loading, backendURL }: IProps) => {
  const history = useHistory()
  const [username, setUsername] = useLocalStorage<string>('username', '')
  const [password, setPassword] = useState<string>('')
  const [disclaimerActive, setDisclaimerActive] = useState(true)

  return (
    <DefaultTransition active={true} slideFrom="top" offset={10}>
      {props => (
        <div style={props} className="login-container">
          <TitleComponent title={I18n.t('login.login')} />
          <div className="login-form-wrapper">
            <div className="login-form-inner-container">
              {disclaimerActive ? (
                <div className="disclaimer-wrapper" id={'loginform-disclaimer'}>
                  <h2 id={'loginform-disclaimer-title'}>{I18n.t('disclaimer.title')}</h2>
                  <p id={'loginform-disclaimer-content'}>{I18n.t('disclaimer.content')}</p>
                  <button
                    className="button login-button large"
                    onClick={() => {
                      setDisclaimerActive(false)
                    }}
                    id={'loginform-disclaimer-button-agree'}
                  >
                    {I18n.t('disclaimer.agree')}
                  </button>
                </div>
              ) : (
                <>
                  <div className="login-header">
                    <div className="title">
                      <div className="app-logo" id={'loginform-applogo'} />
                      <h2 id={'loginform-apptitle'}>{I18n.t('appTitle')}</h2>
                    </div>
                    <p id={'loginform-welcome-timebased'} className="welcome-message">
                      {I18n.t(`login.welcome.${getTimebasedGreeting()}`)}
                    </p>
                    <LocalePicker />
                    {/** This block is a last warning-test for cordova apps to be absolutely clear that it is a develop build  */}
                    {backendURL !== 'https://backend.teqplay.nl' && isCordovaApp && (
                      <div className="error-message">
                        {I18n.t('login.developMode', { url: backendURL })}
                      </div>
                    )}
                  </div>

                  {errorMessage && <div className="error">{errorMessage}</div>}
                  <form
                    onSubmit={e => {
                      e.preventDefault()
                      handleLogin(username, password)
                    }}
                  >
                    <div className="input-wrappers">
                      <input
                        className="input"
                        placeholder={I18n.t('login.username')}
                        value={username}
                        onChange={e => setUsername(e.target.value)}
                        autoCapitalize="none"
                      />
                      <PasswordInput
                        password={password}
                        setPassword={setPassword}
                        className="input"
                        autoFocus={username !== ''}
                      />
                    </div>
                    <button className="button login primary" type="submit">
                      {loading ? (
                        <i className="icon-circle-notch animate-spin" />
                      ) : (
                        I18n.t('login.login')
                      )}
                    </button>
                  </form>

                  <div className="button-set-wrapper">
                    <Link
                      className="button secondary button-register second"
                      to={`/register`}
                      id={'loginform-button-register-newaccount'}
                    >
                      {I18n.t('login.registerLink')}
                    </Link>
                    <Link
                      className="button secondary button-register second"
                      to={`/forgot`}
                      id={'loginform-button-forgot-password'}
                    >
                      {I18n.t('login.forgot')}
                    </Link>
                  </div>
                </>
              )}
            </div>
          </div>
          <button
            className="button secondary button-register privacy-button"
            onClick={() => history.push('/privacy')}
          >
            {I18n.t('privacy')}
          </button>
        </div>
      )}
    </DefaultTransition>
  )
}

export default LoginForm
