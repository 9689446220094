export const margrietKanaalInnerPolygon = {
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',
      properties: {},
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [5.690412259937488, 52.86414888909112],
            [5.701142641478849, 52.8771499699994],
            [5.7015717401730415, 52.877809362314345],
            [5.701802869724385, 52.878503920923364],
            [5.705110923397996, 52.89655227519178],
            [5.705144914971836, 52.89681784400329],
            [5.7063928010491045, 52.91392722384305],
            [5.706335542036522, 52.91465927921389],
            [5.706059378542897, 52.91537298076566],
            [5.695913900763637, 52.9338035514641],
            [5.696833499998225, 52.95738931437519],
            [5.697776157668249, 52.96933490980008],
            [5.697678374060799, 52.97023849540463],
            [5.694189661327628, 52.98193596773912],
            [5.694395248234845, 52.98337287122231],
            [5.695895251659148, 52.98628350363864],
            [5.7000384871707634, 52.98931451590641],
            [5.700318664019178, 52.989534453306945],
            [5.705496866310181, 52.99390187325771],
            [5.7184606680920425, 53.0004968224277],
            [5.718909692962836, 53.00074722177191],
            [5.724445993409278, 53.00412983113791],
            [5.725096850684155, 53.004592343784424],
            [5.725625187370875, 53.00510832802042],
            [5.726955819830749, 53.006657604039205],
            [5.727422931399743, 53.007353579523894],
            [5.728667960824001, 53.00985819935737],
            [5.728901783306338, 53.01053572806385],
            [5.730450041510359, 53.018337681458156],
            [5.732796066878961, 53.021366186860966],
            [5.740017491247258, 53.02621165344936],
            [5.7605132389965705, 53.03701934363865],
            [5.771238632704016, 53.04077024087388],
            [5.77153544332368, 53.04088032055016],
            [5.781191133850042, 53.04467233023075],
            [5.782175114474476, 53.04514330868182],
            [5.789041776687963, 53.04911566752249],
            [5.7890589643003505, 53.04912564463203],
            [5.798801053191081, 53.05479981115426],
            [5.798925530555897, 53.05487421801521],
            [5.811242737519049, 53.06243004970801],
            [5.812130622634902, 53.06310580493606],
            [5.81882285747854, 53.06949834414368],
            [5.823167217595922, 53.07270389137744],
            [5.831811679826195, 53.07549279503599],
            [5.832837989969924, 53.075900683775835],
            [5.840004855566813, 53.07935463106208],
            [5.840877124386329, 53.07985892526466],
            [5.841584615190866, 53.08044905703137],
            [5.852443883622257, 53.09147988868817],
            [5.853146835347009, 53.092480984001924],
            [5.855874703907754, 53.09853886850469],
            [5.862102865794065, 53.10427404556026],
            [5.869391974329792, 53.10722006180831],
            [5.875921291806484, 53.10962192368138],
            [5.877036535471787, 53.11013550984803],
            [5.877949636096278, 53.11077701871542],
            [5.882670874875069, 53.11489804973597],
            [5.883341144096953, 53.115638088425804],
            [5.883741032400227, 53.116445495639326],
            [5.885494398496444, 53.12208582696635],
            [5.890595864922039, 53.129242343516715],
            [5.89506946896791, 53.134823537946005],
            [5.911957180823749, 53.14130959573966],
            [5.9121046591821775, 53.141367867052416],
            [5.922489934968533, 53.14558853483729],
            [5.9234133629275325, 53.14604009236503],
            [5.924189682710859, 53.14658218685545],
            [5.935305947917718, 53.15589764643902],
            [5.935915890289345, 53.156519275098276],
            [5.936331765054645, 53.15719604010201],
            [5.941351154536439, 53.16834445141828],
            [5.947205863648642, 53.17250222771785],
            [5.95688607183473, 53.17641614886421],
            [5.968663602056531, 53.18037739006537],
            [5.983398846966214, 53.18109360789827],
            [5.995353702943199, 53.18127059946386],
            [5.996771271267144, 53.1813823593016],
            [6.008142762388793, 53.183027773660775],
            [6.0092096332637395, 53.183239821260955],
            [6.0102029306797435, 53.18355510744335],
            [6.020484808934186, 53.18750822765741],
            [6.035501446600807, 53.190826293359876],
            [6.035703467777268, 53.19087313440337],
            [6.053887578784161, 53.19528968448785],
            [6.076793501316676, 53.1977340146458],
            [6.077419769698312, 53.19781936435961],
            [6.09424107470047, 53.20062064786729],
            [6.095374515844538, 53.200876885010395],
            [6.096413250247256, 53.2012503620181],
            [6.0973238150459235, 53.201729051082026],
            [6.106593957365721, 53.20756304815656],
            [6.1072241644350695, 53.208023087877194],
            [6.1077348204815065, 53.20853354408143],
            [6.110896912828692, 53.21229734766963],
            [6.116367972219722, 53.215769049833526],
            [6.120766912998967, 53.216825716911245],
            [6.131472890362538, 53.216894403067805],
            [6.132642791039516, 53.216963099665506],
            [6.147051873909431, 53.21857559574061],
            [6.159830170225024, 53.21988026698561],
            [6.161589019592404, 53.22021264916127],
            [6.17643684570605, 53.224425400155624],
            [6.177618621198326, 53.22485419974844],
            [6.191927611210725, 53.23132832309898],
            [6.196970482979315, 53.233420578020315],
            [6.20636364245156, 53.2332336237422],
            [6.20710332159097, 53.23324311469572],
            [6.221735818276319, 53.233910599240154],
            [6.2230369855975, 53.23404734452956],
            [6.239987024763308, 53.236871937680746],
            [6.241222781314597, 53.23715843931845],
            [6.242341433857353, 53.23758371693874],
            [6.243299997111034, 53.2381314340056],
            [6.244061630934108, 53.2387805484029],
            [6.244597056539954, 53.2395061201053],
            [6.244885683128761, 53.24028026883673],
            [6.244916401416622, 53.24107324503107],
            [6.244688013226795, 53.24185457300961],
            [6.2442092802283184, 53.24259422245587],
            [6.243498589538731, 53.24326376312134],
            [6.242583248690099, 53.24383745828755],
            [6.241498436812407, 53.244293254825585],
            [6.2402858522487366, 53.244613631651674],
            [6.238992108645333, 53.2447862738261],
            [6.237666941376473, 53.24480454627204],
            [6.236361293571736, 53.24466774883944],
            [6.220047699611241, 53.241948580365296],
            [6.206443363515876, 53.24132752275039],
            [6.195182290299318, 53.241551160089465],
            [6.193748521993812, 53.241488002319926],
            [6.19236951892595, 53.24124480999324],
            [6.191107669322063, 53.24083258948845],
            [6.184112327262198, 53.237929730174585],
            [6.183888109867627, 53.23783254350781],
            [6.170022524136992, 53.23155769031309],
            [6.156641992015678, 53.22776047632288],
            [6.144726835043402, 53.22654343264023],
            [6.1446228179897275, 53.22653229965865],
            [6.1307389448045235, 53.224978103744604],
            [6.119354268825772, 53.22490459551992],
            [6.11811091441502, 53.22482723341851],
            [6.116912681535196, 53.224614017738205],
            [6.1099598987317965, 53.22294352369556],
            [6.108672792883414, 53.22253259570907],
            [6.107563109939686, 53.221965789639974],
            [6.100396590193431, 53.21741696823644],
            [6.099276102093626, 53.21645918082646],
            [6.096121988449211, 53.21270310945058],
            [6.088828942276556, 53.208112130829115],
            [6.074111699611563, 53.20566049364792],
            [6.050822441563796, 53.2031742661593],
            [6.049472704840329, 53.20294104233906],
            [6.0307319329735956, 53.198388395049264],
            [6.0151253685688655, 53.19493913800904],
            [6.013817751582888, 53.194549003984],
            [6.003872993718454, 53.19072479995423],
            [5.994302714203862, 53.18933960677785],
            [5.98287515872694, 53.18917000320301],
            [5.982498057953964, 53.18915804679937],
            [5.966102571506712, 53.18836047208874],
            [5.96468035377452, 53.18819770852504],
            [5.963348936583762, 53.18785676559833],
            [5.950044592699108, 53.18338115915057],
            [5.949574764271851, 53.18320753147677],
            [5.938888592671058, 53.17888588360975],
            [5.937507919912106, 53.17814266469484],
            [5.930084087539222, 53.17286889314078],
            [5.929263995255754, 53.17213701227106],
            [5.9287283403635245, 53.171315923823826],
            [5.923640364080001, 53.16000524976373],
            [5.913936318533027, 53.15187034131295],
            [5.9046218575757745, 53.14808396078162],
            [5.886199175106778, 53.1410065955506],
            [5.885221847290548, 53.14054976151753],
            [5.884400666855103, 53.13999322396904],
            [5.883763443884544, 53.139355838864084],
            [5.878400179803466, 53.13266141066587],
            [5.878277764557666, 53.13249955968479],
            [5.8728288938349875, 53.12485124103301],
            [5.8724012026368415, 53.12400379925831],
            [5.870775493936086, 53.11876743099366],
            [5.867673211293847, 53.11605854204821],
            [5.862239740476236, 53.114059313591405],
            [5.861998374092933, 53.1139661841758],
            [5.853458019308716, 53.11051362865743],
            [5.852423822955922, 53.109998242772754],
            [5.851581048384332, 53.1093696953261],
            [5.8440289291179, 53.10241269619587],
            [5.843524731648166, 53.101857395452186],
            [5.843171192712333, 53.10126163084063],
            [5.840406508160194, 53.09511657386262],
            [5.830638682927477, 53.0851902706925],
            [5.824909768071715, 53.08242864144459],
            [5.815626100999877, 53.07943277352862],
            [5.814527939196768, 53.07898946939624],
            [5.813595727424031, 53.07842547592909],
            [5.808145903916345, 53.07440301952168],
            [5.807669310235383, 53.074003838179344],
            [5.801144421541573, 53.067768750472304],
            [5.789393860196351, 53.06055847452272],
            [5.779724005523039, 53.054924882603196],
            [5.773321470225054, 53.051220078397385],
            [5.764340167735357, 53.04769212318522],
            [5.753203775755357, 53.04379660810284],
            [5.752154892230234, 53.04334309022136],
            [5.7308691883255864, 53.0321161020023],
            [5.730297933066773, 53.03177614118371],
            [5.72214449126301, 53.02630365180379],
            [5.72154163326107, 53.02583344579885],
            [5.721060106182824, 53.0253150996158],
            [5.717842178394098, 53.02115884449194],
            [5.71747141154099, 53.02055998665938],
            [5.717262559798738, 53.01993342139777],
            [5.7156588361576866, 53.01183583478524],
            [5.714759405158688, 53.0100249840283],
            [5.714182979431431, 53.009353523827485],
            [5.709574856175724, 53.00653721972618],
            [5.696204828051627, 52.999733843493466],
            [5.695077233356878, 52.998996903780565],
            [5.689546796792757, 52.99433080522664],
            [5.684715341721551, 52.99079513420845],
            [5.683992690052863, 52.99014736995139],
            [5.683490874645883, 52.98942794829473],
            [5.681388807886691, 52.98534588648807],
            [5.6811130953045215, 52.98449720346293],
            [5.68077024871348, 52.98209436870939],
            [5.680851462051744, 52.98103549924599],
            [5.684367485792146, 52.96926141704218],
            [5.683461418587114, 52.957722859060965],
            [5.683455735723333, 52.957625753732],
            [5.682516326384466, 52.933292272226716],
            [5.6825883295896364, 52.932600995137726],
            [5.682855620849185, 52.93192735771545],
            [5.6929847941599325, 52.91353880503823],
            [5.691806996564445, 52.89730458753385],
            [5.688660613907162, 52.880101464275505],
            [5.678248838141547, 52.86747992177967],
            [5.677882951245586, 52.86694213239909],
            [5.6667288470975645, 52.84644413119122],
            [5.666444473914665, 52.84567337454729],
            [5.6664145317752475, 52.84488392510957],
            [5.66664015405841, 52.844106118905145],
            [5.667112655455066, 52.84336984226809],
            [5.667813867987635, 52.84270338398249],
            [5.668716840718726, 52.84213234906076],
            [5.669786876028048, 52.84167867576304],
            [5.670982862547664, 52.841359793479704],
            [5.672258853604142, 52.841187953693776],
            [5.6735658307289345, 52.84116975961738],
            [5.6748535848003145, 52.84130591250924],
            [5.676072642922507, 52.841591184410355],
            [5.677176167393319, 52.84201461839413],
            [5.678121754125815, 52.842559948735015],
            [5.678873061644066, 52.84320622498575],
            [5.6794012081379295, 52.84392861613374],
            [5.690412259937488, 52.86414888909112]
          ]
        ]
      }
    }
  ]
}

export const margrietKanaalOuterPolygon = {
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',
      properties: {},
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [5.708976994489137, 52.859540230578034],
            [5.719067737359926, 52.87175704534524],
            [5.720785572796207, 52.87439437882958],
            [5.721711731166632, 52.87717259241146],
            [5.725030500841772, 52.895221520679236],
            [5.72516709434817, 52.89628384497554],
            [5.726425004147264, 52.91339425795393],
            [5.726197607226602, 52.91632276578491],
            [5.725094293288924, 52.919178047114116],
            [5.716053979109794, 52.93561753745574],
            [5.71689824406742, 52.95695823691646],
            [5.717836348210669, 52.968758923271594],
            [5.717447215703381, 52.97237364395355],
            [5.7148463096310085, 52.9811115948312],
            [5.715551031926183, 52.98162685824461],
            [5.716672045425976, 52.98250640759702],
            [5.720389811190357, 52.985640490100856],
            [5.731432383271457, 52.991255808773026],
            [5.733228609749166, 52.99225709160194],
            [5.738765572209421, 52.99563871778504],
            [5.7413695434409835, 52.99748830303476],
            [5.743483801624031, 52.999551875692106],
            [5.744815204008047, 53.0011009347031],
            [5.746685167051791, 53.003884573368765],
            [5.7479316493736565, 53.006389066000914],
            [5.748868547982334, 53.00909915104045],
            [5.749999955693771, 53.01478352932509],
            [5.754187956993875, 53.01759229390276],
            [5.772312804003625, 53.02714632758578],
            [5.781354398852702, 53.030307248127436],
            [5.782541521033315, 53.030747381127455],
            [5.792196425060468, 53.034537848702634],
            [5.796132339541578, 53.036421097929114],
            [5.802999621891271, 53.04039224652528],
            [5.803068378676054, 53.040432142840416],
            [5.812811382991546, 53.04610459074107],
            [5.813309346226169, 53.04640213005029],
            [5.82562806020518, 53.05395577470748],
            [5.829180496824837, 53.05665816374428],
            [5.835220973489258, 53.06242476945416],
            [5.836246791096754, 53.063181351473006],
            [5.841342302356206, 53.064824636075976],
            [5.8454472278267176, 53.066455535731926],
            [5.852614101801964, 53.06990826593451],
            [5.856103490722606, 53.07192483060441],
            [5.85893434632392, 53.07428485698514],
            [5.8697986703427505, 53.08531383242004],
            [5.872612627677856, 53.08931780661741],
            [5.874510464181759, 53.09352685338651],
            [5.876761805847866, 53.09559881520789],
            [5.880297939668234, 53.09702744540075],
            [5.8864596228924455, 53.09929332789502],
            [5.890920515002286, 53.10134692834723],
            [5.894573655176898, 53.10391231352815],
            [5.899296545326434, 53.10803251101083],
            [5.901979049958139, 53.1109922221595],
            [5.90358047610016, 53.11422168929587],
            [5.90492807151391, 53.11854839747453],
            [5.908982096531809, 53.124230511906525],
            [5.910781198264301, 53.12647344687362],
            [5.922842698197626, 53.13110394234772],
            [5.92343256497008, 53.13133693328987],
            [5.933817127320649, 53.13555592475454],
            [5.937510868947692, 53.13736152817213],
            [5.940616765925408, 53.13952935263416],
            [5.951736601145896, 53.14884283962612],
            [5.954177512414554, 53.151328939483996],
            [5.955842530159511, 53.15403578167308],
            [5.959696111240566, 53.16258363173262],
            [5.960840424886775, 53.163395882196276],
            [5.967503694169198, 53.16608901425175],
            [5.974703563897402, 53.168510011898135],
            [5.984465688429661, 53.16898382158324],
            [5.9958502470046735, 53.169151790690655],
            [6.0015187135136525, 53.16959838841813],
            [6.0128869804762655, 53.171242647129496],
            [6.017153416589939, 53.1720903343093],
            [6.0211260154434445, 53.17335089761916],
            [6.029552311280929, 53.17658967275631],
            [6.042498401427289, 53.17944911652408],
            [6.043306307559936, 53.17963637777181],
            [6.059523843948285, 53.183574207535074],
            [6.080338041281969, 53.18579393511832],
            [6.082842392029261, 53.18613508684775],
            [6.099659168679422, 53.18893454390403],
            [6.104191914749392, 53.18995892440508],
            [6.10834639096896, 53.19145219403622],
            [6.1119888049114905, 53.19336632313038],
            [6.121260229165169, 53.19919867371675],
            [6.123781632147395, 53.20103838330738],
            [6.125825183824808, 53.2030798590899],
            [6.127217128861713, 53.20473552784691],
            [6.131687219041758, 53.204763851317146],
            [6.136365284393378, 53.20503830893583],
            [6.150614267761182, 53.20663209722306],
            [6.163232454989516, 53.20791971352712],
            [6.170266085427091, 53.20924843838151],
            [6.185111262617729, 53.2134591150569],
            [6.189837854533418, 53.215173585942196],
            [6.203066411339128, 53.22115694670391],
            [6.205687595655587, 53.221104564804776],
            [6.208645288450108, 53.221142374711796],
            [6.223272880218419, 53.22180890326475],
            [6.228475958298563, 53.22235542964284],
            [6.245421433518455, 53.22517818402904],
            [6.250363372090087, 53.22632356496138],
            [6.254837569889425, 53.228023974905014],
            [6.258672148642665, 53.23021417430979],
            [6.2617196983537635, 53.23281009649808],
            [6.263862949639879, 53.235712062863776],
            [6.265019303672393, 53.23880860109997],
            [6.265144042108065, 53.24198072160519],
            [6.2642320882370495, 53.24510648885933],
            [6.262318245184219, 53.248065712301035],
            [6.259475895444068, 53.250744575688195],
            [6.255814206078473, 53.253040025442694],
            [6.2514739431151565, 53.25486374713946],
            [6.24662205451142, 53.25614557485718],
            [6.24144523098246, 53.25683620001165],
            [6.236142695717838, 53.25690907367927],
            [6.230918505544292, 53.25636142817157],
            [6.21651953253713, 53.25396062835994],
            [6.206005955657169, 53.25348009515933],
            [6.195852359546014, 53.25368121846226],
            [6.190115369401681, 53.25342821831801],
            [6.18459791594872, 53.25245483720501],
            [6.179549810000615, 53.2508052101397],
            [6.172554045732103, 53.24790121598248],
            [6.171657137461587, 53.24751232183714],
            [6.159453570290774, 53.24198790570801],
            [6.1504786538105325, 53.239440183897244],
            [6.141314173962535, 53.23850346272749],
            [6.140897978293205, 53.23845889395088],
            [6.128750621767787, 53.23709851553588],
            [6.119132873170027, 53.23703587378125],
            [6.114157828398387, 53.23672606936721],
            [6.109363648424856, 53.2358726716664],
            [6.10240937426165, 53.23420127713808],
            [6.0972601955434165, 53.2325568120882],
            [6.092821592161898, 53.23028882945661],
            [6.085656102174589, 53.225738734103075],
            [6.081175643735527, 53.221906800703984],
            [6.0793728378872345, 53.219758417418205],
            [6.078008954424248, 53.21889952171141],
            [6.069610449811983, 53.21749973303093],
            [6.047264114840505, 53.21511280755587],
            [6.041863744448255, 53.21417931846009],
            [6.0234202509682255, 53.20969747147719],
            [6.008115132935491, 53.206313680700184],
            [6.002883802522915, 53.20475240076286],
            [5.99580673716099, 53.20203023600477],
            [5.991645310681537, 53.20142763545005],
            [5.982371194396922, 53.20128944488353],
            [5.980862277977555, 53.2012415288995],
            [5.964461314263398, 53.2004428606599],
            [5.958770735011691, 53.19979128977077],
            [5.953443998764689, 53.19842681072243],
            [5.940137893836709, 53.19394920185453],
            [5.938258387862213, 53.19325439804774],
            [5.927571464498232, 53.18893102796044],
            [5.922049071351551, 53.18595720072676],
            [5.914626807327101, 53.18068210037299],
            [5.911347637121141, 53.17775400347442],
            [5.9092068325576905, 53.174469355264435],
            [5.905075312042324, 53.1652727489677],
            [5.899602537436625, 53.16068246715146],
            [5.893501376031526, 53.158201354108094],
            [5.875300213259378, 53.151206796936535],
            [5.871390855830032, 53.149378805033805],
            [5.86810672830207, 53.147152069901104],
            [5.865558991031517, 53.14460209398103],
            [5.860199190627495, 53.137906819800534],
            [5.859709872419952, 53.137259341010015],
            [5.854265137584332, 53.12961021920852],
            [5.852556334546584, 53.1262202746262],
            [5.852025910432642, 53.124508528238],
            [5.851689049513957, 53.124384528379665],
            [5.850723499467464, 53.12401185756575],
            [5.842182541548728, 53.120557924949686],
            [5.838045825346006, 53.11849567660917],
            [5.834675537279505, 53.11598088638557],
            [5.827126364236349, 53.109022566179576],
            [5.825110647042582, 53.106801028466215],
            [5.823697836934118, 53.10441779095423],
            [5.821650102288679, 53.099860258679335],
            [5.815149574063752, 53.093250144327456],
            [5.813734375097496, 53.092567685235466],
            [5.806082825150281, 53.09009764099872],
            [5.801689866592396, 53.08832371973632],
            [5.797961446816451, 53.08606708799613],
            [5.792512900309301, 53.08204365551627],
            [5.79060713425796, 53.0804465922297],
            [5.785235095767694, 53.075310212910104],
            [5.775182633525341, 53.069139198298835],
            [5.765728538257782, 53.06362909945594],
            [5.760717636907146, 53.06072849142773],
            [5.753760465916962, 53.0579946830921],
            [5.743075174606086, 53.054255741021386],
            [5.73887945337685, 53.0524409808997],
            [5.71759467335278, 53.041210299420584],
            [5.715309893364709, 53.039850052331374],
            [5.70715787420719, 53.03437610379809],
            [5.704747091409593, 53.03249484971584],
            [5.702821967189318, 53.03042114233585],
            [5.699606209586317, 53.02626438821939],
            [5.698124477660741, 53.02386876133855],
            [5.6972905619921725, 53.02136248076579],
            [5.696147870617121, 53.015575418093206],
            [5.695889167170221, 53.01541723385815],
            [5.683221514846791, 53.008968823214104],
            [5.678711844646626, 53.00602026451254],
            [5.67358440786438, 53.001691909016856],
            [5.669192990192008, 52.99847670918301],
            [5.666303440623909, 52.99588514578128],
            [5.6642977284602285, 52.99300716865742],
            [5.662198017749308, 52.988924881162276],
            [5.66109718021971, 52.985530130364324],
            [5.66075576213705, 52.98312739732224],
            [5.661082985393613, 52.978892330172776],
            [5.664202727693549, 52.968465067584475],
            [5.663409807947131, 52.95829240448412],
            [5.663387304012397, 52.95790400806223],
            [5.662462078346934, 52.93357221027836],
            [5.6627516257059725, 52.930807389996204],
            [5.663822046901671, 52.92811329448439],
            [5.672794696104545, 52.9118407831723],
            [5.671815111800821, 52.89823078779571],
            [5.669141019359602, 52.88356269510436],
            [5.660322767434352, 52.872864496030445],
            [5.6588603822066075, 52.87071313036885],
            [5.647717937945484, 52.85021384599565],
            [5.646582254629, 52.84713076757246],
            [5.64646430800501, 52.84397318852002],
            [5.647368354247958, 52.84086241961576],
            [5.649259415196708, 52.837917936762224],
            [5.65206465845888, 52.83525280014195],
            [5.655676220614633, 52.83296932379037],
            [5.659955361347567, 52.831155159806805],
            [5.664737787510613, 52.82987994523887],
            [5.669839943702294, 52.82919263817254],
            [5.67506603113044, 52.82911964354835],
            [5.680215490359608, 52.82966379962783],
            [5.685090666713421, 52.83080426388102],
            [5.689504370125449, 52.83249730344634],
            [5.693287044369657, 52.83467796138215],
            [5.696293273867405, 52.83726253686065],
            [5.698407379417216, 52.840151786432465],
            [5.708976994489137, 52.859540230578034]
          ]
        ]
      }
    }
  ]
}
