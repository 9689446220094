import { addSeconds, subSeconds } from 'date-fns'
import { distanceTo, headingTo, Location } from 'geolocation-utils'
import polyUtil from 'polyline-encoded'
import React from 'react'
import { I18n } from 'react-redux-i18n'
import { toast } from 'react-toastify'

import { ISelectedRoute, IShipInfo } from '../../../services/TeqplayAPIService/TeqplayApi'
import { isCordovaApp } from '../../../utils/cordovaUtils'
import { formatIntoHoursAndMinutesFromNow } from '../../../utils/dates'

import './RouteSimulator.scss'

interface ISimulatedPosition {
  location: IShipInfo['location']
  timeLastUpdate: number
  courseOverGround: number

  posAccuracyMeters?: number
  speedOverGround?: number
}

interface IProps {
  enabled: boolean

  currentPosition: IShipInfo | null
  route: ISelectedRoute | null
  speedSetting: number
  processPosition: (newPosition: IShipInfo) => void
}

interface IState {
  debug: boolean
  simulating: boolean | null
  simulatingInterval: number
  ticksSinceLastSimulation: number

  closestPreviousIndexTime: null | Date
  closestPreviousIndex: null | number
  routeIndexLength: null | number

  simulationStartTime: null | number
  simulatedPositionTime: null | number
  simulatedPosition: null | ISimulatedPosition
  simulatedDistance: number
  routeDistance: null

  totalRouteDistanceArray: null | number[]
  speedMetersPerSecond: null | number
}

/**
 * Class that simulates a users position along a given route
 */
class RouteSimulator extends React.PureComponent<IProps, IState> {
  private simulatingInterval: NodeJS.Timeout | undefined
  private tickRate: NodeJS.Timeout | undefined

  constructor(props: IProps) {
    super(props)
    this.state = {
      debug: false, // Show even more advanced logging inside the console
      simulating: null, // Boolean or NULL when initialized

      simulatingInterval: 10000,
      ticksSinceLastSimulation: 0,

      closestPreviousIndexTime: null,
      closestPreviousIndex: null,
      routeIndexLength: null,

      simulationStartTime: null,
      simulatedPositionTime: null,
      simulatedPosition: null,
      simulatedDistance: 0,
      routeDistance: null,

      totalRouteDistanceArray: null,
      speedMetersPerSecond: 0
    }
  }

  public componentDidUpdate(prevProps: IProps) {
    if (!prevProps.route && this.props.route && this.state.simulating) {
      // New route started
      const routeIndexLength = polyUtil.decode(this.props.route.route.polyLine).length
      this.setState({ routeIndexLength })
      this.toggleSimulation()
    } else if (this.state.simulating && !this.props.route) {
      // Route stopped
      this.clearSimulation()
    }

    if (this.props.speedSetting !== prevProps.speedSetting) {
      this.setState({
        speedMetersPerSecond: this.props.speedSetting / 1.944
      })
    }
  }

  public render() {
    if (!this.props.enabled || !this.props.route || isCordovaApp) {
      return null
    }

    const {
      simulating,
      simulatingInterval,
      simulatedDistance,
      speedMetersPerSecond,
      ticksSinceLastSimulation,
      totalRouteDistanceArray
    } = this.state

    const distanceLeft =
      this.state.simulating && totalRouteDistanceArray
        ? totalRouteDistanceArray.reduce((acc, curr) => (acc += curr)) - simulatedDistance
        : this.props.route.route.distance + this.props.route.distanceToRoute

    const etaInMS = this.state.simulating
      ? (distanceLeft / (speedMetersPerSecond || 0)) * 1000
      : null
    // TO-DO: Format to I18n string
    const formattedETA =
      this.state.simulating && etaInMS
        ? etaInMS / 3600000 >= 24
          ? `${Math.round(etaInMS / 3600000 / 24)} dag${
              Math.round(etaInMS / 3600000 / 24) > 1 ? 'en' : ''
            }`
          : etaInMS / 3600000 >= 1
          ? `${Math.round(etaInMS / 3600000)} uur`
          : `${Math.round(etaInMS / 1000 / 60)} ${
              Math.round(etaInMS / 1000 / 60) === 1 ? 'minuut' : 'minuten'
            }`
        : 'N/A'

    // TO-DO: Format to I18n string
    return (
      <div className="route-simulator" id="route-simulator">
        <span>{I18n.t('routeSimulator.title')}</span>
        <div className="information">
          <div className="progress-ext">
            <div
              className="progress-bar"
              style={{ width: `${(ticksSinceLastSimulation / simulatingInterval) * 100}%` }}
            />
          </div>
          {this.state.simulating && (
            <React.Fragment>
              <div className="row">
                <span className="label">{I18n.t('routeSimulator.speed')}</span>
                <span className="value" id="route-simulator-speed">
                  {((speedMetersPerSecond || 0) * 3.6).toFixed(2)} km/h
                </span>
              </div>
              <div className="row">
                <span className="label">{I18n.t('routeSimulator.distanceLeft')}</span>
                <span className="value" id="route-simulator-distance-left">
                  {(distanceLeft / 1000).toFixed(2)} km
                </span>
              </div>
              <div className="row">
                <span className="label">{I18n.t('routeSimulator.eta')}</span>
                <span className="value" id="route-simulator-eta">
                  {formattedETA}
                </span>
              </div>
            </React.Fragment>
          )}
          <div className="row">
            <span className="label">{I18n.t('routeSimulator.interval')}</span>
            <span className="value" id="route-simulator-interval">
              {simulatingInterval / 1000}s
            </span>
          </div>
          <div className="row interval">
            <button
              className={`button interval-button${simulating ? ' disabled' : ''}`}
              onClick={() => this.changeSimulationInterval('increment')}
              disabled={simulating || false}
              id="route-simulator-increment-interval"
            >
              +
            </button>
            <button
              className={`button interval-button${simulating ? ' disabled' : ''}`}
              onClick={() => this.changeSimulationInterval('decrement')}
              onContextMenu={() => this.changeSimulationInterval('set', 5000)}
              disabled={simulating || false}
              id="route-simulator-decrement-interval"
            >
              -
            </button>
          </div>
        </div>
        <button
          className={`button ${simulating ? 'red' : 'green'}`}
          onClick={() => this.toggleSimulation()}
          id="route-simulator-toggle"
        >
          {simulating ? I18n.t('routeSimulator.pause') : I18n.t('routeSimulator.start')}
        </button>
      </div>
    )
  }

  public toggleSimulation = () => {
    if (!this.state.simulating) {
      const tickRate = 500

      this.simulatingInterval = setInterval(
        () => this.simulatePosition(),
        this.state.simulatingInterval
      )
      this.tickRate = setInterval(
        () =>
          this.setState({
            ticksSinceLastSimulation: this.state.ticksSinceLastSimulation + tickRate
          }),
        tickRate
      )

      this.initializeSimulation()
      this.setState({ simulating: true })
    } else {
      this.clearSimulation()
    }
  }

  public clearSimulation = () => {
    if (this.simulatingInterval) {
      clearInterval(this.simulatingInterval)
    }

    if (this.tickRate) {
      clearInterval(this.tickRate)
    }

    this.setState({
      simulating: false,

      closestPreviousIndexTime: null,
      closestPreviousIndex: null,

      simulationStartTime: null,
      simulatedPositionTime: null,
      simulatedPosition: null,
      speedMetersPerSecond: null,
      routeDistance: null
    })
  }

  public changeSimulationInterval = (type: 'increment' | 'decrement' | 'set', value?: number) => {
    const currentInterval = this.state.simulatingInterval
    let newInterval = currentInterval

    if (type === 'set' && value) {
      newInterval = value
    } else if (type === 'increment') {
      newInterval = currentInterval + 1000
    } else if (currentInterval > 5000) {
      newInterval = currentInterval - 1000
    }

    this.setState({ simulatingInterval: newInterval, ticksSinceLastSimulation: 0 })

    if (this.state.simulating) {
      if (this.simulatingInterval) {
        clearInterval(this.simulatingInterval)
      }

      this.simulatingInterval = setInterval(() => this.simulatePosition(), currentInterval - 1000)
    }
  }

  /**
   * Initializes the simulation
   *
   * Determines the closest starting position for the simulation
   * according to the latest valid current position
   */
  public initializeSimulation = (startAtRouteStart?: boolean) => {
    const { currentPosition, route } = this.props

    const polylinePositions = route?.route.polyLine ? polyUtil.decode(route.route.polyLine) : []
    const newSpeedMetersPerSecond = this.props.speedSetting / 1.944
    const location =
      currentPosition &&
      currentPosition.location &&
      currentPosition.location.latitude &&
      currentPosition.location.longitude
        ? { lat: currentPosition.location.latitude, lon: currentPosition.location.longitude }
        : { lat: polylinePositions[0][0], lon: polylinePositions[0][1] }

    const totalRouteDistanceArray: number[] = []

    if (!startAtRouteStart && currentPosition) {
      let closestIndex: { index: null | number; distance: null | number } = {
        index: null,
        distance: null
      }

      polylinePositions.forEach((position, i) => {
        const distance = distanceTo({ lat: position[0], lon: position[1] }, location)

        // Determine which index is closest in order to resume
        // simulation from that point.
        if (!closestIndex.distance || distance < (closestIndex.distance || 0)) {
          closestIndex = { index: i, distance }
        }

        // Add distance measurements to a total amount to show to the developer
        if (polylinePositions[i + 1]) {
          totalRouteDistanceArray.push(
            distanceTo(
              { lat: position[0], lon: position[1] },
              { lat: polylinePositions[i + 1][0], lon: polylinePositions[i + 1][1] }
            )
          )
        }
      })

      if (!closestIndex.index) {
        console.error(
          '[RouteSimulator] Could not find the closest index in the existing route, retrying with start of route'
        )
        this.initializeSimulation(true)
        return
      }

      const simulatedPositionTime = new Date().valueOf()
      const previousPoint = {
        lat: polylinePositions[closestIndex.index][0],
        lon: polylinePositions[closestIndex.index][1]
      }
      const nextPoint = polylinePositions[closestIndex.index + 1]
        ? {
            lat: polylinePositions[closestIndex.index + 1][0],
            lon: polylinePositions[closestIndex.index + 1][1]
          }
        : previousPoint

      const distanceToPreviousPoint = distanceTo(location, previousPoint)
      const courseOverGround = headingTo(previousPoint, nextPoint)

      const startPosition = {
        courseOverGround,
        timeLastUpdate: subSeconds(
          simulatedPositionTime,
          distanceToPreviousPoint / newSpeedMetersPerSecond
        ).valueOf(),
        location: {
          coordinates: [
            polylinePositions[closestIndex.index][1],
            polylinePositions[closestIndex.index][0]
          ] as [number, number],
          latitude: polylinePositions[closestIndex.index][0],
          longitude: polylinePositions[closestIndex.index][1],
          type: 'SIMULATED'
        }
      }

      const distanceLeft = totalRouteDistanceArray
        .slice(closestIndex.index, totalRouteDistanceArray.length)
        .reduce((acc, curr) => acc + curr)

      toast.success(
        <div>
          <div>{I18n.t('routeSimulator.simulationStarted')}</div>
          <div>{I18n.t('routeSimulator.startFromIndex', { index: closestIndex.index })}</div>
        </div>
      )

      console.info(`[RouteSimulator]
  > ROUTE SIMULATION STARTED
  > resumed from index ${closestIndex.index}

  > Total route info
  Total route length:         ${route?.route.distance} km
  Distance left:              ${(distanceLeft / 1000).toFixed(1)} km
  Simulation speed:           ${(this.props.speedSetting * 1.852).toFixed(2)} km/h
  Frontend ETA:               ${(distanceLeft / 1000 / (this.props.speedSetting * 1.852)).toFixed(
    1
  )} hours
  Backend ETA:                ${
    route?.route.eta ? formatIntoHoursAndMinutesFromNow(route?.route.eta) : 'N/A'
  }
      `)

      this.setState({
        speedMetersPerSecond: newSpeedMetersPerSecond,
        closestPreviousIndex: closestIndex.index,
        simulatedPositionTime,
        simulatedPosition: startPosition,
        ticksSinceLastSimulation: 0,
        totalRouteDistanceArray
      })
      this.handlePositionUpdate(startPosition)
    } else {
      // Start from initial route start
      const simulatedPositionTime = new Date().valueOf()
      const routeStart = { lat: polylinePositions[0][0], lon: polylinePositions[0][1] }
      const route2 = { lat: polylinePositions[1][0], lon: polylinePositions[1][1] }

      const distanceToPreviousPoint = distanceTo(location, routeStart)
      const courseOverGround = headingTo(routeStart, route2)

      polylinePositions.forEach((position, i) => {
        // Add distance measurements to a total amount to show to the developer
        if (polylinePositions[i + 1]) {
          totalRouteDistanceArray.push(
            distanceTo(
              { lat: position[0], lon: position[1] },
              { lat: polylinePositions[i + 1][0], lon: polylinePositions[i + 1][1] }
            )
          )
        }
      })

      const startPosition = {
        courseOverGround,
        timeLastUpdate: simulatedPositionTime,
        location: {
          coordinates: [polylinePositions[0][1], polylinePositions[0][0]] as [number, number],
          latitude: polylinePositions[0][0],
          longitude: polylinePositions[0][1],
          type: 'SIMULATED'
        }
      }

      toast.success(
        <div>
          <div>{I18n.t('routeSimulator.simulationStarted')}</div>
          <div>{I18n.t('routeSimulator.startOfRoute')}</div>
        </div>
      )

      console.info(`[RouteSimulator]
  > ROUTE SIMULATION STARTED
  > from the start of the route (index 0)

  Distance of route:          ${route?.route.distance} km
  Simulation speed:           ${(this.props.speedSetting * 1.852).toFixed(2)} km/h
  Frontend ETA:               ${(
    (route?.route.distance || 0) /
    (this.props.speedSetting * 1.852)
  ).toFixed(1)} hours
  Backend ETA:                ${
    route?.route.eta ? formatIntoHoursAndMinutesFromNow(route?.route.eta) : 'N/A'
  }
      `)

      this.setState({
        closestPreviousIndexTime: subSeconds(
          simulatedPositionTime,
          distanceToPreviousPoint / newSpeedMetersPerSecond
        ),
        closestPreviousIndex: 0,

        simulationStartTime: simulatedPositionTime,
        simulatedPositionTime,
        simulatedPosition: startPosition,

        speedMetersPerSecond: this.props.speedSetting / 1.944,
        ticksSinceLastSimulation: 0,

        totalRouteDistanceArray
      })

      this.handlePositionUpdate(startPosition)
    }
  }

  public simulatePosition = (overrideIndex?: number) => {
    const { route } = this.props
    const { speedMetersPerSecond, simulatedPositionTime, closestPreviousIndex, simulatedPosition } =
      this.state

    const polylinePositions = route?.route.polyLine ? polyUtil.decode(route.route.polyLine) : []

    const currentTime = new Date().valueOf()
    const simulationDurationSeconds = (currentTime - (simulatedPositionTime || currentTime)) / 1000
    const simulatedPositionLocation = {
      lat: simulatedPosition?.location.latitude,
      lon: simulatedPosition?.location.longitude
    }

    const i = overrideIndex || (closestPreviousIndex || 0) + 1

    if (
      polylinePositions[i] &&
      speedMetersPerSecond &&
      simulatedPositionTime &&
      simulatedPositionLocation.lat &&
      simulatedPositionLocation.lon
    ) {
      // Determine if the distance between points is greater than the distance travelled
      const pointsDistance = distanceTo(simulatedPositionLocation as Location, {
        lat: polylinePositions[i][0],
        lon: polylinePositions[i][1]
      })
      const distanceTravelledMeters = (speedMetersPerSecond || 0) * simulationDurationSeconds

      if (distanceTravelledMeters < pointsDistance) {
        // Travelled distance is smaller than distance between points
        // Interpolate position between existing indices
        const distanceToPreviousPoint = distanceTo(simulatedPositionLocation as Location, {
          lat: polylinePositions[i - 1][0],
          lon: polylinePositions[i - 1][1]
        })
        const previousPositionTime = subSeconds(
          simulatedPositionTime,
          distanceToPreviousPoint / speedMetersPerSecond
        ).valueOf()

        const prevPoint = {
          timeLastUpdate: previousPositionTime,
          location: {
            coordinates: polylinePositions[i - 1],
            latitude: polylinePositions[i - 1][0],
            longitude: polylinePositions[i - 1][1],
            type: 'SIMULATED'
          },
          courseOverGround: 0 // to-do: improve
        }

        const nextPoint = {
          timeLastUpdate: addSeconds(
            simulatedPositionTime,
            pointsDistance / speedMetersPerSecond
          ).valueOf(),
          location: {
            coordinates: polylinePositions[i],
            latitude: polylinePositions[i][0],
            longitude: polylinePositions[i][1],
            type: 'SIMULATED'
          },
          courseOverGround: 0 // to-do: improve
        }

        console.info(
          `[RouteSimulator] Travelled ${distanceTravelledMeters.toFixed(
            2
          )}m with ${speedMetersPerSecond.toFixed(
            2
          )} m/s in ${simulationDurationSeconds} s. ${Math.round(pointsDistance)}m left until ${i}`
        )

        const simPosition = this.interpolateDatapoint(prevPoint, nextPoint, currentTime)

        this.setState({
          closestPreviousIndex: i - 1,
          simulatedPosition: simPosition,
          simulatedPositionTime: currentTime,
          routeIndexLength: polylinePositions.length,
          simulatedDistance: this.state.simulatedDistance + distanceTravelledMeters,
          ticksSinceLastSimulation: 0
        })

        this.handlePositionUpdate(simPosition)
      } else {
        // Travelled farther than the distance until the next point
        // Check again with a point after that
        if (i === polylinePositions.length - 1) {
          this.clearSimulation()
          toast.info(<div>{I18n.t('routeSimulator.simulationFinished')}</div>)
          console.warn('[RouteSimulator] Simulation has been finished, the route has ended.')
        } else {
          console.info(
            `[RouteSimulator] (${i}/${
              polylinePositions.length - 1
            }) Distance until ${i}: ${pointsDistance.toFixed(2)}m`
          )
          this.simulatePosition(i + 1)
        }
      }
    } else if (speedMetersPerSecond === 0) {
      this.clearSimulation()
      toast.error(<div>{I18n.t('routeSimulator.speedError')}</div>)
      console.error('[RouteSimulator] Speed set is 0 m/s, cannot execute simulation.')
    } else {
      this.clearSimulation()
      toast.error(<div>{I18n.t('routeSimulator.polylineError')}</div>)
      console.error(
        '[RouteSimulator] No positions found inside the polyline, simulation completed.'
      )
    }
  }

  /**
   * Interpolates both position and time
   */
  public interpolateDatapoint(
    prevPoint: ISimulatedPosition,
    nextPoint: ISimulatedPosition,
    selectedTimestamp: number
  ): ISimulatedPosition {
    const prevPointMoment = prevPoint.timeLastUpdate
    const nextPointMoment = nextPoint.timeLastUpdate
    const courseOverGround = headingTo(prevPoint.location, nextPoint.location)

    if (prevPointMoment && nextPointMoment && prevPoint.location) {
      const duration = nextPointMoment - prevPointMoment
      const remaining = nextPointMoment - selectedTimestamp
      const progress = 1 - remaining / duration

      const diffLat =
        (nextPoint.location.latitude || nextPoint.location.coordinates[1]) -
        (prevPoint.location.latitude || prevPoint.location.coordinates[1])
      const diffLon =
        (nextPoint.location.longitude || nextPoint.location.coordinates[0]) -
        (prevPoint.location.longitude || prevPoint.location.coordinates[0])
      const newLat =
        (prevPoint.location.latitude || prevPoint.location.coordinates[1]) + progress * diffLat
      const newLon =
        (prevPoint.location.longitude || prevPoint.location.coordinates[0]) + progress * diffLon

      return {
        location: {
          coordinates: [newLon, newLat],
          latitude: newLat,
          longitude: newLon,
          type: 'SIMULATED'
        },
        timeLastUpdate: prevPointMoment + (duration - remaining),
        courseOverGround
      }
    } else {
      return {
        location: {
          coordinates: nextPoint.location.coordinates,
          latitude: nextPoint.location.latitude,
          longitude: nextPoint.location.longitude,
          type: 'SIMULATED'
        },
        timeLastUpdate: nextPoint.timeLastUpdate,
        courseOverGround
      }
    }
  }

  public handlePositionUpdate = (position: ISimulatedPosition) => {
    this.props.processPosition({
      ...this.props.currentPosition,
      ...position,
      posAccuracyMeters: '0',
      speedOverGround: (this.state.speedMetersPerSecond || 0) * 1.94384
    } as IShipInfo)
  }
}

export default RouteSimulator
