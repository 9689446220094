import { cloneDeep, flatMap, isEqual } from 'lodash'
import React, { useCallback, useEffect, useState } from 'react'
import { I18n } from 'react-redux-i18n'
import { toast } from 'react-toastify'
import { useSelector } from 'react-redux'
import { isValidDate } from '@teqplay/teqplay-ui'

import BICSBillingInfoModal from '../bicsBillingInfoModal/BICSBillingInfoModal'
import BICSConeSelection from '../bicsConeSelection/BICSConeSelection'
import BICSDestinationPicker from '../bicsDestinationPicker/BICSDestinationPicker'
import BICSGenericInputRow from '../bicsGenericInputRow/BICSGenericInputRow'
import BICSGoodsModal from '../bicsGoodsModal/BICSGoodsModal'
import BICSReportingPointPicker from '../bicsReportingPointPicker/BICSReportingPointPicker'
import BICSSelectHullsBlock from '../bicsSelectHullsBlock/BICSSelectHullsBlock'
import BICSTravelAddressBar from '../bicsTravelAddressBar/BICSTravelAddressBar'
import BICSTravelCargoRow from '../bicsTravelCargoRow/BICSTravelCargoRow'
import TeqplayApiService from '../../../services/TeqplayAPIService/TeqplayApiService'
import TitleComponent from '../../titleComponent/TitleComponent'
import MobileOrRegularDateTimePicker from '../../datetime/mobileDateTimePicker/MobileOrRegularDateTimePicker'
import CustomNumberInput from '../../customNumberInput/CustomNumberInput'

import {
  IBicsVoyage,
  ICustomError,
  IRouteBicsDestination,
  IRootProps,
  IRouteSearchProps
} from '../../../@types/types'
import { confirmPopup } from '../../shared/confirmPopup/ConfirmPopup'
import { useLocalStorage } from '../../../hooks/useLocalStorage'
import { usePrevious } from '../../../hooks/usePrevious'
import { useSessionStorage } from '../../../hooks/useSessionStorage'
import { useAnalytics } from '../../../services/AnalyticsWrapper/AnalyticsWrapper'
import {
  BicsAddress,
  BicsCargo,
  BicsDestination,
  BicsHull,
  BicsReportingPoint,
  BicsVoyage,
  BicsVoyageCargo,
  IShipInfo,
  BicsShipType,
  IRouteLocation,
  IRoute
} from '../../../services/TeqplayAPIService/TeqplayApi'
import { filterUniqueAddresses } from '../../../utils/bics'
import { sendExceptionToSentry, setExtraValueInSentry } from '../../../utils/sentry'
import { isCordovaApp } from '../../../utils/cordovaUtils'
import { filterUnique, sortImmutableByKey } from '../../../utils/general'

import './BICSTravelMain.scss'

interface IProps {
  mainHull: BicsHull | undefined
  hulls: BicsHull[] | null
  teqplayAPIService: TeqplayApiService
  voyages: IBicsVoyage[] | null
  currentVoyage: IBicsVoyage | undefined
  updateDraughtInProfile: (
    currentDraught: number,
    dimensions: IShipInfo['dimensions']
  ) => Promise<void>
  travelId: string
  setSelectedTravelID: (travelID: string | undefined) => void
  setSelectedHullID: (id: string) => void
  setFromLocation: (fromLocation: IRouteLocation | null) => void
  setToLocation: (toLocation: IRouteLocation | null) => void
  setViaRoutes: (viaRoutes: IRouteLocation[]) => void
  searchRouteSuggesions?: (
    overrideAbleRouteSelection?: IRouteSearchProps,
    overridableDepartureTime?: number
  ) => void
  setRouteSelection?: (route: IRouteSearchProps) => void
  BICSRoute?: IRoute | null
  setBICSRoute: (route: IRoute | null) => void
  resetAvailableRoutes?: () => void
}

const TravelScreen = ({
  mainHull,
  hulls,
  teqplayAPIService,
  voyages,
  currentVoyage,
  updateDraughtInProfile,
  travelId,
  setSelectedTravelID,
  setSelectedHullID,
  setFromLocation,
  setToLocation,
  setViaRoutes,
  searchRouteSuggesions,
  setRouteSelection,
  BICSRoute,
  setBICSRoute,
  resetAvailableRoutes
}: IProps) => {
  const analytics = useAnalytics('TravelScreen')
  const { userLocation, i18n } = useSelector((props: IRootProps) => props)
  const ship = userLocation?.currentLocation
  const [loading, setLoading] = useState<boolean>(false)
  const [errors, setErrors] = useState<ICustomError<keyof BicsVoyage | 'viaPoints'>[]>([])

  const [previousVoyage, setPreviousVoyage] = useState<IBicsVoyage>()
  const [copiedTravelID, setCopiedTravelID] = useState<string | undefined>()
  const [departure, setDeparture] = useSessionStorage<BicsDestination | undefined>(
    `BICS-trip-${travelId}-DEPARTURE`,
    undefined
  )
  const [viaPoints, setViaPoints] = useSessionStorage<(BicsDestination | undefined)[] | undefined>(
    `BICS-trip-${travelId}-VIAPOINTS`,
    undefined
  )
  const [arrival, setArrival] = useSessionStorage<BicsDestination | undefined>(
    `BICS-trip-${travelId}-ARRIVAL`,
    undefined
  )
  const [firstReportingPoint, setFirstReportingPoint] = useSessionStorage<
    BicsReportingPoint | undefined
  >(`BICS-trip-${travelId}-FIRST-CALL-POINT`, undefined)
  const [personsOnBoard, setPersonsOnBoard] = useSessionStorage<number | undefined | string>(
    `BICS-trip-${travelId}-PERSONS-ON-BOARD`,
    undefined
  )
  const [currentDraught, setCurrentDraught] = useSessionStorage<number | undefined | string>(
    `BICS-trip-${travelId}-CURRENT-DRAUGHT`,
    undefined
  )
  const [cones, setCones] = useSessionStorage<'0' | '1' | '2' | '3' | undefined>(
    `BICS-trip-${travelId}-CONES`,
    undefined
  )
  const [departureDateTime, setDepartureDateTime] = useSessionStorage<string | undefined>(
    `BICS-trip-${travelId}-DEPARTUREDATETIME`,
    undefined
  )
  const [arrivalDateTime, setArrivalDateTime] = useSessionStorage<string | undefined>(
    `BICS-trip-${travelId}-ARRIVALDATETIME`,
    undefined
  )
  const [cargo, setCargo] = useSessionStorage<BicsVoyageCargo[] | undefined>(
    `BICS-trip-${travelId}-CARGO`,
    []
  )
  const previousCargo = usePrevious(cargo)
  const [cargoObjects, setCargoObjects] = useState<BicsCargo[]>([])
  const [cargoLocationObjects, setCargoLocationObjects] = useState<BicsDestination[]>([])
  const [address, setAddress] = useSessionStorage<BicsAddress | undefined>(
    `BICS-trip-${travelId}-ADDRESS`,
    undefined
  )
  const [selectedHulls, setSelectedHulls] = useSessionStorage<string[] | undefined>(
    `BICS-trip-${travelId}-HULLS`,
    mainHull ? [mainHull.mmsi || mainHull.eni] : undefined
  )
  const [convoyType, setConvoyType] = useSessionStorage<BicsShipType | undefined>(
    `BICS-trip-${travelId}-convoyType`,
    undefined
  )

  const [previousAddresses, setPreviousAddresses] = useLocalStorage<BicsAddress[]>(
    `BICS-previousBillingInfo-${mainHull?.userId}`,
    []
  )

  const [lengthM, setLengthM] = useSessionStorage<number | undefined | string>(
    `BICS-trip-${travelId}-LENGTH-OVERRIDE`,
    undefined
  )
  const [widthM, setWidthM] = useSessionStorage<number | undefined | string>(
    `BICS-trip-${travelId}-WIDTH-OVERRIDE`,
    undefined
  )
  const [heightM, setHeightM] = useSessionStorage<number | undefined | string>(
    `BICS-trip-${travelId}-HEIGHT-OVERRIDE`,
    undefined
  )

  const [currentCargoEditIndex, setCurrentCargoEditIndex] = useState<number | 'new' | undefined>()
  const [showEditAddressModal, setShowAddressModal] = useState<boolean>(false)
  const [editMode, setEditMode] = useState<boolean>(false)
  // Initialize lockedMode for review mode for finished trips from the editMode variable
  const [lockedMode, setLockedMode] = useState<boolean>(false)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedCargoSyncFunction = useCallback(setCargoObjectsAndCargoLocationObjects, [
    teqplayAPIService
  ])

  useEffect(() => {
    analytics.setScreen('bics-travelscreen')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    initializeFields()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [travelId])

  useEffect(() => {
    if (!isEqual(cargo, previousCargo)) {
      memoizedCargoSyncFunction(cargo || [])
    }
  }, [cargo, memoizedCargoSyncFunction, previousCargo])

  useEffect(() => {
    updateMapWithRoute()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [arrival, departure, viaPoints])

  const allCargo = cargo?.map(c => ({
    cargoInfo: cargoObjects.find(co => co.vnNr === c.vnNr || co.hsCode === c.hsCode),
    onBoardInfo: c,
    fromLocation: cargoLocationObjects?.find(l => l.srsCode === c.fromLocation),
    toLocation: cargoLocationObjects?.find(l => l.srsCode === c.toLocation),
    hull: (hulls || []).find(
      h => c.shipId !== undefined && (h.eni === c.shipId || h.mmsi === c.shipId)
    )
  }))

  const hullsInVoyage = (selectedHulls || []).map(
    h => hulls?.find(c => h === c.eni || h === c.mmsi) || ({ eni: h } as BicsHull)
  )
  // BE allows maximum 5 via points
  const disableAddingViaPoints = viaPoints && viaPoints.length > 4

  const hasUnsavedChanges = checkForUnsavedChanges()
  return (
    <div className="bics-travel-main">
      <TitleComponent
        title={`${I18n.t(
          editMode
            ? 'announcements.travel.view'
            : travelId === 'new'
            ? 'announcements.travel.new'
            : 'announcements.travel.edit'
        )}${
          departure && arrival
            ? ` · ${
                departure?.term || departure?.termLocal || departure?.name || departure?.nameLocal
              } → ${arrival?.term || arrival?.termLocal || arrival?.name || arrival?.nameLocal}`
            : ''
        }`}
      />
      <button className="button back-button" type="button" onClick={handleOnCancelClick}>
        <i className="icon-left" />
        <span>{I18n.t('announcements.goBack')}</span>
      </button>
      <h1>
        {I18n.t(
          editMode
            ? 'announcements.travel.view'
            : travelId === 'new'
            ? 'announcements.travel.new'
            : 'announcements.travel.edit'
        )}
      </h1>
      <div className={`title ${travelId !== 'new' ? 'full-width' : ''}`}>
        {travelId !== 'new' ? (
          <div className="right buttons">
            <button
              className="button danger delete-button"
              type="button"
              onClick={e => {
                e.preventDefault()
                analytics.newEvent('bics_click_button_delete', { travelId })
                deleteTravel()
              }}
            >
              <i className="icon-cancel-1" />
              {I18n.t('announcements.travel.delete')}
            </button>
            <button
              className="button primary delete-button"
              type="button"
              onClick={() => {
                resetState()
                analytics.newEvent('bics_click_button_use_as_template', { travelId })
                createTemplate()
              }}
            >
              <i className="icon-docs" />
              {I18n.t('announcements.travel.useAsTemplate')}
            </button>
          </div>
        ) : (
          <div className="right buttons">
            <button
              className="button danger delete-button"
              type="button"
              onClick={e => {
                e.preventDefault()
                analytics.newEvent('bics_click_button_reset_fields', { travelId })
                resetState(true)
              }}
            >
              {I18n.t('announcements.travel.resetState')}
            </button>
          </div>
        )}
      </div>

      {currentCargoEditIndex !== undefined && travelId ? (
        <BICSGoodsModal
          value={
            currentCargoEditIndex !== undefined && currentCargoEditIndex >= 0 && cargo
              ? cargo[currentCargoEditIndex]
              : null
          }
          teqplayAPIService={teqplayAPIService}
          setValue={c => handleSetCargoInIndex(c, currentCargoEditIndex)}
          showModal={currentCargoEditIndex !== undefined}
          closeModal={() => handleShowCargoEditDialog(undefined)}
          travelId={travelId}
          cargoIndex={currentCargoEditIndex}
          disabled={editMode}
          routeLocations={
            [
              departure ? { ...departure, routeIndex: 'departure' } : undefined,
              ...(
                viaPoints?.map((vp, i) => ({ ...vp, routeIndex: 'viaPoint', viaIndex: i + 1 })) ||
                []
              ).filter(vp => vp.srsCode),
              arrival ? { ...arrival, routeIndex: 'arrival' } : undefined
            ].filter(l => l) as IRouteBicsDestination[]
          }
          mainHull={mainHull}
          hullsInVoyage={hullsInVoyage}
          otherCargo={
            currentCargoEditIndex !== undefined && currentCargoEditIndex >= 0 && cargo
              ? cargo.filter((cx, i) => i !== currentCargoEditIndex)
              : cargo || []
          }
        />
      ) : null}

      <BICSBillingInfoModal
        showModal={showEditAddressModal}
        onSubmit={a => {
          setAddress(a)
          setPreviousAddresses(
            filterUniqueAddresses(
              undefined,
              [...previousAddresses, a, mainHull?.ownerAddress].filter(ad => ad) as BicsAddress[]
            )
          )
        }}
        onClose={() => setShowAddressModal(false)}
      />

      {travelId !== 'new' && lockedMode && (
        <div className="warning-message locked">{I18n.t('announcements.travel.locked')}</div>
      )}
      {travelId !== 'new' && editMode && !lockedMode && (
        <div className="warning-message partial">{I18n.t('announcements.travel.partialEdit')}</div>
      )}

      <form className="travel-form" onSubmit={handleSubmitTravel}>
        <h3 className="block-preheader top">{I18n.t('announcements.travel.tripInfo')}</h3>
        <div className="page-block">
          <BICSGenericInputRow<keyof BicsVoyage | 'viaPoints'>
            keyName="fromLocation"
            isRequired={false}
            errors={errors}
          >
            <BICSDestinationPicker
              value={departure}
              initialDestinationIdCode={previousVoyage?.fromLocation}
              onChange={v => {
                setDeparture(v)
                setFromLocation(
                  updateMapLocation(
                    v,
                    previousVoyage?.fromLocation
                      ? previousVoyage?.fromLocation !== v?.srsCode
                      : departure?.srsCode !== v?.srsCode
                  )
                )
              }}
              teqplayAPIService={teqplayAPIService}
              isClearable={true}
              disabled={editMode}
              travelId={travelId}
              type={'departure'}
            />
          </BICSGenericInputRow>
          <BICSGenericInputRow<keyof BicsVoyage | 'viaPoints'>
            keyName="startTime"
            isRequired={false}
            errors={errors}
            extraSubtitle={!isCordovaApp ? I18n.t('datetime.fullFormat') : null}
          >
            <MobileOrRegularDateTimePicker
              value={departureDateTime || undefined}
              locale={'nl'}
              dateSeperator="-"
              onDateTimeChange={(iso, inputValue, isValid) =>
                setDepartureDateTime(isValid && iso ? iso : inputValue)
              }
              icon={<i className="icon-calendar" />}
              disabled={editMode}
              id={'departure-time'}
            />
          </BICSGenericInputRow>
          {viaPoints?.map((point, i) => (
            <BICSGenericInputRow<keyof BicsVoyage | 'viaPoints'>
              key={i}
              keyName="viaPoints"
              labelOverride={<div />}
              isRequired={false}
              errors={errors}
              childrenWrapperClassName={'via-wrapper'}
            >
              <BICSDestinationPicker
                value={point}
                onChange={v => handleSetViaPointInArray(v, i)}
                viaIndex={i + 1}
                teqplayAPIService={teqplayAPIService}
                disabled={editMode}
                travelId={travelId}
                type={'via'}
              />
              {!editMode && (
                <button
                  type="button"
                  className="button secondary remove-via-route-button"
                  onClick={() => {
                    handleSetViaPointInArray(null, i)
                  }}
                  id={`button-remove-via-button-${i}`}
                >
                  <i className="icon-minus-circled" />
                  <span className="delete-message">{I18n.t('delete')}</span>
                </button>
              )}
            </BICSGenericInputRow>
          ))}
          {disableAddingViaPoints && (
            <div className="notice">{I18n.t('destinationSelect.warningMax')}</div>
          )}
          {!editMode && (
            <div className="route-control-buttons">
              <button
                className="button"
                id="button-exchange"
                type="button"
                onClick={e => {
                  e.preventDefault()
                  const newDeparture = cloneDeep(arrival)
                  setArrival(departure)
                  setViaPoints(viaPoints ? [...viaPoints].reverse() : undefined)
                  setDeparture(newDeparture)
                  // For the map
                  setToLocation(updateMapLocation(departure))
                  setFromLocation(updateMapLocation(newDeparture))
                  setBICSRoute(null)
                }}
              >
                <i className="icon-exchange" />
                <span>{I18n.t('announcements.travel.exchange')}</span>
              </button>
              <button
                className="button"
                id="via-button"
                type="button"
                onClick={e => {
                  e.preventDefault()
                  setViaPoints([...(viaPoints || []), undefined])
                }}
                disabled={disableAddingViaPoints}
              >
                <i className="icon-plus" />
                <span>{I18n.t('announcements.travel.viaPoint')}</span>
              </button>
            </div>
          )}
          <BICSGenericInputRow<keyof BicsVoyage | 'viaPoints'>
            keyName="toLocation"
            isRequired={false}
            errors={errors}
          >
            <BICSDestinationPicker
              value={arrival}
              initialDestinationIdCode={previousVoyage?.toLocation}
              onChange={v => {
                setArrival(v)
                setToLocation(
                  updateMapLocation(
                    v,
                    previousVoyage?.toLocation
                      ? previousVoyage?.toLocation !== v?.srsCode
                      : arrival?.srsCode !== v?.srsCode
                  )
                )
              }}
              teqplayAPIService={teqplayAPIService}
              isClearable={true}
              disabled={editMode}
              travelId={travelId}
              type={'arrival'}
            />
          </BICSGenericInputRow>
          <BICSGenericInputRow<keyof BicsVoyage | 'viaPoints'>
            keyName="endTime"
            isRequired={false}
            errors={errors}
            extraSubtitle={!isCordovaApp ? I18n.t('datetime.fullFormat') : null}
          >
            <MobileOrRegularDateTimePicker
              value={arrivalDateTime || undefined}
              locale={'nl'}
              dateSeperator="-"
              onDateTimeChange={(iso, inputValue, isValid) =>
                setArrivalDateTime(isValid && iso ? iso : inputValue)
              }
              icon={<i className="icon-calendar" />}
              disabled={editMode}
              id={'arrival-time'}
            />
          </BICSGenericInputRow>
          <BICSGenericInputRow<keyof BicsVoyage | 'viaPoints'>
            keyName="firstReportingPoint"
            isRequired={false}
            errors={errors}
          >
            <BICSReportingPointPicker
              value={firstReportingPoint}
              departureIVSCode={departure?.ivsCode}
              voyageProviderId={previousVoyage?.firstReportingPoint}
              onChange={v => setFirstReportingPoint(v)}
              teqplayAPIService={teqplayAPIService}
              isClearable={true}
              disabled={editMode}
              travelId={travelId}
            />
          </BICSGenericInputRow>
          {!editMode && searchRouteSuggesions && (
            <BICSGenericInputRow<keyof BicsVoyage | 'viaPoints'>
              keyName="riverguideSelectedRoute"
              errors={errors}
              extraSubtitle={
                !(arrival && departure) ? I18n.t('announcements.error.requiredRoute') : ''
              }
            >
              {BICSRoute ? (
                <>
                  <div className="selected-route">
                    <i className="icon-check" /> {I18n.t('announcements.validRoute')}
                  </div>
                  <button
                    type="button"
                    className="button primary"
                    onClick={() => searchRouteSuggesions()}
                    id="button-add-route"
                  >
                    <i className="icon-edit" /> {I18n.t('announcements.editRoute')}
                  </button>
                </>
              ) : (
                <button
                  type="button"
                  className="button primary"
                  onClick={() => searchRouteSuggesions()}
                  id="button-add-route"
                  title={!(arrival && departure) ? I18n.t('announcements.error.requiredRoute') : ''}
                  disabled={arrival && departure ? false : true}
                >
                  <i className="icon-plus" /> {I18n.t('announcements.addRoute')}
                </button>
              )}
            </BICSGenericInputRow>
          )}

          <BICSGenericInputRow<keyof BicsVoyage | 'viaPoints'>
            keyName="personsOnBoard"
            isRequired={false}
            errors={errors}
          >
            <CustomNumberInput
              name="personsOnBoard"
              value={personsOnBoard}
              setValue={val => setPersonsOnBoard(val)}
              className="textfield"
              disabled={lockedMode}
              inputMode="numeric"
            />
          </BICSGenericInputRow>
          <BICSGenericInputRow<keyof BicsVoyage | 'viaPoints'>
            keyName="currentDraught"
            isRequired={false}
            errors={errors}
          >
            <div className="with-suffix">
              <CustomNumberInput
                name="currentDraught"
                value={currentDraught}
                setValue={val => setCurrentDraught(val)}
                className="textfield"
                disabled={lockedMode}
              />
              <span className="suffix">{I18n.t('announcements.fields.meter')}</span>
            </div>
          </BICSGenericInputRow>
          <BICSGenericInputRow<keyof BicsVoyage | 'viaPoints'>
            keyName="cones"
            isRequired={false}
            errors={errors}
          >
            <BICSConeSelection cones={cones} setCones={setCones} disabled={lockedMode} />
          </BICSGenericInputRow>
        </div>

        <BICSSelectHullsBlock
          allHulls={hulls}
          mainHull={mainHull}
          hulls={selectedHulls}
          setHulls={setSelectedHulls}
          onClickHull={setSelectedHullID}
          initialConvoyType={previousVoyage?.convoyType}
          convoyType={convoyType || null}
          setConvoyType={ct => setConvoyType(ct || undefined)}
          errors={errors}
          disabled={editMode}
          teqplayApiService={teqplayAPIService}
        />

        {(selectedHulls || [])?.length >= 2 && (
          <div className="goods-block dimensions">
            <div className="preheader-with-buttons">
              <div className="billing-title combined-dimensions">
                <h3>{I18n.t('announcements.travel.combinedDimensions')}</h3>
                <p>{I18n.t('announcements.travel.combinedDimensionsExplain')}</p>
              </div>
              {!lockedMode && !editMode && (
                <div className="buttons">
                  <button
                    className="button primary apply-largest-dimensions"
                    type="button"
                    onClick={() => handleShowOverrideDimensionsWithLargest('length')}
                  >
                    <i className="icon-resize-vertical" />
                    <span>{I18n.t('announcements.travel.overrideWithLargestLength')}</span>
                  </button>
                  <button
                    className="button primary apply-largest-dimensions"
                    type="button"
                    onClick={() => handleShowOverrideDimensionsWithLargest('width')}
                  >
                    <i className="icon-resize-horizontal" />
                    <span>{I18n.t('announcements.travel.overrideWithLargestWidth')}</span>
                  </button>
                </div>
              )}
            </div>
            <div className="page-block">
              <BICSGenericInputRow<keyof BicsVoyage | 'viaPoints'>
                keyName="lengthM"
                isRequired={false}
                errors={errors}
              >
                <div className="with-suffix">
                  <CustomNumberInput
                    name="lengthM"
                    value={lengthM}
                    setValue={val => setLengthM(val)}
                    className="textfield"
                    disabled={lockedMode}
                  />
                  <span className="suffix">{I18n.t('announcements.fields.meter')}</span>
                </div>
              </BICSGenericInputRow>
              <BICSGenericInputRow<keyof BicsVoyage | 'viaPoints'>
                keyName="widthM"
                isRequired={false}
                errors={errors}
              >
                <div className="with-suffix">
                  <CustomNumberInput
                    name="widthM"
                    value={widthM}
                    setValue={val => setWidthM(val)}
                    className="textfield"
                    disabled={lockedMode}
                  />
                  <span className="suffix">{I18n.t('announcements.fields.meter')}</span>
                </div>
              </BICSGenericInputRow>
              <BICSGenericInputRow<keyof BicsVoyage | 'viaPoints'>
                keyName="heightM"
                isRequired={false}
                errors={errors}
              >
                <div className="with-suffix">
                  <CustomNumberInput
                    name="heightM"
                    value={heightM}
                    setValue={val => setHeightM(val)}
                    className="textfield"
                    disabled={lockedMode}
                  />
                  <span className="suffix">{I18n.t('announcements.fields.meter')}</span>
                </div>
              </BICSGenericInputRow>
            </div>
          </div>
        )}

        <div className="goods-block">
          <div className="preheader-with-buttons goods">
            <h3 className="block-preheader">{I18n.t('announcements.travel.goodsOnBoard')}</h3>
            {!lockedMode && !editMode && (
              <button
                className="button primary"
                type="button"
                onClick={() => handleShowCargoEditDialog('new')}
              >
                <i className="icon-plus" />
                <span>{I18n.t('announcements.travel.addGoods')}</span>
              </button>
            )}
          </div>
          <div className="goods-list">
            {allCargo?.map((c, i) => (
              <BICSTravelCargoRow
                key={`BICS-TRAVEL-CARGO-ROW-${i}`}
                cargoInfo={c.cargoInfo}
                fromLocation={c.fromLocation}
                toLocation={c.toLocation}
                onBoardInfo={c.onBoardInfo}
                index={i}
                handleShowCargoEditDialog={handleShowCargoEditDialog}
                hasMultipleHulls={(selectedHulls || [])?.length > 1}
                hullsInVoyage={hullsInVoyage}
                relatedHull={c.hull}
              />
            ))}
            {(cargo || []).length === 0 && (
              <div className="page-block goods no-goods">
                {I18n.t('announcements.travel.noGoodsAdded')}
              </div>
            )}
            {errors
              .filter(e => e.key === 'cargo')
              .map(err => (
                <div className="error-message cargo-error" key={err.error}>
                  {err.error}
                </div>
              ))}
          </div>
        </div>

        <BICSTravelAddressBar
          address={address}
          setAddress={setAddress}
          setShowAddressModal={setShowAddressModal}
          disabled={lockedMode}
          previousAddresses={previousAddresses}
          setPreviousAddresses={setPreviousAddresses}
          mainHull={mainHull}
        />

        {errors.length > 0 ? (
          <div className="error-hint">
            <div>{I18n.t('announcements.error.present')}</div>
            <div className="key-list">
              {errors
                .map(e => e.key)
                .filter(filterUnique)
                .map(key => I18n.t(`announcements.fields.${key}`))
                .join(', ')}
            </div>
          </div>
        ) : null}

        {!lockedMode && (
          <div className="bottom-buttons">
            <button
              className="button secondary cancel"
              type="button"
              onClick={e => {
                e.preventDefault()
                handleOnCancelClick()
              }}
            >
              {I18n.t('cancel')}
            </button>
            <button className="button primary save" type="submit">
              {!loading ? (
                I18n.t('save')
              ) : (
                <>
                  <i className="animate-spin icon-circle-notch" />
                  <span>{I18n.t('processing')}</span>
                </>
              )}
            </button>
            <button
              className="button primary save"
              type="button"
              onClick={e => {
                e.preventDefault()
                startNavigation()
              }}
            >
              {I18n.t('announcements.travel.announce')}
            </button>
          </div>
        )}
      </form>
    </div>
  )

  function startNavigation() {
    if (voyages?.find(v => v.status === 'SENT')) {
      confirmPopup({
        cancelText: I18n.t('no'),
        confirmText: I18n.t('yes'),
        closeOnOutsideClick: false,
        onConfirm: () => {
          updateAndSubmit()
        },
        message: I18n.t('announcements.travel.sentVoyageConfirmation')
      })
    } else {
      updateAndSubmit()
    }
  }

  function handleShowCargoEditDialog(index: number | 'new' | undefined) {
    setCurrentCargoEditIndex(index)
  }

  function handleSetViaPointInArray(point: BicsDestination | null, index: number) {
    const newViaPoints = [...(viaPoints || [])]

    if (!point) {
      // Clear/splice array at index
      newViaPoints.splice(index, 1)
    } else {
      // Insert value at index
      newViaPoints.splice(index, 1, point)
    }

    setViaPoints(newViaPoints)
    // Display the via points on the map
    if (newViaPoints) {
      const viaRouteSpecific = newViaPoints
        .filter(t => t)
        .map(t => updateMapLocation(t)) as IRouteLocation[]
      setViaRoutes(viaRouteSpecific)
      // This will reset the BICS route if any new gets added or removed
      if (!point) {
        setBICSRoute(null)
      } else if (!viaPoints?.find(p => p?.srsCode === point.srsCode)) {
        setBICSRoute(null)
      }
    }
  }

  function handleSetCargoInIndex(
    cargoItem: BicsVoyageCargo | null,
    index: number | 'new' | undefined
  ) {
    const temp = [...(cargo || [])]

    if (index === undefined) {
      toast.error('Error inserting in cargo array, no index')
    } else if (index === 'new') {
      if (cargoItem) {
        temp.push(cargoItem)
        setCargo(temp)
      } else {
        // No cargoItem, and its new - thus no action
      }
    } else {
      if (!cargoItem) {
        // Clear/splice array at index
        temp.splice(index, 1)
      } else {
        // Insert value at index
        temp.splice(index, 1, cargoItem)
      }

      setCargo(temp)
    }
  }

  function resetState(skipTravelIDReset?: boolean) {
    setDeparture(undefined)
    setViaPoints(undefined)
    setArrival(undefined)
    setDepartureDateTime(undefined)
    setArrivalDateTime(undefined)
    setPersonsOnBoard(undefined)
    setCargo(undefined)
    setAddress(undefined)
    setCones(undefined)
    setFirstReportingPoint(undefined)
    setCurrentDraught(undefined)
    setSelectedHulls(undefined)
    setConvoyType(undefined)
    if (!skipTravelIDReset) {
      setSelectedTravelID(undefined)
    }
    if (setRouteSelection) {
      setRouteSelection({ fromLocation: null, toLocation: null, viaRoutes: [] })
    }
    if (resetAvailableRoutes) {
      resetAvailableRoutes()
      setBICSRoute(null)
    }
  }

  function validate() {
    const validationErrors: ICustomError<keyof BicsVoyage | 'viaPoints'>[] = []

    if (!departure) {
      validationErrors.push({
        key: 'fromLocation',
        error: I18n.t('announcements.error.requiredEmpty')
      })
    }
    if ((viaPoints || []).filter(x => x === undefined).length > 0) {
      validationErrors.push({
        key: 'viaPoints',
        error: I18n.t('announcements.error.requiredEmpty')
      })
    }
    if (!arrival) {
      validationErrors.push({
        key: 'toLocation',
        error: I18n.t('announcements.error.requiredEmpty')
      })
    }
    if (!firstReportingPoint) {
      validationErrors.push({
        key: 'firstReportingPoint',
        error: I18n.t('announcements.error.requiredEmpty')
      })
    }
    if (!departureDateTime) {
      validationErrors.push({
        key: 'startTime',
        error: I18n.t('announcements.error.requiredDateTimeEmpty')
      })
    }
    if (departureDateTime && !isValidDate(departureDateTime)) {
      validationErrors.push({
        key: 'startTime',
        error: I18n.t('announcements.error.invalidDate')
      })
    }
    if (!arrivalDateTime) {
      validationErrors.push({
        key: 'endTime',
        error: I18n.t('announcements.error.requiredDateTimeEmpty')
      })
    }
    if (arrivalDateTime && !isValidDate(arrivalDateTime)) {
      validationErrors.push({
        key: 'endTime',
        error: I18n.t('announcements.error.invalidDate')
      })
    }
    if (!BICSRoute) {
      validationErrors.push({
        key: 'riverguideSelectedRoute',
        error: I18n.t('announcements.error.requiredEmpty')
      })
    }

    if (!personsOnBoard) {
      validationErrors.push({
        key: 'personsOnBoard',
        error: I18n.t('announcements.error.requiredEmpty')
      })
    } else if (personsOnBoard >= 10000) {
      validationErrors.push({
        key: 'personsOnBoard',
        error: I18n.t('announcements.error.maxAmount', { amount: personsOnBoard, maxAmount: 10000 })
      })
    } else if (isNaN(personsOnBoard as number)) {
      // Assertion to pass value as a number, check will go true if a string is passed
      validationErrors.push({
        key: 'personsOnBoard',
        error: I18n.t('announcements.error.invalidNumberValue')
      })
    } else if ((personsOnBoard as number) % 1 > 0) {
      validationErrors.push({
        key: 'personsOnBoard',
        error: I18n.t('announcements.error.cantBeFloat')
      })
    }

    if (!cones) {
      validationErrors.push({
        key: 'cones',
        error: I18n.t('announcements.error.requiredEmpty')
      })
    }

    const totalCargoWeight =
      cargo && cargo.length >= 1
        ? (cargo || []).map(c => c.weightTn).reduce((a, b) => (a || 0) + (b || 0)) || 0
        : 0
    const totalHullCargoCapacity =
      hullsInVoyage.length >= 1
        ? (hullsInVoyage || []).map(h => h.cargoCapacityTn).reduce((a, b) => (a || 0) + (b || 0))
        : mainHull?.cargoCapacityTn || 0

    if (totalCargoWeight > totalHullCargoCapacity) {
      validationErrors.push({
        key: 'cargo',
        error: I18n.t('announcements.error.exceededHullCargoCapacity', {
          totalCargoWeight,
          cargoCapacityTn: mainHull?.cargoCapacityTn || I18n.t('unknownValue')
        })
      })
    }

    if (!currentDraught || currentDraught === 0) {
      validationErrors.push({
        key: 'currentDraught',
        error: I18n.t('announcements.error.requiredEmptyNonZero')
      })
    } else if (currentDraught && currentDraught > 99.99) {
      validationErrors.push({
        key: 'currentDraught',
        error: I18n.t('announcements.error.exceededMaxDraught')
      })
    } else if (currentDraught && isNaN(currentDraught as number)) {
      // Assertion to pass value as a number, check will go true if a string is passed
      validationErrors.push({
        key: 'currentDraught',
        error: I18n.t('announcements.error.invalidNumberValue')
      })
    }

    if (selectedHulls?.length === 0) {
      validationErrors.push({
        key: 'hulls',
        error: I18n.t('announcements.error.noHullSelected')
      })
    }

    if (selectedHulls && selectedHulls?.length > 1 && !convoyType) {
      validationErrors.push({
        key: 'convoyType',
        error: I18n.t('announcements.error.noConvoyTypeSelected')
      })
    }

    const hasUnboundCargoItems = (cargo || [])
      ?.map(c => c.shipId)
      .map(shipId => !selectedHulls?.find(s => s === shipId))
      .filter(v => v)

    if (hasUnboundCargoItems?.length > 0) {
      validationErrors.push({
        key: 'cargo',
        error: I18n.t('announcements.error.unboundCargo')
      })
    }

    if ((selectedHulls || [])?.length >= 2 && (lengthM || widthM || heightM)) {
      if (!lengthM) {
        validationErrors.push({
          key: 'lengthM',
          error: I18n.t('announcements.error.requiredEmpty')
        })
      }
      if (lengthM && isNaN(lengthM as number)) {
        // Assertion to pass value as a number, check will go true if a string is passed
        validationErrors.push({
          key: 'lengthM',
          error: I18n.t('announcements.error.invalidNumberValue')
        })
      }

      if (!widthM) {
        validationErrors.push({
          key: 'widthM',
          error: I18n.t('announcements.error.requiredEmpty')
        })
      }
      if (widthM && isNaN(widthM as number)) {
        // Assertion to pass value as a number, check will go true if a string is passed
        validationErrors.push({
          key: 'widthM',
          error: I18n.t('announcements.error.invalidNumberValue')
        })
      }

      if (!heightM) {
        validationErrors.push({
          key: 'heightM',
          error: I18n.t('announcements.error.requiredEmpty')
        })
      }
      if (heightM && isNaN(heightM as number)) {
        // Assertion to pass value as a number, check will go true if a string is passed
        validationErrors.push({
          key: 'heightM',
          error: I18n.t('announcements.error.invalidNumberValue')
        })
      }
    }

    return validationErrors
  }

  async function handleSubmitTravel(
    e: React.FormEvent<HTMLFormElement> | undefined,
    allowRedirectUponSuccess: boolean = true,
    startAfterCreateUpdate?: boolean
  ) {
    setLoading(true)
    e?.preventDefault()

    const validationErrors = validate()

    if (validationErrors.length > 0) {
      setErrors(validationErrors)
      setLoading(false)
      console.warn(
        'handleSubmitTravel errors\n\n',
        validationErrors.map(err => `${err.key}: ${err.error}`).join('\n')
      )
      return
    }

    try {
      const travel: BicsVoyage = {
        fromLocation: departure?.srsCode || '',
        viaPoints: (viaPoints || [])?.map(p => ({
          location: p?.srsCode || ''
        })),
        toLocation: arrival?.srsCode || '',
        firstReportingPoint: firstReportingPoint?.verkeerspostId || '',
        cargo: cargo || [],
        startTime: departureDateTime || '',
        endTime: arrivalDateTime || '',
        cones: cones || undefined,
        personsOnBoard: personsOnBoard && personsOnBoard >= 0 ? (personsOnBoard as number) : -1,
        travelId: travelId === 'new' ? undefined : parseInt(travelId, 10),
        currentDraught: currentDraught as number,
        ownerAddress: address,
        hulls: (selectedHulls || [])?.filter(h => h) as string[],
        convoyType: convoyType?.id,
        widthM:
          (selectedHulls || [])?.length >= 2
            ? (widthM as number | undefined) || undefined
            : undefined,
        lengthM:
          (selectedHulls || [])?.length >= 2
            ? (lengthM as number | undefined) || undefined
            : undefined,
        heightM:
          (selectedHulls || [])?.length >= 2
            ? (heightM as number | undefined) || undefined
            : undefined,
        riverguideSelectedRoute: BICSRoute
      }

      // Setting the travel inside sentry so that in case something errors we can see the values
      setExtraValueInSentry('travel', {
        ...travel,
        ownerAddress: address !== null ? 'REDACTED' : null
      })

      if (travelId === 'new') {
        analytics.newEvent('bics_create_new_voyage', {})
        const createdObject = await teqplayAPIService.createBICSTravel(travel)
        analytics.newEvent('bics_confirmed_voyage_created', { travelId: createdObject.travelId })
        if (startAfterCreateUpdate) {
          await startTravelInBICS(createdObject.travelId)
        }
      } else {
        analytics.newEvent('bics_edit_voyage', {})
        const returnedObject = await teqplayAPIService.updateBICSTravel(travel)
        analytics.newEvent('bics_confirmed_voyage_edited', { travelId: returnedObject.travelId })
        if (startAfterCreateUpdate) {
          await startTravelInBICS(returnedObject.travelId)
        }
      }
      resetState()
      setLoading(false)
    } catch (error) {
      console.error(error)
      toast.error(I18n.t('generalError'))
      sendExceptionToSentry(
        undefined,
        { message: `[BICS] ${travelId === 'new' ? 'Creating' : 'Updating'} travel failed` },
        {
          error,
          travelId,
          action: travelId === 'new' ? 'create' : 'update'
        }
      )
      throw new Error(error)
    } finally {
      setLoading(false)
      // Clear the travel when it has either errored or succeeded
      setExtraValueInSentry('travel', undefined)
    }
  }

  async function updateAndSubmit(): Promise<void> {
    setLoading(true)

    const validationErrors = validate()

    if (validationErrors.length > 0) {
      setErrors(validationErrors)
      setLoading(false)
      return
    }

    if (
      // Do not proceed if a currentVoyage exists and it is not equal to the travelId
      currentVoyage &&
      (travelId === 'new' || parseInt(travelId, 10) !== currentVoyage.travelId)
    ) {
      toast.error(I18n.t('announcements.error.hasActiveVoyageNoStart'))
      setLoading(false)
      return
    }

    try {
      await handleSubmitTravel(undefined, false, true)
      resetState()
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  async function deleteTravel() {
    confirmPopup({
      cancelText: I18n.t('no'),
      confirmText: I18n.t('yes'),
      closeOnOutsideClick: true,
      onConfirm: async () => {
        try {
          analytics.newEvent('bics_delete_voyage', {})
          await teqplayAPIService.deleteBICSTravel(travelId)
          resetState()
        } catch (error) {
          console.error(error)
          sendExceptionToSentry(new Error('[BICS] Deleting travel failed'), undefined, {
            error,
            travelId,
            action: 'delete'
          })
          toast.error(I18n.t('generalError'))
        }
      },
      message: I18n.t('announcements.travel.deleteConfirmation')
    })
  }

  async function startTravelInBICS(tid: number | undefined) {
    if (loading) {
      toast.error(I18n.t('announcements.error.awaitingAction'))
      return
    }

    if (!tid) {
      toast.error(I18n.t('announcements.error.noTravelId'))
      return
    }

    setLoading(true)

    try {
      analytics.newEvent('bics_start_voyage_from_travelscreen', {})
      await teqplayAPIService.startBICSTravel(tid)
      if (currentDraught && !isNaN(currentDraught as number) && ship?.dimensions) {
        await updateDraughtInProfile(currentDraught as number, ship.dimensions)
      }
      toast.success(I18n.t('announcements.travel.started'))
    } catch (error) {
      console.error(error)
      toast.error(I18n.t('generalError'))
      sendExceptionToSentry(
        undefined,
        { message: '[BICS] Starting travel failed' },
        {
          error,
          travelId,
          action: 'start'
        }
      )
    } finally {
      setLoading(false)
    }
  }

  async function setCargoObjectsAndCargoLocationObjects(
    cargoList: BicsVoyageCargo[]
  ): Promise<void> {
    if (cargoList.length === 0) {
      setCargoObjects([])
      setCargoLocationObjects([])
      return
    }

    try {
      const locationIdentifiers = flatMap(
        cargoList.map(c => [c.fromLocation || '', c.toLocation || ''])
      ).filter(s => s)
      const cargoIdentifiers = cargoList.map(c => c.vnNr || c.hsCode || '').filter(s => s)

      const locationObjectList = await teqplayAPIService.retrieveBICSDestinationsList(
        locationIdentifiers
      )
      const cargoObjectList = await teqplayAPIService.getBICSCargoList(
        cargoIdentifiers,
        i18n.locale
      )

      setCargoLocationObjects(locationObjectList)
      setCargoObjects(cargoObjectList)
    } catch (error) {
      console.error(error)
      setCargoLocationObjects([])
      setCargoObjects([])
    }
  }

  /**
   * General function which returns if there are any unsaved changes for the user
   * Can be used to confirm exiting out of the prompt
   */
  function checkForUnsavedChanges() {
    const matchedVoyage =
      currentVoyage?.travelId === parseInt(travelId, 10)
        ? currentVoyage
        : voyages?.find(v => v.travelId === parseInt(travelId, 10))

    const hasDifferentViaPoints = !isEqual(
      matchedVoyage?.viaPoints.map(vp => vp.location),
      viaPoints?.map(vp => vp?.srsCode)
    )

    if (travelId === 'new') {
      return true
    } else if (
      matchedVoyage &&
      (matchedVoyage.fromLocation !== departure?.srsCode ||
        hasDifferentViaPoints ||
        matchedVoyage.toLocation !== arrival?.srsCode ||
        matchedVoyage?.startTime !== departureDateTime ||
        matchedVoyage?.endTime !== arrivalDateTime ||
        matchedVoyage?.firstReportingPoint !== firstReportingPoint?.verkeerspostId ||
        matchedVoyage?.personsOnBoard !== personsOnBoard ||
        matchedVoyage?.cones !== cones ||
        !isEqual(matchedVoyage.cargo, cargo))
    ) {
      return true
    } else {
      return false
    }
  }

  function handleOnCancelClick() {
    if (hasUnsavedChanges) {
      confirmPopup({
        message: I18n.t(
          travelId === 'new'
            ? 'announcements.travel.unsavedChangesNew'
            : 'announcements.travel.unsavedChanges'
        ),
        closeOnOutsideClick: true,
        onConfirm: () => {
          resetState()
          analytics.newEvent('bics_confirm_cancel_changes', {})
        }
      })
    } else {
      resetState()
    }
  }

  function createTemplate() {
    setCopiedTravelID(travelId)
    setSelectedTravelID('new')
  }

  function updateMapLocation(bics: BicsDestination | undefined, resetBICS?: boolean) {
    if (resetBICS) {
      setBICSRoute(null)
    }
    if (bics && bics?.location) {
      return {
        displayName: bics?.nameLocal || '',
        coordinates: {
          lat: bics.location.coordinates[1],
          lng: bics.location.coordinates[0]
        }
      }
    } else {
      return null
    }
  }

  function updateMapWithRoute() {
    if (setRouteSelection) {
      const fromLocation = departure?.location?.coordinates
        ? {
            displayName: departure.nameLocal || '',
            coordinates: {
              lat: departure.location.coordinates[1],
              lng: departure.location.coordinates[0]
            }
          }
        : null
      const toLocation = arrival?.location?.coordinates
        ? {
            displayName: arrival.nameLocal || '',
            coordinates: {
              lat: arrival.location.coordinates[1],
              lng: arrival.location.coordinates[0]
            }
          }
        : null
      const newViaRoutes = viaPoints
        ? (viaPoints?.filter(t => t).map(t => updateMapLocation(t)) as IRouteLocation[])
        : []
      setRouteSelection({
        fromLocation: fromLocation,
        toLocation: toLocation,
        viaRoutes: newViaRoutes
      })
    }
  }

  function findMatchedVoyage() {
    let matchedVoyage: IBicsVoyage | undefined
    if (travelId === 'new' && copiedTravelID) {
      // Current travel will be a new one, check for presence in either currentVoyage or all voyages
      if (currentVoyage?.travelId === parseInt(copiedTravelID, 10)) {
        matchedVoyage = currentVoyage
      } else {
        matchedVoyage = voyages?.find(v => v.travelId === parseInt(copiedTravelID, 10))
      }
    } else {
      // TravelId is preexisting, check currentVoyage and voyages separately
      if (currentVoyage?.travelId === parseInt(travelId, 10)) {
        matchedVoyage = currentVoyage
      } else {
        matchedVoyage = voyages?.find(v => v.travelId === parseInt(travelId, 10))
      }
    }
    return matchedVoyage
  }

  function initializeFields() {
    const matchedVoyage = findMatchedVoyage()
    // Create a copy template, or initalize found values in the inputs
    if (matchedVoyage) {
      // Re-initialise properties of the matchedVoyage
      setPreviousVoyage(matchedVoyage)
      setPersonsOnBoard(matchedVoyage.personsOnBoard)
      setDepartureDateTime(matchedVoyage.startTime)
      setArrivalDateTime(matchedVoyage.endTime)
      setCargo(matchedVoyage.cargo)
      setCones(matchedVoyage.cones)
      setCurrentDraught(matchedVoyage.currentDraught)
      setAddress(matchedVoyage.ownerAddress)
      setSelectedHulls(matchedVoyage.hulls)
      setLengthM(matchedVoyage.lengthM)
      setWidthM(matchedVoyage.widthM)
      setHeightM(matchedVoyage.heightM)
      setBICSRoute(BICSRoute || matchedVoyage.riverguideSelectedRoute || null)
      if (!viaPoints) {
        setViaPoints(matchedVoyage.viaLocationObjects)
      }

      // Handle setting the locked/edit mode
      if (travelId !== 'new') {
        // Handling a non-new visit
        if (
          // Cancelled visits may not be edited at all
          matchedVoyage.cancelled ||
          // If a visit has just been sent, it is awaiting approval from BICS and cannot be edited
          matchedVoyage.status === 'SENT' ||
          // If a visit is pending, it is awaiting confirmation of being paused and cannot be edited
          matchedVoyage.status === 'PENDING' ||
          // If a visit is rejected, it cannot be edited=
          matchedVoyage.status === 'ANSWERED_REJECT'
        ) {
          setEditMode(true)
          setLockedMode(true)
        } else if (
          // Paused visits may only be partially edited
          matchedVoyage.paused ||
          // Voyages which have been started already can also only be partially edited
          (!matchedVoyage.paused && matchedVoyage.status === 'ANSWERED_OK')
        ) {
          setEditMode(true)
          setLockedMode(false)
        }
      } else {
        // No restrictions on editing for new voyages
        setEditMode(false)
        setLockedMode(false)
      }
    }
  }

  /**
   * Sets the dimensions as if all ships are laying in front of eachother or next to eachother.
   * @param val
   */
  function handleShowOverrideDimensionsWithLargest(val: 'width' | 'length') {
    const allHulls = (selectedHulls || []).map(h =>
      hulls?.find(x => x.mmsi === h || x.eni === h)
    ) as BicsHull[]

    const largestLength = sortImmutableByKey(allHulls, 'lengthM', 'desc')?.[0]
    const newLength =
      val === 'length'
        ? allHulls.map(c => c.lengthM).reduce((p, c) => p + c)
        : largestLength.lengthM

    const largestWidth = sortImmutableByKey(allHulls, 'widthM', 'desc')?.[0]
    const newWidth =
      val === 'width' ? allHulls.map(c => c.widthM).reduce((p, c) => p + c) : largestWidth.widthM

    const largestHeight = sortImmutableByKey(allHulls, 'heightM', 'desc')?.[0]

    confirmPopup({
      title: I18n.t('announcements.travel.overrideDimensions'),
      message: (
        <ul className="override-dimensions">
          <li>
            {`${I18n.t('announcements.fields.lengthM')}: ${
              val === 'length' ? I18n.t('announcements.travel.totalLength') : largestLength.name
            } ${newLength} ${I18n.t('announcements.fields.meter')}`}
          </li>
          <li>
            {`${I18n.t('announcements.fields.widthM')}: ${
              val === 'width' ? I18n.t('announcements.travel.totalWidth') : largestWidth.name
            } ${newWidth} ${I18n.t('announcements.fields.meter')}`}
          </li>
          <li>
            {I18n.t('announcements.fields.heightM')}: {largestHeight.name}, {largestHeight.heightM}{' '}
            {I18n.t('announcements.fields.meter')}
          </li>
        </ul>
      ),
      closeOnOutsideClick: true,
      onConfirm: () => {
        setLengthM(newLength || 0)
        setWidthM(newWidth || 0)
        setHeightM(largestHeight.heightM || 0)
      }
    })
  }
}

export default TravelScreen
