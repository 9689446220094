import React from 'react'
import { Handles, Rail, Slider, Ticks } from 'react-compound-slider'
import { Handle, SliderRail, Tick } from './SliderComponents'

import { format, set } from 'date-fns'
import './Slider.scss'

interface IProps {
  value: number
  domain: { min: number; max: number }
  onChange: (v: readonly number[]) => void
}

const GenericSlider = (props: IProps) => {
  const { value, domain, onChange } = props

  const dayInMS = 24 * 60 * 60 * 1000

  const sliderStyle = {
    position: 'relative' as 'relative',
    width: '100%'
  }

  function formatTick(ms: number) {
    return format(new Date(ms), 'MMM dd')
  }

  // Generate date ticks
  const dateTicks = []
  dateTicks.push(domain.min)
  for (let i = 0; i < (domain.max - domain.max) / dayInMS; i++) {
    if (domain.min + dayInMS < domain.max) {
      dateTicks.push(
        set(domain.min + dayInMS, { milliseconds: 0, seconds: 0, minutes: 0, hours: 0 }).valueOf()
      )
    }
  }
  dateTicks.push(domain.max)

  return (
    <div className={`slider item`} id="slider">
      <Slider
        rootStyle={sliderStyle}
        domain={[domain.min, domain.max]} // [min, max]
        values={[value]} // slider values
        onUpdate={e => onChange(e)}
      >
        <Rail>{({ getRailProps }) => <SliderRail getRailProps={getRailProps} />}</Rail>
        <Handles>
          {({ handles, getHandleProps }) => (
            <div className="slider-handles">
              {handles.map(handle => (
                <Handle
                  key={handle.id}
                  handle={handle}
                  domain={[domain.min, domain.max]}
                  getHandleProps={getHandleProps}
                />
              ))}
            </div>
          )}
        </Handles>
        <Ticks values={dateTicks}>
          {({ ticks }) => (
            <div>
              {ticks.map(tick => (
                <Tick key={tick.id} tick={tick} count={ticks.length} format={formatTick} />
              ))}
            </div>
          )}
        </Ticks>
      </Slider>
    </div>
  )
}

export default GenericSlider
