import React from 'react'
import Modal from 'react-modal'
import { I18n } from 'react-redux-i18n'

import { useHistory, useLocation } from 'react-router'
import { useAnalytics } from '../../../services/AnalyticsWrapper/AnalyticsWrapper'

interface IProps {
  showModal: boolean
  closeModal: () => void
  handleStopRoute: () => void
}

const RequestUseActiveRouteModal = ({ showModal, closeModal, handleStopRoute }: IProps) => {
  const analytics = useAnalytics('RequestUseActiveRouteModal')
  const history = useHistory()
  const location = useLocation()

  return (
    <Modal
      isOpen={showModal}
      className="active-route-modal modal"
      style={{ overlay: { backgroundColor: 'rgba(0,0,0,0.75)' } }}
    >
      <p>{I18n.t('modalSelectPreviousRoute.question')}</p>
      <button
        className="button primary large activate-button"
        onClick={() => {
          analytics.newEvent('resume_previous_active_route', {})
          history.push(`/navigation${location.search}`)
        }}
        id="button-route-selection-page-resume-route"
      >
        {I18n.t('modalSelectPreviousRoute.yes')}
      </button>
      <button
        className="button large empty border-gray close-button"
        onClick={() => {
          closeModal()
          analytics.newEvent('cancel_previous_active_route', {})
          handleStopRoute()
        }}
        id="button-route-selection-page-stop-route"
      >
        {I18n.t('modalSelectPreviousRoute.no')}
      </button>
    </Modal>
  )
}

export default RequestUseActiveRouteModal
