import { AnyAction } from 'redux'
import {
  IBridgeMovement,
  IBridgePlanning,
  ILockPlanning,
  ISelectedRoute
} from '../../services/TeqplayAPIService/TeqplayApi'

export interface IRouteSelectionState {
  navigationRoute: ISelectedRoute | null
  bridgePlanning: IBridgePlanning[]
  bridgeMovement: IBridgeMovement[]
  lockPlanning: ILockPlanning[]
}

// Set inital login state
const initialState: IRouteSelectionState = {
  navigationRoute: null,
  bridgePlanning: [],
  bridgeMovement: [],
  lockPlanning: []
}

// eslint-disable-next-line @typescript-eslint/default-param-last
export function routeSelectionReducer(state = initialState, action: AnyAction) {
  switch (action.type) {
    case 'SET_NAVIGATION_ROUTE': {
      return Object.assign({}, state, { navigationRoute: action.route })
    }

    case 'SET_BRIDGE_AND_LOCK_OPENINGS': {
      return Object.assign({}, state, {
        bridgePlanning: action.bridgePlanning,
        bridgeMovement: action.bridgeMovement,
        lockPlanning: action.lockPlanning
      })
    }

    default:
      return state
  }
}
