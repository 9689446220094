import React, { useEffect, useState } from 'react'
import { addDays, format, subDays } from 'date-fns'
import { minBy } from 'lodash'
import { WMSTileLayer } from 'react-leaflet'
import { I18n } from 'react-redux-i18n'

import TeqplayApiService from '../../../../../services/TeqplayAPIService/TeqplayApiService'
import GenericSlider from '../../../../slider/Slider'
import CollapsableLegendComponent from '../../../controls/CollapsableLegendComponent'
import CurrentColorLegend from './CurrentColorLegend/CurrentColorLegend'
import { RWS_CURRENTS_LAYER, RWS_CURRENTS_PARAMS } from './CurrentsUtils.ts/CurrentsUtils'

import './CurrentsLayer.scss'

interface IProps {
  teqplayApiService: TeqplayApiService
  name: string
  checked: boolean
  zoom: number | undefined
  areas?: string[]
  handleFetchError?: (error: Error) => void
  hideLegendCollapsable?: boolean
  legendPosition?: L.ControlPosition
}

const CurrentsLayer = (props: IProps) => {
  const { teqplayApiService, hideLegendCollapsable, legendPosition, checked } = props

  const [times, setTimes] = useState<number[] | undefined>()
  const [selectedTime, setSelectedTime] = useState<number | undefined>()

  useEffect(() => {
    fetchCurrentInformation()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const domain =
    times && times.length > 1 ? { min: times[0], max: times[times.length - 1] } : { min: 0, max: 1 }

  if (!checked) {
    return null
  }

  return (
    <React.Fragment>
      <CollapsableLegendComponent
        position={legendPosition || 'topright'}
        icon={<i className="icon-waves" />}
        hideCollapsible={hideLegendCollapsable}
        id={props.name}
      >
        <React.Fragment>
          <div className="timeslider-wrapper">
            <div className="timeslider-legend-description">
              {I18n.t('map.currents.current')} {I18n.t('map.currents.ms')}
            </div>
            <CurrentColorLegend teqplayApiService={teqplayApiService} />
            {selectedTime && domain && (
              <GenericSlider domain={domain} value={selectedTime} onChange={n => onChange(n)} />
            )}
            <div className="selected-time">
              {selectedTime && format(new Date(selectedTime), 'd MMM HH:mm')}
            </div>
          </div>
        </React.Fragment>
      </CollapsableLegendComponent>
      {selectedTime && (
        <WMSTileLayer
          url={RWS_CURRENTS_PARAMS.url}
          layers={RWS_CURRENTS_PARAMS.layers}
          format={RWS_CURRENTS_PARAMS.format}
          version={RWS_CURRENTS_PARAMS.version}
          time={new Date(selectedTime).toISOString()}
        />
      )}
    </React.Fragment>
  )

  function onChange(timestamp: readonly number[]) {
    findClosestTimestampFromSlider(timestamp[0])
  }

  function findClosestTimestampFromSlider(timestamp: number) {
    const closest = minBy(times, time => Math.abs(time - timestamp))
    if (closest) {
      setSelectedTime(closest)
    }
  }

  async function fetchCurrentInformation() {
    try {
      const startForecastTime = subDays(new Date(), 1).toISOString()
      const endForecastTime = addDays(new Date(), 1).toISOString()
      const current = await teqplayApiService.fetchCurrent(startForecastTime, endForecastTime)
      const targetLayer = current.layers.find(layer => layer.groupName === RWS_CURRENTS_LAYER)
      const allTimes = targetLayer?.times?.map(date => new Date(date).getTime())
      setTimes(allTimes)
      // This will take the closest nummer from users current time
      setSelectedTime(minBy(allTimes, time => Math.abs(time - new Date().getTime())))
    } catch (err) {
      if (props.handleFetchError) {
        props.handleFetchError(err)
      }
    }
  }
}

export default CurrentsLayer
