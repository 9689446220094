import { BoundingBox } from 'geolocation-utils'
import { LatLngBounds } from 'leaflet'
import React from 'react'
import { I18n } from 'react-redux-i18n'
import { ITeqplayBounds } from '../@types/types'

export function leafletBoundsToTeqplayBoundsObject(bounds?: LatLngBounds): ITeqplayBounds {
  if (!bounds) {
    return {}
  }

  const topLeftLat = bounds.getNorthEast().lat
  const topLeftLon = bounds.getSouthWest().lng
  const bottomRightLat = bounds.getSouthWest().lat
  const bottomRightLon = bounds.getNorthEast().lng

  return {
    topLeftLat,
    topLeftLon,
    bottomRightLat,
    bottomRightLon
  }
}

export function geolocationBoundingBoxToTeqplayBoundsObject(bounds?: BoundingBox): ITeqplayBounds {
  if (!bounds) {
    return {}
  }

  return {
    topLeftLat: bounds.topLeft[0],
    topLeftLon: bounds.topLeft[1],
    bottomRightLat: bounds.bottomRight[0],
    bottomRightLon: bounds.bottomRight[1]
  }
}

export function labelAndValue(label?: string, value?: React.ReactNode, suffix?: React.ReactNode) {
  if (value === undefined || value === null || value === '') return null

  return (
    <div className="label-value-wrapper">
      <label>{label}</label>
      <span>
        {value === true ? I18n.t('yes') : value === false ? I18n.t('no') : value}
        {suffix}
      </span>
    </div>
  )
}
