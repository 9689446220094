import { Control, DomEvent, DomUtil, Map as LeafletMap } from 'leaflet'
import ReactDOM from 'react-dom'
import { MapControl, withLeaflet } from 'react-leaflet'

const DumbControl = (options: any) =>
  Control.extend({
    options: {
      className: options.className,
      onOff: '',
      handleOff: () => ({}),
      ...options
    },

    onAdd(map: LeafletMap) {
      const controlDiv = DomUtil.create('div', this.options.className)
      const hasTouchStart = 'ontouchstart' in window
      const hasTouchPoints = navigator.maxTouchPoints > 0
      /* Chrome devtools etc will mimic touch events 
         Fallback for mobiles for mobile & apps
      */
      if ((!hasTouchStart && !hasTouchPoints) || !navigator.userAgent.includes('Chrome')) {
        // Web
        DomEvent.addListener(controlDiv, 'mouseover', e => {
          DomEvent.preventDefault(e)
          map.dragging.disable()
        })
        DomEvent.addListener(controlDiv, 'mouseout', e => {
          DomEvent.preventDefault(e)
          map.dragging.enable()
        })
      } else {
        // Mobile, apps
        DomEvent.addListener(controlDiv, 'touchstart', e => {
          DomEvent.preventDefault(e)
          map.dragging.disable()
        })
        DomEvent.addListener(controlDiv, 'touchend', e => {
          DomEvent.preventDefault(e)
          map.dragging.enable()
        })
      }
      return controlDiv
    },

    onRemove(map: LeafletMap) {
      if (this.options.onOff) {
        map.off(this.options.onOff, this.options.handleOff, this)
      }

      return this
    }
  })

export default withLeaflet<any>(
  class UpdatableControl extends MapControl {
    public createLeafletElement(props: any) {
      const ControlWithProps = DumbControl(props)
      return new ControlWithProps()
    }

    public componentDidMount() {
      // @ts-ignore
      super.componentDidMount()

      // This is needed because the control is only attached to the map in
      // MapControl's componentDidMount, so the container is not available
      // until this is called. We need to now force a render so that the
      // portal and children are actually rendered.
      this.forceUpdate()
    }

    public render() {
      if (!this.leafletElement || !this.leafletElement.getContainer()) {
        return null
      }

      return ReactDOM.createPortal(
        this.props.children,
        // @ts-ignore
        this.leafletElement.getContainer()
      )
    }
  }
)
