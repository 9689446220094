import React from 'react'
import { differenceInDays, formatRelative, formatDistance } from 'date-fns'
import { useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { toast } from 'react-toastify'
import { useHistory } from 'react-router'

import DefaultTransition from '../../components/shared/defaultTransition/DefaultTransition'
import TitleComponent from '../../components/titleComponent/TitleComponent'

import { IRootProps, ILocationStatus } from '../../@types/types'
import { degreesToCardinals } from '../../utils/general'

import './AISPage.scss'

interface IProps {
  locationStatus: ILocationStatus
}

const AISPage = ({ locationStatus }: IProps) => {
  const { userLocation } = useSelector((rootProps: IRootProps) => rootProps)
  const history = useHistory()

  const currentIsAncient =
    userLocation.currentLocation?.timeLastUpdate &&
    differenceInDays(new Date(), new Date(userLocation.currentLocation?.timeLastUpdate)) > 60
  const currentIsOld =
    userLocation.currentLocation?.timeLastUpdate &&
    differenceInDays(new Date(), new Date(userLocation.currentLocation?.timeLastUpdate)) > 7

  return (
    <DefaultTransition active={true} offset={10} slideFrom="top">
      {styleProps => (
        <div className="main-wrapper ais-page" style={styleProps}>
          <TitleComponent title={I18n.t('aisPage.title')} />
          <h2>{I18n.t('aisPage.title')}</h2>

          <div className="ais-box">
            <button className="button primary" onClick={() => reloadLocation()}>
              {locationStatus.loading ? (
                <i className="animate-spin icon-arrows-cw" />
              ) : (
                <i className="icon-arrows-cw" />
              )}
              {I18n.t('aisPage.refresh')}
            </button>

            <div
              className={`info-box${
                currentIsAncient ? ' is-ancient' : currentIsOld ? ' is-old' : ''
              }`}
            >
              <div className="time">
                {I18n.t('aisPage.lastSignal', {
                  time:
                    userLocation.currentLocation?.timeLastUpdate &&
                    formatRelative(
                      new Date(userLocation.currentLocation?.timeLastUpdate),
                      new Date()
                    )
                })}
              </div>
              <div className="relative-time">
                {userLocation.currentLocation?.timeLastUpdate &&
                  formatDistance(
                    new Date(userLocation.currentLocation?.timeLastUpdate),
                    new Date(),
                    {
                      includeSeconds: true
                    }
                  )}{' '}
                {I18n.t('mapLayers.past')}
              </div>
            </div>
          </div>
          {(currentIsAncient || currentIsOld) && (
            <div className="info-box is-old old-warning">
              {I18n.t('aisPage.lastSignalNotUptoDate')}
            </div>
          )}

          <div className="page-block">
            <div className="form-row">
              <h3>{I18n.t('aisPage.staticInfo')}</h3>
            </div>
            <AISItem label={I18n.t('aisPage.name')} value={userLocation.currentLocation?.name} />
            <AISItem label={I18n.t('aisPage.mmsi')} value={userLocation.currentLocation?.mmsi} />
            <AISItem
              label={I18n.t('aisPage.imo')}
              value={userLocation.currentLocation?.imoNumber}
            />
            <AISItem
              label={I18n.t('aisPage.callsign')}
              value={userLocation.currentLocation?.callSign}
            />
            <AISItem
              label={I18n.t('aisPage.category')}
              value={userLocation.currentLocation?.category}
            />
            <AISItem
              label={I18n.t('aisPage.shipType')}
              value={userLocation.currentLocation?.shipType}
            />
            <AISItem
              label={I18n.t('aisPage.status')}
              value={userLocation.currentLocation?.status.split('_').join(' ')}
            />
            <AISItem
              label={I18n.t('aisPage.length')}
              value={userLocation.currentLocation?.length}
              suffix={'m'}
            />
            <AISItem
              label={I18n.t('aisPage.width')}
              value={userLocation.currentLocation?.width}
              suffix={'m'}
            />
            <AISItem
              label={I18n.t('aisPage.maxDraught')}
              value={userLocation.currentLocation?.maxDraught}
              suffix={'m'}
            />
          </div>

          <div className="page-block">
            <div className="form-row">
              <h3>{I18n.t('aisPage.dynamicInfo')}</h3>
            </div>
            <AISItem
              label={I18n.t('aisPage.courseOverGround')}
              value={userLocation.currentLocation?.courseOverGround}
              suffix={`° (${
                userLocation.currentLocation?.courseOverGround &&
                degreesToCardinals(userLocation.currentLocation?.courseOverGround)
              })`}
            />
            <AISItem
              label={I18n.t('aisPage.speedOverGround')}
              value={userLocation.currentLocation?.speedOverGround}
              suffix={` ${I18n.t('aisPage.knots')}`}
            />
            <AISItem
              value={
                userLocation.currentLocation?.speedOverGround &&
                Math.round(userLocation.currentLocation.speedOverGround * 1.852 * 10) / 10
              }
              suffix={` ${I18n.t('aisPage.kmh')}`}
            />
            <AISItem
              label={I18n.t('aisPage.destination')}
              value={userLocation.currentLocation?.destination}
            />
            <AISItem
              label={I18n.t('aisPage.latitude')}
              value={
                userLocation.currentLocation?.location.latitude &&
                Math.round(userLocation.currentLocation?.location.latitude * 10000) / 10000
              }
            />
            <AISItem
              label={I18n.t('aisPage.longitude')}
              value={
                userLocation.currentLocation?.location.longitude &&
                Math.round(userLocation.currentLocation?.location.longitude * 10000) / 10000
              }
            />
          </div>
          <button className="button primary" onClick={() => history.push('/')}>
            <i className="icon-right-dir" />
            {I18n.t('aisPage.viewOnMap')}
          </button>
        </div>
      )}
    </DefaultTransition>
  )

  async function reloadLocation() {
    try {
      await locationStatus.refreshLocation()
      toast.success(I18n.t('aisPage.latestDataFetched'))
    } catch (error) {
      toast.error(I18n.t('aisPage.latestDataFetchedError'))
      console.error(error)
    }
  }
}

const AISItem = ({
  label,
  value,
  suffix
}: {
  value: React.ReactNode
  label?: React.ReactNode
  suffix?: React.ReactNode
}) =>
  !value ? null : (
    <div className="form-row ais-item">
      <div className="label">{label}</div>
      <div className="value">
        {value}
        {suffix}
      </div>
    </div>
  )

export default AISPage
