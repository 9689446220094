import React from 'react'
import { I18n } from 'react-redux-i18n'
import CollapsableLegendComponent from '../../../controls/CollapsableLegendComponent'

interface IProps {
  name: string
  hideLegendCollapsable: boolean | undefined
  legendPosition: L.ControlPosition | undefined
}

const BerthLegendComponent = ({ name, hideLegendCollapsable, legendPosition }: IProps) => {
  return (
    <CollapsableLegendComponent
      icon={<span className="icon-bolder" />}
      hideCollapsible={hideLegendCollapsable}
      position={legendPosition || 'topright'}
      id={name}
    >
      <div className="item-legend">
        <div className="name">{I18n.t('mapLayers.berthLayer.free')}</div>
        <div className="icon">
          <div
            className={'polygon'}
            style={{
              borderColor: 'rgb(19, 163, 79)',
              backgroundColor: 'rgba(19, 163, 79, 0.2)'
            }}
          />
        </div>
      </div>
      <div className="item-legend">
        <div className="name">{I18n.t('mapLayers.berthLayer.occupied')}</div>
        <div className="icon">
          <div
            className={'polygon'}
            style={{
              borderColor: 'rgb(209, 175, 6)',
              backgroundColor: 'rgba(209, 175, 6, 0.2)'
            }}
          />
        </div>
      </div>
      <div className="item-legend">
        <div className="name">{I18n.t('mapLayers.berthLayer.full')}</div>
        <div className="icon">
          <div
            className={'polygon'}
            style={{
              borderColor: 'rgb(244, 7, 0)',
              backgroundColor: 'rgba(244, 7, 0, 0.2)'
            }}
          />
        </div>
      </div>
      <div className="item-legend">
        <div className="name">{I18n.t('mapLayers.berthLayer.unknown')}</div>
        <div className="icon">
          <div
            className={'polygon'}
            style={{
              borderColor: 'rgb(0, 2, 170)',
              backgroundColor: 'rgba(0, 2, 170, 0.2)'
            }}
          />
        </div>
      </div>
    </CollapsableLegendComponent>
  )
}

export default BerthLegendComponent
