import React, { useState, useEffect } from 'react'
import { I18n } from 'react-redux-i18n'
import { toast } from 'react-toastify'

import BICSGenericInputRow from '../bicsGenericInputRow/BICSGenericInputRow'
import BICSHullEntry from '../bicsHullEntry/BICSHullEntry'
import BICSHullSelectorModal from '../bicsHullSelectorModal/BICSHullSelectorModal'
import BICSShipTypeSelector from '../bicsShipTypeSelector/BICSShipTypeSelector'
import SwitchButton from '../../shared/switchButton/SwitchButton'
import TeqplayApiService from '../../../services/TeqplayAPIService/TeqplayApiService'

import { BicsHull, BicsVoyage, BicsShipType } from '../../../services/TeqplayAPIService/TeqplayApi'
import { ICustomError } from '../../../@types/types'
import { activeClass } from '../../../utils/general'
import { confirmPopup } from '../../shared/confirmPopup/ConfirmPopup'
import { isMainHull } from '../../../utils/bics'
import { usePrevious } from '../../../hooks/usePrevious'

import './BICSSelectHullsBlock.scss'

interface IProps {
  mainHull: BicsHull | undefined
  allHulls: BicsHull[] | null
  hulls: string[] | undefined
  setHulls: (usedHulls: string[]) => void
  onClickHull: (eni: string) => void
  convoyType: BicsShipType | null
  setConvoyType: (ct: BicsShipType | null) => void
  initialConvoyType: string | undefined
  errors: ICustomError<keyof BicsVoyage | 'viaPoints'>[]
  disabled: boolean
  teqplayApiService: TeqplayApiService
}

const BICSSelectHullsBlock = ({
  mainHull,
  allHulls,
  hulls,
  setHulls,
  onClickHull,
  convoyType,
  initialConvoyType,
  setConvoyType,
  errors,
  disabled,
  teqplayApiService
}: IProps) => {
  const [isConvoy, setIsConvoy] = useState<boolean>(
    initialConvoyType || (hulls && hulls.length === 1) ? false : !!convoyType
  )
  const [showSelectorModal, setShowSelectorModal] = useState<boolean>(false)
  const mappedHullValues = (hulls || []).map(
    h => allHulls?.find(c => h === c.eni || h === c.mmsi) || ({ eni: h } as BicsHull)
  )

  const [initialized, setInitialized] = useState<boolean>(false)
  const previousInitialConvoyType = usePrevious(initialConvoyType)

  useEffect(() => {
    // Set convoy type to true in case loaded from previous state and not yet done
    if (!initialized && !previousInitialConvoyType && initialConvoyType && !isConvoy) {
      setInitialized(true)
      setIsConvoy(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialConvoyType])

  useEffect(() => {
    if (hulls?.length === 1) {
      setHulls(hulls)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="hulls-block">
      <BICSHullSelectorModal
        mainHull={mainHull}
        hulls={allHulls}
        showModal={showSelectorModal}
        onClickHull={e => handleAddNewHull(e)}
        onClose={() => setShowSelectorModal(false)}
        filterHulls={hulls}
        hideCreateNewButton={allHulls && allHulls.length <= 1 ? false : true}
        titleOverride={I18n.t('announcements.travel.selectHull')}
        subtitleOverride={I18n.t('announcements.travel.clickToAddHull')}
      />
      <div className="preheader-with-buttons hull-title">
        <h3 className="block-preheader top">{I18n.t('announcements.travel.sailingUnity')}</h3>
        <div className="right buttons">
          <button
            className="button primary"
            type="button"
            onClick={e => {
              e.preventDefault()
              if (isConvoy) {
                setShowSelectorModal(true)
              } else {
                confirmPopup({
                  message: I18n.t('announcements.travel.willSwitchToCombinedHulls'),
                  closeOnOutsideClick: true,
                  onConfirm: () => {
                    setIsConvoy(true)
                    setShowSelectorModal(true)
                  }
                })
              }
            }}
          >
            <div className="name">
              <i className="icon-plus" />
              {I18n.t('announcements.travel.addHull')}
            </div>
          </button>
        </div>
      </div>
      <div className="page-block bics-select-hull">
        <BICSGenericInputRow<keyof BicsVoyage | 'viaPoints'>
          keyName="hulls"
          labelOverride={
            <label className="label" htmlFor={'hulls'}>
              {I18n.t('announcements.fields.type')}
            </label>
          }
          isRequired={false}
          errors={errors}
        >
          <SwitchButton isDisabled={disabled}>
            <button
              className={`button ${activeClass(!isConvoy)}`}
              type="button"
              onClick={e => {
                e.preventDefault()
                handleSetSingleHull(mainHull)
              }}
              disabled={disabled}
            >
              {I18n.t('announcements.fields.singleHull')}
            </button>
            <button
              className={`button ${activeClass(isConvoy === true)}`}
              type="button"
              onClick={e => {
                e.preventDefault()
                setIsConvoy(true)
              }}
              disabled={disabled}
            >
              {I18n.t('announcements.fields.multiHull')}
            </button>
          </SwitchButton>
        </BICSGenericInputRow>

        <div className="hulls-list-wrapper form-row dimension-row">
          <label className="label" htmlFor={'hulls'}>
            {I18n.t('announcements.travel.hullProperties')}
          </label>
          <div className="hulls-list">
            {mappedHullValues.map(h => (
              <BICSHullEntry
                key={h.mmsi || h.eni}
                hull={h}
                mainHull={mainHull}
                onDelete={handleRemoveHull}
                disabled={disabled || isMainHull(mainHull, h)}
              />
            ))}
          </div>
        </div>

        {isConvoy ? (
          <BICSGenericInputRow<keyof BicsVoyage | 'viaPoints'>
            keyName="convoyType"
            isRequired={(hulls || [])?.length > 1}
            errors={errors}
          >
            <BICSShipTypeSelector
              value={convoyType}
              initialShipTypeCode={initialConvoyType}
              onChange={v => setConvoyType(v)}
              showConvoyTypes={true}
              teqplayAPIService={teqplayApiService}
              isClearable={(hulls || [])?.length <= 0}
            />
          </BICSGenericInputRow>
        ) : null}
      </div>
    </div>
  )

  function handleSetSingleHull(c: BicsHull | undefined) {
    if (!c) {
      toast.error(I18n.t('announcements.error.noMainHull'))
      return
    }

    if (!c.mmsi) {
      toast.error(I18n.t('announcements.error.noMMSIOnHull'))
      return
    }

    if (hulls && hulls.length > 1) {
      confirmPopup({
        onConfirm: () => {
          setIsConvoy(false)
          setConvoyType(null)
          setHulls([c.mmsi as string])
        },
        message: I18n.t('announcements.travel.willClearSelectedHulls'),
        closeOnOutsideClick: true
      })
    } else {
      setIsConvoy(false)
      setConvoyType(null)
      setHulls([c.mmsi])
    }
  }

  function handleAddNewHull(eniMMSICombo: string) {
    if (eniMMSICombo === 'new') {
      // If the button is pressed to create a new hull,
      // it will pas the variable as "new"
      return confirmPopup({
        message: I18n.t('announcements.travel.createNewHullSave'),
        onConfirm: () => onClickHull('new')
      })
    }

    const eni = eniMMSICombo.split('-')[0]
    const mmsi = eniMMSICombo.split('-')[1]
    const correctedMMSI = mmsi === 'undefined' ? undefined : mmsi

    if (!correctedMMSI && !eni) {
      toast.error(I18n.t('announcements.error.noENIOnHull'))
      return
    }

    setShowSelectorModal(false)
    setHulls([...(hulls || []), correctedMMSI || eni])
  }

  function handleRemoveHull(eniOrMMSI: string | undefined) {
    setHulls((hulls || []).filter(h => h !== eniOrMMSI))
  }
}

export default BICSSelectHullsBlock
