import { format } from 'date-fns'
import * as React from 'react'
import { I18n } from 'react-redux-i18n'
import { Link } from 'react-router-dom'

import { useAnalytics } from '../../../../services/AnalyticsWrapper/AnalyticsWrapper'
import { ISelectedRoute } from '../../../../services/TeqplayAPIService/TeqplayApi'
import { TIME_FORMAT } from '../../../../utils/constants'
import { formatIntoHoursAndMinutesFromNow } from '../../../../utils/dates'
import { getDistanceLeftOnRoute } from '../../../../utils/routeUtils'

interface IProps {
  onClick: (e: React.ChangeEvent<any>) => void
  url: string
  queryParams?: string
  activePage?: string
  navigationRoute: ISelectedRoute
}

const NavigationItem: React.FunctionComponent<IProps> = ({
  onClick,
  url,
  activePage,
  navigationRoute,
  queryParams
}) => {
  const analytics = useAnalytics('NavigationItem')
  const activePageClass = isPageActive(url, activePage) ? 'active' : ''
  const routeDistance = getDistanceLeftOnRoute(navigationRoute)

  const absoluteTime = format(navigationRoute.route.eta, TIME_FORMAT)
  const relativeTime = formatIntoHoursAndMinutesFromNow(navigationRoute.route.eta)
  const distanceLeft = `${routeDistance > 0 ? formatDistanceLeft(routeDistance) : '?'} km`

  return (
    <Link
      id="menu-item-active-route"
      onClick={handleClick}
      className={`menu-item ${activePageClass}`}
      to={{ pathname: url, search: queryParams }}
    >
      <div className="row">
        <i className="icon-user-location" />
        <span>{I18n.t('pages.navigationActive')}</span>
      </div>
      <div className="summary">
        <div className="relativeTime">{relativeTime}</div>
        <div className="row">
          <div className="distance">{distanceLeft}</div>
          <div> · </div>
          <div className="absoluteTime">{absoluteTime}</div>
        </div>
      </div>
    </Link>
  )

  function handleClick(e: React.ChangeEvent<any>) {
    analytics.newEvent('click_navigation_item', {
      navigationRoute,
      activePage,
      url,
      absoluteTime,
      relativeTime,
      distanceLeft
    })
    onClick(e)
  }

  function formatDistanceLeft(d: number) {
    return Math.round(d / 1000)
  }
}

export default NavigationItem

export function isPageActive(pageName: string, activePage?: string) {
  if (!activePage) return false

  if ((activePage === '/' || activePage === '') && pageName !== '/') {
    // If the active page is empty (homepage) make sure not all pages are set to active
    return false
  }

  return pageName.includes(activePage)
}
