import { BicsAddress, BicsHull } from '../services/TeqplayAPIService/TeqplayApi'
import { filterUnique } from './general'

export function mapAddressToAddressString(ad: BicsAddress | undefined) {
  return !ad
    ? ''
    : `${ad.givenName}-${ad.familyName}-${ad.streetName}-${ad.postalCode}-${ad.city}-${ad.country}`
}

export function filterUniqueAddresses(
  address: BicsAddress | undefined,
  addressList: BicsAddress[],
  removeSelected?: boolean,
  hullAddress?: BicsAddress,
  removeHullAddress?: boolean
): BicsAddress[] {
  const currentSelectedAddressKey = mapAddressToAddressString(address)
  const hullAddressKey = mapAddressToAddressString(hullAddress)
  const keyedAddresses = addressList.map(a => ({
    ...a,
    key: mapAddressToAddressString(a)
  }))

  return keyedAddresses
    .map(ka => ka.key)
    .filter(filterUnique)
    .filter(a => (removeSelected ? a !== currentSelectedAddressKey : a))
    .filter(a => (removeHullAddress ? a !== hullAddressKey : a))
    .map(k => ({ ...keyedAddresses.find(ka => ka.key === k), key: undefined })) as BicsAddress[]
}

export function convertAddressToComparableString(address: BicsAddress) {
  return `${address.city} ${address.country || ''} ${address.familyName || ''} ${
    address.givenName
  } ${address.postalCode} ${address.streetName} ${address.vatNumber} ${address.organisation}`
}

export function areAddressesEqual(
  address1: BicsAddress | undefined,
  address2: BicsAddress | undefined
) {
  const key1 = address1 ? convertAddressToComparableString(address1) : ''
  const key2 = address2 ? convertAddressToComparableString(address2) : ''

  return key1 === key2
}

export function isMainHull(mainHull: BicsHull | undefined, h: BicsHull | undefined) {
  return mainHull?.eni === h?.eni && mainHull?.mmsi === h?.mmsi && mainHull?.type === h?.type
}
