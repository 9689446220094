import * as React from 'react'
import { Marker } from 'react-leaflet'

import { IRouteItem } from '../../../services/TeqplayAPIService/TeqplayApi'
import { getRouteItemIcon } from '../../mapShared/mapIcons'
import { SelectedItemModalEnum } from '../../../@types/types'

import './RouteItemMarker.scss'

interface IProps {
  routeItem: IRouteItem
  setSelectedItem: (item: SelectedItemModalEnum) => void
}

const RouteItemMarker = ({ routeItem, setSelectedItem }: IProps) => {
  // checks if a bridge can be passed, for most items this results in canBePassed = true
  const canBePassed =
    routeItem.canBePassed !== undefined && routeItem.canBePassed === false ? false : true
  const icon = getRouteItemIcon(routeItem, canBePassed)

  const location = routeItem.location[0]

  if (!location) {
    return null
  }

  return (
    <Marker
      icon={icon}
      position={[location.coordinates[1], location.coordinates[0]]}
      onClick={() => setSelectedItem({ item: routeItem, type: 'ROUTE_ITEM' })}
    />
  )
}

export default RouteItemMarker
