import React, { useEffect, useState } from 'react'
import { connect, MapDispatchToPropsFunction } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router'
import { toast } from 'react-toastify'
import { Action } from 'redux'
import { ThunkDispatch } from 'redux-thunk'

import LocationWatcher from '../components/LocationWatcher/LocationWatcher'
import CustomPage from '../pages/customPage/CustomPage'
import InformationPage from '../pages/informationPage/InformationPage'
import NavigationPage from '../pages/navigationPage/NavigationPage'
import RouteSelectionPage from '../pages/routeSelectionPage/RouteSelectionPage'
import Settings from '../pages/settings/Settings'
import WeatherPage from '../pages/weatherPage/WeatherPage'
import TeqplayApiService from '../services/TeqplayAPIService/TeqplayApiService'
import AISPage from '../pages/aisPage/AISPage'

import { IApplicationTheme, IRootProps } from '../@types/types'
import { frieslandChannels } from '../assets/channels/frieslandChannels'
import { clearAuth, setAuth, setUserProfile } from '../pages/loginPage/actions'
import { setNavigationRoute, stopCurrentRoute } from '../pages/routeSelectionPage/actions'
import { IAuthenticationWrapper } from '../services/AuthenticationWrapper/AuthenticationWrapper'
import {
  ILoggedInUser,
  ISelectedRoute,
  IShipInfo,
  IUserProfile
} from '../services/TeqplayAPIService/TeqplayApi'
import { initAnalytics } from '../utils/fcmUtils'
import { determineApplicationTheme, determineDynamicTheme } from '../utils/style'
import { convertQueryStringToObject } from '../utils/url'
import { setCurrentLocation } from './actions'
import { setUserInSentry } from '../utils/sentry'

interface IDispatchProps {
  clearAuth: () => void
  setAuth: (user: ILoggedInUser) => void
  setUserProfile: (userProfile?: IUserProfile) => void
  setCurrentLocation: (location: IShipInfo | null) => void
  setNavigationRoute: (route: ISelectedRoute) => void
  stopCurrentRoute: (teqplayApiService: TeqplayApiService) => void
}

const Routes = (props: IRootProps & IDispatchProps & IAuthenticationWrapper) => {
  const { currentUser, teqplayAPIService, routeSelection, userLocation } = props
  const history = useHistory()
  const location = useLocation()

  const [themeChanged, setThemeChanged] = useState(0)

  useEffect(() => {
    /** Dark/light mode code */
    /** Adds listeners to force rerender ONCE when theme systematically changes */
    window.matchMedia('(prefers-color-scheme: dark)').addListener(e => {
      setThemeChanged(themeChanged + 1)
      setStatusbarTheme('dark')
    })
    window.matchMedia('(prefers-color-scheme: light)').addListener(e => {
      setThemeChanged(themeChanged + 1)
      setStatusbarTheme('light')
    })
    // Initially set status bar dynamically determined by device theme
    setStatusbarTheme(
      determineApplicationTheme(props.settings.listenToDeviceTheme, props.settings.themeOverride)
    )

    if (currentUser.auth) {
      initAnalytics(props.teqplayAPIService)
      handleRetrieveUserProfile()
      setUserInSentry(currentUser.auth)
    }
    // Only execute on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (
    !currentUser ||
    !currentUser.auth?.token ||
    (currentUser.userProfile && !currentUser.userProfile.gdprAcceptanceDate)
  ) {
    if (currentUser.userProfile && !currentUser.userProfile.gdprAcceptanceDate) {
      toast.error(I18n.t('register.gdpr.error'))
    }
    return <Redirect to={`/login${location.search ? location.search : ''}`} push />
  }

  const queryParams = convertQueryStringToObject(history.location.search)
  const theme = determineApplicationTheme(
    props.settings.listenToDeviceTheme,
    props.settings.themeOverride
  )

  return (
    <div className={`app ${theme} ${window.cordova?.platformId || 'web'}`}>
      <LocationWatcher
        routeSelection={props.routeSelection.navigationRoute}
        setCurrentLocation={props.setCurrentLocation}
        isNavigating={location.pathname === '/navigation'}
        channelCrossings={frieslandChannels}
        currentLocation={props.userLocation.currentLocation}
        shipId={props.currentUser.ship?.mmsi}
        routeSimulatorActive={queryParams.simulator === 'true'}
        speedSetting={
          props.speed.speedType === 'CUSTOM'
            ? props.speed.userInputSpeed
            : props.userLocation.currentLocation?.speedOverGround || 0
        }
        stopCurrentRoute={handleExternalRouteStopping}
        setNavigationRoute={props.setNavigationRoute}
        teqplayAPIService={props.teqplayAPIService}
      >
        {(VDJSNotificationMessage, channelApproachNotificationMessage, locationStatus) => {
          return (
            <Switch location={location}>
              <Route
                exact
                path="/navigation"
                render={rprops => (
                  <NavigationPage
                    {...rprops}
                    theme={theme}
                    VDJSNotificationMessage={VDJSNotificationMessage}
                    channelApproachNotificationMessage={channelApproachNotificationMessage}
                    setStatusbarTheme={setStatusbarTheme}
                    teqplayAPIService={teqplayAPIService}
                  />
                )}
              />

              <Route
                exact
                path="/settings"
                render={() => (
                  <CustomPage
                    userProfile={currentUser.userProfile}
                    shipName={currentUser.ship?.name || ''}
                    onLogout={handleLogout}
                    navigationRoute={routeSelection.navigationRoute}
                    theme={theme}
                    setStatusbarTheme={setStatusbarTheme}
                  >
                    <Settings
                      teqplayAPIService={teqplayAPIService}
                      refreshUserProfile={handleRetrieveUserProfile}
                      locationStatus={locationStatus}
                    />
                  </CustomPage>
                )}
              />

              <Route
                exact
                path="/ais"
                render={() => (
                  <CustomPage
                    userProfile={currentUser.userProfile}
                    shipName={currentUser.ship?.name || ''}
                    onLogout={handleLogout}
                    navigationRoute={routeSelection.navigationRoute}
                    theme={theme}
                    setStatusbarTheme={setStatusbarTheme}
                  >
                    <AISPage locationStatus={locationStatus} />
                  </CustomPage>
                )}
              />

              <Route
                exact
                path="/information"
                render={() => (
                  <CustomPage
                    userProfile={currentUser.userProfile}
                    shipName={currentUser.ship?.name || ''}
                    onLogout={handleLogout}
                    navigationRoute={routeSelection.navigationRoute}
                    theme={theme}
                    setStatusbarTheme={setStatusbarTheme}
                  >
                    <InformationPage />
                  </CustomPage>
                )}
              />

              <Route
                exact
                path="/weather"
                render={() => (
                  <CustomPage
                    userProfile={currentUser.userProfile}
                    shipName={currentUser.ship?.name || ''}
                    onLogout={handleLogout}
                    navigationRoute={routeSelection.navigationRoute}
                    theme={theme}
                    setStatusbarTheme={setStatusbarTheme}
                  >
                    <WeatherPage
                      location={userLocation.currentLocation}
                      teqplayApiService={teqplayAPIService}
                    />
                  </CustomPage>
                )}
              />
              <Route
                render={() => (
                  <RouteSelectionPage
                    currentLocation={userLocation.currentLocation}
                    setStatusbarTheme={setStatusbarTheme}
                    teqplayAPIService={teqplayAPIService}
                    theme={theme}
                    locationStatus={locationStatus}
                  />
                )}
              />
            </Switch>
          )
        }}
      </LocationWatcher>
    </div>
  )

  async function handleExternalRouteStopping() {
    await props.stopCurrentRoute(teqplayAPIService)
    history.push(`/${location.search}`)
  }

  async function handleRetrieveUserProfile() {
    try {
      const userProfile = await props.teqplayAPIService.getUserProfile()
      props.setUserProfile(userProfile)
    } catch (error) {
      console.error(error)
    }
  }

  function handleLogout() {
    teqplayAPIService.logoutUser()
  }

  function setStatusbarTheme(
    t: IApplicationTheme = determineApplicationTheme(
      props.settings.listenToDeviceTheme,
      props.settings.themeOverride
    )
  ) {
    if (window.cordova?.platformId === 'android' && window.StatusBar) {
      window.StatusBar.overlaysWebView(false)
      window.StatusBar.backgroundColorByHexString('#ff1b434b')
    } else {
      if (window.StatusBar) {
        if (t === 'dark') {
          window.StatusBar.styleBlackTranslucent()
        } else {
          const systemStyle = determineDynamicTheme()

          if (systemStyle === 'dark') {
            window.StatusBar.styleBlackTranslucent()
          } else {
            window.StatusBar.styleDefault()
          }
        }
      }
    }
  }
}

const mapDispatchToProps: MapDispatchToPropsFunction<IDispatchProps, any> = (
  dispatch: ThunkDispatch<IRootProps, void, Action>
) => {
  return {
    clearAuth: () => dispatch(clearAuth()),
    setAuth: (user: ILoggedInUser) => dispatch(setAuth(user)),
    setUserProfile: (userProfile?: IUserProfile) => dispatch(setUserProfile(userProfile)),
    setCurrentLocation: (location: IShipInfo | null) => dispatch(setCurrentLocation(location)),
    setNavigationRoute: (route: ISelectedRoute) => dispatch(setNavigationRoute(route)),
    stopCurrentRoute: (teqplayApiService: TeqplayApiService) =>
      stopCurrentRoute(dispatch, teqplayApiService)
  }
}

const mapStateToProps = (state: IRootProps, ownProps: {}) => {
  return state
}

export default connect(mapStateToProps, mapDispatchToProps)(Routes)
