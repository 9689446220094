import React, { useEffect, useState } from 'react'
import ReactCountryFlag from 'react-country-flag'
import { I18n } from 'react-redux-i18n'

import { IRouteBicsDestination } from '../../../@types/types'
import { BicsDestination } from '../../../services/TeqplayAPIService/TeqplayApi'

import './BICSDestinationPresetList.scss'

interface IProps {
  routeLocations: IRouteBicsDestination[] | undefined
  onClick: (destination: BicsDestination) => void
  showOnMountOrUpdate?: boolean
}

const BICSDestinationPresetList = ({ routeLocations, onClick, showOnMountOrUpdate }: IProps) => {
  const [showPresets, setShowPresetList] = useState<boolean>(false)
  const [initialized, setInitialized] = useState<boolean>(false)

  useEffect(() => {
    if (showOnMountOrUpdate && !showPresets && !initialized) {
      setShowPresetList(true)
    } else if (!showOnMountOrUpdate && showPresets && !initialized) {
      setShowPresetList(false)
    }
  }, [showOnMountOrUpdate, setShowPresetList, showPresets, initialized])

  if (!routeLocations || routeLocations.length === 0) {
    return null
  }

  return (
    <div className="destination-preset-wrapper">
      <button
        className="button secondary toggle-presets"
        type="button"
        onClick={() => {
          setShowPresetList(!showPresets)
          setInitialized(true)
        }}
      >
        <i className={`icon-angle-${showPresets ? 'up' : 'down'}`} />
        <span>
          {I18n.t(
            showPresets ? 'destinationPresetList.hidePresets' : 'destinationPresetList.showPresets'
          )}
        </span>
      </button>
      {showPresets && (
        <div className="route-presets">
          {routeLocations.map((destination, i) => (
            <button
              className="button secondary loc-button"
              type="button"
              key={destination.risCode}
              onClick={() => handleClickOnDestination(destination)}
            >
              <div className="left">
                <div className={`dot-icon ${destination.routeIndex}`}>
                  {destination.routeIndex === 'departure' ? (
                    <i className="icon-dot-circled" />
                  ) : destination.routeIndex === 'arrival' ? (
                    <i className="icon-flag" />
                  ) : (
                    destination.viaIndex
                  )}
                </div>
                {destination.loCode && (
                  <ReactCountryFlag
                    countryCode={destination.loCode?.substr(0, 2)}
                    className="flag"
                    svg
                  />
                )}
                <div className="names">
                  <div
                    className={`name main${
                      destination.term || destination.termLocal ? ' with-terminal' : ''
                    }`}
                    title={destination.name || destination.nameLocal}
                  >
                    {destination.term ||
                      destination.termLocal ||
                      destination.name ||
                      destination.nameLocal}
                  </div>
                </div>
              </div>
              {destination.loCode && <div className="tag">{destination.loCode}</div>}
            </button>
          ))}
        </div>
      )}
    </div>
  )

  function handleClickOnDestination(d: IRouteBicsDestination) {
    setShowPresetList(false)
    setInitialized(true)
    onClick({
      ...d,
      viaIndex: undefined,
      routeIndex: undefined
    } as BicsDestination)
  }
}

export default BICSDestinationPresetList
