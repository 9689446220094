import * as Sentry from '@sentry/browser'
import pjson from '../../package.json'
import { IRestError, ILoggedInUser } from '../services/TeqplayAPIService/TeqplayApi.js'
import { getEnvironment } from './constants'

const ERROR_EXCEPTIONS: string[] = [
  'refreshTokenFailure',
  'Unexpected EOF',
  'The request timed out',
  'The network connection was lost',
  'A server with the specified hostname could not be found.',
  'Unable to parse JWT token. Authorization failed: No logged in user found.',
  'Token expired for user',
  'Permission denied',
  "Cannot read property '_leaflet_pos' of undefined",
  'The Internet connection appears to be offline.',
  'No route found for current logged in user',
  'Failed to fetch',
  'Network request failed'
]

// Start sentry when not developing
export function initializeSentry() {
  const environment = getEnvironment()

  if (environment !== 'localhost') {
    Sentry.init({
      dsn: 'https://7ef4e623863e4ee1abaf60443bea081b@o126205.ingest.sentry.io/300409',
      release: `riverguide-binnenvaart@${pjson.version}`,
      environment,
      attachStacktrace: true,
      beforeSend: (event, hint) => {
        // This will also capture non-errors
        let doNotSend = false
        ERROR_EXCEPTIONS.forEach(e => {
          if (hint?.originalException?.toString().includes(e)) {
            doNotSend = true
          }
        })

        if (!doNotSend) {
          console.error(`[SENTRY] Sent event "${hint?.originalException}"`)
          return event
        } else {
          console.warn(`[SENTRY] Filtered event "${hint?.originalException}"`)
          return null
        }
      }
    })
  }
}

export function setUserInSentry(userProfile: ILoggedInUser | null) {
  Sentry.configureScope(scope => {
    scope.setUser(!userProfile ? null : { ...userProfile })
  })
}

export function clearUserInSentry() {
  Sentry.configureScope(scope => {
    scope.setUser(null)
  })
}

export function setExtraValueInSentry(key: string, value: any) {
  Sentry.configureScope(scope => {
    scope.setContext(key, value)
  })
}

export function setTagInSentry(key: string, value: any) {
  Sentry.configureScope(scope => {
    scope.setTag(key, value)
  })
}

export function sendExceptionToSentry(appError?: Error, restError?: IRestError, errorInfo?: any) {
  // Don't send 401 to sentry
  if (restError && restError.status === 401) {
    return
  }

  const error = appError ? appError : restError
  if (!error) {
    console.warn(
      `Trying to send exception but there is no error!`,
      JSON.stringify({ appError, restError, errorInfo })
    )
    return
  }

  // Send to sentry
  Sentry.withScope(scope => {
    if (errorInfo) {
      Object.keys(errorInfo).forEach(key => scope.setExtra(key, errorInfo[key]))
      scope.setExtra('source', 'sendExceptionToSentry')
    }

    console.error(`[SENTRY]`, error, errorInfo)
    if (error.message) {
      Sentry.captureException(error.message)
    } else {
      Sentry.captureException(error)
    }
  })
}

export function sendMessageToSentry(message: string, messageInfo?: any, user?: any) {
  Sentry.withScope(scope => {
    if (messageInfo) {
      Object.keys(messageInfo).forEach(key => scope.setExtra(key, messageInfo[key]))
      scope.setExtra('source', 'sendMessageToSentry')
    }

    if (user) {
      scope.setUser(user)
    }

    Sentry.captureMessage(message)
  })
}
