import React from 'react'
import { I18n } from 'react-redux-i18n'

import NotificationItem from './notificationItem/NotificationItem'

import { useAnalytics } from '../../services/AnalyticsWrapper/AnalyticsWrapper'
import { IRouteNotification } from '../../services/TeqplayAPIService/TeqplayApi'
import './NotificationMonitor.scss'

interface IProps {
  notifications: IRouteNotification[] | null
  VDJSNotificationMessage: string | null
  channelApproachNotificationMessage: string | null
}

const NotifcationMonitor: React.FunctionComponent<IProps> = props => {
  const { VDJSNotificationMessage, channelApproachNotificationMessage, notifications } = props
  const analytics = useAnalytics('NotifcationMonitor')

  React.useEffect(() => {
    analytics.newEvent('open_notification_monitor', {
      notificationAmount: notifications?.length,
      VDJSNotificationMessage,
      channelApproachNotificationMessage
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="notifications-wrapper" id="notification-monitor">
      <div className="notification-list">
        <div className="list-header">
          <h4>{I18n.t('map.notification.title')}</h4>
        </div>
        <ul>
          {notifications?.map((notification, index) => (
            <NotificationItem
              notification={notification}
              key={notification._id || notification.identifier || index}
            />
          ))}

          {VDJSNotificationMessage && (
            <div
              className="notification-wrapper-item blue"
              id="notification-varen-doe-je-samen-message"
            >
              <div className="notification-title" id="notification-title">
                <span className="icon-attention blue" />
                <div className="title">{VDJSNotificationMessage}</div>
              </div>
            </div>
          )}

          {channelApproachNotificationMessage && (
            <div className="notification-wrapper-item" id="notification-channel-approach">
              <div className="notification-title" id="notification-title">
                <span className="icon-attention" />
                <div className="title">{channelApproachNotificationMessage}</div>
              </div>
            </div>
          )}

          {(!notifications || notifications.length === 0) && !VDJSNotificationMessage ? (
            <div className="notification-wrapper-item" id="notification-no-notifications">
              {I18n.t('navigationPage.no_notifications')}
            </div>
          ) : null}
        </ul>
      </div>
    </div>
  )
}

export default NotifcationMonitor
