import * as React from 'react'
import { formatDistanceToNow } from 'date-fns'
import { divIcon } from 'leaflet'
import { Marker, Popup } from 'react-leaflet'
import { I18n } from 'react-redux-i18n'
import { IShipInfo } from '../../../services/TeqplayAPIService/TeqplayApi'
import { SMALL_SHIP_SIZE } from '../../../utils/constants'
import { labelAndValue } from '../../../utils/layers'
import { getShipLength, getShipWidth } from '../../../utils/ship'
import ContourShipMarker from './ContourShipMarker'

interface IProps {
  ship: IShipInfo
  zoomLevel: number
}

const ShipMarker: React.FunctionComponent<IProps> = props => {
  const ship = props.ship
  const shipHeading = ship.calculatedHeading
    ? ship.calculatedHeading
    : ship.trueHeading > 0 && ship.trueHeading <= 360
    ? ship.trueHeading
    : ship.courseOverGround
  const shipLength = getShipLength(ship)
  const isRecreational = isRecreationalVessel(ship)

  const shipDivIcon = divIcon({
    className: '',
    html: `<div class='default-ship-icon ${
      isRecreational ? 'default' : 'orange large'
    }' style="transform: rotate(${
      shipHeading + 'deg'
    }"><span class=${'icon-user-location'}></span></div>`,
    iconAnchor: shipLength <= SMALL_SHIP_SIZE ? [10, 10] : [17, 17] // if the ship has the large class, it is 35x35 else its 20x20 ( anchor is at the 50% mark of these)
  })

  const shipLocation =
    ship.location.latitude && ship.location.longitude
      ? { latitude: ship.location.latitude, longitude: ship.location.longitude }
      : { latitude: ship.location.coordinates[1], longitude: ship.location.coordinates[0] }

  if (!shipLocation.latitude || !shipLocation.longitude) {
    return null
  }

  const popupElement = (
    <Popup autoPan={false}>
      <div className="popup" id="ship-marker-popup">
        {labelAndValue(
          I18n.t('mapLayers.shipMarker.width'),
          getShipWidth(ship),
          I18n.t('mapLayers.meters')
        )}
        {labelAndValue(
          I18n.t('mapLayers.shipMarker.length'),
          shipLength,
          I18n.t('mapLayers.meters')
        )}
        {labelAndValue(I18n.t('mapLayers.shipMarker.heading'), shipHeading)}
        {labelAndValue(
          I18n.t('mapLayers.last_updated'),
          `${formatDistanceToNow(ship.timeLastUpdate)} ${I18n.t('mapLayers.past')}`
        )}
      </div>
    </Popup>
  )

  if (
    shipLength < 500 &&
    props.zoomLevel >= 16 &&
    ship.positionOfTransponder &&
    (ship.trueHeading !== 511 || ship.calculatedHeading) &&
    ship.shipType !== 'BASESTATION'
  ) {
    return (
      <ContourShipMarker
        key={'contourShip' + ship.mmsi}
        ship={ship}
        location={shipLocation}
        isRecreational={isRecreational}
      >
        {popupElement}
      </ContourShipMarker>
    )
  } else {
    return (
      <Marker
        position={[shipLocation.latitude, shipLocation.longitude]}
        icon={shipDivIcon}
        key={'shipMarker' + ship.imoNumber}
      >
        {popupElement}
      </Marker>
    )
  }
}

export default ShipMarker

function isRecreationalVessel(ship: IShipInfo) {
  const recreationalClasses = [
    'OTHER_TYPE',
    'LAW_ENFORCEMENT_VESSEL',
    'FISHING',
    'MOTOR_YACHT',
    'SPEEDBOAT',
    'SAILING_WITH_MOTOR',
    ' SPORT_FISHERMEN_VESSEL',
    'SAILING_MOTOR_BIG',
    'OTHER_RECREATIONAL_VESSEL',
    'SAILING',
    'PLEASURE_CRAFT',
    'NOT_AVAILABLE',
    'SPARE',
    'UNDEFINED',
    'BASESTATION'
  ]

  if (recreationalClasses.includes(ship.shipType)) {
    return true
  } else {
    return false
  }
}
