import React from 'react'

import './ValidationBox.scss'

interface IProps {
  invalidName: string
  validName: string
  value: boolean
  subTitle?: string
  optional?: boolean
}

const ValidationBox = (props: IProps) => {
  const { validName, invalidName, value, optional, subTitle } = props

  return (
    <div className={`validation-box${value === true ? ' valid' : optional ? '' : ' invalid'}`}>
      <div className="bubble-validation">
        <i className={value ? 'icon-check' : optional ? 'icon-circle' : 'icon-attention'} />
      </div>
      <div className="name">
        {value ? validName : invalidName}
        {subTitle && <div className="subtitle">{subTitle}</div>}
      </div>
    </div>
  )
}

export default ValidationBox
