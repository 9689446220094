import React, { useState } from 'react'
import Modal from 'react-modal'
import { useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { useHistory } from 'react-router'

import { useLocalStorage } from '../../../hooks/useLocalStorage'
import bicsIMG_NL from './bics-feature-nl.png'
import bicsIMG_EN from './bics-feature-gb.png'
import bicsIMG_FR from './bics-feature-fr.png'
import { IRootProps } from '../../../@types/types'
import './BICSNewFeature.scss'

function BICSNewFeature() {
  const [hideMSG, setHideMSG] = useLocalStorage<boolean>('bics-new-feature-v1', false)
  const [show, setShow] = useState<boolean>(!hideMSG)
  const locale = useSelector((s: IRootProps) => s.i18n.locale)
  const history = useHistory()

  const image =
    locale === 'en_GB'
      ? bicsIMG_EN
      : locale === 'fr_FR'
      ? bicsIMG_FR
      : locale === 'nl_NL'
      ? bicsIMG_NL
      : bicsIMG_EN

  if (!show) {
    return null
  }

  return (
    <Modal
      isOpen={show}
      className="bics-new-feature modal"
      style={{ overlay: { backgroundColor: 'rgba(0,0,0,0.25)' } }}
      shouldCloseOnOverlayClick={false}
    >
      <h3>{I18n.t('bicsFeature.title')}</h3>
      <img src={image} alt={I18n.t('bicsFeature.title')} id="bics-feature-image" />
      <p>{I18n.t('bicsFeature.description')}</p>
      <div className="checkbox-container">
        <input type="checkbox" onChange={e => setHideMSG(e.target.checked)} />
        {I18n.t('bicsFeature.dontShowAgain')}
      </div>
      <div className="options">
        <button onClick={() => setShow(false)} className="button large">
          {I18n.t('bicsFeature.ok')}
        </button>
        <button
          onClick={() => {
            setShow(false)
            history.push('/announcements')
          }}
          className="button primary large"
        >
          {I18n.t('bicsFeature.show')}
        </button>
      </div>
    </Modal>
  )
}

export default BICSNewFeature
