import React, { useState } from 'react'
import { I18n } from 'react-redux-i18n'
import { toast } from 'react-toastify'

import { useAnalytics } from '../../services/AnalyticsWrapper/AnalyticsWrapper'
import TeqplayApiService from '../../services/TeqplayAPIService/TeqplayApiService'
import ValidationBox from './ValidationBox'
import PasswordInput from '../../components/passwordInput/passwordInput'

interface IProps {
  username: string
  teqplayAPIService: TeqplayApiService
}

const ChangePasswordForm = ({ username, teqplayAPIService }: IProps) => {
  const analytics = useAnalytics('ChangePasswordForm')
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<string | undefined>()

  const [currentPassword, setPassword] = useState<string>('')
  const [newPassword, setNewPassword] = useState<string>('')
  const [confirmNewPassword, setConfirmNewPassword] = useState<string>('')

  const validation = {
    match: newPassword !== '' && newPassword === confirmNewPassword,
    length: newPassword.length >= 8,

    containsLower: /[a-z]/.test(newPassword),
    containsUpper: /[A-Z]/.test(newPassword),
    containsNumeric: /[0-9]/.test(newPassword),
    // eslint-disable-next-line
    containsSpecial: /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(newPassword)
  }

  return (
    <form className="password-form" onSubmit={handlePasswordFormSubmit}>
      <div className="password-change-wrapper page-block">
        <div className="form-row">
          <h3>{I18n.t('settings.changePassword')}</h3>
        </div>
        <div className="form-row dimension-row">
          <div className="dimension-column">
            <label className="label" htmlFor={'password'}>
              {I18n.t('settings.currentPassword')}
            </label>
            <PasswordInput
              password={currentPassword}
              setPassword={setPassword}
              className="textfield"
            />
          </div>
          {error === I18n.t('settings.wrongPassword') && (
            <span className="error-message">{I18n.t('settings.wrongPassword')}</span>
          )}
        </div>
        <div className="form-row dimension-row">
          <div className="dimension-column">
            <label className="label" htmlFor={'password'}>
              {I18n.t('settings.newPassword')}
            </label>
            <PasswordInput
              password={newPassword}
              setPassword={setNewPassword}
              className="textfield"
            />
          </div>
        </div>
        <div className="form-row dimension-row">
          <div className="dimension-column">
            <label className="label" htmlFor={'repeatNewPassword'}>
              {I18n.t('settings.repeatNewPassword')}
            </label>
            <PasswordInput
              password={confirmNewPassword}
              setPassword={setConfirmNewPassword}
              className="textfield"
            />
          </div>
          {error === I18n.t('settings.newPasswordNoMatch') && (
            <span className="error-message">{I18n.t('settings.newPasswordNoMatch')}</span>
          )}
          {error === I18n.t('settings.fillInAllFields') && (
            <span className="error-message">{I18n.t('settings.fillInAllFields')}</span>
          )}
          {error === I18n.t('settings.weakPassword') && (
            <span className="error-message">{I18n.t('settings.weakPassword')}</span>
          )}
        </div>
        {currentPassword.length > 0 && newPassword.length > 0 && confirmNewPassword.length > 0 && (
          <React.Fragment>
            <div className="password-validation">
              <div className="requirements">
                <div className="title">{I18n.t('settings.passwordTips.requirements')}</div>
                <div className="info-box">{I18n.t('settings.passwordTips.requirementsInfo')}</div>
                <ValidationBox
                  validName={I18n.t('settings.passwordTips.match')}
                  invalidName={I18n.t('settings.passwordTips.noMatch')}
                  value={validation.match}
                />
                <ValidationBox
                  validName={I18n.t('settings.passwordTips.tipsLength')}
                  invalidName={I18n.t('settings.passwordTips.tipsLength')}
                  value={validation.length}
                />
                <ValidationBox
                  validName={I18n.t('settings.passwordTips.tipsCasing')}
                  invalidName={I18n.t('settings.passwordTips.tipsCasing')}
                  value={validation.containsUpper && validation.containsLower}
                />
                <ValidationBox
                  validName={I18n.t('settings.passwordTips.tipsNumber')}
                  invalidName={I18n.t('settings.passwordTips.tipsNumber')}
                  value={validation.containsNumeric}
                />
                <ValidationBox
                  validName={I18n.t('settings.passwordTips.tipsSpecialCharacters')}
                  invalidName={I18n.t('settings.passwordTips.tipsSpecialCharacters')}
                  value={validation.containsSpecial}
                />
              </div>
              <div className="recommendations">
                <div className="title">{I18n.t('settings.passwordTips.tips')}</div>
                <div className="info-box">{I18n.t('settings.passwordTips.tipsInfo')}</div>
                <ValidationBox
                  validName={I18n.t('settings.passwordTips.tipsStoreSafely')}
                  invalidName={I18n.t('settings.passwordTips.tipsStoreSafely')}
                  subTitle={I18n.t('settings.passwordTips.tipsStoreSafelySubtitle')}
                  value={false}
                  optional={true}
                />
                <ValidationBox
                  validName={I18n.t('settings.passwordTips.tipsUnique')}
                  invalidName={I18n.t('settings.passwordTips.tipsUnique')}
                  subTitle={I18n.t('settings.passwordTips.tipsUniqueSubtitle')}
                  value={false}
                  optional={true}
                />
                <ValidationBox
                  validName={I18n.t('settings.passwordTips.tipsNoPersonalInfo')}
                  invalidName={I18n.t('settings.passwordTips.tipsNoPersonalInfo')}
                  value={false}
                  optional={true}
                />
              </div>
            </div>
            {validation.length &&
              validation.containsUpper &&
              validation.containsLower &&
              validation.containsNumeric &&
              validation.containsSpecial && (
                <div className="successful">{I18n.t('settings.passwordTips.allMet')}</div>
              )}
          </React.Fragment>
        )}
        <div className="profile-buttons">
          <div className="buttons">
            <button type="submit" className="button primary large">
              {loading ? <i className="icon-circle-notch animate-spin" /> : I18n.t('settings.save')}
            </button>
          </div>
        </div>
      </div>
    </form>
  )

  async function handlePasswordFormSubmit(e: React.ChangeEvent<any>) {
    e.preventDefault()
    setLoading(true)

    if (!currentPassword || !newPassword || !confirmNewPassword) {
      analytics.newEvent('settings_password_change_error', { type: 'fillInAllFields' })
      setError(I18n.t('settings.fillInAllFields'))
      toast.error(I18n.t('settings.fillInAllFields'))
      setLoading(false)
      return
    }

    if (newPassword !== confirmNewPassword) {
      analytics.newEvent('settings_password_change_error', { type: 'newPasswordNoMatch' })
      setError(I18n.t('settings.newPasswordNoMatch'))
      toast.error(I18n.t('settings.newPasswordNoMatch'))
      setLoading(false)
      return
    }

    setError(undefined)

    try {
      await teqplayAPIService.updatePassword({
        username,
        currentPassword,
        newPassword
      })
      toast.success(I18n.t('settings.updatedPasswordSuccess'))
      setPassword('')
      setNewPassword('')
      setConfirmNewPassword('')
    } catch (err) {
      if (err.status === 400) {
        setError(I18n.t('settings.weakPassword'))
      }
      if (err.status === 401) {
        setError(I18n.t('settings.wrongPassword'))
      }
    } finally {
      setLoading(false)
    }
  }
}

export default ChangePasswordForm
