import { Dispatch, SetStateAction, useState } from 'react'
import { appPrefixedStorageKey } from '../utils/localStorage'

/**
 * @example
 * const [from, setFrom] = useSessionStorage<string>(
 *    'DATE_FROM',
 *    new Date().toISOString(),
 *    true
 * )
 * @param key
 * @param initialValue
 * @param forceReset
 */
export function useSessionStorage<T>(
  key: string,
  initialValue: T,
  forceReset?: boolean
): [T, Dispatch<SetStateAction<T>>] {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState<T>(() => {
    try {
      // Get from session storage by key
      const item = window.sessionStorage.getItem(appPrefixedStorageKey(key))
      // Parse stored json or if none return initialValue
      if (forceReset) {
        window.sessionStorage.removeItem(appPrefixedStorageKey(key))
      }
      return item && forceReset !== true ? JSON.parse(item) : initialValue
    } catch (error) {
      // If error also return initialValue
      console.error(error)
      return initialValue
    }
  })

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to sessionStorage.
  const setValue = (value: T | SetStateAction<T>): void => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore = value instanceof Function ? value(storedValue) : value
      // Save state
      setStoredValue(valueToStore)
      // Save to session storage
      if (value === undefined) {
        window.sessionStorage.removeItem(appPrefixedStorageKey(key))
      } else {
        window.sessionStorage.setItem(appPrefixedStorageKey(key), JSON.stringify(valueToStore))
      }
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.error(error)
    }
  }

  return [storedValue, setValue]
}
