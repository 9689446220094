import * as turf from '@turf/helpers'
import lineIntersect from '@turf/line-intersect'
import { distanceTo, moveTo } from 'geolocation-utils'
import cloneDeep from 'lodash/cloneDeep'
import {
  IRoute,
  IRouteEtaUpdate,
  IRouteItem,
  ISelectedRoute,
  IShipInfo
} from '../services/TeqplayAPIService/TeqplayApi'

export function mergeTimeUpdatesInRoute(etaObject: IRouteEtaUpdate, route: IRoute): IRoute {
  let updatedRouteItems = cloneDeep(route.routeItems)

  updatedRouteItems = updatedRouteItems.map(item => {
    // Update eta
    item.eta = etaObject.eta[item._id]

    // Update distance
    item.distance = etaObject.distance[item._id]

    // Update waitingTime
    item.waitingTime = etaObject.waitingTime[item._id]

    // Clear any frontend hasPassed if there is no ETA anymore as cleanup
    item.hasPassedFrontend = etaObject.eta[item._id] ? item.hasPassedFrontend : undefined

    return item
  })

  // merge current route with the changed route items
  return { ...route, routeItems: updatedRouteItems, distanceToRoute: etaObject.distanceToRoute }
}

export function mergeTimeUpdatesInSelectedRoute(
  etaObject: IRouteEtaUpdate,
  selectedRoute: ISelectedRoute
): ISelectedRoute {
  const updatedRoute = mergeTimeUpdatesInRoute(etaObject, selectedRoute.route)

  return {
    ...selectedRoute,
    route: updatedRoute,
    paused: etaObject.paused,
    distanceToRoute: etaObject.distanceToRoute
  }
}

/**
 * Adds distance to route + distance from nearest point and route onwards
 * @param route
 * @returns {Number} in meters
 */
export function getDistanceLeftOnRoute(route: ISelectedRoute) {
  const nextItemIndex = route.route.routeItems.findIndex(i => !!i.eta)
  const relevantDistances = route.route.routeItems
    .slice(nextItemIndex)
    .map(i => i.distance)
    .filter(d => d && d >= 0)

  const correctedDistances = relevantDistances.map(d => (d || 0) - (relevantDistances[0] || 0))
  return correctedDistances[correctedDistances.length - 1] + route.distanceToRoute
}

export function getRouteDepth(route: IRoute) {
  const measuredDepth = route.maxDimensions?.measuredDepth
  const minimumDepth = route.maxDimensions?.minimumDepth

  if (measuredDepth && minimumDepth) {
    return Math.min(measuredDepth, minimumDepth)
  }

  if (measuredDepth) {
    return measuredDepth
  }

  if (minimumDepth) {
    return minimumDepth
  }

  return 0
}

export function calculateDirectDistanceToObject(routeItem: IRouteItem, location: IShipInfo | null) {
  if (!location) {
    return null
  } else {
    return distanceTo(
      [location.location?.latitude, location.location?.longitude],
      [routeItem.singleLocation.coordinates[1], routeItem.singleLocation.coordinates[0]]
    )
  }
}

export function formatLengthDistance(d: number | null) {
  if (d) {
    if (d > 1000) {
      return Math.round(d / 1000) + 'km'
    } else {
      return Math.round(d) + 'm'
    }
  } else {
    return ''
  }
}

export function getNextVhfChannel(navigationRoute: ISelectedRoute) {
  let nextVHFChannel = null

  if (navigationRoute && navigationRoute.route.routeItems) {
    for (const routeItem of navigationRoute.route.routeItems) {
      if (routeItem.eta && routeItem.vhfChannel && routeItem.type === 'VHFSECTOR') {
        nextVHFChannel = routeItem.vhfChannel
        break
      }
    }
  }

  return nextVHFChannel
}

export function checkIfPassedBridge(
  location: { latitude: number; longitude: number },
  perpendicularHeading: number,
  previousPositions: [number, number][],
  width = 50
): boolean {
  // Not every bridge has a rotation defined,
  // return false and wait until backend updates ETA
  if (perpendicularHeading) {
    const normalisedHeading = getNormalisedRWSBridgeRotation(perpendicularHeading)
    const leftPosition = moveTo(location, { distance: 180 + width, heading: normalisedHeading })
    const rightPosition = moveTo(location, {
      distance: 180 + width,
      heading: (normalisedHeading + 180) % 360
    })

    const locationsString = turf.lineString(previousPositions)
    const positionsString = turf.lineString([
      [leftPosition.latitude, leftPosition.longitude],
      [rightPosition.latitude, rightPosition.longitude]
    ])

    const intersects = lineIntersect(locationsString, positionsString)

    return intersects.features.length > 0
  } else {
    return false
  }
}

export function getNormalisedRWSBridgeRotation(rotation: number) {
  return (-1 * rotation + 360 + 180) % 360
}
