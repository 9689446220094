import * as React from 'react'
import { ISABPoint } from '../../services/TeqplayAPIService/TeqplayApi'
import PhoneNumberButton from '../phoneNumberButton/PhoneNumberButton'

interface IProps {
  itemDetails: ISABPoint | null
  isExpanded: boolean
}

const SABPointDetails = ({ itemDetails, isExpanded }: IProps) => {
  if (!itemDetails || isExpanded === false) {
    return null
  }

  return (
    <div className="item-info-details" id={`sab-details-${itemDetails.name}`}>
      <div className="notification-inner">
        <section className="details-section">
          <div className="route-item-caption" id="sab-details-title">
            <b>{itemDetails.name}</b>
          </div>

          {itemDetails.note && (
            <div className="route-item-details-item" id="sab-details-note">
              {itemDetails.note}
            </div>
          )}
          {itemDetails.telephoneNumber && (
            <div className="route-item-details-item" id="sab-details-telephoneNumber">
              <PhoneNumberButton number={itemDetails.telephoneNumber} />
            </div>
          )}
          {itemDetails.mobileNumber && (
            <div className="route-item-details-item" id="sab-details-mobileNumber">
              <PhoneNumberButton number={itemDetails.mobileNumber} />
            </div>
          )}
        </section>
      </div>
    </div>
  )
}

export default SABPointDetails
