import { moveTo, toLatLng } from 'geolocation-utils'
import * as React from 'react'
import { Polygon } from 'react-leaflet'
import { IShipInfo, LatLng } from '../../../services/TeqplayAPIService/TeqplayApi'

interface IProps {
  ship: IShipInfo
  location: LatLng
  isRecreational: boolean
  children?: React.ReactNode
}

const ContourShipMarker = ({ ship, location, isRecreational, children }: IProps) => {
  const shipHeading = ship.calculatedHeading
    ? ship.calculatedHeading
    : ship.trueHeading > 0 && ship.trueHeading <= 360
    ? ship.trueHeading
    : ship.courseOverGround
  const polygonPositions = getPolygonPositions(ship, location, shipHeading)

  const polygons = polygonPositions.map(polygon => {
    return polygon.map(position => {
      const polygonPosition = { lat: position.latitude, lng: position.longitude }
      return polygonPosition
    })
  })

  return (
    <Polygon
      key={`polygon-${ship.mmsi}`}
      positions={polygons}
      className="traffic-marker-contour"
      fill={true}
      fillColor={isRecreational ? '#197ed4' : 'rgb(238, 156, 32)'} // uses the color orange from the variables.scss file or the color-highlight depending on the ship size
      fillOpacity={1.0}
      stroke={true}
      weight={1}
      color={'#fff'}
    >
      {children}
    </Polygon>
  )

  function getPolygonPositions(s: IShipInfo, center: LatLng, orientation: number) {
    // Set the ship dimensions, and if there are none fall back to a preset
    const distanceToStern =
      s.positionOfTransponder &&
      s.positionOfTransponder.distanceToStern &&
      s.positionOfTransponder.distanceToStern > 0 &&
      s.positionOfTransponder.distanceToBow &&
      s.positionOfTransponder.distanceToBow > 0
        ? s.positionOfTransponder.distanceToStern
        : s.dimensions && s.dimensions.length > 0
        ? s.dimensions.length / 2
        : 10

    const distanceToBow =
      s.positionOfTransponder &&
      s.positionOfTransponder.distanceToStern &&
      s.positionOfTransponder.distanceToStern > 0 &&
      s.positionOfTransponder.distanceToBow &&
      s.positionOfTransponder.distanceToBow > 0
        ? s.positionOfTransponder.distanceToBow
        : s.dimensions && s.dimensions.length > 0
        ? s.dimensions.length / 2
        : 10

    const width = getShipWidth(s)
    const bowLength = ((width < 8 ? 8 : width) / 2) * Math.SQRT2

    const stern = moveTo(center != null ? center : toLatLng(s.location.coordinates as any), {
      distance: distanceToStern,
      heading: orientation - 180
    })

    const bow = moveTo(center != null ? center : toLatLng(s.location.coordinates as any), {
      distance: distanceToBow,
      heading: orientation
    })

    const bowLeft = moveTo(bow, { distance: bowLength, heading: orientation - 90 - 45 })
    const bowRight = moveTo(bow, { distance: bowLength, heading: orientation + 90 + 45 })
    const sternLeft = moveTo(stern, {
      distance: (width < 8 ? 8 : width) / 2,
      heading: orientation - 90
    })
    const sternRight = moveTo(stern, {
      distance: (width < 8 ? 8 : width) / 2,
      heading: orientation + 90
    })

    return [[bow, bowRight, sternRight, sternLeft, bowLeft]]
  }

  function getShipWidth(s: IShipInfo) {
    if (s.positionOfTransponder) {
      return s.positionOfTransponder.distanceToStarboard + s.positionOfTransponder.distanceToPort
    } else if (s.dimensions && s.dimensions.width) {
      return s.dimensions.width
    } else {
      return -1
    }
  }
}

export default ContourShipMarker
