import { formatDistanceStrict } from 'date-fns'
import isEqual from 'lodash/isEqual'
import * as React from 'react'
import { I18n, Translate } from 'react-redux-i18n'
import Slider from 'react-slick'
import ReactTooltip from 'react-tooltip'

import { IRoute } from '../../../../services/TeqplayAPIService/TeqplayApi'
import { formatDateTimeShort, formatDurationIntoHoursAndMinutes } from '../../../../utils/dates'

import './RouteOptionsList.scss'

interface IProps {
  routeSuggestions: IRoute[] | null
  listViewActive: boolean
  selectedRouteSuggestion: IRoute | null
  setSelectedRouteSuggestion: (routeSuggestion: IRoute | null) => void
  toggleWindowOpened: () => void
  activeScreen: 'INFO' | 'ROUTE'
  handleSwitchScreens: (screen: IProps['activeScreen']) => void
}

class RouteOptionsList extends React.PureComponent<IProps> {
  public slider: Slider | null = null

  public componentDidUpdate(prevProps: IProps) {
    if (
      !isEqual(prevProps.selectedRouteSuggestion, this.props.selectedRouteSuggestion) &&
      this.slider
    ) {
      this.setSelectedSlide()
    }
  }

  public render() {
    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      className: 'center',
      centerMode: true,
      centerPadding: '60px',
      responsive: [
        {
          breakpoint: 900,
          settings: {
            centerPadding: '100px'
          }
        },
        {
          breakpoint: 600,
          settings: {
            centerPadding: '60px'
          }
        }
      ]
    }

    if (!this.props.routeSuggestions || this.props.routeSuggestions === null) {
      return null
    }

    return (
      <div className="route-options-list">
        <Slider
          {...settings}
          ref={slider => (this.slider = slider)}
          afterChange={index =>
            this.props.setSelectedRouteSuggestion(
              this.props.routeSuggestions && this.props.routeSuggestions[index]
            )
          }
          focusOnSelect={true}
        >
          {this.props.routeSuggestions.map((routeSuggestion, index) => {
            const routeNotAccessible =
              !routeSuggestion.canPassBridges || !routeSuggestion.shipAllowed
            return (
              <div
                className={`route-option-wrapper ${
                  routeNotAccessible ? 'option-not-accessible' : ''
                }`}
                key={'route-option' + index}
                id={`route-option-${index + 1}`}
              >
                <div className={'route-option'}>
                  <h4 className="option-title">Route {index + 1}</h4>

                  <div className="option-details">
                    <h3>{routeSuggestion.distance.toFixed(0)}km</h3>
                    {routeSuggestion.startTime && (
                      <div className="suggestion-row" id="route-selection-departure-time">
                        <span>
                          <Translate
                            value="routeSelection.departureTime"
                            className="property-title"
                          />
                        </span>
                        <div className="row">
                          <span className="departure-time">
                            {formatDateTimeShort(routeSuggestion.startTime)}
                          </span>
                        </div>
                      </div>
                    )}
                    <div className="suggestion-row" id="route-selection-arrival-time">
                      <span>
                        <Translate value="routeSelection.arrivalTime" className="property-title" />
                      </span>
                      <div className="row">
                        <span className="absolute-eta">
                          {formatDateTimeShort(routeSuggestion.eta)}
                        </span>
                        {!routeSuggestion.startTime && (
                          <span className="relative-eta">
                            (
                            {I18n.t('routeSelection.over', {
                              time: formatDistanceStrict(routeSuggestion.eta, new Date())
                            })}
                            )
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="suggestion-row" id="route-selection-waiting-time">
                      <span>
                        <Translate value="routeSelection.waitingTime" className="property-title" />
                      </span>
                      <div>
                        {formatDurationIntoHoursAndMinutes(routeSuggestion.waitingTime) ||
                          0 + ' min'}
                      </div>
                    </div>
                  </div>

                  {routeNotAccessible && (
                    <React.Fragment>
                      <span
                        className="not-allowed icon-attention"
                        data-tip
                        data-for="shipNotAllowed"
                      />
                      <ReactTooltip
                        id="shipNotAllowed"
                        place="bottom"
                        effect="solid"
                        offset={{ left: 100, bottom: -10 }}
                        className="warning-tooltip"
                      >
                        {routeSuggestion.shipAllowed ? (
                          <Translate value="routeSelection.notAllowedBridge" />
                        ) : (
                          <Translate value="routeSelection.notAllowedDimensions" />
                        )}
                      </ReactTooltip>
                    </React.Fragment>
                  )}
                </div>
                <div className="list-buttons">
                  {this.props.listViewActive && (
                    <div
                      className="single-button"
                      onClick={() =>
                        this.props.handleSwitchScreens(
                          this.props.activeScreen === 'INFO' ? 'ROUTE' : 'INFO'
                        )
                      }
                      id="route-option-button-switch"
                    >
                      {this.props.activeScreen === 'INFO' ? (
                        <span>
                          <i className="icon-list-bullet" /> {I18n.t('routeSelection.list')}
                        </span>
                      ) : (
                        <span>
                          <i className="icon-info-1" /> {I18n.t('routeSelection.details')}
                        </span>
                      )}
                    </div>
                  )}
                  <div
                    className="single-button"
                    onClick={this.props.toggleWindowOpened}
                    id="route-option-button-toggle"
                  >
                    <i
                      className={this.props.listViewActive ? 'icon-angle-up' : 'icon-angle-down'}
                    />
                    <span>
                      {this.props.listViewActive
                        ? I18n.t('routeSelection.closeInfo')
                        : I18n.t('routeSelection.openInfo')}
                    </span>
                  </div>
                </div>
              </div>
            )
          })}
        </Slider>
      </div>
    )
  }

  public setSelectedSlide = () => {
    const selectedRoute = this.props.selectedRouteSuggestion
    const initialSlide =
      selectedRoute && selectedRoute._id && this.props.routeSuggestions
        ? this.props.routeSuggestions.findIndex(x => x._id === selectedRoute._id)
        : undefined

    if (initialSlide !== undefined && this.slider) {
      this.slider.slickGoTo(initialSlide)
    }
  }
}

export default RouteOptionsList
