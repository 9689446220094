import { IShipInfo } from '../services/TeqplayAPIService/TeqplayApi'
import { setExtraValueInSentry } from '../utils/sentry'

export function setCurrentLocation(currentLocation: IShipInfo | null) {
  setExtraValueInSentry('ship', currentLocation)
  return {
    type: 'SET_CURRENT_LOCATION',
    currentLocation
  }
}
