import { divIcon as DIVIcon, LatLngBounds } from 'leaflet'
import * as React from 'react'
import { I18n } from 'react-redux-i18n'

import TeqplayApiService from '../../../../../services/TeqplayAPIService/TeqplayApiService'
import CollapsableLegendComponent from '../../../controls/CollapsableLegendComponent'
import DefaultMarkersLayer from '../defaultMarkerLayer/DefaultMarkerLayer'

import { IShorePower } from '../../../../../services/TeqplayAPIService/TeqplayApi'

interface IProps {
  name: string
  checked: boolean
  bounds?: LatLngBounds
  teqplayApiService: TeqplayApiService
  mapZoomLevel: number
  minimumZoomLevel: number
  setSelectedItem: (item: IShorePower) => void
  disableInteraction?: boolean
}

const ShorePowerLayer: React.FunctionComponent<IProps> = props => {
  if (!props.checked) {
    return null
  }

  const color = '#2e6771'

  return (
    <React.Fragment>
      <DefaultMarkersLayer<IShorePower>
        fetchCall={(bounds?: LatLngBounds) => props.teqplayApiService.fetchShorePower(bounds)}
        determineDivIcon={() =>
          DIVIcon({
            className: '',
            html: `<div class="div-icon-marker-layer" style="background: ${color};
            color:${'#fff'}"><i class="icon-plug"/>`,
            iconAnchor: [20, 20],
            iconSize: [40, 40]
          })
        }
        onClick={props.disableInteraction ? undefined : props.setSelectedItem}
        {...props}
      />
      <CollapsableLegendComponent
        icon={<span className="icon-plug" />}
        position={'topright'}
        id={props.name}
      >
        <div className="item-legend">
          <div className="name">{I18n.t('mapLayers.shorePower.point')}</div>
          <div className="icon">
            <i className="icon-plug" />
          </div>
        </div>
      </CollapsableLegendComponent>
    </React.Fragment>
  )
}

export default ShorePowerLayer
