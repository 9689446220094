import React, { useEffect, useState } from 'react'
import { I18n } from 'react-redux-i18n'
import { useHistory } from 'react-router'
import { toast } from 'react-toastify'

import AddressButton from './AddressButton'
import BICSBillingInfoModal from '../bicsBillingInfoModal/BICSBillingInfoModal'

import { BicsAddress, BicsHull } from '../../../services/TeqplayAPIService/TeqplayApi'
import {
  areAddressesEqual,
  filterUniqueAddresses,
  convertAddressToComparableString,
  mapAddressToAddressString
} from '../../../utils/bics'
import { confirmPopup } from '../../shared/confirmPopup/ConfirmPopup'

import './BICSTravelAddressBar.scss'

interface IProps {
  address: BicsAddress | undefined
  setAddress: (address: BicsAddress | undefined) => void
  mainHull: BicsHull | undefined
  disabled: boolean
  setShowAddressModal: (val: boolean) => void
  previousAddresses: BicsAddress[]
  setPreviousAddresses: (list: BicsAddress[]) => void
}

const BICSTravelAddressBar = ({
  address,
  setAddress,
  mainHull,
  disabled,
  setShowAddressModal,
  previousAddresses,
  setPreviousAddresses
}: IProps) => {
  const history = useHistory()
  const [temporaryAddressValue, setTemporaryAddressValue] = useState<BicsAddress>()
  const [editAddress, setEditAddress] = useState<{ index: number; address: BicsAddress } | null>(
    null
  )

  const hullOwnerAddress = mainHull?.ownerAddress

  useEffect(() => {
    const hasCurrentAddress =
      address &&
      previousAddresses.findIndex(
        a => convertAddressToComparableString(a) === convertAddressToComparableString(address)
      ) !== -1

    if (address && !hasCurrentAddress && !temporaryAddressValue) {
      setTemporaryAddressValue(address)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address])

  if (disabled) {
    return null
  }

  const previousPlusTempAddress = [...previousAddresses, temporaryAddressValue].filter(
    a => a
  ) as BicsAddress[]

  return (
    <>
      {editAddress && (
        <BICSBillingInfoModal
          initialAddress={editAddress.address}
          showModal={true}
          onClose={() => setEditAddress(null)}
          onSubmit={newAddress => handleOnFinalizeEditAddress(newAddress)}
        />
      )}
      <div className="billing-block">
        <div className="preheader-with-buttons address-title">
          <div className="billing-title">
            <h3>{I18n.t('announcements.travel.billingInfo')}</h3>
            <p>
              {I18n.t('announcements.optional')}, {I18n.t('announcements.travel.onlyExecptions')}
            </p>
          </div>
          <div className="right buttons">
            <button
              className="button danger"
              type="button"
              disabled={previousAddresses.length === 0}
              onClick={e => {
                e.preventDefault()
                confirmPopup({
                  message: I18n.t('announcements.travel.deleteAddressesConfirm', {
                    amount: filterUniqueAddresses(
                      address,
                      previousAddresses,
                      false,
                      hullOwnerAddress,
                      true
                    ).length,
                    singularplural:
                      filterUniqueAddresses(
                        address,
                        previousAddresses,
                        false,
                        hullOwnerAddress,
                        true
                      ).length === 1
                        ? I18n.t('announcements.travel.addressSingular')
                        : I18n.t('announcements.travel.addressPlural')
                  }),
                  closeOnOutsideClick: true,
                  onConfirm: () => {
                    setAddress(undefined)
                    setPreviousAddresses([])
                  }
                })
              }}
            >
              <i className="icon-cancel-1" />
              {I18n.t('announcements.travel.removeLocalAddress')}
            </button>
            <button
              className="button primary"
              type="button"
              onClick={e => {
                e.preventDefault()
                setShowAddressModal(true)
              }}
            >
              <div className="name">
                <i className="icon-plus" />
                {I18n.t('announcements.travel.newAddress')}
              </div>
            </button>
          </div>
        </div>

        <div className="address-list">
          <button
            className={`button address-button none ${
              !address ? 'primary' : 'secondary unselected'
            }`}
            type="button"
            id="no-address"
            onClick={() => {
              setAddress(undefined)
              setPreviousAddresses(
                filterUniqueAddresses(
                  address,
                  [...previousAddresses, hullOwnerAddress].filter(a => a) as BicsAddress[]
                )
              )
            }}
          >
            <div className="address-inner">
              <div className="name">{I18n.t('announcements.travel.none')}</div>
            </div>
          </button>

          {address && (
            // Selected address
            <AddressButton
              address={address}
              setAddress={() => setAddress(address)}
              isSelected={true}
              isHullAddress={areAddressesEqual(address, hullOwnerAddress)}
              onEditAddress={() =>
                areAddressesEqual(address, hullOwnerAddress)
                  ? handleEditHullAddress()
                  : setEditAddress({ index: -1, address })
              }
            />
          )}

          {!areAddressesEqual(address, hullOwnerAddress) && hullOwnerAddress && (
            // Hull owner address is always second in the list if it is not selected
            <AddressButton
              address={hullOwnerAddress}
              setAddress={() => setAddress(hullOwnerAddress)}
              isSelected={false}
              isHullAddress={true}
              onEditAddress={() => handleEditHullAddress()}
            />
          )}

          {filterUniqueAddresses(
            address,
            previousPlusTempAddress,
            true,
            hullOwnerAddress,
            true
          ).map(
            // Any other saved addresses can be put last
            (a, i) => (
              <AddressButton
                address={a}
                setAddress={() => setAddress(a)}
                isSelected={false}
                key={i}
                onEditAddress={() => setEditAddress({ index: i, address: a })}
                onDeleteAddress={
                  mapAddressToAddressString(a) === mapAddressToAddressString(temporaryAddressValue)
                    ? undefined
                    : () => handleDeleteAddress(i)
                }
              />
            )
          )}
        </div>

        {!address && !previousPlusTempAddress && (
          <div className="no-address">{I18n.t('announcements.travel.noAddress')}</div>
        )}
      </div>
    </>
  )

  function handleEditHullAddress() {
    // Redirect to relevant hull to edit address for
    history.push(`/announcements/hull/${mainHull?.eni}-${mainHull?.mmsi}`)
  }

  function handleDeleteAddress(index: number) {
    const newList = [...previousAddresses]

    if (index === -1) {
      const matchingIndex = newList.findIndex(
        pa => mapAddressToAddressString(address) === mapAddressToAddressString(pa)
      )
      newList.splice(matchingIndex, 1)
      setPreviousAddresses(newList)
    } else {
      newList.splice(index, 1)
      setPreviousAddresses(newList)
    }
  }

  function handleOnFinalizeEditAddress(newAddress: BicsAddress) {
    // Inject hull information at index position
    if (editAddress && editAddress.index === -1) {
      // Current address being edited, no clue where in array
      // Get the matching index
      const matchingIndex = previousAddresses.findIndex(
        pa => mapAddressToAddressString(pa) === mapAddressToAddressString(editAddress.address)
      )

      if (matchingIndex !== -1) {
        const newList = [...previousAddresses]
        if (
          mapAddressToAddressString(newList[matchingIndex]) === mapAddressToAddressString(address)
        ) {
          // Set as current address as well
          setAddress(newAddress)
          setTemporaryAddressValue(newAddress)
        }

        newList[matchingIndex] = newAddress
        setPreviousAddresses(newList)
      } else {
        // No match, most likely inside travel but not stored locally
        // edit and save locally
        setPreviousAddresses([...previousAddresses, newAddress])
        setTemporaryAddressValue(newAddress)
        setAddress(newAddress)
      }
    } else if (editAddress && editAddress?.index >= 0) {
      const newList = previousAddresses
      newList[editAddress.index] = newAddress
      setPreviousAddresses(newList)
    } else {
      toast.error('An error occurred saving address information')
    }

    setEditAddress(null)
  }
}

export default BICSTravelAddressBar
