import { SupportedLocales } from '../@types/types'
import COUNTRY_LIST from '../assets/countryCodes.json'

export interface ICountry {
  code: string
  name: string
  nameNL?: string
  nameFR?: string
  nameEN?: string
  tags?: string[]
}

/**
 * Get name of the country for which the code is passed
 *
 * @param countryCode Country code which should be same as in countrcode.json file
 * @returns country matching the country code provided
 */
export function getCountryFromCode(countryCode: string): ICountry | undefined {
  return countryCode
    ? COUNTRY_LIST.find(
        country => country.code.toLowerCase().trim() === countryCode.toLowerCase().trim()
      )
    : undefined
}

/**
 * Get code of the country for which the name is passed
 *
 * @param countryName Country name which should be same as in countrcode.json file
 * @returns country matching the country name provided
 */
export function getCountryFromName(countryName: string): ICountry | undefined {
  return countryName
    ? COUNTRY_LIST.find(
        country =>
          (country.nameNL &&
            country.nameNL?.toLowerCase().trim() === countryName.toLowerCase().trim()) ||
          country.name.toLowerCase().trim() === countryName.toLowerCase().trim()
      )
    : undefined
}

export function getCountryName(country: ICountry, locale: SupportedLocales): string {
  switch (locale) {
    case 'fr_FR':
      return country.nameFR || country.name
    case 'en_GB':
      return country.nameEN || country.name
    default:
      return country.nameNL || country.name
  }
}
