import React, { useState, useEffect } from 'react'
import { usePrevious } from '../../hooks/usePrevious'

interface IProps {
  value: number | string | undefined
  setValue: (newValue: number | string | undefined) => void
}

const CustomNumberInput = (props: IProps & React.InputHTMLAttributes<HTMLInputElement>) => {
  const [tempValue, setTempValue] = useState<string | number>()
  const [isValid, setIsValid] = useState<boolean>(false)
  const previousValue = usePrevious(props.value)

  useEffect(() => {
    if (!tempValue && props.value) {
      // In Dutch decimals are shown with a comma instead of a dot
      // Since this is not a numeric input field but a string input field,
      // all dots are replaced with commas
      setTempValue(props.value.toString().split('.').join(','))
      setIsValid(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.value])

  useEffect(() => {
    if (!props.value && previousValue) {
      setTempValue(undefined)
      setIsValid(false)
    } else if (props.value && props.value !== previousValue) {
      setTempValue(props.value.toString().split('.').join(','))
    }
  }, [previousValue, props.value])

  const editedProps = { ...props }
  delete editedProps.setValue

  return (
    <input
      inputMode="decimal"
      {...editedProps}
      value={tempValue}
      onChange={e => handleCheckValidity(e)}
      lang="nl"
      data-valid={isValid}
      data-tempvalue={tempValue}
      data-value={props.value}
    />
  )

  function handleCheckValidity(e: React.ChangeEvent<HTMLInputElement>) {
    e.preventDefault()
    e.stopPropagation()

    const rawInput = e.target.value
    setTempValue(rawInput)

    const commaSeparatedCorrected =
      // Perform a check if there are any comma's in the value (there can only be 1 max)
      // Then check if there are also in the same values no dots, so that comma and dots are not mixed
      rawInput.split(',').length === 2 && rawInput.split('.').length === 1
        ? rawInput.split(',').join('.') // Replace comma with dot for parsing
        : rawInput

    const value = Number(commaSeparatedCorrected)

    if (!rawInput) {
      props.setValue(undefined)
      return
    }

    if (value < 0 || isNaN(value)) {
      setIsValid(false)
      props.setValue(rawInput)
    } else {
      setIsValid(true)
      props.setValue(value)
    }
  }
}

export default CustomNumberInput
