import React from 'react'
import { I18n } from 'react-redux-i18n'

import ShipSpeedBox from './shipSpeedBox/ShipSpeedBox'
import VHFBox from './vhfBox/VHFBox'

import { useAnalytics } from '../../services/AnalyticsWrapper/AnalyticsWrapper'
import {
  IRouteItem,
  ISelectedRoute,
  IShipInfo,
  IShipNotificationStatus
} from '../../services/TeqplayAPIService/TeqplayApi'
import { degreesToCardinals } from '../../utils/general'
import { getIconNameByType } from '../../utils/iconUtils'
import {
  calculateDirectDistanceToObject,
  formatLengthDistance,
  getNextVhfChannel
} from '../../utils/routeUtils'
import { ISpeedState } from '../LocationWatcher/LocationWatcherTypes'

import './NavigationStepDetails.scss'

interface IProps {
  navigationRoute: ISelectedRoute
  speed: ISpeedState
  shipNotificationStatus: IShipNotificationStatus | null
  isRoutePaused: boolean
  userLocation: IShipInfo | null
  toggleOptions: () => void
  optionsActive: boolean
  totalNotificationsCount: number
}

const NavigationStepDetails = (props: IProps) => {
  const {
    navigationRoute,
    userLocation,
    shipNotificationStatus,
    toggleOptions,
    isRoutePaused,
    optionsActive,
    totalNotificationsCount
  } = props
  const analytics = useAnalytics('NavigationStepDetails')

  const nextItem =
    navigationRoute.route.routeItems.find(
      item => !!item.eta && routeItemIsAnObject(item) && !item.hasPassedFrontend
    ) || navigationRoute.route.routeItems[0]
  const vhfChannel = shipNotificationStatus ? shipNotificationStatus.vhfChannel : null
  const nextVhfChannel = getNextVhfChannel(navigationRoute)

  const nextItemDistance = formatLengthDistance(
    calculateDirectDistanceToObject(nextItem, userLocation)
  )

  return (
    <div className="navigation-step" id="navigation-instructions">
      <div className={`attached top ${isRoutePaused ? 'paused' : ''}`}>
        {isRoutePaused && (
          <div className="tab main" id="navigation-instructions-tab-paused">
            <i className="icon-user-location" />
            <span>{I18n.t('navigationPage.route_paused_warning')}</span>
          </div>
        )}
        <VHFBox
          vhfChannel={vhfChannel}
          nextVhfChannel={nextVhfChannel}
          vhfSectorName={shipNotificationStatus?.vhfSectorName}
        />
        {userLocation && userLocation.courseOverGround && userLocation.courseOverGround >= 0 ? (
          <div className="tab secondary" id="navigation-instructions-tab-heading">
            <span className="header-icon icon-user-location" />
            {userLocation?.courseOverGround && userLocation.courseOverGround >= 0
              ? Math.round(userLocation.courseOverGround)
              : 0}
            &deg; {degreesToCardinals(userLocation?.courseOverGround || 0)}
          </div>
        ) : null}
        <ShipSpeedBox userLocation={userLocation} />
      </div>
      <div
        className={`next-step hasTop${isRoutePaused ? ' paused' : ''}${
          optionsActive ? ' optionsActive' : ''
        }`}
        onClick={handleToggleOptions}
      >
        <div className="row" id="navigation-instructions-next-item">
          <div className="icon">
            <i className={`${getIconNameByType(nextItem.type, nextItem)} next-item-icon`} />
            <div className="distance">{nextItemDistance}</div>
          </div>
          <div className="direction">
            <div className="nextItem">{nextItem.name}</div>
          </div>
        </div>
        <div
          className="view-route"
          onClick={() => toggleOptions()}
          id="navigation-instructions-button-toggle-step-by-step"
        >
          <i className={optionsActive ? 'icon-angle-up' : 'icon-angle-down'} />
          <span>
            {I18n.t(
              optionsActive ? 'navigationPage.closeRouteItems' : 'navigationPage.viewRouteItems'
            )}
          </span>
          {totalNotificationsCount > 0 && (
            <span className="notifications-alert">{totalNotificationsCount}</span>
          )}
        </div>
      </div>
    </div>
  )

  function handleToggleOptions() {
    analytics.newEvent(
      !optionsActive === true ? 'show_route_information' : 'hide_route_information',
      {}
    )
    toggleOptions()
  }

  function routeItemIsAnObject(routeItem: IRouteItem) {
    return (
      routeItem.type === 'BRIDGE' ||
      routeItem.type === 'LOCK' ||
      routeItem.type === 'NOTIFICATION' ||
      routeItem.type === 'VIA' ||
      routeItem.type === 'END'
    )
  }
}

export default NavigationStepDetails
