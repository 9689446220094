import { SuppressedWarnings } from '../../@types/types'
import { determineDynamicTheme, supportsThemePreference } from '../../utils/style'
import { ISettingsAction } from './actions'

// Set inital settings state
export interface ISettingsState {
  themeOverride: 'light' | 'dark'
  listenToDeviceTheme: boolean
  suppressedWarnings: SuppressedWarnings
}

export const initialSettingsState: ISettingsState = {
  // Check if the device supports the media query
  themeOverride: supportsThemePreference() ? determineDynamicTheme() : 'light',
  listenToDeviceTheme: supportsThemePreference(),
  suppressedWarnings: {}
}

// eslint-disable-next-line @typescript-eslint/default-param-last
export function settingsReducer(state = initialSettingsState, action: ISettingsAction) {
  switch (action.type) {
    case 'SET_THEMEOVERRIDE':
      return Object.assign({}, state, {
        themeOverride: action.theme,
        listenToDeviceTheme: false
      })

    case 'TOGGLE_DEVICE_THEME':
      return Object.assign({}, state, {
        themeOverride: !state.listenToDeviceTheme ? determineDynamicTheme() : state.themeOverride,
        listenToDeviceTheme: !state.listenToDeviceTheme
      })

    case 'SET_SUPPRESSED_WARNINGS':
      return Object.assign({}, state, {
        suppressedWarnings: {
          ...state.suppressedWarnings,
          [action.layer]: {
            ...state.suppressedWarnings[action.layer],
            ...action.partialWarnings
          }
        }
      })

    case 'RESET_SUPPRESSED_WARNINGS':
      return Object.assign({}, state, { suppressedWarnings: {} })

    default:
      return state
  }
}
