import L from 'leaflet'
import * as React from 'react'
import { TileLayer } from 'react-leaflet'
import { IApplicationTheme, MapTypes } from '../../@types/types'
import { IShipInfo } from '../../services/TeqplayAPIService/TeqplayApi'

const MAPTILER_TOKEN = 'AGNmopVCzxcHY8ybP9oS'

export function centerViewOnLocation(
  mapApi: L.Map | null,
  location: IShipInfo | null,
  zoom?: number
) {
  if (mapApi && mapApi.setView && location && location.location) {
    const coords = {
      lat: location.location.latitude,
      lng: location.location.longitude
    }

    mapApi.setView(coords, zoom || mapApi.getZoom())
  }
}

/**
 * Get a formatted layer name for use in the state.
 * Adds a prefix and removes spaces
 */
export function formatLayerStateName(layerName: string): string {
  const stateName = layerName

  // remove spaces from the string
  return stateName.replace(/-|\s/g, '')
}

export function getMapTiles(mapType: MapTypes, theme: IApplicationTheme) {
  const url = getMapUrl(mapType, theme)

  return (
    <TileLayer
      key={mapType}
      url={url}
      tileSize={url.includes('openstreetmap') ? 256 : 512}
      zoomOffset={url.includes('openstreetmap') ? 0 : -1}
    />
  )
}

function getMapUrl(mapType: MapTypes, theme: IApplicationTheme) {
  return {
    DEFAULT:
      theme === 'light'
        ? `https://api.maptiler.com/maps/73d46abf-139c-4ea4-a57d-ca20d5b7b7ca/{z}/{x}/{y}@2x.png?key=${MAPTILER_TOKEN}`
        : `https://api.maptiler.com/maps/83228b5e-c568-4e7b-a22b-73c6fd16a522/{z}/{x}/{y}@2x.png?key=${MAPTILER_TOKEN}`,
    SATELLITE: `https://api.maptiler.com/maps/hybrid/{z}/{x}/{y}@2x.jpg?key=${MAPTILER_TOKEN}`,
    ECDIS: `https://api.maptiler.com/maps/de293eb7-26e1-4c94-9207-4f7b260eb47d/{z}/{x}/{y}@2x.png?key=${MAPTILER_TOKEN}`,
    OPENSTREETMAP: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
  }[mapType || 'DEFAULT']
}
