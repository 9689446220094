import React, { useEffect, useState } from 'react'
import { Marker, Polygon } from 'react-leaflet'
import { toLatLng } from 'geolocation-utils'
import { divIcon, LatLngBounds } from 'leaflet'
import { isEqual } from 'lodash'

import TeqplayApiService from '../../../../../services/TeqplayAPIService/TeqplayApiService'
import ShipMarker from '../../../shipMarker/ShipMarker'
import BerthLegendComponent from './BerthLegendComponent'
import ModalBerthCones from './ModalBerthCones'
import ModalBerthInfo from './ModalBerthInfo'

import { useBerths } from '../../../../../hooks/useBerths'
import { usePrevious } from '../../../../../hooks/usePrevious'
import { useShipsInBerth } from '../../../../../hooks/useShipsInBerth'
import { IShipInfo } from '../../../../../services/TeqplayAPIService/TeqplayApi'

interface IProps {
  name: string
  bounds?: LatLngBounds
  teqplayApiService: TeqplayApiService
  ship: IShipInfo | null
  minimumZoomLevel: number
  mapZoomLevel: number
  hideLegendCollapsable?: boolean
  legendPosition?: L.ControlPosition
  cones: number | undefined
  setCones: (value: number | undefined) => void
  types: {
    suitable: boolean
    terminal: boolean
    public: boolean
  }
  disableInteraction?: boolean
}

const BerthLayer = ({
  name,
  ship,
  types,
  teqplayApiService,
  bounds,
  mapZoomLevel,
  minimumZoomLevel,
  hideLegendCollapsable,
  legendPosition,
  cones,
  setCones,
  disableInteraction
}: IProps) => {
  const [showSuitableDialog, setShowSuitableDialog] = useState<boolean>(false)

  const berths = useBerths(
    teqplayApiService,
    ship,
    types.public || types.suitable || types.terminal,
    types,
    mapZoomLevel >= minimumZoomLevel,
    cones,
    bounds
  )
  const anyChecked = types.public || types.suitable || types.terminal
  const { selectedBerthOwnerId, setSelectedBerthOwnerId, vesselsNearBerth, selectedBerth } =
    useShipsInBerth(berths, teqplayApiService, anyChecked)
  const previousTypes = usePrevious(types)

  useEffect(() => {
    // Show dialog if suitable berths is being selected
    if (types.suitable && !previousTypes?.suitable) {
      setShowSuitableDialog(true)
    }

    // Reset cones amount if it is being deselected
    if (!types.suitable && previousTypes?.suitable) {
      setCones(undefined)
      setShowSuitableDialog(false)
    }
  }, [types, previousTypes, setCones])

  if (!anyChecked || !berths || mapZoomLevel < minimumZoomLevel) {
    return null
  }

  return (
    <React.Fragment>
      <ModalBerthCones
        showSuitableDialog={showSuitableDialog}
        setShowSuitableDialog={setShowSuitableDialog}
        setCones={setCones}
      />

      <ModalBerthInfo
        activeBerth={selectedBerth}
        clearSelectedBerth={() => setSelectedBerthOwnerId(undefined)}
        vessels={vesselsNearBerth || []}
      />

      <BerthLegendComponent
        name={name}
        hideLegendCollapsable={hideLegendCollapsable}
        legendPosition={legendPosition}
      />

      {berths.map(item => (
        <Polygon
          positions={item.area.coordinates}
          key={`polygonLayer-berth-${item._id}`}
          color={
            selectedBerthOwnerId === item.ownerId
              ? '#ffffff'
              : determineBerthColorBasedOnOccupation(item.occupiedPercentage)
          }
          weight={2}
          fillColor={determineBerthColorBasedOnOccupation(item.occupiedPercentage)}
          onClick={() => {
            setSelectedBerthOwnerId(item.ownerId)
          }}
          interactive={disableInteraction === false}
        />
      ))}

      {vesselsNearBerth?.map((s, index) => (
        <React.Fragment key={s._id}>
          <ShipMarker key={'shipMarker' + s.mmsi} ship={s} zoomLevel={mapZoomLevel} />
          <Marker
            key={s.mmsi + '-number'}
            position={toLatLng(s.location.coordinates)}
            icon={divIcon({
              className: 'ship-circle-icon',
              html:
                '<div style="background-color: ' +
                (s.positionOfTransponder !== null && !isEqual(s.locationBow, s.locationStern)
                  ? ''
                  : '#e28419') +
                '; color: rgb(255, 255, 255); border-radius: 10px; width: 20px; height: 20px; line-height: 20px; text-align: center;">' +
                (index + 1) +
                '</div>'
            })}
          />
        </React.Fragment>
      ))}
    </React.Fragment>
  )

  function determineBerthColorBasedOnOccupation(occupationPercentage?: number | 'Infinity') {
    if (!occupationPercentage && occupationPercentage !== 0) {
      return '#197ed4'
    }

    if (occupationPercentage <= 40) {
      return 'rgb(19,163,79)'
    } else if (occupationPercentage <= 75) {
      return 'rgb(209,175,6)'
    } else {
      return 'rgb(244,7,0)'
    }
  }
}

export default BerthLayer
