import * as React from 'react'
import { Spring } from 'react-spring'

import { RouteSelectionFields } from '../../../../@types/types'
import { parseStringForID } from '../../../../utils/tests'

interface IProps {
  displayName?: string
  value: string | null
  placeholder?: string
  fieldName: RouteSelectionFields
  viaRouteIndex?: number
  className?: string
  setrouteSelectionFormFieldActive: (field: RouteSelectionFields, viaRouteIndex?: number) => void
  removeViaRoute?: (index: number) => void
}

const RouteSelectField: React.FunctionComponent<IProps> = props => {
  const routeSelectField = (
    <div className="input-wrapper">
      <label htmlFor="" className="form-label">
        {props.displayName}
      </label>
      <div
        className={
          props.className ? 'input-field-wrapper ' + props.className : 'input-field-wrapper'
        }
      >
        <div
          className={`textfield textfield-location ${props.value ? 'value' : 'placeholder'}`}
          onClick={() => {
            if (props.fieldName === 'VIA_ROUTES' && props.viaRouteIndex !== null) {
              props.setrouteSelectionFormFieldActive(props.fieldName, props.viaRouteIndex)
            } else {
              props.setrouteSelectionFormFieldActive(props.fieldName)
            }
          }}
          id={parseStringForID(`button-edit-location-${props.fieldName}`)}
        >
          {props.viaRouteIndex !== undefined && props.viaRouteIndex >= 0 ? (
            <div className="via-circle">{props.viaRouteIndex + 1}</div>
          ) : null}
          {props.value ? props.value : props.placeholder ? props.placeholder : ''}
        </div>

        {props.removeViaRoute && props.viaRouteIndex !== undefined && (
          <button
            type="button"
            className="button remove-via-route-button"
            onClick={() => {
              if (props.removeViaRoute && props.viaRouteIndex !== undefined) {
                props.removeViaRoute(props.viaRouteIndex)
              }
            }}
            id={`button-remove-via-button-${props.viaRouteIndex}`}
          >
            <i className="icon-minus-circled" />
          </button>
        )}
      </div>
    </div>
  )

  return (
    <Spring from={{ opacity: 0 }} to={{ opacity: 1 }}>
      {springProps => <div style={springProps}>{routeSelectField}</div>}
    </Spring>
  )
}

export default RouteSelectField
