import React, { useEffect, useState } from 'react'
import { Polygon } from 'react-leaflet'
import { LatLngExpression } from 'leaflet'
import union from '@turf/union'
import {
  Feature,
  Polygon as IPolygon,
  Properties,
  polygon as convertToPolygon,
  Position,
  MultiPolygon
} from '@turf/helpers'

import { ILayerCodeName } from '../../../../../@types/types'
import { LAYER_COVERAGE } from '../../../../../utils/constants'

interface IProps {
  activeLayers: ILayerCodeName[]
  checked: boolean
}

const CoverageAreaLayer = (props: IProps) => {
  const [coverageAreaPolygon, setCoverageAreaPolygon] = useState<(number[][] | Position[][])[]>([])

  function mergeOverlappingPolygons(areas: number[][][]) {
    // Converting entire arr to string in order to do comparison of polygons to not have duplicates in overlap
    const uniqueAreas = areas.filter(
      (v, i, arr) => arr.map(x => JSON.stringify(x)).indexOf(JSON.stringify(v)) === i
    ) as unknown as Position[][][]
    const polygonsArray: Array<Feature<IPolygon | MultiPolygon, Properties> | null> = []

    uniqueAreas.forEach(ca => {
      if (ca.length === 1) {
        // Only 1 polygon in array
        return polygonsArray.push(convertToPolygon(ca))
      } else {
        // Multiple polygons present, separate to conform to union function specs
        return ca.forEach(y => polygonsArray.push(convertToPolygon([y])))
      }
    })

    return polygonsArray.length > 2
      ? polygonsArray.reduce((prev, curr, i) =>
          union(
            prev as Feature<IPolygon | MultiPolygon, Properties>,
            curr as Feature<IPolygon | MultiPolygon, Properties>
          )
        )
      : polygonsArray[0] && polygonsArray[1] && union(polygonsArray[0], polygonsArray[1])
  }

  useEffect(() => {
    if (props.checked) {
      const edgePolygon = [
        [-180, 180],
        [-180, -180],
        [180, -180],
        [180, 180]
      ]
      const validAreas = LAYER_COVERAGE.filter(lc => props.activeLayers.includes(lc.codeName))
        .map(lca => lca.coverageArea)
        .filter(x => x) as unknown as number[][][] // filtering out any undefined values

      if (validAreas.length > 0) {
        const uniqueMergedPolygons = mergeOverlappingPolygons(validAreas)?.geometry
          ?.coordinates as Position[][][]
        const holedPolygon = [edgePolygon, ...uniqueMergedPolygons]

        setCoverageAreaPolygon(holedPolygon)
      } else {
        setCoverageAreaPolygon([])
      }
    }
  }, [props.activeLayers, props.checked])

  if (props.activeLayers.length === 0 || !props.checked) {
    return null
  }

  return (
    <React.Fragment>
      <Polygon
        key={`l-range-polygon`}
        color={'#bd0040'}
        fillOpacity={0.3}
        opacity={0}
        positions={coverageAreaPolygon as LatLngExpression[][][]}
      />
    </React.Fragment>
  )
}

export default CoverageAreaLayer
