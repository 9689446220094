import * as React from 'react'
import { Translate } from 'react-redux-i18n'

import './InactiveRouteSelect.scss'

interface IProps {
  setRouteSelectionFormActive: () => void
}

const InactiveRouteSelect: React.FunctionComponent<IProps> = props => {
  return (
    <div className="route-select-wrapper main-menu">
      <div className="select-inline-inner-wrapper">
        <div
          className="activate-route-select"
          id="route-select-inactive-button"
          onClick={props.setRouteSelectionFormActive}
        >
          <i className="icon-search" />
          <Translate value="routeSelection.selectRoutePlaceholder" />
        </div>
      </div>
    </div>
  )
}

export default InactiveRouteSelect
