const dutchLocale = {
  app: 'RiverGuide',
  appTitle: 'RiverGuide Binnenvaart',
  version: 'Versie %{version}',
  save: 'Opslaan',
  yes: 'Ja',
  no: 'Nee',
  proceed: 'Doorgaan',
  confirm: 'Bevestig',
  proceedAndConfirm: 'Bevestig en ga door',
  cancel: 'Annuleren',
  close: 'Sluiten',
  now: 'Nu',
  loading: 'Laden',
  processing: 'Verwerken',
  required: 'Verplicht',
  delete: 'Verwijder',
  generalError: 'Er is helaas iets fout gegaan, probeer het later opnieuw of neem contact op.',
  noInfoAvailable: 'Geen informatie beschikbaar',
  empty: 'Leeg',
  disclaimer: {
    title: 'RiverGuide Binnenvaart is in ontwikkeling, wees voorzichtig!',
    content:
      'Houd uw ogen op de vaarweg en houd u aan de wet. Er bestaat een mogelijkheid dat de routes onvolledig, incorrect, gevaarlijk of verboden zijn. Het is mogelijk dat data niet altijd real-time is en de precisie van de locatie niet altijd voldoende is. Teqplay, haar opdrachtgevers en haar dataleveranciers zijn niet verantwoordelijk voor schade als gevolg van het gebruik van deze applicatie.',
    agree: 'Begrepen'
  },
  fillInAllFields: 'Vul alle velden in',
  unknownValue: 'Onbekende waarde',
  privacy: 'Privacy',

  title: {
    createAccount: 'Account aanvragen',
    settings: 'Instellingen',
    weather: 'Weer',
    routeSelection: 'Routeplanner',
    routeSelectionPicker: 'Routes voor %{from}%{via} naar %{to}',
    routeSelectionPickerVia: ' via %{number} locaties',
    navigation: 'Navigatie actief',
    navigationPoint: '%{distance} · %{time}',
    routePaused: 'Route gepauzeerd'
  },

  locale: {
    selectLocale: 'Taal',
    nl_NL: 'Nederlands',
    en_GB: 'Engels',
    fr_FR: 'Frans',

    nl_NL_native: 'Nederlands',
    en_GB_native: 'English',
    fr_FR_native: 'Française'
  },

  login: {
    login: 'Login',
    welcome: {
      morning: 'Goedemorgen, welkom terug!',
      afternoon: 'Goedemiddag, welkom terug!',
      evening: 'Goedenavond, welkom terug!',
      night: 'Welkom terug!',
      day: 'Goed om u weer terug te zien!'
    },
    developMode: 'Gelinked aan %{url}',
    welcomeBack: 'Hallo %{name}',
    email: 'E-mail',
    password: 'Wachtwoord',
    submit: 'Verstuur',
    registerLink: 'Vraag een nieuw account aan',
    forgot: 'Wachtwoord vergeten?',
    wrongCredentials:
      'Er is iets misgegaan met het inloggen, probeer een nieuw account aan te maken of neem contact op',
    back: 'Terug naar login',
    forgotPassword: 'Vraag een nieuw wachtwoord aan met uw gebruikersnaam',
    username: 'Gebruikersnaam',
    successForgot: 'Gelukt!',
    forgotPasswordSuccess:
      'Als u een account voor RiverGuide heeft, is er een mail naar uw email adres verstuurd met verdere instructies.'
  },
  register: {
    createAccount: 'Registreer een account',
    submit: 'Registreer',
    backToLogin: 'Terug naar login',
    name: 'Naam schipper',
    emailAddress: 'E-mail',
    password: 'Wachtwoord',
    mmsiNumber: 'MMSI nummer',
    repeatPassword: 'Herhaal wachtwoord',
    phoneNumber: 'Telefoonnummer',
    shipName: 'Scheepsnaam',
    ship: 'Schip',
    helpName:
      'Mochten wij uw schip niet kunnen vinden met uw MMSI-nummer, gebruiken wij de scheepsnaam om deze te zoeken',
    comment: 'Extra bericht',
    passwordsNotMatch: 'De wachtwoorden zijn niet gelijk',
    fieldsRequired: 'Vul alle verplichte velden in (*)',
    alreadyExists: 'Er is al een account voor dit e-mailadres',
    mandatory: 'Verplicht veld',
    confirmAccount: 'Bevestig uw account',
    readApproveGDPR: 'Klik om het privacybeleid te lezen',
    accepted: 'Privacybeleid geaccepteerd',
    confirmed: 'Registratieaanvraag ontvangen',
    activeAISTransponderRequired:
      'U heeft een actieve AIS transponder nodig om RiverGuide Binnenvaart te kunnen gebruiken.',
    awaitActivation:
      'Wij hebben uw aanvraag in goede orde ontvangen. Voordat het account kan worden gebruikt moet het worden goedgekeurd. Hierover zal u een bevestigingsmail krijgen.',

    help: {
      PERSONAL_INFO: 'Wij willen graag uw naam en e-mailadres weten om een account aan te maken.',
      SHIP: 'De naam van uw schip gebruiken wij om de applicatie te personaliseren.',
      SHIP_DIMENSIONS:
        'Om te bepalen waar u wel en niet door kan, hebben wij de dimensies van uw schip nodig.'
    },
    step: 'Stap',
    steps: {
      PERSONAL_INFO: 'Persoonlijke informatie',
      SHIP: 'Scheepsinformatie',
      SHIP_DIMENSIONS: 'Scheepsdimensies',
      CONFIRM: 'Valideer'
    },
    back: 'Terug',
    cancel: 'Annuleer',
    next: 'Volgende',

    mmsiError: {
      empty: 'Selecteer een schip',
      oldAISTitle: 'Verouderd AIS signaal',
      oldAISFirstLine: 'Uw AIS signaal is %{time} voor het laatst ontvangen.',
      oldAISSecondLine: 'Zorg ervoor dat u een werkende en actieve AIS transponder heeft.',
      oldAISThirdLine:
        'Zonder een actief AIS signaal zal RiverGuide Binnenvaart niet functioneren als verwacht, en u niet correct op de kaart kunnen tonen.',
      oldAISFourthLine:
        'Zodra uw AIS transponder een signaal uitzendt en wij uw signaal ontvangen, zal uw locatie direct worden geüpdatet.',
      oldAISFifthLine: 'Weet u zeker dat u door wilt gaan?',
      noMMSI:
        'Het geselecteerde schip heeft geen MMSI, kies een ander schip of neem contact op met support@teqplay.nl'
    },

    gdpr: {
      title: 'Privacy RiverGuide Binnenvaart',
      privacyBodyFirstLine:
        'We willen graag dat u begrijpt welke gegevens we verzamelen en gebruiken.',
      privacyBodySecondLine:
        'Wanneer u RiverGuide gebruikt vertrouwt u ons uw gegevens toe. Het is onze verantwoordelijkheid om duidelijk te maken wat we precies verzamelen en hoe we onze services voor u daarmee nog beter maken.',
      privacyBodyThirdLine:
        'Wanneer u RiverGuide gebruikt verzamelen we gegevens om onze diensten te kunnen leveren en verbeteren. Wanneer u zich aanmeldt voor een RiverGuide-account, bewaren we de accountgegevens die u opgeeft. Dit zijn uw scheepsnaam, naam, e-mailadres en telefoonnummer. Ook bewaren we informatie over locatie en uw apparaatgegevens.',
      privacyBodyFourthLine:
        'De gegevens zijn beschikbaar voor de Nederlandse vaarwegbeheeders en Teqplay. Doel van het gebruik van de gegevens is het realiseren van vlot, veilig en gemakkelijk gebruik van de vaarwegen. Uw gegevens worden gebruikt voor het leveren van de service, het beheren van uw gebruikersaccount, om u te informeren over (nieuwe) services en voor het beantwoorden van uw vragen.',
      privacyBodyFifthLine:
        'U heeft de middelen in RiverGuide om uw privacy te beheren, u kunt uw gegevens zelf inzien en uw account te verwijderen en uw toestemming in te trekken. Na beëindiging van uw account worden de persoonlijke gegevens verwijderd en geanonimiseerd. Persoonsgegevens worden bewaard zolang als u een account heeft.',
      privacyBodySixthLine:
        'We beschermen onze en uw gegevens en applicaties tegen ongeautoriseerde toegang tot of ongeautoriseerde aanpassing. Daarom coderen we veel van onze services met SSL en evalueren we onze handelswijzen met betrekking tot verzameling, opslag en verwerking van gegevens. We beperken de toegang tot persoonlijke gegevens tot medewerkers en vaarwegbeheerders die deze gegevens nodig hebben om onze dienst te kunnen leveren.',
      privacyBodySeventhLine: 'Voor vragen over de privacy kunt u contact opnemen met:',
      accept: 'Accepteren',
      deny: 'Weigeren',
      error:
        'U moet de privacyvoorwaarden accepteren om RiverGuide Binnenvaart te kunnen gebruiken.',
      view: 'Lees voorwaarden',
      close: 'Sluiten'
    }
  },
  routeSelection: {
    selectRoutePlaceholder: 'Selecteer uw reis',
    selectRoute: 'Selecteer route',
    startingPoint: 'Vertrekpunt',
    viaPoint: 'Via punt',
    destinationPoint: 'Bestemming',
    selectDestination: 'Selecteer uw bestemming',
    selectStartingPoint: 'Selecteer uw vertrekpunt',
    selectViaPoint: 'Selecteer een via punt',
    options: 'Opties',
    calculateRoute: 'Bereken route',
    arrival: 'Aankomst',
    departure: 'Vertrek',
    searchOnLocation: 'Zoeken op locatie',
    noOptions: 'Geen locaties gevonden',
    searchAdvice: 'Zoek op locatie',
    currentLocation: 'Huidige locatie',
    selectOnMap: 'Selecteer locatie op de kaart',
    distance: 'Afstand',
    departureTime: 'Vertrektijd',
    arrivalTime: 'Aankomsttijd',
    viewList: 'Lijst Weergave',
    startRoute: 'Start',
    saveRoute: 'Opslaan',
    cancel: 'Annuleren',
    list: 'Route',
    info: 'Info',
    details: 'Details',
    map: 'Kaart',
    notAllowedDimensions: 'Deze route is niet vaarbaar met ingevoerde scheepsafmetingen',
    notAllowedBridge: 'Niet alle bruggen zijn te passeren met de ingevoerde scheepshoogte',
    noRoutesFoundWarning: 'Er zijn geen routes gevonden voor dit traject',
    noDetailsFoundWarning: 'Geen details voor dit object beschikbaar',
    routeDetails: 'Route informatie',
    routeStoredSucces:
      'De route is opgeslagen. Bij het selecteren van de locaties ziet u de opgeslagen routes.',
    showSavedRoutes: 'Toon opgeslagen routes',
    hideSavedRoutes: 'Verberg opgeslagen routes',
    confirmDeleteStoredRoute: 'Weet u zeker dat u Route "%{key}" wilt verwijderen?',
    fromTo: '%{from} naar %{to}',
    dateSaved: 'Opgeslagen op',
    saveRouteTitle: 'Opslaan van route',
    noNameFilled: 'Vul een naam in om de route op te slaan',
    routeNameInput: 'Naam route',
    selectionWarning: 'Selecteer een vertrekpunt en bestemming',
    pauseRoute: 'Pauzeer Route',
    resumeRoute: 'Vervolg Route',
    noLocationWarning:
      'Zet uw GPS aan om de route te kunnen starten. Zodra uw locatie op de kaart zichtbaar is kan de route worden gestart!',
    shipDepth: 'Ondiepste punt',
    shipHeight: 'Laagste doorvaarhoogte',
    maxWidth: 'Smalste punt',
    waitingTime: 'Verwachte wachttijd',
    duration: 'Totale duur',
    openInfo: 'Bekijk info',
    closeInfo: 'Bekijk kaart',
    reverse: 'Wissel start en bestemming om',
    deleteRoute: 'Verwijder route',
    routeOptions: 'Opties',
    avoidMajorRoutes: 'Vermijd grote vaarwegen',
    avoidMajorRoutesInfo:
      'Geef voorkeur aan kleinere routes boven grotere vaarroutes waar mogelijk.',
    staandeMast: 'Staande mast route',
    createGeolocation: '%{value} - Zoek naar locatie',
    speed: 'Gemiddelde snelheid',
    saveSpeedModal: 'Vul uw gemiddelde snelheid in',
    saveSpeedError: 'Vul een geldige waarde in tussen 0 en 50 km/h',
    saveSpeedErrorKnots: 'Vul een geldige waarde in tussen 0 en 27 knopen',
    searchingFor: 'Bezig met zoeken van %{placeName}...',
    notFound: 'Plaats kon niet worden gevonden',
    timeInvalid: 'Ingevulde datum en tijd is niet valide',
    timeInvalidUsingOther: 'Ingevulde datum en tijd is niet valide, %{time} wordt gebruikt',
    savedRouteNameAlreadyExists:
      'U heeft al een opgeslagen route met de naam "%{key}", verander de naam van de route om de route op te slaan.',
    over: 'In %{time}'
  },
  routeList: {
    itemDetails: {
      cannot_pass_bridge: 'Let op! Uw schip past niet onder deze brug door.',
      communication: 'Communicatie',
      vhf: 'VHF',
      control_times: 'Bedientijden',
      yes: 'Ja',
      no: 'Nee',
      NAP: 'NAP',
      opening: 'Opening',
      type: 'Type',
      opening_width: 'Breedte',
      width: 'Breedte',
      length: 'Lengte',
      closed_height: 'Gesloten hoogte',
      opened_height: 'Geopende hoogte',
      telephone: 'Tel',
      startTime: 'Startdatum',
      endTime: 'Einddatum',
      lock_chamber: 'Sluiskom',
      max_width: 'Max. Breedte (m)',
      threshold_outside: 'Drempel beneden/buiten',
      threshold_inside: 'Drempel boven/binnen',
      address: 'Waladres',
      from: 'Van',
      to: 'tot',
      wholeDay: 'Hele dag',
      name: 'Naam',
      bridge_type: {
        VST: 'Vast',
        DR: 'Draai',
        BC: 'Bascule',
        OPH: 'Ophaal',
        HEF: 'Hef',
        DBC: 'DBC'
      },
      bridge_status: {
        open: 'Deze brug is op dit moment geopend',
        closed: 'Deze brug is op dit moment dicht.',
        unknown: 'De actuele status van deze brug is onbekend.'
      },
      bridge_operating_rules: {
        today: 'vandaag',
        tomorrow: 'morgen',
        holiday: 'Feestdag',
        noService: 'Geen bediening'
      },
      no_items_found: 'Geen details voor dit object beschikbaar',
      waitingTime: '%{waitingTime} wachttijd verwacht voor volgende %{objectType}',
      waitingTimeSelected: '%{waitingTime} wachttijd verwacht voor deze %{objectType}',
      note: 'Opmerking',
      bridgeOpeningNote: 'Opmerking over brugopening',
      chamberNote: 'Opmerking over sluiskom',
      waterLevel: 'Waterhoogte',
      operable: 'Bedienbaar',
      arrival: 'Aankomst',
      departure: 'Passeertijd',
      phone: 'Telefoon',
      status: {
        OPEN: 'Op dit moment staat deze brug open',
        CLOSED: 'Op dit moment is deze brug dicht',
        PLANNED: 'Brugopening gepland',
        OPENSHORT: 'Brug staat open',
        CLOSEDSHORT: 'Brug is dicht',
        PLANNEDSHORT: 'Brugopening gepland'
      },
      plannedOpeningTimes: 'Geplande brugopeningstijden',
      infoBridgeHasToOpen: 'Mogelijk brugopening vereist om te passeren',
      openingRequiredBMS:
        'Een brugopening is nodig voor uw schip. Deze wordt automatisch aangevraagd.',
      openingRequestedBMS: 'Er is een brugopening aangevraagd voor uw verwachte aankomsttijd',
      openingPlanned: 'Opening aangevraagd, de brug gaat binnenkort open',
      openingOpened: 'Deze brug staat op dit moment open',
      openingRequestPaused:
        'Brugopening vereist, hervat uw route om deze automatisch aan te laten vragen',

      bmsOpening: {
        status: 'Status',
        plannedOpening: 'Geplande openingstijd',
        plannedClosing: 'Geplande sluitingstijd',
        direction: 'Richting',
        UPSTREAM: 'Stroomopwaarts',
        DOWNSTREAM: 'Stroomafwaarts',
        certainty: {
          PLANNED: 'Mogelijk',
          EXPECTED: 'Waarschijnlijk',
          APPROVED: 'Bijna zeker',
          CANCELLED: 'Geannuleerd',
          CURRENT: 'Actueel',
          TERMINATED: 'Uitgevoerd'
        }
      },
      schematicView: 'Schematische weergave',
      heightToWater: 'Alle hoogtes gebruiken %{referenceLevel} als referentie.'
    },
    objectTypes: {
      BRIDGE: 'brug',
      LOCK: 'sluis',
      START: 'start',
      END: 'einde',
      NOTIFICATION: 'melding',
      WATERMETER: 'watermeter',
      TRAFFIC_SIGN: 'verkeersbord',
      BOTTLENECK: 'vernauwing',
      VHFSECTOR: 'VHF sector',
      UNKNOWN: 'onbekend object'
    }
  },
  navigationPage: {
    destination: 'Bestemming',
    arrival: 'Aankomst',
    next_object: 'Volgend Object',
    no_user_location_warning: 'Geen GPS Locatie gevonden',
    no_notifications: 'Er zijn geen meldingen voor de huidige vaarlocatie',
    list_view: 'Route',
    notifications: 'Notificaties',
    ship_speed: 'Snelheid',
    route_paused_warning: 'Route is gepauzeerd',
    stop_route_confirmation: 'Weet u zeker dat u de route wil stoppen?',
    pause_route: 'Pauzeer',
    stop_route: 'Stop route',
    resume_route: 'Hervat route',
    route: 'Route',
    viewRouteItems: 'Open routeinformatie',
    closeRouteItems: 'Sluit routeinformatie',
    routePausedETA: 'Route is gepauzeerd',
    routePausedETAInfo: 'Klik hier om uw route te hervatten',
    moreInformation: 'Klik voor meer informatie'
  },
  modalSelectPreviousRoute: {
    question: 'Wilt u verdergaan met uw vorige route?',
    yes: 'ja',
    no: 'nee'
  },
  confirmDimensions: {
    title: 'Bevestig gegevens voor %{ship}',
    length: 'Lengte',
    width: 'Breedte',
    height: 'Hoogte',
    draught: 'Kieldiepte',
    meters: 'm',
    noShip: 'Er zijn geen scheepsgegevens gevonden',
    doNotAskMeToday: 'Vraag dit vandaag niet meer',
    patchError:
      'Er is een fout opgetreden bij het versturen van uw nieuwe scheepdimensies, routes worden berekend met oude dimensies',
    fillInAllFields: 'Vul alle dimensiegegevens in'
  },
  pages: {
    welcome: 'Welkom',
    navigation: 'Routeplanner',
    navigationActive: 'Huidige route',
    settings: 'Instellingen',
    logout: 'Uitloggen',
    anonymousLogout: 'Terug naar registratie',
    weather: 'Weerbericht',
    information: 'Informatie',
    announcements: 'E-meldingen',
    ais: 'AIS informatie',
    privacy: 'Privacy'
  },
  map: {
    followButton: 'Centreer schip',
    speedButton: 'Stel snelheid in',
    activelayers: 'Selecteer kaartlagen',
    category: {
      categoryBerths: 'Ligplaatsen',
      categoryFairway: 'Vaarwegen',
      categoryElements: 'Elementen',
      maptype: 'Kaart'
    },
    limitedRange: 'Dekking beperkt',
    limitedRangeMessage: "Kaartlaag %{layerCode} is alleen beschikbaar in de volgende regio's:",
    limitedRangePost: 'Deze informatie kunt u later terugvinden in het Informatie menu.',
    limitedRangePostPagination: 'Menu > Informatie > Kaartlaaginformatie',
    limitedRangeOneTime: 'Dit bericht wordt maar een keer weergegeven.',
    limitedRangePolygonCoverage:
      'Rode gebieden op de kaart hebben geen dekking van deze of andere kaartlagen.',

    dataPlanWarning: 'Mogelijk hoog dataverbruik',
    dataPlanMessage:
      'Kaartlaag %{layerCode} bevat informatie dat veel dataverkeer kan gebruiken. Houd uw dataverbruik in de gaten.',
    dataPlanUsage: '%{layerCode} gebruikt ongeveer %{data}/%{unit}',
    dataPlanOneTime: 'Dit bericht wordt maar een keer weergegeven',
    dataPlanDataUsage: 'Dataverbruik (gemiddeld)',
    dataPlanSingleTime: 'eenmalig als de kaartlaag wordt aangezet', // As in x MB / time
    dataPlanSwipeUnload: 'per swipe naar ongeladen kaartgebied', // As in x MB / swipe
    dataPlanSwipe: 'per swipe', // As in x MB / swipe
    dataPlanIgnore: 'Door op "%{proceed}" te klikken wordt deze melding niet meer getoond.',

    layers: {
      crossings: 'Knooppunten',
      suitable_berths: 'Geschikte ligplaatsen',
      public_berths: 'Publieke ligplaatsen',
      terminal_berths: 'Terminal ligplaatsen',
      fairways: 'Maximale snelheid',
      CEMTClass: 'CEMT Klasse',
      fairwayDepth: 'Vaarwegdiepte (Friesland)',
      recreationalClass: 'Recreatie Klasse',
      electricRoutes: 'Elektrische routes',
      inlandHarbours: 'Jachthavens',
      chargingStations: 'Laadpalen',
      bridges: 'Bruggen',
      locks: 'Sluizen',
      drinkwaterPoints: 'Drinkwaterpunten',
      kilometrering: 'Kilometrering',
      wasteWaterStations: 'Vuilwater aan de wal',
      AISShips: 'AIS Schepen',
      depths: 'Diepte',
      recentDraught: 'Diepte (Waddenzee)',
      waveHeight: 'Golfhoogte',
      currents: 'Stroming',
      bottlenecks: 'Vernauwingen',
      futureAIS: 'Voorspelling AIS Julianakanaal',
      waterHeight: 'Waterhoogte',
      buoys: 'Boeien',
      waterwaySigns: 'Verkeersborden',
      weather: 'Weer',
      basMessages: 'Berichten aan de scheepvaart',
      recreationalRoutes: 'Recreationele routes',
      sabPoints: 'Afvalophaalpunten',
      carDropoffPoints: 'Autoafzetplaatsen',
      shorePower: 'Walstroom',
      berths: 'Ligplaatsen',
      operatingAreas: 'Bedrijfsgebieden'
    },

    berthsOutside: 'Ligplaatsen buitengebied',
    contractDepth: 'Contractdiepte',
    levelDepth: 'Peildiepte',
    speed: {
      title: 'Stel de snelheid in voor het berekenen van aankomsttijden',
      useShip: 'Gebruik snelheid schip',
      useCustom: 'Stel zelf in',
      setRealisticSpeed: 'Stel een snelheid in tussen de 1 en 50 km/h',
      saved: 'De wijziging is opgeslagen'
    },
    details: 'details',
    notification: {
      tooMuchBerths: 'Te veel ligplaatsen gevonden, zoom verder in om de ligplaatsen te tonen.',
      approachVdjsHeader: 'VDJS Knooppunt',
      approachingVdjsCrossing:
        'Je nadert het knooppunt %{crossingName}. ' +
        'Voor dit knooppunt zijn aangeraden routes beschikbaar van Varen Doe je Samen.',
      approachingVdjsCrossingShort: 'U nadert het knooppunt %{crossingName}',
      show: 'Toon op kaart',
      channelNotificationHeader: 'Drukke Vaarweg',
      nearingChannelNotification:
        'U nadert het %{channelName}. Pas op met varen, er is hier veel beroepsvaart actief.',
      zoomInNotification: 'Zoom in om de volgende kaartlagen op de kaart te zien:',
      title: 'Vaarweg notificaties',
      viaPointHeader: 'U nadert een via punt van uw route',
      endOfRouteHeader: 'Bestemming bereikt',
      endOfRouteSubtitle: 'U bent binnen %{radius}m van uw bestemming',
      clickForMoreInfo: 'Klik voor meer informatie...',
      clickForLessInfo: 'Klik om meer informatie te sluiten'
    },
    chargingStationPopup: {
      chargingPossibilities: 'Oplaadmogelijkheden',
      unavailable: 'Niet beschikbaar',
      connections: 'aansluitingen'
    },
    measurementDate: 'Datum meting',
    depthNap: 'Diepte NAP',
    depthMv: 'Diepte midden vaarweg',
    description: 'Beschrijving',
    depthLegendTitle: 'Diepte in meter (LAT)',
    depthLegendAboveLAT: 'boven LAT',
    depthLegendBelowLAT: 'onder LAT',
    depthPopupTitle: 'Diepte meting',
    depthPopupDate: 'Datum',
    waveHeight: {
      name: 'Locatie',
      height: 'Hoogte',
      timeHeightLastUpdated: 'Laatste update'
    },
    currents: {
      current: 'Stroom in ',
      kmh: 'km/h',
      ms: 'm/s',
      error: 'Er heeft zich een onbekende fout opgetreden, probeer het opnieuw.',
      now: '~ Nu'
    },
    futureAIS: {
      estimatedPosition: 'Geschatte positie %{time}',
      icon: 'Geschatte positie %{time}',
      timeSeconds: 'over %{seconds} seconden',
      timeMinutes: 'over %{minutes} minuten',
      notInsideArea:
        'U bent niet in de buurt van het Julianakanaal. Om gebruik te maken van' +
        ' deze kaartlaag moet u zich in de buurt van het Julianakanaal bevinden.',
      noFutureTrace: 'Er kon geen voorspelling worden gedaan voor uw schip in het Julianakanaal.',
      noRoute:
        'De voorspelling kan enkel worden gedaan mits u een actieve route heeft door het Julianakanaal.',
      extrapolatedTime: 'Tijdstip van positie',
      kmh: 'km/h',
      knots: 'knopen',
      explain: 'Schepen met een rode omlijning hebben een voorspelde positie die kan afwijken.'
    },
    waterHeight: {
      name: 'Meetpunt',
      height: 'Waterhoogte',
      timeHeightLastUpdated: 'Laatste update'
    },
    buoys: {
      buoy: 'Boei'
    },
    waterwaySigns: {
      sign: 'Verkeersbord',
      description: 'Beschrijving',
      anchoring: 'Geankerd in'
    },
    crossings: {
      route: 'Recreatievaart route',
      sea: 'Diepzee route',
      moreInformation: 'Meer informatie',
      viewMap: 'Bekijk kaart'
    },
    inlandHarbours: {
      city: 'Stad',
      longStayPlaces: 'Lang verblijf plaatsen',
      shortStayPlaces: 'Kort verblijf plaatsen',
      suppliesFuel: 'Brandstof verkrijgbaar'
    },
    fairways: {
      maxSpeed: 'Maximale snelheid',
      moreInformation: 'Meer informatie'
    },
    drinkwaterPoints: {
      markerLand: 'Drinkwaterpunt op land',
      markerWater: 'Drinkwaterpunt op water',
      Boat: 'Boot',
      Land: 'Land'
    },
    bridges: {
      solid: 'Vaste brug',
      open: 'Geopende brug',
      closed: 'Gesloten brug',
      unknown: 'Brug zonder open/dicht sensor',
      default: 'Beweegbare brug',
      opening: 'Opening',
      type: 'Type',
      heightClosed: 'Gesloten hoogte',
      vhf: 'VHF',
      phoneNumber: 'Telefoon',
      operable: 'Bedienbaar',
      openingTimes: 'Openingstijden',
      yes: 'Ja',
      no: 'Nee'
    },
    recreationalRoutes: {
      sailTowardsRoute: 'Vaar naar deze route'
    }
  },
  settings: {
    title: 'Instellingen',
    titleProfile: 'Profielgegevens',
    titleShip: 'Scheepsinformatie',
    myShip: 'Mijn schip',
    username: 'Gebruikersnaam',
    usernameUnableChange: 'Kan niet gewijzigd worden',
    password: 'Wachtwoord',
    optional: 'Optioneel',
    name: 'Naam',
    email: 'Email',
    emailError: 'Dit is geen geldig email adres',
    mobile: 'Telefoonnummer',
    mobileError: 'Dit is geen geldig telefoonnummer',
    shipError: 'Dit schip heeft geen MMSI en kan niet worden gebruikt',
    unsavedChanges: 'U heeft uw wijzigingen nog niet opgeslagen.',
    currentPassword: 'Huidig wachtwoord',
    newPassword: 'Nieuw wachtwoord',
    repeatNewPassword: 'Herhaal nieuw wachtwoord',
    changePassword: 'Verander wachtwoord',
    fillInAllFields: 'Vul alle velden in',
    save: 'Opslaan',
    newPasswordNoMatch: 'De nieuwe wachtwoorden komen niet overeen',
    updatedPasswordSuccess: 'Uw wachtwoord is gewijzigd',
    wrongPassword: 'Verkeerd huidig wachtwoord',
    weakPassword: 'Uw wachtwoord is te zwak',
    titleDeleteAccount: 'Profiel verwijderen',
    selectAShip: 'Selecteer een schip',
    updateProfileSuccess: 'Instellingen zijn bijgewerkt',
    updateProfileFailed:
      'Er is iets fout gegaan tijdens het opslaan van uw profiel, probeer het later opnieuw of neem contact op met support@teqplay.nl',
    noChangesToSave: 'Er zijn geen wijzigingen om op te slaan',
    areYouSureDeleteAccount:
      'Weet u zeker dat u uw profiel wilt verwijderen? Dit kan niet ongedaan worden gemaakt.',
    removeSuccess: 'Profiel is verwijderd',
    no: 'Nee',
    yes: 'Ja',
    yesDelete: 'Ja, verwijder',
    cancel: 'Maak wijzigingen ongedaan',
    speedUnit: 'Snelheidseenheid',
    kmh: 'kilometer per uur',
    knots: 'knopen',
    ship: {
      ship: 'Schip',
      info: 'Routes worden waar mogelijk bepaald aan de hand van uw scheepsdimensies.'
    },
    theme: {
      style: 'Applicatie stijl',
      theme: 'Thema',
      light: 'Licht',
      dark: 'Donker',
      listenToDeviceTheme: 'Automatisch aanpassen',
      listenToDeviceThemeInfo:
        'Aan de hand van uw apparaatinstellingen bepaalt de applicatie of deze een licht of donker thema weergeeft.'
    },
    other: 'Overig',
    warnings: 'Waarschuwingen',
    resetWarnings: 'Reset waarschuwing popups',
    resetWarningsInfo:
      'Door de waarschuwing popups te resetten zullen waarschuwingen voor Kaartlagen met mogelijk hoog dataverbruik en Kaartlagen met beperkte dekking opnieuw worden getoond als een kaartlaag wordt aangezet.',
    resetWarningsSuccess: 'Alle waarschuwingen gereset',
    selectLanguage: 'Selecteer taal',
    currentLanguage: 'Huidige taal',
    passwordTips: {
      title: 'Wachtwoord vereisten',
      match: 'Wachtwoorden zijn hetzelfde',
      noMatch: 'Wachtwoorden zijn niet hetzelfde',

      requirements: 'Wachtwoord vereisten',
      requirementsInfo:
        'Wij willen dat uw wachtwoord zo veilig mogelijk is om u en uw gegevens te beschermen. Daarom hanteren wij deze eisen aan uw wachtwoord.',

      tips: 'Tips voor een veilig wachtwoord',
      tipsInfo:
        'U mag de inhoud van uw wachtwoord verder zelf invullen, maar wij hebben ook een aantal tips om het zo veilig mogelijk te maken.',
      tipsCasing: 'Gebruik zowel kleine letters als hoofdletters',
      tipsNumber: 'Gebruik een getal',
      tipsSpecialCharacters: 'Gebruik een speciaal karakter zoals !@#$%',
      tipsLength: 'Gebruik 8 karakters of meer',
      tipsUnique: 'Gebruik een uniek wachtwoord wat je niet overal gebruikt',
      tipsUniqueSubtitle:
        'Dit is natuurlijk niet verplicht en kunnen wij zelfs niet weten of controleren vanwege uw geëncrypte wachtwoord, maar voor de hoogste veiligheidseisen raden wij u aan om een uniek wachtwoord te gebruiken.',
      tipsNoPersonalInfo: 'Gebruik geen makkelijk te raden persoonlijke info in uw wachtwoord',
      tipsStoreSafely: 'Sla uw wachtwoorden veilig op',
      tipsStoreSafelySubtitle:
        'Wij raden het gebruik van een wachtwoord- of passwordmanager aan. U kunt ook het wachtwoord opschrijven en goed verbergen, echter is dit onveiliger dan een wachtwoordmanager.',

      allMet: 'Kijk dit is veilig, goed bezig! 👍',
      abideToAll: 'Uw nieuwe wachtwoord voldoet niet aan de vereisten'
    }
  },
  feedbackPage: {
    description: 'Beschrijving',
    descriptionText: 'Voeg een beschrijving van uw feedback toe',
    location: 'Locatie',
    locationText: 'Selecteer een plaats op de kaart waar uw feedback of opmerking over gaat.',
    photo: 'Foto',
    photoText: 'Voeg een foto toe voor de betreffende locatie',
    uploadText: 'Sleep of klik om een foto om bij te voegen',
    sendFeedback: 'Feedback verzenden',
    warningMessage: 'Vul een omschrijving van uw feedback in',
    successMessage: 'Uw feedback is verzonden, hartelijk dank voor uw bijdrage!',
    errorMessage: 'Er ging iets fout bij het verzenden van uw feedback, excuses voor het ongemak.',
    done: 'Gereed',
    chooseLocation: 'Klik hier om een locatie op de kaart te kiezen'
  },
  mobile: {
    navigation_active: 'Navigatie actief',
    navigation_active_destination: 'Navigatie naar %{destination} actief',
    offline_title: 'Geen verbinding',
    offline_text: 'Er is geen verbinding, uw route zal worden gepauzeerd.',
    high_speed_title: 'U gaat te snel',
    high_speed_text:
      'Bent u nog aan het varen? U gaat sneller dan 80 km/h, stop de route wanneer u niet meer vaart in de app.'
  },
  mapLayers: {
    last_updated: 'Laatste update',
    past: 'geleden',
    meters: ' meter',
    shipMarker: {
      width: 'Breedte',
      length: 'Lengte',
      heading: 'Koers'
    },
    sabPoints: {
      point: 'Afvalophaalpunt',
      telephoneNumber: 'Telefoonnummer',
      mobileNumber: 'Mobiel telefoonnummer'
    },
    carDropoffPoints: {
      point: 'Autoafzetplaats'
    },
    shorePower: {
      point: 'Walstroompunt'
    },
    berthLayer: {
      withCones: 'Met %{cones} of meer kegels',
      free: 'Vrije ligplaats',
      occupied: 'Bezette ligplaats',
      full: 'Volle ligplaats',
      unknown: 'Ligplaats met onbekende bezetting',
      occupationPercentage: 'Bezetting',
      occupationDisclaimer: 'Berekening door Blauwe Golf Verbindend',
      ship: 'Schip',
      shipsInBerth: 'Schepen op ligplaats',
      unknownLength: 'Onbekende afmetingen',
      interpretationWarning:
        'Schepen met een info icoon zijn geinterpreteerd, de precieze locatie en oriëntatie kan afwijken.',
      meters: ' meter',
      width: 'Breedte',
      length: 'Lengte',
      draught: 'Diepgang',
      berthName: 'Naam ligplaats',
      owner: 'Beheerder',
      dangerousGoodsLevel: 'Kegels',
      harbourName: 'Havennaam',
      terminalName: 'Terminal naam',
      type: 'Type',
      mooringOptions: 'Aanleg opties',
      mooringServices: 'Aanleg services',
      mooringFunctions: 'Aanleg functies',
      mooringHourTimeLimit: 'Aanleg tijdslimiet',
      shoreConnection: 'Walverbinding',
      sort: 'Type',
      viewFotoURL: 'Bekijk foto van ligplaats',
      berth: 'Ligplaats',
      conesAmount: 'Aantal blauwe kegels',
      publicBerth: 'Publieke ligplaats',
      terminalBerth: 'Terminal ligplaats',
      suitableError: 'Schip niet gevonden, kan geen geschikte ligplaatsen vinden',
      shipTypes: {
        shipTypes: 'Scheepstype(n)',
        AKR: 'Anker',
        DBKV: 'Duwbak geladen',
        DBKL: 'Duwbak leeg',
        MVSC: 'Motorvrachtschip Container/bulkschip',
        MVSB: 'Motorvrachtschip Bulk',
        ALLE: 'Alle type schepen (auto afzetplaats)',
        DWB: 'Duwboot',
        FDR: 'Feeder',
        MTS: 'Motortankschip (vloeibare lading)',
        SLB: 'Sleepboot',
        BVA: 'Binnenvaart',
        ZVA: 'Zeevaart'
      }
    }
  },
  pushNotification: {
    buttonClose: 'Oke'
  },
  months: {
    jan: 'Januari',
    feb: 'Februari',
    mar: 'Maart',
    apr: 'April',
    may: 'Mei',
    jun: 'Juni',
    jul: 'Juli',
    aug: 'Augustus',
    sep: 'September',
    oct: 'Oktober',
    nov: 'November',
    dec: 'December'
  },
  weather: {
    title: 'Weer',
    windDirection: 'Windrichting',
    windSpeed: 'Windkracht',
    gusts: 'Windstoten',
    station: 'Meetstation',
    speedUnit: 'km/u',
    date: 'Datum',
    source: 'Bron',
    loading: 'Bezig met laden...',
    noLocationError: 'Kan geen weerbericht ophalen, uw locatie is onbekend.',
    airPressure: 'Luchtdruk',
    airHumidity: 'Luchtvochtigheid',
    sightMeters: 'Zicht (m)',
    temprature10cm: 'Temperatuur',
    rainMiliMetersPerHour: 'Neerslag per uur',
    distanceTo: '~%{distanceTo}km %{heading}'
  },

  information: {
    information: 'Informatie',
    privacyStatement: 'Privacy statement',
    view: 'Bekijk privacyvoorwaarden',
    dataSources: 'Databronnen',
    downloadPrivacyStatement: 'Download Privacy statement',
    sourceExplainer:
      'Voor het maken van de kaartlagen is RiverGuide afhankelijk van de data die wordt geleverd, hierdoor hebben sommige kaartlagen geen volledige dekking over Nederland.',
    layerInformation: 'Kaartlaaginformatie',
    layer: 'Kaartlaag',
    source: 'Bronnen',
    coverageArea: 'Dekkingsgebieden',
    translationNotice: 'Bepaalde informatie wordt automatisch vertaald'
  },
  unit: {
    kmh: 'km/h',
    knots: 'knopen'
  },
  time: {
    hourShort: 'u', // h
    hourFullSingle: 'uur', // hour
    hourFullPlural: 'uur', // hours
    minShort: 'min', // min
    minFullSingle: 'minuut', // minute
    minFullPlural: 'minuten', // minutes
    dayShort: 'd', // d
    daySingle: 'dag', // day
    dayPlural: 'dagen' // days
  },
  routeSimulator: {
    title: 'Route Simulator',
    speed: 'Snelheid',
    distanceLeft: 'Afstand',
    eta: 'ETA',
    interval: 'Interval',
    start: 'Start',
    pause: 'Stop',
    simulationStarted: 'Simulatie gestart',
    simulationFinished: 'Simulatie is beëindigd, de route is afgelopen',
    polylineError: 'Geen posities gevonden in de polyline, simulatie beëindigd.',
    speedError:
      'Ingestelde snelheid is 0 m/s, kan simulatie niet uitvoeren. (AUTO) snelheid is niet ondersteund in de RouteSimulator.',
    startOfRoute: 'Vanaf het begin van de route',
    startFromIndex: 'Vanaf index %{index} op de route'
  },
  source: {
    area: {
      AT: 'Oostenrijk',
      ATDONAU: 'Oostenrijkse Donau',

      // Belgium
      BE: 'België',
      BEANR: 'Haven van Antwerpen (BE)',
      BEVLA: 'Vlaanderen (BE)',
      BEWAL: 'Wallonië (BE)',
      BENOO: 'Noordzee (BE)',

      // Germany
      DE: 'Duitsland',
      DEWAD: 'Waddenzee (DE)',

      FR: 'Frankrijk',
      EU: 'Europa',
      LU: 'Luxemburg',

      // Netherlands
      NL: 'Nederland',
      // Provinces
      NLDR: 'Provincie Drenthe',
      NLFL: 'Provincie Flevoland',
      NLGE: 'Provincie Gelderland',
      NLGR: 'Provincie Groningen',
      NLLI: 'Provincie Limburg',
      NLNB: 'Provincie Noord-Brabant',
      NLNH: 'Provincie Noord-Holland',
      NLOV: 'Provincie Overijssel',
      NLUT: 'Provincie Utrecht',
      NLZL: 'Provincie Zeeland',
      NLZH: 'Provincie Zuid-Holland',

      // Major bodies of water
      NLIJS: 'IJsselmeer',
      NLMAR: 'Markermeer',
      NLNOO: 'Noordzee',
      NLWAD: 'Waddenzee',

      // Ports
      NLAMS: 'Haven van Amsterdam',
      NLRTM: 'Haven van Rotterdam',

      // Specific rivers or channels
      NLMAJOR: 'Grote Nederlandse vaarwegen',
      NLGOUWE: 'Gouwe',
      NLHOLLANDSDIEP: 'Hollands Diep',
      NLJULIANAKANAAL: 'Julianakanaal (Limburg)',
      NLMERWEDEKANAAL: 'Merwedekanaal',
      NLNIEUWEWATERWEG: 'Nieuwe waterweg',
      NLOOSTERSCHELDE: 'Oosterschelde',
      NLOUDERIJN: 'Oude Rijn',
      NLRIJN: 'Rijn',
      NLWAAL: 'Waal',
      NLWESTERSCHELDE: 'Westerschelde'
    }
  },

  phoneNumberButton: {
    title: 'Telefoonnummer'
  },

  notAllowedToPerform: 'Het is voor u niet toegestaan om deze actie uit te voeren.',
  urlDiffers: 'Account is geregistreerd op een andere server %{url}',
  loggedOutDueToURLMismatch:
    'U bent uitgelogd omdat dit account niet op deze server is geregistreerd.',

  tutorial: {
    routePlanning: {
      title: 'Hoe worden routes bepaald?',
      intro:
        'Routes worden bepaald door de scheepdimensies en eventuele opties die u heeft aangegeven in het Opties menu.',
      intro2:
        'Als de door u opgevraagde route niet bevaarbaar is, kan het gebeuren dat de app wel een route laat zien maar met een ' +
        'rode waarschuwing, of dat de app helemaal geen routes laat zien. Controleer de dimensies van uw schip en verifieër of de route' +
        'daadwerkelijk bevaarbaar is (voor een schip van uw grootte).',
      feedback:
        'Mocht de route wel bevaarbaar zijn maar geeft de app deze niet of foutief weer, stuur ons een mail naar: riverguide@teqplay.nl',
      yourDimensions: 'Uw scheepsdimensies:',
      dimensions:
        'Lengte: %{length}m, Breedte: %{width}m, Hoogte: %{height}m, Kieldiepte: %{draught}m',
      changeDimensions: 'Verander scheepdimensies'
    }
  },

  prominentDisclosure: {
    title: 'Locatiegebruik',
    approve: 'Ik ga akkoord',
    reject: 'Ik weiger',
    explanation: 'Deze app gebruikt locatiegegevens om de volgende functies in staat te stellen:',
    feature1: 'Navigeren langs een route',
    feature2: 'Berekenen van routes vanaf/naar uw locatie',
    feature3: 'Berekenen van aankomsttijden tijdens het varen',
    feature4: 'Automatisch aanvragen van brugopeningen',
    feature5: 'Tonen van uw locatie op de kaart',
    background:
      'De app kan deze functionaliteiten mogelijk ook gebruiken wanneer de app zich in de achtergrond bevindt of gesloten is.',
    notProperly:
      'Bij het niet toestaan van deze permissies kan de app mogelijk niet werken zoals bedoeld.'
  },

  shipSearch: {
    placeholder: 'Schip',
    noName: 'Unnamed ship',
    searchExtended: 'Zoek naar een schip met naam, MMSI of ENI nummer',
    noResults: 'Er zijn geen schepen gevonden voor deze zoekopdracht',
    lastAISSignal: 'AIS signaal %{time} voor het laatst ontvangen'
  },

  shipTypes: {
    PILOT_VESSEL: 'Loodsvaartuig',
    SEARCH_AND_RESCUE_VESSEL: 'Zoek- en reddingsactiesvaartuig',
    TUG: 'Sleepboot',
    PORT_TENDER: 'Haventender',
    VESSEL_WITH_ANTI_POLLUTION_FACILITIES: 'Vaartuig met antivervuilingsfaciliteiten',
    LAW_ENFORCEMENT_VESSEL: 'Politievaartuig',
    MEDICAL_TRANSPORT: 'Medisch transport',
    NOT_PARTIES_TO_AN_ARMED_CONFLICT: 'Geen partij tot bewapend conflict',
    PASSENGER: 'Passagiers',
    CARGO: 'Vracht',
    TANKER: 'Tanker',
    HIGHSPEED: 'Hoge snelheid',
    WING_IN_GROUND: 'Ekranoplan',
    OTHER_TYPE: 'Ander type',
    PASSENGER_HAZCAT_A: 'Passagiers HAZCAT A',
    PASSENGER_HAZCAT_B: 'Passagiers HAZCAT B',
    PASSENGER_HAZCAT_C: 'Passagiers HAZCAT C',
    PASSENGER_HAZCAT_D: 'Passagiers HAZCAT D',
    HIGHSPEED_HAZCAT_A: 'Hoge snelheid HAZCAT A',
    HIGHSPEED_HAZCAT_B: 'Hoge snelheid HAZCAT B',
    HIGHSPEED_HAZCAT_C: 'Hoge snelheid HAZCAT C',
    HIGHSPEED_HAZCAT_D: 'Hoge snelheid HAZCAT D',
    TANKER_HAZCAT_A: 'Tanker HAZCAT A',
    TANKER_HAZCAT_B: 'Tanker HAZCAT B',
    TANKER_HAZCAT_C: 'Tanker HAZCAT C',
    TANKER_HAZCAT_D: 'Tanker HAZCAT D',
    CARGO_HAZCAT_A: 'Vracht HAZCAT A',
    CARGO_HAZCAT_B: 'Vracht HAZCAT B',
    CARGO_HAZCAT_C: 'Vracht HAZCAT C',
    CARGO_HAZCAT_D: 'Vracht HAZCAT D',
    OTHER_TYPE_HAZCAT_A: 'Ander type HAZCAT A',
    OTHER_TYPE_HAZCAT_B: 'Ander type HAZCAT B',
    OTHER_TYPE_HAZCAT_C: 'Ander type HAZCAT C',
    OTHER_TYPE_HAZCAT_D: 'Ander type HAZCAT D',
    WING_IN_GROUND_HAZCAT_A: 'Ekranoplan HAZCAT A',
    WING_IN_GROUND_HAZCAT_B: 'Ekranoplan HAZCAT B',
    WING_IN_GROUND_HAZCAT_C: 'Ekranoplan HAZCAT C',
    WING_IN_GROUND_HAZCAT_D: 'Ekranoplan HAZCAT D',
    FISHING: 'Vissersboot',
    TOWING: 'Sleepboot',
    TOWING_BIG: 'Grote sleepboot',
    DREDGING_UNDERWATER_OPS: 'Baggeraar',
    DIVING_OPS: 'Duikoperaties',
    MILITARY_OPS: 'Militaire operaties',
    SAILING: 'Zeilboot',
    PLEASURE_CRAFT: 'Recreatievaarder',
    NOT_AVAILABLE: 'Niet beschikbaar',
    SPARE: 'Reserve',
    UNDEFINED: 'Ongedefinieerd',
    BASESTATION: 'Basisstation',
    MOTOR_YACHT: 'Motorjacht',
    SPEEDBOAT: 'Speedboot',
    SAILING_WITH_MOTOR: 'Zeilboot met motor',
    SPORT_FISHERMEN_VESSEL: 'Sportvisvaartuig',
    SAILING_MOTOR_BIG: 'Grote zeilboot met motor',
    OTHER_RECREATIONAL_VESSEL: 'Ander recreationeel vaartuig'
  },
  announcements: {
    announcements: 'E-meldingen',
    welcome: 'Welkom bij RiverGuide E-meldingen',
    introduction:
      'Voor het gebruik van de E-meldingen in RiverGuide Binnenvaart heeft uw gekoppelde schip een geldig ENI nummer nodig',
    continue: 'Ga door',
    editShip: 'Pas schip aan',
    goBack: 'Ga terug',
    editHull: 'Pas casco aan',
    editMainHull: 'Pas hoofdcasco aan',
    viewHulls: "Casco's",
    optional: 'Optioneel',
    eniCheck: {
      checking: 'Toestemming voor registratie bepalen',
      valid: 'ENI nummer valide',
      missing: 'ENI nummer ontbreekt, vul deze in bij de volgende stap',
      notUsable: 'U kunt E-meldingen via RiverGuide niet gebruiken',
      alreadyLinked: '%{ship} is reeds geregistreerd aan een ander account.',
      editShip: 'Pas uw schip aan of neem contact op met support@teqplay.nl'
    },
    startNavigation: 'Start navigeren',
    navigationTitle: 'Wilt u een route berekenen tussen deze punten?',
    navigationSuffix:
      'Dit start enkel de navigatiefunctie in RiverGuide, uw reis kan worden aangemeld via de "Reis aanmelden" knop.',
    isHullAddress: 'Adres van uw casco',
    hullUpdatedAgo: '%{distance} ververst',
    validRoute: 'Deze route is geldig',
    editRoute: 'Bewerken',
    addRoute: 'Route toevoegen',
    error: {
      present:
        'Er zijn fouten gedetecteerd bij de ingevulde informatie. Controleer alle velden opnieuw.',
      requiredEmpty: 'Dit veld is verplicht',
      requiredEmptyNonZero: 'Dit veld is verplicht met een waarde groter dan 0',
      addressRequired:
        'Adres is optioneel, maar alle velden behalve Bedrijfsnaam zijn verplicht als er 1 is ingevuld',
      requiredDateEmpty: 'De datum specificeren is verplicht',
      requiredTimeEmpty: 'De tijd specificeren is verplicht',
      requiredDateTimeEmpty: 'De datum en tijd specificeren is verplicht',
      requiredRoute: 'Vereist aankomst + vertrek',
      minAmount: '%{amount} is minder dan het minimale toegestane aantal %{minAmount}',
      maxAmount: '%{amount} is meer dan het maximale toegestane aantal %{maxAmount}',
      minCargoAmount: 'Er moet minimaal 1 lading gekoppeld zijn aan een reis',
      fetchError: 'Er ging iets mis bij het ophalen van de data',
      dangerousMismatch:
        'Uw geselecteerde lading is wel gevaarlijk, selecteer %{yes} of verander de soort lading',
      noTravelId: 'Er is geen reis id gekoppeld, de actie kan niet worden uitgevoerd op dit moment',
      awaitingAction: 'Actie kan nog niet worden uitgevoerd omdat er een andere actie gaande is',
      exceededHullCargoCapacity:
        'U heeft het totale laadvermogen van uw casco (%{cargoCapacityTn} Tn) overschreden, alle vracht bij elkaar heeft een gewicht van %{totalCargoWeight} Tn',
      hasActiveVoyage: 'U moet uw huidige reis beëindigen voordat u deze actie kan uitvoeren.',
      hasActiveVoyageNoStart:
        'U moet uw huidige reis beëindigen voordat u uw reis kan opslaan en aanmelden. U kunt wel uw reis opslaan en later aanmelden.',
      exceededMaxDraught: 'De maximale diepgang is 99,99m',
      invalidDate: 'Datum is niet valide',
      invalidNumberValue: 'Getal is niet valide',
      invalidENILength: 'ENI-nummer bestaat altijd uit 8 cijfers.',
      invalidENILengthLeadingZero:
        'Hint: ENI-nummers uit Nederland, België, Frankrijk, Duitsland en Zwitserland beginnen met een 0.',
      cantBeFloat: 'Getal kan niet bestaan uit decimale waarden',
      noMainHull: 'Account heeft geen hoofdcasco',
      sameMainHullENI: 'Er bestaat al een casco met deze ENI',
      noEniOnHull: 'Casco heeft geen ENI nummer',
      noMMSIOnHull: 'Casco heeft geen MMSI nummer',
      noHullsFound: "Er zijn geen casco's gevonden",
      noHullSelected: 'U heeft geen casco geselecteerd',
      noConvoyTypeSelected: 'Samensteltype is verplicht bij een samengesteld casco',
      unboundCargo:
        'Casco verbonden aan enkele lading(en) is niet meer gekoppeld aan de varende eenheid.'
    },
    hullCreation: {
      required: 'Verplicht',
      titleCreate: 'Voltooi registratie',
      titleNew: 'Nieuw casco',
      titleUpdate: 'Update casco',
      editHull: 'Wijzig casco',
      editMainHull: 'Wijzig hoofdcasco',
      newHull: 'Nieuw casco',
      explanation:
        'Met uw ENI nummer hebben wij de volgende informatie weten in te vullen. Om uw registratie te voltooien, vul de volgende velden in:',
      changeEniWarning:
        'Om uw ENI-nummer te wijzigen, past u uw schip aan in de Instellingen van RiverGuide Binnenvaart.',
      deleteHull: 'Verwijder casco',
      errorNoENI: 'Casco heeft geen ENI en kan niet verwijderd worden',
      warnENIEdited:
        'Uw ENI is aangepast maar niet opgeslagen, sla deze op of maak de wijzigingen ongedaan om het schip te verwijderen.',
      deleteConfirmation: 'Weet u zeker dat u dit casco wil verwijderen?',
      notMatchingMMSI:
        'Het ingevulde MMSI (%{filledMMSI}) is niet gelijk aan het MMSI van uw AIS signaal (%{aisMMSI})',
      lockedMMSI:
        'Het MMSI van uw hoofdcasco moet overeenkomen met het MMSI wat verbonden is aan uw RiverGuide account. Om dit te wijzigen, wijzigt u van schip in de Instellingen van RiverGuide en maakt u voor dat schip een casco aan.'
    },
    travelList: {
      title: 'Ingevoerde reizen',
      subtitle: 'E-meldingen',
      active: 'Actief en gepland',
      inactive: 'Voltooide reizen',
      startNavigation: 'Start navigeren',
      newTravel: 'Nieuwe reis',

      status: {
        PLANNED: 'Ingepland',
        SENT: 'Verstuurd',
        ANSWERED_OK: 'Aangemeld',
        ANSWERED_OK_CANCELLED: 'Beëindigd',
        ANSWERED_OK_PAUSED: 'Gepauzeerd',
        ANSWERED_REJECT: 'Niet aangemeld',
        PENDING: 'In afwachting',
        PENDING_PAUSE: 'In afwachting van pauzeren reis',
        UNKNOWN: 'Onbekend'
      }
    },
    travel: {
      via: 'via',
      new: 'Nieuwe reis',
      edit: 'Reis aanpassen',
      currentVoyage: 'Huidige reis',
      view: 'Bekijk reis',
      tripInfo: 'Reisinformatie',
      departure: 'Vertrek',
      arrival: 'Bestemming',
      viaPoint: 'Via punt',
      exchange: 'Wissel om',
      goodsOnBoard: 'Lading aan boord',
      addGoods: 'Lading toevoegen',
      viewGoods: 'Lading',
      deleteGoods: 'Verwijderen',
      billingInfo: 'Factuuradres',
      editForThisTravel: 'Pas aan voor deze reis',
      save: 'Opslaan',
      delete: 'Reis verwijderen',
      useAsTemplate: 'Gebruik reis als sjabloon voor nieuwe reis',
      announce: 'Opslaan en reis aanmelden',
      noGoodsAdded: 'Er is geen lading toegevoegd',
      editAddress: 'Klik om factuuradres in te vullen',
      editForJourney: 'Pas factuuradres aan voor deze reis',
      startNavigating: 'Start navigeren',
      startInBics: 'Reis aanmelden',
      pauseInBics: 'Reis pauzeren',
      stopInBics: 'Reis stopzetten',
      deleteConfirmation: 'Weet u zeker dat u deze reis wilt verwijderen?',
      sentVoyageConfirmation:
        'De vaarwegautoriteiten staan slechts één actieve e-melding tegelijk toe. Het registreren hiervan kan tot afwijzing leiden. Wilt u doorgaan?',
      started: 'Uw reis is gestart',
      paused: 'Uw reis is gepauzeerd',
      stopped: 'Uw reis is gestopt',
      planned: 'Geplande reizen',
      current: 'Huidige reis',
      noCargo: 'Geen lading aan boord',
      resetState: 'Maak alle velden leeg',
      noTravels: 'U heeft nog geen reizen aangemaakt',
      noAddress: 'Geen factuuradres opgegeven',
      none: 'Geen adres',
      removeLocalAddress: 'Verwijder adressen',
      deleteAddressesConfirm:
        'Dit verwijdert %{amount} %{singularplural} van uw apparaat. Dit verwijdert niet de adressen die aan andere reizen zijn gekoppeld. Weet u dit zeker?',
      addressSingular: 'adres',
      addressPlural: 'adressen',
      newAddress: 'Nieuw adres',
      selected: 'Geselecteerd',
      onlyExecptions: 'alleen te gebruiken voor reizen door België, Frankrijk en Duitsland',
      variousCargo: 'aan verschillende ladingen',
      locked: 'U kunt op dit moment geen wijzigingen aan uw reis aanbrengen',
      partialEdit: 'U kunt slechts enkele velden wijzigen van deze reis.',
      unsavedChanges: 'U heeft uw wijzigingen niet opgeslagen. Weet u zeker dat u terug wilt gaan?',
      unsavedChangesNew:
        'U heeft uw nieuwe reis niet opgeslagen. Weet u zeker dat u terug wilt gaan?',
      viaCount: 'via %{viaCount} andere bestemmingen',
      sailingUnity: 'Varende eenheid',
      hullProperties: 'Samenstel-eigenschappen',
      addHull: 'Casco toevoegen',
      selectHull: 'Selecteer casco',
      clickToSelectHullForCargo: 'Klik de lading aan een casco toe te wijzen',
      clickToAddHull: 'Klik om een casco toe te voegen',
      willClearSelectedHulls: 'Weet u zeker dat u enkel met uw hoofdcasco wil varen?',
      willSwitchToCombinedHulls:
        'Dit zal uw cascotype naar Samengesteld veranderen. Weet u dit zeker?',
      unboundWarning: 'Casco %{named} is niet meer aan de reis gekoppeld',
      otherWeight:
        '%{hull} heeft een laadvermogen van %{cargoCapacity} Tn, waarvan gebruikt deze reis: %{totalWeight} Tn',
      combinedDimensions: 'Afmetingen',
      combinedDimensionsExplain:
        'U kunt de afmetingen van uw varende eenheid aanpassen, zonder uw casco afmetingen aan te passen.',
      overrideDimensions: 'Pas dimensies toe',
      overrideWithLargestLength: "Alle casco's in de lengte",
      overrideWithLargestWidth: "Alle casco's in de breedte",
      totalWidth: 'Totale breedte',
      totalLength: 'Totale lengte',
      createNewHullSave:
        'Weet u zeker dat u dit scherm wilt verlaten? Wijzigingen worden mogelijk niet opgeslagen.'
    },
    fields: {
      general: 'Algemene info',
      dimensions: 'Scheepsafmetingen',
      billingInfo: 'Factuuradres',
      eni: 'ENI-nummer',
      eniShort: 'ENI',
      mmsi: 'MMSI',
      name: 'Schip naam',
      nationality: 'Nationaliteit',
      clickToSelectCountry: 'Selecteer een land',
      ownerAddress: 'Factuuradres',
      type: 'Cascotype',
      clickToSelectType: 'Selecteer een cascotype',
      mode: 'Transportmodus',
      INLAND: 'Binnenvaart',
      SEA: 'Zeevaart',
      unknown: 'Onbekend',
      lengthM: 'Lengte',
      widthM: 'Breedte',
      maxDraughtM: 'Maximale diepgang',
      heightM: 'Hoogte',
      lengthBowToBridgeM: 'Lengte boeg tot boeg',
      cargoCapacityTn: 'Laadvermogen',
      deadWeightTn: 'Bruto tonnage',
      doubleWalled: 'Dubbelwandig',
      callSign: 'Roepnaam',
      placeOfRegistration: 'Registratieplaats',
      dateOfRegistration: 'Registratiedatum',
      fuelType: 'Brandstoftype',
      clickToSelectFuelType: 'Selecteer een brandstoftype',
      NONE: 'Geen',
      LNG: 'LNG',
      HYDROGEN: 'Waterstof',
      ELECTRICITY: 'Elektriciteit',
      LOW_SULPHUR_FUEL: 'Laagzwavelige brandstof',
      DIESEL: 'Diesel',
      OTHER: 'Anders',
      ownerName: 'Eigenaar',
      meter: 'meter',
      tonnage: 'Tn',
      m3: 'm³',
      transportType: 'Transporttype',
      addressName: 'Naam',
      givenName: 'Voornaam',
      familyName: 'Achternaam',
      street: 'Straat en huisnummer',
      streetName: 'Straat en huisnummer',
      postcode: 'Postcode',
      postalCode: 'Postcode',
      city: 'Woonplaats',
      country: 'Land',
      vatNumber: 'BTW-nummer',
      organisation: 'Bedrijfsnaam',
      organisationShort: 'Bedrijf',
      organisationHint:
        'Als de bedrijfsnaam wordt leeggelaten wordt er ter attentie van uw casconaam gefactureerd.',
      organisationPlaceholder: 'c/o %{hullName}',
      optional: 'Optioneel',
      fromLocation: 'Vertrek',
      toLocation: 'Aankomst',
      firstReportingPoint: 'Eerste meldpunt',
      riverguideSelectedRoute: 'Route',
      startTime: 'Datum vertrek',
      endTime: 'Datum aankomst',
      crewOnBoard: 'Bemanning aan boord',
      paxOnBoard: 'Passagiers aan boord',
      personsOnBoard: 'Personen aan boord',
      weightTn: 'Gewicht',
      volumeM3: 'Volume',
      dangerousGoods: 'Gevaarlijke stoffen',
      dangerous: 'Gevaarlijke stoffen',
      hsCode: 'Ladingsoort',
      personalName: 'Naam en achternaam',
      cones: 'Kegels',
      currentDraught: 'Huidige diepgang',
      residual: 'Leeg van',
      hulls: "Casco's",
      singleHull: 'Enkel casco',
      multiHull: 'Samenstel',
      convoyType: 'Samensteltype',
      shipId: 'Casco',
      fullName: 'Naam',
      address: 'Adres',
      vatNumberShort: 'BTW #'
    },
    hullSelect: {
      title: "Uw casco's",
      subtitle: 'Klik op een casco om deze aan te passen',
      createHull: 'Maak nieuw casco aan',
      mainHull: 'Hoofdcasco'
    }
  },
  bicsFeature: {
    title: 'We hebben de E-meldingen geüpdatet!',
    description:
      'De nieuwe E-meldingen zijn bijgewerkt met een gloednieuwe look en verbeterde functies, die u in het nieuwe tabbladsectie zult vinden.',
    dontShowAgain: 'Toon dit niet meer',
    ok: 'Oké',
    show: 'Toon E-meldingen'
  },
  countrySelect: {
    placeholder: 'Selecteer een land',
    noOptions: 'Er zijn geen landen gevonden voor "%{query}"'
  },
  destinationSelect: {
    placeholder: 'Selecteer een bestemming',
    placeholderDeparture: 'Selecteer een vertreklocatie',
    placeholderVia: 'Selecteer een via-punt',
    placeholderArrival: 'Selecteer een aankomstlocatie',
    noOptions: 'Er zijn geen bestemmingen gevonden voor "%{query}"',
    derivedFromProviderId: 'Automatisch toegepast op basis van vertreklocatie',
    errorNotFound: 'Kon eerste meldpunt niet toewijzen op basis van verkeersPostId %{pid}',
    warningMax: 'Maximaal 5 via-punten',
    destinationEstimate:
      'Er zijn meerdere bestemmingen gevonden voor %{id}, de ingevulde bestemming is een schatting'
  },
  goodsSelect: {
    placeholder: 'Zoek goederen',
    noOptions: 'Er is geen goederen gevonden voor "%{query}"',
    vnNr: 'VN #',
    dangerousClass: 'Klasse',
    classification: 'Classif',
    packingGroup: 'V.gr',
    table: 'Tabel',
    showAll: 'Toon alle ladingsoorten',
    estimateCargo:
      'Er zijn meerdere ladingen gevonden voor %{id}, het ingevulde ladingtype is een schatting'
  },
  hullSelect: {
    placeholder: 'Selecteer casco',
    noOptions: 'Er is geen casco gevonden voor "%{query}"',
    unboundShipId:
      'Lading was gebonden aan een casco wat niet meer aan de reis is gebonden. Selecteer opnieuw een casco.'
  },
  transportSelect: {
    placeholder: 'Selecteer transporttype',
    BULK: 'Bulk',
    CONTAINER: 'Container',
    TANKER: 'Tanker'
  },
  shipTypeSelect: {
    placeholder: 'Selecteer scheepstype',
    estimate:
      'Er zijn meerdere scheepstypen gevonden voor %{id}, het ingevulde scheepstype is een schatting',
    noOptions: 'Er is geen scheepstype gevonden voor "%{query}"'
  },
  destinationPresetList: {
    showPresets: 'Toon routelocaties',
    hidePresets: 'Verberg routelocaties',
    departure: 'Vertrekpunt route',
    viaIndex: 'Viapunt %{viaIndex}',
    arrival: 'Aankomstpunt route'
  },
  datetime: {
    clickToAddDate: 'Klik om een datum te selecteren',
    clickToAddTime: 'Klik om een tijd te selecteren',
    clear: 'Maak leeg',
    date: 'Datum',
    time: 'Tijd',
    selectDate: 'Selecteer datum',
    selectDateTime: 'Selecteer datum en tijd',
    fullFormat: 'dd-MM-jjjj UU:mm',
    dateFormat: 'dd-MM-jjjj'
  },
  aisPage: {
    title: 'AIS informatie',
    staticInfo: 'Statische informatie',
    dynamicInfo: 'Dynamische informatie',
    kmh: 'kilometer per uur',
    knots: 'knopen',
    lastSignal: 'AIS signaal is %{time} voor het laatst ontvangen.',
    lastSignalNotUptoDate:
      'Uw AIS signaal is niet up-to-date. Controleer uw AIS transponder of deze aan staat en correct werkt.',
    latestDataFetched: 'Laatst bekende AIS signaal succesvol opgehaald',
    latestDataFetchedError:
      'Er ging iets mis bij het ophalen van het laatst bekende AIS signaal. Controleer uw internetverbinding en probeer het opnieuw.',
    refresh: 'Vernieuw',
    viewOnMap: 'Bekijk op de kaart',

    aisVersion: 'AIS versie',
    alternateDraught: 'Alternatieve diepgang',
    callsign: 'Roepletters',
    category: 'Categorie',
    coms: 'COMS',
    combinationType: 'Combinatie type',
    communicationState: 'Communicatiestatus',
    courseOverGround: 'Koers',
    dataTerminalEquipped: 'Data terminal aan boord',
    destination: 'Bestemming',
    draught: 'Diepgang',
    eni: 'ENI',
    eta: 'ETA',
    hazardCode: 'Gevarencode',
    height: 'Hoogte',
    id: 'ID',
    imo: 'IMO',
    imoNumber: 'IMO',
    isSeaVessel: 'Zeeschip',
    lastUpdate: 'Laatst geüpdatet',
    latitude: 'Lengtegraad',
    length: 'Lengte',
    loadedStatus: 'Gelade status',
    longitude: 'Breedtegraad',
    maxDraught: 'Maximale diepgang',
    mmsi: 'MMSI',
    name: 'Scheepsnaam',
    posAccuracyMeters: 'Positieaccuraatheid in meters',
    positionAccuracy: 'Positieaccuraatheid',
    positionSensorType: 'Positiesensortype',
    shipType: 'Schip type',
    specialManeuverIndicator: 'Indicator voor speciale maneuvre',
    speedOverGround: 'Snelheid',
    source: 'Bron',
    status: 'Status',
    timeLastUpdate: 'Laatst geüpdatet',
    rateOfTurn: 'Draaisnelheid',
    role: 'Rol',
    usingRaim: 'Gebruikt RAIM',
    width: 'Breedte',
    visible: 'Zichtbaar'
  }
}

export default dutchLocale
