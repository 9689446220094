import React from 'react'
import { I18n } from 'react-redux-i18n'

import CustomNumberInput from '../customNumberInput/CustomNumberInput'
import { ICustomError } from '../../@types/types'

const DimensionRow = ({
  dimension,
  value,
  onChange,
  errors
}: {
  dimension: 'length' | 'width' | 'height' | 'draught'
  value: number | string | undefined
  onChange: (num: string | number | undefined) => void
  errors: ICustomError<'length' | 'width' | 'height' | 'draught'>[]
}) => (
  <>
    <div className="dimension-wrapper">
      <label
        className={`dimension-label ${
          errors.filter(e => e.key === dimension).length > 0 ? 'errored' : ''
        }`}
      >
        {I18n.t(`confirmDimensions.${dimension}`)}
      </label>
      <CustomNumberInput
        className="textfield"
        value={value}
        setValue={e => onChange(e)}
        step="0.01"
      />
      <div className="suffix">{I18n.t('confirmDimensions.meters')}</div>
    </div>
    {errors
      .filter(e => e.key === dimension)
      .map(e => (
        <div className="error-message" key={`${e.key}-${e.error}`}>
          {e.error}
        </div>
      ))}
  </>
)

export default DimensionRow
