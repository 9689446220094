import Popup from 'react-popup'
import { I18n } from 'react-redux-i18n'

interface IConfirmPopupOptions {
  message: string | any
  title?: string
  confirmText?: string
  cancelText?: string
  confirmClassName?: string
  cancelClassName?: string
  onConfirm: () => void
  onCancel?: () => void
  className?: string
  closeOnOutsideClick?: boolean
  noCancel?: boolean
}

/**
 * Open a confirm popup using react-popup
 * showing a message and a Cancel and Ok button.
 */
export function confirmPopup({
  message,
  title,
  className,
  confirmText,
  cancelText,
  confirmClassName,
  cancelClassName,
  onConfirm,
  onCancel,
  closeOnOutsideClick,
  noCancel
}: IConfirmPopupOptions) {
  Popup.create({
    title,
    content: message,
    className,
    closeOnOutsideClick,
    buttons: {
      left: noCancel
        ? []
        : [
            {
              text: cancelText || I18n.t('no'),
              className: cancelClassName || 'cancel button large secondary',
              id: 'button-cancel',
              key: 'esc',
              action: () => {
                Popup.close()

                if (onCancel) {
                  onCancel()
                }
              }
            }
          ],
      right: [
        {
          text: confirmText || I18n.t('yes'),
          id: 'button-confirm',
          className: confirmClassName || 'button large primary',
          key: 'enter',
          action: () => {
            Popup.close()

            onConfirm()
          }
        }
      ]
    }
  })
}

export function createAnonymousPopup(onConfirm: () => void) {
  confirmPopup({
    message: I18n.t('anonymous.notAllowed'),
    onConfirm,
    confirmText: I18n.t('anonymous.createAccount'),
    cancelText: I18n.t('anonymous.cancel'),
    closeOnOutsideClick: true
  })
}
