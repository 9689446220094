import { divIcon as DIVIcon, LatLngBounds } from 'leaflet'
import React, { useEffect, useState } from 'react'
import { I18n } from 'react-redux-i18n'

import TeqplayApiService from '../../../../services/TeqplayAPIService/TeqplayApiService'
import DefaultMarkersLayer from './defaultMarkerLayer/DefaultMarkerLayer'
import CollapsableLegendComponent from '../../controls/CollapsableLegendComponent'

import { IBridgeMovement } from '../../../../services/TeqplayAPIService/TeqplayApi'
import { IBridgeDetailsExtended } from '../../../../@types/types'

interface IProps {
  name: string
  checked: boolean
  bounds?: LatLngBounds
  teqplayApiService: TeqplayApiService
  mapZoomLevel: number
  minimumZoomLevel: number
  bridgeMovement?: IBridgeMovement[]
  setSelectedItem: (item: IBridgeDetailsExtended) => void
}

const BridgesLayer: React.FunctionComponent<IProps> = props => {
  const [bridgeMovements, setBridgeMovements] = useState<IBridgeMovement[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  // const [error, setError] = useState('')

  if (!props.checked || props.mapZoomLevel < props.minimumZoomLevel) {
    return null
  }

  return (
    <React.Fragment>
      <DefaultMarkersLayer<IBridgeDetailsExtended>
        fetchCall={(bounds?: LatLngBounds) => handleBridgeStatusInjection(bounds)}
        intervalFetch={{
          fetchCall: (bounds?: LatLngBounds) => handleBridgeStatusInjection(bounds, true),
          intervalMS: 60000
        }}
        fontIconClass="icon-bridge-open"
        backgroundColor="#2e6771"
        onClick={props.setSelectedItem}
        determineDivIcon={i => {
          const bridgeDivIcon = (status: 'open' | 'closed' | 'solid') =>
            DIVIcon({
              className: '',
              html: `<div class='circle-icon bridge gray'><span class='icon-bridge-${status}'></span></div>`,
              iconAnchor: [20, 20]
            })

          if (i.bridgeMovement?.status === 'OPEN') {
            return bridgeDivIcon('open')
          } else if (i.bridgeMovement?.status === 'CLOSED') {
            return bridgeDivIcon('closed')
          } else if (!i.canOpen) {
            return bridgeDivIcon('solid')
          } else {
            return bridgeDivIcon('closed')
          }
        }}
        {...props}
      />
      <CollapsableLegendComponent
        icon={<span className="icon-bridge-open" />}
        position={'topright'}
        id={props.name}
      >
        <div className="item-legend">
          <div className="name">{I18n.t('map.bridges.open')}</div>
          <div className="icon">
            <div className="circle-icon bridge gray">
              <span className="icon-bridge-open" />
            </div>
          </div>
        </div>
        <div className="item-legend">
          <div className="name">{I18n.t('map.bridges.closed')}</div>
          <div className="icon">
            <div className="circle-icon bridge gray">
              <span className="icon-bridge-closed" />
            </div>
          </div>
        </div>
        <div className="item-legend">
          <div className="name">{I18n.t('map.bridges.solid')}</div>
          <div className="icon">
            <div className="circle-icon bridge gray">
              <span className="icon-bridge-solid" />
            </div>
          </div>
        </div>
      </CollapsableLegendComponent>
    </React.Fragment>
  )

  async function handleBridgeStatusInjection(
    bounds?: LatLngBounds,
    forceFetchMovements?: boolean
  ): Promise<IBridgeDetailsExtended[]> {
    try {
      if (!loading) {
        setLoading(true)
        const bridgesInBounds = await props.teqplayApiService.fetchBridges(bounds)
        let movements = props.bridgeMovement !== undefined ? props.bridgeMovement : bridgeMovements

        if (props.bridgeMovement === undefined && (movements.length === 0 || forceFetchMovements)) {
          movements = await props.teqplayApiService.fetchBridgeMovement()
          setBridgeMovements(movements)
        }

        setLoading(false)

        const mappedWithStatus = !movements
          ? bridgesInBounds
          : bridgesInBounds.map((b, i) => {
              const matchedBridge = movements?.find(boi => boi.isrsId === b.isrsCode)
              if (matchedBridge) {
                return { ...b, bridgeMovement: matchedBridge, status: matchedBridge.status }
              } else {
                return b
              }
            })

        return await Promise.resolve(mappedWithStatus)
      } else {
        return []
      }
    } catch (error) {
      console.error(error)
      setLoading(false)
      // setError(error.message)
      return []
    }
  }
}

/**
 * Unused but usable for just the bridgemovements
 * @param apiService
 * @param isChecked
 * @param onFinish
 */
export function useBridgeMovement(
  apiService: TeqplayApiService,
  isChecked?: boolean,
  onFinish?: (bridgeMovements: IBridgeMovement[]) => void
) {
  const [bridgeMovements, setBridgeMovements] = useState<IBridgeMovement[]>([])
  const [loadingBridgeMovements, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<any | null>(null)

  useEffect(() => {
    async function fetchBridgeMovements() {
      try {
        setError(null)
        setLoading(true)
        const movements = await apiService.fetchBridgeMovement()
        setBridgeMovements(movements)

        if (onFinish) {
          onFinish(movements)
        }
      } catch (err) {
        setError(err)
        throw err
      }
    }

    // Double inversion to prevent any null or undefined values
    if (isChecked === true && !loadingBridgeMovements && bridgeMovements.length === 0) {
      fetchBridgeMovements()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isChecked])

  return { bridgeMovements, loadingBridgeMovements, error }
}

export default BridgesLayer
