import React, { useEffect, useState } from 'react'

import TeqplayApiService from '../../../../../../services/TeqplayAPIService/TeqplayApiService'
import { RWSLegend } from '../../../../../../@types/types'
import { RWS_CURRENTS_FALLBACK_LEGEND } from '../CurrentsUtils.ts/CurrentsUtils'

import './CurrentColorLegend.scss'

interface IProps {
  teqplayApiService: TeqplayApiService
}

function CurrentColorLegend(props: IProps) {
  const [legendData, setLegendData] = useState<RWSLegend[]>(RWS_CURRENTS_FALLBACK_LEGEND)
  const units: number[] = [0, 0.3, 0.6, 1, 2, 3]

  useEffect(() => {
    fetchGradeLegend()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Create gradient stops in percentage
  const gradientStops =
    legendData &&
    legendData
      .map((item, index) => {
        const cumulativePercentage = (index + 1) * (100 / legendData.length)
        return `${item.color} ${cumulativePercentage}%`
      })
      .join(', ')

  return (
    <div className="legend-container">
      <div
        className="gradient-legend"
        style={{ background: `linear-gradient(to right, ${gradientStops})` }}
      />
      <div className="legend-labels">
        {units.map((label, index) => (
          <div key={index} className="legend-label">
            <div className="legend-line" />
            <div className="legend-number">{label}</div>
          </div>
        ))}
      </div>
    </div>
  )

  async function fetchGradeLegend() {
    try {
      const grades = await props.teqplayApiService.fetchCurrentGrade()
      setLegendData(grades.legend)
    } catch (err) {
      console.error(err)
    }
  }
}

export default CurrentColorLegend
