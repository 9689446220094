import orderBy from 'lodash/orderBy'
import React, { useEffect, useState } from 'react'
import { connect, MapDispatchToPropsFunction, useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { Redirect, useHistory, useLocation } from 'react-router'
import { toast } from 'react-toastify'
import { Action } from 'redux'
import { ThunkDispatch } from 'redux-thunk'

import DimensionsModal from '../../components/dimensionsModal/DimensionsModal'
import LoadingIndicator from '../../components/loadingIndicator/LoadingIndicator'
import MapRouteSelection from '../../components/mapRouteSelection/MapRouteSelection'
import NavigationFooter from '../../components/navigationFooter/NavigationFooter'
import RouteSelect from '../../components/routeSelect/RouteSelect'
import TeqplayApiService from '../../services/TeqplayAPIService/TeqplayApiService'
import RequestUseActiveRouteModal from './requestUseActiveRouteModal/RequestUseActiveRouteModal'
import TitleComponent from '../../components/titleComponent/TitleComponent'

import {
  IApplicationTheme,
  ILayerCodeName,
  ILayerCoverageDetails,
  ILocationStatus,
  IRootProps,
  IRouteSearchProps,
  ITabs,
  MapTypes,
  RouteSelectionFields
} from '../../@types/types'
import { setActiveLayers, setActiveMap } from '../../components/mapShared/actions'
import { setUserInputSpeed } from '../../components/mapShared/setSpeed/actions'
import { usePrevious } from '../../hooks/usePrevious'
import { useSessionStorage } from '../../hooks/useSessionStorage'
import { useAnalytics } from '../../services/AnalyticsWrapper/AnalyticsWrapper'
import {
  INavigationLocation,
  IRoute,
  IRouteLocation,
  ISelectedRoute,
  IShipInfo
} from '../../services/TeqplayAPIService/TeqplayApi'
import { determineApplicationTheme } from '../../utils/style'
import { convertObjectToQueryString, convertQueryStringToObject } from '../../utils/url'
import { clearAuth } from '../loginPage/actions'
import { setSuppressedWarningsForLayer } from '../settings/actions'
import { clearNavigationRoute, setNavigationRoute } from './actions'
import { DEFAULT_ROUTE_SEARCH } from '../../utils/constants'
import { useWindowDimensions } from '../../hooks/useWindowDimensions'

import './RouteSelectionPage.scss'

interface IProps {
  currentLocation: IShipInfo | null
  teqplayAPIService: TeqplayApiService
  theme: IApplicationTheme
  setStatusbarTheme: (theme?: IApplicationTheme) => void
  locationStatus: ILocationStatus
}

interface IDispatchProps {
  handleClearAuth: () => void
  handleSetNavigationRoute: (route: ISelectedRoute) => void
  handleClearNavigationRoute: () => void
  handleSetUserInputSpeed: (speed: number) => void
  handleSetActiveMap: (activeMap: MapTypes) => void
  handleSetSuppressedWarningsForLayer: (
    l: ILayerCodeName,
    prompts: { [prompt in keyof ILayerCoverageDetails['prompts']]: boolean }
  ) => void
  handleSetActiveLayers: (layers: ILayerCodeName[]) => void
}

const RouteSelectionPage = ({
  currentLocation,
  teqplayAPIService,
  theme,
  setStatusbarTheme,
  locationStatus,

  handleClearAuth: dispatchClearAuth,
  handleClearNavigationRoute: dispatchClearNavigationRoute,
  handleSetActiveLayers: dispatchSetActiveLayers,
  handleSetActiveMap: dispatchSetActiveMap,
  handleSetNavigationRoute: dispatchSetNavigationRoute,
  handleSetSuppressedWarningsForLayer: dispatchSetSuppressedWarningsForLayer,
  handleSetUserInputSpeed: dispatchSetUserInputSpeed
}: IProps & IDispatchProps) => {
  const analytics = useAnalytics('RouteSelectionPage')
  const history = useHistory()
  const location = useLocation()
  const {
    currentUser,
    routeSelection: routeSelectionProps,
    map,
    userLocation,
    settings,
    speed,
    i18n
  } = useSelector((props: IRootProps) => props)
  const previousRouteSelectionProps = usePrevious(routeSelectionProps)

  const [loading, setLoading] = useState<boolean>(false)
  const [routeSelectionFormActive, setRouteSelectionFormActive] = useState<boolean>(false)
  const [routeSelectionFormFieldActive, setRouteSelectionFormFieldActive] = useState<boolean>(false)
  const [selectedField, setSelectedField] = useState<RouteSelectionFields | null>(null)
  const [selectedViaRouteIndex, setSelectedViaRouteIndex] = useState<number | null>(null)
  const [navigationLocations, setNavigationLocations] = useState<INavigationLocation[]>([])
  const [routeSelection, setRouteSelection] = useState<IRouteSearchProps>(DEFAULT_ROUTE_SEARCH)
  const [departureTime, setDepartureTime] = useState<number | null>(null)
  const [selectedRouteSuggestion, setSelectedRouteSuggestion] = useState<IRoute | null>(null)
  const [routeSuggestions, setRouteSuggestions] = useState<IRoute[] | null>(null)
  const [BICSRoute, setBICSRoute] = useSessionStorage<IRoute | null>('BICSRoute', null)
  const [requestUseActiveRoute, setRequestUseActiveRoute] = useState<boolean>(false)
  const [showShipDimensionsDialog, setShowShipDimensionsDialog] = useState<
    undefined | 'help' | 'routeSubmit'
  >(undefined)
  const [hideMapControlButtons, setHideMapControlButtons] = useState<boolean>(false)
  const [selectedTab, setSelectedTab] = useSessionStorage<ITabs>('TAB', 'ROUTE')
  const customBICSRoute =
    routeSelectionFormActive && BICSRoute && selectedTab === 'BICS' ? [BICSRoute] : null
  const { width } = useWindowDimensions()
  const showMobile = width <= 500

  useEffect(() => {
    analytics.setScreen('routeselection')
    analytics.setThemeProperty(
      determineApplicationTheme(settings.listenToDeviceTheme, settings.themeOverride)
    )

    changeStatusbarForNavigationPage('dark')
    fetchAllNavigationLocations()
    checkActiveRoutes()

    // Upon mounting component and no previous fromLocation set, set fromLocation to current location
    if (routeSelection.fromLocation === null && currentLocation?.location) {
      setFromLocation({
        displayName: I18n.t('routeSelection.currentLocation'),
        coordinates: null,
        isCurrentLocation: true
      })
    }

    const queryObject = convertQueryStringToObject(location.search)
    if (queryObject.fromLocation || queryObject.toLocation) {
      handleStartRouteFromQuery(
        queryObject.fromLocation ? JSON.parse(queryObject.fromLocation) : undefined,
        queryObject.viaPoints ? JSON.parse(queryObject.viaPoints) : undefined,
        queryObject.toLocation ? JSON.parse(queryObject.toLocation) : undefined
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const queryObject = convertQueryStringToObject(location.search)

    if (
      !queryObject.fromLocation &&
      !queryObject.toLocation &&
      routeSelection.fromLocation === null &&
      routeSelection.toLocation === null &&
      currentLocation?.location
    ) {
      setFromLocation({
        displayName: I18n.t('routeSelection.currentLocation'),
        coordinates: null,
        isCurrentLocation: true
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [routeSelection, currentLocation])

  useEffect(() => {
    if (
      routeSelectionProps.navigationRoute?.paused === true && // check if currently paused
      (!previousRouteSelectionProps?.navigationRoute ||
        !previousRouteSelectionProps.navigationRoute.paused) // check if wasn't already paused
    ) {
      // is now paused
      changeStatusbarForNavigationPage('light')
    } else if (
      (routeSelectionProps.navigationRoute &&
        routeSelectionProps.navigationRoute.paused === false) === true && // check if currently NOT paused
      previousRouteSelectionProps?.navigationRoute &&
      previousRouteSelectionProps.navigationRoute.paused === true // check if wasn't paused already
    ) {
      changeStatusbarForNavigationPage('dark')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [routeSelectionProps, previousRouteSelectionProps])

  useEffect(() => {
    if (location) {
      if (location.pathname === '/announcements') {
        setSelectedTab('BICS')
        setRouteSelectionFormActive(true)
      }
      if (location.pathname === '/route') {
        setSelectedTab('ROUTE')
        setRouteSelectionFormActive(true)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  if (!currentUser?.auth?.token) {
    dispatchClearAuth()
    return <Redirect to={`/login${location.search ? location.search : ''}`} push />
  }

  return (
    <div
      className={`page-wrapper route-selection-page ${
        routeSuggestions && routeSuggestions.length > 0 ? 'selecting' : 'browsing'
      }`}
    >
      <TitleComponent
        title={
          routeSuggestions
            ? I18n.t('title.routeSelectionPicker', {
                from: routeSelection.fromLocation?.displayName,
                via:
                  routeSelection.viaRoutes.length > 0
                    ? I18n.t('title.routeSelectionPickerVia', {
                        number: routeSelection.viaRoutes.length
                      })
                    : '',
                to: routeSelection.toLocation?.displayName
              })
            : I18n.t('title.routeSelection')
        }
      />
      <LoadingIndicator loading={loading} />

      <RequestUseActiveRouteModal
        showModal={requestUseActiveRoute}
        closeModal={() => setRequestUseActiveRoute(false)}
        handleStopRoute={() => {
          teqplayAPIService.stopRoute()
          dispatchClearNavigationRoute()
        }}
      />

      {showShipDimensionsDialog && (
        <DimensionsModal
          teqplayAPIService={teqplayAPIService}
          userId={currentUser.auth.username}
          ship={currentLocation}
          isOpenSource={showShipDimensionsDialog}
          onClose={() => setShowShipDimensionsDialog(undefined)}
          onSubmit={() => {
            if (showShipDimensionsDialog === 'routeSubmit') {
              searchAvailableRoutes()
            }
            setShowShipDimensionsDialog(undefined)
          }}
          onUpdateDimensionsInState={locationStatus.refreshLocation}
        />
      )}

      <NavigationFooter
        userProfile={currentUser.userProfile}
        shipName={userLocation.currentLocation?.name || ''}
        activePage={location.pathname}
        logout={handleLogout}
        navigationRoute={routeSelectionProps.navigationRoute}
        userLocation={currentLocation}
        isRoutePaused={false}
        routeSelection={routeSelection}
        selectedRouteSuggestion={selectedRouteSuggestion}
        routeSuggestions={routeSuggestions}
        teqplayApiService={teqplayAPIService}
        resetAvailableRoutes={resetAvailableRoutes}
        setSelectedRouteSuggestion={setSelectedRouteSuggestion}
        startRoute={startRoute}
        theme={theme}
        setStatusbarTheme={setStatusbarTheme}
        selectedTab={selectedTab}
        setBICSRoute={route => {
          resetAvailableRoutes()
          setBICSRoute(route)
        }}
      />

      <RouteSelect
        // field values
        routeSelection={routeSelection}
        routeSelectionFormActive={routeSelectionFormActive}
        routeSelectionFormFieldActive={routeSelectionFormFieldActive}
        selectedField={selectedField}
        selectedViaRouteIndex={selectedViaRouteIndex}
        selectedRouteSuggestion={selectedRouteSuggestion}
        navigationLocations={navigationLocations}
        routeSuggestions={routeSuggestions}
        teqplayApiService={teqplayAPIService}
        currentLocation={currentLocation}
        // UI interactions
        searchAvailableRoutes={() => {
          // Instead of searching directly, show the modal instead
          setShowShipDimensionsDialog('routeSubmit')
        }}
        setRouteSelectionFormActive={handleSetRouteSelectionFormActive}
        setRouteSelectionInactive={handleSetRouteSelectionInactive}
        setRouteSelectionFormFieldActive={handleSetRouteSelectionFormFieldActive}
        setRouteSelectionFieldInactive={handleSetRouteSelectionFieldInactive}
        setRouteSelection={selectRouteActive}
        // setting fields
        setFromLocation={setFromLocation}
        setToLocation={setToLocation}
        setViaRoutes={setViaRoutes}
        setSelectedRouteSuggestion={setSelectedRouteSuggestion}
        setDepartureTime={handleSetDepartureTime}
        departureTime={departureTime}
        startRoute={startRoute}
        reverseRoute={reverseRoute}
        cruiseSpeed={speed.userInputSpeed}
        setCruiseSpeed={setCruiseSpeed}
        dimensions={currentLocation?.dimensions || null}
        teqplayAPIService={teqplayAPIService}
        setShowDimensionsDialog={() => setShowShipDimensionsDialog('help')}
        selectedTab={selectedTab}
        setSelectedTab={changeTab}
        locationStatus={locationStatus}
        searchRouteSuggesions={searchAvailableRoutes}
        BICSRoute={BICSRoute}
        setBICSRoute={setBICSRoute}
        resetAvailableRoutes={resetAvailableRoutes}
      />
      <MapRouteSelection
        analytics={analytics}
        routeSelection={routeSelection}
        selectedField={selectedField}
        selectedViaRouteIndex={selectedViaRouteIndex}
        routeSelectionFormActive={routeSelectionFormActive}
        routeSelectionFormFieldActive={routeSelectionFormFieldActive}
        setFromLocation={setFromLocation}
        setToLocation={setToLocation}
        setViaRoutes={setViaRoutes}
        routeSuggestions={routeSuggestions || customBICSRoute}
        token={currentUser.auth.token}
        activeMap={map.activeMap}
        onChangeActiveMap={handleChangeActiveMap}
        selectedRouteSuggestion={selectedRouteSuggestion || BICSRoute}
        hideControlButtons={hideMapControlButtons}
        currentLocation={userLocation.currentLocation}
        setSelectedRouteSuggestion={setSelectedRouteSuggestion}
        teqplayApiService={teqplayAPIService}
        theme={theme}
        suppressedWarnings={settings.suppressedWarnings}
        setSuppressedWarnings={dispatchSetSuppressedWarningsForLayer}
        showLimitedRangeLayer={map.showLimitedRangeOverlay}
        activeLayers={map.activeLayers}
        setActiveLayers={dispatchSetActiveLayers}
        handleSetRouteSelectionFormActive={showMobile ? handleSetRouteSelectionInactive : undefined}
      />
    </div>
  )

  function changeTab(tab: ITabs) {
    setRouteSelection(DEFAULT_ROUTE_SEARCH)
    setSelectedTab(tab)
    if (tab === 'BICS') {
      history.push('/announcements')
    } else {
      history.push('/routes')
    }
  }

  function setFromLocation(fromLocation: IRouteLocation | null) {
    setRouteSelection({ ...routeSelection, fromLocation })
    setRouteSelectionFormFieldActive(false)
  }

  function setToLocation(toLocation: IRouteLocation | null) {
    setRouteSelection({ ...routeSelection, toLocation })
    setRouteSelectionFormFieldActive(false)
  }

  function setViaRoutes(viaRoutes: IRouteLocation[]) {
    setRouteSelection({ ...routeSelection, viaRoutes })
    setRouteSelectionFormFieldActive(false)
    setSelectedViaRouteIndex(null)
  }

  function selectRouteActive(route: IRouteSearchProps) {
    setRouteSelection(route)
    if (selectedTab !== 'BICS') {
      setRouteSelectionFormFieldActive(true)
      setShowShipDimensionsDialog('routeSubmit')
    }
  }

  function reverseRoute() {
    setRouteSelection({
      fromLocation: routeSelection.toLocation,
      viaRoutes: routeSelection.viaRoutes.reverse(),
      toLocation: routeSelection.fromLocation
    })
    setBICSRoute(null)
  }

  function handleSetRouteSelectionFormActive() {
    setRouteSelectionFormActive(true)
    setHideMapControlButtons(true)
    history.push('/routes')
  }

  function handleSetRouteSelectionInactive() {
    setRouteSelectionFormActive(false)
    setHideMapControlButtons(false)
    history.push('/')
  }

  function handleChangeActiveMap(activeMap: MapTypes) {
    analytics.newEvent('set_active_map', { activeMap, from: 'route_selection_page' })
    dispatchSetActiveMap(activeMap)
  }

  function handleSetRouteSelectionFormFieldActive(
    fieldName: RouteSelectionFields,
    viaRouteIndex?: number
  ) {
    setRouteSelectionFormFieldActive(true)
    setSelectedField(fieldName)

    if (fieldName === 'VIA_ROUTES' && viaRouteIndex !== undefined) {
      setSelectedViaRouteIndex(viaRouteIndex)
    }
  }

  function handleSetRouteSelectionFieldInactive() {
    setRouteSelectionFormFieldActive(false)
    setSelectedField(null)
  }

  function resetAvailableRoutes() {
    analytics.newEvent('cancel_route', {})

    setRouteSuggestions(null)
    setRouteSelectionFormActive(true)
    setSelectedRouteSuggestion(null)
    if (selectedTab === 'BICS') {
      setRouteSelection({
        fromLocation: null,
        viaRoutes: [],
        toLocation: null
      })
    }
  }

  function handleSetDepartureTime(arrivalDate: Date) {
    analytics.newEvent('set_route_departure_time', { departureTime: arrivalDate.getTime() })
    setDepartureTime(arrivalDate.getTime())
  }

  async function startRoute(route: IRoute) {
    try {
      analytics.newEvent('start_route', { activeRouteID: route._id })
      const activeRoute = await teqplayAPIService.setUsersActiveRoute(route, speed.userInputSpeed)
      // Set speed returned from the backend to redux
      if (activeRoute.cruiseSpeed) {
        setCruiseSpeed(activeRoute.cruiseSpeed)
      }

      dispatchSetNavigationRoute(activeRoute)
      history.push(`/navigation${location.search}`)
    } catch (error) {
      console.error(error)
    }
  }

  async function searchAvailableRoutes(
    overrideAbleRouteSelection?: IRouteSearchProps,
    overridableDepartureTime?: number
  ) {
    const rs = overrideAbleRouteSelection || routeSelection
    const currentLoc = currentLocation?.location

    // Detect if the current location is being used as a parameter
    const fromLocationCoords: string | null =
      rs.fromLocation &&
      !rs.fromLocation.isCurrentLocation &&
      rs.fromLocation.coordinates?.lat &&
      rs.fromLocation.coordinates.lng
        ? `${rs.fromLocation.coordinates?.lat},${rs.fromLocation.coordinates?.lng}`
        : rs.fromLocation?.isCurrentLocation
        ? currentLoc
          ? `${currentLoc.latitude},${currentLoc.longitude}`
          : null
        : rs.fromLocation &&
          rs.fromLocation.displayName &&
          encodeURIComponent(rs.fromLocation.displayName)

    const viaRoutes = rs.viaRoutes.map(viaRoute => {
      if (viaRoute.isCurrentLocation && currentLoc) {
        return {
          displayName: viaRoute.displayName,
          coordinates: { lat: currentLoc.latitude, lng: currentLoc.longitude }
        }
      } else if (!viaRoute.isCurrentLocation) {
        return viaRoute
      } else {
        return {
          displayName: viaRoute.displayName,
          coordinates: null
        }
      }
    })

    const toLocationCoords: string | null =
      rs.toLocation &&
      !rs.toLocation.isCurrentLocation &&
      rs.toLocation.coordinates?.lat &&
      rs.toLocation.coordinates.lng
        ? `${rs.toLocation.coordinates?.lat},${rs.toLocation.coordinates?.lng}`
        : rs.toLocation?.isCurrentLocation
        ? currentLoc
          ? `${currentLoc.latitude},${currentLoc.longitude}`
          : null
        : rs.toLocation &&
          rs.toLocation.displayName &&
          encodeURIComponent(rs.toLocation.displayName)

    if (toLocationCoords && rs.viaRoutes && currentUser) {
      try {
        setLoading(true)

        analytics.newEvent('search_route', {
          fromLocationCoords,
          viaPoints: viaRoutes.map(vr => vr.displayName).toString(),
          toLocationCoords,
          departureTime: overridableDepartureTime || departureTime,
          userInputSpeed: speed.userInputSpeed
        })

        const fetchedSuggestions = await teqplayAPIService.fetchRouteSuggestions(
          fromLocationCoords,
          toLocationCoords,
          viaRoutes,
          overridableDepartureTime || departureTime,
          speed.userInputSpeed,
          i18n.locale,
          undefined // additionalItems
        )

        const sortedRouteSuggestions = orderBy(
          fetchedSuggestions,
          ['shipAllowed', 'canPassBridges', 'distance'],
          ['desc', 'desc', 'asc']
        )

        setRouteSuggestions(sortedRouteSuggestions)
        setRouteSelectionFormActive(false)
        setRouteSelectionFormFieldActive(false)
        setSelectedRouteSuggestion(sortedRouteSuggestions[0]) // set the first one as the active selected route
      } catch (error) {
        console.error(error)
        toast.warn(I18n.t('routeSelection.noRoutesFoundWarning'))
      } finally {
        setLoading(false)
      }
    }
  }

  async function checkActiveRoutes() {
    try {
      const selectedRoute = await teqplayAPIService.getSelectedRoute()
      if (selectedRoute) {
        setRequestUseActiveRoute(true)
      }
    } catch (error) {
      console.error(error)
    }
  }

  async function fetchAllNavigationLocations() {
    try {
      const terminals = await teqplayAPIService.getHardcodedTerminals()
      const berths = await teqplayAPIService.getHardcodedBerths()

      const mappedTerminals: INavigationLocation[] = terminals.map(t => ({
        _id: t.id.toString(),
        name: t.description,
        city: t.city,
        location: {
          coordinates: [t.longitude, t.latitude],
          type: 'Point'
        },
        type: 'TERMINAL'
      }))

      const mappedBerths: INavigationLocation[] = berths.map(b => ({
        _id: b.properties.objectId.toString(),
        name: b.properties.berthName || b.properties.shortBerthName,
        city: b.properties.city || '',
        location: {
          coordinates: [b.properties.longitude, b.properties.latitude],
          type: 'Point'
        },
        type: 'BERTH'
      }))

      setNavigationLocations([...mappedBerths, ...mappedTerminals])
    } catch (error) {
      console.error(error)
      // no nav route found ( which is fine )
    }
  }

  function handleLogout() {
    analytics.newEvent('logout', {})
    teqplayAPIService.logoutUser()
  }

  function changeStatusbarForNavigationPage(newTheme?: IApplicationTheme) {
    // iPhone X and types alike only
    // Top bar is too dark for black text, force white text
    // Only valid from page width of 380PX

    const pageWidth = Math.max(
      document.body.scrollWidth,
      document.documentElement.scrollWidth,
      document.body.offsetWidth,
      document.documentElement.offsetWidth,
      document.documentElement.clientWidth
    )

    if (pageWidth <= 380) {
      setStatusbarTheme(newTheme)
    }
  }

  function setCruiseSpeed(cruiseSpeed: number) {
    dispatchSetUserInputSpeed(cruiseSpeed)
  }

  function handleStartRouteFromQuery(
    startLocation: { name: string; lat: number; lng: number; departureTime?: string },
    viaLocations: { name: string; lat: number; lng: number }[],
    endLocation: { name: string; lat: number; lng: number }
  ) {
    setRouteSelectionFormActive(true)
    const mappedFromLocation: IRouteLocation = {
      displayName: startLocation.name,
      coordinates:
        startLocation.lat && startLocation.lng
          ? { lat: startLocation.lat, lng: startLocation.lng }
          : null,
      isCurrentLocation: false
    }
    const mappedViaPoints: IRouteLocation[] =
      viaLocations.map(p => ({
        displayName: p.name,
        coordinates: p.lat && p.lng ? { lat: p.lat, lng: p.lng } : null,
        isCurrentLocation: false
      })) || []
    const mappedToLocation: IRouteLocation = {
      displayName: endLocation.name,
      coordinates:
        endLocation.lat && endLocation.lng ? { lat: endLocation.lat, lng: endLocation.lng } : null,
      isCurrentLocation: false
    }

    const queryObject = convertQueryStringToObject(location.search)
    delete queryObject.fromLocation
    delete queryObject.departureTime
    delete queryObject.viaPoints
    delete queryObject.toLocation

    history.push(`/${convertObjectToQueryString(queryObject)}`)

    setDepartureTime(
      startLocation.departureTime ? new Date(startLocation.departureTime).valueOf() : null
    )
    setRouteSelection({
      fromLocation: mappedFromLocation,
      viaRoutes: mappedViaPoints,
      toLocation: mappedToLocation
    })
    setShowShipDimensionsDialog('routeSubmit')
  }
}

const mapDispatchToProps: MapDispatchToPropsFunction<IDispatchProps, any> = (
  dispatch: ThunkDispatch<IRootProps, void, Action>
) => {
  return {
    handleClearAuth: () => dispatch(clearAuth()),
    handleSetNavigationRoute: (route: ISelectedRoute) => dispatch(setNavigationRoute(route)),
    handleClearNavigationRoute: () => dispatch(clearNavigationRoute()),
    handleSetUserInputSpeed: (speed: number) => dispatch(setUserInputSpeed(speed)),
    handleSetActiveMap: (activeMap: MapTypes) => dispatch(setActiveMap(activeMap)),
    handleSetSuppressedWarningsForLayer: (
      l: ILayerCodeName,
      prompts: { [prompt in keyof ILayerCoverageDetails['prompts']]: boolean }
    ) => dispatch(setSuppressedWarningsForLayer(l, prompts)),
    handleSetActiveLayers: (layers: ILayerCodeName[]) => dispatch(setActiveLayers(layers))
  }
}

const mapStateToProps = (state: IRootProps, ownProps: {}) => {
  return state
}

export default connect(mapStateToProps, mapDispatchToProps)(RouteSelectionPage)
