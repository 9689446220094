import { MigrationManifest } from 'redux-persist'

export const migrations: MigrationManifest = {
  2: (state: any) => {
    return {
      ...state,
      map: {
        ...state.map,
        activeMap: 'DEFAULT',
        showLimitedRangeOverlay: true
      }
    }
  },
  3: (state: any) => {
    return {
      ...state,
      settings: {
        themeOverride: state.settings.themeOverride,
        listenToDeviceTheme: state.settings.listenToDeviceTheme,
        suppressedWarnings: {}
      }
    }
  },
  4: (state: any) => {
    return {
      ...state,
      map: {
        ...state.map,
        activeLayers: []
      }
    }
  }
}
