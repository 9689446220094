import React, { useEffect, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { Redirect, useHistory, useLocation } from 'react-router'
import { Action } from 'redux'
import { ThunkDispatch } from 'redux-thunk'

import LoadingIndicator from '../../components/loadingIndicator/LoadingIndicator'
import MapNavigation from '../../components/mapNavigation/MapNavigation'
import NavigationFooter from '../../components/navigationFooter/NavigationFooter'
import NavigationStepDetails from '../../components/navigationStepDetails/NavigationStepDetails'
import TeqplayApiService from '../../services/TeqplayAPIService/TeqplayApiService'
import NavigationOptions from './navigatingOptions/NavigationOptions'

import {
  IApplicationTheme,
  ILayerCodeName,
  ILayerCoverageDetails,
  IRootProps,
  MapTypes
} from '../../@types/types'
import { setActiveLayers, setActiveMap } from '../../components/mapShared/actions'
import { useInterval } from '../../hooks/useInterval'
import { usePrevious } from '../../hooks/usePrevious'
import { useAnalytics } from '../../services/AnalyticsWrapper/AnalyticsWrapper'
import {
  IBridgeMovement,
  IBridgePlanning,
  ILockPlanning,
  ILoggedInUser,
  ISelectedRoute,
  IShipNotificationStatus
} from '../../services/TeqplayAPIService/TeqplayApi'
import {
  INTERVAL_BRIDGE_OPENINGS,
  INTERVAL_NOTIFICATIONS,
  INTERVAL_ROUTE_ETA
} from '../../utils/constants'
import { determineApplicationTheme } from '../../utils/style'
import { clearAuth, setAuth } from '../loginPage/actions'
import {
  checkAndSetActiveRoute,
  clearNavigationRoute,
  pauseCurrentRoute,
  resumeCurrentRoute,
  setBridgeOpenings,
  setNavigationRoute,
  stopCurrentRoute,
  updateRouteEta
} from '../routeSelectionPage/actions'
import { setSuppressedWarningsForLayer } from '../settings/actions'

import 'react-tabs/style/react-tabs.css'
import './NavigationPage.scss'

interface IProps {
  VDJSNotificationMessage: string | null
  channelApproachNotificationMessage: string | null
  theme: IApplicationTheme
  setStatusbarTheme: (theme?: IApplicationTheme) => void
  teqplayAPIService: TeqplayApiService
}

interface IDispatchProps {
  handlePauseCurrentRoute: (
    teqplayApiService: TeqplayApiService,
    selectedRoute: ISelectedRoute
  ) => void
  handleResumeCurrentRoute: (
    teqplayApiService: TeqplayApiService,
    selectedRoute: ISelectedRoute
  ) => void
  handleStopCurrentRoute: (teqplayApiService: TeqplayApiService) => void
  handleClearNavigationRoute: () => void
  handleCheckAndSetActiveRoute: (
    teqplayApiService: TeqplayApiService,
    redirectToHome: () => void
  ) => void
  handleUpdateRouteEta: (
    teqplayApiService: TeqplayApiService,
    selectedRoute: ISelectedRoute,
    redirectToHome: () => void
  ) => void
  handleSetActiveMap: (activeMap: MapTypes) => void
  handleSetSuppressedWarningsForLayer: (
    l: ILayerCodeName,
    prompts: { [prompt in keyof ILayerCoverageDetails['prompts']]: boolean }
  ) => void
  handleSetActiveLayers: (layers: ILayerCodeName[]) => void
  handleSetBridgeOpenings: (
    bridgePlanning: IBridgePlanning[],
    bridgeMovement: IBridgeMovement[],
    lockPlanning: ILockPlanning[]
  ) => void
}

const NavigationPage = ({
  VDJSNotificationMessage,
  channelApproachNotificationMessage,
  theme,
  setStatusbarTheme,
  teqplayAPIService,
  handleCheckAndSetActiveRoute,
  handleClearNavigationRoute,
  handlePauseCurrentRoute,
  handleResumeCurrentRoute,
  handleSetActiveLayers,
  handleSetActiveMap,
  handleSetBridgeOpenings,
  handleSetSuppressedWarningsForLayer,
  handleStopCurrentRoute,
  handleUpdateRouteEta
}: IProps & IDispatchProps) => {
  const analytics = useAnalytics('NavigationPage')
  const history = useHistory()
  const location = useLocation()
  const { currentUser, routeSelection, map, userLocation, settings, speed } = useSelector(
    (props: IRootProps) => props
  )
  const previousRouteSelection = usePrevious(routeSelection)
  const [shipNotificationStatus, setShipNotificationStatus] =
    useState<IShipNotificationStatus | null>(null)
  const [optionsActive, setOptionsActive] = useState<boolean>(false)

  useInterval(() => fetchRouteEta(), INTERVAL_ROUTE_ETA)
  useInterval(() => fetchShipNotificationStatus(), INTERVAL_NOTIFICATIONS)
  useInterval(() => fetchBridgeAndLockOpenings(), INTERVAL_BRIDGE_OPENINGS)

  useEffect(() => {
    analytics.setScreen('routeselection')
    analytics.setThemeProperty(
      determineApplicationTheme(settings.listenToDeviceTheme, settings.themeOverride)
    )

    changeStatusbarForNavigationPage('dark')
    fetchActiveRoute()
    fetchShipNotificationStatus()
    fetchBridgeAndLockOpenings(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    // Only executed once upon initialisation + route selection fetch completed
    if (routeSelection && !shipNotificationStatus) {
      fetchRouteEta()
      fetchShipNotificationStatus()
      fetchBridgeAndLockOpenings()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [routeSelection, shipNotificationStatus])

  useEffect(() => {
    if (
      routeSelection.navigationRoute?.paused === true && // check if currently paused
      (!previousRouteSelection?.navigationRoute || !previousRouteSelection.navigationRoute.paused) // check if wasn't already paused
    ) {
      // is now paused
      changeStatusbarForNavigationPage('light')
    } else if (
      (routeSelection.navigationRoute && routeSelection.navigationRoute.paused === false) ===
        true && // check if currently NOT paused
      previousRouteSelection?.navigationRoute &&
      previousRouteSelection.navigationRoute.paused === true // check if wasn't paused already
    ) {
      changeStatusbarForNavigationPage('dark')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [routeSelection, previousRouteSelection])

  if (!currentUser?.auth?.token) {
    return <Redirect to={`/login${location.search ? location.search : ''}`} push />
  }

  const isRoutePaused =
    routeSelection.navigationRoute && routeSelection.navigationRoute.paused === true ? true : false

  const totalNotificationsCount = getNotificationCount()

  return (
    <div className="page-wrapper navigation-page">
      {routeSelection.navigationRoute ? (
        <>
          <NavigationStepDetails
            navigationRoute={routeSelection.navigationRoute}
            toggleOptions={toggleOptions}
            optionsActive={optionsActive}
            speed={speed}
            userLocation={userLocation.currentLocation}
            shipNotificationStatus={shipNotificationStatus}
            isRoutePaused={isRoutePaused}
            totalNotificationsCount={totalNotificationsCount}
          />
          <MapNavigation
            analytics={analytics}
            token={currentUser.auth.token}
            navigationRoute={routeSelection.navigationRoute}
            currentLocation={userLocation.currentLocation}
            activeMap={map.activeMap}
            onChangeActiveMap={handleChangeActiveMap}
            teqplayApiService={teqplayAPIService}
            theme={theme}
            suppressedWarnings={settings.suppressedWarnings}
            setSuppressedWarnings={handleSetSuppressedWarningsForLayer}
            showLimitedRangeLayer={map.showLimitedRangeOverlay}
            activeLayers={map.activeLayers}
            onChangeActiveLayers={handleSetActiveLayers}
            bridgePlanning={routeSelection.bridgePlanning}
            bridgeMovement={routeSelection.bridgeMovement}
            lockPlanning={routeSelection.lockPlanning}
          />
          <NavigationFooter
            userProfile={currentUser.userProfile}
            shipName={userLocation.currentLocation?.name || ''}
            activePage={location.pathname}
            logout={handleLogout}
            navigationRoute={routeSelection.navigationRoute}
            toggleOptions={toggleOptions}
            userLocation={userLocation.currentLocation}
            shipNotificationStatus={shipNotificationStatus}
            isRoutePaused={isRoutePaused}
            pauseRoute={pauseRoute}
            resumeRoute={resumeRoute}
            stopRoute={stopRoute}
            optionsActive={optionsActive}
            theme={theme}
            setStatusbarTheme={t => changeStatusbarForNavigationPage(t)}
            teqplayApiService={teqplayAPIService}
          />
          <NavigationOptions
            active={optionsActive}
            navigationRoute={
              routeSelection.navigationRoute ? routeSelection.navigationRoute.route : null
            }
            toggleOptions={toggleOptions}
            shipNotificationStatus={shipNotificationStatus}
            VDJSNotificationMessage={VDJSNotificationMessage}
            channelApproachNotificationMessage={channelApproachNotificationMessage}
            teqplayApiService={teqplayAPIService}
            totalNotificationsCount={totalNotificationsCount}
            isRoutePaused={isRoutePaused}
            bridgePlanning={routeSelection.bridgePlanning}
            bridgeMovement={routeSelection.bridgeMovement}
            lockPlanning={routeSelection.lockPlanning}
            selectedTime={routeSelection.navigationRoute.selectionTime}
            speed={speed}
          />
        </>
      ) : (
        <LoadingIndicator loading={true} />
      )}
    </div>
  )

  function handleChangeActiveMap(activeMap: MapTypes) {
    analytics.newEvent('set_active_map', { activeMap, from: 'route_selection_page' })
    handleSetActiveMap(activeMap)
  }

  function getNotificationCount(): number {
    const shipNotificationCount = shipNotificationStatus
      ? shipNotificationStatus.notifications.length
      : 0

    const VDJSNotificationCount = VDJSNotificationMessage ? 1 : 0
    const channelApproachCount = channelApproachNotificationMessage ? 1 : 0

    return shipNotificationCount + VDJSNotificationCount + channelApproachCount
  }

  function pauseRoute() {
    const activeRoute = routeSelection.navigationRoute
    if (activeRoute) {
      analytics.newEvent('pause_route', { activeRouteID: activeRoute._id })
      handlePauseCurrentRoute(teqplayAPIService, activeRoute)
    }
  }

  async function stopRoute() {
    const activeRoute = routeSelection.navigationRoute
    analytics.newEvent('stop_route', { activeRouteID: activeRoute?._id })
    await handleStopCurrentRoute(teqplayAPIService)
    history.push(`/${location.search}`)
  }

  function resumeRoute() {
    const activeRoute = routeSelection.navigationRoute
    if (activeRoute) {
      analytics.newEvent('resume_route', { activeRouteID: activeRoute._id })
      handleResumeCurrentRoute(teqplayAPIService, activeRoute)
    }
  }

  function fetchRouteEta() {
    if (isRouteActive() && routeSelection.navigationRoute) {
      handleUpdateRouteEta(teqplayAPIService, routeSelection.navigationRoute, redirectToHomePage)
    }
  }

  async function fetchBridgeAndLockOpenings(getInitialStatus?: boolean) {
    if (getInitialStatus || !routeSelection.navigationRoute?.paused) {
      try {
        const routeItems = routeSelection.navigationRoute?.route.routeItems || []

        let nhBridgePlanning = [] as IBridgePlanning[]
        let bridgeMovement = [] as IBridgeMovement[]

        // Check if there are any bridges en route, no point to fetch if there are none
        // Or if navigation route still needs to initialize, thus has 0 routeItems

        // Check for any BMS bridges (Noord-Holland functionality)
        if (routeItems.filter(ri => ri.bridgeType === 'BMS').length > 0 || getInitialStatus) {
          nhBridgePlanning = await teqplayAPIService.getRouteBridgePlanningOpenings()
        }

        // Check for any bridges (Global bridgeMovement call)
        if (routeItems.filter(ri => ri.type === 'BRIDGE').length > 0 || getInitialStatus) {
          bridgeMovement = await teqplayAPIService.fetchBridgeMovement()
        }

        handleSetBridgeOpenings(nhBridgePlanning, bridgeMovement, [])
      } catch (error) {
        console.error(error)
      }
    }
  }

  async function fetchActiveRoute() {
    handleCheckAndSetActiveRoute(teqplayAPIService, redirectToHomePage)
  }

  function redirectToHomePage() {
    history.push(`/${location.search}`)
  }

  async function fetchShipNotificationStatus(getInitialNotificationStatus?: boolean) {
    if (isRouteActive() || getInitialNotificationStatus === true) {
      try {
        const newShipNotificationStatus = await teqplayAPIService.getShipNotificationStatus()
        setShipNotificationStatus(newShipNotificationStatus)
      } catch (error) {
        // TODO: Handle error?
        return
      }
    }
  }

  function isRouteActive() {
    if (
      routeSelection &&
      routeSelection.navigationRoute &&
      !routeSelection.navigationRoute.paused
    ) {
      return true
    }

    return false
  }

  function handleLogout() {
    analytics.newEvent('logout', {
      activeRouteID: routeSelection.navigationRoute?._id
    })

    handleClearNavigationRoute()
    teqplayAPIService.logoutUser()
  }

  function toggleOptions() {
    setOptionsActive(!optionsActive)
  }

  function changeStatusbarForNavigationPage(overrideTheme?: IApplicationTheme) {
    // iPhone X and types alike only
    // Top bar is too dark for black text, force white text
    // Only valid from page width of 380PX

    let newTheme = overrideTheme

    if (!newTheme) {
      if (
        routeSelection.navigationRoute &&
        routeSelection.navigationRoute.paused === true // check if currently paused
      ) {
        // Is now paused
        newTheme = 'light'
      } else if (
        (routeSelection.navigationRoute && routeSelection.navigationRoute.paused === false) === true // check if currently NOT paused
      ) {
        // Was paused, not anymore
        newTheme = 'dark'
      }
    }

    const pageWidth = Math.max(
      document.body.scrollWidth,
      document.documentElement.scrollWidth,
      document.body.offsetWidth,
      document.documentElement.offsetWidth,
      document.documentElement.clientWidth
    )

    if (pageWidth <= 380) {
      setStatusbarTheme(newTheme)
    }
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<IRootProps, void, Action>) => {
  return {
    handleClearAuth: () => dispatch(clearAuth()),
    handleSetAuth: (user: ILoggedInUser) => dispatch(setAuth(user)),
    handleClearNavigationRoute: () => dispatch(clearNavigationRoute()),
    handleSetNavigationRoute: (selectedRoute: ISelectedRoute) =>
      dispatch(setNavigationRoute(selectedRoute)),
    handlePauseCurrentRoute: (
      teqplayApiService: TeqplayApiService,
      selectedRoute: ISelectedRoute
    ) => pauseCurrentRoute(dispatch, teqplayApiService, selectedRoute),
    handleResumeCurrentRoute: (
      teqplayApiService: TeqplayApiService,
      selectedRoute: ISelectedRoute
    ) => resumeCurrentRoute(dispatch, teqplayApiService, selectedRoute),
    handleStopCurrentRoute: (teqplayApiService: TeqplayApiService) =>
      stopCurrentRoute(dispatch, teqplayApiService),
    handleCheckAndSetActiveRoute: (
      teqplayApiService: TeqplayApiService,
      redirectToHome: () => void
    ) => checkAndSetActiveRoute(dispatch, teqplayApiService, redirectToHome),
    handleUpdateRouteEta: (
      teqplayApiService: TeqplayApiService,
      selectedRoute: ISelectedRoute,
      redirectToHome: () => void
    ) => updateRouteEta(dispatch, teqplayApiService, selectedRoute, redirectToHome),
    handleSetActiveMap: (activeMap: MapTypes) => dispatch(setActiveMap(activeMap)),
    handleSetSuppressedWarningsForLayer: (
      l: ILayerCodeName,
      prompts: { [prompt in keyof ILayerCoverageDetails['prompts']]: boolean }
    ) => dispatch(setSuppressedWarningsForLayer(l, prompts)),
    handleSetActiveLayers: (layers: ILayerCodeName[]) => dispatch(setActiveLayers(layers)),
    handleSetBridgeOpenings: (
      bridgePlanning: IBridgePlanning[],
      bridgeMovement: IBridgeMovement[],
      lockPlanning: ILockPlanning[]
    ) => dispatch(setBridgeOpenings(bridgePlanning, bridgeMovement, lockPlanning))
  }
}

const mapStateToProps = (state: IRootProps, ownProps: {}) => {
  return state
}

export default connect(mapStateToProps, mapDispatchToProps)(NavigationPage)
