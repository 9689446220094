import { ILayerCodeName, MapTypes } from '../../@types/types'

export type IMapAction = ISetActiveMap | ISetActiveLayers

interface ISetActiveMap {
  type: 'SET_ACTIVE_MAP'
  map: MapTypes
}
export function setActiveMap(map: MapTypes): ISetActiveMap {
  return {
    type: 'SET_ACTIVE_MAP',
    map
  }
}

interface ISetActiveLayers {
  type: 'SET_ACTIVE_LAYERS'
  layers: ILayerCodeName[]
}
export function setActiveLayers(layers: ILayerCodeName[]): ISetActiveLayers {
  return {
    type: 'SET_ACTIVE_LAYERS',
    layers
  }
}
