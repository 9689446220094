import React from 'react'
import { useHistory } from 'react-router'
import { I18n } from 'react-redux-i18n'

import './PrivacyPolicy.scss'

const PrivacyPolicy = () => {
  const history = useHistory()

  return (
    <div className="privacy-policy">
      <button
        className="button back-button"
        type="button"
        onClick={() => {
          history.push(`/`)
        }}
      >
        <i className="icon-left" />
        <span>{I18n.t('announcements.goBack')}</span>
      </button>
      <h1>{I18n.t('register.gdpr.title')}</h1>
      <p>{I18n.t('register.gdpr.privacyBodyFirstLine')}</p>
      <p>{I18n.t('register.gdpr.privacyBodySecondLine')}</p>
      <p>{I18n.t('register.gdpr.privacyBodyThirdLine')}</p>
      <p>{I18n.t('register.gdpr.privacyBodyFourthLine')}</p>
      <p>{I18n.t('register.gdpr.privacyBodyFifthLine')}</p>
      <p>{I18n.t('register.gdpr.privacyBodySixthLine')}</p>
      <p>
        {I18n.t('register.gdpr.privacyBodySeventhLine')}&nbsp;
        <a href="mailto:riverguide@teqplay.nl?subject=Privacy RiverGuide" target="_system">
          riverguide@teqplay.nl
        </a>
      </p>
    </div>
  )
}

export default PrivacyPolicy
