import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { I18n } from 'react-redux-i18n'
import { format } from 'date-fns'
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from 'recharts'
import { DATE_TIME_FORMAT } from '../../utils/constants'
import { IWaterHeight, IWaterHeightMeasurements } from '../../services/TeqplayAPIService/TeqplayApi'
import TeqplayApiService from '../../services/TeqplayAPIService/TeqplayApiService'
import Loader from '../loadingIndicator/LoadingIndicator'

interface IProps {
  teqplayApiService: TeqplayApiService
  itemDetails: IWaterHeight
}

function WaterHeightDetails(props: IProps) {
  const [heightMeasurements, setHeightMeasurements] = useState<
    IWaterHeightMeasurements | undefined
  >()
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    if (props.itemDetails.linkId) {
      fetchDetails(props.itemDetails.linkId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.itemDetails])

  return (
    <div className="item-info-details" id={`height-details-${props.itemDetails.name}`}>
      <div className="notification-inner">
        <section className="details-section">
          <div className="route-item-caption" id="height-details-title">
            <b>
              {I18n.t('map.waterHeight.name')} {props.itemDetails.name}
            </b>
          </div>
          {props.itemDetails.height && (
            <div className="popup-wave-height">
              <span>{I18n.t('map.waterHeight.height')}: </span>
              <span>
                {props.itemDetails.height || '?'} {props.itemDetails.unit}{' '}
                {props.itemDetails.referenceLevel}
              </span>
            </div>
          )}
          {props.itemDetails.measuredAt && (
            <>
              <span>{I18n.t('map.waterHeight.timeHeightLastUpdated')}: </span>
              <span>{format(props.itemDetails.measuredAt, DATE_TIME_FORMAT)}</span>
            </>
          )}
        </section>
        {loading ? (
          <Loader loading />
        ) : (
          heightMeasurements && (
            <ResponsiveContainer width="100%" height={250}>
              <LineChart data={formatData(heightMeasurements)}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="date"
                  tickFormatter={date => new Date(date).toLocaleDateString()}
                  width={40}
                />
                <YAxis width={20} />
                <Tooltip labelFormatter={label => new Date(label).toLocaleString()} />
                <Legend />
                <Line
                  type="monotone"
                  dataKey="value"
                  stroke="#2e6771"
                  activeDot={{ r: 8 }}
                  name={I18n.t('map.waterHeight.height')}
                />
              </LineChart>
            </ResponsiveContainer>
          )
        )}
      </div>
    </div>
  )

  function formatData(measurements: IWaterHeightMeasurements) {
    return Object.entries(measurements).map(([date, value]) => ({
      date,
      value
    }))
  }

  async function fetchDetails(id: string) {
    try {
      setLoading(true)
      const fetchedWaterHeightDetails = await props.teqplayApiService.getWaterHeightDetails(id)
      setHeightMeasurements(fetchedWaterHeightDetails)
    } catch (err) {
      toast.error(I18n.t('generalError'))
    } finally {
      setLoading(false)
    }
  }
}

export default WaterHeightDetails
