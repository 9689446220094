import { I18n } from 'react-redux-i18n'
import { toast } from 'react-toastify'
import { IBerth, IShipInfo } from '../services/TeqplayAPIService/TeqplayApi'

export function filterSuitableBerth(berth: IBerth, ship: IShipInfo | null, shipCones: number) {
  let availableSpace = false
  let draughtSuitable = false
  let conesSuitable = false

  const berthLength = berth.length
  const berthWidth = berth.width
  const berthOccupiedWidth = berth.occupiedWidth
  const berthDangerousGoodsLevel = berth.dangerousGoodsLevel
  const berthDraught = berth.draught
  const berthOccupation = berth.occupiedPercentage

  if (!ship) {
    toast.error(I18n.t('mapLayers.berthLayer.suitableError'))
    return false
  }

  // Check if the ship fits in the berth
  if (ship.dimensions && ship.dimensions.length && ship.dimensions.width) {
    if (berthLength >= ship.dimensions.length && berthWidth >= ship.dimensions.width) {
      if (berthOccupiedWidth !== null && berthOccupiedWidth !== undefined) {
        if (berthWidth - berthOccupiedWidth > ship.dimensions.width) {
          availableSpace = true
        }
      } else if (berthOccupation <= 75) {
        availableSpace = true
      }
    }
  } else if (berthOccupation <= 75) {
    availableSpace = true
  }

  // Check if the ships depth is suitable for the berth
  if (berthDraught >= 0 && ship.maxDraught >= 0) {
    if (ship.maxDraught <= berthDraught) {
      draughtSuitable = true
    }
  }

  // Check if the ship is suitable with the dangerousGoodsLevel
  if (berthDangerousGoodsLevel && berthDangerousGoodsLevel >= shipCones) {
    conesSuitable = true
  }

  // If all is suitable return the berth
  if (availableSpace === true && draughtSuitable === true && conesSuitable === true) {
    return true
  } else {
    return false
  }
}
