import { DivIcon, divIcon as DIVIcon, LatLngBounds } from 'leaflet'
import isEqual from 'lodash/isEqual'
import * as React from 'react'
import { Marker } from 'react-leaflet'
import { I18n } from 'react-redux-i18n'

import CollapsableLegendComponent from '../../../controls/CollapsableLegendComponent'

import './DefaultMarkerLayer.scss'

interface IProps<T> {
  name: string
  checked: boolean
  divIcon?: DivIcon
  determineDivIcon?: (item: T) => DivIcon
  fontIconClass?: string
  getPopup?: (item: T) => React.ReactNode
  backgroundColor?: string
  iconColor?: string
  showLegend?: boolean
  onClick?: (item: T) => void
}

interface IFetchProps<T> extends IProps<T> {
  fetchCall: (bounds?: LatLngBounds) => Promise<T[]>
  filterData?: (data: T[]) => T[]
  intervalFetch?: {
    fetchCall: (bounds?: LatLngBounds) => Promise<T[]>
    intervalMS: number
  }
  useBounds?: boolean // default is true
  bounds?: LatLngBounds
}

interface IProvideProps<T> extends IProps<T> {
  dataOverride?: T[]
}

type IMarkerLayerProps<T> = IFetchProps<T> | IProvideProps<T>

interface ICustomMarker {
  location?: { coordinates: [number, number] }
  loc?: { coordinates: [number, number] }
  Location?: { coordinates: [number, number] }
  _id?: string
}

interface IState<T> {
  data: T[]
  showLegend: boolean
}

export default class DefaultMarkersLayer<T extends ICustomMarker> extends React.PureComponent<
  IMarkerLayerProps<T>,
  IState<T>
> {
  public refetchTimer: NodeJS.Timeout | undefined

  private isFetchableLayer = (
    props: IFetchProps<T> | IProvideProps<T>
  ): props is IFetchProps<T> => {
    return (props as IFetchProps<T>).bounds !== undefined
  }

  public readonly state: Readonly<IState<T>> = {
    data: [],
    showLegend: false
  }

  public componentDidMount() {
    this.fetchData()
  }

  public componentDidUpdate(prevProps: IMarkerLayerProps<T>) {
    if (this.isFetchableLayer(this.props) && this.isFetchableLayer(prevProps)) {
      if (
        !isEqual(prevProps.bounds, this.props.bounds) ||
        (!prevProps.checked && this.props.checked)
      ) {
        this.fetchData()
      }

      if (this.props.intervalFetch !== undefined && !this.refetchTimer && this.props.checked) {
        // Initiate interval fetch every n amount
        const intervalCall = (bounds?: LatLngBounds) =>
          this.isFetchableLayer(this.props) && this.props.intervalFetch
            ? this.props.intervalFetch.fetchCall(bounds)
            : Promise.resolve([])

        const interval =
          this.isFetchableLayer(this.props) && this.props.intervalFetch
            ? this.props.intervalFetch.intervalMS
            : null

        if (interval === null) {
          console.error(
            'Something went wrong passing the interval or intervalCall to layer ' + this.props.name
          )
        } else {
          this.refetchTimer = setInterval(() => this.fetchData(intervalCall), interval)
        }
      }
    } else if (!this.isFetchableLayer(this.props) && this.state.data !== this.props.dataOverride) {
      this.fetchData()
    }
  }

  public componentWillUnmount() {
    if (this.refetchTimer) {
      clearInterval(this.refetchTimer)
    }
  }

  public render() {
    const {
      checked,
      determineDivIcon,
      divIcon,
      fontIconClass,
      getPopup,
      showLegend,
      name,
      onClick
    } = this.props
    const layerIcon = divIcon ? divIcon : this.getLayerIcon(fontIconClass)

    if (!checked) {
      return null
    }

    return (
      <React.Fragment>
        {this.state.data.map((point, index) => {
          const locationObject = point.loc || point.location || point.Location

          if (!locationObject || !locationObject.coordinates || !locationObject.coordinates.length)
            return null

          return (
            <Marker
              key={point._id || index}
              position={[locationObject.coordinates[1], locationObject.coordinates[0]]}
              icon={determineDivIcon ? determineDivIcon(point) : layerIcon}
              onclick={onClick ? () => onClick(point) : undefined}
              interactive={onClick ? true : false}
            >
              {getPopup ? getPopup(point) : null}
            </Marker>
          )
        })}

        {showLegend && (
          <CollapsableLegendComponent
            icon={<i className={fontIconClass} />}
            position={'topright'}
            id={name}
          >
            <div className="item-legend">
              <div className="name">{I18n.t(`map.layers.${this.props.name}`)}</div>
              <div className="icon">
                <div
                  className="div-icon-marker-layer"
                  style={{
                    backgroundColor: this.props.backgroundColor || '#3FAFE1',
                    color: '#fff'
                  }}
                >
                  <i className={fontIconClass} />
                </div>
              </div>
            </div>
          </CollapsableLegendComponent>
        )}
      </React.Fragment>
    )
  }

  public getLayerIcon = (fontIconClass?: string) => {
    const { backgroundColor, iconColor } = this.props
    // const style = { background: iconColor ? iconColor : '#3FAFE1' }
    return DIVIcon({
      className: '',
      html: `<div class="div-icon-marker-layer" style="background: ${
        backgroundColor ? backgroundColor : '#3FAFE1'
      };color:${iconColor ? iconColor : '#fff'}"><i class="${fontIconClass}"/>`,
      iconAnchor: [20, 20],
      iconSize: [40, 40]
    })
  }

  public fetchData = async (fetchCallOverride?: (bounds?: LatLngBounds) => Promise<T[]>) => {
    if (this.isFetchableLayer(this.props)) {
      const { fetchCall, bounds, useBounds, checked, filterData } = this.props
      if (!fetchCall || !checked || (useBounds !== false && !bounds)) {
        return
      }

      try {
        const boundsToUse = useBounds !== false ? bounds : undefined
        const data = fetchCallOverride
          ? await fetchCallOverride(boundsToUse)
          : await this.props.fetchCall(boundsToUse)

        const filteredData = filterData ? filterData(data) : data

        this.setState({ data: filteredData })
      } catch (err) {
        console.error(err)
      }
    } else if (this.props.dataOverride) {
      this.setState({ data: this.props.dataOverride })
    } else {
      console.error(
        `Layer ${this.props.name} could not be identified as either Fetchablelayer or provided data layer. Please check the code`
      )
    }
  }

  public toggleShowLegend() {
    this.setState({ showLegend: !this.state.showLegend })
  }
}
