import * as React from 'react'
import { I18n } from 'react-redux-i18n'
import { IDrinkwaterPoint } from '../../services/TeqplayAPIService/TeqplayApi'

interface IProps {
  itemDetails: IDrinkwaterPoint | null
  isExpanded: boolean
}

const DrinkwaterPointDetails = ({ itemDetails, isExpanded }: IProps) => {
  if (!itemDetails || isExpanded === false) {
    return null
  }

  return (
    <div className="item-info-details" id={`drinkwaterpoint-details-${itemDetails.name}`}>
      <div className="notification-inner">
        <section className="details-section">
          <div className="route-item-caption" id="drinkwaterpoint-details-title">
            <b>
              {itemDetails.name} ({I18n.t(`map.drinkwaterPoints.${itemDetails.type}`)})
            </b>
          </div>
          {itemDetails.description && (
            <div className="route-item-details-item" id="drinkwaterpoint-details-description">
              {itemDetails.description}
            </div>
          )}
        </section>
      </div>
    </div>
  )
}

export default DrinkwaterPointDetails
