import * as Sentry from '@sentry/browser'

import { ILoggedInUser } from '../../services/TeqplayAPIService/TeqplayApi'
import { loadFromLocalStorage, saveToLocalStorage } from '../../utils/localStorage'
import { ILoginAction } from './actions'

// Set inital login state
const initialState = {
  auth: loadFromLocalStorage<ILoggedInUser>('auth', undefined),
  ship: undefined,
  userProfile: undefined
}

// eslint-disable-next-line @typescript-eslint/default-param-last
export function loginReducer(state = initialState, action: ILoginAction) {
  switch (action.type) {
    case 'SET_AUTH': {
      const auth = {
        username: action.auth?.username || undefined,
        token: action.auth?.token || undefined,
        refreshToken: action.auth?.refreshToken || undefined,
        timestamp: Date.now()
      }
      saveToLocalStorage('auth', auth)
      Sentry.configureScope(scope => scope.setUser(auth))

      return Object.assign({}, state, { auth })
    }

    case 'CLEAR_AUTH': {
      Sentry.configureScope(scope =>
        scope.setUser({
          username: state.auth?.username || undefined,
          loggedOut: true
        })
      )

      return Object.assign({}, state, {
        auth: undefined,
        ship: undefined,
        userProfile: undefined
      })
    }

    case 'SET_USERPROFILE': {
      return Object.assign({}, state, { userProfile: action.userProfile })
    }

    default:
      return state
  }
}
