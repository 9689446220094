import { IApplicationTheme, ILayerCoverageDetails, ILayerCodeName } from '../../@types/types'

export type ISettingsAction =
  | ISetThemeOverride
  | IToggleListenToDeviceTheme
  | ISetSuppressedWarnings
  | IResetSuppressedWarnings

interface ISetThemeOverride {
  type: 'SET_THEMEOVERRIDE'
  theme: IApplicationTheme
}
export function setThemeOverride(theme: IApplicationTheme): ISetThemeOverride {
  return {
    type: 'SET_THEMEOVERRIDE',
    theme
  }
}

interface IToggleListenToDeviceTheme {
  type: 'TOGGLE_DEVICE_THEME'
}
export function toggleListenToDeviceTheme(): IToggleListenToDeviceTheme {
  return {
    type: 'TOGGLE_DEVICE_THEME'
  }
}

interface ISetSuppressedWarnings {
  type: 'SET_SUPPRESSED_WARNINGS'
  layer: ILayerCodeName
  partialWarnings: { [prompt in keyof ILayerCoverageDetails['prompts']]: boolean }
}
export function setSuppressedWarningsForLayer(
  layer: ILayerCodeName,
  partialWarnings: { [prompt in keyof ILayerCoverageDetails['prompts']]: boolean }
): ISetSuppressedWarnings {
  return {
    type: 'SET_SUPPRESSED_WARNINGS',
    layer,
    partialWarnings
  }
}

interface IResetSuppressedWarnings {
  type: 'RESET_SUPPRESSED_WARNINGS'
}
export function resetWarnings() {
  return {
    type: 'RESET_SUPPRESSED_WARNINGS'
  }
}
