import { divIcon as DIVIcon, LatLngBounds } from 'leaflet'
import * as React from 'react'
import { I18n } from 'react-redux-i18n'

import TeqplayApiService from '../../../../../services/TeqplayAPIService/TeqplayApiService'
import CollapsableLegendComponent from '../../../controls/CollapsableLegendComponent'
import DefaultMarkersLayer from '../defaultMarkerLayer/DefaultMarkerLayer'

import { IDrinkwaterPoint } from '../../../../../services/TeqplayAPIService/TeqplayApi'
import { SupportedLocales } from '../../../../../@types/types'

import './DrinkingWaterPointsLayer.scss'

interface IProps {
  name: string
  checked: boolean
  bounds?: LatLngBounds
  teqplayApiService: TeqplayApiService
  mapZoomLevel: number
  minimumZoomLevel: number
  setSelectedItem: (item: IDrinkwaterPoint) => void
  locale?: SupportedLocales
  disableInteraction?: boolean
}

const DrinkingWaterPointsLayer: React.FunctionComponent<IProps> = props => {
  if (!props.checked || props.mapZoomLevel < props.minimumZoomLevel) {
    return null
  }

  const color = '#2e6771'

  return (
    <React.Fragment>
      <DefaultMarkersLayer<IDrinkwaterPoint>
        fetchCall={(bounds?: LatLngBounds) =>
          props.teqplayApiService.fetchDrinkwaterPoints(bounds, props.locale)
        }
        determineDivIcon={i => {
          const icon = i.type === 'Land' ? 'icon-drinkwaterpoint' : 'icon-drinkwaterpoint-wave'

          return DIVIcon({
            className: '',
            html: `<div class="div-icon-marker-layer" style="background: ${color};
            color:${'#fff'}"><i class="${icon}"/>`,
            iconAnchor: [20, 20],
            iconSize: [40, 40]
          })
        }}
        onClick={!props.disableInteraction ? props.setSelectedItem : undefined}
        {...props}
      />
      <CollapsableLegendComponent
        icon={<span className="icon-drinkwaterpoint" />}
        position={'topright'}
        id={props.name}
      >
        <div className="item-legend dwp">
          <div className="name">{I18n.t('map.drinkwaterPoints.markerLand')}</div>
          <div className="icon">
            <i className="icon-drinkwaterpoint" />
          </div>
        </div>
        <div className="item-legend dwp">
          <div className="name">{I18n.t('map.drinkwaterPoints.markerWater')}</div>
          <div className="icon">
            <i className="icon-drinkwaterpoint-wave" />
          </div>
        </div>
      </CollapsableLegendComponent>
    </React.Fragment>
  )
}

export default DrinkingWaterPointsLayer
