import * as React from 'react'
import { I18n } from 'react-redux-i18n'
import { useSelector } from 'react-redux'

import PhoneNumberButton from '../phoneNumberButton/PhoneNumberButton'

import { ILockDetails } from '../../services/TeqplayAPIService/TeqplayApi'
import { determineOperatingRulesForDaySet } from '../../utils/openingHours'
import { parseStringForID } from '../../utils/tests'
import { IRootProps } from '../../@types/types'

interface IProps {
  itemDetails: ILockDetails | null
  isExpanded: boolean
  departureTime?: number | null
}

const LockDetails = ({ itemDetails, isExpanded, departureTime }: IProps) => {
  const locale = useSelector((s: IRootProps) => s.i18n.locale)
  if (!itemDetails || isExpanded === false) {
    return null
  }
  const { dateBasedRules, activeOperatingPeriods } = determineOperatingRulesForDaySet(
    itemDetails.operatingTimes,
    departureTime ? new Date(departureTime) : undefined,
    undefined,
    locale
  )
  const notes: string[] = [itemDetails.note, itemDetails?.operatingTimes?.note]
    .concat(activeOperatingPeriods.map(period => period.note))
    .filter(x => x)
    .filter((v, i, s) => s.indexOf(v) === i) as string[]

  return (
    <div className="item-info-details" id={`lock-details-${itemDetails.isrsCode}`}>
      <section className="details-section">
        {itemDetails.name && (
          <div className="route-item-caption" id="lock-details-name">
            <b>
              <span>{itemDetails.name}</span>
            </b>
          </div>
        )}
        {itemDetails.city && (
          <div className="route-item-caption" id="lock-details-city">
            <b>
              <span>{itemDetails.city}</span>
            </b>
          </div>
        )}
      </section>

      {(itemDetails.vhfChannel || itemDetails.phoneNumber) && (
        <section className="details-section">
          {itemDetails.vhfChannel ? (
            <div className="route-item-details-item" id="lock-details-vhf-channel">
              <b>{I18n.t('routeList.itemDetails.vhf')}:</b> {itemDetails.vhfChannel}
            </div>
          ) : null}
          {itemDetails.phoneNumber ? (
            <div className="route-item-details-item phone" id="lock-details-phone-number">
              <div className="title">
                <b>{I18n.t('phoneNumberButton.title')}:</b>
              </div>
              <PhoneNumberButton number={itemDetails.phoneNumber} />
            </div>
          ) : null}
        </section>
      )}

      {dateBasedRules.length > 0 && (
        <section className="details-section">
          <div className="opening-item" id="lock-details-opening-hours">
            <div className="route-item-caption">
              <b>{I18n.t('routeList.itemDetails.control_times')}:</b>
            </div>
            {dateBasedRules.map((time, key) => (
              <div
                key={key}
                className="opening-time-wrapper"
                id={`lock-details-opening-hour-${parseStringForID(
                  time.validDates[0].date.toDateString()
                )}`}
              >
                <div className="opening-time">
                  <div className="day-row">
                    <div className="dates">
                      {time.validDates.map((d, i) => (
                        <div className="date" key={i}>
                          {i !== 0 ? '& ' : ''}
                          {d.textFormatted} {d.isHoliday && `(${d.isHoliday.name})`}
                        </div>
                      ))}
                    </div>
                    <div className="rules">
                      {time.validRulesForToday.length === 0 && (
                        <div
                          className="rule"
                          id={`lock-details-opening-hour-${parseStringForID(
                            time.validDates[0].date.toDateString()
                          )}-no-service`}
                        >
                          {I18n.t('routeList.itemDetails.bridge_operating_rules.noService')}
                        </div>
                      )}
                      {time.validRulesForToday.map((rule, ruleKey) => (
                        <div
                          className="rule"
                          key={ruleKey}
                          id={`lock-details-opening-hour-${parseStringForID(
                            time.validDates[0].date.toDateString()
                          )}-${ruleKey}`}
                        >
                          <div className="time">
                            {rule.timespan === '00:00 - 23:59'
                              ? I18n.t('routeList.itemDetails.wholeDay')
                              : rule.timespan}
                          </div>
                          <div className="note">{rule.notes}</div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </section>
      )}

      {notes.map((note, noteIndex) => (
        <div key={noteIndex} id={`lock-details-note-${noteIndex}`}>
          <b>
            {I18n.t('routeList.itemDetails.note')}
            {notes.length > 1 ? ' ' + (noteIndex + 1) : ''}
          </b>
          : {note}
        </div>
      ))}

      {itemDetails.chambers && (
        <section className="details-section">
          {itemDetails.chambers
            .sort((a, b) => (a.number || 0) - (b.number || 0))
            .map((chamber, key) => (
              <section className="details-section" key={key} id={`lock-chamber-${chamber.number}`}>
                {chamber.number ? (
                  <div className="route-item-caption" id={`lock-chamber-${chamber.number}-number`}>
                    <b>{I18n.t('routeList.itemDetails.lock_chamber')}: </b>
                    {chamber.number}
                  </div>
                ) : (
                  chamber.name && (
                    <div className="route-item-details-item" id="lock-chamber-name">
                      <b>{I18n.t('routeList.itemDetails.name')}:</b> {chamber.name}
                    </div>
                  )
                )}
                {chamber.length ? (
                  <div
                    className="route-item-details-item"
                    id={`lock-chamber-${chamber.number}-length`}
                  >
                    <b>{I18n.t('routeList.itemDetails.length')}:</b> {chamber.length}m
                  </div>
                ) : null}
                {chamber.width ? (
                  <div
                    className="route-item-details-item"
                    id={`lock-chamber-${chamber.number}-width`}
                  >
                    <b>{I18n.t('routeList.itemDetails.width')}:</b> {chamber.width}m
                  </div>
                ) : null}
                {chamber.gateWidth ? (
                  <div
                    className="route-item-details-item"
                    id={`lock-chamber-${chamber.number}-max-width`}
                  >
                    <b>{I18n.t('routeList.itemDetails.max_width')}: </b> {chamber.gateWidth}m
                  </div>
                ) : null}
                {chamber.sillDepthBeBu && (
                  <div
                    className="route-item-details-item"
                    id={`lock-chamber-${chamber.number}-threshold-outside`}
                  >
                    <b>{I18n.t('routeList.itemDetails.threshold_outside')}:</b>{' '}
                    {chamber.sillDepthBeBu}m, [{itemDetails.referenceLevelBeBu}]
                  </div>
                )}
                {chamber.sillDepthBoBi && (
                  <div
                    className="route-item-details-item"
                    id={`lock-chamber-${chamber.number}-threshold-inside`}
                  >
                    <b>{I18n.t('routeList.itemDetails.threshold_inside')}:</b>{' '}
                    {chamber.sillDepthBoBi}m, [{itemDetails.referenceLevelBoBi}]
                  </div>
                )}
                {chamber.note && (
                  <div
                    className="route-item-details-item"
                    id={`lock-chamber-${chamber.number}-note`}
                  >
                    <b>{I18n.t('routeList.itemDetails.chamberNote')}:</b> {chamber.note}
                  </div>
                )}
              </section>
            ))}
        </section>
      )}

      {(itemDetails.address || itemDetails.postalCode) && (
        <section className="details-section">
          <div className="route-item-caption">
            <b>{I18n.t('routeList.itemDetails.address')}:</b>
          </div>
          {itemDetails.address ? (
            <p className="route-item-details-item" id="lock-address">
              {itemDetails.address}{' '}
            </p>
          ) : null}
          {itemDetails.postalCode && itemDetails.city ? (
            <p className="route-item-details-item" id="lock-city">
              {itemDetails.postalCode}, {itemDetails.city}
            </p>
          ) : null}
        </section>
      )}
    </div>
  )
}

export default LockDetails
