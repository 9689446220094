import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'

import TeqplayApiService from '../../services/TeqplayAPIService/TeqplayApiService'
import DefaultTransition from '../shared/defaultTransition/DefaultTransition'
import InactiveRouteSelect from './inactiveRouteSelect/InactiveRouteSelect'
import LocationSelect from './locationSelect/LocationSelect'
import RouteOptionsSelect from './routeOptionsSelect/RouteOptionsSelect'
import RouteSelectForm from './routeSelectForm/RouteSelectForm'
import BicsSelect from '../bics/bicsSelect/BicsSelect'
import BICSNewFeature from '../bics/bicsNewFeature/BICSNewFeature'

import {
  ILocationStatus,
  IRootProps,
  IRouteSearchProps,
  ITabs,
  RouteSelectionFields
} from '../../@types/types'
import {
  INavigationLocation,
  IRoute,
  IRouteLocation,
  IShipDimensions,
  IShipInfo
} from '../../services/TeqplayAPIService/TeqplayApi'

import './RouteSelect.scss'

interface IProps {
  routeSelection: IRouteSearchProps
  routeSelectionFormActive: boolean
  routeSelectionFormFieldActive: boolean
  selectedField: RouteSelectionFields | null
  selectedViaRouteIndex: number | null
  navigationLocations: INavigationLocation[]
  routeSuggestions: IRoute[] | null
  selectedRouteSuggestion: IRoute | null
  teqplayApiService: TeqplayApiService
  currentLocation: IShipInfo | null
  locationStatus: ILocationStatus
  BICSRoute?: IRoute | null
  setBICSRoute: (route: IRoute | null) => void
  resetAvailableRoutes?: () => void
  searchAvailableRoutes: () => void

  setRouteSelectionFormActive: () => void
  setRouteSelectionInactive: () => void
  setDepartureTime: (arrivalDate: Date) => void
  departureTime: number | null
  startRoute: (route: IRoute) => void
  reverseRoute: () => void

  setRouteSelectionFormFieldActive: (
    fieldName: RouteSelectionFields,
    viaRouteIndex?: number
  ) => void
  setRouteSelectionFieldInactive: () => void
  setSelectedRouteSuggestion: (routeSuggestion: IRoute | null) => void

  setFromLocation: (fromLocation: IRouteLocation | null) => void
  setToLocation: (toLocation: IRouteLocation | null) => void
  setViaRoutes: (viaRoutes: IRouteLocation[]) => void
  setRouteSelection: (all: IRouteSearchProps) => void
  selectedTab: ITabs
  setSelectedTab: (tab: ITabs) => void

  cruiseSpeed: number | undefined
  setCruiseSpeed: (s: number) => void

  dimensions: IShipDimensions | null
  setShowDimensionsDialog: () => void
  searchRouteSuggesions: (
    overrideAbleRouteSelection?: IRouteSearchProps,
    overridableDepartureTime?: number
  ) => void
  teqplayAPIService: TeqplayApiService
}

const RouteSelect = (props: IProps) => {
  const {
    routeSelection,
    routeSelectionFormActive,
    routeSelectionFormFieldActive,
    selectedField,
    selectedViaRouteIndex,
    navigationLocations,
    routeSuggestions,
    selectedRouteSuggestion,
    teqplayApiService,
    currentLocation,

    searchAvailableRoutes,

    setRouteSelectionFormActive,
    setRouteSelectionInactive,
    setDepartureTime,
    departureTime,
    startRoute,

    setRouteSelectionFormFieldActive,
    setRouteSelectionFieldInactive,
    setSelectedRouteSuggestion,
    BICSRoute,
    setBICSRoute,

    setFromLocation,
    setToLocation,
    setViaRoutes,
    reverseRoute,

    cruiseSpeed,
    setCruiseSpeed,
    dimensions,
    setShowDimensionsDialog,
    teqplayAPIService
  } = props

  const [showExtraOptions, setShowExtraOptions] = React.useState(false)
  const { currentUser } = useSelector((rootProps: IRootProps) => rootProps)
  const BICSAllowed =
    currentUser.userProfile?.isAdmin ||
    currentUser.userProfile?.apiAuthorizationGroups?.includes('BICS') ||
    currentUser.userProfile?.authorizationGroups?.includes('BICS')

  useEffect(() => {
    // Check if user is allowed for BICS, otherwise reset tab
    if (!BICSAllowed) {
      props.setSelectedTab('ROUTE')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [BICSAllowed])

  return (
    <>
      {BICSAllowed && <BICSNewFeature />}
      {!routeSelectionFormActive && !routeSelectionFormFieldActive ? (
        <DefaultTransition active={routeSuggestions ? false : true} slideFrom="top" offset={10}>
          {styleProps => (
            <div className="content-wrapper-top" style={styleProps}>
              <InactiveRouteSelect setRouteSelectionFormActive={setRouteSelectionFormActive} />
            </div>
          )}
        </DefaultTransition>
      ) : (
        <>
          <DefaultTransition
            active={routeSelectionFormActive && !routeSelectionFormFieldActive}
            slideFrom="top"
            offset={50}
            forceRenderOnHide={routeSuggestions ? true : false}
            disableYOffset={true}
          >
            {styleProps => (
              <div className="content-wrapper-top" style={styleProps}>
                {BICSAllowed && (
                  <div className="tabs">
                    <button
                      onClick={() => {
                        props.setSelectedTab('ROUTE')
                        if (BICSRoute) {
                          setBICSRoute(null)
                          props.resetAvailableRoutes && props.resetAvailableRoutes()
                        }
                      }}
                      className={props.selectedTab === 'ROUTE' ? 'active' : ''}
                    >
                      {I18n.t('title.routeSelection')}
                    </button>
                    <button
                      onClick={() => props.setSelectedTab('BICS')}
                      className={props.selectedTab === 'BICS' ? 'active' : ''}
                    >
                      {I18n.t('announcements.travelList.subtitle')}
                    </button>
                  </div>
                )}
                {props.selectedTab === 'ROUTE' ? (
                  <RouteSelectForm
                    key="routeSelectForm"
                    searchAvailableRoutes={searchAvailableRoutes}
                    routeSelection={routeSelection}
                    setRouteSelectionInactive={setRouteSelectionInactive}
                    setRouteSelectionFormFieldActive={setRouteSelectionFormFieldActive}
                    setViaRoutes={setViaRoutes}
                    setFromLocation={setFromLocation}
                    setDepartureTime={setDepartureTime}
                    teqplayApiService={teqplayApiService}
                    startRoute={startRoute}
                    currentLocation={currentLocation}
                    reverseRoute={reverseRoute}
                    departureTime={departureTime}
                    cruiseSpeed={cruiseSpeed}
                    setCruiseSpeed={setCruiseSpeed}
                    showExtraOptions={showExtraOptions}
                    setShowExtraOptions={setShowExtraOptions}
                    dimensions={dimensions}
                    setShowDimensionsDialog={setShowDimensionsDialog}
                  />
                ) : (
                  BICSAllowed && (
                    <BicsSelect
                      teqplayAPIService={props.teqplayAPIService}
                      locationStatus={props.locationStatus}
                      setRouteSelection={props.setRouteSelection}
                      setRouteSelectionInactive={props.setRouteSelectionInactive}
                      setFromLocation={setFromLocation}
                      setToLocation={setToLocation}
                      setViaRoutes={setViaRoutes}
                      searchRouteSuggesions={props.searchRouteSuggesions}
                      resetAvailableRoutes={props.resetAvailableRoutes}
                      BICSRoute={BICSRoute}
                      setBICSRoute={setBICSRoute}
                      startRoute={startRoute}
                    />
                  )
                )}
              </div>
            )}
          </DefaultTransition>

          <DefaultTransition
            active={
              routeSelectionFormActive &&
              routeSelectionFormFieldActive &&
              props.selectedTab !== 'BICS'
            }
            slideFrom="top"
            offset={50}
          >
            {styleProps => (
              <div className="content-wrapper-top overflow-visible" style={styleProps}>
                <LocationSelect
                  selectedField={selectedField}
                  selectedViaRouteIndex={selectedViaRouteIndex}
                  routeSelection={routeSelection}
                  navigationLocations={navigationLocations}
                  setRouteSelectionFieldInactive={setRouteSelectionFieldInactive}
                  setFromLocation={setFromLocation}
                  setToLocation={setToLocation}
                  setViaRoutes={setViaRoutes}
                  currentLocation={currentLocation}
                  teqplayAPIService={teqplayAPIService}
                />
              </div>
            )}
          </DefaultTransition>
        </>
      )}
      {props.routeSuggestions && (
        <RouteOptionsSelect
          routeSuggestions={routeSuggestions}
          selectedRouteSuggestion={selectedRouteSuggestion}
          setSelectedRouteSuggestion={setSelectedRouteSuggestion}
          teqplayApiService={teqplayApiService}
        />
      )}
    </>
  )
}

export default RouteSelect
