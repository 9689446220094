import { ILayerCodeName, MapTypes } from '../../@types/types'
import { IMapAction } from './actions'

export interface IMapState {
  activeMap: MapTypes
  activeLayers: ILayerCodeName[]
  showLimitedRangeOverlay: boolean
}

// Set inital login state
export const initialMapState: IMapState = {
  activeMap: 'DEFAULT',
  activeLayers: [],
  showLimitedRangeOverlay: true
}

// eslint-disable-next-line @typescript-eslint/default-param-last
export function mapReducer(state = initialMapState, action: IMapAction) {
  switch (action.type) {
    case 'SET_ACTIVE_MAP': {
      return Object.assign({}, state, { activeMap: action.map })
    }

    case 'SET_ACTIVE_LAYERS': {
      return Object.assign({}, state, { activeLayers: action.layers })
    }

    default:
      return state
  }
}
