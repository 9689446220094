import React, { useEffect, useState } from 'react'
import { I18n } from 'react-redux-i18n'
import Select, { ValueType } from 'react-select'
import { differenceInDays, formatDistanceToNow } from 'date-fns'
import { orderBy, uniqBy } from 'lodash'

import TeqplayApiService from '../../services/TeqplayAPIService/TeqplayApiService'

import { useDebounce } from '../../hooks/useDebounce'
import { IShipInfo } from '../../services/TeqplayAPIService/TeqplayApi'
import { selectStyles, sortByKey } from '../../utils/general'

import './ShipSelector.scss'

interface IProps {
  value: IShipInfo | null
  onChange: (ship: IShipInfo) => void
  isLoading?: boolean
  onlySeaVessels?: boolean
  placeholder?: string
  showDuplicates?: boolean
  teqplayAPIService: TeqplayApiService
  manualTokenOverride?: string
  isClearable?: boolean
}

const ShipSelector: React.FunctionComponent<IProps> = ({
  value,
  onChange,
  teqplayAPIService,
  isLoading,
  onlySeaVessels,
  placeholder,
  showDuplicates,
  manualTokenOverride,
  isClearable
}) => {
  const [shipOptions, setShipOptions] = useState<IShipInfo[]>([])
  const [searchValue, setSearchValue] = useState<string>('')
  const debouncedSearchValue = useDebounce(searchValue, 400)
  useEffect(() => {
    getShips(searchValue)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchValue])
  const [shipsLoading, setShipsLoading] = useState(false)

  const currentIsAncient = differenceInDays(new Date(), value?.timeLastUpdate) > 60
  const currentIsOld = differenceInDays(new Date(), value?.timeLastUpdate) > 7

  return (
    <div className="ship-input-wrapper">
      <Select<IShipInfo>
        isSearchable
        onChange={(ship: ValueType<IShipInfo>) => onChange(ship as IShipInfo)}
        inputValue={searchValue}
        onInputChange={(newValue, { action }) => {
          if (action !== 'menu-close' && action !== 'input-blur') {
            setSearchValue(newValue)
          }
        }}
        autoFocus={false}
        formatOptionLabel={(ship, select) => {
          if (select.context === 'value') {
            return `${ship.name} (${ship.mmsi})`
          }

          const isAncient = differenceInDays(new Date(), ship.timeLastUpdate) > 60
          const isOld = differenceInDays(new Date(), ship.timeLastUpdate) > 7

          return (
            <div
              className={`search-item${manualTokenOverride ? ' from-registration' : ''}`}
              id={`button-SHIP_${ship.name || ship._id}`}
            >
              <div className="row">
                <h3 className="ship-name">{ship ? ship.name : I18n.t('shipSearch.noName')}</h3>
                {ship.timeLastUpdate ? (
                  <div
                    className={`last-update${isAncient ? ' is-ancient' : isOld ? ' is-old' : ''}`}
                    id={`value-SHIP_${ship.name || ship._id}-TIMELASTUPDATE`}
                  >
                    {I18n.t('shipSearch.lastAISSignal', {
                      time: formatDistanceToNow(ship.timeLastUpdate, {
                        addSuffix: true
                      })
                    })}
                  </div>
                ) : null}
              </div>

              <div className="tags">
                {ship.shipType ? (
                  <div className="tag" id={`value-SHIP_${ship.name || ship._id}-SHIPTYPE`}>
                    <div className="value">{I18n.t(`shipTypes.${ship.shipType}`)}</div>
                  </div>
                ) : null}
                {ship.imoNumber ? (
                  <div className="tag" id={`value-SHIP_${ship.name || ship._id}-IMO`}>
                    <div className="type">IMO</div>
                    <div className="value">{ship.imoNumber}</div>
                  </div>
                ) : null}
                {ship.mmsi ? (
                  <div className="tag" id={`value-SHIP_${ship.name || ship._id}-MMSI`}>
                    <div className="type">MMSI</div>
                    <div className="value">{ship.mmsi}</div>
                  </div>
                ) : null}
                {ship.eni ? (
                  <div className="tag" id={`value-SHIP_${ship.name || ship._id}-ENI`}>
                    <div className="type">ENI</div>
                    <div className="value">{ship.eni}</div>
                  </div>
                ) : null}
              </div>
            </div>
          )
        }}
        openMenuOnFocus
        getOptionValue={ship => ship.mmsi || 'no-mmsi'}
        placeholder={placeholder || I18n.t('shipSearch.placeholder')}
        value={value || null}
        isClearable={isClearable}
        noOptionsMessage={({ inputValue }) =>
          inputValue ? I18n.t('shipSearch.noResults') : I18n.t('shipSearch.searchExtended')
        }
        styles={selectStyles}
        defaultOptions
        options={shipOptions}
        filterOption={() => true}
        isLoading={shipsLoading || isLoading}
        createOptionPosition={'first'}
        classNamePrefix="location-search selector"
        className="location-search"
        inputId="location-select-input"
        id="location-select-creatable"
        maxMenuHeight={200} // needed for mobile landscape
      />
      {value && value.timeLastUpdate && (
        <div
          className={`last-updated${
            currentIsAncient ? ' is-ancient' : currentIsOld ? ' is-old' : ''
          }`}
        >
          {I18n.t('shipSearch.lastAISSignal', {
            time: formatDistanceToNow(value.timeLastUpdate, {
              addSuffix: true
            })
          })}
        </div>
      )}
    </div>
  )

  function getShips(newSearchValue: string | number) {
    const name: string | undefined = isNaN(newSearchValue as number)
      ? newSearchValue.toString()
      : undefined
    const mmsi: string | undefined = !isNaN(newSearchValue as number)
      ? newSearchValue.toString()
      : undefined
    const imo: string | undefined = !isNaN(newSearchValue as number)
      ? newSearchValue.toString()
      : undefined
    const eni: string | undefined = !isNaN(newSearchValue as number)
      ? newSearchValue.toString()
      : undefined

    if ((name || mmsi || imo || eni || '')?.length > 2) {
      getShipsByQuery(name || '', mmsi, imo, eni)
    }
  }

  async function getShipsByQuery(
    name: string,
    mmsi?: string,
    imo?: string,
    eni?: string
  ): Promise<IShipInfo[]> {
    try {
      setShipsLoading(true)
      const fetchedShips = await teqplayAPIService.searchShipsByQuery(
        name,
        mmsi,
        imo,
        eni,
        undefined,
        undefined,
        undefined,
        10,
        false,
        onlySeaVessels,
        false,
        manualTokenOverride
      )

      const sortedShips = orderBy(fetchedShips, ['name', 'timeLastUpdate'], ['asc', 'desc'])
      const uniqueResults = showDuplicates
        ? sortedShips
        : uniqBy<IShipInfo>(sortByKey(sortedShips, 'timeLastUpdate', 'desc'), 'imoNumber')

      setShipOptions(uniqueResults)
    } catch (err) {
      setShipOptions([])
    } finally {
      setShipsLoading(false)
    }

    return []
  }
}

export default React.memo(ShipSelector)
