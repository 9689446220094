import L from 'leaflet'
import React, { useRef, useState } from 'react'
import UpdatableControl from './UpdatableControl'

import { parseStringForID } from '../../../utils/tests'
import './CollapsableLegendComponent.scss'

interface IProps {
  children: any
  className?: string
  icon?: React.ReactNode
  hideCollapsible?: boolean
  position?: L.ControlPosition
  ref?: any
  id: string
}

const CollapsableLegendComponent = ({
  icon,
  hideCollapsible,
  position,
  className,
  id,
  children
}: IProps) => {
  const CollapsableRef = useRef<any>()

  const [isCollapsed, setCollapsed] = useState(false)
  const iconClosePosition =
    position?.indexOf('left') !== -1
      ? isCollapsed
        ? 'right'
        : 'left'
      : isCollapsed
      ? 'left'
      : 'right'

  return (
    <UpdatableControl
      position={position || 'topleft'}
      className={`collapsable-legend-wrapper ${
        position?.indexOf('left') !== -1 ? 'left' : 'right'
      }${className ? ` ${className}` : ''}`}
      ref={CollapsableRef}
    >
      <React.Fragment>
        {hideCollapsible ? null : (
          <div
            className={`legend-button ${isCollapsed ? 'retracted' : 'extended'} ${
              position?.indexOf('left') !== -1 ? 'left' : 'right'
            }`}
            onClick={() => {
              setCollapsed(!isCollapsed)
            }}
            id={`collapsable-legend-component-${parseStringForID(id)}-collapsable`}
          >
            <div className="indicating-icon">
              <span className={`icon-${iconClosePosition}-open`} />
            </div>
            <div className="custom-icon">{icon || <span className="icon-cancel-1" />}</div>
          </div>
        )}
        {!isCollapsed && (
          <div
            className="legend-content"
            id={`collapsable-legend-component-${parseStringForID(id)}-content`}
          >
            {children}
          </div>
        )}
      </React.Fragment>
    </UpdatableControl>
  )
}

export default CollapsableLegendComponent
