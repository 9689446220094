import React from 'react'
import { DatePicker } from '@teqplay/teqplay-ui'
import { I18n } from 'react-redux-i18n'

import MobileDatePicker from './MobileDatePicker'
import { isCordovaApp } from '../../../utils/cordovaUtils'

import '../DateTime.scss'

interface IProps {
  onDateChange: (
    isoString: string | null,
    inputValue: string,
    isValid: boolean,
    fieldName?: string
  ) => void
  icon: React.ReactElement
  initialDate?: string
  dateSeperator?: string
  dialogTitle?: string
  locale?: string
  value?: string
  timeZone?: string
  hideDialogHeader?: boolean
  dialogId?: string
  datePickerId?: string
  name?: string
  id?: string
}

const MobileOrRegularDatePicker = (props: IProps) => {
  if (isCordovaApp) {
    return <MobileDatePicker {...props} />
  }
  return (
    <DatePicker
      className="datepicker-input"
      dialogTitle={I18n.t('datetime.selectDate')}
      {...props}
    />
  )
}

export default MobileOrRegularDatePicker
