import { distanceTo } from 'geolocation-utils'
import {
  LatLng,
  LatLngBounds,
  LatLngLiteral,
  LatLngTuple,
  LeafletMouseEvent,
  Map as MapProps
} from 'leaflet'
import isEqual from 'lodash/isEqual'
import polyUtil from 'polyline-encoded'
import * as React from 'react'
import { Map, Marker, Pane, Polyline, ZoomControl } from 'react-leaflet'
import { I18n } from 'react-redux-i18n'
import { toast } from 'react-toastify'

import TeqplayApiService from '../../services/TeqplayAPIService/TeqplayApiService'
import CustomControlButton from '../mapShared/controls/CustomControlButton'
import CustomLayers from '../mapShared/customLayers/CustomLayers'
import UserShipMarker from '../mapShared/userShipMarker/UserShipMarker'
import RouteItemMarker from './routeItemMarker/RouteItemMarker'
import SelectedItemModal from '../selectedItemModal/SelectedItemModal'

import {
  IApplicationTheme,
  MapTypes,
  SuppressedWarnings,
  SelectedItemModalEnum,
  ILayerCodeName,
  ILayerCoverageDetails
} from '../../@types/types'
import { AnalyticsContextType } from '../../services/AnalyticsWrapper/AnalyticsWrapper'
import {
  IBridgeMovement,
  IBridgePlanning,
  ILockPlanning,
  IRoute,
  ISelectedRoute,
  IShipInfo
} from '../../services/TeqplayAPIService/TeqplayApi'
import { NETHERLANDS_CENTER } from '../../utils/constants'
import { arrivalIcon, departureIcon } from '../mapShared/mapIcons'
import { centerViewOnLocation, getMapTiles } from '../mapShared/mapUtils'

import '../mapShared/LeafletMap.scss'

interface IProps {
  analytics: AnalyticsContextType
  token: string
  hideControlButtons?: boolean
  navigationRoute: ISelectedRoute
  currentLocation: IShipInfo | null
  activeMap: MapTypes
  teqplayApiService: TeqplayApiService
  onChangeActiveMap: (activeMap: MapTypes) => void
  theme: IApplicationTheme
  suppressedWarnings: SuppressedWarnings
  setSuppressedWarnings: (
    l: ILayerCodeName,
    prompts: { [prompt in keyof ILayerCoverageDetails['prompts']]: boolean }
  ) => void
  showLimitedRangeLayer: boolean
  activeLayers: ILayerCodeName[]
  onChangeActiveLayers: (layers: ILayerCodeName[]) => void
  bridgePlanning: IBridgePlanning[]
  bridgeMovement: IBridgeMovement[]
  lockPlanning: ILockPlanning[]
}

interface IState {
  followMode: boolean
  layerSelectActive: boolean
  mapApiIsSet: boolean
  bounds: LatLngBounds | null
  mapZoomLevel: number
  clickedLocation: LatLng | null
  center: LatLng | LatLngLiteral | LatLngTuple
  selectedItem: SelectedItemModalEnum | undefined
}

class MapNavigation extends React.PureComponent<IProps, IState> {
  public mapApi: MapProps | null = null

  constructor(props: IProps) {
    super(props)
    this.state = {
      followMode: false,
      layerSelectActive: false,
      mapApiIsSet: false,
      bounds: null,
      selectedItem: undefined,
      mapZoomLevel: this.props.currentLocation ? 13 : 7,
      center: this.props.currentLocation?.location
        ? {
            lat: this.props.currentLocation.location.latitude,
            lng: this.props.currentLocation.location.longitude
          }
        : NETHERLANDS_CENTER,
      clickedLocation: null
    }
  }

  public componentDidMount() {
    const mapRef: any = this.refs.map
    if (mapRef && mapRef.leafletElement) {
      this.mapApi = mapRef.leafletElement
      // make sure to reload view once, when mapApi is set
      centerViewOnLocation(this.mapApi, this.props.currentLocation, 13)
      this.setState({ mapApiIsSet: true })
    }
  }

  public componentDidUpdate(prevProps: IProps) {
    if (this.state.followMode && !isEqual(prevProps.currentLocation, this.props.currentLocation)) {
      centerViewOnLocation(this.mapApi, this.props.currentLocation)
    } else if (
      !isEqual(prevProps.currentLocation, this.props.currentLocation) &&
      this.state.center === NETHERLANDS_CENTER &&
      this.state.mapZoomLevel === 7
    ) {
      centerViewOnLocation(this.mapApi, this.props.currentLocation, 12)
    }
  }

  public render() {
    const { followMode, layerSelectActive } = this.state
    const { navigationRoute, activeLayers } = this.props
    const followClass = followMode ? 'follow-active' : ''
    const layerSelectClass = layerSelectActive ? 'layer-select-active' : ''
    const currentLocation = this.props.currentLocation

    return (
      <>
        <div className={`leaflet-container ${followClass} ${layerSelectClass}`} id="map-navigation">
          <div className="leaflet-map-wrapper">
            <SelectedItemModal
              selectedItem={this.state.selectedItem}
              clearSelectedItem={() => this.setSelectedItem(undefined)}
              teqplayApiService={this.props.teqplayApiService}
              bridgePlanning={this.props.bridgePlanning}
              bridgeMovement={this.props.bridgeMovement}
            />

            <Map
              center={this.state.center}
              zoom={this.state.mapZoomLevel}
              zoomControl={false}
              onMoveend={this.handleMoveend}
              onZoomend={this.handleZoom}
              onDragstart={this.handleDragStart}
              ref="map"
              attributionControl={false}
              onClick={this.onMapClick}
            >
              {getMapTiles(this.props.activeMap, this.props.theme)}

              <Pane className="pane-layerobjects">
                {/* Controls */}
                {this.props.hideControlButtons !== true && (
                  <>
                    <ZoomControl position={'bottomright'} />
                    <CustomControlButton
                      title={I18n.t('map.followButton')}
                      onClick={this.toggleFollowMode}
                      tag="follow"
                      position="bottomright"
                    >
                      <i className="icon-user-location" />
                    </CustomControlButton>
                    <CustomControlButton
                      title={I18n.t('map.activelayers')}
                      onClick={this.toggleLayerSelect}
                      tag="layers"
                      position="bottomright"
                    >
                      <i className="icon-layers" />
                    </CustomControlButton>

                    {/* Custom layers */}
                    <CustomLayers
                      mapApi={this.mapApi}
                      activeLayers={activeLayers}
                      token={this.props.token}
                      onChangeActiveLayers={this.handleChangeActiveLayers}
                      popupActive={layerSelectActive}
                      togglePopupActive={this.toggleLayerSelect}
                      activeMap={this.props.activeMap}
                      onChangeActiveMap={this.props.onChangeActiveMap}
                      teqplayApiService={this.props.teqplayApiService}
                      mapZoomLevel={this.state.mapZoomLevel}
                      currentLocation={currentLocation}
                      clickedLocation={this.state.clickedLocation}
                      suppressedWarnings={this.props.suppressedWarnings}
                      setSuppressedWarnings={this.props.setSuppressedWarnings}
                      showLimitedRangeLayer={this.props.showLimitedRangeLayer}
                      theme={this.props.theme}
                      bridgeMovement={this.props.bridgeMovement}
                      selectedItem={this.state.selectedItem}
                      setSelectedItem={this.setSelectedItem}
                    />
                  </>
                )}
              </Pane>

              <Pane className="pane-routeobjects">
                <Pane className="route-lines">
                  {this.createRoutePolylines(navigationRoute.route)}
                </Pane>
                <Pane className="route-markers">
                  {this.createUserFromToLocationMarkers(this.props.navigationRoute)}
                  {this.createRouteItemMarkers(this.props.navigationRoute)}
                </Pane>
                <Pane className="route-foreground">
                  {currentLocation && currentLocation.location && (
                    <UserShipMarker
                      courseOverGround={
                        currentLocation && currentLocation.courseOverGround
                          ? currentLocation.courseOverGround
                          : null
                      }
                      location={{
                        lat: currentLocation.location.latitude,
                        lng: currentLocation.location.longitude
                      }}
                    />
                  )}
                </Pane>

                {this.props.children}
              </Pane>
            </Map>
          </div>
        </div>
      </>
    )
  }

  /**
   * Refresh overlay's when map is moved
   */
  private handleMoveend = () => {
    if (!this.mapApi) return
    const mapBounds = this.mapApi.getBounds()
    const center = this.mapApi.getCenter()
    this.setState({ bounds: mapBounds, center })
  }

  private handleDragStart = () => {
    if (!this.mapApi || !this.state.followMode) return
    this.setState({ followMode: false })
  }

  private handleZoom = () => {
    if (!this.mapApi) return
    const currentLocation = this.props.currentLocation

    if (this.state.followMode && currentLocation && currentLocation.location) {
      this.mapApi.setView(
        { lat: currentLocation.location.latitude, lng: currentLocation.location.longitude },
        this.mapApi.getZoom()
      )
    }

    const zoomLevel = this.mapApi.getZoom()
    if (zoomLevel && Number.isInteger(zoomLevel)) {
      this.setState({
        mapZoomLevel: zoomLevel
      })
    }
  }

  private onMapClick = (e: LeafletMouseEvent) => {
    this.setState({ clickedLocation: e.latlng })
  }

  private handleChangeActiveLayers = (activeLayers: ILayerCodeName[]) => {
    this.props.onChangeActiveLayers(activeLayers)
  }

  private toggleFollowMode = () => {
    if (this.props.currentLocation) {
      this.props.analytics.newEvent('toggle_follow_mode', { followMode: !this.state.followMode })
      centerViewOnLocation(this.mapApi, this.props.currentLocation)
      this.setState({ followMode: !this.state.followMode })
    } else {
      this.props.analytics.newEvent('error_follow_mode', {
        currentLocation: this.props.currentLocation
      })
      this.setState({ followMode: false })
      toast.warn(I18n.t('navigationPage.no_user_location_warning'))
    }
  }

  private toggleLayerSelect = () => {
    this.props.analytics.newEvent('toggle_layer_selection_menu', {
      layerSelectActive: !this.state.layerSelectActive
    })
    this.setState({ layerSelectActive: !this.state.layerSelectActive })
  }

  private createRoutePolylines = (navigationRoute: IRoute) => {
    const polyLine = polyUtil.decode(navigationRoute.polyLine)
    if (!polyLine) {
      return
    }

    const routePolyline = (
      <Polyline
        key={navigationRoute._id}
        color={'#2e6771'}
        positions={polyLine}
        ref={navigationRoute._id}
        stroke={true}
        weight={7}
      />
    )

    const firstRouteCoordinate = polyLine[0]
    const lastRouteCoordinate = polyLine[polyLine.length - 1]
    const fromCoordinates = navigationRoute.from.split(',')
    const toCoordinates = navigationRoute.to.split(',')
    if (
      fromCoordinates &&
      fromCoordinates.length === 2 &&
      toCoordinates &&
      toCoordinates.length === 2
    ) {
      const fromCoordinatesLatLng = [Number(fromCoordinates[0]), Number(fromCoordinates[1])] as [
        number,
        number
      ]
      const toCoordinatesLatLng = [Number(toCoordinates[0]), Number(toCoordinates[1])] as [
        number,
        number
      ]
      // Check if first routecoordinate is the from location or to location
      const firstAndLastOrdered =
        distanceTo(fromCoordinatesLatLng as any, firstRouteCoordinate as any) <
        distanceTo(fromCoordinatesLatLng as any, lastRouteCoordinate as any)

      const toStartPolyline = (
        <Polyline
          key={'pickedLocationToStart'}
          positions={[
            firstAndLastOrdered ? firstRouteCoordinate : lastRouteCoordinate,
            fromCoordinatesLatLng
          ]}
          color={'#878787'}
          dashArray={'10 10'}
          weight={4}
        />
      )

      const toEndPolyline = (
        <Polyline
          key={'pickedLocationToEnd'}
          positions={[
            firstAndLastOrdered ? lastRouteCoordinate : firstRouteCoordinate,
            toCoordinatesLatLng
          ]}
          color={'#878787'}
          dashArray={'10 10'}
          weight={4}
        />
      )

      return [toStartPolyline, toEndPolyline, routePolyline]
    }

    return [routePolyline]
  }

  private createRouteItemMarkers = (navigationRoute: ISelectedRoute) => {
    const allRouteItems = navigationRoute.route.routeItems

    return allRouteItems.map((routeItem, index) => {
      if (routeItem.type === 'TRAFFIC_SIGN' || routeItem.type === 'BOTTLENECK') {
        return null
      }

      const b = this.props.bridgeMovement.find(bm => bm.isrsId === routeItem.refUuid)

      const locationDuplicates = allRouteItems.filter(
        (ri, riIndex) =>
          // Check if any items have the exact same location
          isEqual(routeItem.location[0].coordinates, ri.location[0].coordinates) ||
          // Or if they are less than 1m apart
          distanceTo(routeItem.location[0].coordinates, ri.location[0].coordinates) < 5
      )

      const locationOffset =
        locationDuplicates.length > 1
          ? locationDuplicates.findIndex(ri => ri._id === routeItem._id) / 5000
          : 0

      const newLocation: [number, number] | undefined = locationOffset
        ? [
            routeItem.location[0].coordinates[0] + locationOffset,
            routeItem.location[0].coordinates[1] + locationOffset
          ]
        : undefined

      return (
        <RouteItemMarker
          routeItem={{
            ...routeItem,
            location: [{ coordinates: newLocation || routeItem.location[0].coordinates }] as any,
            status: b?.status
          }}
          key={'RouteItemMarker' + routeItem._id}
          setSelectedItem={this.setSelectedItem}
        />
      )
    })
  }

  private createUserFromToLocationMarkers = (navigationRoute: ISelectedRoute) => {
    const locationMarkers = []
    const fromCoordinates = navigationRoute.route.from.split(',')
    const toCoordinates = navigationRoute.route.to.split(',')

    if (
      fromCoordinates &&
      fromCoordinates.length === 2 &&
      toCoordinates &&
      toCoordinates.length === 2
    ) {
      const fromCoordinatesLatLng = [Number(fromCoordinates[0]), Number(fromCoordinates[1])] as [
        number,
        number
      ]
      const toCoordinatesLatLng = [Number(toCoordinates[0]), Number(toCoordinates[1])] as [
        number,
        number
      ]

      if (fromCoordinatesLatLng) {
        locationMarkers.push(
          <Marker
            position={fromCoordinatesLatLng}
            key={'fromLocationMarker'}
            icon={departureIcon}
          />
        )
      }

      if (toCoordinatesLatLng) {
        locationMarkers.push(
          <Marker position={toCoordinatesLatLng} key={'toLocationMarker'} icon={arrivalIcon} />
        )
      }
    }
    return locationMarkers
  }

  private setSelectedItem = (newItem: SelectedItemModalEnum | undefined) => {
    this.props.analytics.newEvent(!newItem ? 'clear_selected_item' : 'set_selected_item', {
      type: newItem?.type
    })
    this.setState({ selectedItem: newItem })
  }
}

export default MapNavigation
