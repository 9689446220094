import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { toast } from 'react-toastify'
import { Dispatch } from 'redux'

import { IRootProps } from '../../../@types/types'
import { updateNavigationRoute } from '../../../pages/routeSelectionPage/actions'
import { useAnalytics } from '../../../services/AnalyticsWrapper/AnalyticsWrapper'
import { SpeedTypes } from '../../../services/TeqplayAPIService/TeqplayApi'
import TeqplayApiService from '../../../services/TeqplayAPIService/TeqplayApiService'
import { activeClass, kmhToKnots, knotsToKmh } from '../../../utils/general'
import SwitchButton from '../../shared/switchButton/SwitchButton'
import { setSpeedType, setUserInputSpeed } from './actions'

import './SetSpeed.scss'

interface IDispatchProps {
  setSpeedType: (speedType: SpeedTypes) => void
  setUserInputSpeed: (speed: number) => void
  updateNavigationRoute: (
    teqplayApiService: TeqplayApiService,
    speedType: SpeedTypes,
    speed: number
  ) => void
}

interface IProps {
  teqplayApiService: TeqplayApiService
}

const SetSpeed = (props: IProps & IRootProps & IDispatchProps) => {
  const analytics = useAnalytics('SetSpeed')
  const [unsavedSpeed, setUnsavedSpeed] = useState<number | null>(null)
  const [unsavedSpeedType, setUnsavedSpeedType] = useState<SpeedTypes | null>(null)

  useEffect(() => {
    analytics.newEvent('open_set_speed', {})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const speedType = unsavedSpeedType || props.speed.speedType
  const speed = unsavedSpeed !== null ? unsavedSpeed : props.speed.userInputSpeed
  const useShipSpeed = speedType === 'SHIP'

  return (
    <div className="set-speed-wrapper" id="set-speed">
      <h4>{I18n.t('map.speed.title')}</h4>
      <div className="inner">
        <SwitchButton>
          <button
            className={`button large use-ship-speed ${activeClass(useShipSpeed, 'primary')}`}
            onClick={() => onSelectSpeedType('SHIP')}
            id="set-speed-use-ship"
          >
            {I18n.t('map.speed.useShip')}
          </button>
          <button
            className={`button large ${activeClass(!useShipSpeed, 'primary')}`}
            onClick={() => onSelectSpeedType('CUSTOM')}
            id="set-speed-use-custom"
          >
            {I18n.t('map.speed.useCustom')}
          </button>
        </SwitchButton>

        {!useShipSpeed && (
          <div className="form-row">
            <input
              type="number"
              value={knotsToKmh(speed)}
              onChange={handleSpeedChange}
              className="input speed-input"
              step="1"
              id="set-speed-input-custom"
            />
            <span>{I18n.t('unit.kmh')}</span>

            <div className="button-container">
              <button
                className="button large primary"
                id="set-speed-save"
                onClick={handleClickSave}
              >
                {I18n.t('save')}
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  )

  function handleSpeedChange(e: React.ChangeEvent<any>) {
    const value = kmhToKnots(Number(e.target.value))

    setUnsavedSpeed(value)
  }

  async function onSelectSpeedType(type: 'SHIP' | 'CUSTOM') {
    if (unsavedSpeedType !== type) {
      analytics.newEvent('toggle_speed_type', {
        type,
        speed: type === 'SHIP' ? unsavedSpeed : null
      })
      await setUnsavedSpeedType(type)
      handleClickSave()
    }
  }

  function handleClickSave() {
    const st = unsavedSpeedType || props.speed.speedType
    if (st === 'CUSTOM') {
      // Save user speed to redux
      const s = unsavedSpeed !== null ? unsavedSpeed : props.speed.userInputSpeed
      if (knotsToKmh(s) >= 1 && knotsToKmh(s) <= 50) {
        analytics.newEvent('set_user_input_speed', { s })
        props.setUserInputSpeed(s)
      } else {
        toast.error(I18n.t('map.speed.setRealisticSpeed'))
        return
      }
    }

    // Save speed type to redux
    if (unsavedSpeedType) {
      props.setSpeedType(unsavedSpeedType)
    }

    // Done saving, close popup
    if (unsavedSpeed || unsavedSpeedType) {
      const typeSpeed = unsavedSpeedType || props.speed.speedType
      const s = unsavedSpeed !== null ? unsavedSpeed : props.speed.userInputSpeed
      props.updateNavigationRoute(props.teqplayApiService, typeSpeed, s)
      setUnsavedSpeed(null)
      setUnsavedSpeedType(null)
      toast.success(I18n.t('map.speed.saved'))
    }
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    setSpeedType: (speedType: SpeedTypes) => dispatch(setSpeedType(speedType)),
    setUserInputSpeed: (speed: number) => dispatch(setUserInputSpeed(speed)),
    updateNavigationRoute: (
      teqplayApiService: TeqplayApiService,
      speedType: SpeedTypes,
      speed: number
    ) => updateNavigationRoute(dispatch, teqplayApiService, speedType, speed)
  }
}

const mapStateToProps = (state: IRootProps, ownProps: {}) => {
  return state
}

export default connect(mapStateToProps, mapDispatchToProps)(SetSpeed)
