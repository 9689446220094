import { divIcon } from 'leaflet'
import React from 'react'

import TeqplayApiService from '../../../../../services/TeqplayAPIService/TeqplayApiService'
import DefaultMarkersLayer from '../defaultMarkerLayer/DefaultMarkerLayer'

import './KilometreringsLayer.scss'

interface IProps {
  name: string
  bounds: L.LatLngBounds | undefined
  checked: boolean
  teqplayApiService: TeqplayApiService
  mapZoomLevel: number
  minimumZoomLevel: number
}

export interface IKilometrering {
  name: string
  loc: { coordinates: [number, number] }
  axisPosition?: 'L' | 'R'
  fairwayId?: number
  type: string
  _id: string
}

const KilometreringsLayer = (props: IProps) => {
  if (props.mapZoomLevel < props.minimumZoomLevel) {
    return null
  }

  return (
    <DefaultMarkersLayer<IKilometrering>
      fetchCall={(bounds?: L.LatLngBounds) =>
        props.teqplayApiService.getKilometreringInBounds(bounds)
      }
      fontIconClass="icon-kilometer"
      backgroundColor="#2e6771"
      determineDivIcon={hectometer =>
        divIcon({
          className: 'div-icon-marker',
          html:
            '<div class="div-icon hectometer-icon"><span className="popup-kilometrering-km">' +
            hectometer.name +
            '</span></div>',
          iconAnchor: [17, 10],
          iconSize: [34, 20]
        })
      }
      {...props}
    />
  )
}

export default KilometreringsLayer
