import { useQuery } from 'react-query'

import { IShipInfo } from '../services/TeqplayAPIService/TeqplayApi'
import TeqplayApiService from '../services/TeqplayAPIService/TeqplayApiService'

export function useCurrentShip(
  api: TeqplayApiService,
  shipId: string | undefined
): {
  shipInformation: IShipInfo | null
  // The sole reason there is an `any` here is because I could not get the types to work.
  // The function does return a promise but its return type is not cooperating
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  refresh: () => Promise<any>
  loading: boolean
  error: {} | null
} {
  const {
    data: shipInformation,
    isFetching: loading,
    error,
    refetch: refresh
  } = useQuery<IShipInfo | null | undefined, {} | null>(
    ['current-ship-location', { shipId }],
    () => api.getCurrentShipInformation(),
    {
      refetchIntervalInBackground: false,
      refetchInterval: 30 * 1000
    }
  )

  return {
    shipInformation: shipInformation || null,
    refresh,
    loading,
    error
  }
}
