import { nl, enGB, fr } from 'date-fns/locale'
import { SupportedLocales } from '../@types/types'

export function currentLocale(locale: SupportedLocales) {
  switch (locale) {
    case 'nl_NL':
      return nl
    case 'en_GB':
      return enGB
    case 'fr_FR':
      return fr
    default:
      return nl
  }
}

// Return en_GB to en for BE compatibility
export function localeToBELang(locale?: SupportedLocales) {
  return locale ? locale.split('_')[0] : undefined
}
