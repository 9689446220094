import React, { useEffect, useState } from 'react'
import Modal from 'react-modal'
import { useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { toast } from 'react-toastify'

import BICSDestinationPicker from '../bicsDestinationPicker/BICSDestinationPicker'
import BICSDestinationPresetList from '../bicsDestinationPresetList/BICSDestinationPresetList'
import BICSHullSelectorModal from '../bicsHullSelectorModal/BICSHullSelectorModal'
import BICSGenericInputRow from '../bicsGenericInputRow/BICSGenericInputRow'
import BICSCargoSelector from '../bicsGoodsSelector/BICSGoodsSelector'
import BICSTransportTypeSelector from '../bicsTransportTypeSelector/BICSTransportTypeSelector'
import BICSHullSelector from '../bicsHullSelector/BICSHullSelector'
import CustomNumberInput from '../../customNumberInput/CustomNumberInput'
import TeqplayApiService from '../../../services/TeqplayAPIService/TeqplayApiService'
import SwitchButton from '../../shared/switchButton/SwitchButton'

import { ICustomError, IRootProps, IRouteBicsDestination } from '../../../@types/types'
import { useSessionStorage } from '../../../hooks/useSessionStorage'
import {
  BicsCargo,
  BicsDestination,
  BicsVoyageCargo,
  BicsHull
} from '../../../services/TeqplayAPIService/TeqplayApi'
import { activeClass } from '../../../utils/general'
import { determineApplicationTheme } from '../../../utils/style'

import './BICSGoodsModal.scss'

interface IProps {
  teqplayAPIService: TeqplayApiService
  value: BicsVoyageCargo | null
  setValue: (cargo: BicsVoyageCargo | null) => void
  showModal: boolean
  closeModal: () => void
  travelId: string
  cargoIndex: number | 'new' | undefined
  disabled?: boolean
  routeLocations?: IRouteBicsDestination[]
  mainHull: BicsHull | undefined
  hullsInVoyage: BicsHull[]
  otherCargo: BicsVoyageCargo[]
}

const BICSGoodsModal = ({
  teqplayAPIService,
  value,
  setValue,
  showModal,
  closeModal,
  travelId,
  cargoIndex,
  disabled,
  routeLocations,
  mainHull,
  hullsInVoyage,
  otherCargo
}: IProps) => {
  const { listenToDeviceTheme, themeOverride } = useSelector((props: IRootProps) => props.settings)
  const theme = determineApplicationTheme(listenToDeviceTheme, themeOverride)
  const customStorageKey = (suffix: string) => `BICS-trip-${travelId}-cargo-${cargoIndex}-${suffix}`

  const [fromLocation, setFromLocation] = useSessionStorage<BicsDestination | string | undefined>(
    customStorageKey('fromLocation'),
    value?.fromLocation
  )
  const [toLocation, setToLocation] = useSessionStorage<BicsDestination | string | undefined>(
    customStorageKey('toLocation'),
    value?.toLocation
  )
  const [transportType, setTransportType] = useSessionStorage<
    BicsVoyageCargo['transportType'] | undefined
  >(customStorageKey('transportType'), value?.transportType)
  const [dangerousGoods, setDangerousGoods] = useState<boolean | undefined>(undefined)
  const [selectedGoods, setSelectedGoods] = useSessionStorage<BicsCargo | undefined>(
    customStorageKey('selectedGoods'),
    undefined
  )
  const [residual, setResidual] = useSessionStorage<boolean | undefined>(
    customStorageKey('isEmpty'),
    value?.residual || undefined
  )
  const [weightTn, setWeightTn] = useSessionStorage<number | undefined | string>(
    customStorageKey('weightTn'),
    value?.weightTn || undefined
  )
  const [volumeM3, setVolumeM3] = useSessionStorage<number | undefined | string>(
    customStorageKey('volumeM3'),
    value?.volumeM3 || undefined
  )
  const [shipId, setShipId] = useSessionStorage<string | undefined>(
    customStorageKey('shipId'),
    value?.shipId || (hullsInVoyage && hullsInVoyage.length === 1)
      ? mainHull?.mmsi || mainHull?.eni
      : undefined
  )

  const [showHullSelectModal, setShowHullSelectModal] = useState<boolean>(false)
  const [errors, setErrors] = useState<ICustomError<keyof BicsVoyageCargo>[]>([])

  // If data is not yet initialized yet (value = null), but then has been initialized (value = { ... })
  // Rehydrate state/storage by re-setting variables
  useEffect(() => {
    if (value && !fromLocation) {
      setFromLocation(value.fromLocation)
      setToLocation(value.toLocation)
      setTransportType(value.transportType)
      setDangerousGoods(value.dangerous ? true : false)
      setVolumeM3(value.volumeM3)
      setWeightTn(value.weightTn)
      setShipId(value.shipId)
    }
  }, [
    value,
    fromLocation,
    setFromLocation,
    setToLocation,
    setTransportType,
    setDangerousGoods,
    setVolumeM3,
    setWeightTn,
    setShipId
  ])

  useEffect(() => {
    // Prefill the from and to if there are only 2 locations inside the route (PRP-1166)
    if (cargoIndex === 'new' && !fromLocation && !toLocation && routeLocations?.length === 2) {
      setFromLocation(routeLocations.find(l => l.routeIndex === 'departure'))
      setToLocation(routeLocations.find(l => l.routeIndex === 'arrival'))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const mappedSelectedHull = shipId
    ? hullsInVoyage.find(h => h.eni === shipId || h.mmsi === shipId)
    : undefined
  const newHullCargoLoad = determineTotalHullLoad(otherCargo)

  return (
    <Modal
      isOpen={showModal}
      onRequestClose={closeModal}
      className={`goods-modal ${theme}`}
      style={{ overlay: { backgroundColor: 'rgba(0,0,0,0.50)' } }}
      shouldCloseOnOverlayClick={true}
    >
      <BICSHullSelectorModal
        mainHull={mainHull}
        hulls={hullsInVoyage}
        showModal={showHullSelectModal}
        onClickHull={e => handleAssignHullToCargo(e)}
        onClose={() => setShowHullSelectModal(false)}
        hideCreateNewButton={true}
        titleOverride={I18n.t('announcements.travel.selectHull')}
        subtitleOverride={I18n.t('announcements.travel.clickToSelectHullForCargo')}
      />

      <form className="goods-modal-inner" onSubmit={handleSubmitGoodsForm}>
        <div className="title top">
          <h2>
            {I18n.t(disabled ? 'announcements.travel.viewGoods' : 'announcements.travel.addGoods')}
          </h2>
          {value && !disabled && (
            <button
              className="button danger"
              onClick={e => {
                e.preventDefault()
                handleDeleteSessionStorageArtifacts()
                setValue(null)
                closeModal()
              }}
            >
              {I18n.t('announcements.travel.deleteGoods')}
            </button>
          )}
        </div>

        <BICSGenericInputRow<keyof BicsVoyageCargo>
          keyName="fromLocation"
          errors={errors}
          className="align-flex-start"
        >
          <div className="destination-picker with-presets">
            <BICSDestinationPicker
              value={fromLocation && isBicsDestination(fromLocation) ? fromLocation : undefined}
              initialDestinationIdCode={
                fromLocation && !isBicsDestination(fromLocation)
                  ? fromLocation || undefined
                  : undefined
              }
              onChange={v => setFromLocation(v)}
              teqplayAPIService={teqplayAPIService}
              isClearable={true}
              disabled={disabled}
              type={'departure'}
            />
            {!disabled && (
              <BICSDestinationPresetList
                routeLocations={routeLocations}
                onClick={v => setFromLocation(v)}
                showOnMountOrUpdate={fromLocation === undefined}
              />
            )}
          </div>
        </BICSGenericInputRow>
        <BICSGenericInputRow<keyof BicsVoyageCargo>
          keyName="toLocation"
          errors={errors}
          className="align-flex-start"
        >
          <div className="destination-picker with-presets">
            <BICSDestinationPicker
              value={toLocation && isBicsDestination(toLocation) ? toLocation : undefined}
              initialDestinationIdCode={
                toLocation && !isBicsDestination(toLocation) ? toLocation || undefined : undefined
              }
              onChange={v => setToLocation(v)}
              teqplayAPIService={teqplayAPIService}
              isClearable={true}
              disabled={disabled}
              type={'arrival'}
            />
            {!disabled && (
              <BICSDestinationPresetList
                routeLocations={routeLocations}
                onClick={v => setToLocation(v)}
                showOnMountOrUpdate={toLocation === undefined}
              />
            )}
          </div>
        </BICSGenericInputRow>

        <BICSGenericInputRow<keyof BicsVoyageCargo>
          keyName="shipId"
          errors={errors}
          className="align-flex-start"
        >
          <div className="hull-select-wrapper">
            <BICSHullSelector
              value={shipId}
              onChange={h => setShipId(h?.mmsi || h?.eni)}
              hulls={hullsInVoyage}
              mainHull={mainHull}
            />
          </div>
        </BICSGenericInputRow>

        <BICSGenericInputRow<keyof BicsVoyageCargo> keyName="dangerous" errors={errors}>
          <SwitchButton isDisabled={disabled}>
            <button
              className={`button ${activeClass(dangerousGoods === true)}`}
              type="button"
              onClick={e => {
                e.preventDefault()
                setDangerousGoods(true)
              }}
              disabled={disabled}
            >
              {I18n.t('yes')}
            </button>
            <button
              className={`button ${activeClass(dangerousGoods === false)}`}
              type="button"
              onClick={e => {
                e.preventDefault()
                setDangerousGoods(false)
              }}
              disabled={disabled}
            >
              {I18n.t('no')}
            </button>
            <button
              className={`button ${activeClass(dangerousGoods === undefined)}`}
              type="button"
              onClick={e => {
                e.preventDefault()
                setDangerousGoods(undefined)
              }}
              disabled={disabled}
            >
              {I18n.t('goodsSelect.showAll')}
            </button>
          </SwitchButton>
        </BICSGenericInputRow>
        <BICSGenericInputRow<keyof BicsVoyageCargo> keyName="hsCode" errors={errors}>
          <BICSCargoSelector
            value={selectedGoods}
            initialCargoCode={value?.vnNr || value?.hsCode}
            teqplayAPIService={teqplayAPIService}
            dangerousGoods={dangerousGoods}
            onChange={g => setSelectedGoods(g)}
            disabled={disabled}
            isClearable={true}
          />
        </BICSGenericInputRow>
        <BICSGenericInputRow<keyof BicsVoyageCargo> keyName="transportType" errors={errors}>
          <BICSTransportTypeSelector
            value={transportType}
            onChange={v => setTransportType(v)}
            disabled={disabled}
          />
        </BICSGenericInputRow>
        <BICSGenericInputRow<keyof BicsVoyageCargo> keyName="residual" errors={errors}>
          <SwitchButton isDisabled={disabled}>
            <button
              className={`button ${activeClass(residual === true)}`}
              type="button"
              onClick={e => {
                e.preventDefault()
                setResidual(true)
              }}
              disabled={disabled}
            >
              {I18n.t('yes')}
            </button>
            <button
              className={`button ${activeClass(!residual)}`}
              type="button"
              onClick={e => {
                e.preventDefault()
                setResidual(undefined)
              }}
              disabled={disabled}
            >
              {I18n.t('no')}
            </button>
          </SwitchButton>
        </BICSGenericInputRow>
        {mappedSelectedHull && (
          <div
            className={`error-message cargo-weight-hint ${
              newHullCargoLoad > mappedSelectedHull?.cargoCapacityTn ? ' exceeds' : ''
            }`}
          >
            {I18n.t('announcements.travel.otherWeight', {
              hull: mappedSelectedHull.name || mappedSelectedHull.eni || mappedSelectedHull.mmsi,
              totalWeight: newHullCargoLoad,
              cargoCapacity: mappedSelectedHull.cargoCapacityTn
            })}
          </div>
        )}
        <BICSGenericInputRow keyName={'weightTn'} errors={errors}>
          <div className="with-suffix">
            <CustomNumberInput
              name="weightTn"
              value={residual === true ? 0 : weightTn}
              setValue={val => setWeightTn(val)}
              className="textfield"
              disabled={residual || disabled}
            />
            <span className="suffix">{I18n.t('announcements.fields.tonnage')}</span>
          </div>
        </BICSGenericInputRow>
        <BICSGenericInputRow keyName={'volumeM3'} errors={errors}>
          <div className="with-suffix">
            <CustomNumberInput
              name="volumeM3"
              value={volumeM3}
              setValue={val => setVolumeM3(val)}
              className="textfield"
              disabled={disabled}
            />
            <span className="suffix">{I18n.t('announcements.fields.m3')}</span>
          </div>
        </BICSGenericInputRow>

        <div className="buttons">
          <button
            className="button secondary cancel"
            type="reset"
            onClick={e => {
              e.preventDefault()
              closeModal()
              handleDeleteSessionStorageArtifacts()
            }}
          >
            {disabled ? I18n.t('close') : I18n.t('cancel')}
          </button>
          {!disabled && (
            <button className="button primary save" type="submit">
              {I18n.t('save')}
            </button>
          )}
        </div>
      </form>
    </Modal>
  )

  function validate(): ICustomError<keyof BicsVoyageCargo>[] {
    const validationErrors: ICustomError<keyof BicsVoyageCargo>[] = []

    if (!fromLocation) {
      validationErrors.push({
        key: 'fromLocation',
        error: I18n.t('announcements.error.requiredEmpty')
      })
    }
    if (!toLocation) {
      validationErrors.push({
        key: 'toLocation',
        error: I18n.t('announcements.error.requiredEmpty')
      })
    }
    if (!transportType) {
      validationErrors.push({
        key: 'transportType',
        error: I18n.t('announcements.error.requiredEmpty')
      })
    }
    if (selectedGoods && dangerousGoods === true && !selectedGoods?.dangerous) {
      validationErrors.push({
        key: 'dangerous',
        error: I18n.t('announcements.error.dangerousMismatch', { yes: I18n.t('yes') })
      })
    }
    if (!selectedGoods) {
      validationErrors.push({ key: 'hsCode', error: I18n.t('announcements.error.requiredEmpty') })
    }
    if (residual !== true && !weightTn) {
      validationErrors.push({ key: 'weightTn', error: I18n.t('announcements.error.requiredEmpty') })
    }

    if (!residual) {
      if (!weightTn) {
        validationErrors.push({
          key: 'weightTn',
          error: I18n.t('announcements.error.requiredEmpty')
        })
      } else if (weightTn >= 9999999) {
        validationErrors.push({
          key: 'weightTn',
          error: I18n.t('announcements.error.maxAmount', { amount: weightTn, maxAmount: 9999999 })
        })
      } else if (isNaN(weightTn as number)) {
        // Assertion to pass value as a number, check will go true if a string is passed
        validationErrors.push({
          key: 'weightTn',
          error: I18n.t('announcements.error.invalidNumberValue')
        })
      }

      if (cargoIndex !== undefined && cargoIndex !== 'new' && cargoIndex >= 0) {
        if (mappedSelectedHull && newHullCargoLoad > mappedSelectedHull?.cargoCapacityTn) {
          validationErrors.push({
            key: 'weightTn',
            error: I18n.t('announcements.error.exceededHullCargoCapacity', {
              cargoCapacityTn: mappedSelectedHull.cargoCapacityTn,
              totalCargoWeight: newHullCargoLoad
            })
          })
        }
      }
    }

    if (volumeM3 && volumeM3 <= 0) {
      validationErrors.push({
        key: 'volumeM3',
        error: I18n.t('announcements.error.minAmount', { amount: volumeM3, minAmount: 0.1 })
      })
    } else if (volumeM3 && volumeM3 > 999999) {
      validationErrors.push({
        key: 'volumeM3',
        error: I18n.t('announcements.error.maxAmount', { amount: volumeM3, maxAmount: 999999 })
      })
    } else if (volumeM3 && isNaN(volumeM3 as number)) {
      // Assertion to pass value as a number, check will go true if a string is passed
      validationErrors.push({
        key: 'volumeM3',
        error: I18n.t('announcements.error.invalidNumberValue')
      })
    }

    if (!shipId) {
      validationErrors.push({
        key: 'shipId',
        error: I18n.t('announcements.error.noHullSelected')
      })
    }

    return validationErrors
  }

  function handleSubmitGoodsForm(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault()

    const validationErrors = validate()

    if (validationErrors.length > 0) {
      setErrors(validationErrors)
      return
    }

    setValue({
      dangerous: selectedGoods?.dangerous,
      fromLocation: fromLocation
        ? isBicsDestination(fromLocation)
          ? fromLocation.srsCode
          : fromLocation
        : '',
      toLocation: toLocation
        ? isBicsDestination(toLocation)
          ? toLocation.srsCode
          : toLocation
        : '',
      hsCode: selectedGoods?.hsCode,
      transportType,
      volumeM3: volumeM3 as number,
      weightTn: residual === true ? 0 : (weightTn as number),
      residual,
      vnNr: selectedGoods?.vnNr,
      shipId: shipId
    })

    closeModal()
    handleDeleteSessionStorageArtifacts()
  }

  /**
   * Function which resets/delets any artifacts inside the session storage
   * Makes sure that whenever window is re-opened, newest values will be
   * shown to the user, and not the ones inside the session storage
   */
  function handleDeleteSessionStorageArtifacts() {
    setFromLocation(undefined)
    setToLocation(undefined)
    setTransportType(undefined)
    setDangerousGoods(undefined)
    setSelectedGoods(undefined)
    setWeightTn(undefined)
    setVolumeM3(undefined)
    setResidual(undefined)
    setShipId(undefined)
  }

  function isBicsDestination(v: BicsDestination | string | null): v is BicsDestination {
    return (v as BicsDestination)?.srsCode !== undefined
  }

  function handleAssignHullToCargo(eniMMSICombo: string) {
    const eni = eniMMSICombo.split('-')[0]
    const mmsi = eniMMSICombo.split('-')[1]
    const correctedMMSI = mmsi === 'undefined' ? undefined : mmsi

    if (!correctedMMSI && !eni) {
      toast.error(I18n.t('announcements.error.noENIOnHull'))
      return
    }

    setShowHullSelectModal(false)
    setShipId(correctedMMSI || eni)
  }

  /**
   * Function to determine the total load of cargo on a specific hull
   */
  function determineTotalHullLoad(allVoyageCargo: BicsVoyageCargo[]) {
    const allCargo =
      allVoyageCargo
        .filter(oc => oc.shipId === shipId)
        .filter((oc, i) => (!cargoIndex || cargoIndex === 'new' ? true : false)) || []

    const totalCargoWeight =
      allCargo.length > 0
        ? allCargo.map(c => c.weightTn).reduce((a, b) => (a || 0) + (b || 0)) || 0
        : 0

    const currentCargoLoad =
      residual === true || !weightTn ? 0 : isNaN(weightTn as number) ? 0 : (weightTn as number) || 0
    const totalHullLoad = currentCargoLoad + totalCargoWeight

    return totalHullLoad
  }
}

export default BICSGoodsModal
