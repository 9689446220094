import cloneDeep from 'lodash/cloneDeep'
import * as React from 'react'
import { I18n } from 'react-redux-i18n'

import RouteSelectField from '../routeSelectField/RouteSelectField'

import { IRouteSearchProps, RouteSelectionFields } from '../../../../@types/types'
import { IRouteLocation } from '../../../../services/TeqplayAPIService/TeqplayApi'

import './ViaRouteSelection.scss'

interface IProps {
  routeSelection: IRouteSearchProps
  setRouteSelectionFormFieldActive: (
    fieldName: RouteSelectionFields,
    viaRouteIndex?: number
  ) => void
  setViaRoutes: (viaRoutes: IRouteLocation[]) => void
  reverseRoute: () => void
}

const ViaRouteSelection = ({
  routeSelection,
  setRouteSelectionFormFieldActive,
  setViaRoutes,
  reverseRoute
}: IProps) => {
  return (
    <div className="via-routes-wrapper">
      {routeSelection.viaRoutes.map((viaRoute, index) => {
        const locationValue =
          viaRoute.displayName && viaRoute.displayName !== null ? viaRoute.displayName : ''

        return (
          <RouteSelectField
            key={'via-route' + index + locationValue}
            fieldName={'VIA_ROUTES'}
            value={locationValue}
            setrouteSelectionFormFieldActive={setRouteSelectionFormFieldActive}
            placeholder={I18n.t('routeSelection.selectViaPoint')}
            viaRouteIndex={index}
            className="via-route-field"
            removeViaRoute={removeViaRoute}
          />
        )
      })}

      <div className="buttons">
        <button
          type="button"
          className="button swap-button"
          onClick={reverseRoute}
          id="button-reverse-route-item-order"
        >
          <i className="icon-sort" />
        </button>
        <button
          type="button"
          className="button via-button"
          onClick={addEmptyViaRoute}
          id="button-add-via-point"
        >
          {I18n.t('routeSelection.viaPoint')} <i className="icon-plus-circled via-icon" />
        </button>
      </div>
    </div>
  )

  function addEmptyViaRoute() {
    const viaRoutes = cloneDeep(routeSelection.viaRoutes)
    const nextIndex = viaRoutes.length
    viaRoutes.push({
      displayName: '',
      coordinates: null
    })

    setViaRoutes(viaRoutes)
    setRouteSelectionFormFieldActive('VIA_ROUTES', nextIndex)
  }

  function removeViaRoute(viaRouteIndex: number) {
    const viaRoutes = cloneDeep(routeSelection.viaRoutes)
    viaRoutes.splice(viaRouteIndex, 1)
    setViaRoutes(viaRoutes)
  }
}

export default ViaRouteSelection
