import * as React from 'react'
import { I18n } from 'react-redux-i18n'
import { format } from 'date-fns'

import { IWaveObject } from '../mapShared/customLayers/layers/WavesLayer/WavesLayer'
import { DATE_TIME_FORMAT } from '../../utils/constants'

interface IProps {
  itemDetails: IWaveObject | null
  isExpanded: boolean
}

const WaveObjectDetails = ({ itemDetails, isExpanded }: IProps) => {
  if (!itemDetails || isExpanded === false) {
    return null
  }

  return (
    <div className="item-info-details" id={`wave-details-${itemDetails.name}`}>
      <div className="notification-inner">
        <section className="details-section">
          <div className="route-item-caption" id="wave-details-title">
            <b>
              {I18n.t('map.waveHeight.name')} {itemDetails.name}
            </b>
          </div>
          {itemDetails.height && (
            <div className="popup-wave-height">
              <span>{I18n.t('map.waveHeight.height')}: </span>
              <span>{itemDetails.height || '?'} cm</span>
            </div>
          )}
          {itemDetails.timeHeightLastUpdated && (
            <div className="popup-wave-timeHeightLastUpdated">
              <span>{I18n.t('map.waveHeight.timeHeightLastUpdated')}: </span>
              <span>{format(itemDetails.timeHeightLastUpdated, DATE_TIME_FORMAT)}</span>
            </div>
          )}
        </section>
      </div>
    </div>
  )
}

export default WaveObjectDetails
