import React from 'react'
import { I18n } from 'react-redux-i18n'

import { SelectedItemModalEnum } from '../../@types/types'
import { IBridgePlanning } from '../../services/TeqplayAPIService/TeqplayApi'
import { parseStringForID } from '../../utils/tests'
import { getBMSIcon } from '../../utils/bridgePlanning'
import { formatTime } from '../../utils/dates'

interface IProps {
  selectedItem: SelectedItemModalEnum
  selectedBridgePlanning: IBridgePlanning | undefined
}

const BridgePlannedOpeningTimes = ({ selectedItem, selectedBridgePlanning }: IProps) => {
  if (
    !selectedItem.item ||
    selectedItem.type !== 'ROUTE_ITEM' ||
    selectedItem.item.type !== 'BRIDGE'
  ) {
    return null
  }

  const { openingRequired, eta, hasPassedFrontend } = selectedItem.item

  if (
    !openingRequired ||
    !eta ||
    hasPassedFrontend ||
    !selectedBridgePlanning ||
    !selectedBridgePlanning.plannedOpeningTimes ||
    selectedBridgePlanning.plannedOpeningTimes.length === 0
  ) {
    return null
  }

  return (
    <div
      className="row bridge-planned-opening-times"
      id={`bridge-planned-opening-times-${selectedBridgePlanning.isrs}`}
    >
      <span className="info-tag">{I18n.t('routeList.itemDetails.plannedOpeningTimes')}</span>
      <div className="row">
        {selectedBridgePlanning.plannedOpeningTimes.slice(0, 3).map((ot, i) => (
          <div
            className={`planned-opening-time ${parseStringForID(ot.certainty)}`}
            id={`planned-opening-time-${selectedBridgePlanning.isrs}-${i}`}
            key={i}
          >
            <div>
              <span>
                <i className={getBMSIcon(ot.certainty)} />
              </span>
              <span>{formatTime(ot.plannedOpen)}</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default BridgePlannedOpeningTimes
